import { SVGProps } from 'react';

export const CloseFullscreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14 7L14 14C14 14.5523 13.5523 15 13 15L6 15'
      stroke={props.stroke || '#EEEEEE'}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M30 15L23 15C22.4477 15 22 14.5523 22 14L22 7'
      stroke={props.stroke || '#EEEEEE'}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M22 29V22C22 21.4477 22.4477 21 23 21H30'
      stroke={props.stroke || '#EEEEEE'}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M6 21L13 21C13.5523 21 14 21.4477 14 22L14 29'
      stroke={props.stroke || '#EEEEEE'}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
