import { MouseEventHandler, PropsWithChildren, ReactElement } from 'react';

export enum SelectorSize {
  Small = 'small',
  Medium = 'medium',
  MediumWider = 'mediumWider',
  Large = 'large',
}

export interface SelectorProps extends PropsWithChildren {
  size: SelectorSize;
  selected?: boolean;
  icon?: ReactElement;
  onClick?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
}
