import { CrossInCircle } from '../../assets/icons';
import { CrossInCircleLarge } from '../../assets/icons/CrossInCircleLarge';
import { InfoError } from '../../assets/icons/InfoError';
import { InfoErrorLarge } from '../../assets/icons/InfoErrorLarge';
import { useLogScreenViewedEvent } from '../../hooks/useLogScreenViewedEvent';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { colors } from '../ThemeProvider/constants/colors';
import * as Ui from './ErrorComponent.styles';

export enum IconType {
  ErrorX = 'ErrorX',
  InfoError = 'InfoError',
}

export const ErrorIcons = {
  [IconType.ErrorX]: 'ErrorX',
  [IconType.InfoError]: 'InfoError',
};

const iconsMap = {
  [ErrorIcons.InfoError]: {
    desktop: <InfoErrorLarge fill={colors.primary} />,
    mobile: <InfoError fill={colors.primary} />,
  },
  [ErrorIcons.ErrorX]: {
    desktop: <CrossInCircleLarge />,
    mobile: <CrossInCircle />,
  },
};

export const ErrorComponent = ({
  error,
  description,
  action,
  buttonText,
  icon,
  ...rest
}: {
  error?: string;
  buttonText?: string | JSX.Element;
  description?: string | JSX.Element;
  action?: () => void;
  icon: IconType;
}) => {
  const isDesktopView = useMediaQuery('lg');
  const hasButton = action && buttonText;
  useLogScreenViewedEvent(`error-${error}`);

  return (
    <Ui.ErrorWrapper {...rest}>
      <Ui.ErrorWrapperInner>
        <div>{iconsMap[icon][isDesktopView ? 'desktop' : 'mobile']}</div>
        <Ui.TextWrapper>
          {error && <Ui.ErrorMessage data-name='error-message'>{error}</Ui.ErrorMessage>}
          {description && <Ui.ErrorDescription>{description}</Ui.ErrorDescription>}
        </Ui.TextWrapper>

        {hasButton && (
          <ClickEventDispatcher location={`Error: ${error}`} text={`CTA: ${buttonText}`}>
            <Ui.ErrorButton onPress={action}>{buttonText}</Ui.ErrorButton>
          </ClickEventDispatcher>
        )}
      </Ui.ErrorWrapperInner>
    </Ui.ErrorWrapper>
  );
};
