import { quickplayCmsApi } from '../api/cmsApi/quickplayCmsApi';
import { getEnv } from '../env';

export const getImageResizeUrl = ({
  assetId,
  size,
  width = 300,
  format = 'png',
}: {
  assetId: string;
  size?: string;
  width?: number;
  format?: 'png' | 'jpg';
}) => {
  const { THUMB_IMAGE_URL } = getEnv();
  return quickplayCmsApi.getImageResizeUrl({
    assetId,
    assetSize: size,
    format,
    thumbImageUrl: THUMB_IMAGE_URL,
    width,
  });
};
