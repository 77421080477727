import { DeviceInfo } from '../../../api/authApi/authApi.types';
import { getDeviceId } from './getDeviceId';

export const getWebInfo = ({
  deviceModelNo,
  deviceUserAgent,
}: {
  deviceModelNo: string;
  deviceUserAgent: string;
}): DeviceInfo => {
  return {
    deviceId: getDeviceId(),
    deviceModelNo: deviceModelNo,
    deviceName: 'web',
    deviceType: 'browser',
    deviceUserAgent: navigator.userAgent || deviceUserAgent,
    isAndroid: /Android/.test(navigator.userAgent),
    isIOS: /iPhone|iPad|iPod/.test(navigator.userAgent),
    isTTSEnabled: false,
    isWeb: true,
    version: 'unsupported',
  };
};
