import { useSelector } from 'react-redux';

import { fullScreenSelector } from '../../store/player';
import { selectedVideoSelector } from '../../store/videos';
import ChromecastButton from '../playerUI/ChromecastButton/ChromecastButton';
import ClosedCaptionsButton from '../playerUI/ClosedCaptionsButton/ClosedCaptionsButton';
import { ProgressBar } from '../playerUI/ProgessBar/ProgressBar';
import { SeekToLiveButton } from '../playerUI/SeekToLiveButton/SeekToLiveButton';
import VolumeControl from '../playerUI/VolumeControl';
import FastForwardButton from '../playerUI/fastforwardButton';
import FullScreenButton from '../playerUI/fullScreenButton';
import { useSeekTime } from '../playerUI/hooks/useSeekTime';
import PlayPauseButton from '../playerUI/playPauseButton';
import RewindButton from '../playerUI/rewindButton';
import * as Ui from './PlayerControls.styles';

export const PlayerControlsVOD = () => {
  const selectedVideo = useSelector(selectedVideoSelector);
  const fullscreen = useSelector(fullScreenSelector);
  useSeekTime();

  return (
    <Ui.Wrapper>
      {fullscreen && <Ui.PlayerTitle>{selectedVideo?.title}</Ui.PlayerTitle>}
      <ProgressBar />

      <Ui.ControlsWrapper>
        <VolumeControl />
        <Ui.ButtonsWrapper>
          <Ui.PlayPauseButtonWrapper>
            <RewindButton />
            <PlayPauseButton />
            <FastForwardButton />
          </Ui.PlayPauseButtonWrapper>
        </Ui.ButtonsWrapper>
        <Ui.ButtonsWrapper>
          <SeekToLiveButton />
          <ClosedCaptionsButton />
          <ChromecastButton />
          <FullScreenButton />
        </Ui.ButtonsWrapper>
      </Ui.ControlsWrapper>
    </Ui.Wrapper>
  );
};

export default PlayerControlsVOD;
