import { PropsWithChildren, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useZipCodeTopVisibility } from '../../hooks/useZipCodeTopVisibility';
import { ZipCodeTopWeb } from '../ZipCodeTopBar/ZipCodeTopBar';

export const ZipCodeValidation = ({ children }: PropsWithChildren & { showModal?: boolean }) => {
  const { isVisible } = useZipCodeTopVisibility();
  const theme = useTheme();

  useEffect(() => {
    theme.setIsZipBarVisible(isVisible);
  }, [isVisible]);

  return (
    <>
      {isVisible && <ZipCodeTopWeb />}
      {children}
    </>
  );
};
