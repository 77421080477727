import { ChannelRaw } from '..';
import { ProductServiceTypeEnum } from '../authApi/authApi.types';
import { PlaybackMode } from '../playerApi';

export type StorefrontTab = {
  id: string;
  lon: { n: string }[];
};

export type AssetCatalogDataRaw = {
  aq?: string;
  aw_tm?: string;
  ca_ty?: AssetCategoryType;
  cid?: string;
  cids?: Array<string>;
  // Created DateTime
  ct?: string;
  // Content type
  cty?: AssetContentType;
  dur?: number;
  // Catalog Availibility End DateTime
  ed_dt?: string;
  // Catalog Availability Start DateTime (Product specific)
  ed_dt_prd?: {
    prd?: string;
    st_dt?: string;
  };
  ent?: [
    {
      con?: string;
      det?: string;
      dt?: string;
      f_ed_dt?: string;
      f_st_dt?: string;
      prd?: string;
      sp?: Array<string>;
    },
    {
      con?: string;
      det?: string;
      dt?: string;
      f_ed_dt?: string;
      f_st_dt?: string;
      prd?: string;
      sp?: Array<string>;
    },
  ];
  //  Enrichment Provider
  ep?: {
    n?: string;
    id?: string;
  };
  ev_chty?: string;
  ev_ed_dt?: string;
  ev_st_dt?: string;
  // External Identifier
  ex_id?: string;
  ev_live?: string;
  gameId?: string;
  gm_tm?: Array<string>;
  hm_tm?: string;
  // Image Assets
  ia?: string[];
  // Unique Identifier of Resource
  id?: string;
  // Unique identifier of Version
  key?: string;
  lck?: string;
  // Localized Description
  lod?: QPTranslation[];
  // Localized Event Title
  loen?: QPTranslation[];
  // Localized Genre
  log?: [
    {
      lang?: string;
      n?: Array<string>;
    },
  ];
  // Localized Long Description
  lold?: QPTranslation[];
  // Localized Keywords
  lok?: QPTranslation[];
  // Localized Title
  lon?: QPTranslation[];
  // Localized Tags
  lotg?: QPTranslation[];
  lwe?: string;
  lws?: string;
  // Network name
  net?: string;
  net_id?: number;
  nu?: string;
  // Original Air Date
  oadt?: string;
  p_clr?: string;
  p_losn?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  pgm?: VideoProgramationRaw;
  // Provider Name
  pn?: string;
  // Products
  prd?: Array<string>;
  pt?: ProductServiceTypeEnum;
  // Release Year
  r?: string;
  // Release Date
  rdt?: string;
  rt?: number;
  sc_ed_dt?: string;
  sc_st_dt?: string;
  spt_lg?: string;
  spt_ty?: string;
  // Publication Status
  st?: string;
  // Catalog Availibility Start DateTime
  st_dt?: string;
  // Catalog Availability Start DateTime (Product specific)
  st_dt_prd?: {
    prd?: string;
    st_dt?: string;
  };
  // Team Information
  tm?: [
    {
      // Team Code / Abbreviation
      c?: string;
      // image aspect ratio
      ia?: Array<string>;
      // Team Identifier
      id?: string;
      // Localized Team Name
      lon?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      // Localized Team Short Name
      losn?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      pmy_tm?: string;
      // Team Region Name Example: "New York"
      r?: string;
      // "Team Type home | away"
      ty?: string;
    },
    {
      c?: string;
      // Team Colors
      clr?: string;
      ia?: Array<string>;
      id?: string;
      lod?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      lon?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      losn?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      pmy_tm?: string;
      r?: string;
      ty?: string;
    },
  ];
  // Transition (legacy platform) Identifier
  ts_id?: string;
  ty?: VideoType;
  tv_pc?: [
    {
      prd?: string;
      sku?: string;
      tp_ed_dt?: string;
      tp_st_dt?: string;
    },
  ];
  // Unique Resource Name
  urn?: string;
  // Updated DateTime
  ut?: string;
  vq?: string;
  zn?: Array<string>;
};

export enum ContainerType {
  BANNER = 'banner',
  CAROUSEL = 'carousel',
}

export enum AssetCategoryType {
  GAME = 'game',
  PREGAME = 'pregame',
  POSTGAME = 'postgame',
  SHOW = 'show',
  PAID = 'paid',
  BLACKOUT = 'blackout',
}

export enum AssetContentType {
  EPISODE = 'episode',
  LIVEEVENT = 'liveevent',
  AIRING = 'airing',
  CHANNEL = 'channel',
}

export enum VideoType {
  COLLECTION = 'Collection',
}
export interface QPTranslation {
  // Language Code
  lang: string;
  // translated text
  n: string;
}

export interface DeviceImageAspectRatio {
  // device type
  dt: string;
  // image aspect ratio
  iar: string;
}

export interface TextStyle {
  text_overlay?: string;
  font_size?: string;
  font_weight?: string;
  font_style?: string;
  font_stretch?: string;
  position?: string;
  transparency?: number;
}

export interface ScrollStyle {
  type?: string;
  direction?: string;
  autoplay?: string;
  loop?: string;
}

export interface LogoOverlay {
  enable?: string;
  position?: string;
  transparency?: number;
  image_name?: string;
}

export interface TagOverlay {
  enable?: string;
  position?: string;
  transparency?: number;
  tag_name?: string;
}

export interface ContainerItem {
  count: number;
  cu: string;
  priority: number;
  type: string;
}

export type StorefrontContainer = {
  appliesif:
    | {
        // device type
        dt: string[];
        // zone list
        reg: string[];
        // user type
        ut: string[];
      }
    | undefined;
  cd?: AssetCatalogDataRaw[];
  click_action?: string;
  // container tag
  con_tg?: string;
  // device image aspect ratio
  diar?: DeviceImageAspectRatio[];
  ed_dt?: string;
  edit?: boolean;
  // container items
  i?: ContainerItem[];
  // container aspect ratio
  iar?: string;
  // Unique Identifier of Container
  id: string;
  // container layout
  lo?: string;
  // Localized Container Name
  lod?: QPTranslation[];
  logo_overlay?: LogoOverlay;
  // Localized Container Name
  lon?: QPTranslation[];
  // container size
  s?: string;
  scroll_style?: ScrollStyle;
  // container source
  src?: string;
  // container source type
  src_ty?: string;
  st_dt?: string;
  // container style
  stl?: string;
  tag_overlay?: TagOverlay;
  text_style?: TextStyle;
  // unique resource name
  urn: string;
};

export type Channel = {
  id: string;
  name: string;
  rawData: ChannelRaw;
  program: Video | null;
  purchaseType: ProductServiceTypeEnum;
};

export type VideoProgramationRaw = {
  ex_id?: string;
  id?: string;
  lod?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  loepn?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  lold?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  lon?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  r?: string;
  spt_lg?: string;
  spt_ty?: string;

  tm?: [
    {
      c?: string;
      ia?: Array<string>;
      id?: string;
      lon?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      losn?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      pmy_tm?: string;
      r?: string;
      ty?: string;
    },
    {
      c?: string;
      clr?: string;
      ia?: Array<string>;
      id?: string;
      lod?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      lon?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      losn?: [
        {
          lang?: string;
          n?: string;
        },
      ];
      pmy_tm?: string;
      r?: string;
      ty?: string;
    },
  ];
};

export type VideoProgramation = {
  leagueName: string;
  sportName: string;
  venueCity?: string;
  description?: string;
  title?: string;
  rawData: VideoProgramationRaw;
};

export type VideoTeamRaw = {
  c?: string;
  clr?: string;
  ia?: Array<string>;
  id?: string;
  lod?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  lon?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  losn?: [
    {
      lang?: string;
      n?: string;
    },
  ];
  pmy_tm?: string;
  r?: string;
  ty?: string;
};

export type VideoTeam = {
  id: string;
  teamId: string;
  name: string;
  rawData: VideoTeamRaw;

  venueCity?: string;
  shortName: string;
  fullName: string;
  imagesSizes: string[];
};

export type Video = {
  categoryType?: AssetCategoryType;
  id: string;
  externalId: string;
  cid: string | null;
  title: string;
  channel?: string;
  contentType?: AssetContentType;
  description: string;
  duration?: string | number;
  isFree: boolean;
  isLive: boolean;
  genre?: string[];
  rawData: AssetCatalogDataRaw;
  // thumbImageUrl: string;
  productType?: ProductServiceTypeEnum;
  startDate: string;
  endDate: string;
  homeTeam?: VideoTeam;
  programation?: VideoProgramation;
  venueCity?: string;
  visitorTeam?: VideoTeam;
  isCurrentlyAiring?: boolean | undefined;
  league?: string;
  licenseWindowStart?: string;
  licenseWindowEnd?: string;
  purchaseAvailabilityStart?: string;
  purchaseAvailabilityEnd?: string;
  isPPGPurchasable?: boolean | undefined;
  videoType?: VideoType;
  playbackMode?: PlaybackMode;
  playbackContentType?: AssetContentType;
  primaryTeam?: string;
  gameId?: string;
};

export interface GetImageResizeUrlProps {
  assetId: string;
  assetSize?: string;
  width: number;
  format?: string;
  thumbImageUrl: string;
}

export interface GetLiveEventsResponseHeader {
  code: number;
  count: number;
  message: string;
  rows: number;
  source: string;
  start: number;
  system_time: number;
  tracking_id: string;
}

export interface GetLiveEventsResponse {
  data: AssetCatalogDataRaw[];
  header: GetLiveEventsResponseHeader;
}
