import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { Video } from '../../api';
import { updateCarouselWithAllVideos } from './utils/utils';
import { VideoTeamPage, Videos, VideosState } from './videos.types';

export const initialState: VideosState = {
  banner: null,
  carousels: [],
  error: '',
  isPurchasingVideo: false,
  loaded: false,
  loading: false,
  purchaseVideo: null,
  selectedVideo: null,
  teamPage: null,
  videoLoaded: false,
  videoLoading: false,
};

export const loadVideosAction = createAction('videos/loadVideos');
export const loadAllVideosAction = createAction('videos/loadAllVideos');
export const loadVideoById = createAction('videos/loadVideoById');

export const displayVideoAction = createAction('videos/displayVideo');

const slice = createSlice({
  initialState: initialState,
  name: 'videos',
  reducers: {
    displayVideo: (state, action: PayloadAction<Video>) => ({
      ...state,
      isPurchasingVideo: false,
      selectedVideo: action.payload,
      videoLoaded: true,
      videoLoading: false,
    }),
    displayVideoFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      selectedVideo: null,
      videoLoaded: true,
      videoLoading: false,
    }),
    loadAllVideosFailure: (state, action: PayloadAction<{ containerId: string }>) => ({
      ...state,
      carousels: updateCarouselWithAllVideos(state.carousels, action.payload.containerId, []),
    }),
    loadAllVideosSuccess: (
      state,
      action: PayloadAction<{ videos: Video[]; containerId: string }>,
    ) => ({
      ...state,
      carousels: updateCarouselWithAllVideos(
        state.carousels,
        action.payload.containerId,
        action.payload.videos,
      ),
    }),
    loadVideo: (state) => ({
      ...state,
      videoLoaded: false,
      videoLoading: true,
    }),
    loadVideosError: (state, action: PayloadAction<string>) => ({
      ...state,
      banner: null,
      carousels: [],
      error: action.payload,
      loading: false,
    }),
    loadVideosLoading: (state) => ({
      ...state,
      error: null,
      loading: true,
    }),
    loadVideosSuccess: (state, action: PayloadAction<Omit<Videos, 'teamPage'>>) => ({
      ...state,
      ...action.payload,
      error: null,
      loaded: true,
      loading: false,
    }),
    purchaseClosed: (state) => ({
      ...state,
      isPurchasingVideo: false,
      purchaseVideo: null,
    }),
    purchaseVideo: (state, action: PayloadAction<Video | null>) => ({
      ...state,
      isPurchasingVideo: true,
      purchaseVideo: action.payload,
    }),
    removeVideo: (state) => ({
      ...state,
      selectedVideo: null,
    }),
    resetCarousels: (state) => ({
      ...state,
      carousels: [],
      loaded: false,
    }),
    setTeamPage: (state, action: PayloadAction<VideoTeamPage | null>) => ({
      ...state,
      teamPage: action.payload,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as videosActions, name, reducer };
