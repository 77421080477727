import React, { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../../Constants/routes';
import useIsMobile from '../../hooks/useIsMobile';
import { useTranslations } from '../../hooks/useTranslations';
import { cacheManager } from '../../utils';
import * as Ui from './LegalInfoBar.styles';

export const LEGAL_INFO_BAR_SESSION = 'legal-info-bar-shown';

const MOBILE_EMBEDDED_PAGES = ['editpassword'];
const getCachedState = () => cacheManager.load<boolean>(LEGAL_INFO_BAR_SESSION);

const LegalInfoBar: React.FC = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const t = useTranslations();
  const [visible, setVisible] = useState<boolean>(!getCachedState());

  const isMobilePage = MOBILE_EMBEDDED_PAGES.some(
    (path) => path === location.pathname.toLowerCase().replaceAll('/', ''),
  );

  const onClose = () => {
    setVisible(false);
    cacheManager.save<boolean>(LEGAL_INFO_BAR_SESSION, true);
  };

  if (!visible || isMobilePage) return null;

  return (
    <Ui.Root>
      <Ui.Content>
        <Ui.TitleWrapper>
          <Ui.Title>{t.legalInfoBar_title}</Ui.Title>
          <CloseButton variant='white' onClick={onClose} />
        </Ui.TitleWrapper>
        <Ui.LegalInfo>
          {t.legalInfoBar_message_1}{' '}
          <Ui.LegalInfoLink to={ROUTES[RouteName.TermsOfUse]}>
            {t.legalInfoBar_link_termsOfUse}
          </Ui.LegalInfoLink>{' '}
          {t.legalInfoBar_message_2}{' '}
          <Ui.LegalInfoLink to={ROUTES[RouteName.PrivacyPolicy]}>
            {t.legalInfoBar_link_privacyPolicy}
          </Ui.LegalInfoLink>
          {t.legalInfoBar_message_3}
          {!isMobile && <br />}
          {t.legalInfoBar_message_4}
          <Ui.LegalInfoLink to={ROUTES[RouteName.PastTermsOfUse]}>
            {t.legalInfoBar_link_pastTerms}
          </Ui.LegalInfoLink>
          {t.global_dot}
        </Ui.LegalInfo>
      </Ui.Content>
    </Ui.Root>
  );
};

export default LegalInfoBar;
