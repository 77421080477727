import * as Ui from './TileStep.styles';

export interface TileStepProps {
  index: number;
  imgSrc: string;
  imgAlt: string;
  caption: string;
}

export const TileStep = ({ index, imgAlt, imgSrc, caption }: TileStepProps) => {
  return (
    <Ui.Root>
      <Ui.DynamicImg src={imgSrc} alt={imgAlt} />

      <Ui.TextWrapper>
        <Ui.Index>{index}</Ui.Index>

        <Ui.CaptionWrapper>
          <Ui.Caption>{caption}</Ui.Caption>
        </Ui.CaptionWrapper>
      </Ui.TextWrapper>
    </Ui.Root>
  );
};
