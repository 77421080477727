import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { browserActions } from '../store/browser';
import { AppDispatch } from '../store/store';

export const useIsActiveTab = () => {
  const dispatch: AppDispatch = useDispatch();
  const { pathname } = useLocation();

  const visibilityChangeHandler = useCallback(() => {
    // detect tab change only
    if (document.visibilityState == 'visible') {
      dispatch(
        browserActions.setBrowserState({
          currentPathname: pathname,
          inactivityEndTime: new Date().getTime(),
          isActive: true,
        }),
      );
    } else {
      dispatch(
        browserActions.setBrowserState({
          currentPathname: pathname,
          inactivityStartTime: new Date().getTime(),
          isActive: false,
        }),
      );
    }
  }, [dispatch, pathname]);

  const onBlurHandler = useCallback(() => {
    // detect tab change or browser out of focus
    dispatch(
      browserActions.setBrowserState({
        currentPathname: pathname,
        inactivityStartTime: new Date().getTime(),
        isFocused: false,
      }),
    );
  }, [dispatch, pathname]);
  const onFocusHandler = useCallback(() => {
    // detect tab change or browser in focus
    dispatch(
      browserActions.setBrowserState({
        currentPathname: pathname,
        inactivityEndTime: new Date().getTime(),
        isFocused: true,
      }),
    );
  }, [dispatch, pathname]);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangeHandler);
    window.addEventListener('blur', onBlurHandler);
    window.addEventListener('focus', onFocusHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      window.removeEventListener('blur', onBlurHandler);
      window.removeEventListener('focus', onFocusHandler);
    };
  }, [pathname]);
};
