import Conviva from '@convivainc/conviva-js-coresdk';
import * as flConviva from '@msgn/fl-module/fl-conviva';
import { version } from '@msgn/fl-module/fl-player';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import packageJson from '../../package.json';
import { AssetContentType, FLPlatformAsset, Video } from '../api';
import { Platform } from '../api/mParticlesApi/constants';
import { useEnv } from '../components/EnvProvider/EnvProvider';
import { activeSubscriptionsSelector } from '../store/activeSubscriptions';
import { userDataSelector } from '../store/userData';
import { selectedVideoSelector } from '../store/videos';
import { parseContentType } from '../utils/conviva';

window.Conviva = Conviva;

export const mapIdForAssetName = (selectedVideo: Video) => {
  let contentId = selectedVideo.externalId || selectedVideo.id;
  if (selectedVideo.contentType === AssetContentType.AIRING) {
    contentId = selectedVideo.gameId || selectedVideo.id;
  }
  if (selectedVideo.contentType === AssetContentType.CHANNEL) {
    contentId = selectedVideo.id;
  }
  return contentId;
};

export const useConviva = () => {
  const { conviva } = useEnv();
  const [convivaSession, setConvivaSession] = useState<flConviva.ConvivaSession>();
  const selectedVideo = useSelector(selectedVideoSelector);
  const user = useSelector(userDataSelector);
  const { tveProviderName } = useSelector(activeSubscriptionsSelector);

  const isProd = process.env.REACT_APP_ENV === 'prod';

  const updateContentMetadata = useCallback(
    (asset: FLPlatformAsset) => {
      convivaSession?.setContentMetadata({
        [Conviva.Constants.STREAM_URL]: asset.contentUrl,
        'c3.cm.contentType': parseContentType(asset?.contentType, selectedVideo?.playbackMode),
      });
    },
    [convivaSession, selectedVideo],
  );

  const startConvivaSession = useCallback(() => {
    if (conviva.isEnabled && selectedVideo && convivaSession) {
      convivaSession?.reportPlaybackRequested({
        [Conviva.Constants.STREAM_URL]: '',
        [Conviva.Constants.ASSET_NAME]: `[${mapIdForAssetName(selectedVideo)}] ${
          selectedVideo?.title
        }`,
        [Conviva.Constants.IS_LIVE]: selectedVideo?.isLive
          ? Conviva.Constants.StreamType.LIVE
          : Conviva.Constants.StreamType.VOD,
        [Conviva.Constants.PLAYER_NAME]: Platform.Web,
        [Conviva.Constants.VIEWER_ID]: user.cpCustomerID,
        'c3.app.version': packageJson.version,
        'c3.cm.affiliate': tveProviderName?.toLowerCase() || 'NA',
        'c3.cm.appName': 'MSG+ (WEB)',
        'c3.cm.categoryType': selectedVideo?.categoryType || 'NA',
        'c3.cm.channel': selectedVideo.channel || '',
        'c3.cm.genre':
          (Array.isArray(selectedVideo?.genre) ? selectedVideo?.genre[0] : selectedVideo?.genre) ||
          'NA',
        'c3.cm.genreList': Array.isArray(selectedVideo?.genre)
          ? selectedVideo?.genre?.join(', ') || 'NA'
          : 'NA',
        'c3.cm.id': selectedVideo?.id || '',
        'c3.cm.name': 'QP',
        'c3.cm.showTitle': selectedVideo?.title || 'NA',
        [Conviva.Constants.FRAMEWORK_VERSION]: version,
        [Conviva.Constants.FRAMEWORK_NAME]: 'quickplay',
      });
    }
  }, [selectedVideo, convivaSession, user, conviva]);

  useEffect(() => {
    if (conviva.isEnabled) {
      const initializationData = {} as flConviva.ConvivaInitializationData;
      const deviceMetadata = {} as flConviva.ConvivaDeviceMetadata;

      // eslint-disable-next-line
      // @ts-ignore
      deviceMetadata[Conviva.Constants.DeviceMetadata.CATEGORY] =
        Conviva.Constants.DeviceCategory.WEB;

      initializationData.customerKey = conviva.key;
      if (!isProd) {
        initializationData.debug = true;
        initializationData.gatewayUrl = conviva.touchstoneURL;
      }

      const flConvivaSession = flConviva.createConvivaSession(initializationData, deviceMetadata);

      flConvivaSession.initialize();
      setConvivaSession(flConvivaSession);
    }
  }, []);

  return { convivaSession, startConvivaSession, updateContentMetadata };
};
