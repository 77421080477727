import Conviva from '@convivainc/conviva-js-coresdk';
import { ConvivaMetadata } from '@msgn/fl-module/fl-conviva';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlayerHandler, PlayerState, PlayerTrack, TrackType } from '../../api';
import {
  heartbeatEvent,
  videoErrored,
  videoPlayClickedEvent,
  videoSeekEnded,
  videoSeekStarted,
  videoStartedEvent,
} from '../../api/mParticlesApi/mParticleEvents/video/videoEvents';
import { flpOnBuufferingStateChange } from '../../api/playerApi/eventAdapters/flpOnBuufferingStateChange';
import { flpOnProgressChangedAdapter } from '../../api/playerApi/eventAdapters/flpOnProgressChangedAdapter';
import { flpOnStateChangedAdapter } from '../../api/playerApi/eventAdapters/flpOnStateChangedAdapter';
import { PlayerHandlerContext } from '../../components/Player/Player';
import { envName } from '../../env';
import { useFLPCastSession } from '../../hooks/flp/useFLPCastSession';
import { playerActions } from '../../store/player';
import {
  fullScreenSelector,
  isLocalPlayerSelector,
  playerAssetErrorSelector,
  playerAssetSelector,
  playerErrorSelector,
  playerStateSelector,
} from '../../store/player/player.selectors';
import { programSelector } from '../../store/programs';
import { AppDispatch } from '../../store/store';
import { selectedVideoSelector } from '../../store/videos';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { useConvivaContext } from '../ConvivaProvider/ConvivaProvider';
import { usePlayerTracks } from './usePlayerTracks';

export const isFullscreen = () => !!document.fullscreenElement;

declare global {
  interface Window {
    player: unknown;
  }
}

interface PlayerControlsData {
  activeTracks: Record<TrackType, PlayerTrack>;
  ccActive: boolean;
  countTracks: number;
  handleChangeTrack: (track?: PlayerTrack) => void;
  handleJumpToLive: () => void;
  handleOnResume: () => void;
  handlePause: () => void;
  handlePlay: () => void;
  handleReplay: () => void;
  handleTime: (time: number) => void;
  isLoadingIndicatorVisible: boolean;
  logSeekBarEnded: (time: number) => void;
  logSeekBarStarted: (time: number) => void;
  notification: string;
  setCcActive: React.Dispatch<React.SetStateAction<boolean>>;
  pushNotification: (notification: string) => void;
  tracksGroups: Record<TrackType, PlayerTrack[]>;
  player: PlayerHandler;
  stopCastSession: () => void;
  isCastDevicesAvailable?: boolean;
  castDeviceName?: string;
}

const PlayerControlsContext = createContext<PlayerControlsData | undefined>(undefined);

export const usePlayerControlsContext = () => {
  const context = useContext(PlayerControlsContext);
  if (!context) {
    throw new Error('usePlayerControlsContext must be used within a PlayerControlsProvider');
  }
  return context;
};

export const PlayerControlsProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch: AppDispatch = useDispatch();
  const playerState = useSelector(playerStateSelector);
  const [notification, setNotificaton] = useState('');
  const [isBuffering, setIsBuffering] = useState(false);
  const [heartbeatCounter, setHeartbeatCounter] = useState(0);
  const { zoneKey } = useSelector(zoneInfoStateSelector);
  const selectedVideo = useSelector(selectedVideoSelector);
  const fullscreen = useSelector(fullScreenSelector);
  const playerError = useSelector(playerErrorSelector);
  const assetError = useSelector(playerAssetErrorSelector);
  const isLocalPlayer = useSelector(isLocalPlayerSelector);
  const program = useSelector(programSelector);
  const platformAsset = useSelector(playerAssetSelector);
  const { ref, clearNetworkError, networkError, assetState, player } =
    useContext(PlayerHandlerContext);
  const { convivaSession } = useConvivaContext();
  const { isCastDevicesAvailable, castDeviceName, stopCastSession } = useFLPCastSession();

  const {
    activeTracks,
    ccActive,
    countTracks,
    handleChangeTrack,
    setCcActive,
    selectTrack,
    tracksGroups,
  } = usePlayerTracks({
    player,
  });

  if (envName === 'dev') {
    window.player = player;
  }

  const logEventPlay = () => {
    videoPlayClickedEvent(selectedVideo);
  };

  const logSeekBarStarted = useCallback(
    (time: number) => {
      videoSeekStarted(selectedVideo, time);
    },
    [selectedVideo],
  );

  const logSeekBarEnded = useCallback(
    (time: number) => {
      videoSeekEnded(selectedVideo, time);
    },
    [selectedVideo],
  );

  const handlePlay = useCallback(() => {
    logEventPlay();
    player.play();

    if (player.internalPlayerName === 'shaka-player') {
      selectTrack(player);
    }
    videoStartedEvent(selectedVideo, player.currentTime, zoneKey, isFullscreen(), platformAsset);
  }, [player, zoneKey]);

  const handlePause = useCallback(() => {
    player.pause();
  }, [player]);

  const handleTime = useCallback(
    (time: number) => {
      player.seek(time);
    },
    [player],
  );

  const handleReplay = useCallback(() => {
    player.seek(0);
    setTimeout(() => {
      handlePlay();
    }, 0);
  }, [handlePlay, player]);

  const handleOnResume = useCallback(() => {
    const { end } = player.seekableRange();
    handleTime(end);
  }, [handleTime, player]);

  const handleJumpToLive = useCallback(() => {
    player.seekToLiveEdge();
    player.play();
  }, [player]);

  useEffect(() => {
    if (!fullscreen) {
      document.body.classList.add('player-not-fullscreen');
      return () => {
        document.body.classList.remove('player-not-fullscreen');
      };
    }
  }, [fullscreen]);

  // we need to set initial player state when player has changed (local => cast | cvast => local)
  useEffect(() => {
    dispatch(playerActions.setPlayerState('' as PlayerState));
  }, [player]);

  useEffect(() => {
    return flpOnStateChangedAdapter(player, (state: string) => {
      dispatch(playerActions.setPlayerState(state as PlayerState));
      convivaSession?.setContentMetadata({
        [Conviva.Constants.ENCODED_FRAMERATE]: player.playbackStatistics?.framerate,
      } as ConvivaMetadata);
    });
  }, [player]);

  useEffect(() => {
    return flpOnBuufferingStateChange(player, (bufferingState: string) => {
      setIsBuffering(bufferingState === 'active');
    });
  }, [player]);

  useEffect(() => {
    // HOTFIX: play only when video tag has content
    // TODO: Look for better flags to trigger play

    if (ref?.current?.src && !player?.isInvalidPlayer) {
      handlePlay();
    }
  }, [handlePlay, player?.isInvalidPlayer]);

  useEffect(() => {
    if (!assetState.loading && assetState.asset) {
      const intervalID = setInterval(() => {
        setHeartbeatCounter((counter) => counter + 1);
      }, 60000);

      return () => {
        clearInterval(intervalID);
      };
    }
  }, [assetState.loading]);

  useEffect(() => {
    if (!assetState.loading && platformAsset && selectedVideo) {
      const video = program ?? selectedVideo;
      heartbeatEvent(video, player.currentTime, zoneKey, isFullscreen(), platformAsset);
    }
  }, [heartbeatCounter, platformAsset, selectedVideo, program]);

  useEffect(() => {
    if (assetError || playerError) {
      videoErrored(selectedVideo, player.currentTime);
    }
  }, [assetError, playerError]);

  useEffect(() => {
    return flpOnProgressChangedAdapter(player, () => {
      if (navigator.onLine && networkError) {
        clearNetworkError();
      }
    });
  }, [networkError, clearNetworkError]);

  const isLoadingIndicatorVisible = useMemo(() => {
    if (!isLocalPlayer) {
      return false;
    }
    return (
      [PlayerState.LOADING, PlayerState.IDLE].includes(playerState as PlayerState) || isBuffering
    );
  }, [playerState, isBuffering, isLocalPlayer]);

  const pushNotification = useCallback((notification: string) => {
    setNotificaton(notification);
  }, []);

  const playerControls: PlayerControlsData = useMemo(
    (): PlayerControlsData => ({
      activeTracks,
      castDeviceName,
      ccActive,
      countTracks,
      handleChangeTrack,
      handleJumpToLive,
      handleOnResume,
      handlePause,
      handlePlay,
      handleReplay,
      handleTime,
      isCastDevicesAvailable,
      isLoadingIndicatorVisible,
      logSeekBarEnded,
      logSeekBarStarted,
      notification,
      player,
      pushNotification,
      setCcActive,
      stopCastSession,
      tracksGroups,
    }),
    [
      activeTracks,
      ccActive,
      countTracks,
      handleChangeTrack,
      handleJumpToLive,
      handleOnResume,
      handlePause,
      handlePlay,
      handleReplay,
      handleTime,
      isLoadingIndicatorVisible,
      logSeekBarEnded,
      logSeekBarStarted,
      notification,
      setCcActive,
      pushNotification,
      tracksGroups,
      player,

      isCastDevicesAvailable,
      castDeviceName,
      stopCastSession,
    ],
  );

  return (
    <PlayerControlsContext.Provider value={playerControls}>
      {children}
    </PlayerControlsContext.Provider>
  );
};
