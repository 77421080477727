import { getMonth, getYear } from 'date-fns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getMonthName } from '../../../helpers/getMonthName';
import { monthsWithoutGamesSelector, scheduleSelector } from '../../../store/schedule';

export const useGamesGuideData = () => {
  const { games } = useSelector(scheduleSelector);

  const monthsWithoutGames = useSelector(monthsWithoutGamesSelector);

  const gamesWithSlotsForEmptyMonth = useMemo(() => {
    const currentMoth = getMonth(new Date());
    const currentYear = getYear(new Date());

    return monthsWithoutGames.map((monthIndex) => ({
      month: monthIndex,
      monthName: getMonthName(monthIndex),
      startDate: new Date(monthIndex < currentMoth ? currentYear + 1 : currentYear, monthIndex, 1),
      year: monthIndex < currentMoth ? currentYear + 1 : currentYear,
    }));
  }, [monthsWithoutGames]);

  const gamesGuideData = useMemo(
    () =>
      [...games, ...gamesWithSlotsForEmptyMonth].sort(
        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
      ),
    [games, gamesWithSlotsForEmptyMonth],
  );

  return gamesGuideData;
};
