import styled from 'styled-components';

import { bolder, titleXs } from '../Common';
import * as Ui from '../PurchaseModal/PurchaseModal.styles';

export const Title = styled(Ui.Title)`
  ${bolder}
`;
export const TitleDescription = styled(Ui.TitleDescription)`
  ${titleXs}
  ${bolder}
`;
export const ContentContainer = styled(Ui.ContentContainer)`
  min-height: calc(100vh - 7.5rem);
  min-height: calc(100vh - 7.5rem);
`;
