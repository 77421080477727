import { FailureResponse, GetStringResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getString = async ({
  accessToken,
  attributeName,
  evergentApiKey,
  evergentChannelPartnerId,
  getStringUrl,
}: {
  accessToken: string;
  attributeName: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getStringUrl: string;
}): Promise<GetStringResponse> => {
  const getStringData = {
    apiKey: evergentApiKey,
    attributeName: attributeName,
    channelPartnerID: evergentChannelPartnerId,
  };

  const body = { GetStringRequestMessage: getStringData };

  const signUpResponse = await evergentAxiosInstance.post<{
    GetStringResponseMessage: GetStringResponse & FailureResponse;
  }>(getStringUrl, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = signUpResponse.data.GetStringResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return { ...dataResponse, failureMessage };
};
