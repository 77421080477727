import { CacheManager } from './cache.types';

export const buildCacheManager = (): CacheManager => {
  const save = <T>(key: string, data: T) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  const load = <T>(key: string) => {
    const rawData = localStorage.getItem(key);
    if (rawData) {
      return JSON.parse(rawData) as T;
    }
    return null;
  };

  const remove = (key: string) => {
    localStorage.removeItem(key);
  };

  return { load, remove, save };
};

export const cacheManager = buildCacheManager();

export default cacheManager;
