import styled from 'styled-components';

import { TypographyProps, bolder, textBase, titleXs } from '../Common';

export const PaymentOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.p<TypographyProps>`
  ${textBase};
  ${titleXs};
  ${bolder};
  color: ${({ theme }) => theme.colors['off-white']};
`;
