import { BsCircleFill } from 'react-icons/bs';

import { useTranslations } from '../../hooks/useTranslations';
import * as Ui from './LivePill.styles';

export const LivePill = () => {
  const t = useTranslations();

  return (
    <Ui.LivePill>
      <BsCircleFill />
      <span className='ps-1'>{t.global_live}</span>
    </Ui.LivePill>
  );
};
