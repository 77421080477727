import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { selectedVideoSelector } from '../videos/videos.selectors';
import { ProgramsState } from './programs.types';

export const programsSelector = (state: RootState): ProgramsState => state.programs;

export const programSelector = createSelector(
  programsSelector,
  selectedVideoSelector,
  (programs, selectedVideo) => {
    const programsMap = programs.programsMap;
    const selectedVideoCid = selectedVideo?.cid;

    if (selectedVideoCid && programsMap && programsMap[selectedVideoCid]) {
      return programsMap[selectedVideoCid];
    }

    return null;
  },
);
