import styled from 'styled-components';

export const ImageWrapper = styled.a<{ $isClickable: boolean }>`
  position: relative;
  pointer-events: ${({ $isClickable }) => ($isClickable ? 'auto' : 'none')};
`;

export const Image = styled.img<{ $isVisible: boolean }>`
  transition: opacity 0.5s ease-out;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  aspect-ratio: auto;
  opacity: ${({ $isVisible }) => ($isVisible ? '1' : '0')};
  height: ${({ $isVisible }) => ($isVisible ? 'auto' : '0')};
`;
