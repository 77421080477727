import { debounce } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import { flpOnBuufferingStateChange } from '../../../api/playerApi/eventAdapters/flpOnBuufferingStateChange';
import { usePlayerContext } from '../../../hooks/quickplay/usePlayerContext';
import { usePlayerTimeContext } from '../PlayerTimeProvider/PlayerTimeProvider';

export const useSeekTime = () => {
  const { player } = usePlayerContext();
  const { setCurrentTime, seekValue, setSeekValue } = usePlayerTimeContext();

  const debounced = useMemo(
    () =>
      debounce((seek) => {
        player.seek(seek);
      }, 600),
    [player],
  );

  useEffect(() => {
    if (seekValue) {
      debounced(seekValue);
    }
  }, [seekValue]);

  useEffect(() => {
    return flpOnBuufferingStateChange(player, (bufferingState: string) => {
      if (seekValue && bufferingState === 'inactive') {
        setCurrentTime(seekValue);
        setSeekValue(undefined);
      }
    });
  }, [player, seekValue]);
};
