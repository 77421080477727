import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Channel } from '../../api';
import { ScheduleTabs } from '../../pages/Schedule/Schedule';
import { channelsSelector } from '../../store/channels';
import { zoneNameSelector } from '../../store/zoneInfo';
import { useUpdateSearchParams } from './useUpdateSearchParams';

export const useChannelSearchParam = ({
  searchParamName = 'channel',
}: { searchParamName?: string } = {}) => {
  const channels = useSelector(channelsSelector);
  const userZone = useSelector(zoneNameSelector);

  const [searchChannelId, updateSearchParams] = useUpdateSearchParams({ searchParamName });
  const { tab } = useParams();
  const channelsById = useMemo(
    () =>
      channels.reduce(
        (acc, channel) => ({ ...acc, [channel.id]: channel }),
        {} as { [key: string]: Channel },
      ),
    [channels],
  );

  useEffect(() => {
    if (!searchChannelId && channels?.length) {
      updateSearchParams(channels[0].id);
    }
  }, [searchChannelId]);

  const selectedChannel = useMemo(
    () => (searchChannelId ? channelsById[searchChannelId] : null),
    [channelsById, searchChannelId, userZone],
  );

  const selectChannel = useCallback(
    (channel?: Channel) => {
      updateSearchParams(channel?.id || null);
    },
    [updateSearchParams],
  );

  useEffect(() => {
    if (!selectedChannel && channels.length && tab === ScheduleTabs.CHANNELS) {
      selectChannel(channels[0]);
    }
  }, [selectedChannel, channels, selectChannel]);

  return { channels, channelsById, searchChannelId, selectChannel, selectedChannel };
};
