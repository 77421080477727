import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { FavoriteTeamsState } from './favoriteTeams.types';

export const initialState: FavoriteTeamsState = {
  favoriteTeams: [],
  loading: false,
};

export const loadFavoriteTeamsAction = createAction('favorite-teams/loadTeams');

const slice = createSlice({
  initialState: initialState,
  name: 'favoriteTeams',
  reducers: {
    loadFavoriteTeamsError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      favoriteTeams: [],
      loading: false,
    }),
    loadFavoriteTeamsLoading: (state) => ({
      ...state,
      error: undefined,
      loading: true,
    }),
    loadFavoriteTeamsSuccess: (state, action: PayloadAction<string[]>) => ({
      ...state,
      error: undefined,
      favoriteTeams: action.payload,
      loading: false,
    }),
    resetFavoriteTeams: (state) => ({
      ...state,
      favoriteTeams: [],
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as favoriteTeamsActions, name, reducer };
