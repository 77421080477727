import { createAsyncThunk } from '@reduxjs/toolkit';
import { startOfTomorrow } from 'date-fns';

import { quickplayCmsApi } from '../../api';
import { isCurrentDateBetween } from '../../helpers/date';
import { sortFromOldestToNewest } from '../../utils/date';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { loadProgramsAction, programsActions } from './programs.slice';

export const getProgram = createAsyncThunk(
  loadProgramsAction.type,
  async ({ channelId }: { channelId: string }, thunksapi) => {
    const {
      appConfig: {
        env: {
          GET_STOREFRONT_CHANNEL_AIRING,
          FORCE_ZONE_KEY,
          X_CLIENT_ID,
          GET_STOREFRONT_CHANNEL_EPG,
        },
      },
    } = thunksapi.getState() as RootState;

    const scheduleProgramFetch = (date: string) => {
      const timeToCurrentProgramEnd = new Date(date).getTime() - new Date().getTime();

      setTimeout(() => {
        thunksapi.dispatch(getProgram({ channelId }));
      }, timeToCurrentProgramEnd);
    };

    try {
      thunksapi.dispatch(programsActions.loadProgramsLoading());

      const programs = await quickplayCmsApi.getAiringLive({
        channelId,
        forceZoneKey: FORCE_ZONE_KEY,
        getStorefrontChannelAiring: GET_STOREFRONT_CHANNEL_AIRING,
        startDate: new Date(),
        xClientId: X_CLIENT_ID,
      });

      const program = programs.find(({ startDate, endDate }) =>
        isCurrentDateBetween(startDate, endDate),
      );

      if (program) {
        scheduleProgramFetch(program.endDate);
      }

      const programMap = program ? { [channelId]: program } : {};
      thunksapi.dispatch(programsActions.loadProgramsSuccess(programMap));
    } catch (error: unknown) {
      const EPG = await quickplayCmsApi.getEPG({
        channelId,
        forceZoneKey: FORCE_ZONE_KEY,
        getStoreFrontChannelEpg: GET_STOREFRONT_CHANNEL_EPG,
        startDate: new Date(),
        xClientId: X_CLIENT_ID,
      });

      const [upcomingProgram] = EPG.filter((item) => !item.isCurrentlyAiring).sort((a, b) =>
        sortFromOldestToNewest(a.startDate, b.startDate),
      );

      if (upcomingProgram) {
        scheduleProgramFetch(upcomingProgram.startDate);
      } else {
        scheduleProgramFetch(startOfTomorrow().toDateString());
      }

      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(programsActions.loadProgramsError(errorMessage));
    }
  },
);
