import { AxiosRequestConfig } from 'axios';

import { ErrorCodeValue } from '../../Constants/ErrorCodesTVEMap';
import {
  ActivationCodeResponse,
  ActivationCodeValidatedResponse,
  AddOrRemoveFavoriteResponse,
  AddOrUpdateCouchRightsResponseMessage,
  ChangeEmailAddress,
  ChangePasswordResponse,
  DisconnectTVEAccountResponse,
  FailureResponse,
  ForgotContactPasswordResponse,
  GetActiveSubscriptionsResponse,
  GetContactResponse,
  GetCouchRightsResponseMessage,
  GetEntitlementsResponse,
  GetFavoriteTeamsResponse,
  GetProductsResponse,
  GetProrateResponseMessage,
  GetStringResponse,
  GetSubscriptionHistoryResponse,
  GetVoucherCodeResponse,
  LogOutUserResponse,
  RefreshTokenResponse,
  RegisterDeviceResponse,
  RemoveSubscriptionResponse,
  ResetPasswordResponse,
  SearchAccountV2Response,
  SetStringResponse,
} from './authApi.types';

export type EvergentAuthApiBody = (
  | GetActiveSubscriptionsResponse
  | GetEntitlementsResponse
  | DisconnectTVEAccountResponse
  | ActivationCodeValidatedResponse
  | ActivationCodeResponse
  | GetContactResponse
  | GetProductsResponse
  | RegisterDeviceResponse
  | LogOutUserResponse
  | RemoveSubscriptionResponse
  | SearchAccountV2Response
  | ForgotContactPasswordResponse
  | ChangePasswordResponse
  | ResetPasswordResponse
  | GetSubscriptionHistoryResponse
  | GetFavoriteTeamsResponse
  | AddOrRemoveFavoriteResponse
  | SetStringResponse
  | GetStringResponse
  | ChangeEmailAddress
  | GetCouchRightsResponseMessage
  | AddOrUpdateCouchRightsResponseMessage
  | RefreshTokenResponse
  | GetVoucherCodeResponse
  | GetProrateResponseMessage
) &
  FailureResponse;

export type EvergentAuthApiResponse = Record<string, EvergentAuthApiBody>;

export interface AxiosRequestRetryConfig extends AxiosRequestConfig {
  _retry: boolean;
}

interface ErrorCause {
  errorCode: ErrorCodeValue;
}

export interface ErrorWithCodeInCause extends Error {
  cause: ErrorCause;
}

export const isErrorWithCodeInCause = (error: unknown): error is ErrorWithCodeInCause =>
  typeof error === 'object' &&
  error !== null &&
  'cause' in error &&
  typeof error.cause === 'object' &&
  error.cause !== null &&
  'errorCode' in error.cause;
