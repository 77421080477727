import { ChangePasswordResponse, FailureResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const changePassword = async ({
  accessToken,
  oldPassword,
  newPassword,
  confirmNewpassword,
  evergentApiKey,
  evergentChannelPartnerId,
  changePasswordUrl,
}: {
  accessToken: string;
  oldPassword: string;
  newPassword: string;
  confirmNewpassword: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  changePasswordUrl: string;
}): Promise<ChangePasswordResponse> => {
  const changePasswordData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    confirmNewpassword: confirmNewpassword,
    newPassword: newPassword,
    oldPassword: oldPassword,
  };

  const body = { ChangePasswordRequestMessage: changePasswordData };

  const response = await evergentAxiosInstance.post<{
    ChangePasswordResponseMessage: ChangePasswordResponse & FailureResponse;
  }>(changePasswordUrl, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = response.data.ChangePasswordResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
