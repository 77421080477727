import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GetProductsResponseItem, ProductServiceTypeEnum } from '../../api/authApi/authApi.types';
import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { videosSelector } from '../../store/videos';
import EvergentIframeComponent from '../EvergentIframe/EvergentIframeComponent';
import PurchaseIframeSVOD from './PurchaseIframeSVOD/PurchaseIframeSVOD';
import { PurchaseIframeTVOD } from './PurchaseIframeTVOD';

type PurchaseIframeProps = {
  product: GetProductsResponseItem;
  onError: (err: unknown) => void;
  onSuccess: () => void;
  zipCode: string;
  zoneKey: string | null;
  total?: number;
  couponCode?: string;
};

export const PurchaseIframe = ({
  product,
  onSuccess,
  onError,
  zipCode,
  zoneKey,
  total,
  couponCode,
}: PurchaseIframeProps) => {
  const { purchaseVideo } = useSelector(videosSelector);
  const isTVODPurchase = useMemo(
    () =>
      purchaseVideo?.productType === ProductServiceTypeEnum.TVOD &&
      product.serviceType === ProductServiceTypeEnum.TVOD,
    [purchaseVideo?.productType, product.serviceType],
  );
  const { serviceType, dmaName: productZoneKey } = product;
  const purchaseZoneKey = zoneKey || productZoneKey;

  const handleSuccess = () => {
    buttonClicked({
      destination: '/home',
      location: 'Module: Home',
      path: window.location.pathname,
      screen: 'home',
      text: 'CTA: Purchase Succesful',
      type: 'Button',
      url: window.location.href,
    });
    onSuccess();
  };

  return (
    <EvergentIframeComponent
      actionType='add'
      serviceType={serviceType}
      onSuccess={handleSuccess}
      onError={onError}
      zipCode={zipCode}
      total={total}
      couponCode={couponCode}
    >
      {!isTVODPurchase && <PurchaseIframeSVOD product={product} zoneKey={purchaseZoneKey} />}
      {isTVODPurchase && purchaseVideo && (
        <PurchaseIframeTVOD product={product} asset={purchaseVideo} zoneKey={purchaseZoneKey} />
      )}
    </EvergentIframeComponent>
  );
};

export default PurchaseIframe;
