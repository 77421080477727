import { useSelector } from 'react-redux';

import { Video } from '../../../api';
import { GetProductsResponseItem } from '../../../api/authApi/authApi.types';
import { usePurchaseToken } from '../../../hooks/evergent';
import { useOvatTokenSelector } from '../../../hooks/evergent/useOvatTokenSelector';
import { useFLPPlatformAuthorizer } from '../../../hooks/flp';
import { authSelector } from '../../../store/auth';
import { deviceInfoSelector } from '../../../store/deviceInfo';
import { PurchaseIframeBase } from '../PurchaseIframeBase';

type PurchaseIframeTVODProps = {
  product: GetProductsResponseItem;
  asset: Video;
  zoneKey: string;
};

export const PurchaseIframeTVOD = ({ product, asset, zoneKey }: PurchaseIframeTVODProps) => {
  const { accessToken } = useSelector(authSelector);

  const { deviceId } = useSelector(deviceInfoSelector);
  const platformAuthorizer = useFLPPlatformAuthorizer({ accessToken });
  const contentId = asset.id;
  const { ovatToken, isValid } = useOvatTokenSelector();

  const { purchaseToken } = usePurchaseToken({
    contentId,
    deviceId,
    ovatToken,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    platformAuthorizer,
    skip: !isValid,
  });

  return (
    <PurchaseIframeBase
      purchaseToken={purchaseToken}
      product={product}
      needPuschaseToken={true}
      zoneKey={zoneKey}
    />
  );
};

export default PurchaseIframeTVOD;
