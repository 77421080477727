import { useEffect } from 'react';

import { OTInteractionCookie } from '../../Constants/CookieNames';
import { ROUTES, RouteName } from '../../Constants/routes';
import { YourPrivacyChoices } from '../../assets/icons';
import { fillStringTemplate } from '../../helpers/fillStringTemplate';
import { showOTBanner } from '../../helpers/oneTrustBanner';
import { useTranslations } from '../../hooks/useTranslations';
import { getCookie } from '../../utils/handler-cookies';
import { useEnv } from '../EnvProvider/EnvProvider';
import { LEGAL_LINK } from '../LegalLink/LegalLink';
import * as Ui from './Footer.styles';

export const Footer = ({ noOneTrust }: { noOneTrust?: boolean }): JSX.Element => {
  const t = useTranslations();
  const isOTCookieSet = !!getCookie(OTInteractionCookie);
  const { ONETRUST_ENABLED } = useEnv();

  useEffect(() => {
    !isOTCookieSet && !noOneTrust && ONETRUST_ENABLED && showOTBanner();
  }, [noOneTrust]);

  return (
    <Ui.Wrapper data-cy='footer-wrapper'>
      <Ui.Content>
        <Ui.LinksWrapper>
          <Ui.Link to={ROUTES[RouteName.TermsOfUse]} data-cy='terms-of-use-link'>
            {t.footer_termsOfUse}
          </Ui.Link>
          <Ui.Link to={ROUTES[RouteName.PrivacyPolicy]} data-cy='privacy-policy-link'>
            {t.footer_privacyPolicy}
          </Ui.Link>
          <Ui.Link to={LEGAL_LINK.faqs} data-cy='faq-link'>
            {t.footer_faq}
          </Ui.Link>
          <Ui.Link
            to={`${ROUTES[RouteName.PrivacyPolicy]}?target=noticeCalifornia`}
            data-cy='privacy-policy-ca-link'
          >
            {t.footer_noticeToCAResidents}
          </Ui.Link>
          {ONETRUST_ENABLED ? (
            <Ui.LinkOneTrust
              id='ot-sdk-btn'
              className='ot-sdk-show-settings'
              onClick={showOTBanner}
              data-cy='one-trust'
            >
              <span className='icon'>
                <YourPrivacyChoices width={25} height={14} />
              </span>
              {t.footer_privacyChoices}
            </Ui.LinkOneTrust>
          ) : (
            <Ui.Link to={ROUTES[RouteName.PrivacyChoices]}>
              <span className='icon'>
                <YourPrivacyChoices width={25} height={14} />
              </span>
              {t.footer_privacyChoices}
            </Ui.Link>
          )}
        </Ui.LinksWrapper>
        <Ui.Copyrights data-cy='copyrights'>
          {fillStringTemplate(t.footer_copyright, {
            year: `${new Date().getFullYear()}`,
          })}
        </Ui.Copyrights>
      </Ui.Content>
    </Ui.Wrapper>
  );
};
