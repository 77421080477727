import { useMemo } from 'react';

import { Migartion } from '../../../api/remoteConfigApi/remoteConfigApi.types';
import { getImageResizeUrl } from '../../../helpers/imageResizeUrl';
import { navigateToExternal } from '../../../helpers/navigateToExternal';
import { useLogScreenViewedEvent } from '../../../hooks/useLogScreenViewedEvent';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Button } from '../../Button';
import { ButtonVariant } from '../../Button/Button.types';
import { Col, Row } from '../../Common';
import { InlineLink } from '../../InlineLink/InlineLink.styled';
import * as Ui from './MigrationModal.styles';

type Props = { onClose?: () => void } & Migartion;

export const MigrationModal = (props: Props) => {
  const isDesktop = useMediaQuery('lg');
  useLogScreenViewedEvent('migration-modal');
  const isFaqVisible = Boolean(props.faqText && props.faqUrl);
  const isCTAVisible = Boolean(props.url && props.goToURLText);

  const [faqTextPart1, faqTextPart2] = props.faqText?.split('{{ faqUrl }}') || [];

  const aspectRatio = `${props.imageType}-${
    (isDesktop ? props.imageProportionDefault : props.imageProportionMobile) ||
    props.imageProportionDefault
  }`;

  const mappedDescription = props.description?.replace('/n', '<br />');

  const backgroundUrl = useMemo(
    () =>
      getImageResizeUrl({
        assetId: props.imageID || '',
        format: props.usePng ? 'png' : 'jpg',
        size: aspectRatio,
        width: window.innerWidth,
      }),
    [props.imageID, props.usePng, aspectRatio],
  );

  return (
    <Ui.ModalBody {...props} backgroundUrl={backgroundUrl}>
      <Ui.Wrapper
        $mobileMinTopMargin={props.mobileMinTopMargin || 120}
        $defaultMinTopMargin={props.defaultMinTopMargin || 240}
      >
        <Ui.Container
          $mobileMinTopMargin={props.mobileMinTopMargin || 120}
          $defaultMinTopMargin={props.defaultMinTopMargin || 240}
        >
          <Row>
            <Col md={{ offset: 3, span: 6 }} className='p-0'>
              <Ui.ContentWrapper>
                {props.title && <Ui.Title>{props.title}</Ui.Title>}
                {props.description && (
                  <Ui.Description dangerouslySetInnerHTML={{ __html: mappedDescription || '' }} />
                )}
                {isFaqVisible && (
                  <Ui.Faq>
                    {faqTextPart1}
                    <InlineLink onClick={() => props.faqUrl && navigateToExternal(props.faqUrl)}>
                      {props.faqUrlText || props.faqUrl}
                    </InlineLink>
                    {faqTextPart2}
                  </Ui.Faq>
                )}
              </Ui.ContentWrapper>
            </Col>
          </Row>
          <Ui.ButtonRow>
            <Col md={{ offset: 4, span: 4 }} className='p-0'>
              <Ui.ButtonsWrapper>
                {isCTAVisible && (
                  <Button w100 onPress={() => props.url && navigateToExternal(props.url)}>
                    {props.goToURLText}
                  </Button>
                )}
                {props?.closeBtnText && (
                  <Button variant={ButtonVariant.Secondary} w100 onPress={props.onClose}>
                    {props.closeBtnText}
                  </Button>
                )}
              </Ui.ButtonsWrapper>
            </Col>
          </Ui.ButtonRow>
        </Ui.Container>
      </Ui.Wrapper>
    </Ui.ModalBody>
  );
};
