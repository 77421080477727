import { getEnv } from '../../../env';
import { AnonymizeAccountRequestMessage } from '../../../store/purchase';
import { AnonymyzeAccountResponseMessage } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const anonymizeAccount = async (
  anonymizeAccountRequestMessage: AnonymizeAccountRequestMessage,
): Promise<Partial<AnonymyzeAccountResponseMessage>> => {
  const { ANONYMIZE_ACCOUNT_URL, EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID } = getEnv();
  const { accessToken } = anonymizeAccountRequestMessage;

  const requestData = {
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
  };

  const body = { AnonymizeAccountRequestMessage: requestData };

  const anonymizeAccountResponse = await evergentAxiosInstance.post<{
    AnonymyzeAccountResponseMessage: AnonymyzeAccountResponseMessage;
  }>(ANONYMIZE_ACCOUNT_URL, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { failureMessage, ...dataResponse } =
    anonymizeAccountResponse.data.AnonymyzeAccountResponseMessage;

  if (failureMessage && failureMessage.length > 0) throw new Error(failureMessage[0].errorMessage);

  return dataResponse;
};
