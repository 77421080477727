import { Replay } from '../../../assets/icons';
import { getImageResizeUrl } from '../../../helpers/imageResizeUrl';
import useImageSafe from '../../../hooks/useImageSafe';
import { useTranslations } from '../../../hooks/useTranslations';
import { useVideos } from '../../../hooks/useVideos';
import { AspectRatioKeys } from '../../../store/videos';
import * as Ui from './ReplayButton.styles';

interface ReplayButtonProps {
  onClick: () => void;
}

export default function ReplayButton({ onClick }: ReplayButtonProps) {
  const t = useTranslations();
  const { selectedVideo } = useVideos();

  const videoImageUrl = selectedVideo?.id
    ? getImageResizeUrl({
        assetId: selectedVideo.id,
        format: 'jpg',
        size: AspectRatioKeys.A0_16x9,
        width: window.innerWidth,
      })
    : '';

  const { error: videoImageError } = useImageSafe(videoImageUrl);

  return (
    <Ui.ReplayButtonWrapper $backgroundImage={videoImageError ? undefined : videoImageUrl}>
      <Ui.ReplayButton aria-label={t.playVideoAgain} onClick={onClick}>
        <Replay />
      </Ui.ReplayButton>
    </Ui.ReplayButtonWrapper>
  );
}
