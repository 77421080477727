import { hoursToMilliseconds } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useEnv } from '../components/EnvProvider/EnvProvider';
import { isActiveSelector } from '../store/browser';
import { AppDispatch } from '../store/store';
import { timeOfLastLocationFetchSelector, zoneInfoLoad } from '../store/zoneInfo';
import { getDifferenceTimeInHours } from '../utils/getTimeDifferenceInHours';

// get current location every time user opens the app +
// when application comes back from the background and last get was more than 1h ago +
// at least one per 24h
export const useCurrentLocation = () => {
  const dispatch: AppDispatch = useDispatch();
  const isTabActive = useSelector(isActiveSelector);
  const timeOfLastLocationFetch = useSelector(timeOfLastLocationFetchSelector);
  const { NUMBER_OF_HOURS_LOCATION_IS_VALID } = useEnv();

  useEffect(() => {
    dispatch(zoneInfoLoad());

    const intervalId = setInterval(() => {
      dispatch(zoneInfoLoad());
    }, hoursToMilliseconds(24)); // 24h;

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isTabActive && timeOfLastLocationFetch) {
      const differenceInHours = getDifferenceTimeInHours(Date.now(), timeOfLastLocationFetch);

      if (differenceInHours > NUMBER_OF_HOURS_LOCATION_IS_VALID) {
        dispatch(zoneInfoLoad());
      }
    }
  }, [isTabActive, NUMBER_OF_HOURS_LOCATION_IS_VALID]);
};
