import { DeviceInfo, FailureResponse, LoginResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const login = async ({
  password,
  username,
  deviceInfo,
  evergentApiKey,
  evergentChannelPartnerId,
  loginUrl,
}: {
  password: string;
  username: string;
  deviceInfo: DeviceInfo;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  loginUrl: string;
}): Promise<LoginResponse> => {
  const deviceMessage = {
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    modelNo: deviceInfo.deviceModelNo,
    serialNo: deviceInfo.deviceId,
    userAgent: deviceInfo.deviceUserAgent,
  };

  const loginData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    contactPassword: password,
    contactUserName: username,
    deviceMessage,
    sessionExpiryPeriod: '',
  };

  const body = { GetOAuthAccessTokenv2RequestMessage: loginData };

  const response = await evergentAxiosInstance.post<{
    GetOAuthAccessTokenv2ResponseMessage: LoginResponse & FailureResponse;
  }>(loginUrl, body);

  const { failureMessage, ...dataResponse } = response.data.GetOAuthAccessTokenv2ResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(`${failureMessage[0].errorCode}-${failureMessage[0].errorMessage}`);
  }

  return dataResponse;
};
