import { TAuthFormFieldsAttribute, TAuthFormFieldsAttributes } from './AuthForm.types';

export const createInitialValues = (obj: TAuthFormFieldsAttributes): Record<string, string> => {
  const initialValues: Record<string, string> = {};
  for (const [key] of Object.entries(obj)) {
    initialValues[key] = obj[key].value || '';
  }
  return initialValues;
};
export const sortFormFields = (
  [, formFieldA]: [string, TAuthFormFieldsAttribute],
  [, formFieldB]: [string, TAuthFormFieldsAttribute],
) => formFieldA.order - formFieldB.order;
