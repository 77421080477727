import { createHeartbeatBuilder } from '@msgn/fl-module/fl-heartbeat';

import { AssetContentType } from '../cmsApi';
import { FLPlatformAsset, HeartbeatManager, PlatformAuthorizer } from './playerApi.types';

export const createHeartbeatManager = ({
  asset,
  deviceId,
  heartbeatToken,
  platformAuthorizer,
  heartbeatUrl,
  heartbeatMaxAllowedFailures,
  streamConcurrencyUrl,
  heartbeatIntervalSync,
}: {
  deviceId: string;
  asset: FLPlatformAsset;
  platformAuthorizer: PlatformAuthorizer;
  heartbeatToken: string;
  heartbeatUrl: string;
  heartbeatMaxAllowedFailures: number;
  streamConcurrencyUrl: string;
  heartbeatIntervalSync: number;
}): HeartbeatManager => {
  const heartbeatConfiguration = {
    heartbeatEndPointUrl: heartbeatUrl,
    maxAllowedFailures: heartbeatMaxAllowedFailures,
    multiStreamMode: true,
    streamConcurrencyEndPointUrl: streamConcurrencyUrl,
    syncIntervalMS: asset.heartbeatFreq || heartbeatIntervalSync,
  };
  const contentId = asset.contentId;
  const liveEventType = asset.contentType === AssetContentType.LIVEEVENT ? 'regular' : undefined;
  const liveStartTime =
    asset.contentType === AssetContentType.LIVEEVENT ? asset.liveStartTime : undefined;
  const liveEndTime =
    asset.contentType === AssetContentType.LIVEEVENT ? asset.liveEndTime : undefined;

  const heartbeatManager = createHeartbeatBuilder(
    deviceId,
    contentId,
    heartbeatConfiguration,
    platformAuthorizer,
  )
    .setHeartbeatToken(heartbeatToken)
    .setLiveEventType(liveEventType)
    .setLiveStartTime(liveStartTime)
    .setLiveEndTime(liveEndTime)
    .build();
  return heartbeatManager;
};
