import { FirebasePerformance, trace } from 'firebase/performance';
import { useState } from 'react';

import { PerformanceMeasure } from '../constants';
import { perf } from '../firebase';

export const useFirebasePerformanceTracer = (tracerName: PerformanceMeasure) => {
  const [isTracing, setIsTracing] = useState<boolean>(false);
  const [tracer, setTracer] = useState(trace(perf as FirebasePerformance, tracerName));

  const resetTracer = () => {
    setTracer(trace(perf as FirebasePerformance, tracerName));
    setIsTracing(false);
  };

  const startTracer = ({
    additionalCondition = true,
    omitInternalState = false,
  }: {
    additionalCondition?: boolean;
    omitInternalState?: boolean;
  } = {}) => {
    if (omitInternalState || (!isTracing && additionalCondition)) {
      tracer.start();

      setIsTracing(true);
    }
  };

  const stopTracer = ({
    withReset,
    additionalCondition = true,
    omitInternalState = false,
  }: {
    withReset?: boolean;
    additionalCondition?: boolean;
    omitInternalState?: boolean;
  } = {}) => {
    if (omitInternalState || (isTracing && additionalCondition)) {
      tracer.stop();
      if (withReset) {
        resetTracer();
      }
    }
  };

  return {
    startTracer,
    stopTracer,
    tracer,
  };
};
