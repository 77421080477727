import { SVGProps } from 'react';

export const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.50011 2.44455C5.43186 2.44455 2.94455 4.93186 2.94455 8.00011C2.94455 11.0684 5.43186 13.5557 8.50011 13.5557C11.5684 13.5557 14.0557 11.0684 14.0557 8.00011C14.0557 4.93186 11.5684 2.44455 8.50011 2.44455ZM2.05566 8.00011C2.05566 4.44094 4.94094 1.55566 8.50011 1.55566C12.0593 1.55566 14.9446 4.44094 14.9446 8.00011C14.9446 11.5593 12.0593 14.4446 8.50011 14.4446C4.94094 14.4446 2.05566 11.5593 2.05566 8.00011Z'
      fill={props?.fill ?? 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.50011 4.22217C8.74557 4.22217 8.94455 4.42115 8.94455 4.66661V7.44766C8.94455 7.68341 9.0382 7.9095 9.2049 8.0762L10.8144 9.68568C10.9879 9.85924 10.9879 10.1406 10.8144 10.3142C10.6408 10.4878 10.3594 10.4878 10.1858 10.3142L8.57636 8.70474C8.24297 8.37134 8.05566 7.91916 8.05566 7.44766V4.66661C8.05566 4.42115 8.25465 4.22217 8.50011 4.22217Z'
      fill={props?.fill ?? 'white'}
    />
  </svg>
);
