import { FlowOnStepChange, StepConfig } from './flow.types';
import { Step } from './step';

export class Flow {
  id: string;
  steps: Step[];
  currentStep: Step | undefined;
  currentIndex: number;

  previousStep: Step | undefined = undefined;
  previousIndex: number | undefined = undefined;

  constructor(id: string, steps: StepConfig[]) {
    this.id = id;
    this.steps = steps.map((config) => new Step(config));
    this.currentStep = this.steps[0];
    this.currentIndex = 0;
  }

  next(onChange?: FlowOnStepChange) {
    if (this.isLastStep()) {
      return;
    }

    this.setStep(this.currentIndex + 1);
    onChange?.(this.currentStep as Step, this.previousStep as Step);
  }

  previous(onChange?: FlowOnStepChange) {
    if (this.currentIndex === 0) {
      return;
    }

    this.setStep(this.currentIndex - 1);
    onChange?.(this.currentStep as Step, this.previousStep as Step);
  }

  goTo(pathname: string, onChange?: FlowOnStepChange) {
    const stepIndex = this.steps.findIndex((step) => step.pathname === pathname);

    if (stepIndex > -1) {
      this.setStep(stepIndex);
      onChange?.(this.currentStep as Step, this.previousStep as Step);
    }
  }

  exit() {
    this.currentStep = undefined;
    this.currentIndex = 0;
    this.steps = [];
    this.id = '';
  }

  isFlowStep(pathname: string) {
    return !!this.steps.find((step) => step.pathname === pathname);
  }

  isOneOfPreviousSteps(pathname: string) {
    const stepIndex = this.steps.findIndex((step) => step.pathname === pathname);
    return stepIndex > -1 && stepIndex < this.currentIndex;
  }

  isLastStep() {
    return this.steps.length - 1 === this.currentIndex;
  }

  private setStep(index: number) {
    this.previousStep = this.currentStep;
    this.previousIndex = this.currentIndex;

    this.currentIndex = index;
    this.currentStep = this.steps[index];
  }
}
