import { VelocityControl } from '../../hooks/flp/useFLPLocalVelocityControl';

export const buildCastVelocityControl = (): VelocityControl => {
  const adjustVelocity = (velocity: number) => {
    throw new Error(`cast device cannot adjust velocity to ${velocity}`);
  };

  const getVelocity = () => 1;

  const canAdjustVelocity = () => false;

  return { adjustVelocity, canAdjustVelocity, getVelocity };
};

export default buildCastVelocityControl;
