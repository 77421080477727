import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetProductsResponseItem } from '../api/authApi/authApi.types';
import { buttonClicked } from '../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { UpsellType } from '../api/remoteConfigApi/remoteConfigApi.types';
import { useSimpleModalContext } from '../components/Modals/SimpleModal/SimpleModalContext';
import { useModalContext } from '../components/Modals/context';
import { SelectPaymentModal } from '../components/PurchaseModal/SelectPaymentModal/SelectPaymentModal';
import { upSellSelector } from '../store/config';
import { selectProduct } from '../store/products';
import {
  availableProductsSelector,
  selectedProductSelector,
} from '../store/products/products.selectors';
import { isPayPerView } from '../store/products/products.utils';
import { AppDispatch } from '../store/store';
import { openSubscriptionModal } from './useSubscriptionNavigate';

type Args = {
  onSuccess: (product: GetProductsResponseItem) => void;
  onSkip: () => void;
  onGoBack?: () => void;
  applyCoupon?: boolean;
};

export const useProceedToPaymentSelection = ({
  onSuccess,
  onSkip,
  onGoBack,
  applyCoupon,
}: Args) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedProduct = useSelector(selectedProductSelector);
  const upSellsOptions = useSelector(upSellSelector);
  const products = useSelector(availableProductsSelector);

  const { openModal: openSimpleModal } = useSimpleModalContext();

  const { openModal } = useModalContext();

  const goToSelectPaymentPage = useCallback(
    (product?: GetProductsResponseItem) => {
      if (selectedProduct) {
        openSubscriptionModal({
          content: (
            <SelectPaymentModal
              product={product || selectedProduct}
              onSuccess={onSuccess}
              onSkip={onSkip}
              passedOnGoBack={onGoBack}
              applyCoupon={applyCoupon}
            />
          ),
          openModal,
        });
      }
    },
    [selectedProduct, onSuccess, onSkip, onGoBack, applyCoupon],
  );

  const handleProceedToPaymentSelection = useCallback(() => {
    if (!selectedProduct) {
      return;
    }
    buttonClicked({
      destination: '/home',
      location: 'Module: Subscription Plan',
      path: window.location.pathname,
      screen: 'Subscription Plan',
      text: `CTA: ${selectedProduct.displayName} Purchase Plan Selected`,
      type: 'Button',
      url: window.location.href,
    });

    if (isPayPerView(selectedProduct) && upSellsOptions.length) {
      if (upSellsOptions.length === 1) {
        const [selectedUpSell] = upSellsOptions;

        if (selectedUpSell.id === UpsellType.PPG_UPSELL_A) {
          const preselectedOption = products.filter(
            (product) => product.ovpSKU === selectedUpSell.ctaAction.preselectedOption,
          )[0];

          if (preselectedOption) {
            return openSimpleModal({
              acceptButton: {
                destination: 'Select payment',
                onClick: () => {
                  dispatch(selectProduct(preselectedOption));
                  goToSelectPaymentPage(preselectedOption);
                },
                text: selectedUpSell.ctaLabel,
              },
              cancelButton: {
                onClick: goToSelectPaymentPage,
                text: selectedUpSell.ctaContinueLabel,
              },
              description: selectedUpSell.description,
              modalName: 'Upsell',
              title: selectedUpSell.title,
            });
          }
          return goToSelectPaymentPage();
        }
      } else {
        // placeholder for choosing correct upsell (A/B testing)
      }
    }
    goToSelectPaymentPage();
  }, [selectedProduct, upSellsOptions, openSimpleModal, goToSelectPaymentPage]);

  return handleProceedToPaymentSelection;
};
