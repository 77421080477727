import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';

import { Footer } from '../Footer/Footer';
import { Navigation } from '../Navigation/Navigation';
import RegionValidationWeb from '../RegionValidation/RegionValidation.web';
import { useCheckTVEProviderResult } from '../TVEProviderComponents/useCheckTVEProviderResult';
import * as Ui from './Layout.styles';

interface Props extends PropsWithChildren {
  noFooter?: boolean;
  noNavbar?: boolean;
  noZipBar?: boolean;
  noFooterOneTrust?: boolean;
  noContentPadding?: boolean;
  noFooterVhGrowth?: boolean;
}

export const Layout = ({
  children,
  noContentPadding,
  noFooter,
  noNavbar,
  noZipBar,
  noFooterOneTrust,
}: Props) => {
  const { isZipBarVisible } = useTheme();
  useCheckTVEProviderResult();

  return (
    <>
      <Ui.Root $isZipBarVisible={!noZipBar && !!isZipBarVisible} $noFooter={noFooter}>
        {!noNavbar && <Navigation />}
        <Ui.Main $noNavPadding={noNavbar} $noContentPadding={noContentPadding}>
          {children}
        </Ui.Main>
        {!noFooter && <Footer noOneTrust={noFooterOneTrust} />}
      </Ui.Root>

      <RegionValidationWeb />
    </>
  );
};
