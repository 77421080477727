import { useEffect, useState } from 'react';

import { Log } from '../../helpers/CustomLogger';

interface Props {
  src: string;
  alt: string;
}

export const DynamicImage = ({ src, alt, ...rest }: Props) => {
  const [imageSrc, setImageSrc] = useState<string>(src ?? '');

  useEffect(() => {
    if (src.startsWith('http')) {
      return;
    }

    import(`../../assets/images/${src}`)
      .then((module) => setImageSrc(module.default))
      .catch((error) => Log.error(`Error loading image ${src}:`, error));
  }, [src]);

  return imageSrc ? <img src={imageSrc} alt={alt} {...rest} /> : null;
};
