import { useDispatch } from 'react-redux';

import { ROUTES, RouteName } from '../../../Constants/routes';
import { buttonClicked } from '../../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { ErrorComponent, IconType } from '../../../components/ErrorComponent/ErrorComponent';
import { Loader } from '../../../components/Loader/Loader';
import { Loading } from '../../../components/Loading';
import { useExtendedNavigate } from '../../../hooks/useExtendedNavigate';
import { useGeolocationError } from '../../../hooks/useGeolocationError';
import { useTranslations } from '../../../hooks/useTranslations';
import { AppDispatch } from '../../../store/store';
import { zoneInfoLoad } from '../../../store/zoneInfo';
import { getScreenName } from '../../../utils/getScreenName';
import * as Ui from './GeolocationError.styles';
import GeolocationErrorDescription from './GeolocationErrorDescription/GeolocationErrorDescription';

export const GeolocationError = () => {
  const navigate = useExtendedNavigate();
  const dispatch: AppDispatch = useDispatch();
  const t = useTranslations();

  const { error, loading, loaded } = useGeolocationError(false);

  if (!error && !loading) {
    navigate(ROUTES[RouteName.Home]);
    return null;
  }

  if (loading && !error && !loaded) {
    return <Loader />;
  }
  return (
    <Ui.ContentContainer>
      <ErrorComponent
        error={t.geolocationErrorModal_title}
        description={<GeolocationErrorDescription />}
        action={() => {
          buttonClicked({
            location: 'Error: Geolocation',
            path: window.location.pathname,
            screen: getScreenName(),
            text: `CTA: ${t.retryModal_retryButton}`,
            type: 'Button',
            url: window.location.href,
          });
          dispatch(zoneInfoLoad());
        }}
        icon={IconType.ErrorX}
        buttonText={loading ? <Loading size='sm' /> : t.retryModal_retryButton}
      />
    </Ui.ContentContainer>
  );
};
