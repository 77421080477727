import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { videoCompleted } from '../../../api/mParticlesApi/mParticleEvents/video/videoEvents';
import { usePlayerProgressUpdate } from '../../../hooks/quickplay/usePlayerProgressUpdate';
import { selectedVideoSelector } from '../../../store/videos';
import { usePlayerTimeContext } from '../PlayerTimeProvider/PlayerTimeProvider';

const VIDEO_COMPLETED_TIME = 90 / 100;

export const useVideoComplete = () => {
  const selectedVideo = useSelector(selectedVideoSelector);
  const { currentTime } = usePlayerTimeContext();
  const videoCompletedEventSent = useRef(false);

  const onTimeUpdate = useCallback(
    (time: number, duration: number) => {
      const isVideoCompleted = time > duration * VIDEO_COMPLETED_TIME;

      // send videoCompleted event to mParticle when video is reached 90% of the way through
      if (isVideoCompleted && !videoCompletedEventSent.current) {
        videoCompleted(selectedVideo, currentTime);
        videoCompletedEventSent.current = true;
      }
    },
    [selectedVideo],
  );

  usePlayerProgressUpdate(onTimeUpdate);
};
