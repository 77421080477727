import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ErrorMessageState } from './serverError.types';

export const initialState: ErrorMessageState = {
  errorMessage: null,
  hasErrors: false,
  isLoading: false,
  tryAgain: null,
};

const slice = createSlice({
  initialState,
  name: 'serverError',
  reducers: {
    clearGlobalError: (state) => ({
      ...state,
      errorMessage: null,
      hasErrors: false,
      isLoading: true,
      tryAgain: null,
    }),
    setGlobalError: (
      state,
      action: PayloadAction<{ errorMessage: string; tryAgain: ErrorMessageState['tryAgain'] }>,
    ) => ({
      ...state,
      errorMessage: action.payload.errorMessage,
      hasErrors: true,
      isLoading: false,
      tryAgain: action.payload.tryAgain,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as serverErrorActions, name, reducer };
