import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';

import { PlayerTrack, TrackType } from '../../../api/playerApi/playerApi.types';
import useKeyPressHandler from '../../../hooks/useKeyPressHandler';
import { KEY_CODES } from '../../../utils/keyCodes';
import * as Ui from './ClosedCaptionsModal.styles';

const GroupsNames = {
  [TrackType.AUDIO]: 'Audios',
  [TrackType.TEXT]: 'Subtitles',
};

export type ClosedCaptionsModalProps = {
  show?: boolean;
  onHide: () => void;
  onSelectTrack: (track: PlayerTrack) => void;
  tracks: PlayerTrack[];
  activeTrack: PlayerTrack;
};

export const ClosedCaptionsModal = ({
  show = false,
  onHide,
  onSelectTrack,
  tracks,
  activeTrack,
}: ClosedCaptionsModalProps) => {
  const onKeyPress = useCallback(
    ({ keyCode }: { keyCode: number }) => {
      if (keyCode === KEY_CODES.BACK) {
        onHide();
      }
    },
    [onHide],
  );

  useKeyPressHandler({ onKeyPress });

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={true}
      backdrop='static'
      // classes from src/components/GlobalStyle/GlobalStyle.tsx
      backdropClassName='closedCaptionBackdrop'
      contentClassName='closedCaptionModalContent'
      dialogClassName='closedCaptionModalDialog'
    >
      <Modal.Body className='border-0 px-5 pt-5 pb-3'>
        {tracks.length > 0 && (
          <div className='row'>
            <div className='col-6'>
              <ClosedCaptionGroup
                tracks={tracks}
                groupName={GroupsNames[TrackType.TEXT]}
                activeTrack={activeTrack}
                onSelectTrack={onSelectTrack}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

type ClosedCaptionGroupProps = {
  groupName: string;
  tracks: PlayerTrack[];
  activeTrack?: PlayerTrack;
  onSelectTrack: (track: PlayerTrack) => void;
};

const ClosedCaptionGroup = ({
  groupName,
  tracks,
  activeTrack,
  onSelectTrack,
}: ClosedCaptionGroupProps) => {
  return (
    <>
      <Ui.TrackTypeName className='text-center'>{groupName}</Ui.TrackTypeName>
      <div className='py-1'>
        {tracks.map((track, idx) => (
          <ClosedCaptionItem
            key={idx}
            track={track}
            selected={activeTrack?.language === track.language}
            onSelectTrack={onSelectTrack}
          />
        ))}
      </div>
    </>
  );
};

type ClosedCaptionItemProps = {
  onSelectTrack: (track: PlayerTrack) => void;
  track: PlayerTrack;
  selected: boolean;
};

const ClosedCaptionItem = ({ track, selected, onSelectTrack }: ClosedCaptionItemProps) => {
  const onEnterPress = useCallback(() => {
    onSelectTrack(track);
  }, [onSelectTrack, track]);

  return (
    <Ui.TrackName
      onClick={onEnterPress}
      className='py-3 d-flex align-items-center'
      selected={selected}
    >
      <span>{track.language}</span>
    </Ui.TrackName>
  );
};

export default ClosedCaptionsModal;
