import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { noScrollbarsForOverflowElements } from '../../../../utils/styled/mixins';
import { ZIP_CODE_FORM_HEIGHT_MOBILE } from '../../../ZipCodeForm/ZipCodeForm.styles';
import { NAVIGATION_HEIGHT } from '../../Navigation.styles';

export const Sidebar = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: ${({ theme }) =>
    theme.isZipBarVisible ? NAVIGATION_HEIGHT + ZIP_CODE_FORM_HEIGHT_MOBILE : NAVIGATION_HEIGHT}px;

  height: ${({ theme }) =>
    theme.isZipBarVisible
      ? `calc(100vh - calc(${NAVIGATION_HEIGHT}px + ${ZIP_CODE_FORM_HEIGHT_MOBILE}px))`
      : `calc(100vh - ${NAVIGATION_HEIGHT}px)`};

  left: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
  width: ${({ $isOpen }) => ($isOpen ? '18.125rem' : '0')};
  padding: 0.5rem 2rem 2rem 2rem;

  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: ${({ theme }) => theme.zIndex.navigationSidebar};
  overflow-y: scroll;
  ${noScrollbarsForOverflowElements}
  ${breakpoint('lg')`
	  display: none;
  `}
`;
