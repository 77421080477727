import { Col as BsCol, ColProps as BsColProps, Row as BsRow } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { flexCenter as flexCenterMixin } from '../../utils/styled/mixins';

export const GUTTER_X_XS = '1rem';
export const GUTTER_X_MD = '1.25rem';
export const GUTTER_X_XL = '1.25rem';

export const CONTAINER_MARGIN_X_XS = '1rem';
export const CONTAINER_MARGIN_X_MD = '2rem';
export const CONTAINER_MARGIN_X_XL = '7.5rem';

export type ContainerProps = {
  $minSvh100?: boolean;
  $flex?: boolean;
  $flex1?: boolean;
  $flexCenter?: boolean;
};

export const container = css<ContainerProps>`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-right: 0;
  padding-left: 0;
  max-width: calc(100% - 2 * ${CONTAINER_MARGIN_X_XS});

  ${({ $flex }) => $flex && 'display: flex;'}
  ${({ $flex1 }) => $flex1 && 'flex: 1;'}
  ${({ $flexCenter }) => $flexCenter && flexCenterMixin}

  ${({ $minSvh100 }) =>
    $minSvh100 &&
    `
      min-height: 100vh;
      min-height: 100svh;
    `}

  ${breakpoint('md')`
    max-width: calc(100% - 2 * ${CONTAINER_MARGIN_X_MD});
  `}

  ${breakpoint('xl')`
    max-width: calc(100% - 2 * ${CONTAINER_MARGIN_X_XL});
  `}
`;

export const Container = styled.div<ContainerProps>`
  ${container}
`;

type RowProps = {
  $flex?: boolean;
  $justifyCenter?: boolean;
};

export const Row = styled(BsRow)<RowProps>`
  width: 100%;
  --bs-gutter-x: ${GUTTER_X_XS};

  ${({ $flex }) => $flex && 'display: flex;'}
  ${({ $justifyCenter }) => $justifyCenter && 'justify-content: center;'}

  ${breakpoint('xl')`
    --bs-gutter-x: ${GUTTER_X_XL}
  `};
`;

// Types from Bootstrap node_modules/react-bootstrap/esm/Col.d.ts
// which are not exported, unfortunately
type NumberAttr = number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
type ColOrderNumber = number | '1' | '2' | '3' | '4' | '5';
type ColOrder = ColOrderNumber | 'first' | 'last';
type ColSize = boolean | 'auto' | NumberAttr;
export type ColSpec =
  | ColSize
  | {
      span?: ColSize;
      offset?: NumberAttr;
      order?: ColOrder;
    };

interface ColProps extends BsColProps {
  fullHD?: ColSpec;
  ultraHD?: ColSpec;
  $textCenter?: boolean;
}

export const Col = styled(BsCol)<ColProps>`
  ${({ $textCenter }) => $textCenter && 'text-align: center;'}
`;
