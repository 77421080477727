import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ToastPayload, ToastState } from './toast.types';

export const initialState: ToastState = {
  className: null,
  delay: 3000,
  message: '',
  position: 'top-end',
  show: false,
  type: '',
};

const slice = createSlice({
  initialState,
  name: 'toast',
  reducers: {
    hideToast: () => ({
      ...initialState,
    }),
    showToast: (state, action: PayloadAction<Partial<ToastPayload>>) => ({
      ...state,
      ...initialState,
      ...action.payload,
      show: true,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as toastActions, name, reducer };
