import { closestTo, isDate, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';

import { useDaysFilter } from '../../../../hooks/useDaysFilter';
import useGenericCarousel from '../../../GenericCarousel/useGenericCarousel';
import { Selector } from '../../../Selector/Selector';
import { SelectorSize } from '../../../Selector/Selector.types';
import { DaysFilterCarousel } from './DaysFilter.styles';

export const DaysFilter = ({
  date,
  onChangeDate,
}: {
  date: Date;
  onChangeDate: (date: Date) => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(date);
  const { daysFilter } = useDaysFilter();
  const { props: carouselProps } = useGenericCarousel();
  const selectDate = (date: Date) => {
    setSelectedDate(date);
    onChangeDate(date);
  };

  useEffect(() => {
    if (date && isDate(date)) {
      const closestDate = closestTo(
        date,
        daysFilter.map((d) => d.value),
      );
      setSelectedDate(closestDate as Date);
    }
  }, [date]);

  return (
    <DaysFilterCarousel {...carouselProps}>
      {daysFilter.map((date) => (
        <Selector
          key={date.label}
          size={SelectorSize.Small}
          selected={isSameDay(date.value, selectedDate)}
          onClick={() => selectDate(date.value)}
        >
          {date.label}
        </Selector>
      ))}
    </DaysFilterCarousel>
  );
};
