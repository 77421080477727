import axios from 'axios';


import { cacheManager } from '../../../utils/cache';
import evergentAuthApi from '../EvergentAuthApi';
import { AdobeAccessTokenResponse } from '../authApi.types';
import {
  ADOBE_AUTHORIZATION_RESPONSE,
  ADOBE_AUTHORIZATION_TOKEN,
  ADOBE_TOKENS_AUTHZ_RESPONSE,
  AdobeAuthorizationResponse,
  AdobeTokensAuthzResponse,
  GetMediaShortTokenResponse,
} from './adobeApi.types';
import { needToRefresh } from './adobeApi.helpers';

export const adobeTveApi = {
  async authorize({
    adobeAccessToken,
    deviceId,
    tveAdobeRequestor,
    tveAdobeResource,
    adobeAuthorizeUrl,
  }: {
    adobeAccessToken: string;
    deviceId: string;
    tveAdobeRequestor: string;
    tveAdobeResource: string;
    adobeAuthorizeUrl: string;
  }): Promise<AdobeAuthorizationResponse> {
    const headers = {
      Authorization: `Bearer ${adobeAccessToken}`,
    };
    const params = {
      deviceId: deviceId,
      requestor: tveAdobeRequestor,
      resource: tveAdobeResource,
    };

    const response = await axios.get<AdobeAuthorizationResponse>(adobeAuthorizeUrl, {
      headers,
      params,
    });

    return response.data;
  },

  // ADOBE_GET_MEDIA_SHORT_TOKEN_URL
  async getMediaShortTokens({
    adobeAccessToken,
    deviceId,
    tveAdobeRequestor,
    tveAdobeResource,
    adobeGetMediaShortTokenUrl,
  }: {
    adobeAccessToken: string;
    deviceId: string;
    tveAdobeRequestor: string;
    tveAdobeResource: string;
    adobeGetMediaShortTokenUrl: string;
  }): Promise<GetMediaShortTokenResponse> {
    const headers = {
      Authorization: `Bearer ${adobeAccessToken}`,
    };
    const params = {
      deviceId: deviceId,
      requestor: tveAdobeRequestor,
      resource: tveAdobeResource,
    };

    const response = await axios.get<GetMediaShortTokenResponse>(adobeGetMediaShortTokenUrl, {
      headers,
      params,
    });

    return response.data;
  },
  // ADOBE_TOKENS_AUTHZ_URL
  async tokensAuthz({
    adobeAccessToken,
    deviceId,
    tveAdobeRequestor,
    tveAdobeResource,
    adobeTokensAuthzUrl,
  }: {
    adobeAccessToken: string;
    deviceId: string;
    tveAdobeRequestor: string;
    tveAdobeResource: string;
    adobeTokensAuthzUrl: string;
  }): Promise<AdobeTokensAuthzResponse> {
    const headers = {
      Authorization: `Bearer ${adobeAccessToken}`,
    };
    const params = {
      deviceId: deviceId,
      requestor: tveAdobeRequestor,
      resource: tveAdobeResource,
    };

    const response = await axios.get<AdobeTokensAuthzResponse>(adobeTokensAuthzUrl, {
      headers,
      params,
    });

    return response.data;
  },
};

export const getAdobeToken = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  getAdobeAccessTokenUrl,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getAdobeAccessTokenUrl: string;
}) => {
  let adobeToken = cacheManager.load<AdobeAccessTokenResponse>(ADOBE_AUTHORIZATION_TOKEN);

  if (needToRefresh(adobeToken?.expiresIn)) {
    adobeToken = await evergentAuthApi.getAdobeAccessToken({
      accessToken,
      evergentApiKey,
      evergentChannelPartnerId,
      getAdobeAccessTokenUrl,
    });
    cacheManager.save(ADOBE_AUTHORIZATION_TOKEN, adobeToken);
  }

  const adobeAccessToken = adobeToken?.adobeAccessToken as string;

  return adobeAccessToken;
};

export const checkAdobeAuthorizationAndGetMediaShortToken = async ({
  adobeAccessToken,
  deviceId,
  tveAdobeRequestor,
  tveAdobeResource,
  adobeGetMediaShortTokenUrl,
  adobeTokensAuthzUrl,
  adobeAuthorizeUrl,
}: {
  adobeAccessToken: string;
  deviceId: string;
  tveAdobeRequestor: string;
  tveAdobeResource: string;
  adobeGetMediaShortTokenUrl: string;
  adobeTokensAuthzUrl: string;
  adobeAuthorizeUrl: string;
}) => {
  let adobeAuthorizationResponse = cacheManager.load<AdobeAuthorizationResponse>(
    ADOBE_AUTHORIZATION_RESPONSE,
  );
  let adobeTokensAuthzResponse = cacheManager.load<AdobeTokensAuthzResponse>(
    ADOBE_TOKENS_AUTHZ_RESPONSE,
  );

  if (needToRefresh(adobeAuthorizationResponse?.expires)) {
    adobeAuthorizationResponse = await adobeTveApi.authorize({
      adobeAccessToken,
      adobeAuthorizeUrl,
      deviceId,
      tveAdobeRequestor,
      tveAdobeResource,
    });
    cacheManager.save(ADOBE_AUTHORIZATION_RESPONSE, adobeAuthorizationResponse);
  }

  if (needToRefresh(adobeTokensAuthzResponse?.expires)) {
    adobeTokensAuthzResponse = await adobeTveApi.tokensAuthz({
      adobeAccessToken,
      adobeTokensAuthzUrl,
      deviceId,
      tveAdobeRequestor,
      tveAdobeResource,
    });
    cacheManager.save(ADOBE_TOKENS_AUTHZ_RESPONSE, adobeTokensAuthzResponse);
  }

  const mediaShortToken = await adobeTveApi.getMediaShortTokens({
    adobeAccessToken,
    adobeGetMediaShortTokenUrl,
    deviceId,
    tveAdobeRequestor,
    tveAdobeResource,
  });

  return mediaShortToken;
};

