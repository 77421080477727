import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useGetCurrentPathPattern } from '../../hooks/useGetCurrentPathPattern';
import { LogoutType, useLogoutAction } from '../../hooks/useLogoutAction';
import { routesConfig } from '../../routes/routesConfig';
import { AUTH_SESSION_STORAGE_KEY, authActions, authSelector } from '../../store/auth';
import { AppDispatch } from '../../store/store';
import { cacheManager } from '../../utils';

export const useLogoutWhenRefreshTokenFailed = () => {
  const logout = useLogoutAction();
  const navigate = useExtendedNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { logoutUser } = useSelector(authSelector);

  const pathPattern = useGetCurrentPathPattern();
  const { isPrivate } = routesConfig[pathPattern] || {};

  useEffect(() => {
    if (logoutUser) {
      logout(LogoutType.JUST_CLEAR_DATA);
      dispatch(authActions.logoutSuccess());
      dispatch(authActions.setLogoutUser(false));
      cacheManager.remove(AUTH_SESSION_STORAGE_KEY);

      if (isPrivate) {
        navigate('/welcome');
      }
    }
  }, [logoutUser]);
};
