import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { DebugState } from './debug.types';

export const configAppAction = createAction('config/changeConfig');

export const initialState: DebugState = {
  enhancedMParticleLogging: false,
};

const slice = createSlice({
  initialState,
  name: 'debug',
  reducers: {
    setEnhancedMParticleLogging: (state: DebugState, action: PayloadAction<boolean>) => ({
      ...state,
      enhancedMParticleLogging: action.payload,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as debugActions, name, reducer };
