import { useCallback, useMemo } from 'react';

import { PlayerHandler } from '../../api';

export type VelocityControl = {
  adjustVelocity: (volume: number) => void;
  getVelocity: () => number;
  canAdjustVelocity: () => boolean;
};

export const useFLPVelocityControl = ({ player }: { player: PlayerHandler }): VelocityControl => {
  const adjustVelocity = useCallback(
    (newVelocity: number) => {
      player.playbackRate = newVelocity;
    },
    [player],
  );

  const getVelocity = useCallback(() => player.playbackRate, [player]);

  const canAdjustVelocity = useCallback(() => true, []);

  const velocityControl: VelocityControl = useMemo(
    () => ({ adjustVelocity, canAdjustVelocity, getVelocity }),
    [adjustVelocity, canAdjustVelocity, getVelocity],
  );

  return velocityControl;
};

export default useFLPVelocityControl;
