import json from '../../../package.json';
import { envName } from '../../env';
import { StyledVersion } from './Version.styled';

const Version = () => {
  return envName === 'dev' || envName === 'stage' || envName === 'preprod' ? (
    <StyledVersion>
      {envName}: v.{json.version}
    </StyledVersion>
  ) : null;
};

export { Version };
