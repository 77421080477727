import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { Button } from '../Button/Button';
import { FONT_WEIGHT } from '../Common';
import { Input } from '../atoms';

export const ZIP_CODE_FORM_HEIGHT_DESKTOP = 83;
export const ZIP_CODE_FORM_HEIGHT_MOBILE = 191;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors['grey-dark']};
  padding: 1rem 1.5rem;
  gap: 0.75rem;
  border-radius: 4px;
  max-width: 69.56rem;
  height: ${ZIP_CODE_FORM_HEIGHT_MOBILE - 1}px; // -1 for border which is outside
  flex: 1;
  margin: 0 auto;

  ${breakpoint('lg')`
    flex-direction: row;
    align-items: center;
    height: ${ZIP_CODE_FORM_HEIGHT_DESKTOP - 1}px; // -1 for border which is outside
  `}
`;

export const ZipCodeMessage = styled.div`
  color: ${({ theme }) => theme.colors['off-white']};
  font-family: 'GothamNarrow';
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.125rem;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  width: 100%;

  ${breakpoint('lg')`
	  flex-direction: row;
  `}
`;

export const ZipCodeInput = styled(Input)`
  width: 100%;
  height: 3.25rem;
  ${breakpoint('lg')`
  	width: 326px;
  `}
`;

export const SubmitButton = styled(Button)`
  width: 100%;

  ${breakpoint('lg')`
  	width: auto;
  `}
`;
