import { emailRegX } from '../utils/validations';

export const isEmail = (email: string): boolean => {
  return emailRegX.test(email.trim());
};

export const maskEmail = (email: string): string => {
  if (!isEmail(email)) {
    return email;
  }

  const atIndex = email.indexOf('@');
  const localPart = email.substring(0, atIndex);

  if (localPart.length < 2) {
    return email;
  }

  const maskedLocalPart =
    localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
  const maskedEmail = maskedLocalPart + email.substring(atIndex);

  return maskedEmail;
};
