import { getEnv } from '../../../env';
import { AddTVODOrderRequestMessage } from '../../../store/purchase';
import { AddTVODOrderResponseMessage } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const addTVODOrder = async (
  addTVODOrderRequestMessage: AddTVODOrderRequestMessage,
): Promise<Partial<AddTVODOrderResponseMessage>> => {
  const { ADD_TVOD_ORDER, EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID } = getEnv();
  const { accessToken, ...payload } = addTVODOrderRequestMessage;
  const requestData = {
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
    ...payload,
  };

  const body = { AddTVODOrderRequestMessage: requestData };

  const addTVODOrderResponse = await evergentAxiosInstance.post<{
    AddTVODOrderResponseMessage: AddTVODOrderResponseMessage;
  }>(ADD_TVOD_ORDER, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { failureMessage, ...dataResponse } = addTVODOrderResponse.data.AddTVODOrderResponseMessage;

  if (failureMessage && failureMessage.length > 0) throw new Error(failureMessage[0].errorMessage);

  return dataResponse;
};
