import { ALL } from '../../../../Constants/TeamSelector';
import { TeamData } from '../../../../api';
import useTeamsListState from '../../../../hooks/quickplay/useTeamsListState';
import { useTranslations } from '../../../../hooks/useTranslations';
import { getAllTeamItemData } from '../../../../utils/allTeamItemData';
import { GenericCarousel } from '../../../GenericCarousel/GenericCarousel';
import useGenericCarousel from '../../../GenericCarousel/useGenericCarousel';
import { Selector } from '../../../Selector/Selector';
import { SelectorSize } from '../../../Selector/Selector.types';
import { TeamFilterItem } from '../TeamFilterItem';

export const TeamsFilter = ({
  onTeamChange,
  selectedTeamId,
}: {
  onTeamChange: (team: TeamData) => void;
  selectedTeamId: string | undefined;
}) => {
  const t = useTranslations();
  const { props: carouselProps } = useGenericCarousel();
  const { teams } = useTeamsListState();
  const currentTeamId = selectedTeamId || ALL;

  const isSelected = (teamId: string) => teamId === currentTeamId;

  return (
    <GenericCarousel {...carouselProps}>
      <Selector
        size={SelectorSize.Medium}
        selected={isSelected(ALL)}
        onClick={() => onTeamChange(getAllTeamItemData(t))}
      >
        {t.schedule_allTeamsButton}
      </Selector>
      {teams.map((teamData) => (
        <TeamFilterItem
          key={teamData.teamId}
          team={teamData}
          selected={isSelected(teamData.id)}
          onClick={() => onTeamChange(teamData)}
        />
      ))}
    </GenericCarousel>
  );
};
