import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ContainerType,
  StorefrontContainer,
  Video,
  parseStorefrontContainer,
  parseVideoRaw,
  quickplayCmsApi,
} from '../../api';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import {
  addOrUpdateCouchRights,
  areCouchRightsValidSelector,
  couchRightsActions,
} from '../couchRights';
import { setPlayerError } from '../player/player.thunks';
import { RootState } from '../types';
import { isTeamContainersParams } from './utils/isTeamContainersParams';
import { getVideosFromPromise } from './utils/utils';
import {
  displayVideoAction,
  loadAllVideosAction,
  loadVideoById,
  loadVideosAction,
  videosActions,
} from './videos.slice';
import { Container } from './videos.types';

export interface TeamContainersParams {
  teamKey: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface ContainersParams {
  pageNumber?: number;
  pageSize?: number;
  storefrontId: string;
  tabId: string;
}

const getContainers = async (
  params: ContainersParams | TeamContainersParams,
  options: {
    forceZoneKey: string;
    xClientId: string;
    teamsVideosUrl: string;
    getStorefrontTabContainerEnv: string;
  },
): Promise<StorefrontContainer[]> => {
  const finalParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== null && value !== undefined),
  );

  if (isTeamContainersParams(finalParams)) {
    return quickplayCmsApi.getTeamsContainer({
      ...finalParams,
      forceZoneKey: options.forceZoneKey,
      teamsVideosUrl: options.teamsVideosUrl,
      xClientId: options.xClientId,
    });
  }

  return quickplayCmsApi.getStorefrontTabContainer({
    ...(finalParams as ContainersParams),
    forceZoneKey: options.forceZoneKey,
    getStorefrontTabContainerEnv: options.getStorefrontTabContainerEnv,
    storefrontId: finalParams.storefrontId,
    tabId: finalParams.tabId,
    xClientId: options.xClientId,
  });
};

export const getVideos = createAsyncThunk(
  loadVideosAction.type,
  async (params: { teamKey?: string; pageNumber: number; pageSize: number } | null, thunksapi) => {
    try {
      const { storefront } = thunksapi.getState() as RootState;
      const {
        storefrontId,
        tabs: [tab],
      } = storefront;

      thunksapi.dispatch(videosActions.loadVideosLoading());

      if (tab) {
        const {
          appConfig: {
            env: { X_CLIENT_ID, FORCE_ZONE_KEY, TEAMS_VIDEOS_URL, GET_STOREFRONT_TAB_CONTAINER },
          },
        } = thunksapi.getState() as RootState;

        const options = {
          forceZoneKey: FORCE_ZONE_KEY,
          getStorefrontTabContainerEnv: GET_STOREFRONT_TAB_CONTAINER,
          teamsVideosUrl: TEAMS_VIDEOS_URL,
          xClientId: X_CLIENT_ID,
        };

        const teamParams = isTeamContainersParams(params) ? params : null;

        if (teamParams) {
          thunksapi.dispatch(videosActions.setTeamPage(teamParams));
        }

        const containersParams = teamParams ?? {
          pageNumber: params?.pageNumber,
          pageSize: params?.pageSize,
          storefrontId,
          tabId: tab.id,
        };

        const containers = await getContainers(containersParams, options);

        if (!containers) {
          const errorMessage = 'No data to show';
          thunksapi.dispatch(videosActions.loadVideosError(errorMessage));
        } else {
          const [banner, carousels] = containers.reduce<[Container | null, Container[]]>(
            (acc, storeFrontContainer) => {
              const container = parseStorefrontContainer(storeFrontContainer);
              if (storeFrontContainer.lo === ContainerType.BANNER) {
                return [container, acc[1]];
              }

              return [acc[0], acc[1].concat(container)];
            },
            [null, []],
          );

          thunksapi.dispatch(videosActions.loadVideosSuccess({ banner, carousels }));
        }
      } else {
        thunksapi.dispatch(videosActions.loadVideosError('Failed to load videos'));
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(videosActions.loadVideosError(errorMessage));
    }
  },
);

export const getVideoById = createAsyncThunk(
  loadVideoById.type,
  async ({ id, contentType }: { id: string; contentType: string }, thunksAPI) => {
    try {
      const {
        appConfig: {
          env: { X_CLIENT_ID, GET_VIDEO_BY_ID_URL },
        },
      } = thunksAPI.getState() as RootState;
      thunksAPI.dispatch(videosActions.loadVideo());

      const finalUrl = GET_VIDEO_BY_ID_URL.split('{id}')
        .join(id as string)
        .split('{contentType}')
        .join(contentType as string);

      const video = await quickplayCmsApi.getVideoById({
        url: finalUrl,
        xClientId: X_CLIENT_ID,
      });

      thunksAPI.dispatch(videosActions.displayVideo(parseVideoRaw(video)));
    } catch (error) {
      Log.error(error);
      thunksAPI.dispatch(videosActions.displayVideoFailure(error as string));
    }
  },
);

export const getAllVideos = createAsyncThunk(
  loadAllVideosAction.type,
  async (containerId: string, thunksAPI) => {
    try {
      const {
        appConfig: {
          env: { X_CLIENT_ID, FORCE_ZONE_KEY },
        },
      } = thunksAPI.getState() as RootState;

      const { videos: videosState } = thunksAPI.getState() as RootState;
      const container = videosState.carousels.find(({ id }) => containerId === id);
      const videos = await getVideosFromPromise(
        container?.allVideosInfo,
        FORCE_ZONE_KEY,
        X_CLIENT_ID,
      );

      thunksAPI.dispatch(
        videosActions.loadAllVideosSuccess({
          containerId,
          videos,
        }),
      );
    } catch (error) {
      Log.error(error);
      thunksAPI.dispatch(videosActions.loadAllVideosFailure({ containerId }));
    }
  },
);

export const displayVideo = createAsyncThunk(
  displayVideoAction.type,
  async (video: Video, thunksapi) => {
    const state = thunksapi.getState() as RootState;
    const areCouchRightsValid = areCouchRightsValidSelector(state);

    if (!areCouchRightsValid) {
      await thunksapi.dispatch(addOrUpdateCouchRights());
      const freshState = thunksapi.getState() as RootState;
      const areUpdatedCouchRightsValid = areCouchRightsValidSelector(freshState);

      if (!areUpdatedCouchRightsValid) {
        thunksapi.dispatch(
          setPlayerError({
            errorMessage: state.translations.data.couchRightsExpired,
          }),
        );
      } else {
        thunksapi.dispatch(couchRightsActions.setShouldUpdateCouchRightsOnStreamStart(false));
      }
    }

    thunksapi.dispatch(videosActions.displayVideo(video));
  },
);
