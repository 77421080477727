import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { Video } from '../../api';
import { ScheduleState } from './schedule.types';

export const initialState: ScheduleState = {
  allGames: [],
  channels: [],
  error: '',
  games: [],
  isPrevMonth: false,
  loading: false,
  team: '',
};

export const loadChannelEPGAction = createAction('schedule/loadChannelEPG');
export const loadGamesAction = createAction('schedule/loadGames');
export const loadAllGames = createAction('schedule/loadAllGames');

const slice = createSlice({
  initialState: initialState,
  name: 'schedule',
  reducers: {
    clearError: (state) => {
      return {
        ...state,
        error: '',
      };
    },
    loadAllGamesLoading: (state) => ({
      ...state,
      loading: true,
    }),
    loadAllGamesSuccess: (state, action: PayloadAction<Video[]>) => ({
      ...state,
      allGames: action.payload,
      loading: false,
    }),
    loadGamesSuccess: (
      state,
      action: PayloadAction<{
        games: Video[];
        isPrevMonth: boolean;
        month: number;
        team: string;
        year: number;
      }>,
    ) => {
      const { games, isPrevMonth, month, team, year } = action.payload;

      return {
        ...state,
        games,
        isPrevMonth,
        loading: false,
        month,
        team,
        year,
      };
    },
    loadScheduleError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    loadScheduleLoading: (state) => ({
      ...state,
      loading: true,
    }),
    loadScheduleSuccess: (state, action: PayloadAction<Video[]>) => ({
      ...state,
      channels: action.payload,
      loading: false,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as scheduleActions, name, reducer };
