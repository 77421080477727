import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BrowserState } from './browser.types';

const initialState: BrowserState = {
  currentPathname: '',
  inactivityEndTime: 0,
  inactivityStartTime: 0,
  isActive: true,
  isFocused: true,
};

const slice = createSlice({
  initialState,
  name: 'browser',
  reducers: {
    setBrowserState: (state: BrowserState, action: PayloadAction<Partial<BrowserState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

const { reducer, actions } = slice;

export { actions as browserActions, initialState, reducer };
