import * as flConviva from '@msgn/fl-module/fl-conviva';
import { ReactNode, createContext, useContext, useMemo } from 'react';

import { FLPlatformAsset } from '../../api';
import { useConviva } from '../../hooks/useConviva';

type ConvivaContextValue = {
  convivaSession?: flConviva.ConvivaSession;
  startConvivaSession: () => void;
  updateContentMetadata: (asset: FLPlatformAsset) => void;
};

export const ConvivaContext = createContext<ConvivaContextValue>({
  convivaSession: undefined,
  startConvivaSession: () => {
    return;
  },
  updateContentMetadata: () => {
    return;
  },
});

export const useConvivaContext = () => useContext(ConvivaContext);

export const ConvivaProvider = ({ children }: { children: ReactNode }) => {
  const { convivaSession, startConvivaSession, updateContentMetadata } = useConviva();

  const contextValue = useMemo<ConvivaContextValue>(
    () => ({
      convivaSession,
      startConvivaSession,
      updateContentMetadata,
    }),
    [convivaSession, startConvivaSession, updateContentMetadata],
  );

  return <ConvivaContext.Provider value={contextValue}>{children}</ConvivaContext.Provider>;
};
