import { createPlatformAuthorizer as platformAuthorizerCreator } from '@msgn/fl-module/fl-platform-authorizer';

import { PlatformAuthorizer } from './playerApi.types';

export const createPlatformAuthorizer = ({
  accessToken,
  authorizationEndpoint,
  clientId,
  clientSecret,
  xClinetId,
}: {
  accessToken: string;
  authorizationEndpoint: string;
  clientId: string;
  clientSecret: string;
  xClinetId: string;
}): PlatformAuthorizer => {
  const platformConfig = {
    authorizationEndPoint: authorizationEndpoint,
    clientID: clientId,
    clientSecret: clientSecret,
    xClientId: xClinetId,
  };
  return platformAuthorizerCreator(platformConfig, {
    fetchUserAuthorizationToken: async () => ({ accessToken }),
  });
};
