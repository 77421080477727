import { ZoneInfoType } from './zoneInfo.types';

export const ZONE_SESSION_STORAGE_KEY = 'session-zone';

export const allowedCountries = ['us'];

export const noZoneInfo: ZoneInfoType = {
  currentLocation: null,
  currentZone: null,
  isAllowedCountry: false,
  realZone: null,
  zipCode: null,
  zoneKey: null,
};
