import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ProductServiceTypeEnum } from '../../api/authApi/authApi.types';
import { EvergentIframeContext, useEvergentIframe } from '../../hooks/evergent';
import { authSelector } from '../../store/auth';

type EvergentIframeComponentProps = PropsWithChildren & {
  actionType: 'add' | 'update';
  onError?: (err: unknown) => void;
  onSuccess: () => void;
  serviceType: ProductServiceTypeEnum;
  zipCode: string;
  total?: number;
  couponCode?: string;
};

export const EvergentIframeComponent = ({
  actionType,
  onError,
  onSuccess,
  serviceType,
  zipCode,
  children,
  total,
  couponCode,
}: EvergentIframeComponentProps) => {
  const { accessToken } = useSelector(authSelector);
  const purchaseIframeState = useEvergentIframe({
    accessToken,
    actionType,
    onError,
    onSuccess,
    serviceType,
    zipCode,
  });

  const iframeContextValue = useMemo(
    () => ({
      couponCode,
      total,
      zipCode,
      ...purchaseIframeState,
    }),
    [couponCode, total, zipCode, purchaseIframeState],
  );

  return (
    <EvergentIframeContext.Provider value={iframeContextValue}>
      {children}
    </EvergentIframeContext.Provider>
  );
};

export default EvergentIframeComponent;
