import styled, { css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { Col, FONT_WEIGHT, TypographyProps, body1Xs, body2Xs, fonts, textBase } from '../Common';
import AppLogo from '../Logo/Logo';

export const Logo = styled(AppLogo)``;

export const FormWrapperCol = styled(Col)`
  margin-bottom: 1.5rem;
`;

export const SummaryCol = styled(Col)``;

export const SummaryPPGImage = styled.img`
  margin-bottom: 0.5rem;
  width: 100%;

  ${breakpoint('sm')`
      max-width: 7.5rem;
  `}
`;

export const SummaryContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoint('sm')`
     flex-direction: row;
     gap: 1.25rem;
  `}
`;
export const SummaryDescriptionWrapper = styled.div``;

export const SummaryPPGTitle = styled.p<TypographyProps>`
  ${textBase({
    $bolder: true,
    $mb: 0.5,
  })};
`;

export const SummaryPPGEventDate = styled.p`
  margin-bottom: 0;
`;
export const SummaryPPGEventDateLabel = styled.span<TypographyProps>`
  ${textBase({
    $bolder: true,
    $mb: 0.5,
  })};
  ${body1Xs}
`;

export const SummaryPPGEventDateValue = styled.span<TypographyProps>`
  ${body2Xs};
`;

export const FormBox = styled.div`
  background-color: ${({ theme }) => theme.colors['grey-dark']};
  padding: 1rem;
  border-radius: 4px;
  gap: 1.25rem;
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    padding: 1.5rem;
    gap: 1.5rem;
  `}
  hr {
    margin: 0;
  }
`;
export const SummaryBox = styled(FormBox)`
  background-color: transparent;
  padding: 0;
`;

export const BillingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const FormTitle = styled.div`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.5rem;

  ${breakpoint('md')`
    font-size: 1.75rem;
    line-height: 2rem;
  `}
`;

export const FormBillingZipCode = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.125rem;
  margin-bottom: 0.5rem;

  ${breakpoint('md')`
    font-size: 0.875rem;
    line-height: 1.125rem;
  `}
`;

export const FormDescription = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.125rem;
`;

export const Hr = styled.hr`
  border-color: ${({ theme }) => theme.colors['grey-3']};
  border-width: 1px;
  margin: 0;
`;

export const SummaryTitle = styled.div`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.5rem;
  text-transform: uppercase;
`;

export const SummaryRow = styled.div<{ $withMarginTop?: boolean }>`
  display: flex;

  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 1rem;
    `}
`;

export const SummaryLink = styled.div`
  padding: 0.75rem 0;
  font-weight: ${FONT_WEIGHT.Bold};
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 0.75rem;
  white-space: nowrap;
`;

export const SummaryLabel = styled.div`
  color: ${({ theme }) => theme.colors['off-white']};
  flex: 1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.125rem;
`;

export const SummaryValue = styled.div`
  color: ${({ theme }) => theme.colors['off-white']};
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.125rem;
`;

export const NoChargeNote = styled.p`
  color: ${({ theme }) => theme.colors['pure-white']};
  ${fonts.body2.xl}
  text-align: center;
  margin: 1.5rem 0 0 0;
`;
