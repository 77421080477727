import styled from 'styled-components';

import bgImage from '../../assets/images/logo.svg';
import { breakpoint } from '../../utils/breakpoint';
import { Container, FONT_WEIGHT } from '../Common';
import { LegalLink } from '../LegalLink/LegalLink';

export const Root = styled.div`
  position: fixed;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.termsAndConditions};
  background-color: ${({ theme }) => theme.colors['grey-dark']};
  min-width: 100%;
  color: ${({ theme }) => theme.colors['pure-white']};
  font-family: 'GothamNarrow';

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.04;
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size: auto 200%;
    background-position: 35% 30%;
  }
`;

export const Content = styled(Container)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoint('lg')`
	  padding-top: 2rem;
	  padding-bottom: 2rem;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Title = styled.p`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.2;
  margin: 0;
`;

export const LegalInfo = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.25;
  margin-bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.termsAndConditions + 1};
`;

export const LegalInfoLink = styled(LegalLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:active,
  &:visited,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`;
