export const ErrorCode = {
  '111111111': '111111111',
  '20003': '20003',
  eV1765: 'eV1765',
  eV2124: 'eV2124',
  eV2235: 'eV2235',
  eV2327: 'eV2327',
  eV2767: 'eV2767',
  eV5662: 'eV5662',
  eV5883: 'eV5883',
  eV5898: 'eV5898',
  eV5965: 'eV5965',
} as const;

export const ERROR_CODES: {
  [key: string]: string;
} = {
  [ErrorCode.eV1765]: 'Invalid Zipcode',
  [ErrorCode.eV2124]: 'Invalid Session Token',
  [ErrorCode.eV2235]:
    'Either SP Account ID or CP Customer ID or Contact ID or Session Token is required',
  [ErrorCode.eV2327]: 'No account found with the given details',
  [ErrorCode.eV5662]: 'encryptedZip is required',
  [ErrorCode.eV5883]: 'Account has already DTC subscription',
  [ErrorCode.eV5898]: 'Zipcode is out of market',
  [ErrorCode.eV5965]:
    'Your TV provider account is already paired with a different MSG+ account. The email of the account is: [[email]]. You will need to disconnect from this account before you can connect.',
};

export type ErrorCodeValue = keyof typeof ErrorCode;

export const GENERIC_ERROR_MESSAGE = 'Your TV Provider connection has failed.';
