import { useEffect } from 'react';

import { USER_INTERACTED, cacheManager } from '../utils';

const events = ['click', 'keydown', 'touchstart'];

export const useUserInteracted = () => {
  useEffect(() => {
    const handleUserInteraction = () => {
      cacheManager.save(USER_INTERACTED, true);

      events.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };

    cacheManager.save(USER_INTERACTED, false);

    events.forEach((event) => {
      window.addEventListener(event, handleUserInteraction);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };
  }, []);
};
