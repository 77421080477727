import { Fragment, useCallback, useState } from 'react';

import { Promotion, PromotionStep } from '../../../api/remoteConfigApi/remoteConfigApi.types';
import { Close } from '../../../assets/icons';
import { useLogScreenViewedEvent } from '../../../hooks/useLogScreenViewedEvent';
import { useTranslations } from '../../../hooks/useTranslations';
import { Button } from '../../Button';
import { Col, Row } from '../../Common';
import { DynamicImage } from '../../DynamicImage/DynamicImage';
import { TileStep } from '../../InstructionStepTile/TileStep';
import * as Ui from './PromotionModal.styles';

const PROMOTION_IMAGE_DIRECTORY = 'promotion';

const isExternalSrc = (src: string) => src.startsWith('http');

interface Props {
  promo: Promotion;
  onClose?: () => void;
  customSteps?: PromotionStep[];
}

export const PromotionModal = ({ promo, onClose, customSteps }: Props) => {
  const t = useTranslations();
  const [show, setShow] = useState(true);

  useLogScreenViewedEvent(`promotion-modal-${promo.id}`);

  const closeModal = useCallback(() => {
    setShow(false);
    onClose && onClose();
  }, [onClose]);

  if (!promo) {
    return null;
  }

  const steps = customSteps ?? promo.steps ?? [];
  const logoSrc = promo.logo?.src ? `${PROMOTION_IMAGE_DIRECTORY}/${promo.logo?.src}` : '';
  const logoAlt = promo.logo?.alt ?? '';

  return (
    <Ui.ModalRoot show={show} dialogClassName='promotion-dialog' fullscreen>
      <Ui.ModalBody>
        <Ui.CloseWrapper onClick={closeModal}>
          <Close />
        </Ui.CloseWrapper>

        <Row $justifyCenter>
          <Col xl={10}>
            {logoSrc && (
              <Ui.LogoWrapper>
                <DynamicImage src={logoSrc} alt={logoAlt} />
              </Ui.LogoWrapper>
            )}
            <Ui.Title>{promo.title ?? ''}</Ui.Title>
            <Ui.Description>{promo.subtitle ?? ''}</Ui.Description>

            <Ui.Steps>
              {steps.map((step, i) => (
                <Fragment key={i}>
                  <TileStep
                    index={i + 1}
                    caption={step.caption}
                    imgAlt={step.imgAlt}
                    imgSrc={
                      isExternalSrc(step.imgSrc)
                        ? step.imgSrc
                        : `${PROMOTION_IMAGE_DIRECTORY}/${step.imgSrc}`
                    }
                  />
                  {i !== steps.length - 1 && <Ui.Line />}
                </Fragment>
              ))}
            </Ui.Steps>
          </Col>
        </Row>
        <Ui.ButtonRow $justifyCenter>
          <Col xl={4}>
            <Button w100 onPress={closeModal}>
              {promo.ctaLabel ?? t['gotIt!']}
            </Button>
          </Col>
        </Ui.ButtonRow>
      </Ui.ModalBody>
    </Ui.ModalRoot>
  );
};
