import { AxiosError, AxiosResponse } from 'axios';

import { ErrorCodeValue } from '../../Constants/ErrorCodesTVEMap';
import {
  AUTH_SESSION_STORAGE_KEY,
  SessionType,
  authActions,
  authResponseToState,
} from '../../store/auth';
import { store as mainStore } from '../../store/store';
import { RootState } from '../../store/types';
import { cacheManager } from '../../utils';
import EvergentAuthApi from './EvergentAuthApi';
import {
  isRefreshTokenErrorCode as isRefreshTokenErrorCodeFn,
  parseResponseBody,
  shouldLogoutUser,
  shouldOmitCheckingRefreshToken,
} from './EvergentAuthApi.helpers';
import {
  AxiosRequestRetryConfig,
  EvergentAuthApiResponse,
  isErrorWithCodeInCause,
} from './EvergentAuthApi.types';
import { evergentAxiosInstance } from './evergentAxiosInstance';

const refreshTokenInterceptor = async (
  store: typeof mainStore,
  response: AxiosResponse<EvergentAuthApiResponse>,
) => {
  const originalRequest = response.config as AxiosRequestRetryConfig;
  const { data } = response;
  const parsedData = parseResponseBody(data);

  if (shouldOmitCheckingRefreshToken(originalRequest.url as string, parsedData)) {
    return response;
  }

  const error = parsedData.failureMessage[0];

  const {
    auth: { refreshToken, authed },
    appConfig: {
      env: {
        EVERGENT_API_KEY,
        EVERGENT_CHANNEL_PARTNER_ID,
        REFRESH_TOKEN_URL,
        QA_USER_NAME,
        VIP_USER_NAME,
      },
    },
  } = store.getState() as RootState;

  if (isRefreshTokenErrorCodeFn(error.errorCode as ErrorCodeValue) && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const refreshTokenResponse = await EvergentAuthApi.refreshToken({
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        refreshToken,
        refreshTokenUrl: REFRESH_TOKEN_URL,
      });
      const options = {
        qaUserName: QA_USER_NAME,
        vipUserName: VIP_USER_NAME,
      };
      const session: SessionType = {
        ...authResponseToState(refreshTokenResponse, options),
        authed,
      };

      cacheManager.save(AUTH_SESSION_STORAGE_KEY, session);
      await store.dispatch(authActions.loginSuccess(session));

      originalRequest.headers = {
        ...originalRequest.headers,
        Authorization: `Bearer ${session.accessToken}`,
      };

      return evergentAxiosInstance.request(originalRequest);
    } catch (error: unknown) {
      if (isErrorWithCodeInCause(error)) {
        const { errorCode } = error.cause;

        if (shouldLogoutUser(errorCode) && authed) {
          store.dispatch(authActions.setLogoutUser(true));
        }
      }

      return response;
    }
  } else {
    return response;
  }
};

export const setupInterceptor = (store: typeof mainStore) => {
  evergentAxiosInstance.interceptors.response.use(
    async (response: AxiosResponse<EvergentAuthApiResponse>) => {
      return refreshTokenInterceptor(store, response);
    },
    async (error: AxiosError<EvergentAuthApiResponse>) => {
      return refreshTokenInterceptor(store, error.response || ({} as AxiosResponse));
    },
  );
};
