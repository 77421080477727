import { SessionType } from '../auth.types';

export const authResponseToState = (
  response: {
    accountRole?: string;
    accessToken: string;
    expiresIn: string;
    refreshToken: string;
  },
  options: {
    qaUserName: string;
    vipUserName: string;
  },
): SessionType => ({
  accessToken: response.accessToken,
  accountRole: response.accountRole,
  authed: false,
  expiresIn: response.expiresIn,
  // TO DO: Waiting for EVG to include accountRole on generateDeviceActivationCode() api and use 'isQAUser store variable instead'
  isQAUser: response.accountRole === options.qaUserName,
  // END TODO
  isVIPUser: response.accountRole === options.vipUserName,
  refreshToken: response.refreshToken,
  tveToken: null,
});
