import { SVGProps } from 'react';

export const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='17'
    height='12'
    viewBox='0 0 17 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.332031 0.666667C0.332031 0.298477 0.630508 0 0.998698 0H15.6654C16.0336 0 16.332 0.298477 16.332 0.666667C16.332 1.03486 16.0336 1.33333 15.6654 1.33333H0.998698C0.630508 1.33333 0.332031 1.03486 0.332031 0.666667ZM0.332031 6C0.332031 5.63181 0.630508 5.33333 0.998698 5.33333H15.6654C16.0336 5.33333 16.332 5.63181 16.332 6C16.332 6.36819 16.0336 6.66667 15.6654 6.66667H0.998698C0.630508 6.66667 0.332031 6.36819 0.332031 6ZM0.332031 11.3333C0.332031 10.9651 0.630508 10.6667 0.998698 10.6667H10.332C10.7002 10.6667 10.9987 10.9651 10.9987 11.3333C10.9987 11.7015 10.7002 12 10.332 12H0.998698C0.630508 12 0.332031 11.7015 0.332031 11.3333Z'
      fill={props.fill || 'white'}
    />
  </svg>
);
