export const getPathWithoutSearchParams = (pathname = ''): string => {
  const path: string = pathname.split('?')[0];
  return path;
};

export const pathStartsWith = (path = ''): boolean => window.location.pathname.startsWith(path);

export const matchingPath = (path = ''): boolean => window.location.pathname === path;

export const matchingPathWithSearchParams = (path = ''): boolean =>
  `${window.location.pathname}${window.location.search}` === path;

export const appendQuerySeparator = (url: string): string => {
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}`;
};
