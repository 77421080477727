import React from 'react';

import { useEnv } from '../../EnvProvider/EnvProvider';
import * as Ui from '../CompanionAds.styles';
import { AdImage, MappedAds } from '../CompanionAds.types';
import { CompanionAdsDebugView } from '../CompanionAdsDebugView';

type TProps = {
  onImgLoad: (ad: MappedAds, adImage: AdImage) => Promise<void>;
  adToShow: MappedAds;
  adToShowIndex: number;
  imageToShow: AdImage | undefined;
  mappedAds: MappedAds[];
  isDebugViewEnabled: boolean;
};
export const CompanionAdImage = React.memo(
  ({ onImgLoad, adToShow, adToShowIndex, imageToShow, mappedAds, isDebugViewEnabled }: TProps) => {
    const { IS_COMPANION_ADS_CLICKABILITY_ENABLED } = useEnv();

    return (
      <div className='d-flex flex-column'>
        {adToShow && imageToShow ? (
          <Ui.ImageWrapper
            href={imageToShow?.imageRedirectUrl}
            rel='noreferrer'
            target='_blank'
            $isClickable={IS_COMPANION_ADS_CLICKABILITY_ENABLED && !!imageToShow?.imageRedirectUrl}
          >
            <Ui.Image
              key={adToShow.startDateTime}
              src={imageToShow.image?.src}
              onLoad={() => onImgLoad(adToShow, imageToShow)}
              $isVisible={true}
            />
          </Ui.ImageWrapper>
        ) : null}

        {isDebugViewEnabled && (
          <CompanionAdsDebugView ads={mappedAds} adToShowIndex={adToShowIndex} />
        )}
      </div>
    );
  },
);

CompanionAdImage.displayName = 'CompanionAdImage';
