export const PROMO_PARAM_KEY = {
  collectionId: 'collectionId',
  coupon: 'coupon',
  id: 'id',
  ppgId: 'ppgId',
  promotionId: 'promotionId',
} as const;

export const PROMO_TYPE = {
  fanduelCollection: 'fanduel-collection',
  seasonTicketHolder: 'season-ticket-holder',
  zoneToPpg: 'zone-to-ppg',
} as const;
