import { getEnv } from '../../../env';
import { FailureResponse, PostPaypalSetExpressCheckoutResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const paypalSetExpressCheckout = async ({
  accessToken,
  totalAmount,
}: {
  accessToken: string;
  totalAmount: number;
}): Promise<string> => {
  const { POST_EXPRESS_CHECKOUT_URL, EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID } = getEnv();

  const message = {
    amount: totalAmount,
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
  };
  const data = { PaypalSetExpressCheckoutRequestMessage: message };
  const response = await evergentAxiosInstance.post<{
    PaypalSetExpressCheckoutResponseMessage: PostPaypalSetExpressCheckoutResponse & FailureResponse;
  }>(POST_EXPRESS_CHECKOUT_URL, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const { failureMessage, ...dataResponse } = response.data.PaypalSetExpressCheckoutResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse.token;
};
