import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  ActiveSubscription,
  GetActiveSubscriptionsResponse,
  PauseSubscriptionResponseMessage,
  ProductServiceStatusEnum,
} from '../../api/authApi/authApi.types';
import { cacheManager } from '../../utils';
import { ACTIVE_SUBSCRIPTIONS_STORAGE_KEY } from './activeSubscriptions.constants';
import { ActiveSubscriptionState } from './activeSubscriptions.types';
import { activeSubscriptionsResponseToState } from './utils/activeSubscriptionsResponseToState';

const cachedActiveSubscriptions =
  cacheManager.load<GetActiveSubscriptionsResponse>(ACTIVE_SUBSCRIPTIONS_STORAGE_KEY) || null;

const noActiveSubscriptionsState: ActiveSubscriptionState = {
  canCancelSubscription: false,
  error: null,
  hasActiveSubscriptions: false,
  hasTveProvider: true,
  lastSubscription: null,
  loaded: false,
  loading: false,
  subscriptions: [],
  tveProviderName: undefined,
};

export const initialState: ActiveSubscriptionState = {
  ...noActiveSubscriptionsState,
  ...activeSubscriptionsResponseToState(cachedActiveSubscriptions),
};

export const loadActiveSubscriptionAction = createAction('activeSubscription/load');
export const pauseActiveSubscriptionAction = createAction('activeSubscription/pauseSubscription');
export const resumeSubscriptionAction = createAction('activeSubscription/resumeSubscription');

export const slice = createSlice({
  initialState: initialState,
  name: 'activeSubscription',
  reducers: {
    clearActiveSubscriptionSuccess: () => ({
      ...noActiveSubscriptionsState,
    }),
    loadActiveSubscriptionError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loaded: true,
      loading: false,
    }),
    loadActiveSubscriptionLoading: (state) => ({
      ...state,
      error: null,
      loading: true,
    }),
    loadActiveSubscriptionSuccess: (
      state,
      action: PayloadAction<GetActiveSubscriptionsResponse>,
    ) => ({
      error: null,
      loaded: true,
      loading: false,
      ...activeSubscriptionsResponseToState(action.payload),
    }),
    pauseSubscriptionSuccess: (state, action: PayloadAction<PauseSubscriptionResponseMessage>) => ({
      ...state,
      lastSubscription: {
        ...(state.lastSubscription as ActiveSubscription),
        pauseEndDate: action.payload.pauseEndDate,
        pauseStartDate: action.payload.pauseStartDate,
        subscriptionStatus: ProductServiceStatusEnum.PAUSED,
      },
    }),
    resumeSubscriptionSuccess: (state) => ({
      ...state,
      lastSubscription: {
        ...(state.lastSubscription as ActiveSubscription),
        pauseEndDate: undefined,
        pauseStartDate: undefined,
        subscriptionStatus: ProductServiceStatusEnum.ACTIVE_ALREADY_PAUSED,
      },
    }),
  },
});

const { actions, name, reducer } = slice;

export { actions, name, reducer };
