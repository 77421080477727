import { getEnv } from '../../../env';
import { ResumeSubscriptionResponseMessage, ServiceType } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const resumeSubscription = async (accessToken: string, subscriptionSku?: string) => {
  const { RESUME_SUBSCRIPTION_URL, EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID } = getEnv();

  const requestData = {
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
    serviceId: subscriptionSku,
    serviceType: ServiceType.Product,
  };

  const body = { ResumeSubscriptionRequestMessage: requestData };

  const response = await evergentAxiosInstance.post<{
    ResumeSubscriptionResponseMessage: ResumeSubscriptionResponseMessage;
  }>(RESUME_SUBSCRIPTION_URL, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { failureMessage, ...dataResponse } = response.data.ResumeSubscriptionResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
