export const AppleStore = () => (
  <svg width='208' height='64' viewBox='0 0 208 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.04081 0H199.961C204.398 0 208 3.63203 208 8.10383V55.6573C208 60.1304 204.397 63.7611 199.961 63.7611H8.03943C3.60317 63.7611 0 60.1304 0 55.6573V8.10522C0 3.63203 3.60317 0 8.04081 0Z'
      fill='black'
    />
    <path
      d='M18.9019 26.0537C15.5281 31.9557 17.6723 40.9133 21.404 46.3432C23.2663 49.0589 25.1522 51.496 27.7344 51.496C27.7827 51.496 27.8325 51.496 27.8822 51.4932C29.0952 51.4445 29.9725 51.0685 30.8208 50.7036C31.7727 50.2942 32.7578 49.8722 34.3024 49.8722C35.847 49.8722 36.7091 50.2816 37.6155 50.6757C38.5093 51.0657 39.4295 51.4696 40.7889 51.4431C43.6986 51.3888 45.4849 48.7581 47.0599 46.4379C48.704 44.0161 49.5302 41.6639 49.8079 40.7643L49.819 40.728C49.8715 40.5679 49.7969 40.3938 49.6449 40.3242C49.6449 40.3242 49.6255 40.3158 49.6214 40.3144C49.1088 40.1027 44.6145 38.0932 44.5676 32.6159C44.5234 28.165 47.9386 25.81 48.6239 25.3824L48.6557 25.3616C48.7303 25.3142 48.7814 25.2376 48.7994 25.1499C48.8173 25.0621 48.7994 24.9716 48.7482 24.8978C46.3913 21.4204 42.7784 20.8967 41.3236 20.834C41.1136 20.8132 40.8953 20.802 40.677 20.802C38.968 20.802 37.3322 21.4524 36.017 21.9746C35.1093 22.3353 34.3245 22.6473 33.7857 22.6473C33.1778 22.6473 32.3889 22.3311 31.4771 21.9677C30.2558 21.4788 28.8714 20.926 27.4069 20.926C27.3724 20.926 27.3379 20.926 27.3033 20.9274C23.8963 20.9775 20.6773 22.9425 18.9033 26.0523L18.9019 26.0537Z'
      fill='white'
    />
    <path
      d='M41.3237 11.2637C39.261 11.3487 36.7866 12.6285 35.3083 14.3749C34.0511 15.8414 32.8229 18.2897 33.1461 20.7561C33.1669 20.9106 33.2912 21.029 33.4459 21.0415C33.5855 21.0527 33.7264 21.0583 33.8687 21.0583C35.8858 21.0583 38.0618 19.933 39.547 18.1225C41.111 16.2104 41.9012 13.7566 41.6622 11.5576C41.6429 11.3849 41.4923 11.2568 41.3237 11.2637Z'
      fill='white'
    />
    <path
      d='M65.0625 14.5462H68.9738C70.0003 14.5462 70.9107 14.7593 71.7065 15.1869C72.5023 15.6144 73.1213 16.2049 73.5634 16.9597C74.0055 17.7145 74.2265 18.571 74.2265 19.5291C74.2265 20.4873 74.0055 21.3438 73.5634 22.0986C73.1213 22.8534 72.5023 23.4439 71.7065 23.87C70.9107 24.2976 70.0003 24.5106 68.9738 24.5106H65.0625V14.5462ZM68.9171 23.8561C69.8303 23.8561 70.6344 23.6737 71.3321 23.3074C72.0284 22.9425 72.5659 22.4314 72.9417 21.7769C73.3175 21.1223 73.5067 20.3731 73.5067 19.5277C73.5067 18.6824 73.3188 17.9332 72.9417 17.2786C72.5645 16.6241 72.0284 16.1144 71.3321 15.7481C70.6358 15.3832 69.8303 15.2008 68.9171 15.2008H65.7823V23.8547H68.9171V23.8561Z'
      fill='white'
    />
    <path
      d='M77.4969 24.0831C76.9374 23.76 76.4966 23.3116 76.1761 22.7378C75.8556 22.164 75.6953 21.5164 75.6953 20.7951C75.6953 20.0737 75.8556 19.4261 76.1761 18.8523C76.4966 18.2785 76.936 17.8301 77.4969 17.507C78.0564 17.1839 78.6851 17.0224 79.3828 17.0224C80.0805 17.0224 80.7077 17.1839 81.2672 17.507C81.8268 17.8301 82.2675 18.2785 82.588 18.8523C82.9086 19.4261 83.0688 20.0737 83.0688 20.7951C83.0688 21.5164 82.9086 22.164 82.588 22.7378C82.2675 23.3116 81.8282 23.76 81.2672 24.0831C80.7077 24.4062 80.0791 24.5677 79.3828 24.5677C78.6864 24.5677 78.0578 24.4062 77.4969 24.0831ZM80.8997 23.5274C81.3474 23.2614 81.6997 22.8924 81.9594 22.4175C82.2178 21.9426 82.3476 21.4022 82.3476 20.7951C82.3476 20.1879 82.2178 19.6475 81.9594 19.1726C81.6997 18.6977 81.3474 18.3287 80.8997 18.0627C80.4521 17.7967 79.9464 17.6644 79.3814 17.6644C78.8163 17.6644 78.3106 17.7967 77.863 18.0627C77.4154 18.3287 77.0631 18.6991 76.8033 19.1726C76.5436 19.6475 76.4151 20.1879 76.4151 20.7951C76.4151 21.4022 76.545 21.944 76.8033 22.4175C77.0617 22.8924 77.4154 23.2628 77.863 23.5274C78.3106 23.7934 78.8163 23.9257 79.3814 23.9257C79.9464 23.9257 80.4521 23.7934 80.8997 23.5274Z'
      fill='white'
    />
    <path
      d='M95.5774 17.0795L92.7811 24.5093H92.1179L89.6325 18.0613L87.1332 24.5093H86.4838L83.6875 17.0795H84.3659L86.8223 23.7127L89.3354 17.0795H89.9433L92.4426 23.6987L94.9281 17.0795H95.5774Z'
      fill='white'
    />
    <path
      d='M103.009 17.8343C103.545 18.3746 103.814 19.1531 103.814 20.1684V24.5092H103.108V20.2241C103.108 19.3885 102.898 18.7534 102.48 18.3161C102.061 17.8802 101.475 17.6616 100.721 17.6616C99.8548 17.6616 99.1723 17.9234 98.6736 18.4442C98.1748 18.9665 97.9247 19.6684 97.9247 20.5513V24.5079H97.2188V17.0781H97.8971V18.6866C98.1513 18.1643 98.5299 17.7563 99.0342 17.4624C99.537 17.1686 100.134 17.021 100.821 17.021C101.743 17.021 102.473 17.2911 103.009 17.8329V17.8343Z'
      fill='white'
    />
    <path d='M106.719 13.9488H107.425V24.5106H106.719V13.9488Z' fill='white' />
    <path
      d='M111.403 24.0831C110.844 23.76 110.403 23.3116 110.082 22.7378C109.762 22.164 109.602 21.5164 109.602 20.7951C109.602 20.0737 109.762 19.4261 110.082 18.8523C110.403 18.2785 110.842 17.8301 111.403 17.507C111.963 17.1839 112.591 17.0224 113.289 17.0224C113.987 17.0224 114.614 17.1839 115.173 17.507C115.733 17.8301 116.174 18.2785 116.494 18.8523C116.815 19.4261 116.974 20.0737 116.974 20.7951C116.974 21.5164 116.813 22.164 116.494 22.7378C116.174 23.3116 115.734 23.76 115.173 24.0831C114.614 24.4062 113.985 24.5677 113.289 24.5677C112.593 24.5677 111.964 24.4062 111.403 24.0831ZM114.806 23.5274C115.254 23.2614 115.606 22.8924 115.866 22.4175C116.124 21.9426 116.254 21.4022 116.254 20.7951C116.254 20.1879 116.124 19.6475 115.866 19.1726C115.606 18.6977 115.254 18.3287 114.806 18.0627C114.358 17.7967 113.853 17.6644 113.288 17.6644C112.723 17.6644 112.217 17.7967 111.769 18.0627C111.322 18.3287 110.969 18.6991 110.71 19.1726C110.45 19.6475 110.321 20.1879 110.321 20.7951C110.321 21.4022 110.451 21.944 110.71 22.4175C110.968 22.8924 111.322 23.2628 111.769 23.5274C112.217 23.7934 112.723 23.9257 113.288 23.9257C113.853 23.9257 114.358 23.7934 114.806 23.5274Z'
      fill='white'
    />
    <path
      d='M123.722 17.727C124.211 18.1964 124.455 18.8871 124.455 19.7979V24.5093H123.777V23.1848C123.542 23.6207 123.198 23.9605 122.746 24.2029C122.294 24.4452 121.753 24.5663 121.123 24.5663C120.303 24.5663 119.657 24.3714 119.182 23.9828C118.706 23.5943 118.469 23.0818 118.469 22.4453C118.469 21.8089 118.687 21.3284 119.125 20.9441C119.563 20.5597 120.262 20.3675 121.222 20.3675H123.751V19.7701C123.751 19.0779 123.564 18.5515 123.192 18.1894C122.821 17.8287 122.276 17.6491 121.561 17.6491C121.072 17.6491 120.603 17.734 120.156 17.9053C119.708 18.0766 119.329 18.3036 119.019 18.5891L118.665 18.0766C119.032 17.7452 119.475 17.4861 119.993 17.3009C120.511 17.1157 121.052 17.0238 121.616 17.0238C122.529 17.0238 123.231 17.2591 123.72 17.7284L123.722 17.727ZM122.782 23.5567C123.21 23.2726 123.532 22.859 123.749 22.3186V20.9232H121.236C120.511 20.9232 119.986 21.0555 119.661 21.3215C119.336 21.5875 119.173 21.9523 119.173 22.4175C119.173 22.8826 119.352 23.2837 119.709 23.5636C120.067 23.8436 120.57 23.9842 121.221 23.9842C121.833 23.9842 122.352 23.8422 122.781 23.5567H122.782Z'
      fill='white'
    />
    <path
      d='M133.934 13.9488V24.5106H133.242V22.7601C132.95 23.3394 132.54 23.7851 132.013 24.0984C131.486 24.4118 130.889 24.5677 130.22 24.5677C129.551 24.5677 128.912 24.4062 128.356 24.0831C127.801 23.76 127.366 23.3144 127.051 22.7448C126.736 22.1752 126.578 21.5262 126.578 20.7951C126.578 20.0639 126.736 19.4122 127.051 18.8384C127.366 18.2646 127.801 17.819 128.356 17.5C128.912 17.1825 129.533 17.0238 130.22 17.0238C130.907 17.0238 131.47 17.1797 131.993 17.4931C132.515 17.8064 132.926 18.2423 133.228 18.8022V13.9488H133.934ZM131.78 23.5288C132.227 23.2628 132.58 22.8938 132.839 22.4189C133.098 21.944 133.228 21.4036 133.228 20.7964C133.228 20.1893 133.098 19.6489 132.839 19.174C132.58 18.6991 132.227 18.3301 131.78 18.0641C131.332 17.7981 130.826 17.6658 130.261 17.6658C129.696 17.6658 129.191 17.7981 128.743 18.0641C128.295 18.3301 127.943 18.7005 127.683 19.174C127.424 19.6489 127.295 20.1893 127.295 20.7964C127.295 21.4036 127.425 21.9454 127.683 22.4189C127.942 22.8938 128.295 23.2642 128.743 23.5288C129.191 23.7948 129.696 23.9271 130.261 23.9271C130.826 23.9271 131.332 23.7948 131.78 23.5288Z'
      fill='white'
    />
    <path
      d='M141.536 24.0831C140.976 23.76 140.536 23.3116 140.215 22.7378C139.895 22.164 139.734 21.5164 139.734 20.7951C139.734 20.0737 139.895 19.4261 140.215 18.8523C140.536 18.2785 140.975 17.8301 141.536 17.507C142.095 17.1839 142.724 17.0224 143.42 17.0224C144.117 17.0224 144.745 17.1839 145.306 17.507C145.866 17.8301 146.307 18.2785 146.627 18.8523C146.948 19.4261 147.106 20.0737 147.106 20.7951C147.106 21.5164 146.946 22.164 146.627 22.7378C146.307 23.3116 145.867 23.76 145.306 24.0831C144.747 24.4062 144.118 24.5677 143.42 24.5677C142.723 24.5677 142.095 24.4062 141.536 24.0831ZM144.939 23.5274C145.386 23.2614 145.739 22.8924 145.998 22.4175C146.257 21.9426 146.387 21.4022 146.387 20.7951C146.387 20.1879 146.257 19.6475 145.998 19.1726C145.739 18.6977 145.386 18.3287 144.939 18.0627C144.491 17.7967 143.985 17.6644 143.42 17.6644C142.855 17.6644 142.35 17.7967 141.902 18.0627C141.454 18.3287 141.102 18.6991 140.842 19.1726C140.583 19.6475 140.454 20.1879 140.454 20.7951C140.454 21.4022 140.584 21.944 140.842 22.4175C141.101 22.8924 141.454 23.2628 141.902 23.5274C142.35 23.7934 142.855 23.9257 143.42 23.9257C143.985 23.9257 144.491 23.7934 144.939 23.5274Z'
      fill='white'
    />
    <path
      d='M155.087 17.8343C155.623 18.3746 155.893 19.1531 155.893 20.1684V24.5092H155.187V20.2241C155.187 19.3885 154.977 18.7534 154.558 18.3161C154.139 17.8802 153.554 17.6616 152.799 17.6616C151.933 17.6616 151.25 17.9234 150.752 18.4442C150.253 18.9665 150.003 19.6684 150.003 20.5513V24.5079H149.297V17.0781H149.975V18.6866C150.229 18.1643 150.608 17.7563 151.112 17.4624C151.615 17.1686 152.212 17.021 152.899 17.021C153.822 17.021 154.551 17.2911 155.087 17.8329V17.8343Z'
      fill='white'
    />
    <path
      d='M166.082 24.0399C165.903 24.2112 165.68 24.3407 165.411 24.4313C165.143 24.5218 164.862 24.5664 164.571 24.5664C163.93 24.5664 163.437 24.3881 163.089 24.033C162.74 23.6764 162.566 23.1821 162.566 22.5456V17.6922H161.211V17.0795H162.566V15.457H163.272V17.0795H165.617V17.6922H163.272V22.4746C163.272 22.9578 163.387 23.3269 163.618 23.5776C163.848 23.8296 164.19 23.955 164.641 23.955C165.093 23.955 165.484 23.8171 165.785 23.5428L166.082 24.0413V24.0399Z'
      fill='white'
    />
    <path
      d='M173.837 17.8343C174.373 18.3746 174.643 19.1531 174.643 20.1684V24.5093H173.937V20.2241C173.937 19.3885 173.727 18.7534 173.308 18.3161C172.889 17.8802 172.304 17.6616 171.549 17.6616C170.683 17.6616 170 17.9234 169.502 18.4443C169.003 18.9665 168.753 19.6684 168.753 20.5513V24.5079H168.047V13.946H168.753V18.6295C169.007 18.117 169.383 17.7215 169.883 17.4416C170.382 17.1616 170.97 17.021 171.649 17.021C172.572 17.021 173.301 17.2911 173.837 17.8329V17.8343Z'
      fill='white'
    />
    <path
      d='M183.791 20.965H177.451C177.471 21.5443 177.616 22.0582 177.889 22.5094C178.163 22.9606 178.532 23.3088 178.997 23.5553C179.463 23.8018 179.988 23.9257 180.572 23.9257C181.052 23.9257 181.497 23.838 181.907 23.6625C182.316 23.487 182.657 23.228 182.931 22.8868L183.34 23.3561C183.019 23.7461 182.617 24.0441 182.132 24.253C181.647 24.4619 181.122 24.5663 180.557 24.5663C179.822 24.5663 179.169 24.4048 178.594 24.0817C178.019 23.7586 177.57 23.313 177.246 22.7434C176.921 22.1738 176.758 21.5248 176.758 20.7936C176.758 20.0625 176.911 19.4247 177.216 18.8509C177.522 18.2771 177.943 17.8287 178.481 17.5056C179.017 17.1825 179.619 17.021 180.288 17.021C180.956 17.021 181.556 17.1797 182.088 17.4972C182.62 17.8148 183.038 18.259 183.345 18.8286C183.651 19.3982 183.804 20.043 183.804 20.7644L183.79 20.9636L183.791 20.965ZM178.891 17.9972C178.476 18.2298 178.145 18.5571 177.895 18.979C177.645 19.401 177.501 19.8787 177.464 20.4093H183.112C183.084 19.8773 182.942 19.4038 182.688 18.986C182.433 18.5682 182.099 18.2409 181.685 18.0042C181.27 17.7674 180.804 17.6477 180.286 17.6477C179.768 17.6477 179.303 17.7646 178.888 17.9958L178.891 17.9972Z'
      fill='white'
    />
    <path
      d='M75.5166 46.5368H67.071L65.4062 50.478H62.1719L69.7733 33.4501H72.8626L80.4875 50.478H77.2063L75.5166 46.5368ZM74.479 44.0551L71.2931 36.6114L68.132 44.0551H74.479Z'
      fill='white'
    />
    <path
      d='M92.8534 38.1697C93.8427 38.7212 94.6191 39.4997 95.1828 40.5052C95.7451 41.5107 96.0269 42.6708 96.0269 43.9841C96.0269 45.2973 95.7451 46.4616 95.1828 47.474C94.6191 48.4879 93.844 49.2706 92.8534 49.822C91.8642 50.3735 90.7424 50.6493 89.4865 50.6493C87.7485 50.6493 86.3738 50.0658 85.3597 48.8973V55.1977H82.3438V37.4887H85.2161V39.192C85.7148 38.5764 86.3296 38.114 87.0618 37.8049C87.7941 37.4971 88.6023 37.3425 89.4865 37.3425C90.741 37.3425 91.8628 37.6183 92.8534 38.1697ZM91.8877 46.9267C92.6034 46.1817 92.9612 45.1998 92.9612 43.9841C92.9612 42.7683 92.6034 41.7865 91.8877 41.04C91.1721 40.2949 90.2588 39.9217 89.1494 39.9217C88.4255 39.9217 87.7734 40.0888 87.1945 40.4203C86.6156 40.7531 86.1569 41.2266 85.8184 41.8436C85.4799 42.4605 85.3114 43.1735 85.3114 43.9841C85.3114 44.7946 85.4799 45.509 85.8184 46.1246C86.1569 46.7415 86.6142 47.215 87.1945 47.5479C87.7734 47.8807 88.4255 48.0464 89.1494 48.0464C90.2588 48.0464 91.1721 47.6732 91.8877 46.9281V46.9267Z'
      fill='white'
    />
    <path
      d='M109.408 38.1697C110.397 38.7212 111.174 39.4997 111.737 40.5052C112.3 41.5107 112.582 42.6708 112.582 43.9841C112.582 45.2973 112.3 46.4616 111.737 47.474C111.174 48.4879 110.399 49.2706 109.408 49.822C108.419 50.3735 107.297 50.6493 106.041 50.6493C104.303 50.6493 102.929 50.0658 101.914 48.8973V55.1977H98.8984V37.4887H101.771V39.192C102.27 38.5764 102.884 38.114 103.617 37.8049C104.349 37.4971 105.157 37.3425 106.041 37.3425C107.296 37.3425 108.418 37.6183 109.408 38.1697ZM108.442 46.9267C109.158 46.1817 109.516 45.1998 109.516 43.9841C109.516 42.7683 109.158 41.7865 108.442 41.04C107.727 40.2949 106.814 39.9217 105.704 39.9217C104.98 39.9217 104.328 40.0888 103.749 40.4203C103.17 40.7531 102.712 41.2266 102.373 41.8436C102.035 42.4605 101.866 43.1735 101.866 43.9841C101.866 44.7946 102.035 45.509 102.373 46.1246C102.712 46.7415 103.169 47.215 103.749 47.5479C104.328 47.8807 104.98 48.0464 105.704 48.0464C106.814 48.0464 107.727 47.6732 108.442 46.9281V46.9267Z'
      fill='white'
    />
    <path
      d='M123.86 50.1619C122.646 49.7886 121.684 49.2942 120.977 48.6787L122.062 46.2221C122.753 46.7735 123.603 47.2234 124.609 47.5715C125.615 47.9211 126.623 48.0952 127.637 48.0952C128.892 48.0952 129.828 47.8932 130.449 47.4866C131.068 47.0813 131.377 46.5465 131.377 45.8809C131.377 45.3948 131.204 44.9923 130.858 44.6762C130.511 44.3601 130.073 44.1136 129.542 43.9339C129.012 43.7557 128.288 43.5537 127.371 43.3253C126.083 43.0176 125.041 42.7098 124.245 42.4006C123.45 42.0928 122.766 41.6096 122.194 40.9537C121.623 40.2963 121.337 39.4092 121.337 38.2895C121.337 37.3495 121.59 36.493 122.097 35.7229C122.604 34.9527 123.368 34.34 124.389 33.886C125.41 33.432 126.662 33.205 128.141 33.205C129.171 33.205 130.185 33.3345 131.182 33.5935C132.18 33.8525 133.041 34.2258 133.764 34.7118L132.775 37.1684C132.035 36.7311 131.262 36.3983 130.458 36.1713C129.654 35.9443 128.874 35.8315 128.118 35.8315C126.879 35.8315 125.959 36.0418 125.355 36.4638C124.751 36.8857 124.45 37.4456 124.45 38.1419C124.45 38.6279 124.623 39.0262 124.969 39.334C125.315 39.6418 125.754 39.8855 126.285 40.0638C126.815 40.242 127.539 40.4453 128.456 40.6723C129.711 40.9648 130.74 41.2684 131.546 41.5845C132.35 41.9007 133.038 42.3839 133.608 43.0315C134.179 43.6805 134.465 44.5564 134.465 45.658C134.465 46.5981 134.212 47.4504 133.705 48.2121C133.198 48.9739 132.43 49.5825 131.401 50.0365C130.371 50.4905 129.115 50.7175 127.636 50.7175C126.333 50.7175 125.074 50.5309 123.859 50.1577L123.86 50.1619Z'
      fill='white'
    />
    <path
      d='M145.036 49.7719C144.683 50.0644 144.252 50.283 143.746 50.4292C143.239 50.5755 142.704 50.6479 142.141 50.6479C140.725 50.6479 139.632 50.2747 138.859 49.5296C138.087 48.7845 137.702 47.6969 137.702 46.2694V40.0178H135.578V37.5848H137.702V34.6171H140.718V37.5848H144.169V40.0178H140.718V46.197C140.718 46.8292 140.87 47.3125 141.176 47.6439C141.482 47.9768 141.924 48.1425 142.504 48.1425C143.18 48.1425 143.743 47.9643 144.194 47.6077L145.038 49.7733L145.036 49.7719Z'
      fill='white'
    />
    <path
      d='M149.362 49.797C148.316 49.2288 147.5 48.4392 146.913 47.4253C146.326 46.4115 146.031 45.2639 146.031 43.9827C146.031 42.7014 146.324 41.5581 146.913 40.5526C147.5 39.5471 148.316 38.7602 149.362 38.1934C150.408 37.6266 151.582 37.3425 152.885 37.3425C154.188 37.3425 155.387 37.6266 156.433 38.1934C157.479 38.7616 158.296 39.5471 158.883 40.5526C159.47 41.5581 159.763 42.7014 159.763 43.9827C159.763 45.2639 159.469 46.4115 158.883 47.4253C158.296 48.4392 157.479 49.2302 156.433 49.797C155.387 50.3652 154.205 50.6479 152.885 50.6479C151.566 50.6479 150.408 50.3638 149.362 49.797ZM155.636 46.9267C156.36 46.1817 156.722 45.1998 156.722 43.9841C156.722 42.7683 156.36 41.7865 155.636 41.04C154.912 40.2949 153.995 39.9217 152.885 39.9217C151.776 39.9217 150.863 40.2949 150.147 41.04C149.431 41.7865 149.073 42.7669 149.073 43.9841C149.073 45.2012 149.431 46.1817 150.147 46.9267C150.863 47.6732 151.776 48.045 152.885 48.045C153.995 48.045 154.912 47.6718 155.636 46.9267Z'
      fill='white'
    />
    <path
      d='M170.113 37.3425V40.2378C169.856 40.1891 169.622 40.1654 169.412 40.1654C168.238 40.1654 167.32 40.5108 166.661 41.1988C166.001 41.8881 165.672 42.8811 165.672 44.179V50.4794H162.656V37.4901H165.529V39.3869C166.398 38.0249 167.926 37.3439 170.114 37.3439L170.113 37.3425Z'
      fill='white'
    />
    <path
      d='M184.612 44.9561H174.5C174.677 45.9129 175.14 46.6705 175.887 47.2303C176.636 47.7902 177.565 48.0701 178.674 48.0701C180.09 48.0701 181.256 47.5994 182.174 46.6593L183.79 48.5325C183.211 49.2302 182.479 49.7566 181.595 50.1131C180.709 50.4696 179.713 50.6479 178.602 50.6479C177.186 50.6479 175.94 50.3638 174.862 49.797C173.785 49.2288 172.952 48.4392 172.364 47.4253C171.777 46.4115 171.484 45.2639 171.484 43.9827C171.484 42.7014 171.77 41.579 172.341 40.5651C172.912 39.5513 173.705 38.7616 174.717 38.1934C175.731 37.6266 176.873 37.3425 178.144 37.3425C179.415 37.3425 180.52 37.6224 181.511 38.1823C182.5 38.7421 183.272 39.529 183.827 40.5414C184.383 41.5553 184.661 42.7265 184.661 44.0565C184.661 44.2682 184.644 44.5676 184.612 44.9561ZM175.695 40.6389C175.028 41.2141 174.622 41.9814 174.477 42.9382H181.788C181.66 41.9981 181.265 41.235 180.606 40.6515C179.945 40.0679 179.126 39.7755 178.144 39.7755C177.161 39.7755 176.361 40.0637 175.694 40.6389H175.695Z'
      fill='white'
    />
  </svg>
);
