export type DeviceInfo = {
  isAndroid: boolean;
  isIOS: boolean;
  isWeb: boolean;
  version: string;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  deviceModelNo: string;
  deviceUserAgent: string;
  isTTSEnabled: boolean;
};

export type ErrorType = {
  errorCode: string;
  errorMessage: string;
};

export type AnonymyzeAccountResponseMessage = {
  responseCode: string;
  message: string;
  failureMessage: ErrorType[];
};

export type FailureResponse = {
  failureMessage: ErrorType[];
};

export type LoginResponse = {
  accountRole?: string;
  accessToken: string;
  channelPartnerID: string;
  expiresIn: string;
  message: string;
  refreshToken: string;
  responseCode: string;
  status: string;
  tokenType: string;
};

export type RefreshTokenResponse = {
  accessToken: string;
  channelPartnerID: string;
  expiresIn: string;
  message: string;
  refreshToken: string;
  responseCode: string;
  status: string;
  tokenType: string;
};

export type PauseSubscriptionResponseMessage = {
  failureMessage?: ErrorType[];
  message?: string;
  pauseStartDate?: number;
  pauseEndDate?: number;
};

export type ResumeSubscriptionResponseMessage = {
  failureMessage?: ErrorType[];
  message?: string;
};

export enum ProductServiceTypeEnum {
  TVOD = 'TVOD',
  SVOD = 'SVOD',
}

export enum ProductServiceStatusEnum {
  ACTIVE = 'Active',
  ACTIVE_CANCELLED = 'Active - Cancelled',
  ACTIVE_IN_GRACE = 'Active - In Grace',
  PAUSED = 'Pause',
  ACTIVE_ALREADY_PAUSED = 'Existing',
}

export enum SubscriptionTypeEnum {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export type TaxData = {
  displayOrder: number;
  taxAmount: number;
  taxDescription: string;
  taxRate: number;
};

export enum PaymentMethod {
  ApplePay = 'ApplePay',
  PayPal = 'PayPal Account',
  CreditCard = 'Credit Card',
  SamsungCheckout = 'Samsung Checkout',
  Roku = 'Roku Payment',
  GoogleWallet = 'Google Wallet',
}

export interface ActiveSubscription {
  orderID: string;
  subscriptionStatus: ProductServiceStatusEnum;
  status: ProductServiceStatusEnum;
  startDate: number;
  validityTill: number;
  displayName: string;
  paymentMethod: PaymentMethod;
  priceCharged: number;
  retailPrice: number;
  serviceID: string;
  serviceName: string;
  subscriptionType: SubscriptionTypeEnum;
  mvpdName: string;
  serviceType: ProductServiceTypeEnum;
  taxDetails: TaxData[];
  formattedValidityEndDateWithTZ?: string;
  pauseStartDate?: number;
  pauseEndDate?: number;
}

export interface SubscriptionHistory {
  startDate: number;
  displayName: string;
  validityTill: number;
  last4Digits: {
    paymentMethodDetail: {
      last4Digits: string;
    };
  };
  planPrice: number;
}

export interface AccountPayment {
  currencyCode: string;
  period: string;
  duration: number;
  serviceDescription: string;
  amount: number;
  receivedDate: number;
  paymentType: string;
  postingStatus: string;
  gatewayResponse: string;
  paymentID: string;
  serviceName: string;
  serviceID: string;
  orderedProductID: string;
  paymentMethodID: string;
  serviceStartDate: number;
  serviceEndDate: number;
  cardNumber: string;
}

export interface GetActiveSubscriptionsResponse {
  AccountServiceMessage: ActiveSubscription[];
}

export interface PostPaypalSetExpressCheckoutResponse {
  token: string;
}

export type SignUpResponse = {
  cpCustomerID: string;
  spAccountID: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  responseCode: string;
  message: string;
  contactID: string;
};

export type EntitlementVodItem = {
  assetId: string;
  title: string;
};

export type EntitlementAccountService = {
  status: ProductServiceStatusEnum;
  validityTill: number;
  serviceID: string;
  serviceName: string;
  name: string;
  vodItems: EntitlementVodItem[];
  description: string;
  isContent: boolean;
  paymentMethod: string;
  isUpgradeAllowed: boolean;
  isRenewal: boolean;
};

export type GetEntitlementsResponse = {
  ovatToken: string;
  tvodToken: string;
  ovatTokenExpiry: number;
  billingZipCode: string;
  dmaID: string;
  AccountServiceMessage: EntitlementAccountService[];
};

export type DisconnectTVEAccountResponse = {
  message: string;
  responseCode: string;
};

export type ActivationCodeValidatedResponse = {
  channelPartnerID: string;
  sessionToken: string;
  accessToken: string;
  refreshToken: string;
  message: string;
  responseCode: string;
  accountRole: string;
};

export type ActivationCodeResponse = {
  activationCode: string;
  validityTill: number;
};

export type AdobeAccessTokenResponse = {
  adobeAccessToken: string;
  expiresIn: string;
  message: string;
  responseCode: string;
};

export type ContactMessage = {
  contactCreateDate: number;
  contactCreateDateString: string;
  contactID: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type GetContactResponse = {
  accountRegistrationDate: number;
  contactMessage: ContactMessage[];
  cpCustomerID: string;
  spAccountID: string;
  verificationStatus: boolean;
  isProfileComplete: boolean;
  isMobileVerified: boolean;
  lastLoginTime: number;
  channelPartnerID: string;
};

export enum OVPSKU {
  ANUUAL = 'urn:package:dtc:annual',
  MONTHLY = 'urn:package:dtc:monthly',
}

export type GetProductsResponseItem = {
  channelPartnerDescription?: string;
  externalID: string;
  // channelPartnerID: string;
  dmaName: string;
  // basicService: boolean;
  productName: string;
  skuORQuickCode: string;
  retailPrice: number;
  currencyCode: string;
  productDescription: string;
  period?: string;
  displayOrder: number;
  // duration: number;
  // renewable: boolean;
  displayName: string;
  // isAdsEnabled: boolean;
  // isBannerAdsEnabled: boolean
  serviceType: ProductServiceTypeEnum;
  productCategory: string;
  // isFreemium: boolean
  currencySymbol: string;
  suggestedPrice: number;
  ovpSKU?: OVPSKU;
  productID: string;
  // isInterstitialAdsEnabled: boolean;
};

export type GetProductsResponse = {
  productsResponseMessage: GetProductsResponseItem[];
};

export type RegisterDeviceResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
};

export type LogOutUserResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
};

export type RemoveSubscriptionResponse = {
  responseCode: number;
  message: string;
};

export type SearchAccountV2Response = {
  contactUserName: string;
  email: string;
  // channelPartnerID: string;
  responseCode: string;
  message: string;
  status: string;
  accountID: string;
  // isMobileVerified: boolean;
  // termsAndConditionsChecked: boolean;
  accountType: string;
  cpCustomerID: string;
  failureMessage?: ErrorType[];
};

export type ForgotContactPasswordResponse = {
  responseCode: string;
  message: string;
  status: string;
  failureMessage?: ErrorType[];
};

export type ChangePasswordResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
};

export type ResetPasswordResponse = {
  responseCode: string;
  message: string;
  status: string;
  failureMessage?: ErrorType[];
};

export type GetPaymentsResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
  AccountPayments?: AccountPayment[];
};
export type GetSubscriptionHistoryResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
  AccountServiceMessage?: SubscriptionHistory[];
};

export type GetFavoriteTeamsResponse = {
  responseCode: string;
  message: string;
  favourites: {
    favoriteTeams: string[];
  };
};

export type AddOrRemoveFavoriteResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
};

export type SetStringResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
  value: boolean;
};

export type GetStringResponse = {
  responseCode: string;
  message: string;
  failureMessage?: ErrorType[];
  value: string;
  attributeType: {
    attributeTypeId: number;
    objectType: string;
    attributeName: string;
    attributeLabel: string;
  };
};

export interface AddSubscriptionsResponseMessage {
  responseCode: string;
  message: string;
  orderID: string;
  failureMessage: ErrorType[];
}

export interface AddTVODOrderResponseMessage {
  responseCode: string;
  message: string;
  orderID: string;
  failureMessage: ErrorType[];
  txID: number;
  authorized: boolean;
  status: string;
  orderProductId: string;
  startDate: number;
}

export type ChangeEmailAddress = {
  responseCode: string;
  message?: string;
  status?: string;
  failureMessage?: ErrorType[];
};

export type GetCouchRightsResponseMessage = {
  responseCode: number;
  status: string;
  endDate: string;
};

export type AddOrUpdateCouchRightsResponseMessage = {
  responseCode: number;
  status: string;
  endDate: string;
};

export interface CouponAdditionalInfo {
  paramName: string;
  paramValue: string;
}

export interface CouponItem {
  op: number;
  ovpSKU: string;
  displayName: string;
  planPeriod: string;
  planDuration: string;
  isRenewable: boolean;
  serviceType: string;
  sku: string;
  name: string;
  type: string;
  price: number;
  priceToBeCharged: number;
  discountAmount: number;
  currencyCode: string;
}

export enum ServiceType {
  Product = 'Product',
}

export type GetVoucherCodeResponse = {
  responseCode: string;
  message: string;
  status: string;
  channelPartnerID: string;
  couponCodeDetails: {
    period: string;
    validityEndDate: number;
    promotionID: string;
    couponBatchName: string;
    couponAmount: number;
    couponCode: string;
    descrip: string;
    discountAmount: number;
    discountType: string;
    couponItems: CouponItem[];
    couponAdditionalInfo: CouponAdditionalInfo[];
    duration: number;
    paymentMethodRequired: boolean;
    isTVODPromo: boolean;
    promotionName: string;
    status: string;
    isPaidCoupon: boolean;
    promotionDuration: number;
    isDefaultPromo: boolean;
  };
};

export type GetProrateResponseMessage = {
  responseCode: string;
  addedItems: {
    orderQuotationInfo: {
      costBreakdown: {
        bill: {
          from: string;
          priceToBeCharged: number;
          priceAfterTax: number;
          taxRate: number;
          tax: number;
        };
      };
      serviceId: string;
      serviceType: string;
      serviceName: string;
      displayOrder: string;
      displayName: string;
      originalPrice: number;
      discounts: {
        discount: {
          description: string;
          discountType: string;
          period: string;
          duration: string;
          type: string;
          discountAmount: number;
          promotionalPrice: number;
          applicableOrder: number;
          code: string;
        };
      }[];
    }[];
  };
  totalTax: number;
  totalPriceAfterTax: number;
  totalDiscountAmount: number;
  totalTaxRate: number;
  nextBillingDateTime: number;
  amountDueToday: number;
};
