import { useCallback, useEffect, useState } from 'react';

import { Video } from '../../../../api';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

export const useGameLiveStatus = (game: Video) => {
  const [isLive, setIsLive] = useState(game.isLive);

  const updateGameStatus = useCallback(() => {
    const currentTime = new Date().getTime();

    const gameStartTime = new Date(game.startDate).getTime();
    const gameEndTime = new Date(game.endDate).getTime();

    const shouldBeLive = gameStartTime <= currentTime && gameEndTime >= currentTime;

    setIsLive(shouldBeLive);
  }, [game.endDate, game.startDate]);

  useEffect(() => {
    let checkStartIntervalId: NodeJS.Timeout | null = null;
    let checkEndIntervalId: NodeJS.Timeout | null = null;

    const currentTime = new Date().getTime();
    const gameStartDate = new Date(game.startDate).getTime();

    const timeUntilStart = gameStartDate - currentTime;

    // TODO consider using functions, can be from date-fns
    const gameShouldStartSoon = timeUntilStart > 0 && timeUntilStart < HOUR * 6;

    if (gameShouldStartSoon) {
      checkStartIntervalId = setInterval(updateGameStatus, MINUTE);
    }

    const gameEndDate = new Date(game.endDate).getTime();
    const timeUntilEnd = gameEndDate - currentTime;

    const gameShouldEndSoon = timeUntilStart < 0 && timeUntilEnd > 0 && timeUntilEnd < HOUR * 6;

    if (gameShouldEndSoon) {
      checkEndIntervalId = setInterval(updateGameStatus, MINUTE);
    }

    return () => {
      checkStartIntervalId && clearInterval(checkStartIntervalId);
      checkEndIntervalId && clearInterval(checkEndIntervalId);
    };
  }, [isLive]);

  return isLive;
};
