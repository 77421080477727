import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { Wrapper } from '../playerUI/PlayerUi.styles';

export const LogoWrapper = styled(Wrapper)`
  position: relative;
  cursor: pointer;
  height: 60vh;
`;

export const LockIconWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  ${breakpoint('lg')`
    bottom: 2rem;
    left: 1.5rem;
  `}
`;
