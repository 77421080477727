import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';

interface IDatesRange {
  end: string;
  start: string;
}

interface IDatesRangesProps {
  currentMonth: number;
  currentYear: number;
  shouldIncludePrevMonth: boolean;
}

const MONTHS_IN_YEAR = 11; // zero based

export const getOneYearRange = ({
  currentMonth,
  currentYear,
  shouldIncludePrevMonth,
}: IDatesRangesProps): IDatesRange => {
  const currentDate = new Date(currentYear, currentMonth, 1);
  const firstMonth = shouldIncludePrevMonth ? subMonths(currentDate, 1) : currentDate;

  return {
    end: endOfMonth(addMonths(firstMonth, MONTHS_IN_YEAR)).toISOString(),
    start: startOfMonth(firstMonth).toISOString(),
  };
};
