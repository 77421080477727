import { SVGProps } from 'react';

export const Pause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='13'
    height='25'
    viewBox='0 0 13 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1 1L1 24'
      stroke={props?.stroke ? props.stroke : '#EEEEEE'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 1L12 24'
      stroke={props?.stroke ? props.stroke : '#EEEEEE'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
