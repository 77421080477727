import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { PROMO_PARAM_KEY } from '../../../Constants/promotions';
import { ROUTES, RouteName } from '../../../Constants/routes';
import { activePromotionsSelector } from '../../../store/config';

export const useDetectCollectionPromo = () => {
  const { pathname } = useLocation();

  if (pathname !== ROUTES[RouteName.Promotion]) return;

  const [searchParams] = useSearchParams();
  const urlIdParam = searchParams.get(PROMO_PARAM_KEY.id);

  if (!urlIdParam) return;

  const promotions = useSelector(activePromotionsSelector);
  const promo = promotions.find((p) => p.collectionId === urlIdParam);

  return promo;
};
