/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useMemo, useState } from 'react';

import { useDataZoom } from '../../hooks/useDataZoom';
import { DataZoomContext, DataZoomContextValue } from './context';

export const DataZoomProvider = ({ children }: { children: ReactNode }) => {
  const [playbackSession, setPlaybackSession] = useState(null);

  const { appSession, userSession, userInfo } = useDataZoom();

  const setPlaybackSessionState = (playbackSession: any) => setPlaybackSession(playbackSession);

  const contextValue = useMemo<DataZoomContextValue>(
    () => ({
      appSession,
      playbackSession,
      setPlaybackSession: setPlaybackSessionState,
      userInfo,
      userSession,
    }),
    [appSession, playbackSession, userInfo, userSession],
  );

  return <DataZoomContext.Provider value={contextValue}>{children}</DataZoomContext.Provider>;
};
