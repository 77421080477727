import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { Button as AppButton } from '../Button/Button';
import { FONT_WEIGHT } from '../Common';

export const Container = styled.div.attrs((props) => ({
  className: props.className,
  ...props,
}))<{ $fullPage?: boolean }>`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0 1rem;
  width: 100%;
  max-width: 100%;

  ${breakpoint('sm')`
        margin: 0 1rem;
        padding: 0;
  `}

  ${breakpoint('md')`
      max-width: 44rem;
  `}

  ${breakpoint('lg')`
    max-width: 34.25rem;
    font-size: 1rem;
    gap: 2.5rem;
    justify-content: space-evenly;
  `}

  ${(props) =>
    props.$fullPage
      ? `
        display: flex;
        height: 100%;
        flex-direction: column;
        `
      : ''}
`;

export const IconWrapper = styled.div`
  margin: 0 auto;
`;

export const ErrorMessage = styled.p`
  font-weight: ${FONT_WEIGHT.Regular};
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors['pure-white']};
  margin-top: 0.5rem;
  ${breakpoint('lg')`
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
  ${breakpoint('sm')`
    display: flex;
    justify-content-center;
  `}
  ${breakpoint('lg')`
    margin-top: initial; 
  `}
`;

export const Button = styled(AppButton)`
  ${breakpoint('sm')`
    max-width: 21.5rem;
    margin: 0 auto;
  `}

  ${breakpoint('lg')`
    max-width: 100%;
  `}
`;
