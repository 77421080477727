import { format } from 'date-fns';

import { usePlayerTimeContext } from '../playerUI/PlayerTimeProvider/PlayerTimeProvider';
import { MappedAds } from './CompanionAds.types';


type CompanionAdsDebugViewProps = {
  ads: Array<MappedAds>;
  adToShowIndex: number;
};

const CompanionAdsDebugView = ({ ads, adToShowIndex }: CompanionAdsDebugViewProps) => {
  const { currentTime } = usePlayerTimeContext();

  if (ads.length) {
    return (
      <div className='px-5'>
        <h3 style={{ color: 'white' }}>COMPANION ADS</h3>

        {ads.map((ad, index) => {
          return (
            <div key={ad.id + index}>
              <p style={{ color: index === adToShowIndex ? 'green' : 'white', marginBottom: 4 }}>
                <span> start time </span>
                <span>
                  <b>{format(new Date(ad.startDateTime), 'h:mm:ss a')} </b>
                </span>
                <span>
                  <small style={{ color: 'lightgray' }}>{ad.startDateTime}</small>
                </span>
              </p>
              <p style={{ color: index === adToShowIndex ? 'green' : 'white', marginBottom: 4 }}>
                <span>Duration </span>
                <span> {ad.duration / 1000}s / </span>
                <span> {ad.duration}ms</span>
              </p>
              <hr style={{ color: 'lightgray' }} />
            </div>
          );
        })}
        <p style={{ color: 'white' }}>
          Player current time: <b>{format(new Date(currentTime * 1000), 'h:mm:ss a')}</b>
        </p>
      </div>
    );
  }

  return (
    <p style={{ color: 'white' }}>
      Player current time: <b>{format(new Date(currentTime * 1000), 'h:mm:ss a')}</b>
    </p>
  );
};

export { CompanionAdsDebugView };