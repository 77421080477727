import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { areResponseStatsConfigItems } from '../../components/MsgStats/utils/areResponseStatsConfigItems';
import { envName } from '../../env';
import { Log } from '../../helpers/CustomLogger';
import { stringToHashTag } from '../../helpers/searchParams';
import { RootState } from '../types';

export const fetchMsgStats = createAsyncThunk('msgStats/fetchMsgStats', async (_, thunkAPI) => {
  try {
    const {
      appConfig: {
        env: { WEB_VIEW_CONFIG_API_KEY, WEB_VIEW_CONFIG_URL, LANGUAGE },
      },
    } = thunkAPI.getState() as RootState;

    const response = await axios.get(WEB_VIEW_CONFIG_URL, {
      headers: {
        'x-api-key': WEB_VIEW_CONFIG_API_KEY,
      },
      params: {
        environment: envName === 'prod' ? 'prod' : 'qa',
        language: LANGUAGE,
        platform: 'web',
      },
    });

    const config = response?.data?.statsPage;
    const isValidResponse = areResponseStatsConfigItems(config);

    if (!isValidResponse) {
      return thunkAPI.rejectWithValue(null);
    }

    return config
      .filter((item) => item.visible)
      .map((item) => ({
        ...item,
        hashtag: stringToHashTag(item.name),
      }));
  } catch (error) {
    // TODO: Should be reported.
    Log.error('fetchWebViewConfig', error);
    return thunkAPI.rejectWithValue(null);
  }
});
