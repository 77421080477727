import { createAsyncThunk } from '@reduxjs/toolkit';

import { GeoLocationType, quickplayCmsApi } from '../../api';
import { GetEntitlementsResponse } from '../../api/authApi/authApi.types';
import { mParticleApi } from '../../api/mParticlesApi/mParticlesApi';
import { getErrorMessage } from '../../utils/error';
import { fetchProducts } from '../products';
import { getPurchaseItem } from '../purchase';
import { RootState } from '../types';
import { getCacheZoneInfo, getZoneInfoFromResponse, saveZoneInfo } from './utils/utils';
import { noZoneInfo } from './zoneInfo.constants';
import { zoneInfoActions, zoneInfoLoadAction } from './zoneInfo.slice';
import { ZoneInfoType } from './zoneInfo.types';

export const zoneInfoLoad = createAsyncThunk(zoneInfoLoadAction.type, async (__, thunksapi) => {
  try {
    thunksapi.dispatch(zoneInfoActions.loadSetLoading(true));

    const {
      appConfig: {
        env: {
          GEO_LOCATION_LOOKUP_URL,
          X_CLIENT_ID,
          CLIENT_ID,
          CLIENT_SECRET,
          AUTHORIZATION_ENDPOINT,
        },
      },
    } = thunksapi.getState() as RootState;

    const currentLocation = await quickplayCmsApi.getGeoLocalization({
      authorizationEndPoint: AUTHORIZATION_ENDPOINT,
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      geoLocationLookupUrl: GEO_LOCATION_LOOKUP_URL,
      xClientId: X_CLIENT_ID,
    });

    const zoneInfo = {
      ...getCacheZoneInfo(),
      ...getZoneInfoFromResponse(currentLocation),
    };

    saveZoneInfo(zoneInfo);
    thunksapi.dispatch(zoneInfoActions.loadSuccess(zoneInfo));
  } catch (error: unknown) {
    const errorMessage = getErrorMessage(error);

    thunksapi.dispatch(zoneInfoActions.loadError(errorMessage));
  }
});

export const zipCodeUpdate = createAsyncThunk(
  zoneInfoLoadAction.type,
  async (payload: string, thunksapi) => {
    try {
      thunksapi.dispatch(zoneInfoActions.updateSetUpdating(true));

      const {
        appConfig: {
          env: { X_CLIENT_ID, GET_ZIP_CODE_INFO_URL },
        },
        products: { selectedProduct },
      } = thunksapi.getState() as RootState;

      const appState = thunksapi.getState() as RootState;
      const zipCode = payload;
      const { zn: apiZoneKey } = await quickplayCmsApi.getZipCodeInfo({
        getZipCodeInfoUrl: GET_ZIP_CODE_INFO_URL,
        xClientId: X_CLIENT_ID,
        zipCode,
      });
      const zoneKey = appState.zoneInfo.realZone ? appState.zoneInfo.zoneKey : apiZoneKey;
      const realZone = appState.zoneInfo.realZone ? apiZoneKey : null;
      const zoneInfo: ZoneInfoType = {
        ...appState.zoneInfo,
        realZone,
        zipCode,
        zoneKey,
      };

      thunksapi.dispatch(zoneInfoActions.updateSuccess(zoneInfo));
      saveZoneInfo(zoneInfo);
      thunksapi.dispatch(fetchProducts());
      if (selectedProduct) {
        thunksapi.dispatch(getPurchaseItem(selectedProduct.skuORQuickCode));
      }
      mParticleApi.setUserData({ key: 'zip_code', value: zipCode });
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error);

      thunksapi.dispatch(zoneInfoActions.updateError(errorMessage));
    }
  },
);

export const loadZoneFromEntitlements = createAsyncThunk(
  zoneInfoLoadAction.type,
  async (payload: GetEntitlementsResponse, thunksapi) => {
    try {
      const appState = thunksapi.getState() as RootState;
      const { billingZipCode: zipCode, dmaID: apiZoneKey } = payload;
      const zoneKey = appState.zoneInfo.realZone ? appState.zoneInfo.zoneKey : apiZoneKey;
      const realZone = appState.zoneInfo.realZone ? apiZoneKey : null;

      const zoneInfo: ZoneInfoType = {
        ...appState.zoneInfo,
        realZone,
        zipCode,
        zoneKey,
      };

      thunksapi.dispatch(zoneInfoActions.updateSuccess(zoneInfo));
      saveZoneInfo(zoneInfo);
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error);

      thunksapi.dispatch(zoneInfoActions.updateError(errorMessage));
    }
  },
);

export const changeZoneKey = createAsyncThunk(
  zoneInfoLoadAction.type,
  async (payload: string, thunksapi) => {
    const appState = thunksapi.getState() as RootState;
    const zoneInfo: ZoneInfoType = {
      ...appState.zoneInfo,
      zoneKey: payload,
    };

    thunksapi.dispatch(zoneInfoActions.updateSuccess(zoneInfo));
    saveZoneInfo(zoneInfo);
  },
);

export const clearZoneInfo = createAsyncThunk(
  zoneInfoLoadAction.type,
  async (payload: never, thunksapi) => {
    const { zoneInfo } = thunksapi.getState() as RootState;

    const newZoneInfo: ZoneInfoType = {
      ...noZoneInfo,
      ...getZoneInfoFromResponse(zoneInfo.currentLocation as GeoLocationType),
    };

    thunksapi.dispatch(zoneInfoActions.updateSuccess(newZoneInfo));
    saveZoneInfo(newZoneInfo);
  },
);
