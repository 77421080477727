import styled from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { fonts } from '../../Common';
import { LegalLink } from '../../LegalLink/LegalLink';

export const Wrapper = styled.div<{ $height?: number }>`
  overflow: hidden;
  height: ${({ $height }) => `${$height}px`};
  transition: height 0.5s ease;
  margin-bottom: ${({ $height }) => ($height ? '1rem' : 0)};

  ${({ $height }) => `
    ${$height} && 
    ${breakpoint('md')`
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    `}
  `}

  box-sizing: content-box;
`;

export const Text = styled.p<{ $bold?: boolean }>`
  display: inline-block;
  width: 100%;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors['grey-deep']};
  ${fonts.body2.xs}
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')}
`;

export const Link = styled(LegalLink)`
  ${fonts.body3.xs}
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;
