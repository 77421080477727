import { GeoLocationType } from '../../../api';
import { cacheManager } from '../../../utils';
import { ZONE_SESSION_STORAGE_KEY, allowedCountries } from '../zoneInfo.constants';
import { ZoneInfoType } from '../zoneInfo.types';

export const getCacheZoneInfo = (): ZoneInfoType => {
  const cachedZoneInfo = cacheManager.load<ZoneInfoType>(ZONE_SESSION_STORAGE_KEY);
  return {
    currentLocation: cachedZoneInfo?.currentLocation || null,
    currentZone: cachedZoneInfo?.currentZone || null,
    isAllowedCountry:
      allowedCountries.includes(cachedZoneInfo?.currentLocation?.country || '') || false,
    realZone: cachedZoneInfo?.realZone || null,
    zipCode: cachedZoneInfo?.zipCode || null,
    zoneKey: cachedZoneInfo?.zoneKey || null,
  };
};
export const getZoneInfoFromResponse = (
  currentLocation: GeoLocationType,
): Partial<ZoneInfoType> => {
  return {
    currentLocation,
    currentZone: currentLocation.zone === 'unavailable' ? null : currentLocation.zone,
    isAllowedCountry: allowedCountries.includes(currentLocation.country),
  };
};
export const saveZoneInfo = (zoneInfo: ZoneInfoType) => {
  cacheManager.save<ZoneInfoType>(ZONE_SESSION_STORAGE_KEY, { ...zoneInfo });
};
