import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AssetContentType, PlaybackMode, Video } from '../../../api';
import { Restart } from '../../../assets/icons/Restart';
import { AppDispatch } from '../../../store/store';
import { selectedVideoSelector, videosActions } from '../../../store/videos';
import { useConvivaContext } from '../../ConvivaProvider/ConvivaProvider';
import { useEnv } from '../../EnvProvider/EnvProvider';
import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import * as Ui from '../PlayerUi.styles';

export const RestartButton = () => {
  const { player } = usePlayerControlsContext();
  const selectedVideo = useSelector(selectedVideoSelector);
  const { PLAYER_FORCE_RESTART_BUTTON, IS_PLAYER_IN_STORYBOOK_MODE } = useEnv();
  const { startConvivaSession } = useConvivaContext();
  const dispatch: AppDispatch = useDispatch();

  const hideRestartButton =
    !PLAYER_FORCE_RESTART_BUTTON &&
    (selectedVideo?.contentType !== AssetContentType.LIVEEVENT ||
      selectedVideo?.playbackMode === PlaybackMode.RESTART);

  const handleRestartModeClick = useCallback(() => {
    if (IS_PLAYER_IN_STORYBOOK_MODE) {
      player.seek(0);
      return;
    }
    if (selectedVideo) {
      player.stop();
      dispatch(
        videosActions.displayVideo({
          ...selectedVideo,
          isCurrentlyAiring: false,
          playbackMode:
            selectedVideo?.playbackMode === PlaybackMode.CATCHUP
              ? PlaybackMode.CATCHUP
              : PlaybackMode.RESTART,
        } as Video),
      );
      startConvivaSession();
    }
  }, [selectedVideo, dispatch, player, startConvivaSession]);

  if (hideRestartButton) {
    return null;
  }

  return (
    <Ui.PlayerButton aria-label='Restart video' onClick={handleRestartModeClick}>
      <Restart />
    </Ui.PlayerButton>
  );
};
