import styled from 'styled-components';

export const LivePill = styled.span.attrs({ className: 'p-2' })`
  font-style: normal;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'GothamNarrow';
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1em;
  font-size: 0.875rem;
  padding: 0.7em 0.9em;
  border-radius: 2em;
`;
