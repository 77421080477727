import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import {
  ErrorContentWrapper as AuthFormErrorContentWrapper,
  ErrorLine as AuthFormErrorLine,
} from '../AuthForm/AuthForm.styles';
import { Button } from '../Button';
import { FONT_WEIGHT, TypographyProps, fonts, textBase } from '../Common';
import { Input as AppInput } from '../atoms';

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  ${breakpoint('md')`
      flex-direction: row;
  `}
`;

export const Label = styled.div<TypographyProps>`
  ${(props) => textBase(props)};
  ${fonts.body2.xs}
  font-weight: ${FONT_WEIGHT.Bold};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1rem;
`;

export const Input = styled(AppInput)`
  order: 1;
  ${breakpoint('md')`
    flex: 1;
  `}
`;

export const SubmitButton = styled(Button)`
  order: 3;
  width: 100%;
  margin-top: 1rem;
  ${breakpoint('md')`
    margin-top: 0;
    margin-left: 1rem;
    order: 2;
    width: auto;
  `}
`;

export const Hr = styled.hr`
  border-color: ${({ theme }) => theme.colors['black']};
  border-width: 2px;
  margin: 1.5rem 0;
`;

export const ErrorContentWrapper = styled(AuthFormErrorContentWrapper)`
  background-color: ${({ theme }) => theme.colors['pure-white']};
  margin-top: 1rem;
  order: 2;
  ${breakpoint('md')`
    order: 3;
  `}
`;
export const ErrorLine = styled(AuthFormErrorLine)`
  background-color: ${({ theme }) => theme.colors['pure-white']};
`;
