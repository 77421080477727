import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SUBSCRIPTION_PURCHASE_ITEM } from '../../components/Modals';
import { ChannelBody } from '../../components/Schedule/components/ChannelBody/ChannelBody';
import { GameBody } from '../../components/Schedule/components/GameBody/GameBody';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useLogScreenViewedEvent } from '../../hooks/useLogScreenViewedEvent';
import { useSubscriptionNavigate } from '../../hooks/useSubscriptionNavigate';

export enum ScheduleTabs {
  GAMES = 'games',
  CHANNELS = 'channels',
}

export const Schedule = () => {
  const navigate = useExtendedNavigate();
  const params = useParams() as { tab: ScheduleTabs };
  const initialSelectedTab = params.tab || ScheduleTabs.GAMES;
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const { checkSubscription } = useSubscriptionNavigate();

  useLogScreenViewedEvent(`schedule-${params.tab}`);
  useEffect(() => {
    if (params.tab !== selectedTab) {
      setSelectedTab(params.tab);
    }
  }, [params.tab]);

  useEffect(() => {
    const storedSubPurchaseItem = sessionStorage.getItem(SUBSCRIPTION_PURCHASE_ITEM);

    storedSubPurchaseItem &&
      checkSubscription(JSON.parse(storedSubPurchaseItem), {
        replaceRoute: false,
        shouldBlockMigrationModal: true,
        shouldTriggerGoBackOnClick: false,
      });
  }, []);

  const onChangeTab = useCallback(
    (tab: ScheduleTabs) => {
      setSelectedTab(tab);
      navigate(`/schedule/${tab}`);
    },
    [navigate],
  );

  useEffect(() => {
    // if route === /schedule then change tab to initialSelectedTab
    if (!params.tab) {
      onChangeTab(initialSelectedTab);
    }
  }, [params.tab, initialSelectedTab]);

  return (
    <>
      {selectedTab === ScheduleTabs.GAMES && (
        <GameBody selectedTab={selectedTab} onChangeTab={onChangeTab} />
      )}

      {selectedTab === ScheduleTabs.CHANNELS && (
        <ChannelBody selectedTab={selectedTab} onChangeTab={onChangeTab} />
      )}
    </>
  );
};

export default Schedule;
