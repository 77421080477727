import { PropsWithChildren, useCallback } from 'react';

import {
  GetProductsResponseItem,
  ProductServiceTypeEnum,
} from '../../../api/authApi/authApi.types';
import { useTranslations } from '../../../hooks/useTranslations';
import * as Ui from './PlanCard.styles';

export interface PlanCardProps extends PropsWithChildren {
  dataPlan: GetProductsResponseItem;
  selected: boolean;
  onPress?: (dataPlan: GetProductsResponseItem) => void;
  disabled?: boolean;
}

const PlanCard = ({ dataPlan, selected, onPress, disabled, children }: PlanCardProps) => {
  const t = useTranslations();
  const { displayName, retailPrice, currencySymbol, period, serviceType } = dataPlan;
  const isSVOD = serviceType == ProductServiceTypeEnum.SVOD;
  const unit = isSVOD && period ? period.replaceAll('(s)', '') : t.planCard_game;
  const productName = isSVOD ? displayName : t.planCard_productName_perGame;

  const handlePress = useCallback(() => {
    if (onPress !== undefined) {
      onPress(dataPlan);
    }
  }, [onPress, dataPlan]);

  return (
    <Ui.Card $disabled={disabled} $selected={selected} onClick={handlePress}>
      {dataPlan.channelPartnerDescription && (
        <Ui.BestValueDiv>{dataPlan.channelPartnerDescription}</Ui.BestValueDiv>
      )}
      <Ui.CardBody $hasChildren={!!children}>
        <Ui.CardBodyInner $hasChildren={!!children}>
          <Ui.CardTitle>{productName}</Ui.CardTitle>
          <Ui.PlanCardPrice>
            {currencySymbol}
            <Ui.PlanCardPriceSpan>{retailPrice}</Ui.PlanCardPriceSpan>/{unit}
          </Ui.PlanCardPrice>
          {disabled && (
            <Ui.PlanCardDescription>
              <Ui.PlanCardDescriptionSpan>{dataPlan.productDescription}</Ui.PlanCardDescriptionSpan>
            </Ui.PlanCardDescription>
          )}
        </Ui.CardBodyInner>
        {children}
      </Ui.CardBody>
    </Ui.Card>
  );
};
export default PlanCard;
