import { SelectorButton } from './Selector.styles';
import { SelectorProps } from './Selector.types';

export const Selector = ({
  size,
  selected,
  icon,
  children,
  disabled = false,
  ...restProps
}: SelectorProps) => {
  return (
    <SelectorButton
      $size={size}
      $selected={selected}
      disabled={disabled}
      data-selected={selected}
      {...restProps}
    >
      {icon && icon}
      {children}
    </SelectorButton>
  );
};
