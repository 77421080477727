import { VideoTeam } from '../../../../api';
import { getImageResizeUrl } from '../../../../helpers/imageResizeUrl';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { useTranslations } from '../../../../hooks/useTranslations';
import * as Ui from './Team.styles';

export const Team = ({ team, teamBroadcast }: { team?: VideoTeam; teamBroadcast?: boolean }) => {
  const t = useTranslations();
  const isTeamCityVisible = useMediaQuery('lg');
  const teamName = isTeamCityVisible ? `${team?.venueCity} ${team?.shortName}` : team?.shortName;

  const imageUrl = getImageResizeUrl({
    assetId: team?.teamId ?? '',
    size: '3-1x1',
    width: isTeamCityVisible ? 64 : 19,
  });

  return (
    <Ui.Team>
      {team && (
        <>
          <img src={imageUrl} alt={team.fullName} />
          <Ui.TeamNameWrapper>
            <span>{teamName}</span>
            {teamBroadcast && (
              <>
                <Ui.Broadcast>
                  {team?.rawData?.ty === 'home' ? t.home_broadcast : t.away_broadcast}
                </Ui.Broadcast>
                <Ui.MobileBroadcast>{teamBroadcast && t.broadcast}</Ui.MobileBroadcast>
              </>
            )}
          </Ui.TeamNameWrapper>
        </>
      )}
    </Ui.Team>
  );
};
