export const buildZoneKeeper = () => {
  let zoneKey: string;
  let ready = false;
  let _resolve: () => void = () => void 0;
  const _promise = new Promise<void>(function (resolve) {
    _resolve = resolve;
  });

  const setZoneKey = (newZoneKey: string) => {
    if (!ready) {
      _resolve();
      ready = true;
    }
    zoneKey = newZoneKey;
  };

  const getZoneKey = async () => {
    await _promise;
    if (!zoneKey) {
      throw new Error('zone key is not defined');
    }
    return zoneKey;
  };

  return { _resolve, getZoneKey, setZoneKey };
};

export const zoneStore = buildZoneKeeper();
