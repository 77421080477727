import { useCallback, useEffect, useState } from 'react';

import { PlayerHandler } from '../../api';
import useFLPLocalVelocityControl from './useFLPLocalVelocityControl';

export const useFLPVelocityControlState = ({ player }: { player: PlayerHandler }) => {
  const localVelocityControlRaw = useFLPLocalVelocityControl({ player });

  const [localVelocityControl, setLocalVelocityControl] = useState(localVelocityControlRaw);
  const [velocityControl, setVelocityControl] = useState(localVelocityControl);
  const isLocalVelocityControl = localVelocityControl === velocityControl;

  const restoreLocalVelocityControl = useCallback(() => {
    setVelocityControl(localVelocityControl);
  }, [localVelocityControl, setVelocityControl]);

  useEffect(() => {
    setLocalVelocityControl(localVelocityControlRaw);
    if (isLocalVelocityControl) {
      setVelocityControl(localVelocityControlRaw);
    }
  }, [player]);

  return { restoreLocalVelocityControl, setVelocityControl, velocityControl };
};

export default useFLPVelocityControlState;
