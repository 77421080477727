import ReactDOM from 'react-dom/client';

import App from './App';
import './polyfills.ts';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    {/* HOTFIX: Double rendering removed as a performance issue for dev testing */}
    {/* <StrictMode> */}
    <App />
    {/* </StrictMode> */}
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
