import { useSelector } from 'react-redux';

import { useTranslations } from '../../hooks/useTranslations';
import { useZipCodeForm } from '../../hooks/useZipCodeForm';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { ZipCodeFormFields } from '../../utils/validations';
import { ButtonSize, ButtonVariant } from '../Button/Button.types';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { Loading } from '../Loading';
import { Input } from '../atoms';
import * as Ui from './ZipCodeModal.styles';

export const ZipCodeModalUpdateForm = ({ onCancelAction }: { onCancelAction: () => void }) => {
  const t = useTranslations();
  const { updating, updateError, currentZone, currentLocation } =
    useSelector(zoneInfoStateSelector);
  const { handleFormSubmit, isSubmitDisabled, register, submitOnEnter } = useZipCodeForm(
    currentLocation?.zip,
  );

  const showCancelButton = !!currentZone;

  return (
    <div>
      <Ui.ZipCodeInputLabel>{t.zipCodeModal_formTitle}</Ui.ZipCodeInputLabel>
      <Ui.ZipCodeInputWrapper>
        <Input
          type='text'
          placeholder={t.zipCodeModal_placeholder}
          {...register(ZipCodeFormFields.ZipCode)}
          onKeyUp={submitOnEnter}
        />
      </Ui.ZipCodeInputWrapper>

      {updateError && <div className='py-3 text-danger'>{updateError}</div>}
      <ClickEventDispatcher
        location='Modal: Update zip code modal'
        text={`CTA: ${t.zipCodeModal_formButton}`}
      >
        <Ui.ChangeZipCodeButton
          disabled={isSubmitDisabled}
          variant={ButtonVariant.Primary}
          onPress={handleFormSubmit}
          size={ButtonSize.Large}
        >
          {t.zipCodeModal_formButton}
          {updating && <Loading size='sm' />}
        </Ui.ChangeZipCodeButton>
      </ClickEventDispatcher>

      {showCancelButton && (
        <Ui.CancelButtonWrapper>
          <ClickEventDispatcher
            location='Modal: Update zip code modal'
            text={`CTA: ${t.global_cancel}`}
          >
            <Ui.CancelButton
              disabled={updating}
              variant={ButtonVariant.Secondary}
              onPress={onCancelAction}
              className='m-auto border-0'
              size={ButtonSize.Large}
            >
              <Ui.UnderlineCancel>{t.global_cancel}</Ui.UnderlineCancel>
            </Ui.CancelButton>
          </ClickEventDispatcher>
        </Ui.CancelButtonWrapper>
      )}
    </div>
  );
};
