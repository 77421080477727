import unescapeString from '../../../utils/unescapeString';
import { zoneStore } from '../../../utils/zoneKeeper';
import { FailureResponse, GetProductsResponse, GetProductsResponseItem } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getProducts = async ({
  returnAppChannels = true,
  returnTVOD = true,
  zone,
  evergentApiKey,
  evergentChannelPartnerId,
  getProductsUrl,
}: {
  returnAppChannels?: boolean;
  returnTVOD?: boolean;
  zone?: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getProductsUrl: string;
}): Promise<GetProductsResponseItem[]> => {
  const zoneKey = zone || (await zoneStore.getZoneKey());
  const data = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    dmaID: zoneKey,
    returnAppChannels: returnAppChannels ? 'T' : 'F',
    returnTVOD: returnTVOD ? 'T' : 'F',
  };

  const body = { GetProductsRequestMessage: data };

  const response = await evergentAxiosInstance.post<{
    GetProductsResponseMessage: GetProductsResponse & FailureResponse;
  }>(getProductsUrl, body);

  const { failureMessage, ...dataResponse } = response.data.GetProductsResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  const productsResponseMessage = dataResponse.productsResponseMessage.map((productItem) => ({
    ...productItem,
    currencySymbol: unescapeString(productItem.currencySymbol) ?? productItem.currencySymbol,
  }));

  return productsResponseMessage;
};
