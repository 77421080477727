import { SVGProps } from 'react';

export const MsgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='124'
    height='113'
    viewBox='0 0 124 113'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.1558 17.1505C-5.71862 40.0249 -5.71862 77.1073 17.1558 99.9818C22.5147 105.341 28.6468 109.432 35.1869 112.289C15.8672 103.053 2.51833 83.3361 2.51833 60.4938C2.51833 28.8025 28.2065 3.11436 59.8978 3.11436C89.8601 3.11436 114.464 26.0855 117.052 55.3712C116.3 41.464 110.608 27.7823 99.9872 17.1505C88.5499 5.71325 73.558 0 58.5661 0C43.5742 0 28.5931 5.71325 17.1558 17.1505Z'
      fill={props.fill || 'url(#paint0_linear_1019_15616)'}
    />
    <path
      d='M117.213 57.2511L113.916 59.2916C113.916 59.2916 113.905 59.3023 113.905 59.313V64.3068C113.905 64.3068 113.927 64.339 113.937 64.339H117.352C117.352 64.339 117.385 64.3282 117.385 64.3068V57.2189C117.385 57.2189 117.406 57.1867 117.417 57.1867H123.968C123.968 57.1867 124 57.1759 124 57.1545V54.4697C124 54.4697 123.989 54.4375 123.968 54.4375H117.417C117.417 54.4375 117.385 54.4267 117.385 54.4052V47.3281C117.385 47.3281 117.363 47.2959 117.352 47.2959H113.937C113.937 47.2959 113.905 47.3066 113.905 47.3281V54.4052C113.905 54.4052 113.884 54.4375 113.873 54.4375H107.333C107.333 54.4375 107.301 54.4482 107.301 54.4697V57.1545C107.301 57.1545 107.311 57.1867 107.333 57.1867H117.213C117.213 57.1867 117.266 57.2296 117.234 57.2404'
      fill={props.fill || 'white'}
    />
    <path
      d='M55.5377 68.1297L55.1511 68.9566H17.725V47.0487H30.1503V47.199C31.3316 46.791 32.6525 46.5762 34.113 46.5762C36.7549 46.5762 38.5913 47.3064 39.8692 48.2944C41.2653 47.3172 43.2413 46.5762 45.9906 46.5762C48.5465 46.5762 50.6406 47.199 52.2408 48.4233C52.4985 48.6166 52.9174 49.0032 53.1858 49.2824'
      stroke={props.stroke || 'white'}
      strokeWidth='0.86'
      strokeMiterlimit='10'
    />
    <path
      d='M20.1736 66.5078V49.4969H27.7018V51.8273H27.7662C29.1945 49.9587 31.3101 49.0244 34.1131 49.0244C36.916 49.0244 38.7309 49.9695 39.7404 51.8596C41.0291 49.9695 43.1125 49.0244 45.9906 49.0244C47.9988 49.0244 49.5882 49.4647 50.748 50.3668C52.0582 51.3441 52.7026 52.8153 52.7026 54.7806V66.5078H44.9274V56.8962C44.9274 55.382 44.2294 54.6303 42.844 54.6303C41.0828 54.6303 40.2022 55.5861 40.2022 57.4869V66.5078H32.674V56.8962C32.674 55.382 31.976 54.6303 30.5906 54.6303C28.8294 54.6303 27.9488 55.5861 27.9488 57.4869V66.5078H20.1736Z'
      fill={props.fill || 'white'}
    />
    <path
      d='M73.2358 68.4199C71.0128 69.2468 67.9951 69.6549 64.0431 69.6549C60.0911 69.6549 57.17 69.2468 55.0007 68.4199'
      stroke={props.stroke || 'white'}
      strokeWidth='0.86'
      strokeMiterlimit='10'
    />
    <path
      d='M52.9925 48.8313C53.4221 48.5092 53.8839 48.2299 54.3994 47.9829C56.5579 46.8983 59.6938 46.3721 63.9572 46.3721C69.9819 46.3721 73.4721 47.6823 75.4159 49.465'
      stroke={props.stroke || 'white'}
      strokeWidth='0.86'
      strokeMiterlimit='10'
    />
    <path
      d='M66.0406 60.5368L58.4588 59.7635C56.3002 59.538 54.7645 58.9796 53.8517 58.0882C53.0462 57.2828 52.6381 56.1015 52.6381 54.5443C52.6381 52.5576 53.5832 51.1078 55.4733 50.1949C57.299 49.2821 60.1234 48.8311 63.9572 48.8311C71.3351 48.8311 75.0938 50.7534 75.2119 54.6195H66.438C66.438 53.6207 65.4715 53.1267 63.5491 53.1267C61.6268 53.1267 60.7784 53.5241 60.7784 54.308C60.7784 54.888 61.4765 55.2423 62.8618 55.3605L70.2611 56.0156C74.1165 56.3485 76.0495 58.056 76.0495 61.1489C76.0495 63.3075 75.0938 64.8539 73.1714 65.799C71.2491 66.744 68.2099 67.2165 64.0431 67.2165C59.8763 67.2165 57.0197 66.7548 55.1618 65.8312C53.304 64.9076 52.3159 63.3826 52.1871 61.2455H61.3261C61.4335 61.9758 61.8201 62.4591 62.5074 62.7061C62.9263 62.8564 63.5814 62.9209 64.4942 62.9209C66.2125 62.9209 67.0716 62.4913 67.0716 61.6107C67.0716 60.9663 66.7172 60.6119 66.0191 60.5582'
      fill={props.fill || 'white'}
    />
    <path
      d='M75.3085 50.0664C75.5448 49.8516 75.9099 49.3576 76.1677 49.1643C78.4659 47.4568 81.4191 46.5869 84.9416 46.5869C86.6169 46.5869 88.1311 46.8017 89.4843 47.2313V47.0487H101.909V65.2946C101.909 68.7955 100.46 71.534 97.7212 73.1986C95.4016 74.6162 92.0939 75.2928 87.6156 75.2928C83.1374 75.2928 79.6901 74.7665 77.5101 73.6819C74.9971 72.4254 73.891 70.8145 73.3862 67.9149'
      stroke={props.stroke || 'white'}
      strokeWidth='0.86'
      strokeMiterlimit='10'
    />
    <path
      d='M91.6858 65.0795V63.619C90.3004 64.8432 88.0452 65.4554 84.9416 65.4554C81.6017 65.4554 79.0565 64.7573 77.2953 63.3612C75.534 61.9651 74.6534 59.9247 74.6534 57.2506C74.6534 54.5766 75.6522 52.622 77.6389 51.1293C79.5076 49.7439 81.9453 49.0459 84.9416 49.0459C87.9378 49.0459 90.3004 49.8406 91.9435 51.4407V49.5077H99.4717V65.305C99.4717 67.9576 98.4622 69.9014 96.454 71.1149C94.5424 72.2747 91.5999 72.8547 87.6264 72.8547C83.4381 72.8547 80.4311 72.4036 78.6055 71.4908C76.7798 70.578 75.6844 69.053 75.3085 66.9159L83.8891 66.8837C84.1361 68.0006 85.3174 68.559 87.4331 68.559C88.9902 68.559 90.0856 68.2798 90.73 67.7213C91.3743 67.1629 91.6965 66.2823 91.6965 65.0795M91.6965 57.401C91.6965 55.178 90.2038 54.0718 87.2183 54.0718C85.7041 54.0718 84.5657 54.4048 83.7925 55.0706C83.1267 55.6505 82.7937 56.4237 82.7937 57.401C82.7937 59.6562 84.265 60.7946 87.2075 60.7946C90.1501 60.7946 91.6858 59.667 91.6858 57.401'
      fill={props.fill || 'white'}
    />
    <defs>
      <linearGradient
        id='paint0_linear_1019_15616'
        x1='79.035'
        y1='85.8598'
        x2='17.7787'
        y2='4.18827'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#231F20' />
        <stop offset='0.17' stopColor='#231F20' />
        <stop offset='0.57' stopColor='white' />
        <stop offset='0.83' stopColor='#C4C5C7' />
        <stop offset='1' stopColor='#C4C5C7' />
      </linearGradient>
    </defs>
  </svg>
);
