import { Log } from '../helpers/CustomLogger';

type TokenResult = {
  details?: unknown;
  errors?: Array<{
    category: string;

    code: string;
    title: string;
  }>;
  status?: 'Abort' | 'Cancel' | 'Error' | 'Invalid' | 'OK' | 'Unknown';
  token?: string;
};

const tokenize = async (paymentMethod: { tokenize: () => Promise<TokenResult> }) => {
  let tokenResult: TokenResult = {};

  try {
    tokenResult = await paymentMethod?.tokenize();
  } catch (e) {
    Log.log('Tokenize Error =>', e);
    return;
  }

  if (tokenResult?.status === 'OK') {
    return tokenResult?.token;
  }

  if (tokenResult.status === 'Cancel') {
    return;
  }

  let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;

  if (tokenResult.errors) {
    errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
  }
  throw new Error(errorMessage);
};

export const payWithApplePay = async (payment: unknown) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const applePay = await (window as any).squarePayments.applePay(payment);
  const token = await tokenize(applePay);

  return token;
};
