import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { authSelector } from '../../store/auth';
import { deviceInfoSelector } from '../../store/deviceInfo';
import { userDataSelector } from '../../store/userData';

export const useTVEProviderUrl = ({ redirectBackUrl }: { redirectBackUrl?: string } = {}) => {
  const { WEB_APP_URL, EVERGENT_TVE_PROVIDER_URL } = useEnv();
  const { deviceId, ...deviceInfo } = useSelector(deviceInfoSelector);
  const { cpCustomerID } = useSelector(userDataSelector);
  const { accessToken } = useSelector(authSelector);
  // TODO: Use Buffer.from
  const encodeDeviceInfo = btoa(JSON.stringify(deviceInfo));
  const sessionToken = useMemo(() => `${cpCustomerID}_${new Date().getTime()}`, [cpCustomerID]);
  const redirectionUrl = useMemo(
    () => WEB_APP_URL + `/tveResult/${(redirectBackUrl || '').split('/').join('--')}`,
    [redirectBackUrl],
  );

  const redirectUrl = useCallback(
    (tveLinkCode?: string) => {
      const finalUrl = EVERGENT_TVE_PROVIDER_URL.replace(
        '&tveLinkCode={tveLinkCode}',
        tveLinkCode ? `&tveLinkCode=${tveLinkCode}` : '',
      )
        .split('{accessToken}')
        .join(accessToken as string)
        .split('{session_token}')
        .join(sessionToken as string)
        .split('{shouldRedirect}')
        .join(String(!!redirectionUrl) as string)
        .split('{redirectionUrl}')
        .join(redirectionUrl as string);
      if (tveLinkCode) {
        return finalUrl
          .split('{tveLinkCode}')
          .join(tveLinkCode as string)
          .replace('&deviceid={deviceid}', '')
          .replace('&device-info={device-info}', '');
      } else {
        return finalUrl
          .split('{deviceid}')
          .join(deviceId as string)
          .split('{device-info}')
          .join(encodeDeviceInfo as string)
          .replace('&tveLinkCode={tveLinkCode}', '');
      }
    },
    [accessToken, deviceId, sessionToken, encodeDeviceInfo, redirectionUrl],
  );

  return redirectUrl;
};
