import { endOfMonth, startOfMonth } from 'date-fns';

import { ALL } from '../../../Constants/TeamSelector';

const GAMES_PAGE_SIZE = 100;

export const prepareGetGamesParams = (team: string, date: Date) => {
  const startISO = startOfMonth(date).toISOString();
  const endISO = endOfMonth(date).toISOString();

  const params = {
    ...(team !== ALL && { team: team.toLowerCase() }),
    end: endISO,
    pageSize: GAMES_PAGE_SIZE,
    start: startISO,
  };

  return params;
};
