import { providerFlow, tizenPaymentFlow, tizenSignInFlow, tizenSignUpFlow } from '../../flow/flows';
import { cacheManager } from '../../utils';

export enum EventName {
  ButtonClicked = 'Button Clicked',
  Heartbeat = 'Heartbeat',
  ScreenViewed = 'Screen Viewed',
  SignedIn = 'Signed In',
  SignedUp = 'Signed Up',
  SessionStarted = 'Session Started',
  FavoriteTeamsSelected = 'Favorite Teams Selected',
  PurchasePlanSelected = 'Purchase Plan Selected',
  VideoDetailViewed = 'Video Detail Viewed',
  VideoPlayClicked = 'Video Play Clicked',
  VideoStarted = 'Video Started',
  VideoSeekStarted = 'Video Seek Started',
  VideoSeekEnded = 'Video Seek Ended',
  VideoErrored = 'Video Errored',
  VideoCompleted = 'Video Completed',
  ErrorReceived = 'Error Received',
}

export enum Platform {
  Chromecast = 'Chromecast',
  Web = 'Web',
  Tizen = 'Tizen',
  Ctv = 'CTV',
}

export const flowPlatformMap: Record<string, Platform> = {
  [providerFlow]: Platform.Ctv,
  [tizenPaymentFlow]: Platform.Tizen,
  [tizenSignInFlow]: Platform.Tizen,
  [tizenSignUpFlow]: Platform.Tizen,
};

export const MPARTICLE_SESSION_KEY = 'MPARTICLE_SESSION_KEY';

export const cachedMParticleSession = cacheManager.load(MPARTICLE_SESSION_KEY);
