import { Card as BootstrapCard } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { FONT_WEIGHT } from '../../Common';

export const Card = styled(BootstrapCard).attrs({
  className: 'text-light position-relative',
})<{ $disabled?: boolean; $selected?: boolean }>`
  background-color: ${({ theme }) => theme.colors['grey-dark']};
  border: ${({ theme }) => `2px solid ${theme.colors['grey-dark']}`};
  cursor: pointer;
  padding: 1.5rem 1rem;

  ${breakpoint('md')`
    padding: 1.5rem;
  `}

  ${(props) =>
    props.$disabled
      ? `
          cursor: default;
        `
      : `
          &:hover {
            border-color: ${props.theme.colors['primary']};
          }`};

  ${(props) =>
    props.$selected
      ? `
          background-color: ${props.theme.colors['grey']};
          border-color: ${props.theme.colors['primary']};
        `
      : ''};
`;

export const CardBody = styled(BootstrapCard.Body)<{ $hasChildren?: boolean }>`
  padding: 0;

  ${breakpoint('md')`
    flex-direction: column;
    justify-content: center;
  `}

  ${({ $hasChildren }) =>
    !$hasChildren &&
    breakpoint('md')`
      padding-top: 3rem;
      padding-bottom: 3rem;
    `}
`;

export const CardBodyInner = styled.div<{ $hasChildren?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $hasChildren }) =>
    $hasChildren
      ? css`
          margin-bottom: 0.5rem;
        `
      : css`
          height: 100%;
        `}

  ${breakpoint('md')`
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    `}
`;

export const CardTitle = styled(BootstrapCard.Title)`
  text-align: center;
  margin-bottom: 0;
  font-family: 'GothamNarrow';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.5rem;

  ${breakpoint('md')`
    text-align: center;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: ${FONT_WEIGHT.Bold};;
    line-height: 2rem;
  `}
`;

export const BestValueDiv = styled.div.attrs({
  className: 'position-absolute fw-bold d-inline-block',
})`
  right: -2px;
  top: -2px;
  background-color: ${({ theme }) => theme.colors['success']};
  color: ${({ theme }) => theme.colors['off-white']};
  font-size: 0.75rem;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 0.75rem;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 0.38rem 0.5rem;

  ${breakpoint('md')`
    right: -0.18rem;
    font-size: 1rem;
    line-height: 1.25rem;
  `}
`;

export const PlanCardPrice = styled.div`
  text-transform: lowercase;
  color: ${({ theme }) => theme.colors['off-white']};
  font-family: 'GothamNarrow';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.5rem;

  ${breakpoint('md')`
    text-align: center;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: ${FONT_WEIGHT.Bold};;
    line-height: 2rem;
  `}
`;

export const PlanCardPriceSpan = styled.span`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 2rem;
  letter-spacing: 0.07rem;
`;

export const PlanCardDescription = styled.div.attrs({})`
  display: flex;
  justify-content: center;
`;

export const PlanCardDescriptionSpan = styled.span``;
