import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isActiveSelector } from '../store/browser';
import {
  addOrUpdateCouchRights,
  couchRightsActions,
  shouldUpdateCouchRightsOnStreamStartSelector,
} from '../store/couchRights';
import { playerAssetSelector } from '../store/player';
import { AppDispatch } from '../store/store';
import { timeOfLastLocationFetchSelector } from '../store/zoneInfo';
import { getDifferenceTimeInHours } from '../utils/getTimeDifferenceInHours';

export const useCouchRights = () => {
  const dispatch: AppDispatch = useDispatch();

  const asset = useSelector(playerAssetSelector);
  const isFirstRender = useRef(true);
  const isTabActive = useSelector(isActiveSelector);
  const timeOfLastLocationFetch = useSelector(timeOfLastLocationFetchSelector);

  const shouldUpdateCouchRightsOnStreamStart = useSelector(
    shouldUpdateCouchRightsOnStreamStartSelector,
  );

  // update couch rights when video starts
  useEffect(() => {
    if (asset) {
      if (shouldUpdateCouchRightsOnStreamStart) {
        dispatch(addOrUpdateCouchRights());
      } else {
        // reset flag value
        dispatch(couchRightsActions.setShouldUpdateCouchRightsOnStreamStart(true));
      }
      isFirstRender.current = false;
    }
  }, [asset]);

  // update couch rights when application comes back from the background and
  // last location check was made more than 1h ago
  useEffect(() => {
    if (isTabActive && !isFirstRender.current) {
      const differenceInHours = getDifferenceTimeInHours(Date.now(), timeOfLastLocationFetch || 0);
      if (differenceInHours > 1) {
        dispatch(addOrUpdateCouchRights());
      }
    }
  }, [isTabActive, isFirstRender.current]);
};
