import { SVGProps } from 'react';

export const ClockFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props?.width ?? '25'}
    height={props?.width ?? '24'}
    viewBox='0 0 25 24'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8307 22.0013C18.1695 22.0013 22.4974 17.6734 22.4974 12.3346C22.4974 6.99588 18.1695 2.66797 12.8307 2.66797C7.49198 2.66797 3.16406 6.99588 3.16406 12.3346C3.16406 17.6734 7.49198 22.0013 12.8307 22.0013ZM13.5468 6.96427C13.5468 6.5688 13.2262 6.24822 12.8307 6.24822C12.4353 6.24822 12.1147 6.5688 12.1147 6.96427V11.4448C12.1147 12.2045 12.4164 12.933 12.9536 13.4701L15.5466 16.0632C15.8263 16.3428 16.2796 16.3428 16.5593 16.0632C16.8389 15.7835 16.8389 15.3302 16.5593 15.0505L13.9662 12.4575C13.6977 12.1889 13.5468 11.8247 13.5468 11.4448V6.96427Z'
      fill={props?.fill ?? 'white'}
    />
  </svg>
);
