import { useCallback, useContext, useState } from 'react';

import { PlayerHandlerContext } from '../../Player/Player';

const MAX_VOLUME = 100;
const MIN_VOLUME = 0;

export const useVolumeControl = () => {
  const {
    volumeControlState: {
      volumenControl: {
        volume,
        volumeControl: { adjustVolume },
      },
    },
  } = useContext(PlayerHandlerContext);

  const [previousVolume, setPreviousVolume] = useState<number>();

  const handleChange = useCallback(
    (newValue: number) => {
      setPreviousVolume(volume);
      adjustVolume(newValue);
    },
    [adjustVolume, volume],
  );

  const handleVolumeMin = useCallback(() => {
    if (volume === MIN_VOLUME && previousVolume !== undefined) {
      setPreviousVolume(MIN_VOLUME);
      adjustVolume(previousVolume);
    } else {
      setPreviousVolume(volume);
      adjustVolume(MIN_VOLUME);
    }
  }, [adjustVolume, volume, previousVolume]);

  const handleVolumeMax = useCallback(() => {
    if (volume === MAX_VOLUME && previousVolume !== undefined) {
      setPreviousVolume(MAX_VOLUME);
      adjustVolume(previousVolume);
    } else {
      setPreviousVolume(volume);
      adjustVolume(MAX_VOLUME);
    }
  }, [adjustVolume, previousVolume, volume]);

  return {
    handleChange,
    handleVolumeMax,
    handleVolumeMin,
    volume,
  };
};
