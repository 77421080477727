import { forwardRef } from 'react';

import { Loading } from '../Loading';
import * as Ui from './Button.styles';
import { ButtonProps, ButtonSize, ButtonVariant, DisabledButtonVariant } from './Button.types';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonWeb(
  {
    onPress,
    variant = ButtonVariant.Primary,
    disabledVariant = DisabledButtonVariant.Regular,
    size = ButtonSize.Large,
    disabled,
    className,
    type = 'button',
    selected,
    isSelector = false,
    w100,
    shouldLookLikeDisabled,
    loading = false,
    ...props
  },
  ref,
) {
  return (
    <Ui.Button
      onClick={onPress}
      className={className}
      disabled={disabled}
      ref={ref}
      type={type}
      data-cy={props.text ?? props.children}
      $disabled={disabled || shouldLookLikeDisabled}
      $size={size}
      $variant={variant}
      $disabledVariant={disabledVariant}
      $selected={selected}
      $isSelector={isSelector}
      $w100={w100}
    >
      <span className={loading ? 'button-content-hidden' : ''}>{props.text ?? props.children}</span>
      {loading && (
        <span className='button-loader'>
          <Loading size='sm' />
        </span>
      )}
    </Ui.Button>
  );
});
