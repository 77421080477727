import { createSelector } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns';

import { getDifferenceTimeInHours } from '../../utils/getTimeDifferenceInHours';
import { hasTveProviderSelector } from '../activeSubscriptions/activeSubscriptions.selectors';
// to do: figure it out why authSelector is undefined
// import { authSelector } from '../auth/auth.selectors';
import { isActiveSelector } from '../browser';
import { envSelector } from '../config';
import { deviceIdSelector } from '../deviceInfo';
import { RootState } from '../types';

export const couchRightsSelector = (state: RootState) => state.couchRights;

export const couchRightsValidDateSelector = (state: RootState) => state.couchRights.endDate;

export const areCouchRightsValidSelector = createSelector(
  couchRightsSelector,
  ({ endDate }) => !endDate || isBefore(new Date(), new Date(endDate)),
);

export const shouldFetchOrUpdateCouchRightsSelector = createSelector(
  (state: RootState) => state.auth,
  hasTveProviderSelector,
  isActiveSelector,
  deviceIdSelector,
  (auth, hasTveProvider, isPageActive, deviceId) => {
    const { authed, isVIPUser } = auth;

    return Boolean(authed && !isVIPUser && !hasTveProvider && isPageActive && deviceId);
  },
);

export const shouldUpdateCouchRightsOnStreamStartSelector = createSelector(
  couchRightsSelector,
  ({ shouldUpdateCouchRightsOnStreamStart }) => shouldUpdateCouchRightsOnStreamStart,
);

export const timeOfLastCouchRightUpdateSelector = createSelector(
  couchRightsSelector,
  ({ timeOfLastUpdate }) => timeOfLastUpdate,
);

export const shouldUpdateCouchRightsSelector = (state: RootState) => {
  const timeOfLastCouchRightUpdate = timeOfLastCouchRightUpdateSelector(state);
  const envs = envSelector(state);

  if (!timeOfLastCouchRightUpdate) {
    return true;
  }

  const differenceInHours = getDifferenceTimeInHours(Date.now(), timeOfLastCouchRightUpdate);

  return differenceInHours > envs.NUMBER_OF_HOURS_LOCATION_IS_VALID;
};
