import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fillStringTemplate } from '../../../helpers/fillStringTemplate';
import { useTranslations } from '../../../hooks/useTranslations';
import {
  isSubscriptionPausedSelector,
  lastSubscriptionSelector,
  pauseSubscription,
  resumeSubscription,
} from '../../../store/activeSubscriptions';
import { AppDispatch } from '../../../store/store';
import { useSimpleModalContext } from '../../Modals/SimpleModal/SimpleModalContext';

export const usePauseSubscription = () => {
  const dispatch: AppDispatch = useDispatch();
  const t = useTranslations();
  const { openModal } = useSimpleModalContext();
  const subscription = useSelector(lastSubscriptionSelector);
  const isSubscriptionPaused = useSelector(isSubscriptionPausedSelector);

  const validityTillDate = useMemo(
    () => (subscription ? new Date(subscription.validityTill) : null),
    [subscription],
  );

  const pauseStartDate = useMemo(
    () =>
      subscription?.pauseStartDate
        ? format(new Date(subscription?.pauseStartDate), 'MMMM d, yyyy, h:mm a')
        : '',
    [subscription?.pauseStartDate],
  );

  const pauseEndDate = useMemo(
    () =>
      subscription?.pauseEndDate
        ? format(new Date(subscription?.pauseEndDate), 'MMMM d, yyyy, h:mm a')
        : '',
    [subscription?.pauseEndDate],
  );

  const onErrorHandler = () => {
    openModal({
      cancelButton: {
        text: t.global_close,
      },
      description: t.global_error_description,
      modalName: 'Pause Subscription Failed',
      title: t.global_error_title,
    });
  };

  const onPauseSubscriptionSuccess = useCallback((endDate: string) => {
    openModal({
      cancelButton: {
        text: t.global_close,
      },
      description: fillStringTemplate(t.settings_pause_subscription_postPauseModal_description, {
        endDate,
      }),
      modalName: 'Pause Subscription Success',

      title: t.settings_pause_subscription_postPauseModal_title,
    });
  }, []);

  const handlePauseSubscription = useCallback(() => {
    openModal({
      acceptButton: {
        onClick: () =>
          dispatch(
            pauseSubscription({
              onError: onErrorHandler,
              onSuccess: (endDate: string) => {
                onPauseSubscriptionSuccess(endDate);
              },
            }),
          ),
        text: t.settings_pause_subscription_modal_cta,
      },
      cancelButton: {
        text: t.global_close,
      },
      description: t.settings_pause_subscription_modal_description,
      modalName: 'Pause Subscription',

      title: t.settings_pause_subscription_modal_title,
    });
  }, [onPauseSubscriptionSuccess]);

  const onResumeSubscriptionSuccess = () => {
    openModal({
      cancelButton: {
        text: t.global_close,
      },
      description: fillStringTemplate(
        t.settings_pause_subscription_postResumeSubscriptionModal_description,
        {
          billingDate: validityTillDate ? format(validityTillDate, 'MM/dd/yyyy') : '',
        },
      ),
      modalName: 'Resume subscription success',
      title: t.settings_pause_subscription_postResumeSubscriptionModal_title,
    });
  };

  const handleResumeSubscription = useCallback(() => {
    openModal({
      acceptButton: {
        onClick: () =>
          dispatch(
            resumeSubscription({ onError: onErrorHandler, onSuccess: onResumeSubscriptionSuccess }),
          ),
        text: t.settings_pause_subscription_resumeSubscriptionModal_cta,
      },
      cancelButton: {
        text: t.global_close,
      },
      description: t.settings_pause_subscription_resumeSubscriptionModal_description,
      modalName: 'Resume subscription',
      title: t.settings_pause_subscription_resumeSubscriptionModal_title,
    });
  }, [subscription, onResumeSubscriptionSuccess]);

  return {
    handlePauseSubscription,
    handleResumeSubscription,
    isSubscriptionPaused,
    onErrorHandler,
    onPauseSubscriptionSuccess,
    onResumeSubscriptionSuccess,
    pauseEndDate,
    pauseStartDate,
  };
};
