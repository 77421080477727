import { createSelector } from '@reduxjs/toolkit';

import { envName } from '../../env';
import { RootState } from '../types';
import { zoneInfoStateSelector } from '../zoneInfo/zoneInfo.selectors';

export const authSelector = (state: RootState) => state.auth;

export const accessTokenSelector = createSelector(authSelector, ({ accessToken }) => accessToken);

export const ignoreRegionValidationSelector = createSelector(
  [authSelector, zoneInfoStateSelector],
  (auth, zoneInfo) => {
    const { authed, isVIPUser } = auth;
    const { loaded: zoneInfoLoaded } = zoneInfo;

    return envName === 'dev' || !authed || isVIPUser || !zoneInfoLoaded;
  },
);

export const isUserLoggedInForbidenRegionSelector = createSelector(
  [ignoreRegionValidationSelector, zoneInfoStateSelector],
  (ignoreRegionValidation, zoneInfo) => {
    const { isAllowedCountry } = zoneInfo;

    if (ignoreRegionValidation) {
      return false;
    }

    return !isAllowedCountry;
  },
);

export const isAuthedSelector = createSelector(authSelector, (auth) => auth.authed);
