import clsx from 'clsx';
import { CSSProperties } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import logo from '../../assets/images/msg_plus_logo.png';
import { ColSpec } from '../Common';
import * as Ui from './Logo.styles';

export type LogoSize = 'medium' | 'large' | 'small' | 'responsive';

const Logo = ({
  imgStyles,
  imgClasses,
  colClasses,
  imgSize,
  colXs,
  colStyles,
  ...rest
}: {
  imgStyles?: CSSProperties;
  imgClasses?: string;
  imgSize?: LogoSize;
  colClasses?: string;
  colXs?: ColSpec;
  colStyles?: CSSProperties;
}) => {
  return (
    <Row {...rest}>
      <Col xs={colXs} style={colStyles} className={clsx('text-center', colClasses)}>
        <Ui.Logo
          src={logo}
          style={imgStyles}
          alt={'MSG Logo'}
          data-cy='logo'
          size={imgSize}
          className={clsx(imgClasses)}
        />
      </Col>
    </Row>
  );
};

export default Logo;
