import { PropsWithChildren, RefObject } from 'react';

export type GenericCarouselProps = PropsWithChildren & {
  scrollRef: RefObject<HTMLDivElement>;
  isBackwardButtonDisabled: boolean;
  isForwardButtonDisabled: boolean;
  handleControlBackward: () => void;
  handleControlForward: () => void;
  containerSize: number;
  $align: GC_ALIGN;
  $isFullWidth: boolean;
};

export enum GC_ALIGN {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}
