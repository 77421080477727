import { Checkmark } from '../../assets/icons';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { Button } from '../Button';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { Col, Typography } from '../Common';
import { colors } from '../ThemeProvider/constants/colors';
import * as Ui from './ResetPasswordDone.styles';

export const ResetPasswordDone = () => {
  const t = useTranslations();
  const navigate = useExtendedNavigate();

  return (
    <Ui.ContentContainer>
      <Ui.Logo />

      <Ui.CheckWrapper>
        <Checkmark fill={colors['pure-white']} />
      </Ui.CheckWrapper>

      <Typography.Header2 $bolder $uppercase $mb={1.5}>
        {t.resetPasswordDone_title}
      </Typography.Header2>

      <Typography.Body1 $mb={8.375}>{t.resetPasswordDone_subtitle}</Typography.Body1>

      <Ui.ButtonRow>
        <Col md={8} xl={4}>
          <ClickEventDispatcher
            destination='/login'
            location='Module: Reset Password Done'
            text={`CTA: ${t.global_ok}`}
          >
            <Button w100 onPress={() => navigate('/login', { replace: true })} disabled={false}>
              {t.global_ok}
            </Button>
          </ClickEventDispatcher>
        </Col>
      </Ui.ButtonRow>
    </Ui.ContentContainer>
  );
};

ResetPasswordDone.displayName = 'ResetPasswordDone';
