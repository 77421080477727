import { SVGProps } from 'react';

import { colors } from '../../../components/ThemeProvider/constants/colors';

interface Props extends SVGProps<SVGSVGElement> {
  active: boolean;
}

export const Live = (props: Props) => {
  if (props.active) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4 6.00033C3.63181 6.00033 3.33333 6.2988 3.33333 6.66699V19.3337C3.33333 19.7018 3.63181 20.0003 4 20.0003H20C20.3682 20.0003 20.6667 19.7019 20.6667 19.3337V6.66699C20.6667 6.2988 20.3682 6.00033 20 6.00033H4ZM2 6.66699C2 5.56242 2.89543 4.66699 4 4.66699H20C21.1046 4.66699 22 5.56242 22 6.66699V19.3337C22 20.4382 21.1046 21.3337 20 21.3337H4C2.89543 21.3337 2 20.4382 2 19.3337V6.66699Z'
          fill={props.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.08252 0.813711C7.31309 0.526653 7.72198 0.493478 7.99581 0.739615L12.1958 4.51494C12.4697 4.76108 12.5047 5.19332 12.2742 5.48038C12.0436 5.76744 11.6347 5.80061 11.3609 5.55447L7.16085 1.77915C6.88702 1.53301 6.85196 1.10077 7.08252 0.813711Z'
          fill={props.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.5815 0.813711C17.351 0.526653 16.9421 0.493478 16.6683 0.739615L12.4682 4.51494C12.1944 4.76108 12.1593 5.19332 12.3899 5.48038C12.6205 5.76744 13.0294 5.80061 13.3032 5.55447L17.5032 1.77915C17.777 1.53301 17.8121 1.10077 17.5815 0.813711Z'
          fill={props.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.99935 5.3335C3.26297 5.3335 2.66602 5.93045 2.66602 6.66683V19.3335C2.66602 20.0699 3.26297 20.6668 3.99935 20.6668H19.9993C20.7357 20.6668 21.3327 20.0699 21.3327 19.3335V6.66683C21.3327 5.93045 20.7357 5.3335 19.9993 5.3335H3.99935ZM10.0849 8.84029C9.99935 8.95755 9.99935 9.17027 9.99935 9.59572V15.7379C9.99935 16.1634 9.99935 16.3761 10.0849 16.4934C10.1595 16.5955 10.2734 16.6587 10.3968 16.6664C10.5384 16.6751 10.7091 16.5571 11.0506 16.3211L15.4947 13.25C15.7769 13.055 15.9179 12.9575 15.9671 12.8346C16.0101 12.7272 16.0101 12.6065 15.9671 12.499C15.9179 12.3761 15.7769 12.2786 15.4947 12.0836L11.0506 9.01251C10.7091 8.77652 10.5384 8.65852 10.3968 8.66728C10.2734 8.67492 10.1595 8.73813 10.0849 8.84029Z'
          fill={props.fill || colors.primary}
        />
      </svg>
    );
  }

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 6.00008C3.63181 6.00008 3.33333 6.29856 3.33333 6.66675V19.3334C3.33333 19.7016 3.63181 20.0001 4 20.0001H20C20.3682 20.0001 20.6667 19.7016 20.6667 19.3334V6.66675C20.6667 6.29856 20.3682 6.00008 20 6.00008H4ZM2 6.66675C2 5.56218 2.89543 4.66675 4 4.66675H20C21.1046 4.66675 22 5.56218 22 6.66675V19.3334C22 20.438 21.1046 21.3334 20 21.3334H4C2.89543 21.3334 2 20.438 2 19.3334V6.66675Z'
        fill={props.fill || colors.grey}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.08643 0.813589C7.317 0.52653 7.72589 0.493356 7.99971 0.739493L12.1997 4.51482C12.4736 4.76096 12.5086 5.1932 12.2781 5.48026C12.0475 5.76731 11.6386 5.80049 11.3648 5.55435L7.16476 1.77903C6.89093 1.53289 6.85586 1.10065 7.08643 0.813589Z'
        fill={props.fill || colors.grey}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5854 0.813589C17.3549 0.52653 16.946 0.493356 16.6722 0.739493L12.4721 4.51482C12.1983 4.76096 12.1632 5.1932 12.3938 5.48026C12.6244 5.76731 13.0333 5.80049 13.3071 5.55435L17.5071 1.77903C17.7809 1.53289 17.816 1.10065 17.5854 0.813589Z'
        fill={props.fill || colors.grey}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4081 8.44946C11.4151 8.45427 11.422 8.45908 11.429 8.4639L15.873 11.535C15.8793 11.5393 15.8855 11.5436 15.8917 11.5479C16.0164 11.6341 16.1445 11.7225 16.2452 11.8067C16.3532 11.8969 16.5001 12.0379 16.5854 12.2512C16.692 12.5176 16.692 12.8157 16.5854 13.0821C16.5001 13.2954 16.3532 13.4364 16.2452 13.5266C16.1445 13.6108 16.0164 13.6993 15.8917 13.7854C15.8855 13.7897 15.8793 13.794 15.873 13.7983L11.4081 16.8839C11.2556 16.9893 11.1052 17.0932 10.9746 17.1666C10.845 17.2394 10.6253 17.3483 10.3549 17.3316C10.0283 17.3114 9.73414 17.1443 9.54577 16.8862C9.39168 16.6751 9.35854 16.4393 9.34532 16.2898C9.33198 16.1389 9.33201 15.9537 9.33203 15.7625L9.33204 9.59555C9.33204 9.58731 9.33203 9.57907 9.33203 9.57084C9.33201 9.37962 9.33198 9.19445 9.34532 9.04357C9.35854 8.89401 9.39168 8.65826 9.54577 8.44712C9.73414 8.18899 10.0283 8.02194 10.3549 8.00172C10.6253 7.98499 10.845 8.09393 10.9746 8.16671C11.1053 8.24011 11.2556 8.34404 11.4081 8.44946ZM10.6654 9.55693C10.6654 9.56947 10.6654 9.58234 10.6654 9.59555V15.7378C10.6654 15.751 10.6654 15.7639 10.6654 15.7764C10.6672 15.7751 10.6691 15.7738 10.671 15.7725L15.115 12.7014C15.1327 12.6892 15.1494 12.6777 15.1653 12.6667C15.1494 12.6557 15.1327 12.6441 15.115 12.6319L10.671 9.56079C10.6691 9.5595 10.6672 9.55822 10.6654 9.55693Z'
        fill={props.fill || colors.grey}
      />
    </svg>
  );
};
