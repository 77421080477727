import styled from 'styled-components';

import { flexCenter } from '../../utils/styled/mixins';
import { FONT_WEIGHT } from '../Common';

export const Button = styled.button<{ $isFloating: boolean }>`
  position: ${({ $isFloating }) => ($isFloating ? 'absolute' : 'relative')};
  color: ${({ theme }) => theme.colors['off-white']};
  font-family: 'GothamNarrow';
  font-weight: ${FONT_WEIGHT.Bold};
  font-style: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  text-transform: uppercase;
  background: transparent;
  border: none;
  ${flexCenter}
  svg {
    margin-right: 0.75rem;
  }
  &:focus {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors['grey']};
    color: ${({ theme }) => theme.colors.primary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.grey};
    path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }
`;
