import { useSelector } from 'react-redux';

import { Video } from '../../../../api';
import { ClockFilled, PadlockFilled, Play } from '../../../../assets/icons';
import { isSubscriptionPausedSelector } from '../../../../store/activeSubscriptions';
import { colors } from '../../../ThemeProvider/constants/colors';
import * as UIAction from '../ActionButton/ActionButton.styles';

interface ProgramButtonProps {
  program: Video;
  hasActiveSubscriptions: boolean;
}

export const ProgramButton = ({ program, hasActiveSubscriptions }: ProgramButtonProps) => {
  const isSubscriptionPaused = useSelector(isSubscriptionPausedSelector);

  if (!hasActiveSubscriptions || isSubscriptionPaused) {
    return (
      <UIAction.PadlockButton>
        <PadlockFilled fill={colors.primary} width={24} height={24} />
      </UIAction.PadlockButton>
    );
  }

  if (program.isCurrentlyAiring) {
    return (
      <UIAction.PlayButton>
        <Play stroke={colors.primary} fill={colors.primary} width={20} height={20} />
      </UIAction.PlayButton>
    );
  }

  return (
    <UIAction.Clock>
      <ClockFilled width={24} height={24} fill={colors.primary} />
    </UIAction.Clock>
  );
};
