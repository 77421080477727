import { useEffect } from 'react';

import { useLogScreenViewedEvent } from '../../hooks/useLogScreenViewedEvent';
import { useModalContext } from '../Modals/context';

const LANDING_WRAPPER_ID = 'landing-wrapper';

export const LandingPageModal = ({ html, id }: { html: string; id: string }) => {
  useLogScreenViewedEvent(`landing-page-${id}`);
  const { closeModal } = useModalContext();

  useEffect(() => {
    const linkElements = document.querySelectorAll(`#${LANDING_WRAPPER_ID} a`);

    [].forEach.call(linkElements, function (e: Element) {
      e.addEventListener(
        'click',
        (e) => {
          e.preventDefault();
          closeModal();
        },
        false,
      );
    });
  }, []);

  return <div id={LANDING_WRAPPER_ID} dangerouslySetInnerHTML={{ __html: html }} />;
};
