export const colors = {
  'additional-color-black': '#000000',
  aqua: '#18C5DC',
  bgFallbackColor: '#02101f',
  black: '#111111',
  error: '#F44336',
  grey: '#626266',
  'grey-3': '#828282',
  'grey-dark': '#333333',
  'grey-deep': '#202020',
  'grey-light': '#F2F2F4',
  'grey-medium': '#D6D8E1',
  'grey-soft': '#B2B4BD',
  'off-white': '#EEEEEE',
  primary: '#FFB800',
  'primary-dark': '#423000',
  'primary-dark-2': '#A57700',
  'primary-light': '#FFFBF2',
  'primary-medium': '#FFE9AF',
  'primary-soft': '#F9D476',
  'pure-black': '#000000',
  'pure-white': '#FFFFFF',
  'scrim-black': 'rgba(17, 17, 17, 0.8)',
  'scrim-white': 'rgba(238, 238, 238, 0.50)',
  success: '#009F53',
  teal: '#00466B',
  'teal-d': '#14234C',
  warning: '#DBAB00',
  'white-scrim': 'rgba(238, 238, 238, 0.5)',
} as const;
