import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Location } from 'react-router';

import { RouterState } from './router.types';

export const initialState: RouterState = {
  previousLocation: undefined,
};

const slice = createSlice({
  initialState,
  name: 'router',
  reducers: {
    setPreviousLocation: (state, action: PayloadAction<Location>) => {
      return { ...state, previousLocation: action.payload };
    },
  },
});

const { name, actions, reducer } = slice;

export { actions as routerActions, name, reducer };
