import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetProductsResponseItem } from '../../api/authApi/authApi.types';
import { useCompletePaymentActions } from '../../hooks/evergent/useCompletePaymentActions';
import { useTVODOrder } from '../../hooks/evergent/useTVODOrder';
import { openSubscriptionModal } from '../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { accessTokenSelector } from '../../store/auth';
import {
  addSubscriptions,
  addTVODOrder,
  payPalOnApproveDataSelector,
  paymentRequestPendingSelector,
  purchaseActions,
  purchaseSelector,
} from '../../store/purchase';
import { AppDispatch } from '../../store/store';
import { videosSelector } from '../../store/videos';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { BackButton } from '../BackButton/BackButton';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { useModalContext } from '../Modals/context';
import { PayPalInfoForm, PayPalInfoFormValues } from '../PayPalInfoForm/PayPalInfoForm';
import { AvailablePaymentOptions } from '../PaymentOptions';
import * as Ui from '../PurchaseModal/PurchaseModal.styles';
import { SelectPaymentModal } from '../PurchaseModal/SelectPaymentModal/SelectPaymentModal';
import * as PayPalUi from './PayPalPaymentModal.styles';

const PayPalPaymentModal = ({
  product,
  onSuccess,
  onSkip,
  passedOnGoBack,
  selectPaymentOnGoBack,
}: {
  product: GetProductsResponseItem;
  onSuccess: (product: GetProductsResponseItem) => void;
  onSkip: () => void;
  passedOnGoBack?: () => void;
  selectPaymentOnGoBack?: () => void;
}) => {
  const t = useTranslations();
  const dispatch: AppDispatch = useDispatch();
  const { zipCode, zoneKey } = useSelector(zoneInfoStateSelector);
  const accessToken = useSelector(accessTokenSelector);
  const paymentRequestPending = useSelector(paymentRequestPendingSelector);
  const payPalOnApproveData = useSelector(payPalOnApproveDataSelector);
  const { openModal, closeModal } = useModalContext();
  const { purchaseVideo } = useSelector(videosSelector);
  const { item, voucherCode } = useSelector(purchaseSelector);
  const { isTVODPurchase, purchaseToken } = useTVODOrder({
    product,
  });

  useCompletePaymentActions({
    product,
  });

  const onButtonPress = useCallback(
    (formValues: PayPalInfoFormValues) => {
      if (payPalOnApproveData && item) {
        if (isTVODPurchase) {
          dispatch(
            addTVODOrder({
              accessToken,
              assetType: purchaseVideo?.productType || '',
              formValues,
              onApproveData: payPalOnApproveData,
              paymentMethod: AvailablePaymentOptions.PAYPAL,
              price: item?.amountDueToday,
              product: product,
              purchaseToken: purchaseToken || '',
              voucherCode,
              zipCode: zipCode || '',
              zone: zoneKey || '',
            }),
          );
        } else {
          dispatch(
            addSubscriptions({
              accessToken,
              formValues,
              onApproveData: payPalOnApproveData,
              paymentMethod: AvailablePaymentOptions.PAYPAL,
              product: product,
              voucherCode,
              zipCode: zipCode || '',
              zone: zoneKey || '',
            }),
          );
        }
      }
    },
    [
      payPalOnApproveData,
      isTVODPurchase,
      purchaseVideo,
      purchaseToken,
      product,
      item?.amountDueToday,
      zoneKey,
      voucherCode,
    ],
  );

  const onGoBackAction = useCallback(() => {
    dispatch(purchaseActions.setPayPalOnApproveData(null));
    openSubscriptionModal({
      content: (
        <SelectPaymentModal
          product={product}
          onSuccess={onSuccess}
          onSkip={onSkip}
          passedOnGoBack={passedOnGoBack}
          onGoBack={selectPaymentOnGoBack}
        />
      ),
      openModal,
    });
  }, [closeModal, dispatch, onSkip]);

  return (
    <Ui.ModalBody>
      <Ui.ModalContainer $minSvh100>
        <ClickEventDispatcher
          destination='/home'
          location='Module: PayPal Payment Form'
          screen='Paypal payment Form Modal'
          text='CTA: Back'
        >
          <BackButton onClick={onGoBackAction} isFloating={false} />
        </ClickEventDispatcher>
        <PayPalUi.ContentContainer>
          <Ui.Wrapper xs={12} md={12} xl={6} fullHD={4}>
            <Ui.TitleRow>
              <PayPalUi.Title>{t.paypal_payment_modal_title}</PayPalUi.Title>
              <PayPalUi.TitleDescription>
                {t.paypal_payment_modal_description}
              </PayPalUi.TitleDescription>
            </Ui.TitleRow>
            <PayPalInfoForm
              onSuccess={onButtonPress}
              disabled={paymentRequestPending}
              amount={item?.amountDueToday}
            />
          </Ui.Wrapper>
        </PayPalUi.ContentContainer>
      </Ui.ModalContainer>
    </Ui.ModalBody>
  );
};

export default PayPalPaymentModal;
