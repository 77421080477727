import { FailureResponse, RegisterDeviceResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const registerDevice = async ({
  accessToken,
  activationCode,
  evergentApiKey,
  evergentChannelPartnerId,
  registerDeviceUrl,
}: {
  accessToken: string;
  activationCode: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  registerDeviceUrl: string;
}): Promise<RegisterDeviceResponse> => {
  const registerDeviceData = {
    activationCode,
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };
  const body = { RegisterDeviceRequestMessage: registerDeviceData };

  const response = await evergentAxiosInstance.post<{
    RegisterDeviceResponseMessage: RegisterDeviceResponse & FailureResponse;
  }>(registerDeviceUrl, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = response.data.RegisterDeviceResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
