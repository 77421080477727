export const initialConfig = {
  ACTIVE_SUBSCRIPTIONS_URL: '',
  ADD_OR_REMOVE_FAVORITE_URL: '',
  AUTHORIZATION_ENDPOINT: '',
  CHANGE_EMAIL_URL: '',
  CHANGE_PASSWORD_URL: '',
  CLIENT_ID: '',
  CLIENT_NAME: '',
  CLIENT_REGISTERATION_ENDPOINT: '',
  CLIENT_SECRET: '',
  CONTENT_AUTH_ENDPOINT: '',
  DEVICE_MODEL_NO: '',
  DEVICE_USER_AGENT: '',
  EVERGENT_ADD_OUR_UPDATE_COUCH_RIGHTS: '',
  EVERGENT_API_KEY: '',
  EVERGENT_CHANNEL_PARTNER_ID: '',
  EVERGENT_CHECKOUT_URL: '',
  FORCE_TIZEN: '',
  FORCE_ZONE_KEY: '',
  FORGOT_CONTACT_PASSWORD_URL: '',
  FREE_CONTENT_IDENTIFIER: '',
  GENERATE_ACTIVATION_CODE: '',
  GEO_LOCATION_LOOKUP_URL: '',
  GET_CONTACT_URL: '',
  GET_ENTITLEMENTS_URL: '',
  GET_FAVORITES_TEAMS_URL: '',
  GET_LIVE_EVENT: '',
  GET_LIVE_EVENTS: '',
  GET_PRODUCTS_URL: '',
  GET_STOREFRONT_AIRING_LIVE: '',
  GET_STOREFRONT_CHANNELS: '',
  GET_STOREFRONT_CHANNEL_EPG: '',
  GET_STOREFRONT_LIST: '',
  GET_STOREFRONT_TABS: '',
  GET_STOREFRONT_TAB_CONTAINER: '',
  GET_STRING_URL: '',
  GET_SUBSCRIPTION_HISTORY_URL: '',
  GET_VIDEO_BY_ID_URL: '',
  GET_ZIP_CODE_INFO_URL: '',
  HEARTBEAT_INTERVAL_SYNC: 0,
  HEARTBEAT_MAX_ALLOWED_FAILURES: 0,
  HEARTBEAT_URL: '',
  HERO_BANNER_INTERVAL: 0,
  LOGIN_URL: '',
  LOG_OUT_ALL_DEVICES_URL: '',
  LOG_OUT_URL: '',
  MAX_REELS_LENGTH: 0,
  MEDIA_CHECKREDEEM_URL: '',
  MPARTICLE_API_KEY_WEB: '',
  OUT_OF_MARKET_ZONE: '',
  PAUSE_SUBSCRIPTION_URL: '',
  PURCHASE_FORBIDDEN_ZONES: [''],
  REFRESH_TOKEN_URL: '',
  REGISTER_DEVICE_URL: '',
  REMOVE_SUBSCRIPTION_URL: '',
  RESET_PASSWORD_URL: '',
  RESUME_SUBSCRIPTION_URL: '',
  SEARCH_ACCOUNT_V2_URL: '',
  SET_STRING_URL: '',
  SIGN_UP_URL: '',
  STREAMCONCURRENCY_URL: '',
  TEAMS_LIST_URL_BY_ZONE: '',
  TEAMS_VIDEOS_URL: '',
  THUMB_IMAGE_URL: '',
  WEB_APP_URL: '',
  X_CLIENT_ID: '',
  X_FORWARDED_FOR: '',
  availablePaymentOptions: [],
  promotionConfiguration: [],
};
