import { useMemo } from 'react';

import { PlatformAuthorizer, PlatformClient, flpPlayerApi } from '../../api';
import { useEnv } from '../../components/EnvProvider/EnvProvider';

export const useFLPContentAuthorizer = ({
  platformClient,
  platformAuthorizer,
}: {
  platformClient: PlatformClient;
  platformAuthorizer: PlatformAuthorizer;
}) => {
  const { CLIENT_REGISTERATION_ENDPOINT, CONTENT_AUTH_ENDPOINT } = useEnv();
  return useMemo(() => {
    return flpPlayerApi.getContentAuthorizer({
      clientRegisterationEndpoint: CLIENT_REGISTERATION_ENDPOINT,
      contentAuthEndpoint: CONTENT_AUTH_ENDPOINT,
      platformAuthorizer,
      platformClient,
    });
  }, [platformAuthorizer, platformClient]);
};

export default useFLPContentAuthorizer;
