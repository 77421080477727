import { createSelector } from '@reduxjs/toolkit';

import { AssetCategoryType, AssetContentType, PlaybackMode } from '../../api';
import { RootState } from '../types';
import { playbackModeSelector, selectedVideoSelector } from '../videos';

export const msgStatsSelector = (state: RootState) => state.msgStats;

export const tabsConfigSelector = createSelector(
  msgStatsSelector,
  playbackModeSelector,
  (msgStats, playbackMode) =>
    msgStats.configItems.filter((item) => {
      if (item?.excludedPlaybackModes && playbackMode) {
        return !item?.excludedPlaybackModes.includes(playbackMode);
      }
      return true;
    }),
);

export const shouldFetchStatsSelector = createSelector(selectedVideoSelector, (video) => {
  if (!video || video.playbackMode === PlaybackMode.CATCHUP) {
    return false;
  }

  if (video.playbackContentType === AssetContentType.LIVEEVENT) {
    return true;
  }

  if (video.playbackContentType === AssetContentType.CHANNEL) {
    if (
      video.categoryType !== AssetCategoryType.PREGAME &&
      video.categoryType !== AssetCategoryType.GAME &&
      video.categoryType !== AssetCategoryType.POSTGAME
    ) {
      return false;
    }

    if (video.isLive) return true;
  }

  return false;
});

export const shouldShowStatsSelector = createSelector(
  tabsConfigSelector,
  shouldFetchStatsSelector,
  (tabsConfig, shouldFetchStats) => {
    if (!tabsConfig.length) {
      return false;
    }
    return shouldFetchStats;
  },
);

export const areStatsLoadingSelector = createSelector(
  msgStatsSelector,
  ({ isLoading }) => isLoading,
);
