import { envName } from '../env';
import { getQueryParams } from '../helpers/searchParams';

export const DEBUG_VIEW_PASSWORD_KEY = 'debugViewPassword';

export const isDebugViewEnabled = () => {
  const queryParams = getQueryParams();
  const debugViewPassword = localStorage.getItem(DEBUG_VIEW_PASSWORD_KEY);

  if (queryParams[DEBUG_VIEW_PASSWORD_KEY]) {
    localStorage.setItem(DEBUG_VIEW_PASSWORD_KEY, queryParams[DEBUG_VIEW_PASSWORD_KEY]);
  }

  const isLocalEnviroment = window.location.hostname === 'localhost';

  return (
    ([debugViewPassword, queryParams[DEBUG_VIEW_PASSWORD_KEY]].includes(
      process.env.REACT_APP_DEBUG_PASSWORD || '',
    ) &&
      !['prod', 'preprod'].includes(envName)) ||
    isLocalEnviroment
  );
};
