import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { flexCenter } from '../../../../utils/styled/mixins';

export const Button = styled.div`
  ${flexCenter}
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  ${breakpoint('lg')`
  	display: none;
  `}
`;
