import { AUTH_SESSION_STORAGE_KEY, SessionType } from '../../../store/auth';
import { cacheManager } from '../../../utils';
import { zoneStore } from '../../../utils/zoneKeeper';
import { FailureResponse, GetProrateResponseMessage, ServiceType } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getProrate = async ({
  couponCode,
  zipCode,
  serviceId,
  evergentApiKey,
  evergentChannelPartnerId,
  getProrateUrl,
}: {
  couponCode?: string;
  zipCode: string;
  serviceId: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getProrateUrl: string;
}): Promise<GetProrateResponseMessage> => {
  const zoneKey = await zoneStore.getZoneKey();
  const { accessToken } = (await cacheManager.load<SessionType>(AUTH_SESSION_STORAGE_KEY)) || {};
  const requestParams = {
    address: {
      zipCode,
    },
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    coupons: [
      {
        couponCode,
      },
    ],
    dmaID: zoneKey,
    itemsInfo: [
      {
        quantity: '1',
        serviceId,
        serviceType: ServiceType.Product,
      },
    ],
  };

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  const requestBody = {
    GetProrateRequestMessage: requestParams,
  };

  const response = await evergentAxiosInstance.post<{
    GetProrateResponseMessage: GetProrateResponseMessage & FailureResponse;
  }>(getProrateUrl, requestBody, { headers });

  const { failureMessage, ...dataResponse } = response.data.GetProrateResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage, {
      cause: {
        errorCode: failureMessage[0].errorCode,
      },
    });
  }

  return dataResponse;
};
