import styled, { css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { convertPxToRem } from '../../utils/styled/convertPxToRem';
import {
  Container as AppContainer,
  Col,
  Row,
  TypographyProps,
  bolder,
  ellipsis,
  fonts,
  textBase,
} from '../Common';
import { COMPANION_ADS_SIZES } from '../CompanionAds/CompanionAds.constants';
import { AdSizeName } from '../CompanionAds/CompanionAds.types';
import { FOOTER_HEIGHT_DESKTOP, FOOTER_HEIGHT_MOBILE } from '../Footer/Footer.styles';
import { NAVIGATION_HEIGHT } from '../Navigation/Navigation.styles';

// Player bottom section heights
export const PLAYER_BOTTOM_SECTION_PADDING = '1.5rem';
const PLAYER_BOTTOM_SECTION_GAP = '1.5rem';

export const TITLE_AND_DESCRIPTION_HEIGHT = '50px';

export const VERTICAL_LARGE_HEIGHT = `calc(max(${
  COMPANION_ADS_SIZES[AdSizeName.LARGE].height
}px, ${TITLE_AND_DESCRIPTION_HEIGHT}) + 2 * ${PLAYER_BOTTOM_SECTION_PADDING})`;

export const HORIZONTAL_SMALL_HEIGHT = `calc(${
  COMPANION_ADS_SIZES[AdSizeName.SMALL].height
}px + ${TITLE_AND_DESCRIPTION_HEIGHT} + 2 * ${PLAYER_BOTTOM_SECTION_PADDING} + ${PLAYER_BOTTOM_SECTION_GAP} )`;

export const HORIZONTAL_LARGE_HEIGHT = `calc(${
  COMPANION_ADS_SIZES[AdSizeName.LARGE].height
}px + ${TITLE_AND_DESCRIPTION_HEIGHT} + 2 * ${PLAYER_BOTTOM_SECTION_PADDING} + ${PLAYER_BOTTOM_SECTION_GAP})`;

const TITLE_AND_DESCRIPTION_MAX_WIDTH = `calc(100% - ${
  COMPANION_ADS_SIZES[AdSizeName.LARGE].width
}px)`;

export const Container = styled(AppContainer)`
  min-height: calc(100svh - calc(${NAVIGATION_HEIGHT}px + ${FOOTER_HEIGHT_MOBILE}));

  ${breakpoint('lg')`
    min-height: calc(100svh - calc(${NAVIGATION_HEIGHT}px + ${FOOTER_HEIGHT_DESKTOP}));
  `}
`;

interface PropsAdSlotWrapper {
  $isOneColumnLayout: boolean;
}

export const AdSlotWrapper = styled.div<PropsAdSlotWrapper>`
  display: flex;
  flex: 1;
  max-width: ${COMPANION_ADS_SIZES[AdSizeName.LARGE].width}px;

  ${({ $isOneColumnLayout }) =>
    $isOneColumnLayout &&
    css`
      ${breakpoint('xxl')`
        justify-content: end;
      `}
    `}
`;

export const PlayerLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${convertPxToRem(45)};

  ${breakpoint('lg')`
     min-height: calc(100svh - calc(${NAVIGATION_HEIGHT}px + ${FOOTER_HEIGHT_DESKTOP}));
  `}
`;

export const PlayerTopSection = styled.div`
  margin-bottom: 1.5rem;
`;

export const ContentRow = styled(Row)<{
  $isOneColumnLayout: boolean;
  $visible: boolean;
}>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};

  display: flex;
  ${({ $isOneColumnLayout }) => !$isOneColumnLayout && 'flex: 1;'}
  overflow: auto;
  margin-bottom: 1.5rem;
`;

export const PlayerCol = styled(Col)`
  overflow: auto;
`;

interface PropsSidebarCol {
  $showSidebar?: boolean;
}

export const SidebarCol = styled(Col)<PropsSidebarCol>`
  display: flex;
  overflow: auto;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 1.5rem;

  ${({ $isOneColumnLayout }) =>
    !$isOneColumnLayout &&
    css`
      ${breakpoint('lg')`
        padding-top: unset;

        `}
    `}
`;

export const PlayerBottomSection = styled.div<{ $statsVisible?: boolean }>`
  padding-top: ${PLAYER_BOTTOM_SECTION_PADDING};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${PLAYER_BOTTOM_SECTION_GAP};
  flex-direction: column;

  ${({ $statsVisible }) =>
    !$statsVisible &&
    breakpoint('xxl')`
        flex-direction: row;
  `}
`;

interface PropsPlayerTitleAndDescription {
  $fullWidth: boolean;
}

export const PlayerTitleAndDescription = styled.div<PropsPlayerTitleAndDescription>`
  min-height: 3.25rem;
  flex-grow: 1;
  max-width: 100%;
  min-width: 30%;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      min-width: 100%;
    `}

  ${breakpoint('xxl')`
      max-width: ${TITLE_AND_DESCRIPTION_MAX_WIDTH};
  `}
`;

export const PlayerContentTitle = styled.h2<TypographyProps>`
  ${textBase}
  ${fonts.title.xs}
  ${bolder}
  ${ellipsis}
  margin-bottom: 0.5rem;
`;

export const PlayerContentDescription = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.body1.lg}
  ${ellipsis}
`;
