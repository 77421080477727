import { useSelector } from 'react-redux';

import { GetProductsResponseItem } from '../../../api/authApi/authApi.types';
import { useTranslations } from '../../../hooks/useTranslations';
import { purchaseSelector } from '../../../store/purchase';
import * as Ui from './PriceSummary.styles';

interface IPriceSummary {
  product: GetProductsResponseItem;
}

const PriceSummary = ({ product }: IPriceSummary) => {
  const t = useTranslations();
  const { item, voucherCode } = useSelector(purchaseSelector);

  function formatValue(value: number | undefined): string | undefined {
    return value !== undefined ? (Math.round(value * 100) / 100).toFixed(2) : undefined;
  }

  return (
    <>
      <Ui.SummaryRow>
        <Ui.SummaryLabel>
          {product.displayName} {t.purchaseModal_subscription}
        </Ui.SummaryLabel>
        <Ui.SummaryValue>
          {product.currencySymbol}
          {formatValue(product.retailPrice)}
        </Ui.SummaryValue>
      </Ui.SummaryRow>
      {!!item?.totalTax && (
        <Ui.SummaryRow>
          <Ui.SummaryLabel>{t.purchaseModal_tax}</Ui.SummaryLabel>

          <Ui.SummaryValue>
            {product.currencySymbol}
            {formatValue(item.totalTax)}
          </Ui.SummaryValue>
        </Ui.SummaryRow>
      )}
      {!!voucherCode && (
        <Ui.SummaryRow>
          <Ui.SummaryLabel>{t.purchaseModal_discount}</Ui.SummaryLabel>
          <Ui.SummaryValue>
            {product.currencySymbol}
            {formatValue(item?.totalDiscountAmount || 0)}
          </Ui.SummaryValue>
        </Ui.SummaryRow>
      )}
      <Ui.SummaryRow>
        <Ui.TotalSummaryLabel>{t.purchaseModal_total}</Ui.TotalSummaryLabel>
        <Ui.SummaryValue>
          {product.currencySymbol}
          {formatValue(item?.amountDueToday)}
        </Ui.SummaryValue>
      </Ui.SummaryRow>
    </>
  );
};

export { PriceSummary };
