import { createAsyncThunk } from '@reduxjs/toolkit';

import { quickplayCmsApi } from '../../api';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { channelsActions, loadChannelsAction } from './channels.slice';

export const getChannels = createAsyncThunk(
  loadChannelsAction.type,
  async (payload: never, thunksapi) => {
    try {
      thunksapi.dispatch(channelsActions.loadChannelsLoading());
      const {
        appConfig: {
          env: { GET_STOREFRONT_CHANNELS, X_CLIENT_ID, FORCE_ZONE_KEY },
        },
      } = thunksapi.getState() as RootState;

      const channels = await quickplayCmsApi.getStorefrontChannels({
        forceZoneKey: FORCE_ZONE_KEY,
        getStoreFrontChannels: GET_STOREFRONT_CHANNELS,
        xClientId: X_CLIENT_ID,
      });

      thunksapi.dispatch(channelsActions.loadChannelsSuccess(channels));
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(channelsActions.loadChannelsError(errorMessage));
    }
  },
);
