import { CastPlayer, createCastManagerImpl } from '@msgn/fl-module/fl-chrome-sender';
import { createContentAuthorizer } from '@msgn/fl-module/fl-content-authorization';
import { createHeartbeatBuilder } from '@msgn/fl-module/fl-heartbeat';
import { createPlatformAuthorizer } from '@msgn/fl-module/fl-platform-authorizer';
import { createPlayerBuilder } from '@msgn/fl-module/fl-platform-player';
import { createStreamConcurrencyResolver } from '@msgn/fl-module/fl-stream-concurrency';

import { AssetContentType } from '../cmsApi';

export enum TrackType {
  AUDIO = 'audio',
  TEXT = 'text',
}

export enum ConsumptionType {
  VOD = 'vod',
  LIVE = 'live',
}

export enum PlayerState {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADED = 'loaded',
  STARTED = 'started',
  PAUSED = 'paused',
}

export enum PlaybackMode {
  LIVE = 'live',
  RESTART = 'restart',
  CATCHUP = 'catchup',
  VOD = 'vod',
}

export enum CatalogType {
  EPISODE = 'episode',
  CHANNEL = 'channel',
  LIVEEVENT = 'liveevent',
}

export enum StreamTimelineEvent {
  BLACKOUT = 'blackout',
  LIVE_PROGRAM_START = 'liveProgramStart',
  LIVE_PROGRAM_END = 'liveProgramEnd',
  LIVE_EVENT_START = 'liveEventStart',
  LIVE_EVENT_END = 'liveEventEnd',
  OVERFLOW_EVENT_END = 'overflowEventEnd',
  GAME_INFO = 'gameInfo',
  GAME_EVENT = 'gameEvent',
  UNKNOWN = 'unknown',
}

export enum DrmScheme {
  NONE = 'none',
  WIDEVINE = 'widevine',
  PLAYREADY = 'playready',
  FAIRPLAY = 'fairplay',
  CLEARKEY = 'clearkey',
}

export enum MediaType {
  DASH = 'dash',
  SMOOTH_STREAMING = 'ss',
  HLS = 'hls',
  MP4 = 'mp4',
  OTHER = 'other',
}

export type PlayerTrack = {
  type: TrackType;
  language: string;
};

export type CastPlayerHandler = PlayerHandler & {
  loadMedia: ReturnType<typeof createCastManagerImpl>['castPlayer']['loadMedia'];
};

export type PlatformClient = {
  id: string;
  name: string;
};

export type FLPlatformAsset = {
  custom: {
    ssaiAdMetadataUrl?: string;
  };
  liveEventType?: string;
  heartbeatToken: string;
  heartbeatFlag: boolean;
  heartbeatFreq: number;
  contentUrl: string;
  licenseUrl: string;
  contentId: string;
  mediaType: MediaType;
  drmScheme: DrmScheme;
  livePlaybackMode: PlaybackMode;
  contentType?: AssetContentType;
  liveEndTime: string;
  liveStartTime: string;
  isLiveStreamEnded?: boolean;
};

type PlayerTypeOverwrites = {
  getAllTracks: () => PlayerTrack[];
  getActiveTracks: () => PlayerTrack[];
  isInvalidPlayer?: boolean;
  stop: () => Promise<void>;
  isLive?: boolean;
  currentTime?: unknown;
};

// TODO: remove dependency of third-party librery
export type FLLocalPlayer = PlayerTypeOverwrites &
  ReturnType<ReturnType<typeof createPlayerBuilder>['build']>;
export type FLCastPlayer = PlayerTypeOverwrites &
  ReturnType<typeof createCastManagerImpl>['castPlayer'] &
  CastPlayer;

export type ContentAuthorizer = ReturnType<typeof createContentAuthorizer>;
export type PlatformAuthorizer = ReturnType<typeof createPlatformAuthorizer>;
export type HeartbeatManager = ReturnType<ReturnType<typeof createHeartbeatBuilder>['build']>;
export type StreamConcurrencyManager = ReturnType<typeof createStreamConcurrencyResolver>;

export type CastDevice = {
  friendlyName: string;
};

export type CastManagerTypeOverwrites = {
  castSessionManager: {
    getCastDevice: () => CastDevice;
  };
};

export type CastManager = CastManagerTypeOverwrites & ReturnType<typeof createCastManagerImpl>;

export type PlayerHandler = FLLocalPlayer | FLCastPlayer;

export type PlaybackParams = {
  catalogType: string;
  consumptionType: ConsumptionType;
  drmScheme: DrmScheme;
  mediaID: string;
  mediaType: MediaType;
  playbackMode: PlaybackMode | undefined;
  startTime?: string;
  endTime?: string;
  playbackOptions?: {
    autoPlayOnLoad?: boolean;
    initialPlaybackTime?: number;
    networkTimeout?: number;
    preferredAudioLanguage?: string;
    preferredTextLanguage?: string;
    minBuffer?: number;
    maxBuffer?: number;
    bufferBehind?: number;
    networkRetries?: number;
  };
  urlparameters?: unknown;
};
