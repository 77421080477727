import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FirebasePerformance, trace } from 'firebase/performance';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { mParticleApi } from '../../api/mParticlesApi/mParticlesApi';
import { PerformanceMeasure } from '../../firebase/constants';
import { perf } from '../../firebase/firebase';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { checkAuthCompleted } from '../auth';
import { RootState } from '../types';
import {
  accountData,
  changeEmailAction,
  changePasswordAction,
  forgotPasswordAction,
  resetPasswordAction,
  userData,
  userDataActions,
} from './userData.slice';

export const getUserData = createAsyncThunk(
  userData.type,
  async (payload: { accessToken: string }, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, GET_CONTACT_URL },
        },
      } = thunksapi.getState() as RootState;

      const userDataResponse = await evergentAuthApi.getContact({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getContactUrl: GET_CONTACT_URL,
      });

      const data = {
        accountRegistrationDate: userDataResponse.accountRegistrationDate,
        channelPartnerID: userDataResponse.channelPartnerID,
        contactCreateDate: userDataResponse.contactMessage[0].contactCreateDate,
        contactCreateDateString: userDataResponse.contactMessage[0].contactCreateDateString,
        contactID: userDataResponse.contactMessage[0].contactID,
        contactMessage: userDataResponse.contactMessage,
        cpCustomerID: userDataResponse.cpCustomerID,
        email: userDataResponse.contactMessage[0].email,
        isMobileVerified: userDataResponse.isMobileVerified,
        isProfileComplete: userDataResponse.isProfileComplete,
        lastLoginTime: userDataResponse.lastLoginTime,
        lastName: userDataResponse.contactMessage[0].lastName,
        name: userDataResponse.contactMessage[0].firstName,
        spAccountID: userDataResponse.spAccountID,
        verificationStatus: userDataResponse.verificationStatus,
      };

      mParticleApi.startSession({
        customerid: data.cpCustomerID,
        email: data.email,
      });

      thunksapi.dispatch(userDataActions.getUserDataSuccess(data));
      thunksapi.dispatch(checkAuthCompleted());
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(userDataActions.getUserDataError(errorMessage));
    }
  },
);

export const searchAccountData = createAsyncThunk(
  accountData.type,
  async (
    payload: {
      email: string;
      successCallback?: () => void;
      failureCallback?: (isServerError?: boolean) => void;
    },
    thunksapi,
  ) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, SEARCH_ACCOUNT_V2_URL },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(userDataActions.searchAccountDataLoading(true));

      const accountDataResponse = await evergentAuthApi.searchAccountV2({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        searchAccountV2Url: SEARCH_ACCOUNT_V2_URL,
      });

      if (accountDataResponse?.failureMessage && accountDataResponse?.failureMessage.length > 0) {
        thunksapi.dispatch(
          userDataActions.searchAccountDataError({
            email: payload.email,
            error: accountDataResponse.failureMessage[0].errorMessage,
          }),
        );

        payload?.failureCallback?.();
      } else {
        thunksapi.dispatch(userDataActions.searchAccountDataSuccess(accountDataResponse));
        payload?.successCallback?.();
      }
    } catch (error: unknown) {
      Log.error(error);

      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(
        userDataActions.searchAccountDataError({
          email: payload.email,
          error: errorMessage,
        }),
      );
      payload?.failureCallback?.(error instanceof AxiosError);
    }
  },
);

export const forgotPassword = createAsyncThunk(
  forgotPasswordAction.type,
  async (payload: { email: string }, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, FORGOT_CONTACT_PASSWORD_URL },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(userDataActions.forgotPasswordLoading(true));

      const forgotPasswordResponse = await evergentAuthApi.forgotContactPassword({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        forgotContactPasswordUrl: FORGOT_CONTACT_PASSWORD_URL,
      });

      if (
        forgotPasswordResponse?.failureMessage &&
        forgotPasswordResponse?.failureMessage.length > 0
      ) {
        thunksapi.dispatch(
          userDataActions.forgotPasswordError(
            forgotPasswordResponse.failureMessage[0].errorMessage,
          ),
        );
      } else {
        thunksapi.dispatch(userDataActions.forgotPasswordSuccess(forgotPasswordResponse));
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(userDataActions.forgotPasswordError(errorMessage));
    }
  },
);

export const changePassword = createAsyncThunk(
  changePasswordAction.type,
  async (
    payload: {
      accessToken: string;
      oldPassword: string;
      newPassword: string;
      confirmNewpassword: string;
      successCallback: VoidFunction;
      errorCallback: (err: string) => void;
    },
    thunksapi,
  ) => {
    const tracer = trace(perf as FirebasePerformance, PerformanceMeasure.EDIT_PASSWORD);
    tracer.start();
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, CHANGE_PASSWORD_URL },
        },
      } = thunksapi.getState() as RootState;

      const changePasswordResponse = await evergentAuthApi.changePassword({
        ...payload,
        changePasswordUrl: CHANGE_PASSWORD_URL,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      });

      if (
        changePasswordResponse?.failureMessage &&
        changePasswordResponse?.failureMessage.length > 0
      ) {
        payload.errorCallback(changePasswordResponse.failureMessage[0].errorMessage);
      } else {
        payload.successCallback();
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      payload.errorCallback(errorMessage);
    } finally {
      tracer.stop();
    }
  },
);

export const changeEmail = createAsyncThunk(
  changeEmailAction.type,
  async (
    payload: {
      accessToken: string;
      email: string;
      successCallback: VoidFunction;
      errorCallback: (err: string) => void;
    },
    thunksapi,
  ) => {
    const tracer = trace(perf as FirebasePerformance, PerformanceMeasure.EDIT_EMAIL);
    tracer.start();
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, CHANGE_EMAIL_URL },
        },
      } = thunksapi.getState() as RootState;

      const changeEmailResponse = await evergentAuthApi.changeEmailAddress({
        ...payload,
        changeEmailUrl: CHANGE_EMAIL_URL,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      });

      if (changeEmailResponse?.failureMessage && changeEmailResponse?.failureMessage.length > 0) {
        payload.errorCallback(changeEmailResponse.failureMessage[0].errorMessage);
      } else {
        payload.successCallback();
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      payload.errorCallback(errorMessage);
    } finally {
      tracer.stop();
    }
  },
);

export const resetPassword = createAsyncThunk(
  resetPasswordAction.type,
  async (
    payload: {
      email: string;
      password: string;
      userToken: string;
    },
    thunksapi,
  ) => {
    try {
      thunksapi.dispatch(userDataActions.resetPasswordLoading(true));

      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, RESET_PASSWORD_URL },
        },
      } = thunksapi.getState() as RootState;

      const resetPasswordResponse = await evergentAuthApi.resetPassword({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        resetPasswordUrl: RESET_PASSWORD_URL,
      });

      if (
        resetPasswordResponse?.failureMessage &&
        resetPasswordResponse?.failureMessage.length > 0
      ) {
        thunksapi.dispatch(
          userDataActions.resetPasswordError(resetPasswordResponse.failureMessage[0].errorMessage),
        );
      } else {
        thunksapi.dispatch(userDataActions.resetPasswordSuccess(resetPasswordResponse));
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(userDataActions.resetPasswordError(errorMessage));
    }
  },
);

export const clearUserData = createAsyncThunk(userData.type, async (payload: void, thunksapi) => {
  thunksapi.dispatch(userDataActions.cleanUserDataSuccess());
});
