import styled from 'styled-components';

import { FONT_WEIGHT, fonts } from '../../../../components/Common';

export const ModalDescriptionListLabel = styled.p`
  ${fonts.body1.xs}
  font-weight: ${FONT_WEIGHT.Regular};
  margin-bottom: 2rem;
`;
export const ModalDescriptionList = styled.ol`
  display: inline-block;
  ${fonts.body1.xs}
  font-weight: ${FONT_WEIGHT.Regular};
`;
