import mParticle from '@mparticle/web-sdk';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../../Constants/routes';
import { GetProductsResponseItem } from '../../api/authApi/authApi.types';
import { Platform } from '../../api/mParticlesApi/constants';
import { useDataZoomContext } from '../../components/DataZoomProvider/context';
import { useModalContext } from '../../components/Modals/context';
import { loadActiveSupscription } from '../../store/activeSubscriptions';
import { accessTokenSelector } from '../../store/auth';
import { loadEntitlements } from '../../store/entitlements';
import {
  paymentErrorSelector,
  paymentRequestCompleteSelector,
  paymentRequestPendingSelector,
  purchaseActions,
} from '../../store/purchase';
import { AppDispatch } from '../../store/store';
import { RootState } from '../../store/types';
import { sendMParticleError } from '../../utils/mParticle/sendMPArticleError';
import { useExtendedNavigate } from '../useExtendedNavigate';

export const useCompletePaymentActions = ({ product }: { product: GetProductsResponseItem }) => {
  const dispatch: AppDispatch = useDispatch();
  const [purchaseConcluded, setPurchaseConcluded] = useState(false);
  const navigate = useExtendedNavigate();
  const { pathname } = useLocation();
  const { closeModal } = useModalContext();
  const appState = useSelector((state: RootState) => state);
  const paymentRequestComplete = useSelector(paymentRequestCompleteSelector);
  const paymentRequestPending = useSelector(paymentRequestPendingSelector);
  const paymentError = useSelector(paymentErrorSelector);
  const { userInfo, userSession } = useDataZoomContext();
  const accessToken = useSelector(accessTokenSelector);

  useEffect(() => {
    if (paymentRequestComplete && !paymentRequestPending && !purchaseConcluded && !paymentError) {
      setPurchaseConcluded(true);
      dispatch(purchaseActions.resetPurchaseState());
      dispatch(loadActiveSupscription({ accessToken }));
      dispatch(loadEntitlements({ accessToken }));
      userSession.completePayment(userInfo);
      navigate(ROUTES[RouteName.AllSetPayment], {
        replace: true,
        state: {
          backRoute: {
            pathname,
          },
          platform: Platform.Web,
          product: { ...product },
          shouldTriggerPurchaseEvent: true,
        },
      });
      closeModal();
    } else if (paymentRequestComplete && !paymentRequestPending && paymentError) {
      closeModal();

      sendMParticleError(
        {
          error: { errorDesc: paymentError },
          eventType: mParticle.EventType.Transaction,
        },
        appState,
      );

      dispatch(purchaseActions.resetPurchaseState());

      navigate(ROUTES[RouteName.PaymentError], {
        replace: true,
      });
    }
  }, [accessToken, userInfo, paymentRequestComplete, userSession, purchaseConcluded, paymentError]);
};
