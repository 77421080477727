import { createPlayerBuilder } from '@msgn/fl-module/fl-platform-player';
import { RefObject } from 'react';

import { CustomLogger } from '../../helpers/CustomLogger';
import { isSafari } from '../../helpers/browser';
import { DrmScheme, FLPlatformAsset, MediaType, PlayerHandler } from './playerApi.types';

export const createPlayerHandler = ({
  asset,
  ref,
  initialPlaybackTime,
  playerLogger,
}: {
  asset: FLPlatformAsset;
  ref: RefObject<HTMLVideoElement>;
  initialPlaybackTime?: number;
  playerLogger: boolean;
}): PlayerHandler => {
  const logger = new CustomLogger(playerLogger);

  const playbackOptions = {
    autoCorrectDrift: false,
    bufferTimeoutMs: 30000,
    initialPlaybackTime,
    segmentRelativeVttTiming: true,
  };

  const playerBuilder = createPlayerBuilder();
  const flPlayer = playerBuilder
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .logger(logger)
    .mediaElement(ref.current as HTMLVideoElement)
    .mediaUrl(asset.contentUrl)
    .drmLicenseUrl(asset.licenseUrl)
    .drmScheme(isSafari ? DrmScheme.FAIRPLAY : DrmScheme.WIDEVINE)
    .mediaType(isSafari ? MediaType.HLS : MediaType.DASH)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .playbackOptions(playbackOptions)
    .build();
  return flPlayer as PlayerHandler;
};
