import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../Constants/routes';
import { useLogoutWhenRefreshTokenFailed } from '../api/authApi/EvergentAuthApi.hooks';
import { clearAdobeAuthorizations } from '../api/authApi/adobeApi/adobeApi.helpers';
import { MigrationTrigger } from '../api/remoteConfigApi/remoteConfigApi.types';
import { useActionRequiredModal } from '../components/ActionRequiredModal/useActionRequiredModal';
import { useMigrationModal } from '../components/Modals/MigratiionModal/hooks/useMigrationModal';
import { getEntryPage } from '../helpers/getEntryPage';
import { useOvatToken } from '../hooks/evergent/useOvatToken';
import usemParticles from '../hooks/mParticle/useMParticles';
import { useCurrentLocation } from '../hooks/useCurrentLocation';
import { useExtendedNavigate } from '../hooks/useExtendedNavigate';
import { useGeolocationError } from '../hooks/useGeolocationError';
import { useGetPaymentOptions } from '../hooks/useGetPaymentOptions';
import { useIsActiveTab } from '../hooks/useIsActiveTab';
import { useLandingPage } from '../hooks/useLandingPage';
import { useLogScreenViewedEvent } from '../hooks/useLogScreenViewedEvent';
import {
  activeSubscriptionsSelector,
  clearActiveSubscription,
  loadActiveSupscription,
} from '../store/activeSubscriptions';
import { authSelector, clearSession, isAuthedSelector } from '../store/auth';
import { getCouchRights } from '../store/couchRights';
import { deviceIdSelector, deviceInfoLoad } from '../store/deviceInfo';
import { entitlementsSelector } from '../store/entitlements';
import { fetchProducts } from '../store/products';
import { serverErrorSelector } from '../store/serverError';
import { AppDispatch } from '../store/store';
import { storeFrontSelector, storefrontLoadId } from '../store/storefront';
import { fetchTranslation } from '../store/translations';
import { getUserData } from '../store/userData';
import { clearZoneInfo, loadZoneFromEntitlements, zoneInfoStateSelector } from '../store/zoneInfo';
import { zoneStore } from '../utils/zoneKeeper';
import { useConvivaEco } from '../hooks/useConvivaEco';

type AppInitializerProps = {
  children: ReturnType<typeof React.cloneElement<{ entryPage: string }>>;
};

export const AppInitializer = ({ children }: AppInitializerProps) => {
  const entryPage = getEntryPage();
  const dispatch: AppDispatch = useDispatch();
  const isAuthed = useSelector(isAuthedSelector);
  const navigate = useExtendedNavigate();
  const { error, accessToken, expiresIn } = useSelector(authSelector);
  const { entitlements } = useSelector(entitlementsSelector);
  const { storefrontId } = useSelector(storeFrontSelector);
  const { errorMessage: globalErrorMessage } = useSelector(serverErrorSelector);
  const { zoneKey } = useSelector(zoneInfoStateSelector);
  const deviceId = useSelector(deviceIdSelector);
  useMigrationModal(MigrationTrigger.appStart);

  const { error: activeSubscriptionError, loaded: activeSubscriptionLoaded } = useSelector(
    activeSubscriptionsSelector,
  );
  useLogoutWhenRefreshTokenFailed();
  usemParticles();
  useIsActiveTab();
  useLogScreenViewedEvent();
  useGetPaymentOptions();
  useCurrentLocation();
  useGeolocationError(true);
  useLandingPage();
  useOvatToken();
  useConvivaEco();

  useEffect(() => {
    dispatch(fetchTranslation());
    dispatch(deviceInfoLoad());
    dispatch(fetchProducts());
  }, []);

  useEffect(() => {
    if (deviceId) {
      dispatch(getCouchRights());
    }
  }, [deviceId]);

  useEffect(() => {
    if (!storefrontId && isAuthed) {
      dispatch(storefrontLoadId());
    }
  }, [dispatch, storefrontId]);

  useEffect(() => {
    if (accessToken) {
      dispatch(loadActiveSupscription({ accessToken }));
      dispatch(getUserData({ accessToken }));
    } else {
      dispatch(clearActiveSubscription());
      dispatch(clearZoneInfo());
      clearAdobeAuthorizations();
    }
  }, [accessToken]);

  useEffect(() => {
    if (entitlements?.dmaID) {
      dispatch(loadZoneFromEntitlements(entitlements));
    }
  }, [entitlements?.dmaID]);

  useEffect(() => {
    if (zoneKey) {
      zoneStore.setZoneKey(zoneKey);
    }
    if (accessToken) {
      dispatch(storefrontLoadId());
    }
  }, [zoneKey]);

  useEffect(() => {
    if (globalErrorMessage) {
      navigate(ROUTES[RouteName.ServerError], { replace: true });
    }
  }, [navigator, navigate, error, globalErrorMessage]);

  useEffect(() => {
    if (activeSubscriptionError && activeSubscriptionLoaded) {
      const calculateMilSecondsToRefresh = (expiresIn: string) => {
        return parseInt(expiresIn) - new Date().getTime();
      };

      const milSecondsToRefresh = calculateMilSecondsToRefresh(expiresIn);

      if (milSecondsToRefresh > 0) {
        dispatch(clearSession());
        navigate('/login', { replace: true });
      }
    }
  }, [activeSubscriptionError, activeSubscriptionLoaded, expiresIn]);

  useActionRequiredModal('actionRequired20231211');

  return React.cloneElement(children, { entryPage });
};
