import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AvailablePaymentOptions } from '../components/PaymentOptions';
import { purchaseItemSelector, selectedPaymentOptionSelector } from '../store/purchase';

export const useGetApplePayPayment = () => {
  const [applePaymentObj, setApplePaymentObj] = useState();
  const selectedPaymentOption = useSelector(selectedPaymentOptionSelector);

  const purchaseItem = useSelector(purchaseItemSelector);

  useEffect(() => {
    if (selectedPaymentOption === AvailablePaymentOptions.APPLE_PAY) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payment = (window as any).squarePayments.paymentRequest({
        countryCode: 'US',
        currencyCode: 'USD',
        total: {
          amount: `${purchaseItem?.amountDueToday}`,
          label: 'Total',
        },
      });

      setApplePaymentObj(payment);
    }
  }, [purchaseItem?.amountDueToday, selectedPaymentOption]);

  return applePaymentObj;
};
