import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { GetStringResponse, SetStringResponse } from '../../api/authApi/authApi.types';
import { MarketingPreferencesState } from './marketingPreferences.types';

export const initialState: MarketingPreferencesState = {
  loading: false,
  value: null,
};

export const loadMarketingPreferenceAction = createAction(
  'marketing-preference/loadMarketingPreferences',
);
export const setMarketingPreferenceAction = createAction('marketing-preference/setPreference');

const slice = createSlice({
  initialState: initialState,
  name: 'marketingPreferences',
  reducers: {
    loadMarketingPreferencesError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    loadMarketingPreferencesLoading: (state) => ({
      ...state,
      error: undefined,
      loading: true,
    }),
    loadMarketingPreferencesSuccess: (
      state: MarketingPreferencesState,
      action: PayloadAction<GetStringResponse>,
    ) => {
      const valueAsBoolean: boolean = action.payload.value === 'true' ? true : false;

      return {
        ...state,
        ...action.payload,
        error: undefined,
        loading: false,
        // value: action.payload.value,
        value: valueAsBoolean,
      };
    },
    setMarketingPreferencesError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    setMarketingPreferencesLoading: (state) => ({
      ...state,
      error: undefined,
      loading: true,
    }),
    setMarketingPreferencesSucces: (
      state: MarketingPreferencesState,
      action: PayloadAction<SetStringResponse>,
    ) => ({
      ...state,
      ...action.payload,
      error: undefined,
      loading: false,
      value: action.payload.value,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as marketingPreferencesActions, name, reducer };
