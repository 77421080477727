import { RemoteConfigDataTypeUrls } from '../../api/remoteConfigApi/remoteConfigApi.types';

export type ConfigUrls = {
  ACTIVE_SUBSCRIPTIONS_URL: string;
  ADD_OR_REMOVE_FAVORITE_URL: string;
  AUTHORIZATION_ENDPOINT: string;
  CHANGE_EMAIL_URL: string;
  CHANGE_PASSWORD_URL: string;
  CLIENT_REGISTERATION_ENDPOINT: string;
  CONTENT_AUTH_ENDPOINT: string;
  EVERGENT_CHECKOUT_URL: string;
  FORGOT_CONTACT_PASSWORD_URL: string;
  GENERATE_ACTIVATION_CODE: string;
  GEO_LOCATION_LOOKUP_URL: string;
  GET_CONTACT_URL: string;
  GET_ENTITLEMENTS_URL: string;
  GET_FAVORITES_TEAMS_URL: string;
  GET_LIVE_EVENT: string;
  GET_LIVE_EVENTS: string;
  GET_PRODUCTS_URL: string;
  GET_STOREFRONT_AIRING_LIVE: string;
  GET_STOREFRONT_CHANNELS: string;
  GET_STOREFRONT_CHANNEL_EPG: string;
  GET_STOREFRONT_LIST: string;
  GET_STOREFRONT_TABS: string;
  GET_STOREFRONT_TAB_CONTAINER: string;
  GET_STRING_URL: string;
  GET_SUBSCRIPTION_HISTORY_URL: string;
  GET_ZIP_CODE_INFO_URL: string;
  HEARTBEAT_URL: string;
  LOGIN_URL: string;
  LOG_OUT_ALL_DEVICES_URL: string;
  LOG_OUT_URL: string;
  MEDIA_CHECKREDEEM_URL: string;
  PAUSE_SUBSCRIPTION_URL: string;
  RESUME_SUBSCRIPTION_URL: string;
  REFRESH_TOKEN_URL: string;
  REGISTER_DEVICE_URL: string;
  REMOVE_SUBSCRIPTION_URL: string;
  RESET_PASSWORD_URL: string;
  SEARCH_ACCOUNT_V2_URL: string;
  SET_STRING_URL: string;
  SIGN_UP_URL: string;
  STREAMCONCURRENCY_URL: string;
  TEAMS_LIST_URL_BY_ZONE: string;
  TEAMS_VIDEOS_URL: string;
  THUMB_IMAGE_URL: string;
};

export const urlsTemplates = (urls: RemoteConfigDataTypeUrls): ConfigUrls => ({
  ACTIVE_SUBSCRIPTIONS_URL: `${urls.EVERGENT_BASE_URL}/msgn/getActiveSubscriptions`,
  ADD_OR_REMOVE_FAVORITE_URL: `${urls.EVERGENT_BASE_URL}/msgn/addOrRemoveFavourites`,
  AUTHORIZATION_ENDPOINT: `${urls.AUTH_BASE_URL}/oauth2/token`,
  CHANGE_EMAIL_URL: `${urls.EVERGENT_BASE_URL}/msgn/updateProfile`,
  CHANGE_PASSWORD_URL: `${urls.EVERGENT_BASE_URL}/msgn/changePassword`,
  CLIENT_REGISTERATION_ENDPOINT: `${urls.DEVICE_REGISTER_BASE_URL}/`,
  CONTENT_AUTH_ENDPOINT: urls.CONTENT_AUTH_BASE_URL,
  EVERGENT_CHECKOUT_URL: `${urls.EVERGENT_IFRAME_BASE_URL}/?accessToken={accessToken}&zipCode={zipCode}&serviceType={serviceType}&actionType=add`,
  FORGOT_CONTACT_PASSWORD_URL: `${urls.EVERGENT_BASE_URL}/msgn/forgotContactPassword`,
  GENERATE_ACTIVATION_CODE: `${urls.EVERGENT_BASE_URL}/msgn/generateDeviceActivationCode`,
  GEO_LOCATION_LOOKUP_URL: `${urls.GEO_LOCATION_BASE_URL}/device/location/lookup`,
  GET_CONTACT_URL: `${urls.EVERGENT_BASE_URL}/msgn/getContact`,
  GET_ENTITLEMENTS_URL: `${urls.EVERGENT_BASE_URL}/msgn/getEntitlements`,
  GET_FAVORITES_TEAMS_URL: `${urls.EVERGENT_BASE_URL}/msgn/getFavourites`,
  GET_LIVE_EVENT: `${urls.DATASTORE_BASE_URL}/content/urn/resource/catalog/liveevent`,
  GET_LIVE_EVENTS: `${urls.DATASTORE_BASE_URL}/content/liveevent/filter`,
  GET_PRODUCTS_URL: `${urls.EVERGENT_BASE_URL}/msgn/getProducts`,
  GET_STOREFRONT_AIRING_LIVE: `${urls.DATASTORE_BASE_URL}/content/airing/live`,
  GET_STOREFRONT_CHANNELS: `${urls.DATASTORE_BASE_URL}/content/urn/resource/catalog/channel`,
  GET_STOREFRONT_CHANNEL_EPG: `${urls.DATASTORE_BASE_URL}/content/epg`,
  GET_STOREFRONT_LIST: `${urls.STOREFRONT_BASE_URL}/storefront/list`,
  GET_STOREFRONT_TABS: `${urls.STOREFRONT_BASE_URL}/storefront/{id}/tabs`,
  GET_STOREFRONT_TAB_CONTAINER: `${urls.CATALOG_BASE_URL}/catalog/storefront/{id}/{tabId}/containers`,
  GET_STRING_URL: `${urls.EVERGENT_BASE_URL}/msgn/getString`,
  GET_SUBSCRIPTION_HISTORY_URL: `${urls.EVERGENT_BASE_URL}/msgn/getSubscriptionHistory`,
  GET_ZIP_CODE_INFO_URL: `${urls.DATASTORE_BASE_URL}/content/urn/resource/catalog/zipcode/{zipCode}`,
  HEARTBEAT_URL: `${urls.HEARTBEAT_BASE_URL}/`,
  LOGIN_URL: `${urls.EVERGENT_BASE_URL}/msgn/getOAuthAccessTokenv2`,
  LOG_OUT_ALL_DEVICES_URL: `${urls.EVERGENT_BASE_URL}/msgn/logoutAllDevices`,
  LOG_OUT_URL: `${urls.EVERGENT_BASE_URL}/msgn/logOutUser`,
  MEDIA_CHECKREDEEM_URL: `${urls.CHECK_REDEEM_BASE_URL}}/media/content/checkredeem`,
  PAUSE_SUBSCRIPTION_URL: `${urls.EVERGENT_BASE_URL}/msgn/pauseSubscription`,
  REFRESH_TOKEN_URL: `${urls.EVERGENT_BASE_URL}/msgn/refreshToken`,
  REGISTER_DEVICE_URL: `${urls.EVERGENT_BASE_URL}/msgn/registerDevice`,
  REMOVE_SUBSCRIPTION_URL: `${urls.EVERGENT_BASE_URL}/msgn/removeSubscription`,
  RESET_PASSWORD_URL: `${urls.EVERGENT_BASE_URL}/msgn/resetPassword`,
  RESUME_SUBSCRIPTION_URL: `${urls.EVERGENT_BASE_URL}/msgn/resumeSubscription`,
  SEARCH_ACCOUNT_V2_URL: `${urls.EVERGENT_BASE_URL}/msgn/searchAccountV2`,
  SET_STRING_URL: `${urls.EVERGENT_BASE_URL}/msgn/setString`,
  SIGN_UP_URL: `${urls.EVERGENT_BASE_URL}/msgn/createUser`,
  STREAMCONCURRENCY_URL: `${urls.STREAMCONCURRENCY_BASE_URL}/`,
  TEAMS_LIST_URL_BY_ZONE: `${urls.DATASTORE_BASE_URL}/content/urn/resource/catalog/team`,
  TEAMS_VIDEOS_URL: `${urls.CATALOG_BASE_URL}/catalog/collection/{teamKey}/containers`,
  THUMB_IMAGE_URL: `${urls.IMAGE_RESIZER_BASE_URL}/image/{assetId}/{assetSize}.{format}`,
});
