import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';

export const IFrameWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  border: 1.5px solid ${({ theme }) => theme.colors['off-white']};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  flex: 1;
  margin-bottom: 1.5rem;
  min-height: 25rem;

  ${breakpoint('lg')`
    margin-bottom: 0;
    min-height: unset;
  `}

  iframe {
    background-color: ${({ theme }) => theme.colors['pure-white']};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 0;
    min-height: 25rem;

    ${breakpoint('lg')`
      min-height: unset;
    `}
  }
`;

export const IFrame = styled.iframe``;
