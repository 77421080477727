import { FLCastPlayer } from '../../api';
import { VolumeControl } from '../../hooks/flp';

export const buildCastVolumeControl = ({
  castPlayer,
}: {
  castPlayer: FLCastPlayer;
}): { volumeControl: VolumeControl; volume: number } => {
  const adjustVolume = (volume: number) => {
    castPlayer.volume = volume / 100;
  };

  const getVolume = () => {
    return castPlayer.volume * 100;
  };

  const canAdjustVolume = () => true;
  const volumeControl = { adjustVolume, canAdjustVolume, getVolume };

  return {
    volume: 100,
    volumeControl,
  };
};

export default buildCastVolumeControl;
