/* eslint-disable camelcase */
import mParticle from '@mparticle/web-sdk';

import { determineLocationType } from '../../../../helpers/determineLocationType';
import { parseAssetParams } from '../../../../utils/mParticle/parseAssetParams';
import { AssetContentType, Video } from '../../../cmsApi';
import { FLPlatformAsset, PlaybackMode } from '../../../playerApi';
import { mParticleApi } from '../../mParticlesApi';
import { EventName } from '../../constants';



const getGameId = (asset: Video | null) => {
  switch (asset?.contentType) {
    case AssetContentType.AIRING:
    case AssetContentType.CHANNEL:
      return asset?.gameId;
    case AssetContentType.LIVEEVENT:
    case AssetContentType.EPISODE:
      return asset?.externalId;
    default:
      return undefined;
  }
};

const getVideoTitle = (asset: Video | null) => {
  switch (asset?.contentType) {
    case AssetContentType.AIRING:
    case AssetContentType.CHANNEL:
      return asset?.rawData?.pgm?.lon?.[0].n;
    case AssetContentType.LIVEEVENT:
    case AssetContentType.EPISODE:
      return asset?.rawData?.lon?.[0].n;
    default:
      return undefined;
  }
};

export const videoDetailViewedEvent = (asset: Video | null) => {
  const params = {
    ...parseAssetParams(asset),
    duration: asset?.duration,
    video_category: asset?.contentType,
  };
  mParticleApi.triggerEvent(EventName.VideoDetailViewed, mParticle.EventType.Media, params);
};

export const videoPlayClickedEvent = (asset: Video | null) => {
  const params = {
    ...parseAssetParams(asset),
    duration: asset?.duration,
    video_category: asset?.contentType,
  };
  mParticleApi.triggerEvent(EventName.VideoPlayClicked, mParticle.EventType.Media, params);
};

const getNetworkName = (asset: Video | null) => {
  return asset?.contentType === AssetContentType.EPISODE ||
    asset?.contentType === AssetContentType.AIRING
    ? asset?.rawData?.net
    : undefined;
};

const getVideoMode = (
  asset: Video | null,
  platformAsset?: FLPlatformAsset,
): PlaybackMode | undefined => {
  if (asset?.contentType !== AssetContentType.LIVEEVENT) return;

  return platformAsset?.livePlaybackMode ?? PlaybackMode.VOD;
};

export const videoStartedEvent = (
  asset: Video | null,
  currentTime: number,
  zone: string | null,
  fullScreen: boolean,
  platformAsset?: FLPlatformAsset,
) => {
  const params = {
    ...parseAssetParams(asset),
    category: asset && asset.contentType,
    duration: asset && asset.duration,
    game_id: getGameId(asset),
    location_type: determineLocationType(asset),
    network_name: getNetworkName(asset),
    playback_mode: fullScreen ? 'full-screen' : 'standard',
    playhead_position: String(currentTime),
    video_mode: getVideoMode(asset, platformAsset),
    video_title: getVideoTitle(asset),
    zone: zone ? zone : '',
  };
  mParticleApi.triggerEvent(EventName.VideoStarted, mParticle.EventType.Media, params);
};

export const heartbeatEvent = (
  asset: Video | null,
  currentTime: number,
  zone: string | null,
  fullScreen: boolean,
  platformAsset?: FLPlatformAsset,
) => {
  const params = {
    ...parseAssetParams(asset),
    category: asset?.contentType,
    duration: asset?.duration,
    game_id: getGameId(asset),
    location_type: determineLocationType(asset),
    network_name: getNetworkName(asset),
    playback_mode: fullScreen ? 'full-screen' : 'standard',
    playhead_position: String(currentTime),
    video_mode: getVideoMode(asset, platformAsset),
    video_title: getVideoTitle(asset),
    zone: zone ? zone : '',
  };
  mParticleApi.triggerEvent(EventName.Heartbeat, mParticle.EventType.Media, params);
};

export const videoSeekStarted = (asset: Video | null, time: number) => {
  const params = {
    ...parseAssetParams(asset),
    seek_position: time,
    video_category: asset?.contentType,
    video_duration: asset?.duration,
  };
  mParticleApi.triggerEvent(EventName.VideoSeekStarted, mParticle.EventType.Media, params);
};

export const videoSeekEnded = (asset: Video | null, time: number) => {
  const params = {
    ...parseAssetParams(asset),
    seek_position: time,
    video_category: asset?.contentType,
    video_duration: asset?.duration,
  };
  mParticleApi.triggerEvent(EventName.VideoSeekEnded, mParticle.EventType.Media, params);
};

export const videoErrored = (asset: Video | null, currentTime: number) => {
  const params = {
    ...parseAssetParams(asset),
    duration: asset?.duration,
    playhead_position: String(currentTime),
  };
  mParticleApi.triggerEvent(EventName.VideoErrored, mParticle.EventType.Media, params);
};

export const videoCompleted = (asset: Video | null, currentTime: number) => {
  const params = {
    ...parseAssetParams(asset),
    duration: asset?.duration,
    playhead_position: String(currentTime),
  };
  mParticleApi.triggerEvent(EventName.VideoCompleted, mParticle.EventType.Media, params);
};
