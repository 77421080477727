import { createContext, ReactNode, useContext } from 'react';
import { ModalProps } from 'react-bootstrap/Modal';

export type OpenModalProps = Omit<ModalProps, 'show' | 'onHide'>;

export type ModalState = {
  isDisplayed: boolean;
  content: ReactNode;
  modalProps?: OpenModalProps;
};

export type OpenModalMethod = (
  content: ModalState['content'],
  modalProps?: ModalState['modalProps'],
) => void;

export type CloseModalMethod = () => void;

export type ModalContextValue = {
  closeModal: CloseModalMethod;
  openModal: OpenModalMethod;
  isDisplayed: boolean;
};

export const ModalContext = createContext<ModalContextValue>({} as ModalContextValue);

export const useModalContext = () => useContext(ModalContext);
