import { isAfter, isBefore } from 'date-fns';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../../../../Constants/routes';
import { PlaybackMode, Video } from '../../../../api';
import { GetProductsResponseItem } from '../../../../api/authApi/authApi.types';
import { ClockFilled, PadlockFilled, Play } from '../../../../assets/icons';
import { getSearchParamsFromAsset } from '../../../../helpers/searchParams';
import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { CheckSubscriptionParamsType } from '../../../../hooks/useSubscriptionNavigate';
import { activeSubscriptionsSelector } from '../../../../store/activeSubscriptions';
import { entitlementsSelector } from '../../../../store/entitlements';
import { AppDispatch } from '../../../../store/store';
import { displayVideo } from '../../../../store/videos';
import { ClickEventDispatcher } from '../../../ClickEventDispatcher/ClickEventDispatcher';
import { usePauseSubscription } from '../../../Settings/hooks/usePauseSubscription';
import { colors } from '../../../ThemeProvider/constants/colors';
import * as Ui from './ActionButton.styles';

interface ActionButtonProps {
  checkSubscription: (
    asset: Video | null,
    checkSubscriptionParams?: CheckSubscriptionParamsType,
  ) => Promise<boolean>;
  handleOnClickPlay: () => void;
  game: Video;
}

export const ActionButton: FC<ActionButtonProps> = ({
  checkSubscription,
  game,
  handleOnClickPlay,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useExtendedNavigate();
  const { hasActiveSubscriptions } = useSelector(activeSubscriptionsSelector);
  const { ppgItemsIds } = useSelector(entitlementsSelector);
  const { handleResumeSubscription, isSubscriptionPaused } = usePauseSubscription();

  const isAvailable =
    ppgItemsIds[game?.externalId] || (hasActiveSubscriptions && !isSubscriptionPaused);
  const startDate = new Date(game.startDate);
  const isUpcoming = isAfter(startDate, new Date());
  const isPastGame = isBefore(startDate, new Date());
  const isCatchupMode = game.playbackMode === PlaybackMode.CATCHUP;

  const currentLocation = useLocation();

  const handleOnClickPadlock = () => {
    if (isSubscriptionPaused) {
      return handleResumeSubscription();
    }

    checkSubscription(game, {
      onSuccess: (product: GetProductsResponseItem) =>
        navigate(`${ROUTES[RouteName.AllSetPayment]}`, {
          state: { backRoute: currentLocation, product },
        }),
      replaceRoute: false,
    }).then((playbackAuthorized) => {
      if (playbackAuthorized) {
        if (game.isLive) {
          dispatch(displayVideo(game));
          navigate(ROUTES[RouteName.GamesPlayer], { state: { goBackWhenNotAuthorized: true } });
        }
      }
    });
  };

  const handleOnClickReplay = () => {
    if (isSubscriptionPaused) {
      return handleResumeSubscription();
    }

    checkSubscription(game).then((playbackAuthorized) => {
      if (playbackAuthorized && (game.isLive || isPastGame)) {
        dispatch(displayVideo(game));
        navigate(
          `${ROUTES[RouteName.ScheduleGamesPlayer]}?${getSearchParamsFromAsset(game)}${
            isCatchupMode ? `&isCatchUpMode=${isCatchupMode}` : ''
          }`,
        );
      }
    });
  };

  if (isAvailable && isPastGame && isCatchupMode) {
    return (
      <ClickEventDispatcher
        destination='/schedule/games'
        location='Module: Schedule'
        text='CTA: Play Filters Option'
      >
        <Ui.PlayButton
          onClick={(event) => {
            event.stopPropagation();
            handleOnClickReplay();
          }}
        >
          <Play stroke={colors.primary} fill={colors.primary} width={20} height={20} />
        </Ui.PlayButton>
      </ClickEventDispatcher>
    );
  }

  if (isAvailable && (game.isLive || isPastGame)) {
    return (
      <ClickEventDispatcher
        destination='/schedule/games'
        location='Module: Schedule'
        text='CTA: Play Filters Option'
      >
        <Ui.PlayButton
          onClick={(event) => {
            event.stopPropagation();
            handleOnClickPlay();
          }}
        >
          <Play stroke={colors.primary} fill={colors.primary} width={20} height={20} />
        </Ui.PlayButton>
      </ClickEventDispatcher>
    );
  }

  if (isAvailable && isUpcoming) {
    return (
      <Ui.Clock>
        <ClockFilled width={24} height={24} fill={colors.primary} />
      </Ui.Clock>
    );
  }

  if (!isAvailable) {
    return (
      <ClickEventDispatcher
        destination='/schedule/games'
        location='Module: Schedule'
        text='CTA: Padlock Filters Option'
      >
        <Ui.Button
          onClick={(event) => {
            event.stopPropagation();
            handleOnClickPadlock();
          }}
        >
          <PadlockFilled fill={colors.primary} width={24} height={24} />
        </Ui.Button>
      </ClickEventDispatcher>
    );
  }

  return <div />;
};
