import { createAsyncThunk } from '@reduxjs/toolkit';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { Log } from '../../helpers/CustomLogger';
import { cacheManager } from '../../utils';
import { getErrorMessage } from '../../utils/error';
import { checkAuthCompleted } from '../auth';
import { RootState } from '../types';
import { ENTITLEMENTS_STORAGE_KEY } from './entitlements.constants';
import { entitlementsActions, loadEntitlementsAction } from './entitlements.slice';

export const loadEntitlements = createAsyncThunk(
  loadEntitlementsAction.type,
  async ({ accessToken }: { accessToken: string }, thunksapi) => {
    thunksapi.dispatch(entitlementsActions.loadEntitlementsLoading());
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, GET_ENTITLEMENTS_URL },
        },
      } = thunksapi.getState() as RootState;

      const fetchTimestamp = new Date().getTime();
      const entitlements = await evergentAuthApi.getEntitlements({
        accessToken,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getEntitlementsUrl: GET_ENTITLEMENTS_URL,
      });

      thunksapi.dispatch(
        entitlementsActions.loadEntitlementsSuccess({
          ...entitlements,
          fetchTimestamp,
        }),
      );
      cacheManager.save(ENTITLEMENTS_STORAGE_KEY, {
        ...entitlements,
        fetchTimestamp,
      });

      thunksapi.dispatch(checkAuthCompleted());
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(entitlementsActions.loadEntitlementsError(errorMessage));
    }
  },
);

export const clearEntitlements = createAsyncThunk(
  loadEntitlementsAction.type,
  async (payload: never, thunksapi) => {
    thunksapi.dispatch(entitlementsActions.clearEntitlements());
    cacheManager.remove(ENTITLEMENTS_STORAGE_KEY);
  },
);
