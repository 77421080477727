import { useEffect, useState } from 'react';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { NavigationItems } from '../Navigation.types';
import { useMenuItems } from './useMenuItems';

export const useNavigation = (): {
  items: NavigationItems;
  isMobileNavigationOpen: boolean;
  setIsMobileNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const items = useMenuItems();
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);
  const isDesktopView = useMediaQuery('lg');

  useEffect(() => {
    if (isDesktopView) {
      setIsMobileNavigationOpen(false);
    }
  }, [isDesktopView]);

  return {
    isMobileNavigationOpen,
    items,
    setIsMobileNavigationOpen,
  };
};
