import { PropsWithChildren, createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import {
  ContentAuthorizer,
  PlatformAuthorizer,
  PlatformClient,
} from '../../api/playerApi/playerApi.types';
import { useOvatTokenSelector } from '../../hooks/evergent/useOvatTokenSelector';
import useFLPContentAuthorizer from '../../hooks/flp/useFLPContentAuthorizer';
import useFLPPlatformAuthorizer from '../../hooks/flp/useFLPPlatformAuthorizer';
import useFLPPlatformClient from '../../hooks/flp/useFLPPlatformClient';
import { deviceInfoSelector } from '../../store/deviceInfo';

type FLPAuthorizersContextType = {
  platformAuthorizer: PlatformAuthorizer;
  platformClient: PlatformClient;
  contentAuthorizer: ContentAuthorizer;
};

const FLPAuthorizersContext = createContext({} as FLPAuthorizersContextType);

export const useFLPAuthorizers = () => useContext(FLPAuthorizersContext);

export const FLPAuthorizers = ({ children }: PropsWithChildren) => {
  const { ovatToken } = useOvatTokenSelector();
  const { deviceId } = useSelector(deviceInfoSelector);
  const platformAuthorizer = useFLPPlatformAuthorizer({ accessToken: ovatToken });
  const platformClient = useFLPPlatformClient({ deviceId });
  const contentAuthorizer = useFLPContentAuthorizer({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    platformAuthorizer,
    platformClient,
  });

  return (
    <FLPAuthorizersContext.Provider
      value={{
        contentAuthorizer,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        platformAuthorizer,
        platformClient,
      }}
    >
      {children}
    </FLPAuthorizersContext.Provider>
  );
};
