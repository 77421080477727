import styled, { css } from 'styled-components';

import { transparentButton } from '../../../../utils/styled/mixins';
import { FONT_WEIGHT } from '../../../Common/Typography.styles';
import { Item } from '../SidebarItem/SidebarItem.styles';

export const CollapseLink = styled(Item)`
  padding: 0;
`;

export const SubItemsWrapper = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => ($isOpen ? 'display: block' : 'display: none')};
  padding: 0 0 0.75rem 0;
`;

export const ParentLink = styled.button<{
  $isActive: boolean;
  $isOpen: boolean;
  $isEnabled: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme, $isEnabled }) =>
    $isEnabled ? theme.colors['pure-white'] : theme.colors['grey']};
  font-family: 'GothamNarrow';
  font-size: 1rem;
  font-style: normal;
  font-weight: ${({ $isActive }) => ($isActive ? 450 : 325)};
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: ${({ $isOpen }) => ($isOpen ? ' 1.5rem 0 0.75rem 0' : ' 1.5rem 0')};
  ${transparentButton}

  &:disabled {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

export const SubItem = styled.span<{ $isEnabled: boolean; $isActive: boolean }>`
  ${transparentButton}
  text-align: left;
  text-transform: uppercase;
  padding: 0.75rem 2.5rem;
  display: block;
  width: 100%;
  color: ${({ theme, $isEnabled }) =>
    $isEnabled ? theme.colors['pure-white'] : theme.colors['grey']};

  ${({ $isActive }) =>
    $isActive
      ? css`
          font-weight: ${FONT_WEIGHT.Bold};
        `
      : css`
          font-weight: ${FONT_WEIGHT.Regular};
        `}
`;

export const Logo = styled.span`
  padding-right: 1rem;
`;

export const Label = styled.span``;

export const ChevronWrapper = styled.div<{ $isOpen: boolean; $disabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    transition: transform 0.3s ease-in-out;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.colors.grey};
    `}
`;
