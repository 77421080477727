import { useContext } from 'react';

import { PlayerHandlerContext, PlayerHandlerContextType } from '../../components/Player/Player';

export const usePlayerContext = (): PlayerHandlerContextType => {
  const context = useContext(PlayerHandlerContext);

  if (!context) {
    throw new Error('usePlayerContext must be used within a PlayerContextProvider');
  }
  return context;
};
