export type TrackingEvent = {
  event:
    | 'creativeView'
    | 'impression'
    | 'start'
    | 'firstQuartile'
    | 'midpoint'
    | 'thirdQuartile'
    | 'complete';
  startTime: number;
  duration: number;
  signalingUrls: string[];
};

export type CompanionAds = {
  attributes: { [key: string]: unknown };
  companion: Companion[];
  images?: AdImages;
};

export type Companion = {
  staticResource: string;
  companionClickThrough?: string;
  companionClickTracking?: string;
  id?: string;
  attributes: {
    adSlotId: string;
    apiFramework?: string;
    assetHeight?: string;
    assetWidth?: string;
    expandedHeight?: string;
    expandedWidth?: string;
    height: string;
    id: string;
    pxratio?: string;
    renderingMode?: string;
    width: string;
  };
  adParameters?: string;
  altText?: string;
  htmlResource?: string;
  iFrameResource?: string;
  trackingEvents: Array<TrackingEvent>;
};

export type Ad = {
  id: string;
  startTime: number;
  startDateTime: number;
  duration: number;
  trackingEvents?: Array<TrackingEvent>;
  clickTracking?: string;
  companionAds?: CompanionAds[] | CompanionAds;
};

export type AdBreaks = {
  id: string;
  startTime: number;
  startDateTime: number;
  duration: number;
  ads: Array<Ad>;
  totalPlannedAds?: number;
};

export type AdImage = {
  image: HTMLImageElement;
  imageRedirectUrl?: string;
  trackingEvents?: Array<TrackingEvent>;
};

export type AdImages = {
  [AdSizeName.LARGE]: AdImage;
  [AdSizeName.SMALL]: AdImage;
  [AdSizeName.MEDIUM]: AdImage;
};
export type MappedAds = {
  id: string;
  startTime: number;
  startDateTime: number;
  duration: number;
  trackingEvents?: Array<TrackingEvent>;
  clickTracking?: string;
  companionAds: CompanionAds[];
};

export enum AdSizeName {
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}
