export type ErrorWithMessage = {
  message: string;
};

export type ErrorWithInternalObj = {
  internalError?: {
    errorCode: string;
  };
};

export const isErrorWithMessage = (item: unknown): item is ErrorWithMessage => {
  return (
    typeof item === 'object' &&
    item !== null &&
    'message' in item &&
    'name' in item &&
    'url' in item &&
    'visible' in item &&
    typeof item.message === 'string'
  );
};

export const isErrorWithInternalObj = (item: unknown): item is ErrorWithInternalObj => {
  return (
    typeof item === 'object' &&
    item !== null &&
    'internalError' in item &&
    typeof item.internalError === 'object'
  );
};

export const getErrorMessage = (error: unknown) => {
  let errorMessage: string;
  if (isErrorWithMessage(error)) {
    errorMessage = error?.message;
  } else {
    errorMessage = `${error}`;
  }

  return errorMessage;
};
