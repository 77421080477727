import { isAfter } from 'date-fns';

import { cacheManager } from '../../../utils/cache';
import {
  ADOBE_AUTHORIZATION_RESPONSE,
  ADOBE_AUTHORIZATION_TOKEN,
  ADOBE_ERRORS_CODES,
  ADOBE_TOKENS_AUTHZ_RESPONSE,
} from './adobeApi.types';

export const clearAdobeAuthorizations = () => {
  cacheManager.remove(ADOBE_AUTHORIZATION_TOKEN);
  cacheManager.remove(ADOBE_AUTHORIZATION_RESPONSE);
  cacheManager.remove(ADOBE_TOKENS_AUTHZ_RESPONSE);
};

export const isAdobeError = (code: number) => {
  return ADOBE_ERRORS_CODES.includes(code);
};

export const needToRefresh = (expires: string | undefined) => {
  const expiresIn = Number(expires);

  if (isNaN(expiresIn)) {
    return true;
  }
  return isAfter(new Date(), expiresIn);
};
