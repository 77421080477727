
import { FLCastPlayer, FLLocalPlayer, StreamTimelineEvent } from '..';

export const flpOnStreamtimeLineEventAdapter = (
    flPlayer: FLLocalPlayer | FLCastPlayer,
    callback: (streamTimelineEvent: StreamTimelineEvent) => void,
  ) => {
    const adaptedCallback = (streamTimelineEvent: StreamTimelineEvent) => {
      callback(streamTimelineEvent);
    };
    flPlayer.subscribe('streamtimelineevent', adaptedCallback);
    return () => {
      flPlayer.unsubscribe('streamtimelineevent', adaptedCallback);
    };
  };
  