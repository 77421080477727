import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { activeSubscriptionsSelector } from '../../store/activeSubscriptions';
import { useEnv } from '../EnvProvider/EnvProvider';

export const TVEProviderLogo = ({ height }: { height?: number | string }) => {
  const { tveProviderName } = useSelector(activeSubscriptionsSelector);
  const env = useEnv();
  const tveProviderId = useMemo(() => tveProviderName?.toLowerCase(), [tveProviderName]);
  const tveLogoFileName = useMemo(
    () => typeof tveProviderId === 'string' && `${tveProviderId.toLowerCase()}.png`,
    [tveProviderId],
  );
  const logoHeight = useMemo(() => height || 59, [height]);
  const fullUrl = `${env.QUICKPLAY_ASSETS_CDN_URL}/${tveLogoFileName}`;

  return (
    <>
      {tveLogoFileName && (
        <img
          className='align-self-center'
          src={fullUrl}
          alt={tveProviderName}
          style={{ height: logoHeight }}
        />
      )}
    </>
  );
};
