import { FirebaseApp } from 'firebase/app';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppInitializer } from './AppInitializer/AppInitializer';
import { setupInterceptor } from './api/authApi/EvergentAuthApi.interceptor';
import './assets/fonts/gotham-fonts-master/css/index.css';
import './assets/fonts/gotham-narrow/index.css';
import BlueBg from './assets/images/minimized/bg_blue_1920.jpg';
import './assets/scss/Common/app-bootstrap.scss';
import { ConvivaProvider } from './components/ConvivaProvider/ConvivaProvider';
import { DataZoomProvider } from './components/DataZoomProvider/DataZoomProvider';
import { EnvLoader } from './components/EnvLoader/EnvLoader';
import { FLPAuthorizers } from './components/FLPAuthorizers/FLPAuthorizers';
import { GlobalStyle } from './components/GlobalStyle/GlobalStyle';
import { LegalInfoBar } from './components/LegalInfoBar';
import { ModalProvider } from './components/Modals/ModalProvider';
import { SimpleModalProvider } from './components/Modals/SimpleModal/SimpleModalProvider';
import { ScrollToTop } from './components/ScrollToTop';
import { ThemeProvider } from './components/ThemeProvider/ThemeProvider';
import { Toast } from './components/Toast/Toast';
import { TranslationsProvider } from './components/TranslationsProvider/TranslationsProvider';
import { Version } from './components/Version/Version';
import firebase from './firebase/firebase';
import { flows } from './flow/flows';
import { useInitFlowManager } from './flow/hooks/useInitFlowManager';
import { useAppInit } from './hooks/useAppInit';
import useImagePreload from './hooks/useImagePreload';
import { useSetEntryPage } from './hooks/useSetEntryPage';
import { useUserInteracted } from './hooks/useUserInteracted';
import { Routes } from './routes';
import { store } from './store/store';

// firebase init - just so the import is not removed by import optimizer
// eslint-disable-next-line
const fbName = firebase as unknown as FirebaseApp['name'];

setupInterceptor(store);

function App() {
  useSetEntryPage();
  useInitFlowManager(flows);
  useImagePreload([BlueBg]);
  useUserInteracted();
  const { appReady, env, teams } = useAppInit();

  return (
    <Provider store={store}>
      <EnvLoader appReady={appReady} env={env} teams={teams}>
        <ThemeProvider>
          <GlobalStyle />
          <DataZoomProvider>
            <ConvivaProvider>
              <BrowserRouter>
                <TranslationsProvider>
                  <SimpleModalProvider>
                    <ModalProvider>
                      <FLPAuthorizers>
                        <AppInitializer>
                          <Routes />
                        </AppInitializer>
                      </FLPAuthorizers>
                      <ScrollToTop />
                      <LegalInfoBar />
                      <Toast />
                    </ModalProvider>
                  </SimpleModalProvider>
                </TranslationsProvider>
              </BrowserRouter>
            </ConvivaProvider>
          </DataZoomProvider>
          <Version />
        </ThemeProvider>
      </EnvLoader>
    </Provider>
  );
}

export default App;
