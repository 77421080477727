import { generatePath } from 'react-router';

import { ROUTES, RouteName, TRouteParams } from '../Constants/routes';
import { Log } from '../helpers/CustomLogger';

export const getRouteByName = (name: string): string => ROUTES[name as RouteName] || '';

/**
 * Wrapper to include params in route pathname
 * @param routeName - RouteName with params, ie "/edit-profile/:profileId"
 * @param params - params that should be inserted into route
 */
export const getRouteByNameWithParams = (routeName: RouteName, params: TRouteParams): string => {
  try {
    return generatePath(getRouteByName(routeName), params);
  } catch (error) {
    Log.error('Missing param in route name.');
  }
  return getRouteByName(routeName);
};
