import { Modal as ModalBootstrap } from 'react-bootstrap';
import { css, styled } from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { TypographyProps, bolder, fonts, textBase } from '../../Common';

const BUTTONS_GAP_DESKTOP = '1.5rem';

export const Modal = styled(ModalBootstrap)`
  z-index: ${({ theme }) => theme.zIndex.simpleModal};
  display: flex !important;
  align-items: center;

  .modal-dialog {
    max-width: 37.5rem;
    width: 100%;

    ${breakpoint('md')`
      min-width: 37.5rem;
      width: auto;
    `}
  }

  .modal-content {
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors['grey-dark']};

    ${breakpoint('lg')`
		  padding: 3rem;
		`}
  }
`;

export const Body = styled(ModalBootstrap.Body)``;

export const Title = styled.h1<TypographyProps>`
  ${textBase}
  ${bolder}
  ${fonts.header2.xs}
  padding-bottom: 2rem;
`;

export const DescritpionText = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.body3.xl}
 
  ${breakpoint('lg')`
    ${fonts.body2.xl}
	`}
`;

export const ButtonsWrapper = styled.div<{
  $singleButton: boolean;
  $reverseButtonOnMobile?: boolean;
  $withTopSpacing?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.$reverseButtonOnMobile ? 'column-reverse' : 'column')};
  gap: 1rem;

  ${breakpoint('md')`
    flex-direction: row;
    gap: ${BUTTONS_GAP_DESKTOP};
  `}

  ${({ $withTopSpacing }) =>
    $withTopSpacing &&
    css`
      padding-top: 2rem;
    `}

  button {
    width: 100%;

    ${({ $singleButton }) =>
      !$singleButton &&
      css`
        //min-width: 15rem;
        ${breakpoint('lg')`
            width: calc(50% - calc(${BUTTONS_GAP_DESKTOP} / 2));
          `}
      `}
  }
`;
