import { useEffect } from 'react';

import { GetProductsResponseItem } from '../../../api/authApi/authApi.types';
import useEvergentIframeContext from '../../../hooks/evergent/useEvergentIframeContext';
import useSendSubscriptionRequest from '../../../hooks/evergent/useSendSubscriptionRequest';
import EvergentIframeElement from '../../EvergentIframe/EvergentIframeElement';

type PurchaseIframeBaseProps = {
  product: GetProductsResponseItem;
  purchaseToken?: string | null;
  needPuschaseToken?: boolean;
  zoneKey: string;
};

export const PurchaseIframeBase = ({
  product,
  purchaseToken,
  zoneKey,
}: PurchaseIframeBaseProps) => {
  const { zipCode, ready, iframeRef, total, couponCode } = useEvergentIframeContext();
  const sendSubscriptionRequest = useSendSubscriptionRequest();
  const iframe = iframeRef.current?.contentWindow;

  useEffect(() => {
    if (iframe && ready) {
      if (purchaseToken || purchaseToken !== null) {
        sendSubscriptionRequest({
          couponCode,
          iframe,
          product,
          purchaseToken,
          total,
          zipCode,
          zoneKey,
        });
      }
    }
  }, [product, purchaseToken, zipCode, zoneKey, ready, iframe, sendSubscriptionRequest, total]);

  return <EvergentIframeElement />;
};

export default PurchaseIframeBase;
