import { SVGProps } from 'react';

export const InfoErrorLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='100'
    viewBox='0 0 100 100'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.0001 11.1112C28.5224 11.1112 11.1112 28.5224 11.1112 50.0001C11.1112 71.4778 28.5224 88.889 50.0001 88.889C71.4778 88.889 88.889 71.4778 88.889 50.0001C88.889 28.5224 71.4778 11.1112 50.0001 11.1112ZM5.55566 50.0001C5.55566 25.4541 25.4541 5.55566 50.0001 5.55566C74.5461 5.55566 94.4445 25.4541 94.4445 50.0001C94.4445 74.5461 74.5461 94.4445 50.0001 94.4445C25.4541 94.4445 5.55566 74.5461 5.55566 50.0001Z'
      fill={props?.fill ? props.fill : '#EEEEEE'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.0831 36.1112H47.9164C46.3823 36.1112 45.1387 34.8676 45.1387 33.3334C45.1387 31.7993 46.3823 30.5557 47.9164 30.5557L52.0831 30.5557C53.6172 30.5557 54.8609 31.7993 54.8609 33.3334C54.8609 34.8676 53.6172 36.1112 52.0831 36.1112ZM49.9998 43.0557C51.5339 43.0557 52.7776 44.2993 52.7776 45.8334V66.6668C52.7776 68.2009 51.5339 69.4445 49.9998 69.4445C48.4657 69.4445 47.222 68.2009 47.222 66.6668V45.8334C47.222 44.2993 48.4657 43.0557 49.9998 43.0557Z'
      fill={props?.fill ? props.fill : '#EEEEEE'}
    />
  </svg>
);
