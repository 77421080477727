export function formatDuration(rawSecondsLeft: number) {
  if (isNaN(rawSecondsLeft)) {
    return '00:00';
  }
  const secondsLeft = Math.floor(rawSecondsLeft);
  const seconds = secondsLeft % 60;
  const minutesLeft = Math.floor(secondsLeft / 60);
  const minutes = minutesLeft % 60;
  const hoursLeft = Math.floor(minutesLeft / 60);
  const hours = hoursLeft;
  const timeParts: string[] = [];
  if (hours > 0) {
    timeParts.push(hours.toString());
    timeParts.push(minutes.toString().padStart(2, '0'));
  } else {
    timeParts.push(minutes.toString());
  }
  timeParts.push(seconds.toString().padStart(2, '0'));

  return timeParts.join(':');
}
