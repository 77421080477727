import { useState } from 'react';

export const COMPANION_ADS_DEBUG_VIEW_ENABLED_KEY = 'debugViewEnabled';

export const CompanionAdsSettings = () => {
  const [isDebugViewEnabled, setIsDebugViewEnabled] = useState(
    localStorage.getItem(COMPANION_ADS_DEBUG_VIEW_ENABLED_KEY) === 'true',
  );

  const onDebugChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      localStorage.setItem(COMPANION_ADS_DEBUG_VIEW_ENABLED_KEY, 'true');
      setIsDebugViewEnabled(true);
    } else {
      localStorage.setItem(COMPANION_ADS_DEBUG_VIEW_ENABLED_KEY, 'false');
      setIsDebugViewEnabled(false);
    }
  };

  return (
    <>
      <h2>Companion ads settings</h2>

      <div className='mb-4'>
        <label htmlFor=''>Debug view enabled </label>
        <input
          type='checkbox'
          className='mx-3'
          checked={isDebugViewEnabled}
          onChange={onDebugChanged}
        />
      </div>
    </>
  );
};
