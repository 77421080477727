import { ResponseStatsConfigItem } from '../../../store/msgStats';

const isResponseStatsConfigItem = (item: unknown): item is ResponseStatsConfigItem => {
  return (
    typeof item === 'object' &&
    item !== null &&
    'id' in item &&
    'name' in item &&
    'url' in item &&
    'visible' in item &&
    typeof item.id === 'number' &&
    typeof item.name === 'string' &&
    typeof item.url === 'string' &&
    typeof item.visible === 'boolean'
  );
};

export const areResponseStatsConfigItems = (data: unknown): data is ResponseStatsConfigItem[] => {
  if (!Array.isArray(data)) {
    return false;
  }

  return data.every((item) => isResponseStatsConfigItem(item));
};
