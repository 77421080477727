import { createAsyncThunk } from '@reduxjs/toolkit';
import { isObject } from 'lodash-es';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import {
  ActivationCodeResponse,
  ActivationCodeValidatedResponse,
} from '../../api/authApi/authApi.types';
import { Log } from '../../helpers/CustomLogger';
import { cacheManager } from '../../utils';
import { getErrorMessage } from '../../utils/error';
import { AUTH_SESSION_STORAGE_KEY, SessionType, authActions } from '../auth';
import { RootState } from '../types';
import { deviceCodeActions, generateDeviceCode } from './deviceCode.slice';
import { ActivationCodePayload } from './deviceCode.types';

const checkResponseTypeActivationCodeResponse = (
  response: unknown,
): response is ActivationCodeResponse => {
  return isObject(response) && 'activationCode' in response;
};
const checkResponseTypeActivationCodeValidatedResponse = (
  response: unknown,
): response is ActivationCodeValidatedResponse => {
  return isObject(response) && 'accessToken' in response;
};

export const getActivationCode = createAsyncThunk(
  generateDeviceCode.type,
  async (payload: ActivationCodePayload, thunksapi) => {
    try {
      thunksapi.dispatch(deviceCodeActions.getActivationCodeSetLoading(true));
      const {
        appConfig: {
          env: {
            EVERGENT_API_KEY,
            EVERGENT_CHANNEL_PARTNER_ID,
            GENERATE_ACTIVATION_CODE,
            QA_USER_NAME,
            VIP_USER_NAME,
          },
        },
      } = thunksapi.getState() as RootState;

      const getActivationCode = await evergentAuthApi.getActivationCode({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        generateActivationCode: GENERATE_ACTIVATION_CODE,
      });

      const { auth: currentSession } = thunksapi.getState() as RootState;

      if (checkResponseTypeActivationCodeResponse(getActivationCode)) {
        thunksapi.dispatch(deviceCodeActions.getActivationCodeSuccess(getActivationCode));
      }

      if (checkResponseTypeActivationCodeValidatedResponse(getActivationCode)) {
        thunksapi.dispatch(deviceCodeActions.getActivationCodeValidatedSuccess(getActivationCode));
        const session: SessionType = {
          ...currentSession,
          accessToken: getActivationCode.accessToken,
          accountRole: getActivationCode.accountRole,
          expiresIn: '',
          isQAUser: getActivationCode.accountRole === QA_USER_NAME,
          isVIPUser: getActivationCode.accountRole === VIP_USER_NAME,
          refreshToken: getActivationCode.refreshToken,
        };
        thunksapi.dispatch(authActions.loginSuccess(session));
        cacheManager.save(AUTH_SESSION_STORAGE_KEY, session);
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(deviceCodeActions.getActivationCodeError(errorMessage));
    }
  },
);
