import { useMemo } from 'react';

import { flpPlayerApi } from '../../api';
import { useEnv } from '../../components/EnvProvider/EnvProvider';

export const useFLPPlatformClient = ({ deviceId }: { deviceId: string }) => {
  const { CLIENT_NAME } = useEnv();
  return useMemo(() => {
    return flpPlayerApi.createPlatformClient(deviceId, CLIENT_NAME);
  }, [deviceId]);
};

export default useFLPPlatformClient;
