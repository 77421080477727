/* eslint-disable sort-keys-fix/sort-keys-fix */

export const BREAKPOINT = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1440,
  fullHD: 1920,
  QHD: 2560,
  ultraHD: 3840,
} as const;

export type BreakpointKey = keyof typeof BREAKPOINT;
