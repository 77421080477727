import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { FONT_WEIGHT } from '../../../Common';

export const Row = styled.div<{ $isDark?: boolean; $isAvailable?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 64px 38px;
  column-gap: 0.75rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors['grey']}`};
  padding: 0.75rem 0.625rem;

  &:hover {
    background: ${({ theme }) => `${theme.colors['white-scrim']}`};
    cursor: ${({ $isAvailable }) => ($isAvailable ? 'default' : 'pointer')};
  }

  ${breakpoint('lg')`
    grid-template-columns: 1fr 10rem 3rem;
    column-gap: 1.5rem;
    padding: 0.5rem 2rem 0.5rem 2rem;
  `}

  ${({ $isDark, theme }) =>
    $isDark &&
    `
		  background: ${theme.colors['grey-deep']}};
  	`}
`;

export const TeamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${breakpoint('lg')`
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  `}
`;

export const Team = styled.div`
  flex: 1;
  font-family: 'GothamNarrow';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors['primary-light']};
  text-transform: uppercase;
`;

export const Time = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    white-space: nowrap;
    font-style: normal;
    font-weight: ${FONT_WEIGHT.Regular};
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ${({ theme }) => theme.colors['pure-white']};
    font-family: 'GothamNarrow';

    ${breakpoint('lg')`
      text-transform: uppercase;
      font-size: 1rem;   
      line-height: 1.25rem;
    `}
  }
`;

export const LivePill = styled.div`
  font-family: 'GothamNarrow';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 33px;
  color: ${({ theme }) => theme.colors['additional-color-black']};
  padding: 0.375rem 0.625rem;
  line-height: 0.875rem;
  margin-bottom: 0.25rem;

  ${breakpoint('lg')`
    line-height: 1.125rem;
    padding: 0.5rem 0.75rem;
  `}
  svg {
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.25rem;

    ${breakpoint('lg')`
      width: 0.875rem;
      height: 0.875rem;
    `}
  }
`;
