import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';

export const browserSelector = (state: RootState) => state.browser;
export const isActiveSelector = createSelector(browserSelector, ({ isActive }) => isActive);
export const isFocusedSelector = createSelector(browserSelector, ({ isFocused }) => isFocused);
export const currentPathnameSelector = createSelector(
  browserSelector,
  ({ currentPathname }) => currentPathname,
);

export const inactivityTimeSelector = createSelector(
  browserSelector,
  ({ inactivityEndTime, inactivityStartTime }) => {
    if (inactivityEndTime <= inactivityStartTime) {
      return 0;
    }
    return inactivityEndTime - inactivityStartTime;
  },
);
