import { authorizePlayback } from './authorizePlayback';
import { createCastManager } from './createCastManager';
import { createHeartbeatManager } from './createHeartbeatManager';
import { createPlatformAuthorizer } from './createPlatformAuthorizer';
import { createPlatformClient } from './createPlatformClient';
import { createPlayerHandler } from './createPlayerHandler';
import { getContentAuthorizer } from './getContentAuthorizer';

export const flpPlayerApi = {
  authorizePlayback,
  createCastManager,
  createHeartbeatManager,
  createPlatformAuthorizer,
  createPlatformClient,
  createPlayerHandler,
  getContentAuthorizer,
};

export * from './playerApi.types';
