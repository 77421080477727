import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FLPlatformAsset, PlayerState as PlState } from '../../api';
import { setPlayerError } from './player.thunks';
import { PlayerError, PlayerState } from './player.types';

const initialState: PlayerState = {
  asset: undefined,
  assetError: undefined,
  assetLoading: true,
  error: undefined,
  isFullScreen: !!document.fullscreenElement,
  isLocalPlayer: undefined,
  maxHeight: '',
  state: undefined,
};

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(setPlayerError.fulfilled, (state, action) => {
      state.error = action.payload;
    });
  },
  initialState,
  name: 'player',
  reducers: {
    resetPlayerAsset: () => initialState,
    setFullScreenValue: (state, action: PayloadAction<boolean>) => {
      state.isFullScreen = action.payload;
    },
    setIsLocalPlayer: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLocalPlayer: action.payload,
    }),
    setLiveStreamEnded: (state, action: PayloadAction<boolean>) => {
      state.asset = { ...(state.asset as FLPlatformAsset), isLiveStreamEnded: action.payload };
    },
    setPlayerAsset: (state, action: PayloadAction<FLPlatformAsset | undefined>): PlayerState => ({
      ...state,
      asset: action.payload,
    }),
    setPlayerAssetError: (state, action: PayloadAction<PlayerError | undefined>) => ({
      ...state,
      assetError: action.payload,
    }),
    setPlayerAssetLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      assetLoading: action.payload,
    }),
    setPlayerMaxHeight: (state, action: PayloadAction<string>) => ({
      ...state,
      maxHeight: action.payload,
    }),
    setPlayerState: (state, action: PayloadAction<PlState>) => ({
      ...state,
      state: action.payload,
    }),
  },
});

const { reducer, actions } = slice;

export { actions as playerActions, initialState, reducer };
