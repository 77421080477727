import { useCallback, useEffect, useState } from 'react';

import { useFLPLocalVolumeControl } from './useFLPLocalVolumeControl';

export const useFLPVolumeControlState = ({ ref }: { ref: React.RefObject<HTMLVideoElement> }) => {
  const localVolumenControl = useFLPLocalVolumeControl({ ref });

  const [volumenControl, setVolumeControl] = useState(localVolumenControl);

  useEffect(() => {
    setVolumeControl(localVolumenControl);
  }, [localVolumenControl]);

  const restoreLocalVolumenControl = useCallback(() => {
    setVolumeControl(localVolumenControl);
  }, [localVolumenControl]);

  return { restoreLocalVolumenControl, setVolumeControl, volumenControl };
};
