import { SVGProps } from 'react';

import { colors } from '../../components/ThemeProvider/constants/colors';

export const CloseCaption = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.33301 9.99984C3.33301 8.08278 4.98091 6.6665 6.83728 6.6665H33.1621C35.0184 6.6665 36.6663 8.08278 36.6663 9.99984V29.9998C36.6663 31.9169 35.0184 33.3332 33.1621 33.3332H6.83728C4.9809 33.3332 3.33301 31.9169 3.33301 29.9998V9.99984ZM6.83728 8.88873C6.05024 8.88873 5.55523 9.4623 5.55523 9.99984V29.9998C5.55523 30.5374 6.05024 31.1109 6.83728 31.1109H33.1621C33.9491 31.1109 34.4441 30.5374 34.4441 29.9998V9.99984C34.4441 9.4623 33.9491 8.88873 33.1621 8.88873H6.83728Z'
      fill={fill ? fill : colors['pure-white']}
    />
    <path
      d='M14.96 25.4757C14.2489 25.4757 13.5852 25.3453 12.9689 25.0846C12.3644 24.8238 11.843 24.4742 11.4044 24.0357C10.9659 23.5853 10.6222 23.0638 10.3733 22.4712C10.1244 21.8668 10 21.2268 10 20.5512V20.5157C10 19.8401 10.1244 19.2001 10.3733 18.5957C10.6222 17.9912 10.9659 17.4638 11.4044 17.0134C11.843 16.5631 12.3644 16.2075 12.9689 15.9468C13.5852 15.686 14.2607 15.5557 14.9956 15.5557C15.8963 15.5557 16.643 15.6979 17.2356 15.9823C17.8281 16.2668 18.3496 16.6579 18.8 17.1557L17.1467 18.9334C16.8385 18.6134 16.5185 18.3586 16.1867 18.169C15.8667 17.9794 15.4637 17.8846 14.9778 17.8846C14.6341 17.8846 14.32 17.9557 14.0356 18.0979C13.763 18.2283 13.52 18.412 13.3067 18.649C13.1052 18.886 12.9452 19.1646 12.8267 19.4846C12.72 19.7927 12.6667 20.1246 12.6667 20.4801V20.5157C12.6667 20.8831 12.72 21.2268 12.8267 21.5468C12.9452 21.8668 13.1111 22.1453 13.3244 22.3823C13.5378 22.6194 13.7926 22.809 14.0889 22.9512C14.3852 23.0816 14.717 23.1468 15.0844 23.1468C15.5348 23.1468 15.9259 23.0579 16.2578 22.8801C16.6015 22.6905 16.9393 22.4416 17.2711 22.1334L18.8533 23.7334C18.3911 24.2431 17.8637 24.6638 17.2711 24.9957C16.6785 25.3157 15.9081 25.4757 14.96 25.4757Z'
      fill={fill ? fill : colors['pure-white']}
    />
    <path
      d='M25.1163 25.4757C24.4051 25.4757 23.7414 25.3453 23.1251 25.0846C22.5207 24.8238 21.9992 24.4742 21.5607 24.0357C21.1222 23.5853 20.7785 23.0638 20.5296 22.4712C20.2807 21.8668 20.1563 21.2268 20.1563 20.5512V20.5157C20.1563 19.8401 20.2807 19.2001 20.5296 18.5957C20.7785 17.9912 21.1222 17.4638 21.5607 17.0134C21.9992 16.5631 22.5207 16.2075 23.1251 15.9468C23.7414 15.686 24.417 15.5557 25.1518 15.5557C26.0525 15.5557 26.7992 15.6979 27.3918 15.9823C27.9844 16.2668 28.5059 16.6579 28.9562 17.1557L27.3029 18.9334C26.9948 18.6134 26.6748 18.3586 26.3429 18.169C26.0229 17.9794 25.62 17.8846 25.134 17.8846C24.7903 17.8846 24.4763 17.9557 24.1918 18.0979C23.9192 18.2283 23.6763 18.412 23.4629 18.649C23.2614 18.886 23.1014 19.1646 22.9829 19.4846C22.8763 19.7927 22.8229 20.1246 22.8229 20.4801V20.5157C22.8229 20.8831 22.8763 21.2268 22.9829 21.5468C23.1014 21.8668 23.2674 22.1453 23.4807 22.3823C23.694 22.6194 23.9488 22.809 24.2451 22.9512C24.5414 23.0816 24.8733 23.1468 25.2407 23.1468C25.6911 23.1468 26.0822 23.0579 26.414 22.8801C26.7577 22.6905 27.0955 22.4416 27.4274 22.1334L29.0096 23.7334C28.5474 24.2431 28.02 24.6638 27.4274 24.9957C26.8348 25.3157 26.0644 25.4757 25.1163 25.4757Z'
      fill={fill ? fill : colors['pure-white']}
    />
  </svg>
);
