import { useEffect } from 'react';

import { FLPlatformAsset } from '../../api';
import { PerformanceMeasure } from '../constants';
import { useFirebasePerformanceTracer } from './useFirebasePerformanceTracer';

export const useMeasureMediaPerformance = (
  ref: React.RefObject<HTMLVideoElement>,
  asset: FLPlatformAsset | undefined,
) => {
  const { startTracer, stopTracer } = useFirebasePerformanceTracer(
    PerformanceMeasure.MEDIA_PLAYBACK,
  );
  useEffect(() => {
    startTracer({ additionalCondition: Boolean(asset?.contentId) });
  }, [asset?.contentId]);

  useEffect(() => {
    stopTracer({
      additionalCondition: Boolean(
        !ref?.current?.paused &&
          !ref?.current?.ended &&
          (ref?.current?.currentTime as number) > 0 &&
          ref?.current?.buffered,
      ),
      withReset: true,
    });
  }, [ref?.current?.paused, ref?.current?.ended, ref?.current?.currentTime]);
};
