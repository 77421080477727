import { createAsyncThunk } from '@reduxjs/toolkit';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import {
  loadMarketingPreferenceAction,
  marketingPreferencesActions,
  setMarketingPreferenceAction,
} from './marketingPreferences.slice';

export const getMarketingPreferences = createAsyncThunk(
  loadMarketingPreferenceAction.type,
  async (payload: { accessToken: string }, thunksapi) => {
    try {
      thunksapi.dispatch(marketingPreferencesActions.loadMarketingPreferencesLoading());
      const data = { accessToken: payload.accessToken, attributeName: 'MarketingPreferences' };

      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, GET_STRING_URL },
        },
      } = thunksapi.getState() as RootState;

      const response = await evergentAuthApi.getString({
        ...data,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getStringUrl: GET_STRING_URL,
      });
      thunksapi.dispatch(marketingPreferencesActions.loadMarketingPreferencesSuccess(response));
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(marketingPreferencesActions.loadMarketingPreferencesError(errorMessage));
    }
  },
);

export const setMarketingPreferences = createAsyncThunk(
  setMarketingPreferenceAction.type,
  async (payload: { accessToken: string; attributeValue: boolean }, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, SET_STRING_URL },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(marketingPreferencesActions.setMarketingPreferencesLoading());
      const data = {
        accessToken: payload.accessToken,
        attributeName: 'MarketingPreferences',
        attributeValue: payload.attributeValue,
      };
      const response = await evergentAuthApi.setString({
        ...data,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        setStringUrl: SET_STRING_URL,
      });
      const response2 = { ...response, value: payload.attributeValue };
      thunksapi.dispatch(marketingPreferencesActions.setMarketingPreferencesSucces(response2));
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(marketingPreferencesActions.setMarketingPreferencesError(errorMessage));
    }
  },
);
