import { CacheManager, cacheManager } from '../utils';
import { Flow } from './flow';
import { FLOW_STORAGE_KEY } from './flow.constants';
import { AllFlowsMap, FlowOnStepChange, StepConfig } from './flow.types';
import { getFlowByStartingPoint, getPathnameFromUrl } from './utils';

export class FlowManager {
  initialized = false;
  private flow: Flow | undefined;
  private cacheManager: CacheManager = cacheManager;
  private allFlows: AllFlowsMap | undefined;
  private static instance: FlowManager | null = null;

  public static getInstance(): FlowManager {
    if (!FlowManager.instance) {
      FlowManager.instance = new FlowManager();
    }
    return FlowManager.instance;
  }

  get currentStep() {
    return this.flow?.currentStep;
  }

  get previousStep() {
    return this.flow?.currentStep;
  }

  get flowId() {
    return this.flow?.id;
  }

  init(entryPage: string, allFlows: AllFlowsMap) {
    this.initFlow(entryPage, allFlows);
  }

  next(onNext?: FlowOnStepChange) {
    this.flow?.next(onNext);
  }

  previous(onPrevious?: FlowOnStepChange) {
    this.flow?.previous(onPrevious);
  }

  isInitialized() {
    return this.initialized;
  }

  isOneOfPreviousSteps(pathname: string) {
    return !!this.flow?.isOneOfPreviousSteps(pathname);
  }

  isFlowStep(pathname: string) {
    return !!this.flow?.isFlowStep(pathname);
  }

  isLastStep() {
    return !!this.flow?.isLastStep();
  }

  goToStep(pathname: string, onStepChange?: FlowOnStepChange) {
    return this.flow?.goTo(pathname, onStepChange);
  }

  exit() {
    this.flow?.exit();
    this.flow = undefined;
    this.initialized = false;

    if (this.cacheManager) {
      this.cacheManager.remove(FLOW_STORAGE_KEY);
    }
  }

  private getCachedFlowSteps() {
    const cachedFlowId = this.cacheManager?.load<string>(FLOW_STORAGE_KEY);
    return cachedFlowId ? (this.allFlows as AllFlowsMap)[cachedFlowId] : null;
  }

  private getCachedFlowId() {
    return this.cacheManager?.load<string>(FLOW_STORAGE_KEY);
  }

  private initFlow(entryPage: string, allFlows: AllFlowsMap) {
    this.allFlows = allFlows;

    if (this.isContinuationOfTheFlow(entryPage)) {
      this.initialized = true;
      this.flow = new Flow(
        this.getCachedFlowId() as string,
        this.getCachedFlowSteps() as StepConfig[],
      );
      return this.goToStep(getPathnameFromUrl(entryPage));
    }

    if (this.isNewFlow(entryPage)) {
      const flowId = getFlowByStartingPoint(entryPage, this.allFlows);

      if (!flowId) return;

      this.initialized = true;
      this.flow = new Flow(flowId, this.allFlows[flowId]);
      this.cacheManager.save(FLOW_STORAGE_KEY, flowId);
    }
  }

  private isContinuationOfTheFlow(entryPage: string) {
    if (!this.getCachedFlowId()) return false;

    const cachedFlowSteps = this.getCachedFlowSteps();
    const pathname = getPathnameFromUrl(entryPage);
    const isFirstStep = pathname === cachedFlowSteps?.[0].pathname;
    return !isFirstStep && !!cachedFlowSteps?.some((step) => step.pathname === pathname);
  }

  private isNewFlow(entryPage: string) {
    return !!getFlowByStartingPoint(entryPage, this.allFlows as AllFlowsMap);
  }
}

const flowManager = FlowManager.getInstance();
export default flowManager;
