import { createContext, useContext, useState } from 'react';

const PlayerTimeContext = createContext<
  | {
      seekValue: number | undefined;
      currentTime: number;
      duration: number;
      setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
      setDuration: React.Dispatch<React.SetStateAction<number>>;
      setSeekValue: React.Dispatch<React.SetStateAction<number | undefined>>;
    }
  | undefined
>(undefined);

export const usePlayerTimeContext = () => {
  const context = useContext(PlayerTimeContext);
  if (!context) {
    throw new Error('usePlayerTimeContext must be used within a PlayerTimeProvider');
  }
  return context;
};

export const PlayerTimeProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seekValue, setSeekValue] = useState<number | undefined>(undefined);

  return (
    <PlayerTimeContext.Provider
      value={{
        currentTime,
        duration,
        seekValue,
        setCurrentTime,
        setDuration,
        setSeekValue,
      }}
    >
      {children}
    </PlayerTimeContext.Provider>
  );
};
