import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { Channel } from '../../api';
import { ChannelsState } from './channels.types';

export const initialState: ChannelsState = {
  channels: [],
  error: '',
  loaded: false,
  loading: false,
};

export const loadChannelsAction = createAction('channels/loadChannels');

const slice = createSlice({
  initialState: initialState,
  name: 'channels',
  reducers: {
    loadChannelsError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    loadChannelsLoading: (state) => ({
      ...state,
      loading: true,
    }),
    loadChannelsSuccess: (state, action: PayloadAction<Channel[]>) => ({
      ...state,
      channels: action.payload,
      loaded: true,
      loading: false,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as channelsActions, name, reducer };
