export const CrossInCircleLarge = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100'
    height='100'
    viewBox='0 0 100 100'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.0001 11.1112C28.5224 11.1112 11.1112 28.5224 11.1112 50.0001C11.1112 71.4778 28.5224 88.889 50.0001 88.889C71.4778 88.889 88.889 71.4778 88.889 50.0001C88.889 28.5224 71.4778 11.1112 50.0001 11.1112ZM5.55566 50.0001C5.55566 25.4541 25.4541 5.55566 50.0001 5.55566C74.5461 5.55566 94.4445 25.4541 94.4445 50.0001C94.4445 74.5461 74.5461 94.4445 50.0001 94.4445C25.4541 94.4445 5.55566 74.5461 5.55566 50.0001Z'
      fill='#F44336'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.5358 35.5358C36.6206 34.451 38.3793 34.451 39.4641 35.5358L49.9999 46.0716L60.5358 35.5358C61.6206 34.451 63.3793 34.451 64.4641 35.5358C65.5489 36.6206 65.5489 38.3793 64.4641 39.4641L53.9283 49.9999L64.4641 60.5358C65.5489 61.6206 65.5489 63.3793 64.4641 64.4641C63.3793 65.5489 61.6206 65.5489 60.5358 64.4641L49.9999 53.9283L39.4641 64.4641C38.3793 65.5489 36.6206 65.5489 35.5358 64.4641C34.451 63.3793 34.451 61.6206 35.5358 60.5358L46.0716 49.9999L35.5358 39.4641C34.451 38.3793 34.451 36.6206 35.5358 35.5358Z'
      fill='#F44336'
    />
  </svg>
);
