import { ButtonHTMLAttributes, forwardRef } from 'react';

import * as Ui from './IconButton.styles';

interface IconButtonrops extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonrops>(
  ({ children, ...rest }, ref) => (
    <Ui.IconButton ref={ref} {...rest}>
      {children}
    </Ui.IconButton>
  ),
);

IconButton.displayName = 'IconButton';
