import { InputHTMLAttributes, Ref, forwardRef } from 'react';

import { StyledInput } from './Input.styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Apply margin bottom in rems
   */
  mb?: number;
  /**
   * Apply styles for input with error
   */
  hasError?: boolean;
  /**
   * Field name
   */
  name?: string;
}

export const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const { mb, hasError, name, ...rest } = props;
  return (
    <StyledInput {...rest} data-cy={name} name={name} $mb={mb} $hasError={hasError} ref={ref} />
  );
});

Input.displayName = 'Input';
