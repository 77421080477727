import { useSelector } from 'react-redux';

import { AndroidStore, AppleStore } from '../../assets/icons';
import { useTranslations } from '../../hooks/useTranslations';
import { deviceInfoSelector } from '../../store/deviceInfo';
import { useEnv } from '../EnvProvider/EnvProvider';
import Logo from '../Logo/Logo';
import * as Ui from './NonSupportedScreen.styles';

export const NonSupportedScreen = ({
  showSpecialContent = true,
}: {
  showSpecialContent?: boolean;
}) => {
  const env = useEnv();
  const t = useTranslations();
  const { isAndroid, isIOS } = useSelector(deviceInfoSelector);
  const showBoth = !isAndroid && !isIOS;

  return (
    <Ui.Container>
      <div>
        <Ui.LogoWrapper>
          <Logo />
        </Ui.LogoWrapper>

        {showSpecialContent && <Ui.SpecialTitle>{t.nonSupportedScreen_title}</Ui.SpecialTitle>}
      </div>

      <div>
        <Ui.DownloadText>{t.nonSupportedScreen_title_2}</Ui.DownloadText>
        <Ui.Links>
          {(isIOS || showBoth) && (
            <Ui.LinkWraper>
              <a href={env.APPLE_STORE_APP_URL}>
                <AppleStore />
              </a>
            </Ui.LinkWraper>
          )}

          {(isAndroid || showBoth) && (
            <Ui.LinkWraper>
              <a href={env.GOOGLE_PLAY_STORE_APP_URL}>
                <AndroidStore />
              </a>
            </Ui.LinkWraper>
          )}
        </Ui.Links>
      </div>
    </Ui.Container>
  );
};
