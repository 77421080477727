import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '../../hooks/useTranslations';
import { AppDispatch } from '../../store/store';
import { zoneInfoLoad, zoneInfoStateSelector } from '../../store/zoneInfo';
import { LoadingErrorTryAgain } from '../LoadingErrorTryAgain/LoadingErrorTryAgain';
import * as ModalUi from '../Modals/SimpleModal/SimpleModal.styles';
import * as Ui from './ZipCodeModal.styles';
import { ZipCodeModalButtons } from './ZipCodeModalButtons';
import { ZipCodeModalUpdateForm } from './ZipCodeModalUpdateForm';

interface Props {
  loadingOrError: boolean;
}

export const ZipCodeModalContent = ({ loadingOrError }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { loadError } = useSelector(zoneInfoStateSelector);
  const t = useTranslations();
  const { currentLocation, currentZone } = useSelector(zoneInfoStateSelector);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const hasValidCurrentZone = !!currentZone;

  const handleShowForm = useCallback(() => {
    setShowUpdateForm(true);
  }, []);

  const handleHideForm = useCallback(() => {
    setShowUpdateForm(false);
  }, []);

  const tryAgain = useCallback(() => {
    dispatch(zoneInfoLoad());
  }, [dispatch]);

  if (loadingOrError || loadError) {
    return (
      <LoadingErrorTryAgain
        loading={!loadError}
        error={
          loadError && (
            <>
              {t.zipCodeModalLoading_error} {loadError}
            </>
          )
        }
        tryAgain={tryAgain}
      />
    );
  }

  return (
    <>
      <ModalUi.Title>{t.zipCodeModal_title}</ModalUi.Title>

      <Ui.ZipCodeInfo>
        {t.zipCodeModal_message_1}&nbsp;
        <span className='text-uppercase'>{currentLocation?.region}</span>
        {currentLocation?.zip && <>&nbsp;{currentLocation?.zip}</>}
        {!currentZone && <>{t.zipCodeModal_message_2}</>}
      </Ui.ZipCodeInfo>

      {!hasValidCurrentZone || showUpdateForm ? (
        <ZipCodeModalUpdateForm onCancelAction={handleHideForm} />
      ) : (
        <ZipCodeModalButtons onCancelAction={handleShowForm} />
      )}

      <Ui.Footer>
        <Ui.FooterText>{t.zipCodeModal_footer}</Ui.FooterText>
      </Ui.Footer>
    </>
  );
};
