import { useCallback } from 'react';

import { GetProductsResponseItem } from '../../api/authApi/authApi.types';
import { EventIdValues } from './useEvergentIframe';

export const useSendSubscriptionRequest = () => {
  return useCallback(
    ({
      purchaseToken,
      product,
      zoneKey,
      zipCode,
      iframe,
      total,
      couponCode,
    }: {
      purchaseToken?: string;
      product: GetProductsResponseItem;
      zoneKey: string;
      zipCode: string;
      iframe: Window;
      total?: number;
      couponCode?: string;
    }) => {
      const { skuORQuickCode, retailPrice, period: rawPeriod } = product;
      const periodParam = rawPeriod
        ? { period: rawPeriod.replaceAll('(s)', '').toLowerCase() }
        : undefined;
      const totalValue = typeof total !== 'undefined' ? total : retailPrice;
      const subscriptionRequestPayload = getSubscriptionRequestPayload({
        couponCode,
        purchaseToken,
        skuORQuickCode,
        total: totalValue,
        zipCode,
        zoneKey,
        ...periodParam,
      });
      iframe.postMessage(subscriptionRequestPayload, '*');
    },
    [],
  );
};

export default useSendSubscriptionRequest;

const getSubscriptionRequestPayload = ({
  zipCode,
  zoneKey,
  skuORQuickCode,
  purchaseToken,
  period,
  total,
  couponCode,
}: {
  zipCode: string;
  zoneKey: string;
  skuORQuickCode: string;
  period?: string;
  purchaseToken?: string;
  total: number;
  couponCode?: string;
}) => ({
  data: {
    SubscriptionRequest: {
      couponCode,
      packageId: [{ serviceId: skuORQuickCode }],
      paymentMethodID: '',
      period,
      purchaseToken,
      total: `${total}`,
      zip: zipCode,
      zone: zoneKey,
    },
  },
  // eslint-disable-next-line camelcase
  event_id: EventIdValues.SUBSCRIPTION_PACKAGE_REQUEST,
});
