import { createContext, useContext } from 'react';

import useEvergentIframe from './useEvergentIframe';

export const EvergentIframeContext = createContext(
  {} as {
    zipCode: string;
    total?: number;
    couponCode?: string;
  } & ReturnType<typeof useEvergentIframe>,
);

export const useEvergentIframeContext = () => useContext(EvergentIframeContext);

export default useEvergentIframeContext;
