import { PreloadedState, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import { getEnv } from '../env';
import { isDebugViewEnabled } from '../utils/iseDebugViewEnabled';
import rootReducer from './rootReducer';
import { RootState } from './types';

export const setupStore = (preloadedState?: PreloadedState<Partial<RootState>>) => {
  const env = getEnv();

  const debugViewEnabled = isDebugViewEnabled();

  return configureStore({
    devTools: debugViewEnabled,
    middleware: getDefaultMiddleware()
      .prepend(thunkMiddleware)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .concat(env.REDUX_LOG ? [logger] : []),
    preloadedState,
    reducer: rootReducer,
  });
};
export type AppStore = ReturnType<typeof setupStore>;
export const store = setupStore();
export type AppDispatch = typeof store.dispatch;
