import { find } from 'lodash-es';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { isAuthedSelector } from '../../../../store/auth';
import { NavigationItem, NavigationItems } from '../../Navigation.types';
import { isElementActive } from '../../helpers/isElementActive';
import { SidebarCollapsibleItem } from '../SidebarCollapsibleItem/SidebarCollapsibleItem';
import { SidebarItem } from '../SidebarItem/SidebarItem';
import * as Ui from './SidebarNav.styles';

interface SidebarNavProps {
  items: NavigationItems;
  isOpen: boolean;
  closeMobileNavigation: () => void;
}

export const SidebarNav = ({ items, isOpen, closeMobileNavigation }: SidebarNavProps) => {
  const isAuthed = useSelector(isAuthedSelector);

  const findActiveCollapsibleItemIndex = (items: NavigationItems): number => {
    const activeItem = find(items, (item) => item.options?.some(isElementActive));

    return activeItem ? items.indexOf(activeItem as NavigationItem) : -1;
  };

  const [subItemsIndexOpen, setSubItemsIndexOpen] = useState(findActiveCollapsibleItemIndex(items));

  const handleCollapsibleItemClick = (index: number) => {
    setSubItemsIndexOpen(index === subItemsIndexOpen ? -1 : index);
  };

  return (
    <Ui.Sidebar $isOpen={isOpen}>
      {items.map((item, index) => {
        if (item?.options?.length) {
          return (
            <SidebarCollapsibleItem
              item={item}
              key={item.label}
              isOpen={index === subItemsIndexOpen}
              onClick={() => handleCollapsibleItemClick(index)}
              closeMobileNavigation={closeMobileNavigation}
              disabled={!isAuthed}
            />
          );
        }
        return (
          <SidebarItem
            key={item.label}
            item={item}
            closeMobileNavigation={closeMobileNavigation}
            disabled={!isAuthed}
          />
        );
      })}
    </Ui.Sidebar>
  );
};
