import { SVGProps } from 'react';

import { colors } from '../../components/ThemeProvider/constants/colors';

export const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6799 8.94427C11.5266 9.13594 11.2469 9.16701 11.0552 9.01368L7.99955 6.56912L4.94386 9.01368C4.75219 9.16701 4.4725 9.13594 4.31916 8.94426C4.16583 8.75259 4.1969 8.47291 4.38857 8.31957L7.72191 5.6529C7.88423 5.52305 8.11487 5.52305 8.27719 5.6529L11.6105 8.31957C11.8022 8.47291 11.8333 8.75259 11.6799 8.94427Z'
      fill={props.fill || colors['pure-white']}
    />
  </svg>
);
