import { FailureResponse, GetEntitlementsResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getEntitlements = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  getEntitlementsUrl,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getEntitlementsUrl: string;
}): Promise<GetEntitlementsResponse> => {
  const data = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  const body = { GetEntitlementsRequestMessage: data };

  const response = await evergentAxiosInstance.post<{
    GetEntitlementsResponseMessage: GetEntitlementsResponse & FailureResponse;
  }>(getEntitlementsUrl, body, { headers });

  const { failureMessage, ...dataResponse } = response.data.GetEntitlementsResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
