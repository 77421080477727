import styled from 'styled-components';

import { TypographyProps, bolder, fonts, textBase } from '../Common';

export const Text = styled.p<TypographyProps>`
  ${(props) => textBase(props)};
  ${fonts.body2.xl}
`;

export const OrderedList = styled.ol`
  margin: 1rem 0;
`;

export const ListItem = styled.li<TypographyProps>`
  ${(props) => textBase(props)};
  ${fonts.body2.xl}
`;

export const Link = styled.a<TypographyProps>`
  ${(props) => textBase(props)};
  ${fonts.body2.xl}
  ${bolder}
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;
