import clsx from 'clsx';
import {
  Toast as ToastBootstrap,
  ToastContainer as ToastContainerBootstrap,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../store/store';
import { toastActions, toastSelector } from '../../store/toast';

export const Toast = () => {
  const dispatch: AppDispatch = useDispatch();
  const { show, type, position, message, delay, className } = useSelector(toastSelector);

  const handleOnCloseToast = () => dispatch(toastActions.hideToast());

  return (
    <ToastContainerBootstrap
      position={position}
      className='p-3 position-fixed'
      style={{ zIndex: 1056 }}
    >
      <ToastBootstrap
        delay={delay}
        bg={type}
        autohide
        show={show}
        onClose={handleOnCloseToast}
        className={clsx(className)}
      >
        <ToastBootstrap.Body>{message}</ToastBootstrap.Body>
      </ToastBootstrap>
    </ToastContainerBootstrap>
  );
};
