import { FLCastPlayer, FLLocalPlayer } from '..';

export const flpOnErrorAdapter = (
  flPlayer: FLLocalPlayer | FLCastPlayer,
  callback: (error: { errorMessage: string; hexErrorCode: string }) => void,
) => {
  const adaptedCallback = (error: { errorMessage: string; hexErrorCode: string }) => {
    callback(error);
  };
  flPlayer.subscribe('error', adaptedCallback);
  return () => {
    flPlayer.unsubscribe('error', adaptedCallback);
  };
};
