import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../Constants/routes';
import { useEnv } from '../components/EnvProvider/EnvProvider';
import { ignoreRegionValidationSelector } from '../store/auth';
import { zoneInfoStateSelector } from '../store/zoneInfo';
import { useExtendedNavigate } from './useExtendedNavigate';

export const useGeolocationError = (shouldRedirectToGeolocationErrorPage?: boolean) => {
  const env = useEnv();
  const navigate = useExtendedNavigate();

  const { loadError: error, loaded, loading, currentLocation } = useSelector(zoneInfoStateSelector);
  const shouldSkipRegionValidation = useSelector(ignoreRegionValidationSelector);

  // should discard location error if at least one it was fetched before
  const shouldDiscardLocationError = env.SHOULD_BLOCK_LOCATION_ERROR && !!currentLocation;

  const hasError = loaded && !shouldSkipRegionValidation && !shouldDiscardLocationError && error;

  useEffect(() => {
    if (shouldRedirectToGeolocationErrorPage && hasError) {
      navigate(ROUTES[RouteName.GeolocationError]);
    }
  }, [hasError]);

  return { error: hasError ? error : '', loaded, loading };
};
