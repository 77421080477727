import { matchPath, useLocation } from 'react-router';

import { ROUTES } from '../Constants/routes';

export const useGetCurrentPathPattern = () => {
  const location = useLocation();
  const paths = Object.values(ROUTES);

  const matchedRoute = paths.find((path) => matchPath(path, location.pathname));

  return matchedRoute || '';
};
