import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { useSimpleModalContext } from '../Modals/SimpleModal/SimpleModalContext';

export const useCheckTVEProviderResult = () => {
  const { openModal } = useSimpleModalContext();
  const navigate = useExtendedNavigate();
  const location = useLocation();
  const { status, errorCode, showModal, errorMessage, isTVERedirect } = (location.state || {}) as {
    isTVERedirect?: boolean;
    status?: string;
    errorCode?: string;
    errorMessage?: string;
    showModal?: boolean;
  };

  const t = useTranslations();

  const onCloseModal = useCallback(() => {
    navigate(location.pathname, {});
  }, [navigate, location]);

  useEffect(() => {
    if (!isTVERedirect || !showModal) {
      return;
    }

    if (status === 'success') {
      openModal({
        cancelButton: {
          onClick: onCloseModal,
          text: t.global_close,
        },
        description: t.checkTVEProviderResult_successModalDescription,
        modalName: 'TVE provider connection success',
        title: t.checkTVEProviderResult_successModalTitle,
      });
    }

    if (status === 'fail') {
      openModal({
        acceptButton: {
          onClick: onCloseModal,
          text: t.global_close,
        },
        description: errorCode ? `${errorMessage} (${errorCode})` : errorMessage,
        modalName: 'TVE provider connection error',
        title: t.checkTVEProviderResult_errorModal_title,
      });
    }
  }, [status, errorCode, errorMessage, onCloseModal, isTVERedirect]);
};
