import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../Constants/routes';
import { Video, VideoType } from '../api';
import { GetProductsResponseItem, ProductServiceTypeEnum } from '../api/authApi/authApi.types';
import { MigrationTrigger } from '../api/remoteConfigApi/remoteConfigApi.types';
import { SUBSCRIPTION_PURCHASE_ITEM, SubscriptionModalBody } from '../components/Modals';
import { useMigrationModal } from '../components/Modals/MigratiionModal/hooks/useMigrationModal';
import { ModalState, OpenModalMethod, useModalContext } from '../components/Modals/context';
import { SelectPaymentModal } from '../components/PurchaseModal/SelectPaymentModal/SelectPaymentModal';
import { isPayPerViewAvailable } from '../components/SubscriptionPlans/helpers/isPayPerViewAvailable';
import { activeSubscriptionsSelector } from '../store/activeSubscriptions';
import { entitlementsSelector } from '../store/entitlements';
import { productsStateSelector } from '../store/products/products.selectors';
import { selectProduct } from '../store/products/products.thunks';
import { AppDispatch } from '../store/store';
import { videosActions } from '../store/videos';
import { useDetectCollectionPromo } from './promotions/promotion-page/useCollectionPromoDetect';
import { useExtendedNavigate } from './useExtendedNavigate';

export type CheckSubscriptionParamsType = {
  replaceRoute: boolean;
  onSuccess?: (product: GetProductsResponseItem) => void;
  shouldTriggerGoBackOnClick?: boolean;
  shouldBlockMigrationModal?: boolean;
  zonePpgPromo?: boolean;
  paymentOnGoBack?: () => void;
};

// classes from src/components/GlobalStyle/GlobalStyle.tsx
const modalProps = {
  className: clsx('bg_blur_web', 'subscriptionModal'),
  contentClassName: 'subscriptionModalContent',
  dialogClassName: 'subscriptionModalDialog',
  fullscreen: true,
};

export const openSubscriptionModal = ({
  openModal,
  content,
}: {
  openModal: OpenModalMethod;
  content: ModalState['content'];
}) => {
  openModal(content, modalProps);
};

export const useSubscriptionNavigate = (isEntryPage = false) => {
  const collectionPromo = useDetectCollectionPromo();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useExtendedNavigate();
  const { openModal } = useModalContext();
  const { ppgItemsIds } = useSelector(entitlementsSelector);
  const products = useSelector(productsStateSelector);
  const { hasActiveSubscriptions } = useSelector(activeSubscriptionsSelector);
  const currentLocation = useLocation();
  const redirectBackUrl = currentLocation.pathname;
  const { openMigrationModal, migration } = useMigrationModal(MigrationTrigger.blockPurchase);
  const ppg = useMemo(
    () =>
      products.items.filter((product) => product.serviceType === ProductServiceTypeEnum.TVOD)[0],
    [products],
  );

  const goBackAction = useCallback(() => {
    return isEntryPage ? navigate(ROUTES[RouteName.Home]) : history.back();
  }, [isEntryPage, navigate]);

  useEffect(() => {
    if (!currentLocation.pathname.includes(RouteName.Schedule)) {
      sessionStorage.removeItem(SUBSCRIPTION_PURCHASE_ITEM);
    }
  }, [currentLocation]);

  const checkSubscription = useCallback(
    (
      asset: Video | null,
      {
        replaceRoute,
        onSuccess,
        shouldBlockMigrationModal,
        shouldTriggerGoBackOnClick,
        zonePpgPromo,
        paymentOnGoBack,
      }: CheckSubscriptionParamsType = {
        replaceRoute: true,
        shouldTriggerGoBackOnClick: false,
      },
    ) => {
      sessionStorage.setItem(SUBSCRIPTION_PURCHASE_ITEM, JSON.stringify(asset));

      return new Promise<boolean>((resolve) => {
        if (
          hasActiveSubscriptions ||
          ppgItemsIds[asset?.externalId as string] ||
          asset?.videoType === VideoType.COLLECTION ||
          asset?.isFree
        ) {
          return resolve(true);
        }

        dispatch(videosActions.purchaseVideo(asset));
        const isPPGPurchasable = isPayPerViewAvailable(asset);

        if (zonePpgPromo && !ppg) {
          // Unfortunately, ppg is not loaded when this method is called,
          // so for that call, we're returning false.
          // The component can call this method when PPG is loaded,
          // and it will know that thanks to a redux selector.
          return resolve(false);
        }

        if (migration?.isEnabled) {
          if (!shouldBlockMigrationModal) {
            return openMigrationModal();
          }
          return;
        }

        if ((zonePpgPromo || collectionPromo) && isPPGPurchasable && ppg) {
          dispatch(selectProduct(ppg));

          openSubscriptionModal({
            content: (
              <SelectPaymentModal
                product={ppg}
                onSuccess={(product: GetProductsResponseItem) => {
                  onSuccess && onSuccess(product);
                }}
                onSkip={goBackAction}
                passedOnGoBack={goBackAction}
                applyCoupon={zonePpgPromo}
                onGoBack={paymentOnGoBack}
                hidePurchaseZipCodeChange
              />
            ),
            openModal,
          });

          return resolve(!!zonePpgPromo);
        }

        if (replaceRoute) {
          navigate(ROUTES[RouteName.SelectSubscription]);
        }

        openSubscriptionModal({
          content: (
            <SubscriptionModalBody
              redirectBackUrl={redirectBackUrl}
              onSuccess={(product: GetProductsResponseItem) => {
                sessionStorage.removeItem(SUBSCRIPTION_PURCHASE_ITEM);
                onSuccess && onSuccess(product);
              }}
              onSkip={() => {
                dispatch(videosActions.purchaseClosed());
                resolve(false);
                if (replaceRoute) {
                  goBackAction();
                }
              }}
              onGoBack={() => {
                if (!replaceRoute && shouldTriggerGoBackOnClick) {
                  goBackAction();
                }
              }}
            />
          ),
          openModal,
        });
      });
    },
    [
      dispatch,
      goBackAction,
      hasActiveSubscriptions,
      migration,
      navigate,
      openModal,
      ppgItemsIds,
      redirectBackUrl,
      ppg,
    ],
  );

  return { checkSubscription };
};
