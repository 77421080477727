import { Dropdown } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { transparentButton } from '../../../../utils/styled/mixins';
import { FONT_WEIGHT } from '../../../Common';
import { navigationItemStyles } from '../NavigationItem/NavigationItem.styles';

const SUBMENU_WIDTH = 11;

export const Wrapper = styled(Dropdown)`
  .dropdown-menu {
    border-radius: 4px;
    padding: 1rem 1rem 0.5rem 1rem;
    transform: translateY(0.5rem);
    width: ${SUBMENU_WIDTH}rem;
    left: calc(calc(100% - ${SUBMENU_WIDTH}rem) / 2);
    top: 2.312rem;
    background-color: ${({ theme }) => theme.colors['grey-dark']};
  }

  .nav-item {
    margin-right: 0;
  }

  .dropdown-toggle {
    &:disabled {
      cursor: auto;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const DropdownToggleWrapper = styled.span`
  .dropdown-toggle {
    ${navigationItemStyles}
    cursor: pointer;
    &::after {
      display: none;
    }

    &:hover:enabled {
      font-weight: ${FONT_WEIGHT.Bold};
    }

    &.active {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary} !important;
      font-weight: ${FONT_WEIGHT.Bold};
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(0deg);
      }
    }

    &[aria-expanded='false'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const IconWrapper = styled.span<{ $disabled?: boolean }>`
  margin-left: 1rem;
  svg {
    transition: transform 0.3s ease-in-out;
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.colors.grey};
    `}
`;

export const Item = styled(Dropdown.Item)<{ $isActive: boolean }>`
  ${transparentButton}
  margin-bottom: 0.5rem;
  text-align: left;
  color: ${({ theme }) => theme.colors['off-white']};
  font-family: 'GothamNarrow';
  font-size: 1rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.25;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.75rem 0;
  display: block;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: inherit;
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      border-bottom: 1px solid ${theme.colors['scrim-white']};
      color: ${theme.colors.primary};
    `}
`;
