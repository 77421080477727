import { ReactNode } from 'react';

import { InfoErrorLarge } from '../../assets/icons/InfoErrorLarge';
import { useTranslations } from '../../hooks/useTranslations';
import { ButtonSize } from '../Button/Button.types';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { Loader } from '../Loader/Loader';
import { colors } from '../ThemeProvider/constants/colors';
import * as Ui from './LoadingErrorTryAgain.styles';

type LoadingErrorTryAgainProps = {
  error?: ReactNode | null;
  tryAgain?: () => void;
  loading?: boolean;
  tryAgainText?: string;
  className?: string;
  fullPage?: boolean;
};

export const LoadingErrorTryAgain = ({
  loading = true,
  tryAgain,
  error,
  className,
  fullPage,
}: LoadingErrorTryAgainProps) => {
  const t = useTranslations();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Ui.Container className={className} $fullPage={fullPage}>
        <Ui.IconWrapper>
          <InfoErrorLarge fill={colors.primary} />
        </Ui.IconWrapper>
        <Ui.ErrorMessage>{t.loadingErrorTryAgain_message}</Ui.ErrorMessage>
        <Ui.ButtonWrapper>
          <ClickEventDispatcher
            location='Error: Fetching content'
            text={`CTA: ${t.loadingErrorTryAgain_retry}`}
          >
            <Ui.Button size={ButtonSize.Large} className='w-100' onPress={tryAgain}>
              {t.loadingErrorTryAgain_retry}
            </Ui.Button>
          </ClickEventDispatcher>
        </Ui.ButtonWrapper>
      </Ui.Container>
    );
  }

  return null;
};
