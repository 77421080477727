import { FC } from 'react';
import { useSelector } from 'react-redux';

import { PlayerState } from '../../../api';
import { PauseBig } from '../../../assets/icons/PauseBig';
import { PlayBig } from '../../../assets/icons/PlayBig';
import { playerStateSelector } from '../../../store/player';
import * as Ui from './PlayPauseAnimation.styles';

interface PlayPauseAnimationProps {
  animationVisible: boolean;
}

export const PlayPauseAnimation: FC<PlayPauseAnimationProps> = ({ animationVisible }) => {
  const playerState = useSelector(playerStateSelector);

  if (!animationVisible) return null;

  return (
    <Ui.Wrapper>
      <Ui.IconWrapper>
        {playerState === PlayerState.PAUSED ? <PauseBig /> : <PlayBig />}
      </Ui.IconWrapper>
      <Ui.Circle />
    </Ui.Wrapper>
  );
};
