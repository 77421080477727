import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { flexCenter } from '../../utils/styled/mixins';
import { Container, GUTTER_X_XL } from '../Common';
import { ZipCodeForm as Form } from '../ZipCodeForm/ZipCodeForm';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.zipCodeTopBar};
  background-color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors['grey']};
`;

export const ContentContainer = styled(Container)`
  ${flexCenter}
  color: ${({ theme }) => theme.colors['off-white']};
  overflow: hidden;
  flex: 1;
`;

export const ZipCodeForm = styled(Form)`
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
`;

export const CloseButtonWrapper = styled.div`
  ${flexCenter}
  position: absolute;
  top: 1rem;
  right: 0;

  ${breakpoint('lg')` 
    position: relative;
    top: initial;
    right: initial;
    margin-left: ${GUTTER_X_XL};
  `};
`;
