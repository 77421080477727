import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { LogoSize } from './Logo';

export const Logo = styled.img<{ size?: LogoSize }>`
  ${({ size }) =>
    size === 'responsive' &&
    `
    ${breakpoint('ultraHD')`
    height: 26.813rem;
    width: auto;
    `}

    ${breakpoint('fullHD')`
    height: 16.3rem;
    width: auto;
    `}

    ${breakpoint('xxl')`
    height: 10.44rem;
    width: auto;
    `}

    ${breakpoint('xl')`
    height: 10rem;
    width: auto;
    `}

    ${breakpoint('xs')`
    height: 8.688rem;
    width: auto;
    `}
`}

  ${({ size }) =>
    size === 'large' &&
    `
  width: calc(8.5vw + 17vh);
  height: auto;

  @media screen and (min-width: 992px) {
    width: 15.74vw;
  }
`}

${({ size }) =>
    size === 'small' &&
    `
      width: auto;
      height: 11rem;
 

      @media screen and (min-width: 992px) {
        width: 12.125rem;
        height: auto;
      }
    `}

${({ size }) =>
    size === 'medium' &&
    `
      width: calc(8.5vw + 17vh);
      height: auto;
 

      @media screen and (min-width: 992px) {
        height: 16.25rem;
        width: auto;
      }
    `}
`;
