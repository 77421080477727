/* eslint-disable sort-keys-fix/sort-keys-fix */
export const zIndex = {
  hidden: -1,
  content: 1,
  player: 2,
  playerControls: 3,
  playerButtons: 4,
  footer: 5,
  replayButton: 5,
  navigationSidebar: 5,
  navigation: 6,
  teamsSelector: 20,
  zipCodeTopBar: 30,
  termsAndConditions: 1031,
  bootstrapModal: 1050,
  simpleModal: 1051,
} as const;
