import { createContentAuthorizer } from '@msgn/fl-module/fl-content-authorization';
import { PlatformClient } from '@msgn/fl-module/fl-platform-authorizer';

import { ContentAuthorizer, PlatformAuthorizer } from './playerApi.types';

export const getContentAuthorizer = ({
  platformAuthorizer,
  platformClient,
  clientRegisterationEndpoint,
  contentAuthEndpoint,
}: {
  platformAuthorizer: PlatformAuthorizer;
  platformClient: PlatformClient;
  clientRegisterationEndpoint: string;
  contentAuthEndpoint: string;
}): ContentAuthorizer => {
  const contentAuthConfiguration = {
    clientRegistrationEndPointUrl: clientRegisterationEndpoint,
    endPointUrl: contentAuthEndpoint,
  };
  return createContentAuthorizer(contentAuthConfiguration, platformAuthorizer, platformClient);
};
