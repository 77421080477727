import { Log } from '../../helpers/CustomLogger';
import { COMPANION_ADS_SIZES, IMPRESSIONS_STORAGE_KEY } from './CompanionAds.constants';
import { AdImage, AdSizeName, Companion, CompanionAds, TrackingEvent } from './CompanionAds.types';

function getCompanionWithSmallestHeight(companions: Companion[] = []) {
  let smallestHeight = Infinity;
  let smallestHeightCompanion = null;

  companions.forEach((companion) => {
    const height = parseInt(companion.attributes.height);
    if (!isNaN(height) && height < smallestHeight) {
      smallestHeight = height;
      smallestHeightCompanion = companion;
    }
  });

  return smallestHeightCompanion;
}

export const convertCompanionAdsToArray = (
  companionAds: CompanionAds | CompanionAds[],
): CompanionAds[] => {
  return !Array.isArray(companionAds) ? [companionAds] : companionAds;
};

const getCompanion = (companionSize: AdSizeName, companions: Companion[] = []) => {
  const expectedSizeCompanion = companions.filter(
    ({ attributes: { height, width } }) =>
      (parseInt(height) === COMPANION_ADS_SIZES[companionSize].height &&
        parseInt(width) === COMPANION_ADS_SIZES[companionSize].width) ||
      (
        COMPANION_ADS_SIZES[companionSize].height / COMPANION_ADS_SIZES[companionSize].width
      ).toFixed(2) === (parseInt(height) / parseInt(width)).toFixed(2),
  )[0];

  return expectedSizeCompanion || getCompanionWithSmallestHeight(companions);
};

export const getAdImageData = (
  companions: Companion[] = [],
  companionAdImageSize: AdSizeName,
): AdImage | undefined => {
  Log.log('getAdImageData', companions, companionAdImageSize);
  if (!companions || !companions?.length) {
    Log.log('getAdImageData return undefined');
    return undefined;
  }
  let companionImage;
  let trackingEvents: TrackingEvent[] = [];

  if (companions?.length) {
    companionImage = getCompanion(companionAdImageSize, companions);
  }

  const imageElement = new Image();

  if (companionImage?.staticResource) {
    Log.log('add static resource to image element', companionImage?.staticResource);
    imageElement.src = companionImage?.staticResource;
    trackingEvents = companionImage.trackingEvents;
  }

  return {
    image: imageElement,
    imageRedirectUrl: companionImage?.companionClickThrough,
    trackingEvents,
  };
};

export const readImpressions = () => {
  const impressions = window.sessionStorage.getItem(IMPRESSIONS_STORAGE_KEY);
  return impressions ? JSON.parse(impressions) : {};
};

export const hasAdBeenImpressed = (adId: string): boolean => {
  const impressions = readImpressions();
  return impressions ? impressions[adId] : false;
};

export const saveAdImpression = (adId: string) => {
  window.sessionStorage.setItem(
    IMPRESSIONS_STORAGE_KEY,
    JSON.stringify({
      ...readImpressions(),
      [adId]: true,
    }),
  );
};

export const clearAllAdImpressions = () => {
  window.sessionStorage.removeItem(IMPRESSIONS_STORAGE_KEY);
};
