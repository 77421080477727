import { useCallback, useState } from 'react';

const usePasswordToggle = (): [boolean, boolean, () => void, boolean, boolean, () => void] => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordVisibleConfirmPassword, setPasswordVisibleConfirmPassword] =
    useState<boolean>(false);
  // if true, eyeOn is displayed
  const [eyeToggle, setEyeToggle] = useState<boolean>(false);
  const [eyeToggleConfirmPassword, setEyeToggleConfirmPassword] = useState<boolean>(false);

  const toggleEye = useCallback(() => {
    setPasswordVisible(!passwordVisible);
    setEyeToggle(!eyeToggle);
  }, [passwordVisible, eyeToggle]);

  const toggleEyeConfirmPassword = useCallback(() => {
    setPasswordVisibleConfirmPassword(!passwordVisibleConfirmPassword);
    setEyeToggleConfirmPassword(!eyeToggleConfirmPassword);
  }, [passwordVisibleConfirmPassword, eyeToggleConfirmPassword]);

  return [
    eyeToggle,
    passwordVisible,
    toggleEye,
    eyeToggleConfirmPassword,
    passwordVisibleConfirmPassword,
    toggleEyeConfirmPassword,
  ];
};

export default usePasswordToggle;
