import { forwardRef } from 'react';

import { EyeOff, EyeOn } from '../../assets/icons';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import { InputProps } from '../atoms';
import * as Ui from './PasswordInput.styles';

export const PasswordInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [eyeToggle, passwordVisible, toggleEye] = usePasswordToggle();
  const { name, ...rest } = props;
  return (
    <Ui.InputGroup>
      <Ui.StyledPasswordInput
        {...rest}
        data-cy={name}
        name={name}
        ref={ref}
        type={passwordVisible ? 'text' : 'password'}
      />
      <Ui.EyeWrapper onClick={toggleEye}>{eyeToggle ? <EyeOff /> : <EyeOn />}</Ui.EyeWrapper>
    </Ui.InputGroup>
  );
});

PasswordInput.displayName = 'PasswordInput';
