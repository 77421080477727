import {
  GetEntitlementsResponse,
  ProductServiceStatusEnum,
} from '../../../api/authApi/authApi.types';
import { PpgItemsIds } from '../entitlements.types';

export const entitlementsResponseResponseToState = (
  activeSubscriptionsResponse: GetEntitlementsResponse | null,
) => {
  const accountServices = activeSubscriptionsResponse?.AccountServiceMessage || [];
  const subscriptions = accountServices
    .filter((accountService) => !accountService.isContent)
    .sort((as1, acs2) => as1.validityTill - acs2.validityTill);
  const tvodAccountServices = accountServices.filter((accountService) => accountService.isContent);
  const ppgItemsIds = tvodAccountServices
    .flatMap(({ vodItems }) => vodItems)
    .reduce((acc, vodItem) => ({ ...acc, [vodItem.assetId]: true }), {} as PpgItemsIds);
  const lastSubscription =
    subscriptions.find(
      ({ status }) =>
        [ProductServiceStatusEnum.ACTIVE, ProductServiceStatusEnum.ACTIVE_CANCELLED].indexOf(
          status,
        ) !== -1,
    ) || null;
  const canCancelSubscription = lastSubscription?.status === ProductServiceStatusEnum.ACTIVE;
  const hasActiveSubscriptions = !!lastSubscription;

  return {
    canCancelSubscription,
    hasActiveSubscriptions,
    lastSubscription,
    ppgItemsIds,
    subscriptions,
    tvodAccountServices,
  };
};
