import { useEffect } from 'react';

import { MigrationTrigger } from '../../api/remoteConfigApi/remoteConfigApi.types';
import { useMigrationModal } from '../../components/Modals/MigratiionModal/hooks/useMigrationModal';

export const MigrationPage = () => {
  const { openMigrationModal } = useMigrationModal(MigrationTrigger.appStart);

  useEffect(() => {
    openMigrationModal();
  });

  return <></>;
};
