import styled from 'styled-components';

export const TrackName = styled.div<{ selected: boolean }>`
  font-size: 1.5vh;
  padding-left: 1.5vh;
  padding-right: 1.5vh;
  text-transform: uppercase;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `

  padding-left: 0;
  &::before {
    content: ' ';
    display: inline-block;
    width: 1vh;
    height: 1vh;
    background-color: white;
    margin-right: 0.5vh;
    border-radius: 100%;
  }
  
  `}
`;

export const TrackTypeName = styled.div`
  font-size: 2vh;
`;
