import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useEnv } from '../components/EnvProvider/EnvProvider';
import { AvailablePaymentOptions } from '../components/PaymentOptions/constants';
import { purchaseActions } from '../store/purchase';
import { useLoadSquareSdk } from './useLoadSquareSdk';

export const APPLE_PAY_MERCHANT_ID = 'merchant.com.MSGI.msg2go';

export const useGetPaymentOptions = () => {
  const dispatch = useDispatch();
  const { availablePaymentOptions, SQUARE_SDK_APP_ID, SQUARE_SDK_LOCATION_ID } = useEnv();

  const squareSdkLoaded = useLoadSquareSdk();

  useEffect(() => {
    if (squareSdkLoaded) {
      if (
        availablePaymentOptions.includes(AvailablePaymentOptions.APPLE_PAY) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Square &&
        window?.ApplePaySession
      ) {
        const paymentAvailabilityPromise =
          window.ApplePaySession.canMakePaymentsWithActiveCard(APPLE_PAY_MERCHANT_ID);

        paymentAvailabilityPromise
          .then((canMakePayments: boolean) => {
            if (canMakePayments) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const payments = (window as any).Square.payments(
                SQUARE_SDK_APP_ID,
                SQUARE_SDK_LOCATION_ID,
              );
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (window as any).squarePayments = payments;
              dispatch(purchaseActions.setPaymentOptions(availablePaymentOptions));
            } else {
              dispatch(purchaseActions.setPaymentOptions)(
                availablePaymentOptions.filter(
                  (option) => option !== AvailablePaymentOptions.APPLE_PAY,
                ),
              );
            }
          })
          .catch(() => {
            dispatch(
              purchaseActions.setPaymentOptions(
                availablePaymentOptions.filter(
                  (option) => option !== AvailablePaymentOptions.APPLE_PAY,
                ),
              ),
            );
          });
      } else {
        dispatch(
          purchaseActions.setPaymentOptions(
            availablePaymentOptions.filter(
              (option) => option !== AvailablePaymentOptions.APPLE_PAY,
            ),
          ),
        );
      }
    }
  }, [squareSdkLoaded]);
};
