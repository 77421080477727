import React, { ReactElement, ReactNode, useMemo } from 'react';

import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { getScreenName } from '../../utils/getScreenName';

type ClickEventDispatcherProps = {
  children: ReactNode;
  destination?: string;
  location?: string;
  screen?: string;
  text: string;
};

export const ClickEventDispatcher = ({ children, screen, ...props }: ClickEventDispatcherProps) => {
  const defaultScreenName = useMemo(() => {
    return getScreenName();
  }, []);

  const buttonClickedProps = {
    path: window.location.pathname,
    screen: screen || defaultScreenName,
    type: 'Button',
    url: window.location.href,
    ...props,
  };

  return (
    <>
      {React.Children.map(children as unknown as React.ReactElement, (childNode: ReactElement) => {
        if (childNode.props?.onPress) {
          return React.cloneElement(childNode, {
            onPress: (e: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>) => {
              buttonClicked(buttonClickedProps);
              childNode.props.onPress(e);
            },
          });
        }

        if (childNode.props?.onClick) {
          return React.cloneElement(childNode, {
            onClick: (e: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>) => {
              buttonClicked(buttonClickedProps);
              childNode.props.onClick(e);
            },
          });
        }

        return childNode;
      })}
    </>
  );
};
