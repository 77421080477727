import { AddOrRemoveFavoriteResponse, FailureResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const addOrRemoveFavorites = async ({
  accessToken,
  favoriteTeamsToRemove,
  favoriteTeamsToAdd,
  evergentApiKey,
  evergentChannelPartnerId,
  addOrRemoveFavoriteUrl,
}: {
  accessToken: string;
  favoriteTeamsToRemove: string[];
  favoriteTeamsToAdd: string[];
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  addOrRemoveFavoriteUrl: string;
}): Promise<AddOrRemoveFavoriteResponse> => {
  const data = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    favoriteTeamsToAdd: favoriteTeamsToAdd,
    favoriteTeamsToRemove: favoriteTeamsToRemove,
    favouriteType: 'favoriteTeams',
  };

  const body = { AddOrRemoveFavouritesRequestMessage: data };

  const response = await evergentAxiosInstance.post<{
    AddOrRemoveFavouritesResponseMessage: AddOrRemoveFavoriteResponse & FailureResponse;
  }>(addOrRemoveFavoriteUrl, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = response.data.AddOrRemoveFavouritesResponseMessage;
  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
