import { FLCastPlayer, FLLocalPlayer } from '..';

export const flpOnBuufferingStateChange = (
  flPlayer: FLLocalPlayer | FLCastPlayer,
  callback: (bufferingState: string) => void,
) => {
  const adaptedCallback = (bufferingState: string) => {
    callback(bufferingState);
  };
  flPlayer.subscribe('bufferingstatechanged', adaptedCallback);
  return () => {
    flPlayer.unsubscribe('bufferingstatechanged', adaptedCallback);
  };
};
