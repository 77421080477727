import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectedVideoSelector } from '../../../store/videos';
import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import * as PlayerUi from '../PlayerUi.styles';
import * as Ui from './ChromeCastButton.styles';

export const ChromecastButton = () => {
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const selectedVideo = useSelector(selectedVideoSelector);
  const { isCastDevicesAvailable, castDeviceName } = usePlayerControlsContext();

  useEffect(() => {
    if (!isCastDevicesAvailable) return;

    const googleCastLauncher = document.createElement('google-cast-launcher');
    const buttonWrapperElement = buttonWrapperRef.current as HTMLDivElement;

    if (buttonWrapperElement) {
      buttonWrapperElement.appendChild(googleCastLauncher);
      return () => {
        buttonWrapperElement.removeChild(googleCastLauncher);
      };
    }
  }, [isCastDevicesAvailable]);

  if (!isCastDevicesAvailable || !selectedVideo) {
    return null;
  }
  return (
    <>
      {isCastDevicesAvailable && (
        <>
          <PlayerUi.PlayerButton aria-label='cast tv'>
            <Ui.ButtonWrapper ref={buttonWrapperRef}></Ui.ButtonWrapper>
          </PlayerUi.PlayerButton>
          {castDeviceName && (
            <div className='d-flex flex-row aling-items-center justify-content-center'>
              <Ui.DeviceName>{castDeviceName}</Ui.DeviceName>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ChromecastButton;
