import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { isErrorWithCodeInCause } from '../../api/authApi/EvergentAuthApi.types';
import {
  AddSubscriptionsResponseMessage,
  AddTVODOrderResponseMessage,
} from '../../api/authApi/authApi.types';
import { AvailablePaymentOptions } from '../../components/PaymentOptions';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import {
  createApplePayPayload,
  createApplePayTVODPayload,
  createPayPalPayload,
  createPayPalTVODPayload,
} from './purchase.services';
import {
  ApplePayPayload,
  ApplePayTVODPayload,
  PayPalPayload,
  PayPalTVODPayload,
} from './purchase.types';

export const addSubscriptionsAction = createAction<AddSubscriptionsResponseMessage>(
  'purchase/addSubscriptionsAction',
);

export const addTVODOrderAction = createAction<AddTVODOrderResponseMessage>(
  'purchase/addTVODOrderAction',
);

export const getPurchaseItemAction = createAction<AddTVODOrderResponseMessage>(
  'purchase/getPurchaseItem',
);

export const applyVoucherCodeAction = createAction<AddTVODOrderResponseMessage>(
  'purchase/applyVoucherCode',
);

export const getPurchaseItem = createAsyncThunk(
  getPurchaseItemAction.type,
  async (serviceId: string, thunkAPI) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, GET_PRORATE_URL },
        },
        zoneInfo,
      } = thunkAPI.getState() as RootState;

      const response = await evergentAuthApi.getProrate({
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getProrateUrl: GET_PRORATE_URL,
        serviceId,
        zipCode: zoneInfo.zipCode as string,
      });

      return response;
    } catch (error: unknown) {
      // Log.log('mar :  ~ error:', error);
    }
  },
);

export const applyVoucherCode = createAsyncThunk(
  applyVoucherCodeAction.type,
  async (payload: { voucherCode: string; serviceId: string }, thunkAPI) => {
    try {
      const { zoneInfo } = thunkAPI.getState() as RootState;

      const {
        appConfig: {
          env: {
            EVERGENT_API_KEY,
            EVERGENT_CHANNEL_PARTNER_ID,
            GET_VOUCHER_CODE_URL,
            GET_PRORATE_URL,
          },
        },
      } = thunkAPI.getState() as RootState;

      const { couponCodeDetails } = await evergentAuthApi.getVoucherCode({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getVoucherCodeUrl: GET_VOUCHER_CODE_URL,
      });

      const response = await evergentAuthApi.getProrate({
        couponCode: couponCodeDetails.couponCode,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getProrateUrl: GET_PRORATE_URL,
        serviceId: payload.serviceId,
        zipCode: zoneInfo.zipCode as string,
      });

      return {
        ...response,
        voucherCode: couponCodeDetails.couponCode,
      };
    } catch (error: unknown) {
      if (isErrorWithCodeInCause(error)) {
        return thunkAPI.rejectWithValue({
          code: error.cause.errorCode,
          message: error.message,
        });
      } else {
        return thunkAPI.rejectWithValue(undefined);
      }
    }
  },
);

export const addSubscriptions = createAsyncThunk(
  addSubscriptionsAction.type,
  async (payload: PayPalPayload | ApplePayPayload, thunkAPI) => {
    try {
      if (payload.paymentMethod === AvailablePaymentOptions.PAYPAL) {
        return await evergentAuthApi.addSubscriptions(
          createPayPalPayload(payload as PayPalPayload),
        );
      }

      if (payload.paymentMethod === AvailablePaymentOptions.APPLE_PAY) {
        return await evergentAuthApi.addSubscriptions(
          createApplePayPayload(payload as ApplePayPayload),
        );
      }
    } catch (error: unknown) {
      Log.error(error);
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  },
);

export const addTVODOrder = createAsyncThunk(
  addTVODOrderAction.type,
  async (payload: PayPalTVODPayload | ApplePayTVODPayload, thunkAPI) => {
    try {
      if (payload.paymentMethod === AvailablePaymentOptions.PAYPAL) {
        return await evergentAuthApi.addTVODOrder(
          createPayPalTVODPayload(payload as PayPalTVODPayload),
        );
      } else if (payload.paymentMethod === AvailablePaymentOptions.APPLE_PAY) {
        return await evergentAuthApi.addTVODOrder(
          createApplePayTVODPayload(payload as ApplePayTVODPayload),
        );
      }
    } catch (error: unknown) {
      Log.error(error);
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  },
);
