import { ErrorCode, ErrorCodeValue } from '../../Constants/ErrorCodesTVEMap';
import configManager from '../../services/config/ConfigManager';

export const URLS_EXCLUDED_FROM_REFRESH_TOKEN = [
  configManager.env.SIGN_UP_URL,
  configManager.env.LOGIN_URL,
  configManager.env.REFRESH_TOKEN_URL,
];
export const REFRESH_TOKEN_ERROR_CODES_FOR_LOGOUT_USER: ErrorCodeValue[] = [
  ErrorCode['20003'],
  ErrorCode.eV2767,
];
export const REFRESH_TOKEN_ERROR_CODES: ErrorCodeValue[] = [
  ErrorCode.eV2124,
  ErrorCode['111111111'],
];

export const NUMBER_OF_DAYS_TO_PAUSE_SUBSCRIPTION = '90';
