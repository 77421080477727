import { useSelector } from 'react-redux';

import { RootState } from '../store/types';

export const useTranslations = () => {
  return useSelector((state: RootState) => state.translations.data);
};

export const useIsLoadingTranslations = () => {
  return useSelector((state: RootState) => state.translations.isLoading);
};
