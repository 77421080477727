import { ALL } from '../Constants/TeamSelector';
import { TeamData } from '../api/cmsApi/quickplayCmsApi';
import { ITranslationData } from '../store/translations';

export const getAllTeamItemData = (t: ITranslationData): TeamData => {
  return {
    externalId: ALL,
    id: ALL,
    imagesSizes: [],
    teamDescription: t.teams_allTeams,
    teamId: ALL,
    teamName: t.teams_allTeams,
    teamShortName: t.teams_allTeams,
  };
};
