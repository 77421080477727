import { SVGProps } from 'react';

import { colors } from '../../components/ThemeProvider/constants/colors';

export const Rewind = (props: SVGProps<SVGSVGElement>) => (
  <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.5405 5.83224C22.7386 5.2749 19.8343 5.56095 17.1949 6.65421C14.5555 7.74748 12.2996 9.59886 10.7124 11.9742C9.12525 14.3496 8.2781 17.1423 8.2781 19.9991C8.2781 20.6128 8.77556 21.1102 9.38921 21.1102C10.0029 21.1102 10.5003 20.6128 10.5003 19.9991C10.5003 17.5818 11.2171 15.2188 12.5601 13.2088C13.9031 11.1989 15.812 9.63235 18.0453 8.70728C20.2786 7.78221 22.7361 7.54017 25.107 8.01176C27.4779 8.48336 29.6556 9.64741 31.365 11.3567C33.0743 13.066 34.2383 15.2438 34.7099 17.6147C35.1815 19.9856 34.9395 22.4431 34.0144 24.6764C33.0893 26.9097 31.5228 28.8185 29.5128 30.1615C27.5029 31.5045 25.1399 32.2214 22.7225 32.2214C22.1089 32.2214 21.6114 32.7188 21.6114 33.3325C21.6114 33.9461 22.1089 34.4436 22.7225 34.4436C25.5794 34.4436 28.3721 33.5964 30.7474 32.0093C33.1228 30.4221 34.9742 28.1662 36.0675 25.5268C37.1607 22.8874 37.4468 19.9831 36.8894 17.1812C36.3321 14.3792 34.9564 11.8055 32.9363 9.78537C30.9162 7.76528 28.3425 6.38958 25.5405 5.83224Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.7759 17.261C14.4462 16.7434 13.7593 16.5911 13.2418 16.9209L9.28563 19.4412L6.76527 15.485C6.43556 14.9675 5.74871 14.8152 5.23117 15.1449C4.71362 15.4747 4.56135 16.1615 4.89107 16.679L8.60542 22.5094L14.4358 18.7951C14.9533 18.4653 15.1056 17.7785 14.7759 17.261Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
    <path
      d='M18.8781 17.3658L17.5225 17.6991L17.167 16.2991L19.4003 15.6325H20.567V23.4658H18.8781V17.3658Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
    <path
      d='M25.6545 22.0547C25.9138 22.0547 26.1471 21.9954 26.3545 21.8769C26.5619 21.751 26.7397 21.5806 26.8878 21.3658C27.036 21.1436 27.1471 20.8843 27.2212 20.588C27.3026 20.2843 27.3434 19.9547 27.3434 19.5991V19.5769C27.3434 19.2214 27.3026 18.8954 27.2212 18.5991C27.1397 18.2954 27.0212 18.0325 26.8656 17.8102C26.7175 17.588 26.536 17.4139 26.3212 17.288C26.1138 17.1621 25.8841 17.0991 25.6323 17.0991C25.373 17.0991 25.1397 17.1621 24.9323 17.288C24.7249 17.4065 24.5471 17.5769 24.3989 17.7991C24.2582 18.0139 24.1471 18.2732 24.0656 18.5769C23.9841 18.8732 23.9434 19.1991 23.9434 19.5547V19.5769C23.9434 19.9325 23.9841 20.2621 24.0656 20.5658C24.1471 20.8695 24.2619 21.1325 24.41 21.3547C24.5582 21.5695 24.736 21.7399 24.9434 21.8658C25.1582 21.9917 25.3952 22.0547 25.6545 22.0547ZM25.6323 23.5991C25.1138 23.5991 24.6397 23.4991 24.21 23.2991C23.7878 23.0917 23.4249 22.8102 23.1212 22.4547C22.8249 22.0917 22.5952 21.6658 22.4323 21.1769C22.2693 20.688 22.1878 20.1621 22.1878 19.5991V19.5769C22.1878 19.0139 22.2693 18.488 22.4323 17.9991C22.6026 17.5102 22.8397 17.0843 23.1434 16.7214C23.4471 16.3584 23.81 16.0732 24.2323 15.8658C24.6619 15.6584 25.136 15.5547 25.6545 15.5547C26.1656 15.5547 26.6323 15.6584 27.0545 15.8658C27.4841 16.0658 27.8471 16.3473 28.1434 16.7102C28.4471 17.0658 28.6804 17.488 28.8434 17.9769C29.0138 18.4658 29.0989 18.9917 29.0989 19.5547V19.5769C29.0989 20.1399 29.0138 20.6658 28.8434 21.1547C28.6804 21.6436 28.4471 22.0695 28.1434 22.4325C27.8397 22.7954 27.473 23.0806 27.0434 23.288C26.6212 23.4954 26.1508 23.5991 25.6323 23.5991Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
  </svg>
);
