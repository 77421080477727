import { createContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../../Constants/routes';
import { ContentAuthorizer, PlatformAuthorizer, PlatformClient } from '../../api';
import { GetProductsResponseItem } from '../../api/authApi/authApi.types';
import { videoDetailViewedEvent } from '../../api/mParticlesApi/mParticleEvents/video/videoEvents';
import { Padlock } from '../../assets/icons';
import { useFLPPlatformAsset, useFLPVolumeControlState, useFLPlayerHandler } from '../../hooks/flp';
import useFLPVelocityControlState from '../../hooks/flp/useFLPVelocityControlState';
import useFLPlayerErrorAction from '../../hooks/flp/useFLPlayerErrorAction';
import { useAutoHideHeader } from '../../hooks/useAutoHideHeader';
import { useCouchRights } from '../../hooks/useCouchRights';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useSubscriptionNavigate } from '../../hooks/useSubscriptionNavigate';
import { isAuthorizedAssetSelector } from '../../store/activeSubscriptions';
import { playerAssetSelector } from '../../store/player/player.selectors';
import { selectedVideoSelector } from '../../store/videos';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { useFLPAuthorizers } from '../FLPAuthorizers/FLPAuthorizers';
import Logo from '../Logo/Logo';
import { PlayerControlsProvider } from '../PlayerControls/PlayerControlsProvider';
import PlayerInterface from '../PlayerInterface/PlayerInterface';
import { usePauseSubscription } from '../Settings/hooks/usePauseSubscription';
import * as Ui from './Player.styles';

export type PlayerHandlerContextType = ReturnType<typeof useFLPlayerHandler> & {
  volumeControlState: ReturnType<typeof useFLPVolumeControlState>;
  velocityControlState: ReturnType<typeof useFLPVelocityControlState>;
  playerErrorActionState: ReturnType<typeof useFLPlayerErrorAction>;
  assetState: ReturnType<typeof useFLPPlatformAsset>;
  contentAuthorizer: ContentAuthorizer | null;
  platformAuthorizer: PlatformAuthorizer;
  platformClient: PlatformClient;
  showChannels: boolean;
};

export const PlayerHandlerContext = createContext({} as PlayerHandlerContextType);

export type PlayerProps = {
  showChannels?: boolean;
  showLiveControls?: boolean;
  selectedChannel?: string;
  playerErrorActionState: ReturnType<typeof useFLPlayerErrorAction>;
};

export const Player = ({
  showChannels = false,
  showLiveControls = false,
  selectedChannel,
  playerErrorActionState,
}: PlayerProps) => {
  const asset = useSelector(playerAssetSelector);
  const selectedVideo = useSelector(selectedVideoSelector);
  const currentLocation = useLocation();
  const { state } = currentLocation;
  const navigate = useExtendedNavigate();
  const { loaded } = useSelector(zoneInfoStateSelector);
  const { handleResumeSubscription, isSubscriptionPaused } = usePauseSubscription();

  const { checkSubscription } = useSubscriptionNavigate();
  useCouchRights();
  const { contentAuthorizer, platformAuthorizer, platformClient } = useFLPAuthorizers();
  const isAuthorized = useSelector(isAuthorizedAssetSelector(selectedVideo));

  const assetState = useFLPPlatformAsset({
    contentAuthorizer,
    isAuthorized,
    selectedVideo,
  });

  const playerHandlerState = useFLPlayerHandler(
    { asset },
    platformAuthorizer,
    assetState.loadAsset,
  );
  const volumeControlState = useFLPVolumeControlState({ ref: playerHandlerState.ref });
  const velocityControlState = useFLPVelocityControlState({ player: playerHandlerState.player });

  const providerValue: PlayerHandlerContextType = {
    ...playerHandlerState,
    assetState,
    contentAuthorizer,
    platformAuthorizer,
    platformClient,
    playerErrorActionState,
    showChannels,
    velocityControlState,
    volumeControlState,
  };

  useEffect(() => {
    if (selectedVideo?.playbackMode) {
      playerHandlerState?.player?.stop();
    }
  }, [selectedChannel, selectedVideo?.playbackMode]);

  useEffect(() => {
    videoDetailViewedEvent(selectedVideo);
  }, []);

  useEffect(() => {
    return () => {
      playerHandlerState.localPlayer.stop();
    };
  }, [playerHandlerState.localPlayer]);

  const handleLogoClick = useCallback(() => {
    if (isSubscriptionPaused) {
      return handleResumeSubscription();
    }

    checkSubscription(selectedVideo, {
      onSuccess: (product: GetProductsResponseItem) => {
        navigate(`${ROUTES[RouteName.AllSetPayment]}`, {
          state: { backRoute: currentLocation, product },
        });
      },
      replaceRoute: false,
    }).then((playbackAuthorized) => {
      if (!playbackAuthorized) {
        if (state?.goBackWhenNotAuthorized) {
          history.back();
        } else {
          navigate(ROUTES[RouteName.Home], { replace: true });
        }
      }
    });
  }, [
    checkSubscription,
    selectedVideo,
    navigate,
    currentLocation,
    state?.goBackWhenNotAuthorized,
    isSubscriptionPaused,
    handleResumeSubscription,
  ]);

  useAutoHideHeader();

  return (
    <PlayerHandlerContext.Provider value={providerValue}>
      {isAuthorized || !loaded ? (
        <PlayerControlsProvider>
          <PlayerInterface showLiveControls={showLiveControls} />
        </PlayerControlsProvider>
      ) : (
        <Ui.LogoWrapper onClick={handleLogoClick}>
          <Logo imgSize='large' />
          <Ui.LockIconWrapper>
            <Padlock width={40} height={40} />
          </Ui.LockIconWrapper>
        </Ui.LogoWrapper>
      )}
    </PlayerHandlerContext.Provider>
  );
};

export default Player;
