import { useLottie } from 'lottie-react';

import animatedLoader from '../../assets/lottieAnimations/loader.json';
import * as Ui from './Loader.styles';

const LOADER_HEIGHT = 54;

export interface LoaderProps {
  /**
   * Apply styles for absolute positioning
   */
  absolute?: boolean;
  /**
   * Apply backdrop
   */
  withBackdrop?: boolean;
}

export const Loader = (props: LoaderProps) => {
  const defaultOptions = {
    animationData: animatedLoader,
    autoplay: true,
    loop: true,
  };

  const lottieStyles = { height: `${LOADER_HEIGHT}px` };
  const { View } = useLottie(defaultOptions, { ...lottieStyles });

  return (
    <Ui.LoaderWrapper $absolute={props.absolute} $withBackdrop={props.withBackdrop}>
      {View}
    </Ui.LoaderWrapper>
  );
};
