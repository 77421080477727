import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../../Constants/routes';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { LogoutType, useLogoutAction } from '../../hooks/useLogoutAction';
import { useTranslations } from '../../hooks/useTranslations';
import { isUserLoggedInForbidenRegionSelector } from '../../store/auth';
import { useSimpleModalContext } from '../Modals/SimpleModal/SimpleModalContext';

export const RegionValidationWeb = () => {
  const { openModal, closeModal } = useSimpleModalContext();
  const isForbidden = useSelector(isUserLoggedInForbidenRegionSelector);
  const navigate = useExtendedNavigate();
  const t = useTranslations();
  const dispatchLogout = useLogoutAction();
  const onBackAction = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    if (isForbidden) {
      openModal({
        acceptButton: {
          destination: ROUTES[RouteName.Welcome],
          onClick: () => {
            dispatchLogout(LogoutType.CURRENT_DEVICE);
            onBackAction();
          },
          text: t.forbiddenRegionModal_button,
        },
        description: t.forbiddenRegionModal_description,
        modalName: 'Forbidden region',
        title: t.forbiddenRegionModal_title,
      });

      return () => {
        closeModal();
      };
    }
  }, [isForbidden]);

  return null;
};

export default RegionValidationWeb;
