import { combineReducers } from 'redux';

import { reducer as activeSubscriptionsReducer } from './activeSubscriptions';
import { reducer as authReducer } from './auth';
import { reducer as browserReducer } from './browser';
import { reducer as channelsReducer } from './channels';
import { reducer as appConfigReducer } from './config';
import { reducer as couchRightsReducer } from './couchRights';
import { reducer as debugReducer } from './debug';
import { reducer as generateDeviceCode } from './deviceCode';
import { reducer as deviceInfoReducer } from './deviceInfo';
import { reducer as entitlementsReducer } from './entitlements';
import { reducer as favoritesTeamsReducer } from './favoriteTeams';
import { reducer as marketingPreferences } from './marketingPreferences';
import { reducer as msgStatsReducer } from './msgStats';
import { reducer as playerReducer } from './player';
import { reducer as productsReducer } from './products';
import { reducer as programsReducer } from './programs';
import { reducer as purchaseReducer } from './purchase';
import { reducer as registerDeviceReducer } from './registerDevice';
import { reducer as routerReducer } from './router';
import { reducer as scheduleReducer } from './schedule';
import { reducer as serverErrorReducer } from './serverError';
import { reducer as storefrontReducer } from './storefront';
import { reducer as teamsReducer } from './teams';
import { reducer as toastReducer } from './toast';
import { reducer as translationsReducer } from './translations';
import { reducer as userData } from './userData';
import { reducer as videosReducer } from './videos';
import { reducer as zoneInfoReducer } from './zoneInfo';

const rootReducer = combineReducers({
  activationCode: generateDeviceCode,
  activeSubscriptions: activeSubscriptionsReducer,
  appConfig: appConfigReducer,
  auth: authReducer,
  browser: browserReducer,
  channels: channelsReducer,
  couchRights: couchRightsReducer,
  debug: debugReducer,
  deviceInfo: deviceInfoReducer,
  entitlements: entitlementsReducer,
  favoritesTeams: favoritesTeamsReducer,
  marketingPreferences: marketingPreferences,
  msgStats: msgStatsReducer,
  player: playerReducer,
  products: productsReducer,
  programs: programsReducer,
  purchase: purchaseReducer,
  registerDevice: registerDeviceReducer,
  router: routerReducer,
  schedule: scheduleReducer,
  serverError: serverErrorReducer,
  storefront: storefrontReducer,
  teams: teamsReducer,
  toast: toastReducer,
  translations: translationsReducer,
  userData: userData,
  videos: videosReducer,
  zoneInfo: zoneInfoReducer,
});

export default rootReducer;
