import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMsgStats, shouldFetchStatsSelector } from '../store/msgStats';
import { FETCH_STATS_INTERVAL } from '../store/msgStats/msgStats.constants';
import { AppDispatch } from '../store/store';

export const useFetchStats = () => {
  const shouldFetchStats = useSelector(shouldFetchStatsSelector);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    shouldFetchStats && dispatch(fetchMsgStats());

    const intervalId = setInterval(() => {
      shouldFetchStats && dispatch(fetchMsgStats());
    }, FETCH_STATS_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [shouldFetchStats]);
};
