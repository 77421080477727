import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '../../hooks/useTranslations';
import { useZipCodeForm } from '../../hooks/useZipCodeForm';
import { AppDispatch } from '../../store/store';
import { toastActions } from '../../store/toast';
import { zoneInfoStateSelector } from '../../store/zoneInfo';
import { ButtonSize, ButtonVariant } from '../Button/Button.types';
import * as Ui from './ZipCodeForm.styles';

export const ZipCodeForm = ({ ...rest }) => {
  const dispatch: AppDispatch = useDispatch();
  const t = useTranslations();
  const { updating, zipCode, updateError } = useSelector(zoneInfoStateSelector);
  const { handleFormSubmit, isSubmitDisabled, register, submitOnEnter } = useZipCodeForm(zipCode);

  useEffect(() => {
    if (updateError) {
      dispatch(
        toastActions.showToast({
          className: 'toastError', // src/components/GlobalStyle/GlobalStyle.tsx
          message: updateError,
          position: 'top-center',
        }),
      );
    }
  }, [updateError]);

  return (
    <Ui.Wrapper {...rest}>
      <Ui.ZipCodeMessage>{t.zipCodeTopForm_message}</Ui.ZipCodeMessage>
      <Ui.FormWrapper
        onSubmit={(e) => {
          e.preventDefault();
        }}
        onKeyDown={submitOnEnter}
      >
        <Ui.ZipCodeInput
          type='text'
          placeholder={t.zipCodeTopForm_placeholder}
          {...register('zipCode')}
        />

        <Ui.SubmitButton
          disabled={isSubmitDisabled}
          variant={ButtonVariant.Secondary}
          onPress={handleFormSubmit}
          size={ButtonSize.Large}
          loading={updating}
        >
          {t.zipCodeTopForm_button}
        </Ui.SubmitButton>
      </Ui.FormWrapper>
    </Ui.Wrapper>
  );
};
