import mParticle from '@mparticle/web-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Video } from '../../api';
import { GetProductsResponseItem, ProductServiceTypeEnum } from '../../api/authApi/authApi.types';
import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { purchaseEvent } from '../../api/mParticlesApi/mParticleEvents/purchase/purchaseEvents';
import { getImageResizeUrl } from '../../helpers/imageResizeUrl';
import { useLogScreenViewedEvent } from '../../hooks/useLogScreenViewedEvent';
import { openSubscriptionModal } from '../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import {
  activeSubscriptionsSelector,
  loadActiveSupscription,
} from '../../store/activeSubscriptions';
import { authSelector } from '../../store/auth';
import { entitlementsSelector, loadEntitlements } from '../../store/entitlements';
import { purchaseItemSelector } from '../../store/purchase';
import { AppDispatch } from '../../store/store';
import { RootState } from '../../store/types';
import { sendMParticleError } from '../../utils/mParticle/sendMPArticleError';
import { BackButton } from '../BackButton/BackButton';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { useDataZoomContext } from '../DataZoomProvider/context';
import { SubscriptionModalBody } from '../Modals';
import { useModalContext } from '../Modals/context';
import { PurchaseForm } from './PurchaseForm/PurchaseForm';
import * as Ui from './PurchaseModal.styles';
import { PurchaseSummary } from './PurchaseSummary/PurchaseSummary';
import { SelectPaymentModal } from './SelectPaymentModal/SelectPaymentModal';

export const PurchaseModal = ({
  product,
  onSuccess,
  purchaseVideo,
  onSkip,
  redirectBackUrl,
  passedOnGoBack,
  selectPaymentOnGoBack,
  hideZipCodeChange,
}: {
  product: GetProductsResponseItem;
  purchaseVideo: Video | null;
  onSuccess: (product: GetProductsResponseItem) => void;
  onSkip: () => void;
  redirectBackUrl?: string;
  passedOnGoBack?: () => void;
  selectPaymentOnGoBack?: () => void;
  hideZipCodeChange?: boolean;
}) => {
  const t = useTranslations();
  const [completed, setCompleted] = useState(false);
  const [purchaseConcluded, setPurchaseConcluded] = useState(false);
  const { openModal, closeModal } = useModalContext();
  const { accessToken } = useSelector(authSelector);
  const { loading: loadingActiveSubscriptions, lastSubscription } = useSelector(
    activeSubscriptionsSelector,
  );
  const appState = useSelector((state: RootState) => state);

  const { loading: loadingEntitlements } = useSelector(entitlementsSelector);
  const purchaseItem = useSelector(purchaseItemSelector);
  const dispatch: AppDispatch = useDispatch();
  const { userInfo, userSession } = useDataZoomContext();
  const handleSuccess = useCallback(() => {
    dispatch(loadActiveSupscription({ accessToken }));
    dispatch(loadEntitlements({ accessToken }));
    setCompleted(true);
  }, [dispatch, accessToken]);

  useLogScreenViewedEvent('purchase');

  const displayPPGSummary =
    product.serviceType !== ProductServiceTypeEnum.SVOD && purchaseVideo?.isPPGPurchasable;

  const imageUrl = displayPPGSummary
    ? getImageResizeUrl({
        assetId: purchaseVideo.id,
        format: 'jpg',
        size: '0-16x9',
      })
    : '';
  const handleFinish = useCallback(
    (product: GetProductsResponseItem) => {
      onSuccess(product);
      closeModal();
    },
    [closeModal, onSuccess],
  );

  const handleError = useCallback((error: unknown) => {
    sendMParticleError(
      {
        error,
        eventType: mParticle.EventType.Transaction,
      },
      appState,
    );
  }, []);

  const changeSelection = useCallback(() => {
    openSubscriptionModal({
      content: (
        <SubscriptionModalBody
          onSuccess={onSuccess}
          onSkip={onSkip}
          redirectBackUrl={redirectBackUrl}
          onGoBack={passedOnGoBack}
        />
      ),
      openModal,
    });
    buttonClicked({
      destination: '/home',
      location: 'Module: Home',
      path: window.location.pathname,
      screen: 'home',
      text: 'CTA: Change Zip code',
      type: 'Button',
      url: window.location.href,
    });
  }, [passedOnGoBack, onSkip, onSuccess, openModal, redirectBackUrl]);

  useEffect(() => {
    if (completed && !loadingActiveSubscriptions && !loadingEntitlements) {
      if (userInfo && userSession && (lastSubscription || purchaseVideo) && !purchaseConcluded) {
        userSession.completePayment(userInfo);
        purchaseEvent({
          activeSubscription: lastSubscription,
          product,
          purchaseVideo,
          subscription: userSession.user?.subscription,
          totalDiscountAmount: purchaseItem?.totalDiscountAmount,
        });
        setPurchaseConcluded(true);
      }
      handleFinish(product);
    }
  }, [
    completed,
    loadingActiveSubscriptions,
    loadingEntitlements,
    handleFinish,
    userInfo,
    userSession,
    purchaseItem,
  ]);

  useEffect(() => {
    if (userInfo && userSession) {
      userSession.purchaseSubscription(userInfo);
    }
  }, []);

  const onGoBackAction = useCallback(() => {
    openSubscriptionModal({
      content: (
        <SelectPaymentModal
          product={product}
          onSuccess={onSuccess}
          onSkip={onSkip}
          passedOnGoBack={passedOnGoBack}
          onGoBack={selectPaymentOnGoBack}
          hidePurchaseZipCodeChange={hideZipCodeChange}
        />
      ),
      openModal,
    });
  }, [passedOnGoBack, closeModal, dispatch, onSkip]);

  return (
    <Ui.ModalBody>
      <Ui.ModalContainer $minSvh100>
        <ClickEventDispatcher
          destination='/home'
          location='Module: Purchase Form'
          screen='Purchase Form'
          text='CTA: Back'
        >
          <BackButton onClick={onGoBackAction} isFloating={false} />
        </ClickEventDispatcher>
        <Ui.ContentContainer>
          <Ui.Wrapper xs={12} md={12} fullHD={10} ultraHD={8}>
            <Ui.TitleRow>
              <Ui.Title>{t.purchaseModal_title}</Ui.Title>
              <Ui.TitleDescription>{t.purchaseModal_description}</Ui.TitleDescription>
            </Ui.TitleRow>

            <PurchaseSummary
              changeSelection={changeSelection}
              imageUrl={imageUrl}
              displayPPGSummary={displayPPGSummary}
              purchaseVideo={purchaseVideo}
            />

            <PurchaseForm
              product={product}
              onError={handleError}
              onSuccess={handleSuccess}
              changeSelection={changeSelection}
              hideZipCodeChange={hideZipCodeChange}
            />
          </Ui.Wrapper>
        </Ui.ContentContainer>
      </Ui.ModalContainer>
    </Ui.ModalBody>
  );
};
