import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { useFLPlayerErrorAction } from '../../hooks/flp';
import { useHandleGoBackOnPlayer } from '../../hooks/useHandleGoBackPlayer';
import { useTranslations } from '../../hooks/useTranslations';
import { playerActions } from '../../store/player';
import { playerSelector } from '../../store/player/player.selectors';
import { IconType } from '../ErrorComponent/ErrorComponent';
import * as PlayerLayoutUi from '../PlayerLayout/PlayerLayout.styles';
import * as Ui from './PlayerError.styles';

type PlayerErrorProps = {
  playerErrorActionState: ReturnType<typeof useFLPlayerErrorAction>;
};

export const PlayerError = ({ playerErrorActionState }: PlayerErrorProps) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const { error, isLocalPlayer, assetError } = useSelector(playerSelector);
  const handleGoBackOnPlayer = useHandleGoBackOnPlayer();

  const goBack = useCallback(() => {
    buttonClicked({
      destination: '/home',
      location: 'Module: Player',
      path: window.location.pathname,
      screen: 'Player',
      text: 'CTA: Go Back',
      type: 'Button',
      url: window.location.href,
    });
    handleGoBackOnPlayer();
  }, [handleGoBackOnPlayer]);

  useEffect(() => {
    return () => {
      dispatch(playerActions.setPlayerAssetError(undefined));
    };
  }, []);

  if (assetError) {
    return (
      <PlayerLayoutUi.Container>
        <PlayerLayoutUi.PlayerLayout>
          <Ui.Error
            icon={IconType.InfoError}
            error={assetError.errorMessage}
            action={goBack}
            buttonText={t.playerInterface_back}
          />
        </PlayerLayoutUi.PlayerLayout>
      </PlayerLayoutUi.Container>
    );
  }

  if (error) {
    return (
      <PlayerLayoutUi.Container>
        <PlayerLayoutUi.PlayerLayout>
          <Ui.Error
            icon={IconType.InfoError}
            error={error.errorMessage}
            action={playerErrorActionState.playerErrorAction}
            buttonText={
              isLocalPlayer !== false ? t.playerInterface_back : t.playerInterface_disconnect
            }
          />
        </PlayerLayoutUi.PlayerLayout>
      </PlayerLayoutUi.Container>
    );
  }

  return null;
};

export default PlayerError;
