import { Video } from '../../api';

export interface AllVideosInfo {
  count: number;
  url: string;
  priority: number;
  type: string;
  q?: string;
}

export enum AspectRatioKeys {
  A0_16x9 = '0-16x9',
  A0_2x3 = '0-2x3',
  A3_16x9 = '3-16x9',
  A4x5 = '0-4x5',
  A1x1 = '3-1x1',
}

export enum ContainerSize {
  REGULAR = 'regular',
  LARGE = 'large',
}

export type Container = {
  id: string;
  title: string;
  videos: Video[];
  showText: boolean;
  aspectRatioKey: AspectRatioKeys;
  containerSize: ContainerSize;
  allVideosInfo: AllVideosInfo[];
  allVideos: Video[];
};

export type VideoTeamPage = {
  teamKey: string;
  pageNumber: number;
  pageSize: number;
};

export type Videos = {
  banner: Container | null;
  carousels: Container[];
  teamPage: VideoTeamPage | null;
};

export type VideosState = Videos & {
  selectedVideo: Video | null;
  purchaseVideo: Video | null;
  isPurchasingVideo: boolean;
  error: string | null;
  loading: boolean;
  videoLoaded: boolean;
  videoLoading: boolean;
  loaded: boolean;
};
