import { AdobeAccessTokenResponse, FailureResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getAdobeAccessToken = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  getAdobeAccessTokenUrl,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getAdobeAccessTokenUrl: string;
}): Promise<AdobeAccessTokenResponse> => {
  const activationCodeData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const body = { GetAdobeAccessTokenRequestMessage: activationCodeData };

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const response = evergentAxiosInstance.post<{
    GetAdobeAccessTokenResponseMessage: AdobeAccessTokenResponse & FailureResponse;
  }>(getAdobeAccessTokenUrl, body, { headers });

  const { failureMessage, ...dataResponse } = (await response).data
    .GetAdobeAccessTokenResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw {
      errorCode: String(failureMessage[0].errorCode),
      errorMessage: String(failureMessage[0].errorMessage),
    };
  }

  return dataResponse;
};
