export const AndroidStore = () => (
  <svg width='208' height='64' viewBox='0 0 208 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.04081 0H199.961C204.398 0 208.001 3.63203 208.001 8.10522V55.6573C208.001 60.1304 204.398 63.7611 199.962 63.7611H8.03943C3.60179 63.7611 0 60.129 0 55.6573V8.10522C0 3.63203 3.60317 0 8.04081 0Z'
      fill='black'
    />
    <path
      d='M68.1696 38.5151H71.0709V45.1483C70.2212 45.8293 69.232 46.353 68.0991 46.7178C66.9676 47.0827 65.8029 47.2652 64.6092 47.2652C62.9265 47.2652 61.4095 46.8961 60.0569 46.1594C58.7043 45.4227 57.6433 44.4046 56.8738 43.1039C56.1028 41.8046 55.7188 40.3381 55.7188 38.7059C55.7188 37.0737 56.1042 35.6073 56.8738 34.3079C57.6433 33.0086 58.7099 31.9906 60.0694 31.2539C61.4288 30.5171 62.9582 30.1481 64.6562 30.1481C66.0392 30.1481 67.2978 30.3779 68.4293 30.8374C69.5608 31.297 70.5127 31.9711 71.2823 32.8582L69.3481 34.7605C68.0894 33.4932 66.5725 32.8582 64.7957 32.8582C63.6325 32.8582 62.599 33.1047 61.6941 33.5949C60.7892 34.0865 60.0818 34.7759 59.572 35.663C59.0608 36.5501 58.8052 37.5653 58.8052 38.7059C58.8052 39.8465 59.0608 40.8144 59.572 41.7015C60.0832 42.5886 60.7905 43.2863 61.6941 43.7933C62.5976 44.3002 63.6242 44.5537 64.7723 44.5537C66.0613 44.5537 67.1928 44.2682 68.1682 43.6972V38.5151H68.1696Z'
      fill='white'
    />
    <path
      d='M77.0363 46.3613C76.0139 45.807 75.2167 45.0341 74.642 44.0439C74.0672 43.0538 73.7812 41.9327 73.7812 40.6807C73.7812 39.4287 74.0672 38.3118 74.642 37.3286C75.2153 36.3468 76.0139 35.578 77.0363 35.0224C78.0586 34.4681 79.2053 34.1909 80.4792 34.1909C81.753 34.1909 82.9232 34.4681 83.9456 35.0224C84.9679 35.5766 85.7651 36.3454 86.3398 37.3286C86.9132 38.3118 87.2006 39.4287 87.2006 40.6807C87.2006 41.9327 86.9132 43.0538 86.3398 44.0439C85.7651 45.0341 84.9679 45.807 83.9456 46.3613C82.9232 46.9156 81.7682 47.1927 80.4792 47.1927C79.1901 47.1927 78.0572 46.9156 77.0363 46.3613ZM83.1677 43.5565C83.8751 42.8282 84.2288 41.8686 84.2288 40.6807C84.2288 39.4928 83.8751 38.5332 83.1677 37.8049C82.4604 37.0765 81.5637 36.7116 80.4792 36.7116C79.3946 36.7116 78.5021 37.0765 77.803 37.8049C77.104 38.5346 76.753 39.4928 76.753 40.6807C76.753 41.8686 77.1026 42.8282 77.803 43.5565C78.5021 44.2863 79.3946 44.6497 80.4792 44.6497C81.5637 44.6497 82.4604 44.2849 83.1677 43.5565Z'
      fill='white'
    />
    <path
      d='M92.2472 46.3613C91.2248 45.807 90.4276 45.0341 89.8529 44.0439C89.2782 43.0538 88.9922 41.9327 88.9922 40.6807C88.9922 39.4287 89.2782 38.3118 89.8529 37.3286C90.4263 36.3468 91.2248 35.578 92.2472 35.0224C93.2696 34.4681 94.4163 34.1909 95.6901 34.1909C96.9639 34.1909 98.1341 34.4681 99.1565 35.0224C100.179 35.5766 100.976 36.3454 101.551 37.3286C102.124 38.3118 102.411 39.4287 102.411 40.6807C102.411 41.9327 102.124 43.0538 101.551 44.0439C100.976 45.0341 100.179 45.807 99.1565 46.3613C98.1341 46.9156 96.9791 47.1927 95.6901 47.1927C94.4011 47.1927 93.2682 46.9156 92.2472 46.3613ZM98.3787 43.5565C99.086 42.8282 99.4397 41.8686 99.4397 40.6807C99.4397 39.4928 99.086 38.5332 98.3787 37.8049C97.6713 37.0765 96.7746 36.7116 95.6901 36.7116C94.6056 36.7116 93.7131 37.0765 93.014 37.8049C92.3149 38.5346 91.964 39.4928 91.964 40.6807C91.964 41.8686 92.3135 42.8282 93.014 43.5565C93.7131 44.2863 94.6056 44.6497 95.6901 44.6497C96.7746 44.6497 97.6713 44.2849 98.3787 43.5565Z'
      fill='white'
    />
    <path
      d='M117.738 34.3316V45.101C117.738 49.57 115.474 51.8052 110.947 51.8052C109.736 51.8052 108.588 51.6506 107.504 51.3414C106.419 51.0323 105.523 50.5838 104.815 49.9989L106.136 47.7637C106.686 48.2233 107.382 48.5923 108.224 48.8695C109.065 49.1466 109.917 49.2859 110.782 49.2859C112.165 49.2859 113.179 48.9684 113.824 48.3347C114.47 47.701 114.792 46.7345 114.792 45.4338V44.7681C114.289 45.3224 113.675 45.743 112.953 46.0285C112.229 46.314 111.436 46.456 110.571 46.456C109.376 46.456 108.295 46.1984 107.328 45.6831C106.361 45.1678 105.599 44.4436 105.04 43.5078C104.482 42.5733 104.203 41.5038 104.203 40.2991C104.203 39.0945 104.482 38.0291 105.04 37.1016C105.599 36.1741 106.361 35.4583 107.328 34.9499C108.295 34.443 109.376 34.1896 110.571 34.1896C111.483 34.1896 112.312 34.34 113.059 34.6408C113.805 34.9416 114.431 35.4012 114.934 36.0195V34.3316H117.74H117.738ZM113.765 42.9257C114.481 42.2516 114.839 41.377 114.839 40.2991C114.839 39.2212 114.481 38.3731 113.765 37.7074C113.049 37.0417 112.133 36.7089 111.017 36.7089C109.901 36.7089 108.961 37.0417 108.246 37.7074C107.53 38.3731 107.172 39.2365 107.172 40.2991C107.172 41.3617 107.53 42.253 108.246 42.9257C108.961 43.5997 109.884 43.9353 111.017 43.9353C112.15 43.9353 113.049 43.5983 113.765 42.9257Z'
      fill='white'
    />
    <path d='M121.609 29.3863H124.558V47.0256H121.609V29.3863Z' fill='white' />
    <path
      d='M140.219 41.6305H130.338C130.51 42.5664 130.962 43.3059 131.694 43.8532C132.425 44.4005 133.333 44.6734 134.419 44.6734C135.802 44.6734 136.942 44.2139 137.838 43.2947L139.419 45.1246C138.852 45.8057 138.137 46.3209 137.273 46.6705C136.408 47.0187 135.434 47.1941 134.348 47.1941C132.964 47.1941 131.745 46.917 130.693 46.3627C129.638 45.8084 128.826 45.0355 128.251 44.0453C127.677 43.0552 127.391 41.9341 127.391 40.6821C127.391 39.4301 127.67 38.3327 128.228 37.3425C128.786 36.3524 129.56 35.5794 130.55 35.0252C131.541 34.4709 132.657 34.1937 133.899 34.1937C135.141 34.1937 136.222 34.4667 137.189 35.014C138.156 35.5613 138.91 36.3301 139.453 37.3202C139.996 38.3104 140.267 39.4566 140.267 40.7559C140.267 40.962 140.252 41.2545 140.22 41.6361L140.219 41.6305ZM131.505 37.4108C130.853 37.9734 130.455 38.7226 130.314 39.6571H137.46C137.334 38.738 136.948 37.9929 136.305 37.4219C135.659 36.8509 134.858 36.5654 133.899 36.5654C132.94 36.5654 132.157 36.8467 131.505 37.4094V37.4108Z'
      fill='white'
    />
    <path
      d='M160.413 31.0993C161.474 31.5742 162.288 32.2566 162.854 33.1437C163.421 34.0308 163.704 35.085 163.704 36.305C163.704 37.525 163.421 38.5597 162.854 39.4552C162.288 40.3506 161.474 41.0358 160.413 41.5121C159.352 41.987 158.106 42.2251 156.675 42.2251H152.949V47.027H149.883V30.3862H156.675C158.105 30.3862 159.351 30.6244 160.413 31.0993ZM159.576 38.7547C160.268 38.1837 160.614 37.3676 160.614 36.3064C160.614 35.2452 160.268 34.4277 159.576 33.8581C158.884 33.2871 157.87 33.0016 156.534 33.0016H152.949V39.6098H156.534C157.87 39.6098 158.884 39.3243 159.576 38.7533V38.7547Z'
      fill='white'
    />
    <path d='M166.727 29.3863H169.675V47.0256H166.727V29.3863Z' fill='white' />
    <path
      d='M182.603 35.5321C183.586 36.4276 184.077 37.7784 184.077 39.5847V47.0256H181.295V45.4798C180.933 46.0341 180.418 46.4588 179.75 46.7513C179.082 47.0437 178.276 47.1913 177.332 47.1913C176.389 47.1913 175.564 47.0284 174.857 46.7039C174.149 46.3794 173.602 45.9282 173.218 45.3489C172.833 44.7709 172.641 44.1164 172.641 43.388C172.641 42.2474 173.061 41.3311 173.902 40.6417C174.743 39.9523 176.067 39.607 177.875 39.607H181.13V39.4162C181.13 38.5291 180.867 37.8467 180.34 37.3718C179.814 36.8969 179.032 36.6587 177.994 36.6587C177.287 36.6587 176.591 36.7701 175.907 36.9916C175.223 37.2144 174.645 37.5222 174.173 37.9191L173.018 35.7563C173.678 35.2494 174.471 34.8608 175.4 34.592C176.327 34.3233 177.31 34.1882 178.348 34.1882C180.203 34.1882 181.622 34.6366 182.605 35.5307L182.603 35.5321ZM179.915 44.5175C180.488 44.1763 180.893 43.6888 181.129 43.0552V41.5817H178.087C176.389 41.5817 175.539 42.1444 175.539 43.2696C175.539 43.8086 175.752 44.2361 176.176 44.5537C176.6 44.8712 177.19 45.0286 177.945 45.0286C178.699 45.0286 179.34 44.8587 179.913 44.5175H179.915Z'
      fill='white'
    />
    <path
      d='M199.407 34.3316L193.511 48.0478C192.961 49.4265 192.293 50.3972 191.507 50.9598C190.721 51.5225 189.769 51.8038 188.654 51.8038C188.025 51.8038 187.403 51.7007 186.79 51.4946C186.177 51.2885 185.674 51.003 185.281 50.6381L186.461 48.4517C186.744 48.7205 187.077 48.9349 187.463 49.0937C187.848 49.2525 188.236 49.3318 188.63 49.3318C189.15 49.3318 189.578 49.1967 189.915 48.928C190.254 48.6578 190.563 48.2066 190.846 47.5729L191.059 47.0743L185.564 34.3316H188.63L192.593 43.7222L196.578 34.3316H199.408H199.407Z'
      fill='white'
    />
    <path
      d='M50.0432 31.0672L42.1185 26.4576L35.6016 32.808L41.5576 38.6126L50.0432 33.6757C50.5102 33.4041 50.7893 32.9167 50.7893 32.3721C50.7893 31.8276 50.5102 31.3402 50.0432 31.0672Z'
      fill='#FFD109'
    />
    <path
      d='M40.7787 25.6735L32.0567 20.6001L12.9453 10.7276L34.5312 31.7608L40.7787 25.6735Z'
      fill='#06EF79'
    />
    <path
      d='M13.2656 54.5793L32.0924 44.1247L40.2189 39.3953L34.5337 33.8553L13.2656 54.5793Z'
      fill='#F93646'
    />
    <path d='M11.6692 11.5785L11.6484 54.0571L33.4567 32.808L11.6692 11.5785Z' fill='#05D5FB' />
    <path
      d='M60.9879 21.1474C60.3219 21.1474 59.7016 21.0012 59.1255 20.7087C58.5494 20.4162 58.0479 20.0235 57.621 19.5291C57.1927 19.0361 56.8597 18.4791 56.6221 17.8607C56.3844 17.2424 56.2656 16.6018 56.2656 15.9403C56.2656 15.2788 56.3844 14.5964 56.6221 13.978C56.8597 13.3597 57.1899 12.811 57.6141 12.3319C58.0368 11.8528 58.5369 11.4768 59.1117 11.2025C59.6864 10.9295 60.3123 10.793 60.9879 10.793C61.9674 10.793 62.7756 10.9991 63.4126 11.4114C64.0495 11.8236 64.5303 12.3612 64.8535 13.0227L63.8699 13.7134C63.5756 13.1188 63.1708 12.6787 62.6568 12.3904C62.1429 12.1021 61.5778 11.9587 60.9589 11.9587C60.4449 11.9587 59.9793 12.0687 59.5607 12.2887C59.1421 12.5088 58.7829 12.8096 58.4831 13.187C58.1833 13.5658 57.9553 13.9947 57.7978 14.4738C57.6403 14.9529 57.5629 15.4514 57.5629 15.9695C57.5629 16.5154 57.6514 17.0307 57.8268 17.5154C58.0023 18 58.2523 18.4261 58.5756 18.7952C58.8989 19.1642 59.2747 19.4539 59.703 19.6656C60.1313 19.8759 60.5927 19.9817 61.0873 19.9817C61.6676 19.9817 62.2189 19.8327 62.7425 19.5361C63.2661 19.2395 63.7607 18.7841 64.2263 18.1699V19.5068C63.3131 20.6001 62.2341 21.146 60.9879 21.146V21.1474ZM64.0688 16.9625H61.8859V15.9848H65.1533V21.075H64.0688V16.9625Z'
      fill='white'
    />
    <path
      d='M73.9855 19.9246V21.075H67.1094V10.8654H73.857V12.0158H68.3929V15.3233H73.1441V16.4012H68.3929V19.9246H73.9855Z'
      fill='white'
    />
    <path
      d='M83.0399 12.0158H79.5445V21.075H78.261V12.0158H74.7656V10.8654H83.0399V12.0158Z'
      fill='white'
    />
    <path d='M87.6797 21.075V10.8654H88.9632V21.075H87.6797Z' fill='white' />
    <path
      d='M92.6741 13.2525V21.075H91.3906V10.8654H92.4752L98.6674 18.8467V10.8794H99.9509V21.075H98.7807L92.6755 13.2525H92.6741Z'
      fill='white'
    />
    <path
      d='M110.092 21.1474C109.379 21.1474 108.731 21.0039 108.152 20.7157C107.572 20.4274 107.07 20.0374 106.648 19.543C106.225 19.0487 105.899 18.493 105.671 17.8747C105.443 17.2563 105.328 16.6213 105.328 15.9695C105.328 15.3177 105.45 14.6395 105.691 14.0212C105.935 13.4029 106.272 12.8514 106.704 12.3681C107.137 11.8835 107.644 11.5005 108.224 11.2178C108.804 10.9351 109.437 10.793 110.121 10.793C110.805 10.793 111.48 10.942 112.061 11.2387C112.641 11.5353 113.14 11.9336 113.558 12.4322C113.977 12.9307 114.3 13.4864 114.528 14.1006C114.756 14.7147 114.871 15.3428 114.871 15.9848C114.871 16.6658 114.752 17.3148 114.514 17.9331C114.277 18.5515 113.941 19.103 113.508 19.5862C113.076 20.0709 112.569 20.4511 111.989 20.7296C111.408 21.0081 110.776 21.146 110.092 21.146V21.1474ZM106.625 15.9709C106.625 16.489 106.708 16.9903 106.875 17.4736C107.041 17.9582 107.278 18.3872 107.581 18.7604C107.885 19.1336 108.252 19.4316 108.68 19.6517C109.108 19.8717 109.583 19.9817 110.107 19.9817C110.631 19.9817 111.134 19.8661 111.562 19.6364C111.99 19.4066 112.353 19.1002 112.653 18.7158C112.953 18.3328 113.181 17.9011 113.339 17.422C113.496 16.943 113.573 16.4583 113.573 15.9695C113.573 15.4514 113.491 14.9529 113.323 14.4738C113.158 13.9947 112.919 13.5658 112.61 13.187C112.301 12.8082 111.935 12.5116 111.512 12.2957C111.089 12.0798 110.621 11.9726 110.107 11.9726C109.565 11.9726 109.081 12.0854 108.652 12.311C108.224 12.5366 107.861 12.8402 107.561 13.2246C107.261 13.6076 107.03 14.0365 106.869 14.5114C106.707 14.9863 106.625 15.4723 106.625 15.9709Z'
      fill='white'
    />
    <path
      d='M117.94 13.2525V21.075H116.656V10.8654H117.741L123.932 18.8467V10.8794H125.215V21.075H124.045L117.94 13.2525Z'
      fill='white'
    />
  </svg>
);
