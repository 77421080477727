import { useCallback, useEffect, useState } from 'react';

import { PlatformAuthorizer, quickplayCmsApi } from '../../api';
import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { Log } from '../../helpers/CustomLogger';

export const usePurchaseToken = ({
  ovatToken,
  contentId,
  deviceId,
  platformAuthorizer,
  shouldSkip = false,
}: {
  ovatToken?: string;
  contentId: string;
  deviceId: string;
  platformAuthorizer: PlatformAuthorizer;
  shouldSkip?: boolean;
}) => {
  const { CLIENT_ID, CLIENT_SECRET, X_CLIENT_ID, MEDIA_CHECKREDEEM_URL } = useEnv();
  const [purchaseToken, setPurchaseToken] = useState<string | null>(null);

  const loadCheckredeemToken = useCallback(
    async (ovatToken: string) => {
      return (
        platformAuthorizer?.oAuthService
          .authorize(CLIENT_ID, CLIENT_SECRET)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .then(async ({ accessToken }: { accessToken: string }) => {
            const authToken = accessToken;

            const { token } = await quickplayCmsApi.mediaCheckredeem({
              authToken,
              contentId,
              deviceId,
              mediaCheckredeemUrl: MEDIA_CHECKREDEEM_URL,
              ovatToken,
              xClientId: X_CLIENT_ID,
            });

            return token;
          })
      );
    },
    [platformAuthorizer, contentId, deviceId],
  );

  useEffect(() => {
    if (!ovatToken || shouldSkip) return;

    loadCheckredeemToken(ovatToken).then(
      (token: string) => {
        setPurchaseToken(token);
      },
      (err) => {
        Log.error('error getting checkredeem token', err);
      },
    );
  }, [loadCheckredeemToken]);

  return { purchaseToken };
};

export default usePurchaseToken;
