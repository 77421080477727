import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { Col, Container, FONT_WEIGHT, Row } from '../Common';

export const ModalBody = styled.div`
  padding: 0;
`;

export const ModalContainer = styled(Container)`
  min-height: 100vh;
  min-height: 100svh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  ${breakpoint('xl')`
    padding-top: 2rem;
    padding-bottom: 2rem;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TitleRow = styled(Row)`
  margin-top: 2.5rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  ${breakpoint('md')`
    margin-top: 3.25rem;
  `}

  ${breakpoint('xl')`
    margin-top: 2.5rem;
  `}
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors['off-white']};
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  text-align: center;

  ${breakpoint('md')`
    font-weight: ${FONT_WEIGHT.Bold};;
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.1rem;
  `}
`;

export const TitleDescription = styled.div`
  color: ${({ theme }) => theme.colors['off-white']};
  font-weight: ${FONT_WEIGHT.Regular};
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  margin-top: 1.5rem;

  ${breakpoint('md')`
    margin-top: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
  `};
`;

export const LogoCol = styled(Col)`
  display: none;

  ${breakpoint('xl')`
    display: flex;
  `}
`;
