import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { flexCenter } from '../../utils/styled/mixins';
import { Container, FONT_WEIGHT } from '../Common';

export const FOOTER_HEIGHT_DESKTOP = '5.5rem';
export const FOOTER_HEIGHT_MOBILE = '12.625rem';

export const Wrapper = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colors['pure-black']};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.footer};
`;

export const Content = styled(Container)`
  ${flexCenter}
  flex-direction: column;
  height: ${FOOTER_HEIGHT_MOBILE};

  ${breakpoint('xl')`
  	height: ${FOOTER_HEIGHT_DESKTOP};
  `}
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.5rem;

  ${breakpoint('xl')`
  	flex-direction: row;
	  justify-content: center;
	  gap: 1.5rem;
  `}
`;

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-family: 'GothamNarrow';
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.125rem; /* 128.571% */
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  .icon {
    display: inline-block;
    padding-right: 0.375rem;
  }
`;

export const LinkOneTrust = styled.button`
  color: ${({ theme }) => theme.colors.primary} !important;
  background: transparent !important;
  border: 0px !important;
  padding: 0 !important;

  text-align: center;
  font-family: 'GothamNarrow';
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.125rem; /* 128.571% */
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  .icon {
    display: inline-block;
    padding-right: 0.375rem;
  }
`;

export const Copyrights = styled.div`
  font-family: 'GothamNarrow';
  font-weight: ${FONT_WEIGHT.Regular};
  color: ${({ theme }) => theme.colors['primary-light']};
  font-size: 0.625rem;
  line-height: 0.875rem;
  text-align: center;
`;
