import { PropsWithChildren } from 'react';

import { RouteName, ROUTES } from '../../Constants/routes';
import { ObjectValues } from '../../utils/object-values';

export const LEGAL_LINK = {
  faqs: 'https://support.msgplus.tv/',
  pastTerms: ROUTES[RouteName.PastTermsOfUse],
  privacyCaResidents: `${ROUTES[RouteName.PrivacyPolicy]}?target=noticeCalifornia`,
  privacyChoices: ROUTES[RouteName.PrivacyChoices],
  privacyPolicy: ROUTES[RouteName.PrivacyPolicy],
  terms: ROUTES[RouteName.TermsOfUse],
} as const;

export type LegalLink = ObjectValues<typeof LEGAL_LINK>;

interface LegalLinkProps {
  className?: string;
  onClick?: () => void;
  to: LegalLink;
}

export const LegalLink = ({
  to,
  className,
  children,
  onClick,
}: LegalLinkProps & PropsWithChildren) => {
  return (
    <a onClick={onClick} href={to} target='_blank' className={className} rel='noreferrer'>
      {children}
    </a>
  );
};
