import { ITranslationData } from '../../../store/translations';
import { AddOrUpdateCouchRightsResponseMessage, FailureResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const addOrUpdateCouchRights = async ({
  deviceId,
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  evergentAddOurUpdateCouchRights,
  translations,
  currentLocationZone,
}: {
  deviceId: string;
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  evergentAddOurUpdateCouchRights: string;
  translations: ITranslationData;
  currentLocationZone: string;
}) => {
  try {
    const body = {
      AddOrUpdateCouchRightsRequestMessage: {
        apiKey: evergentApiKey,
        channelPartnerID: evergentChannelPartnerId,
        deviceID: deviceId,
        dmaID: currentLocationZone,
      },
    };

    const headers = {
      authorization: `Bearer ${accessToken}`,
    };

    const response = await evergentAxiosInstance.post<{
      AddOrUpdateCouchRightsResponseMessage: AddOrUpdateCouchRightsResponseMessage &
        FailureResponse;
    }>(evergentAddOurUpdateCouchRights, body, { headers });

    const { failureMessage = null, ...couchRightsResponse } =
      response.data.AddOrUpdateCouchRightsResponseMessage || {};

    if (failureMessage && failureMessage.length > 0) {
      throw new Error();
    }

    return couchRightsResponse;
  } catch (e: unknown) {
    throw new Error(translations.errorNoEntitledToPlay).message;
  }
};
