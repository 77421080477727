import styled from 'styled-components';

export const IconButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
`;
