import { remoteConfigApi } from '../../api/remoteConfigApi/remoteConfigApi';
import { RemoteConfigDataType } from '../../api/remoteConfigApi/remoteConfigApi.types';
import { Log } from '../../helpers/CustomLogger';
import { initialConfig } from './initialConfig';
import { ConfigUrls, urlsTemplates } from './urlsTemplates';

class ConfigManager {
  private static instance: ConfigManager | null = null;
  public env = initialConfig;
  public remoteConfig: RemoteConfigDataType | undefined;
  public remoteConfigFetchedTrial = false;
  public urls: ConfigUrls | undefined = undefined;
  private remoteConfigError = false;
  private remoteConfigFetching = false;

  private constructor() {
    this.fetchRemoteConfig();
  }

  public static getInstance(): ConfigManager {
    if (!ConfigManager.instance) {
      ConfigManager.instance = new ConfigManager();
    }
    return ConfigManager.instance;
  }

  public async fetchRemoteConfig(): Promise<void | undefined> {
    try {
      this.remoteConfigFetching = true;
      this.remoteConfig = await remoteConfigApi.getRemoteConfig();
      this.remoteConfigFetchedTrial = true;
      this.urls = this.remoteConfig && urlsTemplates(this.remoteConfig.urls);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.env = this.remoteConfig && {
        ...this.urls,
        ...this.remoteConfig.vars,
        ...this.remoteConfig.promotionConfiguration,
        FORCE_ZONE_KEY: localStorage.FORCE_ZONE_KEY,
        X_FORWARDED_FOR: localStorage.X_FORWARDED_FOR || process.env.REACT_APP_X_FORWARDED_FOR,
      };
      this.remoteConfigFetching = false;
    } catch (error) {
      Log.error(error);
      this.remoteConfigError = true;
      this.remoteConfigFetching = false;
      this.remoteConfigFetchedTrial = true;
    }
  }

  public isRemoteConfigError(): boolean {
    return this.remoteConfigError;
  }

  public isRemoteConfigFetching(): boolean {
    return this.remoteConfigFetching;
  }
}

const configManager = ConfigManager.getInstance();
export default configManager;
