import { Nav as BtsrNav, Navbar as BtsrNavbar } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { flexCenter } from '../../utils/styled/mixins';
import { Container } from '../Common';
import { NavigationItem } from './components/NavigationItem/NavigationItem';

export const NAVIGATION_HEIGHT = 80;

export const Navbar = styled(BtsrNavbar).attrs({
  expand: 'sm',
})`
  padding: 0;
  z-index: ${({ theme }) => theme.zIndex.navigation};
  position: fixed;
  height: ${NAVIGATION_HEIGHT}px;
  width: 100%;
`;

export const LogoPlusItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
`;

export const Nav = styled(BtsrNav)`
  width: 100%;
  background: ${({ theme }) => theme.colors.black};
  height: ${NAVIGATION_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['scrim-white']} !important;
`;

export const NavContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Logo = styled.span``;

export const LogoImg = styled.img`
  width: 3.75rem;
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 7px);

  ${breakpoint('lg')`
      width: 5rem;
      position: relative;
      left: unset;
      top: unset;
  `}
`;

export const ItemsWrapper = styled.div`
  display: none;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;

  ${breakpoint('lg')`
    display: flex;
  `}
`;

export const Settings = styled(BtsrNav.Item)`
  display: none;
  ${breakpoint('lg')`
    display: block
  `}

  a.active path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const SettingsPlusProviderWrapper = styled.div`
  ${flexCenter}
  gap: 3rem;

  position: absolute;
  right: 1rem;

  ${breakpoint('lg')`
      position: relative;
      right: unset;
  `}
`;

export const SettingsIconWrapper = styled.div<{
  $isSelected: boolean;
}>`
  ${(props) =>
    props.$isSelected &&
    breakpoint('lg')`
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    margin-top: 0.375rem;
    padding-bottom: 0.375rem;
  `}
`;

export const SettingsButton = styled(NavigationItem)`
  padding: 0;
`;
