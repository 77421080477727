import {
  Analytics,
  logEvent as firebaseLogEvent,
  getAnalytics,
  isSupported,
} from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { FirebasePerformance, getPerformance } from 'firebase/performance';

import { getEnv } from '../env';

const env = getEnv();
const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  appId: env.FIREBASE_APP_ID,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  measurementId: env.FIREBASE_MEASUREMENT_ID,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
};

let app: FirebaseApp | null = null;
export let analytics: Analytics | null = null;
export let perf: FirebasePerformance | null = null;

isSupported().then((result) => {
  if (result) {
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    perf = getPerformance(app);
  }
});

export type EventData = Record<string, unknown>;

export const logEvent = (eventName: string, eventParams?: EventData) => {
  if (env.FIREBASE_ENABLED) {
    firebaseLogEvent(analytics as Analytics, eventName, eventParams);
  }
};

export default app;
