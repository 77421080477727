import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { DebugState } from './debug.types';

export const debugSelectors = (state: RootState): DebugState => state.debug;

export const enhancedMParticleLoggingSelector = createSelector(
  debugSelectors,
  ({ enhancedMParticleLogging }): boolean => enhancedMParticleLogging,
);
