import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { Video } from '../../api';
import { ProgramsState } from './programs.types';

export const initialState: ProgramsState = {
  error: '',
  loading: false,
  programsMap: {},
};

export const loadProgramsAction = createAction('programs/loadPrograms');

const slice = createSlice({
  initialState: initialState,
  name: 'programs',
  reducers: {
    loadProgramsError: (state, action: PayloadAction<string>): ProgramsState => ({
      error: action.payload,
      loading: false,
      programsMap: {},
    }),
    loadProgramsLoading: (state): ProgramsState => ({
      ...state,
      loading: true,
    }),
    loadProgramsSuccess: (
      state,
      action: PayloadAction<{ [key: string]: Video }>,
    ): ProgramsState => ({
      ...state,
      error: '',
      loading: false,
      programsMap: {
        ...state.programsMap,
        ...action.payload,
      },
    }),
    resetProgramState: (): ProgramsState => ({
      ...initialState,
      programsMap: {},
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as programsActions, name, reducer };
