import styled, { css } from 'styled-components';

interface SliderProps {
  $value: number;
}

export const VolumeButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const sliderThumb = css`
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.colors['pure-white']};
  border-radius: 50%;
`;

const inputPseudoclass = css`
  content: '';
  position: absolute;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  z-index: -1;
  border-radius: 2px;
`;

export const Slider = styled.input<SliderProps>`
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 7.875rem;
  height: 0.25rem;
  position: relative;
  background: transparent;

  &::before {
    ${inputPseudoclass}
    left: 0;
    width: ${(props) => props.$value}%;
  }

  &::after {
    ${inputPseudoclass}
    left: ${(props) => props.$value}%;
    width: ${(props) => 100 - props.$value}%;
    opacity: 0.28;
  }

  &::-moz-range-track {
    width: 7.875rem;
    height: 0.25rem;
  }

  /* Target the range thumb (dot) */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${sliderThumb}
  }

  /* Firefox */
  &::-moz-range-thumb {
    ${sliderThumb}
    border-color: transparent;
  }

  /* Edge and IE */
  &::-ms-thumb {
    ${sliderThumb}
  }
`;
