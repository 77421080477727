/* eslint-disable camelcase */
import mParticle from '@mparticle/web-sdk';

import { logEvent } from '../../../../firebase/firebase';
import { mParticleApi } from '../../mParticlesApi';
import { EventName } from '../../constants';
import { EventOptions } from '../../../../store/auth';

export const loginEvent = ({ email }: EventOptions) => {
  mParticleApi.triggerEvent(EventName.SignedIn, mParticle.EventType.UserContent, {
    email,
    event_origin: 'client',
    method: 'SSO',
  });
};

export const sessionStarted = (eventOptions: mParticle.SDKEventAttrs = {}) => {
  mParticleApi.triggerEvent(
    EventName.SessionStarted,
    mParticle.EventType.UserContent,
    eventOptions,
  );
  logEvent('session_start');
};

export const signUpEvent = (eventOptions: EventOptions) => {
  mParticleApi.triggerEvent(EventName.SignedUp, mParticle.EventType.UserContent, {
    created_at: new Date().toISOString(),
    event_origin: 'client',
    ...eventOptions,
  });
};

export const favoriteTeamsSelected = (selectedTeams: string[]) => {
  const selectedTeamsString = selectedTeams.filter((team) => team).join(',');
  mParticleApi.setUserDataList({ key: 'favorite_teams', value: selectedTeams });
  mParticleApi.triggerEvent(EventName.FavoriteTeamsSelected, mParticle.EventType.UserPreference, {
    change_type: 'favorite teams',
    teams: selectedTeamsString,
  });
};
