/* eslint-disable camelcase */
import mParticle from '@mparticle/web-sdk';

import { logEvent } from '../../../../firebase/firebase';
import { mParticleApi } from '../../mParticlesApi';
import { EventName } from '../../constants';

export type PageViewAttributes = {
  path: string;
  referrer: string;
  screen: string;
  search: string;
  url: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_term?: string | null;
  utm_content?: string | null;
};

export type PageViewEventProps = {
  attributes: PageViewAttributes;
  flags?: mParticle.SDKEventCustomFlags;
};

export type ButtonClickedAttributes = {
  path: string;
  url: string;
  destination?: string;
  location?: string;
  text: string;
  screen: string;
  type: string;
};

export const logPageView = (params: PageViewEventProps) => {
  mParticleApi.pageViewEvent(
    EventName.ScreenViewed,
    {
      ...params.attributes,
      browser: navigator.userAgent,
    },
    params.flags,
  );

  logEvent('page_view', {
    page_path: params.attributes.path,
    page_title: params.attributes.screen,
  });
};

export const buttonClicked = (params: ButtonClickedAttributes) => {
  mParticleApi.triggerEvent(EventName.ButtonClicked, mParticle.EventType.Other, params);
  logEvent('user_engagement', params);
};
