import { FailureResponse, GetCouchRightsResponseMessage } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getCouchRights = async ({
  deviceId,
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  evergentCouchRights,
}: {
  deviceId: string;
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  evergentCouchRights: string;
}) => {
  const body = {
    GetCouchRightsRequestMessage: {
      apiKey: evergentApiKey,
      channelPartnerID: evergentChannelPartnerId,
      deviceID: deviceId,
    },
  };

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  const response = await evergentAxiosInstance.post<{
    GetCouchRightsResponseMessage: GetCouchRightsResponseMessage & FailureResponse;
  }>(evergentCouchRights, body, { headers });
  const { failureMessage = null, ...couchRightsResponse } =
    response.data.GetCouchRightsResponseMessage || {};

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(`${failureMessage[0].errorCode}|${failureMessage[0].errorMessage}`);
  }

  return couchRightsResponse;
};
