import styled from 'styled-components';

export const Wrapper = styled.div`
  iframe {
    height: 1000px;
  }

  @media screen and (min-width: 400px) {
    iframe {
      height: 800px;
    }
  }

  @media screen and (min-width: 600px) {
    iframe {
      height: 633px;
    }
  }

  @media screen and (min-width: 770px) {
    iframe {
      height: 553px;
    }
  }

  @media screen and (min-width: 992px) {
    iframe {
      height: 485px;
    }
  }
`;
