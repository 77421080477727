import { SVGProps } from 'react';

export const PauseBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='88'
    height='88'
    viewBox='0 0 88 88'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.7775 12.2227C33.1275 12.2227 34.2219 13.3171 34.2219 14.6671L34.2219 70.8893C34.2219 72.2394 33.1275 73.3338 31.7775 73.3338C30.4274 73.3338 29.333 72.2394 29.333 70.8893L29.333 14.6671C29.333 13.3171 30.4274 12.2227 31.7775 12.2227Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.6661 12.2227C60.0162 12.2227 61.1106 13.3171 61.1106 14.6671L61.1106 70.8893C61.1106 72.2394 60.0162 73.3338 58.6661 73.3338C57.3161 73.3338 56.2217 72.2394 56.2217 70.8893L56.2217 14.6671C56.2217 13.3171 57.3161 12.2227 58.6661 12.2227Z'
      fill='white'
    />
  </svg>
);
