import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useFlowManager } from './useFlowManager';

export const useCheckFlow = () => {
  const location = useLocation();
  const { isFlowInitialized, updateFlowManager } = useFlowManager();

  useEffect(() => {
    if (!isFlowInitialized()) return;

    updateFlowManager(location.pathname);
  }, [location]);
};
