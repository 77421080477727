import { createSelector } from '@reduxjs/toolkit';

import { AvailablePaymentOptions } from '../../components/PaymentOptions';
import { RootState } from '../types';
import { PaymentOption } from './purchase.types';

export const purchaseSelector = (state: RootState) => state.purchase;

export const selectedPaymentOptionSelector = createSelector(
  purchaseSelector,
  ({ selectedPaymentOption }) => selectedPaymentOption,
);

export const doesSelectedPaymentOptionRequiresLegalTextSelector = createSelector(
  selectedPaymentOptionSelector,
  (selectedPayment) =>
    [AvailablePaymentOptions.PAYPAL, AvailablePaymentOptions.APPLE_PAY].includes(
      selectedPayment as PaymentOption,
    ),
);

export const paymentRequestCompleteSelector = createSelector(
  purchaseSelector,
  ({ paymentRequestComplete }) => paymentRequestComplete,
);

export const paymentRequestPendingSelector = createSelector(
  purchaseSelector,
  ({ paymentRequestPending }) => paymentRequestPending,
);
export const paymentErrorSelector = createSelector(purchaseSelector, ({ error }) => error);

export const payPalOnApproveDataSelector = createSelector(
  purchaseSelector,
  ({ payPalOnApproveData }) => payPalOnApproveData,
);
export const isPurchaseLoadingSelector = createSelector(purchaseSelector, ({ loading }) => loading);

export const availablePaymentOptionsSelector = createSelector(
  purchaseSelector,
  ({ availablePaymentOptions }) => availablePaymentOptions,
);

export const purchaseItemSelector = createSelector(purchaseSelector, ({ item }) => item);
