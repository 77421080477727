import { getEnv } from '../../../env';
import { AddSubscriptionRequestMessage } from '../../../store/purchase';
import { AddSubscriptionsResponseMessage } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const addSubscriptions = async (
  addSubscriptionRequestMessage: AddSubscriptionRequestMessage,
): Promise<Partial<AddSubscriptionsResponseMessage>> => {
  const { ADD_SUBSCRIPTIONS_URL, EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID } = getEnv();
  const { accessToken, ...payload } = addSubscriptionRequestMessage;
  const requestData = {
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
    ...payload,
  };

  const body = { AddSubscriptionRequestMessage: requestData };

  const addSubscriptionsResponse = await evergentAxiosInstance.post<{
    AddSubscriptionsResponseMessage: AddSubscriptionsResponseMessage;
  }>(ADD_SUBSCRIPTIONS_URL, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { failureMessage, ...dataResponse } =
    addSubscriptionsResponse.data.AddSubscriptionsResponseMessage;

  if (failureMessage && failureMessage.length > 0) throw new Error(failureMessage[0].errorMessage);

  return dataResponse;
};
