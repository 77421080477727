/* eslint-disable @typescript-eslint/no-explicit-any */
import { getApplicationSession } from '@msgn/fl-module/fl-analytics';
import { createContext, useContext } from 'react';

type UserSubscription = {
  id?: string;
  status?: string;
  plan?: string;
  planID?: string;
};

type UserInfo = {
  type: string;
  id?: string;
  subscription?: UserSubscription;
};

export type DataZoomContextValue = {
  appSession: ReturnType<typeof getApplicationSession>;
  userSession?: any;
  playbackSession?: any;
  setPlaybackSession: (playbackSession: any) => void;
  userInfo?: UserInfo | null;
};

export const DataZoomContext = createContext<DataZoomContextValue>({} as DataZoomContextValue);

export const useDataZoomContext = () => useContext(DataZoomContext);
