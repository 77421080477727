import { SVGProps } from 'react';

export const Replay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ?? '90'}
    height={props?.height ?? '88'}
    viewBox='0 0 90 88'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M56.1255 12.8328C49.9405 11.6066 43.5295 12.2359 37.7033 14.6411C31.8771 17.0463 26.8973 21.1193 23.3938 26.3452C19.8902 31.571 18.0202 37.7149 18.0202 44C18.0202 45.35 19.1183 46.4444 20.4729 46.4444C21.8274 46.4444 22.9255 45.35 22.9255 44C22.9255 38.6818 24.5079 33.4832 27.4724 29.0613C30.437 24.6394 34.6506 21.193 39.5805 19.1579C44.5103 17.1227 49.935 16.5902 55.1685 17.6277C60.4021 18.6652 65.2094 21.2262 68.9825 24.9866C72.7557 28.7471 75.3252 33.5383 76.3662 38.7542C77.4073 43.9701 76.873 49.3766 74.831 54.2899C72.7889 59.2032 69.3309 63.4027 64.8941 66.3573C60.4574 69.3119 55.2411 70.8889 49.9051 70.8889C48.5505 70.8889 47.4524 71.9833 47.4524 73.3333C47.4524 74.6833 48.5505 75.7777 49.9051 75.7777C56.2113 75.7777 62.376 73.914 67.6194 70.4222C72.8629 66.9304 76.9496 61.9674 79.3629 56.1608C81.7762 50.3542 82.4077 43.9647 81.1774 37.8004C79.9471 31.6361 76.9103 25.9739 72.4511 21.5297C67.992 17.0855 62.3106 14.0589 56.1255 12.8328Z'
      fill={props.fill ?? '#ffffff'}
    />

    <path
      d='M32.3629 37.9776C31.6351 36.839 30.1189 36.504 28.9765 37.2294L20.2436 42.7742L14.6801 34.0706C13.9523 32.932 12.4361 32.597 11.2937 33.3224C10.1512 34.0478 9.81511 35.5588 10.5429 36.6974L18.7421 49.5242L31.6121 41.3526C32.7546 40.6273 33.0907 39.1162 32.3629 37.9776Z'
      fill={props.fill ?? '#ffffff'}
    />
  </svg>
);
