import { useCallback, useEffect, useMemo, useState } from 'react';

import { USER_INTERACTED, VIDEO_VOLUME, cacheManager } from '../../utils';

export type VolumeControl = {
  adjustVolume: (volume: number) => void;
  getVolume: () => number;
  canAdjustVolume: () => boolean;
};

export const useFLPLocalVolumeControl = ({
  ref,
}: {
  ref: React.RefObject<HTMLVideoElement>;
}): { volumeControl: VolumeControl; volume: number } => {
  const [volume, setVolume] = useState(0);

  const getInitVolume = useCallback(() => {
    if (cacheManager.load<boolean>(USER_INTERACTED) !== true) {
      return 0;
    }

    const storedVolume = cacheManager.load<number>(VIDEO_VOLUME);

    if (typeof storedVolume === 'number') {
      if (storedVolume <= 1 && storedVolume >= 0) {
        return storedVolume;
      }
    }

    return 0;
  }, []);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const initVolume = getInitVolume();
    cacheManager.save(VIDEO_VOLUME, initVolume);
    ref.current.volume = initVolume;
    setVolume(initVolume * 100);
  }, [ref?.current]);

  const adjustVolume = useCallback((volume: number) => {
    if (ref.current) {
      const newVolume = +(volume / 100).toFixed(2);

      ref.current.volume = newVolume;

      cacheManager.save(VIDEO_VOLUME, newVolume);
      setVolume(volume);
    }
  }, []);

  const getVolume = useCallback(() => {
    if (!ref.current) {
      return 0;
    }

    return (ref.current.volume ?? 0) * 100;
  }, []);

  const canAdjustVolume = useCallback(() => true, []);

  const volumeControl: VolumeControl = useMemo(
    () => ({ adjustVolume, canAdjustVolume, getVolume }),
    [adjustVolume, getVolume, canAdjustVolume],
  );

  return useMemo(() => {
    return {
      volume,
      volumeControl,
    };
  }, [volume, volumeControl]);
};
