import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import cacheManager from '../../utils/cache/buildCacheManager';
import { useUpdateSearchParams } from './useUpdateSearchParams';

export const DATE_SEARCH_PARAM_CACHE_KEY = 'DATE_SEARCH_PARAM_CACHE_KEY';

export const useDateSearchParam = ({
  searchParamName = 'date',
  cache,
}: {
  searchParamName?: string;
  cache?: boolean;
}) => {
  const [searchDate, updateSearchParams] = useUpdateSearchParams({ searchParamName });

  const initialDate = useMemo(() => {
    const cacheDate = cache ? cacheManager.load<string>(DATE_SEARCH_PARAM_CACHE_KEY) : null;

    const selectedDate = searchDate || cacheDate;

    return selectedDate;
  }, [cache, searchDate]);

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const selectDate = useCallback((selectedDate?: Date) => {
    const finalDate = selectedDate || new Date();
    const formattedDate = format(finalDate, 'yyyy-MM-dd');

    setSelectedDate(formattedDate);
  }, []);

  useEffect(() => {
    if (cache) {
      if (selectedDate) {
        cacheManager.save(DATE_SEARCH_PARAM_CACHE_KEY, selectedDate);
      } else {
        cacheManager.remove(DATE_SEARCH_PARAM_CACHE_KEY);
      }
    }
    updateSearchParams(selectedDate);
  }, [selectedDate]);

  return { selectDate, selectedDate };
};
