import { FailureResponse, RemoveSubscriptionResponse, ServiceType } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const removeSubscription = async ({
  accessToken,
  serviceID,
  evergentApiKey,
  evergentChannelPartnerId,
  removeSubscriptionUrl,
}: {
  accessToken: string;
  serviceID: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  removeSubscriptionUrl: string;
}): Promise<RemoveSubscriptionResponse> => {
  const removeSubscriptionData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    serviceID,
    serviceType: ServiceType.Product.toUpperCase(),
  };

  const body = { RemoveSubscriptionRequestMessage: removeSubscriptionData };

  const signUpResponse = await evergentAxiosInstance.post<{
    RemoveSubscriptionResponseMessage: RemoveSubscriptionResponse & FailureResponse;
  }>(removeSubscriptionUrl, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = signUpResponse.data.RemoveSubscriptionResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
