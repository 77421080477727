import { Video, quickplayCmsApi } from '../../../api';
import { AllVideosInfo, Container } from '../videos.types';

export const getVideosFromPromise = async (
  allVideosInfo: AllVideosInfo[] = [],
  forceZoneKey: string,
  xClientId: string,
) => {
  try {
    const result = await Promise.all(
      allVideosInfo.map(async (info) => {
        const url = info.url + (info.q ? `&q=${encodeURI(info.q)}` : '');
        return await quickplayCmsApi.getAllVideos({ forceZoneKey, url, xClientId });
      }),
    );

    return result.flat();
  } catch (err) {
    return [];
  }
};

export const updateCarouselWithAllVideos = (
  carousels: Container[],
  containerId: string,
  allVideos: Video[] = [],
): Container[] =>
  carousels.map((item) => {
    if (item.id === containerId) {
      return {
        ...item,
        allVideos,
      };
    }

    return item;
  });
