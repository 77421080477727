import { useMemo } from 'react';

import { Log } from '../../helpers/CustomLogger';
import { COMPANION_ADS_DEBUG_VIEW_ENABLED_KEY } from '../DebugSettings/CompanionAds/CompanionAdsSetings';
import { CompanionAdImage } from './CompanionAdImage/CompanionAdImage';
import { getAdImageData } from './CompanionAds.utils';
import { useCompanionAdImageSize } from './hooks/useCompanionAdImageSize';
import { useCompanionAds } from './hooks/useCompanionAds';

type CompanionAdsProps = {
  areStatsVisible: boolean;
};

const CompanionAds = ({ areStatsVisible }: CompanionAdsProps) => {
  const companionAdImageSize = useCompanionAdImageSize(areStatsVisible);
  const isDebugViewEnabled = localStorage.getItem(COMPANION_ADS_DEBUG_VIEW_ENABLED_KEY) === 'true';
  const { mappedAds, adToShowIndex, onImgLoad } = useCompanionAds({ isDebugViewEnabled });

  const imageToShow = useMemo(() => {
    if (
      !mappedAds ||
      mappedAds.length === 0 ||
      mappedAds?.[adToShowIndex]?.companionAds?.length === 0
    ) {
      return undefined;
    }
    Log.log('imageToShow', mappedAds, adToShowIndex, companionAdImageSize);

    return getAdImageData(
      mappedAds?.[adToShowIndex]?.companionAds?.[0].companion,
      companionAdImageSize,
    );
  }, [mappedAds, adToShowIndex, companionAdImageSize]);

  const adToShow = useMemo(() => {
    return mappedAds[adToShowIndex];
  }, [mappedAds, adToShowIndex]);

  return (
    <CompanionAdImage
      adToShow={adToShow}
      onImgLoad={onImgLoad}
      adToShowIndex={adToShowIndex}
      imageToShow={imageToShow}
      mappedAds={mappedAds}
      isDebugViewEnabled={isDebugViewEnabled}
    />
  );
};

export { CompanionAds };
