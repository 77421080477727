interface StatsUrlParams {
  url: string;
  deviceId: string;
  team: string;
  league: string;
  distinctId?: string;
}

export const prepareStatsUrl = (params: StatsUrlParams) => {
  const { deviceId, league, team, distinctId, url } = params;

  if (!url) {
    return '';
  }

  let replacedUrl = url
    .replace(/\$DEVICE_ID\$/g, deviceId)
    .replace(/\$LEAGUE\$/g, league)
    .replace(/\$TEAM\$/g, team);

  if (distinctId) {
    replacedUrl = replacedUrl.replace(/\$IFA\$/g, distinctId);
  }

  return replacedUrl;
};
