import { useEffect } from 'react';

import { getEntryPage } from '../../helpers/getEntryPage';
import { AllFlowsMap } from '../flow.types';
import flowManager from '../flowManager';

export const useInitFlowManager = (allFlows: AllFlowsMap) => {
  useEffect(() => {
    flowManager.init(getEntryPage(), allFlows);
  }, []);
};
