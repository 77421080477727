import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { SchemaOf } from 'yup';

import { useForm } from '../../hooks/useForm';
import { useTranslations } from '../../hooks/useTranslations';
import { paymentRequestPendingSelector } from '../../store/purchase';
import { PayPalInfoFormFields, payPalInfoSchema } from '../../utils/validations';
import { ErrorContentWrapper, ErrorLine } from '../AuthForm/AuthForm.styles';
import { ButtonVariant } from '../Button/Button.types';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { Input } from '../atoms';
import * as Ui from './PayPalInfoForm.styles';

export type PayPalInfoFormValues = {
  [PayPalInfoFormFields.Email]: string;
};

type TPayPalInfoForm = {
  amount?: number;
  disabled: boolean;
  onSuccess: (formValues: PayPalInfoFormValues) => void;
};

const PayPalInfoForm = ({ amount, disabled, onSuccess }: TPayPalInfoForm) => {
  const t = useTranslations();
  const { register, handleSubmit, formState } = useForm<PayPalInfoFormValues>({
    mode: 'onTouched',
    resolver: yupResolver(payPalInfoSchema as SchemaOf<PayPalInfoFormValues>),
  });
  const errors = Object.values(formState.errors).map(({ message }) => message);
  const paymentRequestPending = useSelector(paymentRequestPendingSelector);

  const handleFormSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleSubmit(onSuccess)();
  };

  return (
    <>
      <form>
        <Input
          type='email'
          placeholder={t.paypal_info_email_input}
          {...register(PayPalInfoFormFields.Email, { setValueAs: (value) => value.trim() })}
        />

        {!!errors.length && (
          <ErrorContentWrapper>
            {errors.map((err) => (
              <ErrorLine key={err}>{err}</ErrorLine>
            ))}
          </ErrorContentWrapper>
        )}
      </form>
      <Ui.ButtonRow $withSpace>
        <Ui.ButtonRowDescription>
          {t.paypal_charge_info} <Ui.BoldSpan>${amount}</Ui.BoldSpan>
        </Ui.ButtonRowDescription>
        <ClickEventDispatcher
          location='Module: PayPal Payment Form'
          screen='Paypal payment Form Modal'
          text={`CTA: ${t.paypal_info_button}`}
        >
          <Ui.Button
            variant={ButtonVariant.Primary}
            onPress={handleFormSubmit}
            disabled={disabled}
            loading={paymentRequestPending}
          >
            {t.paypal_info_button}
          </Ui.Button>
        </ClickEventDispatcher>
      </Ui.ButtonRow>
    </>
  );
};

export { PayPalInfoForm };
