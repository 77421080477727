import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '../../hooks/useTranslations';
import { authSelector } from '../../store/auth';
import { toastActions } from '../../store/toast';

const LogoutToast: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const { loggedOut, loggedOutAllDevices } = useSelector(authSelector);
  const t = useTranslations();

  useEffect(() => {
    if (loggedOutAllDevices) {
      dispatch(
        toastActions.showToast({
          message: t.logoutToast_loggedOutAllDevices,
          type: 'success',
        }),
      );
    }
    if (loggedOut) {
      dispatch(
        toastActions.showToast({
          message: t.logoutToast_loggedOut,
          type: 'success',
        }),
      );
    }
  }, [loggedOut, loggedOutAllDevices]);

  return <>{children}</>;
};

export default LogoutToast;
