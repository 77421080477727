import styled, { css } from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { TypographyProps, fonts, textBase, uppercase } from '../../Common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.colors['grey-soft']};
  justify-content: space-between;
  gap: 1rem;

  ${breakpoint('lg')`
    padding-top: 1.5rem;
    flex-direction: column;
    border: none;
    gap: 0;
  `}
`;

export const TeamsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${breakpoint('lg')` 
    margin-bottom: 1.5rem;
  `}
`;

export const TeamShieldWrapper = styled.div`
  padding: 0;
  width: auto;

  ${breakpoint('lg')`
    width: 20%;
  `}

  img {
    width: 2.125rem;
    height: auto;

    ${breakpoint('lg')`
      width: 100%;
    `}
  }
`;

export const TeamSpan = styled.span<TypographyProps>`
  ${(props) => textBase(props)};

  ${fonts.body2.xs}
  ${uppercase}
  margin: 0 0.5rem;

  ${breakpoint('lg')` 
    margin: 0 1.5rem;
  `}
`;

const boldText = (props: TypographyProps) => css`
  ${textBase(props)};

  ${fonts.body1.xs}
  font-weight: 900;
`;

const normalText = (props: TypographyProps) => css`
  ${textBase(props)};

  ${fonts.body2.xs}
`;

export const Title = styled.div<TypographyProps>`
  ${(props) => boldText(props)};

  margin-bottom: 0.25rem;

  ${breakpoint('lg')`
    margin-bottom: 1rem;
  `}
`;

export const Description = styled.div<TypographyProps>`
  ${normalText};
  margin-bottom: 0.25rem;

  ${breakpoint('lg')`
    margin-bottom: 1rem;
  `}
`;

export const EventDateLabel = styled.div<TypographyProps>`
  ${(props) => boldText(props)};
`;
export const EventDateSpan = styled.span<TypographyProps>`
  ${(props) => normalText(props)};
`;
