import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RegisterDeviceResponse } from '../../api/authApi/authApi.types';
import { RegisterDeviceState } from './registerDevice.types';

export const registerDeviceAction = createAction('registerDevice/load');

export const initialState: RegisterDeviceState = {
  error: null,
  loaded: false,
  loading: false,
  message: '',
};

const slice = createSlice({
  initialState,
  name: 'registerDevice',
  reducers: {
    registerDeviceError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loaded: false,
      loading: false,
    }),
    registerDeviceSuccess: (state, action: PayloadAction<RegisterDeviceResponse>) => ({
      ...state,
      ...action.payload,
      error: null,
      loaded: true,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as registerDeviceActions, name, reducer };
