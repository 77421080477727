import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { TeamData } from '../../api';
import { TeamState } from './teams.types';

export const initialState: TeamState = {
  loading: true,
  teams: [],
};

export const loadTeamsAction = createAction('schedule/loadTeams');

const slice = createSlice({
  initialState: initialState,
  name: 'teams',
  reducers: {
    loadTeamsError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    loadTeamsLoading: (state) => ({
      ...state,
      error: undefined,
      loading: true,
    }),
    loadTeamsSuccess: (state, action: PayloadAction<TeamData[]>) => ({
      ...state,
      error: undefined,
      loading: false,
      teams: action.payload,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as teamsActions, name, reducer };
