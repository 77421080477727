import { VolumeDown } from '../../../assets/icons/VolumeDown';
import { VolumeUp } from '../../../assets/icons/VolumeUp';
import { useTranslations } from '../../../hooks/useTranslations';
import * as PlayerUi from '../PlayerUi.styles';
import { useVolumeControl } from '../hooks/useVolumeControl';
import * as Ui from './VolumeButton.styles';

export default function VolumeButton() {
  const { handleVolumeMax, handleVolumeMin, handleChange, volume } = useVolumeControl();
  const t = useTranslations();

  return (
    <Ui.VolumeButtonWrapper>
      <PlayerUi.PlayerButton aria-label={t.volumeMinimum} onClick={handleVolumeMin}>
        <VolumeDown />
      </PlayerUi.PlayerButton>

      <Ui.Slider
        aria-label={t.volume}
        value={volume}
        onChange={(e) => handleChange(Number(e.target.value))}
        max={100}
        min={0}
        step={0.1}
        type={'range'}
        $value={volume}
      />

      <PlayerUi.PlayerButton aria-label={t.volumeMaximum} onClick={handleVolumeMax}>
        <VolumeUp />
      </PlayerUi.PlayerButton>
    </Ui.VolumeButtonWrapper>
  );
}
