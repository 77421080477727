/* eslint-disable camelcase */
import json from '../../../package.json';
import { Video } from '../../api';
import { UserInfoType } from '../../hooks/userDataZoom.types';

export const prepareFlAnalyticsData = (selectedVideo: Video, userInfo?: UserInfoType | null) => {
  return {
    app_version: json.version,
    container_id: undefined,
    container_name: undefined,
    content_name: selectedVideo.title,
    episode_number: undefined,
    game_external_id: selectedVideo?.gameId,
    game_id: selectedVideo?.gameId,
    genre: selectedVideo?.genre,
    league_external_id: selectedVideo.rawData?.spt_lg,
    league_id: undefined,
    league_name: selectedVideo?.league,
    league_short_name: selectedVideo.rawData?.spt_lg,
    platform_type: 'web',
    playback_source: undefined,
    provider_id: selectedVideo.rawData?.pn,
    season_number: undefined,
    series_id: undefined,
    series_name: undefined,
    sport: selectedVideo.rawData?.spt_ty,
    subscription_plan: userInfo?.subscription?.plan,
    subscription_plan_id: userInfo?.subscription?.planId,
    team1_external_id: selectedVideo.homeTeam?.id,
    team1_id: selectedVideo.homeTeam?.id,
    team1_name: selectedVideo.homeTeam?.fullName,
    team1_short_name: selectedVideo.homeTeam?.name,
    team2_external_id: selectedVideo.visitorTeam?.id,
    team2_id: selectedVideo.visitorTeam?.id,
    team2_name: selectedVideo.visitorTeam?.fullName,
    team2_short_name: selectedVideo.visitorTeam?.shortName,
    user_id: userInfo?.id,
    user_subscription_id: userInfo?.subscription?.id,
    user_subscription_status: userInfo?.subscription?.status,
    user_type: userInfo?.type,
    venue_city: selectedVideo?.venueCity,
    venue_country: undefined,
    venue_name: undefined,
  };
};
