/* eslint-disable no-console */

export class CustomLogger {
  private isLoggerEnabled: boolean;

  constructor(isLoggerEnabled: boolean) {
    this.isLoggerEnabled = isLoggerEnabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(message?: any, ...optionalParams: any[]) {
    if (this.isLoggerEnabled) {
      console.log(message, ...optionalParams);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message?: any, ...optionalParams: any[]) {
    if (this.isLoggerEnabled) {
      console.error(message, ...optionalParams);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message?: any, ...optionalParams: any[]) {
    if (this.isLoggerEnabled) {
      console.warn(message, ...optionalParams);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(message?: any, ...optionalParams: any[]) {
    if (this.isLoggerEnabled) {
      console.info(message, ...optionalParams);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message?: any, ...optionalParams: any[]) {
    if (this.isLoggerEnabled) {
      console.debug(message, ...optionalParams);
    }
  }
}

const Log = new CustomLogger(String(process.env.REACT_APP_ENV || '').trim() !== 'prod');

export { Log };
