import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { cacheManager } from '../../utils';
import { AUTH_SESSION_STORAGE_KEY } from './auth.constants';
import { SessionState, SessionType } from './auth.types';

const login = createAction('auth/login');
const logout = createAction('auth/logout');
const noSessionState: SessionState = {
  accessToken: '',
  accountRole: '',
  authed: false,
  error: '',
  errorCode: '',
  errorLogin: '',
  errorLogout: '',
  expiresIn: '',
  isQAUser: false,
  isVIPUser: false,
  loading: false,
  loggedOut: false,
  loggedOutAllDevices: false,
  logoutUser: false,
  refreshToken: '',
  successSignup: false,
  tveToken: null,
};

const cachedSession = cacheManager.load<SessionType>(AUTH_SESSION_STORAGE_KEY);

export const initialState: SessionState = {
  ...noSessionState,
  ...cachedSession,
  authed: !!cachedSession?.accessToken,
};

const slice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    anonymizeAccountSuccess: (state): SessionState => ({
      ...state,
      ...noSessionState,
      authed: false,
      loading: false,
    }),
    authCompleted: (state: SessionState): SessionState => ({
      ...state,
      authed: true,
    }),
    cleanUpErrorLogin: (state) => ({
      ...state,
      errorLogin: '',
    }),
    cleanUpErrorLogout: (state) => ({
      ...state,
      errorLogout: '',
    }),
    cleanUpErrorSignUp: (state) => ({
      ...state,
      error: '',
    }),
    loginError: (
      state,
      action: PayloadAction<{ message: string; code: string }>,
    ): SessionState => ({
      ...state,
      errorCode: action.payload.code,
      errorLogin: action.payload.message,
      loading: false,
      loggedOut: false,
      loggedOutAllDevices: false,
    }),
    loginSetLoading: (state, action: PayloadAction<boolean>): SessionState => ({
      ...state,
      loading: action.payload,
      loggedOut: false,
      loggedOutAllDevices: false,
    }),
    loginSuccess: (state: SessionState, action: PayloadAction<SessionType>): SessionState => ({
      ...state,
      ...action.payload,
      loading: false,
      loggedOut: false,
      loggedOutAllDevices: false,
    }),
    logoutAllDevicesSuccess: (state): SessionState => ({
      ...state,
      ...noSessionState,
      loggedOutAllDevices: true,
    }),
    logoutError: (state, action: PayloadAction<string>): SessionState => ({
      ...state,
      ...noSessionState,
      error: action.payload,
      errorLogout: action.payload,
      loading: false,
      loggedOut: false,
    }),
    logoutLoading: (state): SessionState => ({
      ...state,
      error: '',
      loading: true,
      loggedOut: false,
    }),
    logoutSuccess: (state): SessionState => ({
      ...state,
      ...noSessionState,
      authed: false,
      loading: false,
      loggedOut: true,
    }),
    setLogoutUser: (state: SessionState, action: PayloadAction<boolean>) => ({
      ...state,
      logoutUser: action.payload,
    }),
    setTveToken: (state: SessionState, action: PayloadAction<string | null>): SessionState => ({
      ...state,
      tveToken: action.payload,
    }),
    signUpError: (state, action: PayloadAction<string>): SessionState => ({
      ...state,
      error: action.payload,
      loading: false,
      loggedOut: false,
      loggedOutAllDevices: false,
    }),
    signUpSuccess: (state: SessionState, action: PayloadAction<SessionType>): SessionState => ({
      ...state,
      ...action.payload,
      loading: false,
      loggedOut: false,
      loggedOutAllDevices: false,
      successSignup: true,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions, name, reducer, login, logout };
