import Spinner from 'react-bootstrap/Spinner';

import { useTranslations } from '../../hooks/useTranslations';

export const Loading = ({ size }: { size?: 'sm' }) => {
  const t = useTranslations();

  return (
    <Spinner animation='border' role='status' size={size}>
      <span className='visually-hidden'>{t.loading_message}</span>
    </Spinner>
  );
};

export default Loading;
