import { format } from 'date-fns';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlayerState } from '../../api';
import { buttonClicked } from '../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { useMeasureMediaPerformance } from '../../firebase/hooks/useMeasureMediaPerformance';
import { useUpcomingEvent } from '../../hooks/getUpcomingEvent';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useHandleGoBackOnPlayer } from '../../hooks/useHandleGoBackPlayer';
import useKeyPressHandler from '../../hooks/useKeyPressHandler';
import { useTranslations } from '../../hooks/useTranslations';
import { changeHeaderState, configSelector } from '../../store/config';
import {
  fullScreenSelector,
  isLiveStreamEndedSelector,
  isLocalPlayerSelector,
  playerAssetSelector,
  playerErrorSelector,
  playerMaxHeightSelector,
  playerStateSelector,
} from '../../store/player/player.selectors';
import { programsSelector } from '../../store/programs';
import { PROGRAM_ERROR_MESSAGES } from '../../store/programs/contants';
import { AppDispatch } from '../../store/store';
import {
  areLiveControlsVisibleSelector,
  getVideoById,
  isLivePillVisibleSelector,
  selectedVideoSelector,
} from '../../store/videos';
import { USER_INTERACTED, cacheManager } from '../../utils';
import { KEY_CODES } from '../../utils/keyCodes';
import { useConvivaContext } from '../ConvivaProvider/ConvivaProvider';
import { LivePill } from '../LivePill/LivePill';
import { Loader } from '../Loader/Loader';
import { LoadingErrorTryAgain } from '../LoadingErrorTryAgain/LoadingErrorTryAgain';
import { NoProgramError } from '../NoProgramError/NoProgramError';
import { PlayerHandlerContext } from '../Player/Player';
import PlayerControlsLiveWeb from '../PlayerControls/PlayerControlsLive';
import { usePlayerControlsContext } from '../PlayerControls/PlayerControlsProvider';
import PlayerControlsVODWeb from '../PlayerControls/PlayerControlsVOD';
import { PlayerTimeConsumer } from '../PlayerTimeConsumer/PlayerTimeConsumer';
import { PlayButton } from '../playerUI/PlayButton/PlayButton';
import { PlayPauseAnimation } from '../playerUI/PlayPauseAnimation/PlayPauseAnimation';
import PlayerNotification from '../playerUI/PlayerNotification/PlayerNotification';
import ReplayButton from '../playerUI/ReplayButton/ReplayButton';
import {
  LivePillWrapper,
  LoadingErrorWrapper,
  PlayerControlsWrapper,
  PlayerInterfaceWrapper,
  PlayerNotificationContainer,
} from './PlayerInterface.styles';

interface PlayerInterfaceProps {
  showLiveControls?: boolean;
}

export function PlayerInterface({ showLiveControls }: PlayerInterfaceProps) {
  const { ref, fullscreenElementRef, setPlayer, localPlayer, player } =
    useContext(PlayerHandlerContext);
  const selectedVideo = useSelector(selectedVideoSelector);
  const { showHeader } = useSelector(configSelector);
  const playerError = useSelector(playerErrorSelector);
  const fullscreen = useSelector(fullScreenSelector);

  const asset = useSelector(playerAssetSelector);
  const t = useTranslations();
  const { handlePlay, handlePause, isLoadingIndicatorVisible, handleReplay, stopCastSession } =
    usePlayerControlsContext();
  const playerState = useSelector(playerStateSelector);
  const playerMaxHeight = useSelector(playerMaxHeightSelector);
  const dispatch: AppDispatch = useDispatch();
  const [showControls, setShowControls] = useState(true);
  const [animationVisible, setAnimationVisible] = useState(false);
  const { error: programError, loading: programLoading } = useSelector(programsSelector);
  const upcomingEvent = useUpcomingEvent(programError);
  const showLiveBadge = useSelector(isLivePillVisibleSelector);
  const isLiveStreamEnded = useSelector(isLiveStreamEndedSelector);
  const isLocalPlayer = useSelector(isLocalPlayerSelector);

  const isCastStreamEnded = !isLocalPlayer && playerState === PlayerState.IDLE;

  const areLiveControlsVisible = useSelector(
    areLiveControlsVisibleSelector(asset, showLiveControls),
  );
  const handleGoBackOnPlayer = useHandleGoBackOnPlayer();
  const navigate = useExtendedNavigate();
  const { startConvivaSession, updateContentMetadata } = useConvivaContext();
  useMeasureMediaPerformance(ref, asset);

  const noEventView = upcomingEvent ? (
    <NoProgramError
      title={t.liveUpcomingEvent_title}
      description={format(new Date(upcomingEvent), 'EEEE, MMMM d yyyy, h:mm a')}
      playerMaxHeight={playerMaxHeight}
    />
  ) : (
    <NoProgramError
      description={t.liveNoProgram_title}
      title={t.liveNoProgram_description}
      playerMaxHeight={playerMaxHeight}
    />
  );
  const goBack = useCallback(() => {
    buttonClicked({
      destination: '/home',
      location: 'Module: Player',
      path: window.location.pathname,
      screen: 'Player',
      text: 'CTA: Go Back',
      type: 'Button',
      url: window.location.href,
    });
    handleGoBackOnPlayer();
  }, [handleGoBackOnPlayer]);

  const onShowControls = useCallback(() => {
    setShowControls(true);
  }, []);

  const onKeyPress = useCallback(
    ({ keyCode }: { keyCode: number }) => {
      if (keyCode === KEY_CODES.BACK) {
        if (showHeader) {
          goBack();
        } else {
          dispatch(changeHeaderState({ showHeader: true }));
        }
      }
      onShowControls();
    },
    [dispatch, goBack, onShowControls, showHeader],
  );

  useKeyPressHandler({ onKeyPress });

  useEffect(() => {
    const interval = setInterval(() => setShowControls(false), 5000);

    return () => clearInterval(interval);
  }, []);

  const handlePlayerScreenClick = useCallback(() => {
    if (!(areLiveControlsVisible || playerError)) {
      if (playerState === PlayerState.PAUSED) {
        handlePlay();
      } else {
        handlePause();
      }
      setAnimationVisible(true);

      setTimeout(() => {
        setAnimationVisible(false);
      }, 600);
    }
  }, [handlePlay, handlePause, playerState, areLiveControlsVisible, playerError]);

  const onReplayButtonClick = useCallback(() => {
    if (isCastStreamEnded) {
      return stopCastSession();
    }
    if (isLiveStreamEnded && selectedVideo) {
      dispatch(getVideoById({ contentType: 'liveevent', id: selectedVideo.id }));
    } else {
      handleReplay();
      startConvivaSession();
      if (asset) {
        updateContentMetadata(asset);
      }
    }
  }, [
    dispatch,
    handleReplay,
    isLiveStreamEnded,
    selectedVideo,
    asset,
    startConvivaSession,
    isCastStreamEnded,
    updateContentMetadata,
    localPlayer,
    setPlayer,
    player,
    stopCastSession,
  ]);

  const handlerOnClickControlsWrapper = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      cacheManager.save(USER_INTERACTED, true);
      e.stopPropagation();
    },
    [],
  );

  const areControlsHidden = !showControls || playerError;
  const showReplayScreen =
    playerState === PlayerState.LOADED || isLiveStreamEnded || isCastStreamEnded;

  const noDataError = programError === `Error: ${PROGRAM_ERROR_MESSAGES.NO_DATA_IN_EPG}`;
  const tryAgain = useCallback(() => navigate(0), []);

  if (!programLoading && programError && !noDataError) {
    return (
      <LoadingErrorWrapper>
        <LoadingErrorTryAgain loading={programLoading} tryAgain={tryAgain} error={programError} />
      </LoadingErrorWrapper>
    );
  }

  return (
    <PlayerInterfaceWrapper
      $fullscreen={fullscreen}
      $maxHeight={playerMaxHeight}
      ref={fullscreenElementRef}
      onMouseMove={onShowControls}
      onClick={handlePlayerScreenClick}
    >
      {!programLoading && noDataError ? (
        noEventView
      ) : (
        <>
          <PlayerTimeConsumer />
          <PlayerNotificationContainer $stickToBottom={!!areControlsHidden}>
            <PlayerNotification />
          </PlayerNotificationContainer>
          {isLoadingIndicatorVisible && <Loader absolute withBackdrop />}

          <video ref={ref} autoPlay />

          <LivePillWrapper $showControls={!areControlsHidden && showLiveBadge}>
            <LivePill />
          </LivePillWrapper>

          <PlayButton showLiveControls={areLiveControlsVisible} />

          {showReplayScreen ? (
            <ReplayButton onClick={onReplayButtonClick} />
          ) : (
            asset && (
              <>
                <PlayerControlsWrapper
                  $showControls={!areControlsHidden}
                  onClick={handlerOnClickControlsWrapper}
                >
                  {areLiveControlsVisible ? <PlayerControlsLiveWeb /> : <PlayerControlsVODWeb />}
                </PlayerControlsWrapper>
                <PlayPauseAnimation animationVisible={animationVisible} />
              </>
            )
          )}
        </>
      )}
    </PlayerInterfaceWrapper>
  );
}

export default PlayerInterface;
