import { Dropdown, Nav } from 'react-bootstrap';

import { ChevronIcon } from '../../../../assets/icons/Chevron';
import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { NavigationItem as INavigationItem } from '../../Navigation.types';
import { isElementActive } from '../../helpers/isElementActive';
import { navigationClickHandler } from '../../helpers/navigationClickHandler';
import * as Ui from './NavigationDropdown.styles';

export const NavigationDropdown = (item: INavigationItem) => {
  const navigate = useExtendedNavigate();
  const isParentActive = item.options?.some(isElementActive);

  return (
    <Ui.Wrapper>
      <Dropdown as={Nav.Item}>
        <Ui.DropdownToggleWrapper>
          <Dropdown.Toggle
            className={isParentActive ? 'active' : ''}
            as='button'
            data-text={item.label}
            disabled={item.disabled}
          >
            {item.label}
            <Ui.IconWrapper $disabled={item.disabled}>
              <ChevronIcon fill='currentColor' />
            </Ui.IconWrapper>
          </Dropdown.Toggle>
        </Ui.DropdownToggleWrapper>

        {item.options?.length ? (
          <Dropdown.Menu>
            {item.options.map((option) => (
              <Ui.Item
                $isActive={isElementActive(option)}
                onClick={() => {
                  navigationClickHandler(option.type, option.path || '', navigate);
                }}
                data-text={option.label}
                key={option.label}
              >
                {option.label}
              </Ui.Item>
            ))}
          </Dropdown.Menu>
        ) : null}
      </Dropdown>
    </Ui.Wrapper>
  );
};
