import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CloseFullscreen, FullScreen } from '../../assets/icons';
import { fullScreenSelector, playerActions } from '../../store/player';
import { PlayerHandlerContext } from '../Player/Player';
import { colors } from '../ThemeProvider/constants/colors';
import * as Ui from './PlayerUi.styles';

export default function FullScreenButton() {
  const dispatch = useDispatch();
  const fullscreen = useSelector(fullScreenSelector);
  const { fullscreenElementRef } = useContext(PlayerHandlerContext);

  const handleToggleFullscreen = useCallback(() => {
    if (!fullscreen) {
      if (fullscreenElementRef.current) {
        fullscreenElementRef.current.requestFullscreen();
        dispatch(playerActions.setFullScreenValue(true));
      }
    } else {
      dispatch(playerActions.setFullScreenValue(false));

      document.exitFullscreen();
    }
  }, [fullscreen]);

  return (
    <Ui.PlayerButton aria-label='previous song' onClick={handleToggleFullscreen}>
      {fullscreen ? (
        <CloseFullscreen stroke={colors['off-white']} />
      ) : (
        <FullScreen stroke={colors['off-white']} />
      )}
    </Ui.PlayerButton>
  );
}
