import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChannelEPG, scheduleSelector } from '../store/schedule';
import { AppDispatch } from '../store/store';
import { filterOutPastItems, sortFromOldestToNewest } from '../utils/date';
import { useChannelSearchParam } from './params/useChannelSearchParam';

export const useUpcomingEvent = (programError: string): string | undefined => {
  const { channels = [] } = useSelector(scheduleSelector);
  const dispatch: AppDispatch = useDispatch();
  const { selectedChannel } = useChannelSearchParam();

  useEffect(() => {
    if (programError && selectedChannel?.id) {
      dispatch(getChannelEPG({ channelId: selectedChannel?.id, date: new Date() }));
    }
  }, [programError, selectedChannel?.id]);

  const sortedChannels = useMemo(
    () =>
      channels
        .filter((item) => filterOutPastItems(item.endDate))
        .sort((a, b) => sortFromOldestToNewest(a.startDate, b.startDate)),
    [channels],
  );

  if (!programError || !sortedChannels.length) {
    return undefined;
  }

  if (sortedChannels.length > 0) {
    return sortedChannels[0].startDate;
  }
};
