import { useTranslations } from '../../../../hooks/useTranslations';
import { ScheduleTabs } from '../../../../pages/Schedule/Schedule';
import { Selector } from '../../../Selector/Selector';
import { SelectorSize } from '../../../Selector/Selector.types';
import * as Ui from './TabSelector.styles';

export const TabSelector = ({
  selectedTab,
  onChangeTab,
}: {
  selectedTab: ScheduleTabs;
  onChangeTab: (tab: ScheduleTabs) => void;
}) => {
  const isSelected = (tab: ScheduleTabs): boolean => tab === selectedTab;
  const t = useTranslations();

  const handleChangeTab = (tab: ScheduleTabs) => {
    onChangeTab(tab);
  };

  return (
    <Ui.TabSelectorWrapper>
      <Selector
        size={SelectorSize.Large}
        selected={isSelected(ScheduleTabs.GAMES)}
        onClick={() => handleChangeTab(ScheduleTabs.GAMES)}
      >
        {t.schedule_tabs_games}
      </Selector>
      <Selector
        size={SelectorSize.Large}
        selected={isSelected(ScheduleTabs.CHANNELS)}
        onClick={() => handleChangeTab(ScheduleTabs.CHANNELS)}
      >
        {t.schedule_tabs_channels}
      </Selector>
    </Ui.TabSelectorWrapper>
  );
};
