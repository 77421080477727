import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { scheduleGamesSelector } from '../../../store/schedule';
import { getDate } from '../../../utils/date';
import { scrollToPosition } from '../../../utils/scroll';
import { LIVE_GAME_CLASS_NAME } from '../components/GameRow/GameRow';
import { GAME_TABLE_ID, SCROLLING_TIME_IN_MS } from '../constants';
import { getScrollingPositionOfElement } from '../utils/getScrollingPosition';

export const useScrollToGame = (
  setRunIntersectionObserverOnElement: (value: boolean) => void,
  selectedDate?: string | null,
) => {
  const games = useSelector(scheduleGamesSelector);

  const scrollToGame = () => {
    let targetElement;

    const selectedMonth = getDate(selectedDate).getMonth();
    const selectedYear = getDate(selectedDate).getFullYear();
    const currentMonth = new Date().getMonth();

    const activeElement = document.querySelector(`.${LIVE_GAME_CLASS_NAME}`);
    if (selectedMonth === currentMonth && activeElement) {
      targetElement = activeElement;
    } else {
      targetElement = document.querySelector(`[data-monthyear="${selectedMonth}-${selectedYear}"]`);
    }

    if (targetElement) {
      scrollToPosition(
        getScrollingPositionOfElement(targetElement, GAME_TABLE_ID),
        SCROLLING_TIME_IN_MS,
        () => {
          setRunIntersectionObserverOnElement(true);
        },
      );
    }
  };

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (games) {
      id = setTimeout(() => {
        scrollToGame();
      }, 600);
    }

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [games]);
};
