import styled from 'styled-components';

export const StyledVersion = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  color: ${({ theme }) => theme.colors['pure-white']};
  padding: 0.75rem;
  position: fixed;
  right: 0;
  z-index: 9999;
`;
