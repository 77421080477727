export enum RouteName {
  Home = 'home',
  Welcome = 'welcome',
  FavoriteTeams = 'favorite-teams',
  Signup = 'signup',
  Login = 'login',
  ServerError = 'serverError',
  SubscriberAgreement = 'subscriberAgreement',
  TveResultRedirectPath = 'tveResultRedirectPath',
  Networkerror = 'networkerror',
  EditPassword = 'editPassword',
  TermsOfUse = 'terms-of-use',
  PastTermsOfUse = 'past-terms-of-use',
  PrivacyChoices = 'privacy-choices',
  PrivacyPolicy = 'privacy-policy',
  Faqs = 'faqs',
  Payment = 'payment',
  Provider = 'provider',
  Promotion = 'promotion',
  activatePayment = 'activatePayment',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordDone = 'forgotPasswordDone',
  ResetPassword = 'resetPassword',
  ResetPasswordDone = 'resetPasswordDone',
  Activate = 'Activate',
  ActivateSignIn = 'activateSignIn',
  ActivateSignUp = 'activateSignUp',
  Schedule = 'schedule',
  ScheduleGamesPlayer = 'scheduleGamesPlayer',
  ScheduleChannelsPlayer = 'scheduleChannelsPlayer',
  HomePlayer = 'homePlayer',
  SelectSubscription = 'selectSubscription',
  ChannelsPlayer = 'channelsPlayer',
  GamesPlayer = 'gamesPlayer',
  Teams = 'teams',
  TeamsPlayer = 'teamsPlayer',
  Live = 'live',
  AllSet = 'allSet',
  AllSetPayment = 'allSetPayment',
  UpdateFavoriteTeams = 'updateFavoriteTeams',
  HomeCollection = 'homeCollection',
  TeamsCollection = 'teamsCollection',
  HomeSeeAll = 'homeSeeAll',
  TeamsSeeAll = 'teamsSeeAll',
  Settings = 'settings',
  SettingsTab = 'settingsTab',
  TveConnectionStatus = 'tveConnectionStatus',
  ForbiddenRegion = 'forbiddenRegion',
  GeolocationError = 'geolocationError',
  SeeAllPlayer = 'seeAllPlayer',
  SeeAllTeamsPlayer = 'seeAllTeamsPlayer',
  HomeSeeAllPlayer = 'homeSeeAllPlayer',
  PaymentError = 'paymentError',
  Odds = 'odds',
  DeleteAccount = 'deleteAccount',
  SettingsProfileUpdateFavoriteTeams = 'settingsProfileUpdateFavoriteTeams',
  NewApp = 'new-app',
}

export enum RouteParam {
  TveRedirectPath = 'TveRedirectPath',
  ZipCode = 'zipCode',
  Zone = 'zone',
  Sku = 'sku',
  ContentId = 'contentId',
  TabName = 'tabName',
  ChannelId = 'channelId',
  Id = 'id',
}

export type TRouteParams = Partial<Record<RouteParam, string>>;

export const ROUTES: Record<RouteName, string> = {
  [RouteName.Home]: '/home',
  [RouteName.Welcome]: '/welcome',
  [RouteName.FavoriteTeams]: '/favorite-teams',
  [RouteName.Signup]: '/signup',
  [RouteName.Login]: '/login',
  [RouteName.ServerError]: '/serverError',
  [RouteName.SubscriberAgreement]: '/subscriberAgreement',
  [RouteName.TveResultRedirectPath]: `/tveResult/:${RouteParam.TveRedirectPath}`,
  [RouteName.Networkerror]: '/networkerror',
  [RouteName.EditPassword]: '/editPassword',
  [RouteName.TermsOfUse]: '/terms-of-use',
  [RouteName.PastTermsOfUse]: '/past-terms-of-use',
  [RouteName.PrivacyChoices]: '/privacy-choices',
  [RouteName.PrivacyPolicy]: '/privacy-policy',
  [RouteName.Faqs]: '/faqs',
  [RouteName.Payment]: '/payment',
  [RouteName.Provider]: '/provider',
  [RouteName.activatePayment]: `/activate-payment/:${RouteParam.ZipCode}/:${RouteParam.Zone}/:${RouteParam.Sku}/:${RouteParam.ContentId}`,
  [RouteName.ForgotPassword]: '/forgot-password',
  [RouteName.ForgotPasswordDone]: '/forgot-password-done',
  [RouteName.ResetPassword]: '/process/reset',
  [RouteName.ResetPasswordDone]: '/reset-password-done',
  [RouteName.Activate]: '/activate',
  [RouteName.ActivateSignIn]: '/activate-sign-in',
  [RouteName.ActivateSignUp]: '/activate-sign-up',
  [RouteName.Schedule]: `/schedule/:${RouteParam.TabName}`,
  [RouteName.ScheduleGamesPlayer]: '/schedule/games/player',
  [RouteName.ScheduleChannelsPlayer]: '/schedule/channels/player',
  [RouteName.HomePlayer]: '/home/player',
  [RouteName.Promotion]: '/home/promotion',
  [RouteName.SeeAllPlayer]: '/home/see-all/player',
  [RouteName.SeeAllTeamsPlayer]: '/teams/see-all/player',
  [RouteName.SelectSubscription]: '/home/select-subscription',
  [RouteName.ChannelsPlayer]: '/channels/player',
  [RouteName.GamesPlayer]: '/games/player',
  [RouteName.Teams]: '/teams',
  [RouteName.TeamsPlayer]: '/teams/player',
  [RouteName.Live]: '/live',
  [RouteName.AllSet]: '/all-set',
  [RouteName.AllSetPayment]: '/all-set-payment',
  [RouteName.UpdateFavoriteTeams]: '/update-favorite-teams',
  [RouteName.HomeCollection]: '/home/collection',
  [RouteName.TeamsCollection]: '/teams/collection',
  [RouteName.HomeSeeAll]: '/home/see-all',
  [RouteName.HomeSeeAllPlayer]: '/home/see-all/player',
  [RouteName.TeamsSeeAll]: '/teams/see-all',
  [RouteName.Settings]: '/settings',
  [RouteName.SettingsProfileUpdateFavoriteTeams]: '/settings/profile/update-favorite-teams',
  [RouteName.SettingsTab]: `/settings/:${RouteParam.TabName}`,
  [RouteName.TveConnectionStatus]: '/tve-connection-status',
  [RouteName.ForbiddenRegion]: '/forbidden-region',
  [RouteName.GeolocationError]: '/geolocation-error',
  [RouteName.PaymentError]: '/payment-error',
  [RouteName.Odds]: '/odds',
  [RouteName.DeleteAccount]: '/delete-account',
  [RouteName.NewApp]: '/new-app',
} as const;
