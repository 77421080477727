import { useEffect, useState } from 'react';

import fallback from '../assets/images/msg_plus_logo.png';

const useImageSafe = (url?: string) => {
  const [error, setError] = useState<boolean | null>(null);

  useEffect(() => {
    if (url) {
      const image = new Image();
      image.addEventListener('load', () => setError(false));
      image.addEventListener('error', () => setError(true));
      image.addEventListener('abort', () => setError(true));
      image.src = url;
    } else {
      setError(true);
    }
  }, [url]);

  return {
    error: !!error,
    loading: error === null,
    url: error ? fallback : url,
  };
};

export default useImageSafe;
