import { memo } from 'react';
import { useSelector } from 'react-redux';

import { getMonths } from '../../../../helpers/months';
import { scheduleSelector } from '../../../../store/schedule';
import { GenericCarousel } from '../../../GenericCarousel/GenericCarousel';
import useGenericCarousel from '../../../GenericCarousel/useGenericCarousel';
import { Selector } from '../../../Selector/Selector';
import { SelectorSize } from '../../../Selector/Selector.types';
import { MonthsListContainer } from './MonthsList.styles';

interface Props {
  selectedMonth: number;
  onChangeMonth: (month: number, year: number) => void;
}

export const MonthsList = memo((props: Props) => {
  const { props: carouselProps } = useGenericCarousel();
  const { selectedMonth, onChangeMonth } = props;
  const { isPrevMonth } = useSelector(scheduleSelector);

  const isSelected = (date: number) => date === selectedMonth;

  return (
    <MonthsListContainer>
      <GenericCarousel {...carouselProps}>
        {getMonths(isPrevMonth).map((month) => (
          <Selector
            key={month.monthIndex}
            selected={isSelected(month.monthIndex)}
            size={SelectorSize.Small}
            onClick={() => onChangeMonth(month.monthIndex, month.year)}
          >
            {month.label}
          </Selector>
        ))}
      </GenericCarousel>
    </MonthsListContainer>
  );
});

MonthsList.displayName = 'MonthsList';
