import { getEnv } from '../env';

declare global {
  interface Window {
    OneTrust:
      | {
          ToggleInfoDisplay: () => void;
          IsAlertBoxClosed: () => boolean;
        }
      | undefined;
    isOneTrustOn: boolean;
  }
}

export const showOTBanner = () => {
  window.OneTrust?.ToggleInfoDisplay();
};

export const isOptanonClosed = () => {
  const { ONETRUST_ENABLED } = getEnv();

  return window.OneTrust?.IsAlertBoxClosed() || !ONETRUST_ENABLED;
};
