import { AxiosResponse, default as axios } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlayerState } from '../../../api';
import { logEvent } from '../../../firebase/firebase';
import { Log } from '../../../helpers/CustomLogger';
import { useErrorReporting } from '../../../hooks/useErrorReporting';
import {
  adsUrlSelector,
  fullScreenSelector,
  playerAssetLoadingSelector,
  playerStateSelector,
  shouldFetchCompanionAdsSelector,
} from '../../../store/player/player.selectors';
import { getErrorMessage } from '../../../utils/error';
import { usePlayerTimeContext } from '../../playerUI/PlayerTimeProvider/PlayerTimeProvider';
import { Ad, AdBreaks, AdImage, MappedAds } from '../CompanionAds.types';
import {
  clearAllAdImpressions,
  convertCompanionAdsToArray,
  hasAdBeenImpressed,
  saveAdImpression,
} from '../CompanionAds.utils';

const useCompanionAds = ({ isDebugViewEnabled }: { isDebugViewEnabled: boolean }) => {
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const adsUrl = useSelector(adsUrlSelector);
  const [adBreaksData, setAdBreaksData] = useState<AdBreaks[]>();
  const isFullScreen = useSelector(fullScreenSelector);
  const assetLoading = useSelector(playerAssetLoadingSelector);
  const { currentTime } = usePlayerTimeContext();

  const [mappedAds, setMappedAds] = useState<Array<MappedAds>>([]);
  const playerState = useSelector(playerStateSelector);
  const { reportError } = useErrorReporting(60 * 5 * 1000, (error, count) =>
    logEvent('companion_ads_fetch_failure', {
      error,
      occurences: `${count}`,
    }),
  );
  const shouldFetchCompanionAds = useSelector(shouldFetchCompanionAdsSelector);

  useEffect(() => {
    if (assetLoading) {
      setMappedAds([]);
    }
  }, [assetLoading]);

  useEffect(() => {
    if ([PlayerState.STARTED, PlayerState.LOADED].includes(playerState as PlayerState)) {
      setIsPlayerReady(true);
    }
  }, [playerState]);

  const adToShowIndex = mappedAds.findIndex(
    ({ duration, startDateTime }) =>
      currentTime * 1000 > startDateTime && currentTime * 1000 < startDateTime + duration,
  );

  const fetchAdds = async (addsUrl: string) => {
    try {
      const {
        data: { adBreaks },
      }: AxiosResponse<{
        adBreaks: [AdBreaks];
      }> = await axios.get(addsUrl);
      if (adBreaks.length > 0) {
        setAdBreaksData(adBreaks);
      }
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error);
      reportError(errorMessage);
    }
  };

  useEffect(() => {
    Log.log('adBreaksData changed', adBreaksData);
    if (!adBreaksData || !adBreaksData.length) {
      return;
    }
    // const [{ ads }] = adBreaksData;
    const [{ ads }] = adBreaksData;
    if (ads?.find((ad) => ad?.companionAds) && isDebugViewEnabled) {
      Log.log('real companion ads', ads);
    }

    if (ads) {
      Log.log(' if (ads), setMappedAds');
      setMappedAds(
        ads.map((ad: Ad) => {
          const { companionAds = [], ...adData } = ad;
          Log.log('companionAds', companionAds);
          // we need to remove server side trackingEvents
          delete adData.trackingEvents;
          const resultData = {
            ...adData,
            companionAds: convertCompanionAdsToArray(companionAds),
          };
          Log.log('resultData', resultData);

          return resultData;
        }),
      );
    }
  }, [adBreaksData?.[0]?.id, adBreaksData?.[0]?.duration, adBreaksData?.[0]?.startDateTime]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined = undefined;
    if (shouldFetchCompanionAds && adsUrl) {
      intervalId = setInterval(() => {
        fetchAdds(adsUrl);
      }, 2000);
    }

    if (!shouldFetchCompanionAds && intervalId) {
      clearInterval(intervalId);
      setMappedAds([]);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [shouldFetchCompanionAds, isPlayerReady]);

  const onImgLoad = useCallback(
    async (ad: MappedAds, adImage: AdImage) => {
      Log.log('onImgLoad', ad.id, hasAdBeenImpressed(ad.id));
      const { trackingEvents = [] } = adImage;
      if (trackingEvents?.length > 0 && !isFullScreen) {
        try {
          Log.log('try', ad.id);
          if (!hasAdBeenImpressed(ad.id)) {
            Log.log('sending impression for: ', ad.id);
            saveAdImpression(ad.id);
            const requests = trackingEvents.map((event) => {
              Log.log('sending event to: ', event.signalingUrls);
              event.signalingUrls.map((url) => axios.post(url));
            });
            await axios.all(requests);
            Log.log('requests sent');
          } else {
            return;
          }
        } catch (err: unknown) {
          Log.error(err);
        }
      }
    },
    [isFullScreen],
  );

  useEffect(() => {
    return () => {
      clearAllAdImpressions();
    };
  }, []);
  return {
    adToShowIndex,
    mappedAds,
    onImgLoad,
  };
};

export { useCompanionAds };
