import { FailureResponse, GetPaymentsResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getPaymentHistory = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  getPayments,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getPayments: string;
}): Promise<GetPaymentsResponse> => {
  const paymentData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };
  const data = { GetPaymentsRequestMessage: paymentData };

  const response = await evergentAxiosInstance.post<{
    GetPaymentsResponseMessage: GetPaymentsResponse & FailureResponse;
  }>(getPayments, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = response.data.GetPaymentsResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  if (!dataResponse.AccountPayments) {
    throw new Error('No subscription history');
  }

  return dataResponse;
};
