import { FailureResponse, ForgotContactPasswordResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const forgotContactPassword = async ({
  email,
  evergentApiKey,
  evergentChannelPartnerId,
  forgotContactPasswordUrl,
}: {
  email: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  forgotContactPasswordUrl: string;
}): Promise<ForgotContactPasswordResponse> => {
  const forgotContactPasswordData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    email: email,
  };

  const body = { ForgotContactPasswordRequestMessage: forgotContactPasswordData };

  const response = await evergentAxiosInstance.post<{
    ForgotContactPasswordResponseMessage: ForgotContactPasswordResponse & FailureResponse;
  }>(forgotContactPasswordUrl, body);

  const { failureMessage, ...dataResponse } = response.data.ForgotContactPasswordResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
