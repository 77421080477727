import axios from 'axios';

import { Log } from '../../helpers/CustomLogger';
import { RemoteConfigType } from './remoteConfigApi.types';

export const remoteConfigApi = {
  getRemoteConfig: async () => {
    const url =
      process.env.REACT_APP_ENV === 'prod'
        ? 'https://dtc-api.msgnetworks.com/v1/Configuration/App/'
        : 'https://dtc-api-stage.msgnetworks.com/v1/Configuration/App/';
    try {
      const response: RemoteConfigType = await axios.get(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_ENV_X_API_KEY,
        },
        params: {
          environment: process.env.REACT_APP_ENV || 'dev',
          platform: 'web',
        },
      });
      return response.data;
    } catch (error: unknown) {
      Log.error(error);
    }
  },
};
