import { SVGProps } from 'react';

export const Checkmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='100'
    height='101'
    viewBox='0 0 100 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Icon'>
      <path
        id='Ellipse 19 (Stroke)'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.9991 11.911C28.5214 11.911 11.1102 29.3222 11.1102 50.7999C11.1102 72.2776 28.5214 89.6888 49.9991 89.6888C71.4769 89.6888 88.888 72.2776 88.888 50.7999C88.888 29.3222 71.4769 11.911 49.9991 11.911ZM5.55469 50.7999C5.55469 26.2539 25.4531 6.35547 49.9991 6.35547C74.5451 6.35547 94.4436 26.2539 94.4436 50.7999C94.4436 75.3459 74.5451 95.2444 49.9991 95.2444C25.4531 95.2444 5.55469 75.3459 5.55469 50.7999Z'
        fill={props.fill || '#009F53'}
      />
      <path
        id='Vector 5 (Stroke)'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68.63 36.3361C69.7148 37.4208 69.7148 39.1796 68.63 40.2644L43.63 65.2644C42.5452 66.3492 40.7864 66.3492 39.7016 65.2644L31.3683 56.9311C30.2835 55.8463 30.2835 54.0875 31.3683 53.0027C32.4531 51.9179 34.2119 51.9179 35.2967 53.0027L41.6658 59.3719L64.7016 36.3361C65.7864 35.2513 67.5452 35.2513 68.63 36.3361Z'
        fill={props.fill || '#009F53'}
      />
    </g>
  </svg>
);
