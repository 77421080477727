import { addMonths, format, subMonths } from 'date-fns';

interface MonthWithYear {
  label: string;
  monthIndex: number;
  year: number;
}

export const getMonths = (startFromPrevious: boolean) => {
  const months: MonthWithYear[] = [];
  const now = new Date();
  const startingDate = startFromPrevious ? subMonths(now, 1) : now;

  for (let i = 0; i < 12; i++) {
    const date = addMonths(startingDate, i);

    months.push({
      label: format(date, 'MMM'),
      monthIndex: date.getMonth(),
      year: date.getFullYear(),
    });
  }

  return months;
};
