import { addMethod, object, ref, string } from 'yup';

import t from '../translations/en.json';

addMethod(string, 'contains', function (regex: string, message: string) {
  return this.test('contains', message, (value: string | undefined) => {
    if (!value) return false;
    const regExp = new RegExp(regex);
    return regExp.test(value);
  });
});

export const emailRegX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const zipCodeRegx = /^[0-9]{5}$/;

export const passwordValidator = string()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore ts does not recognize added types
  .contains(/^(?=.*[a-z])/, t.passwordValidator_lowercase)
  .contains(/^(?=.*[A-Z])/, t.passwordValidator_uppercase)
  .contains(/(?=.*[(^)#@$!%*?&])/, t.passwordValidator_special)
  .contains(/\d/, t.passwordValidator_digit)
  .min(8, t.passwordValidator_min)
  .max(16, t.passwordValidator_max);

export enum SignUpFormFields {
  Password = 'password',
  Username = 'username',
}

export const signUpSchema = object().shape({
  [SignUpFormFields.Password]: passwordValidator.clone(),
  [SignUpFormFields.Username]: string()
    .email(t.signUpValidator_email)
    .max(120, t.signUpValidator_max)
    .min(8, t.signUpValidator_min),
});

export enum SignInFormFields {
  Password = 'password',
  Username = 'username',
}

export const signInSchema = object().shape({
  [SignInFormFields.Password]: string().required(t.signInValidator_passwordRequired),
  [SignInFormFields.Username]: string()
    .required(t.signInValidator_emailRequired)
    .matches(emailRegX, t.signInValidator_validEmail),
});

export enum EditEmailFormFields {
  NewEmail = 'newEmail',
  ConfirmNewEmail = 'confirmNewEmail',
}

export const editEmailSchema = (currentEmail: string) =>
  object().shape({
    [EditEmailFormFields.NewEmail]: string()
      .email(t.settings_profile_editEmail_valid_email)
      .required(t.settings_profile_editEmail_email_required)
      .notOneOf([currentEmail], t.settings_profile_editEmail_existing_user),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    [EditEmailFormFields.ConfirmNewEmail]: string()
      .email(t.settings_profile_editEmail_valid_email)
      .required(t.settings_profile_editEmail_email_confirmation_required)
      .oneOf([ref(EditEmailFormFields.NewEmail), null], t.settings_profile_editEmail_match),
  });

export enum ZipCodeFormFields {
  ZipCode = 'zipCode',
}

export const zipCodeSchema = object().shape({
  [ZipCodeFormFields.ZipCode]: string().required().matches(zipCodeRegx),
});

export enum PayPalInfoFormFields {
  Email = 'email',
}

export const payPalInfoSchema = object().shape({
  [PayPalInfoFormFields.Email]: string()
    .email(t.paypal_info_email_validation)
    .required(t.settings_profile_editEmail_email_required),
});

export enum EditPasswordFormFields {
  ConfirmPassword = 'confirmNewpassword',
  NewPassword = 'newPassword',
  OldPassword = 'oldPassword',
}

export const editPasswordSchema = object().shape({
  [EditPasswordFormFields.ConfirmPassword]: string()
    .required(t.editPasswordValidator_required)
    .oneOf([ref(EditPasswordFormFields.NewPassword), null], t.editPasswordValidator_match),
  [EditPasswordFormFields.NewPassword]: passwordValidator.clone(),
  [EditPasswordFormFields.OldPassword]: string().required(
    t.editPasswordValidator_oldPasswordRequired,
  ),
});

export enum ResetPasswordFormFields {
  ConfirmNewPassword = 'confirmNewpassword',
  NewPassword = 'newPassword',
}

export const resetPasswordSchema = object().shape({
  [ResetPasswordFormFields.ConfirmNewPassword]: string()
    .required(t.resetPasswordValidator_required)
    .oneOf([ref(ResetPasswordFormFields.NewPassword), null], t.resetPasswordValidator_match),
  [ResetPasswordFormFields.NewPassword]: passwordValidator.clone(),
});

export enum ForgotPasswordFormFields {
  Username = 'username',
}

export const forgotPasswordSchema = object().shape({
  [ForgotPasswordFormFields.Username]: string()
    .required(t.signInValidator_emailRequired)
    .matches(emailRegX, 'Type a valid email address'),
});

export enum CheckEmailFormFields {
  Email = 'email',
}

export const CHECK_EMAIL_EMAIL_FIELD_NAME = 'email';

export const checkEmailSchema = object({
  [CheckEmailFormFields.Email]: string()
    .required(t.signInValidator_emailRequired)
    .matches(emailRegX, 'Type a valid email address'),
});
