import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { GenericCarousel } from '../../../GenericCarousel/GenericCarousel';
import { BREAKPOINT } from '../../../ThemeProvider/constants/breakpoints';

export const DaysFilterCarousel = styled(GenericCarousel)`
  gap: 0.5rem;
  max-width: ${BREAKPOINT.xxl}px;
  width: 100%;
  margin: auto;

  ${breakpoint('lg')`
    gap: 1rem;
  `}
`;
