import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePlayerProgressUpdate } from '../../../hooks/quickplay/usePlayerProgressUpdate';
import { selectedVideoSelector } from '../../../store/videos';
import { usePlayerTimeContext } from '../PlayerTimeProvider/PlayerTimeProvider';

export const usePlayerTime = () => {
  const selectedVideo = useSelector(selectedVideoSelector);

  const { setCurrentTime, setDuration } = usePlayerTimeContext();

  const videoCompletedEventSent = useRef(false);

  const onTimeUpdate = useCallback(
    (time: number, duration: number) => {
      setCurrentTime(time);
      setDuration(duration);
    },
    [selectedVideo, videoCompletedEventSent.current],
  );

  usePlayerProgressUpdate(onTimeUpdate);
};
