import { useCallback, useEffect, useState } from 'react';

import { FLCastPlayer, FLLocalPlayer, PlayerTrack, TrackType } from '../../api';

interface Props {
  player: FLLocalPlayer | FLCastPlayer;
}

const initEmptyTracks = () => {
  return {
    [TrackType.AUDIO]: [] as PlayerTrack[],
    [TrackType.TEXT]: [] as PlayerTrack[],
  };
};
export const usePlayerTracks = ({ player }: Props) => {
  const [ccActive, setCcActive] = useState(false);
  const [availableTracks, setAvailableTracks] = useState<PlayerTrack[]>([]);
  const [activeTracksRaw, setActiveTracksRaw] = useState<PlayerTrack[]>([]);

  const [tracksGroups, setTrackGroups] = useState(initEmptyTracks());
  const [activeTracks, setActiveTracks] = useState({} as Record<TrackType, PlayerTrack>);
  const [countTracks, setCountTracks] = useState(0);

  const onProgressUpdate = useCallback(() => {
    if (availableTracks.length) {
      return;
    }

    const playerAllTracks = player.getAllTracks();
    const playerActiveTracks = player.getActiveTracks();

    if (playerAllTracks.length && playerActiveTracks.length) {
      setAvailableTracks(playerAllTracks);
      setActiveTracksRaw(playerActiveTracks);
    }
  }, [player, availableTracks.length]);

  useEffect(() => {
    if (availableTracks.length) {
      return;
    }

    player.subscribe('progressupdate', onProgressUpdate);

    return () => player.unsubscribe('progressupdate', onProgressUpdate);
  }, [player, onProgressUpdate, availableTracks.length]);

  const selectTrack = useCallback(
    (propsPlayer?: FLLocalPlayer | FLCastPlayer) => {
      if (ccActive && tracksGroups?.text?.length === 1) {
        if (propsPlayer) {
          propsPlayer.selectTrack(tracksGroups.text[0]);
        } else {
          player.selectTrack(tracksGroups.text[0]);
        }
      }
    },
    [player, ccActive, tracksGroups],
  );

  useEffect(() => {
    const tracksGrouped = initEmptyTracks();
    for (const item of availableTracks) {
      tracksGrouped[item.type].push(item);
    }

    setTrackGroups(tracksGrouped);
    setCountTracks(availableTracks.length);
  }, [availableTracks]);

  useEffect(() => {
    if (!ccActive) {
      player.selectTrack();
    } else {
      selectTrack();
    }
  }, [ccActive, tracksGroups]);

  useEffect(() => {
    const mappedKeyValue = activeTracksRaw.map((item) => [item.type, item]);
    const mappedActiveTracks = Object.fromEntries(mappedKeyValue);

    setActiveTracks(mappedActiveTracks);
  }, [activeTracksRaw]);

  const handleChangeTrack = useCallback(
    (track?: PlayerTrack) => {
      player.selectTrack(track);
    },
    [player],
  );

  return {
    activeTracks,
    ccActive,
    countTracks,
    handleChangeTrack,
    selectTrack,
    setCcActive,
    tracksGroups,
  };
};
