import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../../../Constants/routes';
import { matchingPathWithSearchParams } from '../../../helpers/path';
import { useLoadChannels } from '../../../hooks/useLoadChannels';
import { useTranslations } from '../../../hooks/useTranslations';
import { channelsSelector } from '../../../store/channels';
import { useEnv } from '../../EnvProvider/EnvProvider';
import { NavigationItemType } from '../Navigation.types';
import { getNavigationItems } from '../constants/items';

export const useMenuItems = () => {
  const t = useTranslations();
  useLoadChannels();

  const channels = useSelector(channelsSelector);
  const { ODDS_ENABLED } = useEnv();
  const menuItems = useMemo(() => getNavigationItems(t, { ODDS_ENABLED }), [t]);

  useEffect(() => {
    const liveChannels = menuItems.find((item) => item.type === NavigationItemType.LIVE) || {
      label: t.header_live,
      options: [],
    };

    liveChannels.options = channels.map((channel) => ({
      isAvailableOnMobile: false,
      isElementActiveValidator: matchingPathWithSearchParams,
      label: channel.name,
      path: `${ROUTES[RouteName.Live]}?channel=${channel.id}`,
      type: NavigationItemType.LIVE,
    }));
  }, [channels, menuItems]);

  return menuItems;
};
