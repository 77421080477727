import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  ForgotContactPasswordResponse,
  GetContactResponse,
  ResetPasswordResponse,
  SearchAccountV2Response,
} from '../../api/authApi/authApi.types';
import { UserDataState } from './userData.types';

export const userData = createAction('userData/load');
export const accountData = createAction('accountData/load');
export const forgotPasswordAction = createAction('forgotPassword/load');
export const changePasswordAction = createAction('changePassword/load');
export const changeEmailAction = createAction('changeEmail/load');
export const resetPasswordAction = createAction('resetPassword/load');

export const initialState: UserDataState = {
  accountRegistrationDate: 0,
  contactCreateDate: 0,
  contactCreateDateString: '',
  contactID: '',
  cpCustomerID: '',
  email: '',
  error: null,
  forgotPasswordLoaded: false,
  lastLoginTime: 0,
  lastName: '',
  loaded: false,
  loading: false,
  name: '',
  resetPasswordLoaded: false,
  spAccountID: '',
  userNameForCreateAccount: undefined,
};

const slice = createSlice({
  initialState,
  name: 'userData',
  reducers: {
    cleanUserDataErrors: (state: UserDataState) => ({
      ...state,
      error: null,
    }),

    cleanUserDataSuccess: () => ({
      ...initialState,
    }),
    forgotPasswordError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      forgotPasswordLoaded: true,
      loading: false,
    }),
    forgotPasswordLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      forgotPasswordLoaded: false,
      loading: action.payload,
    }),
    forgotPasswordSuccess: (
      state: UserDataState,
      action: PayloadAction<ForgotContactPasswordResponse>,
    ) => ({
      ...state,
      ...action.payload,
      error: null,
      forgotPasswordLoaded: true,
      loading: false,
    }),

    getUserDataError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    getUserDataSetLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    getUserDataSuccess: (state: UserDataState, action: PayloadAction<GetContactResponse>) => ({
      ...state,
      ...action.payload,
      error: null,
      loaded: true,
      loading: false,
    }),
    resetPasswordError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      resetPasswordLoaded: false,
    }),
    resetPasswordLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
      resetPasswordLoaded: false,
    }),
    resetPasswordSuccess: (state: UserDataState, action: PayloadAction<ResetPasswordResponse>) => ({
      ...state,
      ...action.payload,
      error: null,
      resetPasswordLoaded: true,
    }),
    searchAccountDataError: (
      state,
      action: PayloadAction<{
        error: string;
        email: string;
      }>,
    ) => ({
      ...state,
      error: action.payload.error,
      loading: false,
      userNameForCreateAccount: action.payload.email,
    }),
    searchAccountDataLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    searchAccountDataSuccess: (
      state: UserDataState,
      action: PayloadAction<SearchAccountV2Response>,
    ) => ({
      ...state,
      ...action.payload,
      error: null,
      loading: false,
      userNameForCreateAccount: undefined,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as userDataActions, name, reducer };
