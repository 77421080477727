import { createAsyncThunk } from '@reduxjs/toolkit';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { favoriteTeamsActions, loadFavoriteTeamsAction } from './favoriteTeams.slice';

export const getFavoriteTeams = createAsyncThunk(
  loadFavoriteTeamsAction.type,
  async (payload: { accessToken: string }, thunksapi) => {
    try {
      thunksapi.dispatch(favoriteTeamsActions.loadFavoriteTeamsLoading());

      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, GET_FAVORITES_TEAMS_URL },
        },
      } = thunksapi.getState() as RootState;

      const { favourites } = await evergentAuthApi.getFavoriteTeams({
        accessToken: payload.accessToken,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        getFavoritesTeamsUrl: GET_FAVORITES_TEAMS_URL,
      });

      thunksapi.dispatch(favoriteTeamsActions.loadFavoriteTeamsSuccess(favourites.favoriteTeams));
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(favoriteTeamsActions.loadFavoriteTeamsError(errorMessage));
    }
  },
);
