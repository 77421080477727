import mParticle from '@mparticle/web-sdk';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetProductsResponseItem } from '../../../api/authApi/authApi.types';
import { useTranslations } from '../../../hooks/useTranslations';
import {
  getPurchaseItem,
  paymentErrorSelector,
  paymentRequestPendingSelector,
  purchaseItemSelector,
  selectedPaymentOptionSelector,
} from '../../../store/purchase';
import { AppDispatch } from '../../../store/store';
import { RootState } from '../../../store/types';
import { sendMParticleError } from '../../../utils/mParticle/sendMPArticleError';
import { AvailablePaymentOptions } from '../../PaymentOptions';
import { PayPal } from '../../PaymentOptions/PayPal/PayPal';
import { VoucherCode } from '../../VoucherCode/VoucherCode';
import { LegalText } from '../LegalText/LegalText';
import { PriceSummary } from '../PriceSummary/PriceSummary';
import * as Ui from './CostSummary.styles';
import { ErrorType, TError } from './constants';

interface ICostSummary {
  applyCoupon?: boolean;
  product: GetProductsResponseItem;
  openPaymentMethod: () => void;
  isPurchaseLoading: boolean;
}

const CostSummary = ({
  applyCoupon,
  openPaymentMethod,
  product,
  isPurchaseLoading,
}: ICostSummary) => {
  const [payPalError, setPayPalError] = useState<TError>();
  const dispatch: AppDispatch = useDispatch();
  const selectedPaymentOption = useSelector(selectedPaymentOptionSelector);
  const paymentError = useSelector(paymentErrorSelector);
  const t = useTranslations();
  const purchaseItem = useSelector(purchaseItemSelector);
  const paymentRequestPending = useSelector(paymentRequestPendingSelector);
  const appState = useSelector((state: RootState) => state);

  const onError = (error: TError) => {
    sendMParticleError(
      {
        error,
        eventType: mParticle.EventType.Transaction,
      },
      appState,
    );
    setPayPalError(error);
  };

  useEffect(() => {
    if (!purchaseItem) {
      dispatch(getPurchaseItem(product.skuORQuickCode));
    }
  }, [product.skuORQuickCode, purchaseItem]);

  if (!purchaseItem) {
    return null;
  }

  return (
    <Ui.Wrapper>
      <Ui.Title>{t.costSummary_title}</Ui.Title>
      <VoucherCode applyCoupon={applyCoupon} product={product} />
      <PriceSummary product={product} />
      <LegalText />
      {selectedPaymentOption === AvailablePaymentOptions.PAYPAL ? (
        <Ui.PaypalButtonWrapper>
          <PayPal totalAmount={purchaseItem.amountDueToday} onErrorHandler={onError} />
        </Ui.PaypalButtonWrapper>
      ) : (
        <Ui.Button
          disabled={!selectedPaymentOption || isPurchaseLoading || paymentRequestPending}
          onPress={openPaymentMethod}
          loading={paymentRequestPending}
        >
          {selectedPaymentOption === AvailablePaymentOptions.APPLE_PAY
            ? t.costSummary_button_apple
            : t.costSummary_button}
        </Ui.Button>
      )}
      {payPalError && (
        <Ui.ErrorContentWrapper $type={payPalError.type}>
          <Ui.ErrorLine>{payPalError.message}</Ui.ErrorLine>
        </Ui.ErrorContentWrapper>
      )}
      {paymentError && (
        <Ui.ErrorContentWrapper $type={ErrorType.Error}>
          <Ui.ErrorLine>{paymentError}</Ui.ErrorLine>
        </Ui.ErrorContentWrapper>
      )}
    </Ui.Wrapper>
  );
};

export { CostSummary };
