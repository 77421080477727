import { createAsyncThunk } from '@reduxjs/toolkit';
import { subDays } from 'date-fns';

import { ALL } from '../../Constants/TeamSelector';
import { Video, parseVideoRaw, quickplayCmsApi } from '../../api';
import { Log } from '../../helpers/CustomLogger';
import { getDate } from '../../utils/date';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import {
  loadAllGames,
  loadChannelEPGAction,
  loadGamesAction,
  scheduleActions,
} from './schedule.slice';
import { checkPrevMonth } from './utils/checkPrevMonth';
import { getOneYearRange } from './utils/getDatesRanges';
import { getSortedGames } from './utils/getSortedGames';
import { prepareGetGamesParams } from './utils/prepareGetGamesParams';

export const getChannelEPG = createAsyncThunk(
  loadChannelEPGAction.type,
  async (payload: { date: Date; channelId: string }, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { X_CLIENT_ID, FORCE_ZONE_KEY, GET_STOREFRONT_CHANNEL_EPG },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(scheduleActions.loadScheduleLoading());

      const schedule = await quickplayCmsApi.getEPGByDate({
        ...payload,
        forceZoneKey: FORCE_ZONE_KEY,
        getStoreFrontChannelEpg: GET_STOREFRONT_CHANNEL_EPG,
        xClientId: X_CLIENT_ID,
      });

      schedule.sort((a, b) => {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });

      thunksapi.dispatch(scheduleActions.loadScheduleSuccess(schedule));
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(scheduleActions.loadScheduleError(errorMessage));
    }
  },
);

export const getGames = createAsyncThunk(
  loadGamesAction.type,
  async (payload: { team: string }, thunksapi) => {
    try {
      const { team } = payload;
      thunksapi.dispatch(scheduleActions.loadScheduleLoading());

      const currentDate = new Date();
      const threeDaysAgo = subDays(currentDate, 3);

      const shouldIncludePrevMonth = threeDaysAgo.getMonth() !== currentDate.getMonth();
      const month = currentDate.getMonth();
      const year = currentDate.getFullYear();

      const isPrevMonth = await checkPrevMonth(team);
      const games = await fetchGamesForPeriod(
        month,
        year,
        shouldIncludePrevMonth,
        team,
        thunksapi.getState(),
      );

      thunksapi.dispatch(
        scheduleActions.loadGamesSuccess({
          games: getSortedGames(games),
          isPrevMonth,
          month,
          team,
          year,
        }),
      );
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(scheduleActions.loadScheduleError(errorMessage));
    }
  },
);

export const fetchGamesForPeriod = async (
  month: number,
  year: number,
  shouldIncludePrevMonth: boolean,
  team: string,
  state: RootState | unknown,
) => {
  const {
    appConfig: {
      env: { FORCE_ZONE_KEY, X_CLIENT_ID, GET_LIVE_EVENTS },
    },
  } = state as RootState;

  const options = {
    forceZoneKey: FORCE_ZONE_KEY,
    getLiveEvents: GET_LIVE_EVENTS,
    xClientId: X_CLIENT_ID,
  };

  const dateRange = getOneYearRange({
    currentMonth: month,
    currentYear: year,
    shouldIncludePrevMonth,
  });

  let pageNumber = 1;
  const pageSize = 100;
  const games: Video[] = [];

  const fetchGames = async () => {
    const { data, header } = await quickplayCmsApi.getGamesRaw(
      {
        ...(team !== ALL && { team: team.toLowerCase() }),
        end: dateRange.end,
        pageNumber,
        pageSize,
        start: dateRange.start,
      },
      options,
    );

    games.push(...data.map(parseVideoRaw));

    if (!header.rows || header.rows < pageSize) return;

    pageNumber += 1;
    fetchGames();
  };

  await fetchGames();

  return games;
};

export const getAllGames = createAsyncThunk(loadAllGames.type, async (payload: never, thunkAPI) => {
  try {
    const {
      appConfig: {
        env: { FORCE_ZONE_KEY, X_CLIENT_ID, GET_LIVE_EVENTS },
      },
    } = thunkAPI.getState() as RootState;

    const options = {
      forceZoneKey: FORCE_ZONE_KEY,
      getLiveEvents: GET_LIVE_EVENTS,
      xClientId: X_CLIENT_ID,
    };

    thunkAPI.dispatch(scheduleActions.loadAllGamesLoading());
    const selectedDate = getDate();
    const params = prepareGetGamesParams('all', selectedDate);
    const apiGames = await quickplayCmsApi.getGames(params, options);
    const gamesSorted = getSortedGames(apiGames);

    thunkAPI.dispatch(scheduleActions.loadAllGamesSuccess(gamesSorted));
  } catch (e) {
    thunkAPI.dispatch(scheduleActions.loadAllGamesSuccess([]));
  }
});
