import { FastForward } from '../../assets/icons/FastForward';
import { usePlayerTimeContext } from './PlayerTimeProvider/PlayerTimeProvider';
import { SKIPPING_STEP_IN_SECONDS } from './PlayerUI.constants';
import * as Ui from './PlayerUi.styles';

export default function FastForwardButton() {
  const { setSeekValue, currentTime, duration } = usePlayerTimeContext();

  return (
    <Ui.PlayerButton
      aria-label='next song'
      onClick={() => {
        setSeekValue((prev) => {
          return Math.min(duration, (prev || currentTime) + SKIPPING_STEP_IN_SECONDS);
        });
      }}
    >
      <FastForward />
    </Ui.PlayerButton>
  );
}
