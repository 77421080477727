export const shouldVideoBeAvailable = (
  userZoneName: string | null | undefined,
  selectedVideoZoneName: string[] | undefined,
): boolean => {
  return Boolean(
    userZoneName &&
      selectedVideoZoneName &&
      (!selectedVideoZoneName?.length ||
        userZoneName === 'unavailable' ||
        selectedVideoZoneName?.includes(userZoneName) ||
        selectedVideoZoneName?.includes('all')),
  );
};
