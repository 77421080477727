import { FailureResponse, GetSubscriptionHistoryResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getSubscriptionHistory = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  getSubscriptionHistoryUrl,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getSubscriptionHistoryUrl: string;
}): Promise<GetSubscriptionHistoryResponse> => {
  const subscriptionHistoryData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const data = { GetSubscriptionHistoryRequestMessage: subscriptionHistoryData };

  const response = await evergentAxiosInstance.post<{
    GetSubscriptionHistoryResponseMessage: GetSubscriptionHistoryResponse & FailureResponse;
  }>(getSubscriptionHistoryUrl, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = response.data.GetSubscriptionHistoryResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  if (!dataResponse.AccountServiceMessage) {
    throw new Error('No subscription history');
  }

  return dataResponse;
};
