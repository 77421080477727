import { useSelector } from 'react-redux';

import { Channel } from '../../../../api/cmsApi/cmsApi.types';
import { channelsSelector } from '../../../../store/channels';
import { GenericCarousel } from '../../../GenericCarousel/GenericCarousel';
import useGenericCarousel from '../../../GenericCarousel/useGenericCarousel';
import { ChannelItemButton } from '../ChannelItemButton/ChannelItemButton';
import * as Ui from './ChannelList.styles';

export const ChannelList = ({
  onChangeChannel,
  selectedChannel,
}: {
  onChangeChannel: (channelName: Channel) => void;
  selectedChannel: Channel | null;
}) => {
  const channels = useSelector(channelsSelector);
  const { props: carouselProps } = useGenericCarousel();
  const isSelected = (channel: Channel): boolean => channel.id === selectedChannel?.id;

  return (
    <Ui.ChannelListContainer>
      <GenericCarousel {...carouselProps}>
        {channels.map((channel) => (
          <ChannelItemButton
            key={channel.id}
            channel={channel}
            selected={isSelected(channel)}
            onPress={onChangeChannel}
          />
        ))}
      </GenericCarousel>
    </Ui.ChannelListContainer>
  );
};
