import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { PerformanceMeasure } from '../../firebase/constants';
import { useFirebasePerformanceTracer } from '../../firebase/hooks/useFirebasePerformanceTracer';

export enum EventIdValues {
  EV_IFRAME_READY = 'EV_IFRAME_READY',
  SUBSCRIPTION_PACKAGE_REQUEST = 'SUBSCRIPTION_PACKAGE_REQUEST',
  EVERGENT_SUBSCRIPTION_SUCCESS = 'EVERGENT_SUBSCRIPTION_SUCCESS',
  EVERGENT_ERROR = 'EVERGENT_ERROR',
  EVERGENT_UPDATE_PAYMENT_SUCCESS = 'EVERGENT_UPDATE_PAYMENT_SUCCESS',
}

type EvergentIframeParams = {
  zipCode: string;
  accessToken: string;
  serviceType: string;
  onSuccess: () => void;
  onError?: (err: unknown) => void;
  actionType: 'add' | 'update';
};

export const useEvergentIframe = ({
  accessToken,
  zipCode,
  serviceType,
  onSuccess,
  onError,
  actionType,
}: EvergentIframeParams) => {
  const env = useEnv();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [ready, setReady] = useState(false);
  const [iframeError, setIframeError] = useState<string | null>(null);
  const { startTracer, stopTracer } = useFirebasePerformanceTracer(
    PerformanceMeasure.EVERGENT_PAYMENT_GATEWAY_ACCESS,
  );

  useEffect(() => {
    startTracer({ additionalCondition: !ready });
    stopTracer({ additionalCondition: ready, withReset: true });
  }, [ready]);

  const iframeUrl = useMemo(
    () =>
      env.EVERGENT_CHECKOUT_URL.split('{serviceType}')
        .join(serviceType as string)
        .split('{actionType}')
        .join(actionType as string)
        .split('{zipCode}')
        .join(zipCode as string)
        .split('{accessToken}')
        .join(accessToken),
    [accessToken, actionType, serviceType, zipCode],
  );

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      if (env.EVERGENT_CHECKOUT_URL.startsWith(event.origin)) {
        if (event.data['event_id'] === EventIdValues.EV_IFRAME_READY) {
          setReady(true);
        } else if (event.data['event_id'] === EventIdValues.EVERGENT_ERROR) {
          const errorMessage = event.data.data.ErrorResponse.errorDesc;
          setIframeError(errorMessage);
          if (onError) {
            onError(event.data.data.ErrorResponse);
          }
        } else if (
          actionType === 'add' &&
          event.data['event_id'] === EventIdValues.EVERGENT_SUBSCRIPTION_SUCCESS
        ) {
          setIframeError(null);
          onSuccess();
        } else if (
          actionType === 'update' &&
          event.data['event_id'] === EventIdValues.EVERGENT_UPDATE_PAYMENT_SUCCESS
        ) {
          setIframeError(null);
          onSuccess();
        }
      }
    },
    [actionType, onError, onSuccess],
  );

  useEffect(() => {
    window.addEventListener('message', onMessageReceived, false);
    return () => {
      window.removeEventListener('message', onMessageReceived);
    };
  }, []);

  return {
    iframeError,
    iframeRef,
    iframeUrl,
    onMessageReceived,
    ready,
  };
};

export default useEvergentIframe;
