import { FailureResponse, SetStringResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const setString = async ({
  accessToken,
  attributeValue,
  attributeName,
  evergentApiKey,
  evergentChannelPartnerId,
  setStringUrl,
}: {
  accessToken: string;
  attributeName: string;
  attributeValue: boolean;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  setStringUrl: string;
}): Promise<SetStringResponse> => {
  const setStringData = {
    apiKey: evergentApiKey,
    attributeName: attributeName,
    attributeValue: attributeValue,
    channelPartnerID: evergentChannelPartnerId,
  };

  const body = { SetStringRequestMessage: setStringData };

  const signUpResponse = await evergentAxiosInstance.post<{
    SetStringResponseMessage: SetStringResponse & FailureResponse;
  }>(setStringUrl, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { failureMessage, ...dataResponse } = signUpResponse.data.SetStringResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
