import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { EntitlementsState } from './entitlements.types';

export const entitlementsSelector = (state: RootState): EntitlementsState => state.entitlements;

export const ovatTokenSelector = createSelector(
  entitlementsSelector,
  (entitlements) => entitlements.entitlements?.ovatToken || '',
);

export const areEntitlementsLoadedSelector = createSelector(
  entitlementsSelector,
  (state) => state.loaded,
);

export const ovatTokenExpirationTimestampSelector = createSelector(
  entitlementsSelector,
  ({ entitlements }) =>
    entitlements?.ovatTokenExpiry && entitlements?.fetchTimestamp
      ? entitlements?.ovatTokenExpiry * 1000 + entitlements?.fetchTimestamp
      : undefined,
);

export const ovatTokenLastTimeFetchSelector = createSelector(
  entitlementsSelector,
  ({ entitlements }) => entitlements?.fetchTimestamp,
);

export const hasEntitlementsSelector = createSelector(entitlementsSelector, (entitlements) =>
  Boolean(entitlements?.subscriptions?.length || entitlements?.tvodAccountServices?.length),
);
