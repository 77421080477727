import { useEffect } from 'react';

export const useKeyPressHandler = ({
  onKeyPress,
}: {
  onKeyPress: (event: { keyCode: number }) => void;
}) => {
  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);
};

export default useKeyPressHandler;
