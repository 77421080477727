import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2.375rem;
  height: 2.375rem;
  background: ${(props) => props.theme.colors['scrim-black']};
  border: ${(props) => `1px solid ${props.theme.colors['white-scrim']}`};
  border-radius: 0.25rem;

  svg {
    width: 20px;
    height: 20px;
  }

  ${breakpoint('lg')`
    width: 3.5rem;
    height: 3.5rem;
    
    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const PlayButton = styled(Button)`
  svg {
    width: 16px;
    height: 16px;
  }

  ${breakpoint('lg')`
    svg {
      width: 20px;  
      height: 20px;
    }
  `}
`;

export const Clock = styled(Button)`
  cursor: default;
`;

export const PadlockButton = styled(Button)``;

export const RestartButton = styled(Button)``;
