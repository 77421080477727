import { useEffect } from 'react';

import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import * as UI from './PlayerNotification.styles';
import { alertOverwriteStyles } from './PlayerNotification.styles';

const PlayerNotification = () => {
  const { notification, pushNotification } = usePlayerControlsContext();

  useEffect(() => {
    if (!notification) {
      return;
    }

    const timeout = setTimeout(() => {
      pushNotification('');
    }, 6000);

    return () => clearTimeout(timeout);
  }, [notification, pushNotification]);

  return (
    <UI.AlertWrapper $visible={!!notification}>
      <UI.StyledAlert severity='info' sx={alertOverwriteStyles}>
        {notification}
      </UI.StyledAlert>
    </UI.AlertWrapper>
  );
};

export default PlayerNotification;
