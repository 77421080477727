import { useContext, useEffect } from 'react';

import { StreamTimelineEvent } from '../../api';
import { flpOnStreamtimeLineEventAdapter } from '../../api/playerApi/eventAdapters/flpOnStreamtimeLineEventAdapter';
import { PlayerHandlerContext } from '../../components/Player/Player';

export const useListenToLiveEventEnd = (callback?: () => void) => {
  const { player } = useContext(PlayerHandlerContext);
  useEffect(() => {
    return flpOnStreamtimeLineEventAdapter(player, (streamTimelineEvent) => {
      if (
        [StreamTimelineEvent.LIVE_PROGRAM_END, StreamTimelineEvent.LIVE_EVENT_END].includes(
          streamTimelineEvent,
        )
      ) {
        player.stop().then(() => callback?.());
      }
    });
  }, [player, callback]);
};
