import styled from 'styled-components';

import { Input } from '../atoms';

const EYE_WRAPPER_PADDINX_X = '0.75rem';
const EYE_WIDTH = '24px';

export const InputGroup = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

export const StyledPasswordInput = styled(Input)`
  padding-right: calc(${EYE_WRAPPER_PADDINX_X} * 2 + ${EYE_WIDTH});
`;

export const EyeWrapper = styled.div`
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 10;
  padding: 0rem ${EYE_WRAPPER_PADDINX_X};

  svg {
    width: ${EYE_WIDTH};
  }
`;
