import { v4 as uuidv4 } from 'uuid';

import { cacheManager } from '../../../utils';
import { DEVICE_ID_STORAGE_KEY } from '../deviceInfo.constants';

export const getDeviceId = () => {
  const cachedDeviceId = cacheManager.load<string>(DEVICE_ID_STORAGE_KEY);
  if (!cachedDeviceId) {
    const deviceId = uuidv4();
    cacheManager.save(DEVICE_ID_STORAGE_KEY, deviceId);
    return deviceId;
  }
  return cachedDeviceId;
};
