import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { FONT_WEIGHT } from '../../../Common';

const LOGO_HEIGHT = '19px';

export const Team = styled.div`
  flex: 1;
  font-family: 'GothamNarrow';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors['primary-light']};
  min-height: ${LOGO_HEIGHT}; // for missing team data

  ${breakpoint('lg')`
  	font-size: 1rem;
  	line-height: 1.25rem;
	  display: flex;
	  align-items: center;
    text-transform: uppercase;
  `}

  img {
    margin-right: 0.5rem;
    ${breakpoint('lg')`
	    margin-right: 1rem;
    `}
  }
`;

export const TeamNameWrapper = styled.div`
  display: inline-block;
  ${breakpoint('lg')`
  display:block;
`}
`;

export const Broadcast = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  display: none;

  ${breakpoint('lg')`
    font-size: 0.875rem;
    line-height: 1.125rem;
    display:block;
    text-transform: initial;
  `}
`;

export const MobileBroadcast = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: initial;
  font-size: 0.5rem;
  line-height: 0.625rem;
  display: inherit;
  margin-left: 0.5rem;

  ${breakpoint('lg')`
    display:none;
  `}
`;
