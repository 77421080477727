import styled from 'styled-components';

export const ButtonWrapper = styled.div.attrs({ className: 'my-1' })`
  width: 36px;
  height: 36px;
`;

export const DeviceName = styled.div`
  color: ${({ theme }) => theme.colors['grey-light']};
`;
