import styled from 'styled-components';

interface PropsPlayerNotificationContainer {
  $stickToBottom: boolean;
}

export const PlayerNotificationContainer = styled.div<PropsPlayerNotificationContainer>`
  position: absolute;
  bottom: ${({ $stickToBottom }) => ($stickToBottom ? '1.9rem' : '6.25rem')};
  right: 1.9rem;
`;

export const PlayerInterfaceWrapper = styled.div<{
  $fullscreen: boolean;
  $maxHeight: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;
  justify-content: center;

  video {
    width: 100%;
    max-height: ${({ $maxHeight, $fullscreen }) => ($fullscreen ? 'unset' : $maxHeight)};
    aspect-ratio: 16/9;
    background: rgba(0, 0, 0, 0.5);
    min-height: 15rem;
  }
`;

export const PlayerControlsWrapper = styled.div<{ $showControls: boolean }>`
  width: 100%;
  display: ${({ $showControls }) => ($showControls ? 'flex' : 'none')};
  position: absolute;
  bottom: 0;
  padding: 1.25rem 1.5rem 2rem 1.5rem;
  background: var(
    --gradients-black-bottom,
    linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111 100%)
  );
  z-index: ${({ theme }) => theme.zIndex.playerControls};
`;

export const LivePillWrapper = styled.div<{ $showControls: boolean }>`
  display: ${({ $showControls }) => ($showControls ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem;
`;

export const LoadingErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  width: 100%;
`;
