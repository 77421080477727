import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

import { bolder } from '../Common';

export const InlineLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.colors.primary};
  ${bolder};
  display: inline;
`;
