import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { convertPxToRem } from '../../../utils/styled/convertPxToRem';
import { bgBlue } from '../../../utils/styled/mixins';
import { Row, TypographyProps, bolder, fonts, textBase, textCenter } from '../../Common';
import { BREAKPOINT } from '../../ThemeProvider/constants/breakpoints';

export const ModalBody = styled(Modal.Body)<{ backgroundUrl?: string }>`
  ${bgBlue}
  width: 100%;
  background-image: ${({ backgroundUrl }) => `url(${backgroundUrl})`};
  overflow-y: scroll;
  height: 100vh;
`;

export const Wrapper = styled.div<{
  $mobileMinTopMargin: number;
  $defaultMinTopMargin: number;
}>`
  ${({ $mobileMinTopMargin, $defaultMinTopMargin }) => `
    margin-top: ${convertPxToRem($mobileMinTopMargin)};
    @media screen and (min-width: ${BREAKPOINT.lg}px) {
      margin-top: ${convertPxToRem($defaultMinTopMargin)};
    }
  `}

  box-sizing: border-box;
`;

export const Container = styled.div<{
  $mobileMinTopMargin: number;
  $defaultMinTopMargin: number;
}>`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $mobileMinTopMargin, $defaultMinTopMargin }) => `
     min-height: calc(100vh - 2* ${convertPxToRem($mobileMinTopMargin)});
      @media screen and (min-width: ${BREAKPOINT.lg}px) {
        min-height: calc(100vh - 2 * ${convertPxToRem($defaultMinTopMargin)});
      }
    `}
`;

export const Title = styled.h1<TypographyProps>`
  ${textBase}
  ${fonts.body1.xl}
  ${bolder}
  ${textCenter}
  color: ${({ theme }) => theme.colors['pure-white']};
  margin-bottom: 0;

  ${breakpoint('lg')`
    ${fonts.header2.xs}
  `}
`;

export const Description = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.body1.xs}
  ${textCenter}
  color: ${({ theme }) => theme.colors['pure-white']};
  margin-bottom: 0;

  ${breakpoint('lg')`
    ${fonts.body1.xl}
  `}
`;

export const Faq = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.body2.xl}
  ${textCenter}
  color: ${({ theme }) => theme.colors['pure-white']};
  margin-bottom: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const ButtonRow = styled(Row)`
  margin-top: ${convertPxToRem(140)};

  position: absolute;
  bottom: ${convertPxToRem(52)};
  padding: 0 1rem;

  ${breakpoint('md')`
      bottom:  ${convertPxToRem(61)};
  `}

  ${breakpoint('lg')`
    bottom: unset;
    padding: 0;
    position: relative;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;

  ${breakpoint('lg')`
    gap: 1.5rem;
`}
`;
