import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Video } from '../../api';
import { useFLPlayerErrorAction } from '../../hooks/flp';
import { useCalculatePlayerMaxHeight } from '../../hooks/usePlayerHeight';
import { isAuthorizedAssetSelector } from '../../store/activeSubscriptions';
import {
  doesPlayerHasErrorSelector,
  playerActions,
  playerAssetSelector,
  playerMaxHeightSelector,
  shouldFetchCompanionAdsSelector,
} from '../../store/player';
import { AppDispatch } from '../../store/store';
import { isLiveVideoSelector, selectedVideoSelector } from '../../store/videos';
import { CompanionAds } from '../CompanionAds/CompanionAds';
import { useConvivaContext } from '../ConvivaProvider/ConvivaProvider';
import { MsgStats } from '../MsgStats/MsgStats';
import Player, { PlayerProps } from '../Player/Player';
import PlayerError from '../PlayerError/PlayerError';
import { TVEAuthorization } from '../TVEProviderComponents/TVEAuthorization';
import { PlayerTimeProvider } from '../playerUI/PlayerTimeProvider/PlayerTimeProvider';
import * as Ui from './PlayerLayout.styles';

export type PlayerLayoutProps = {
  topbarComponent: React.ReactNode;
  content?: Video | null;
  playerProps?: Partial<PlayerProps>;
  showStats?: boolean;
  isVideoReady?: boolean;
};

export const PlayerLayout = ({
  topbarComponent,
  content,
  playerProps,
  showStats,
  isVideoReady = true,
}: PlayerLayoutProps) => {
  const dispatch: AppDispatch = useDispatch();

  const [companionAdVisible, setCompanionAdVisible] = useState(false);
  const playerErrorActionState = useFLPlayerErrorAction();
  const doesPlayerHasError = useSelector(doesPlayerHasErrorSelector);
  const selectedVideo = useSelector(selectedVideoSelector);
  const isAuthorized = useSelector(isAuthorizedAssetSelector(selectedVideo));
  const isLiveVideo = useSelector(isLiveVideoSelector);
  const oneColumnLayout = !isLiveVideo || !isAuthorized || !showStats || !content;
  const contentRef = useRef<HTMLDivElement>(null);
  const playerMaxHeight = useSelector(playerMaxHeightSelector);

  const shouldFetchCompanionAds = useSelector(shouldFetchCompanionAdsSelector);
  const playerAsset = useSelector(playerAssetSelector);
  const { startConvivaSession } = useConvivaContext();

  useEffect(() => {
    return () => {
      dispatch(playerActions.resetPlayerAsset());
    };
  }, []);

  useEffect(() => {
    if (playerAsset && !companionAdVisible) {
      setCompanionAdVisible(shouldFetchCompanionAds);
    }
  }, [shouldFetchCompanionAds, playerAsset]);

  useEffect(() => {
    if (selectedVideo?.id) {
      startConvivaSession();
    }
  }, [selectedVideo?.id]);

  useCalculatePlayerMaxHeight(contentRef, showStats);

  if (doesPlayerHasError) {
    return <PlayerError playerErrorActionState={playerErrorActionState} />;
  }

  return (
    <Ui.Container>
      <Ui.PlayerLayout>
        <PlayerTimeProvider>
          <Ui.PlayerTopSection>{topbarComponent}</Ui.PlayerTopSection>
          <TVEAuthorization>
            <Ui.ContentRow
              ref={contentRef}
              $visible={isVideoReady && !!playerMaxHeight}
              $isOneColumnLayout={oneColumnLayout}
            >
              <Ui.PlayerCol lg={oneColumnLayout ? 12 : 6} xl={oneColumnLayout ? 12 : 8}>
                <Player {...playerProps} playerErrorActionState={playerErrorActionState} />
                <Ui.PlayerBottomSection $statsVisible={showStats}>
                  <Ui.PlayerTitleAndDescription
                    $fullWidth={!oneColumnLayout || !companionAdVisible}
                  >
                    <Ui.PlayerContentTitle>{content?.title}</Ui.PlayerContentTitle>
                    <Ui.PlayerContentDescription>
                      {content?.description}
                    </Ui.PlayerContentDescription>
                  </Ui.PlayerTitleAndDescription>
                  {companionAdVisible && (
                    <Ui.AdSlotWrapper $isOneColumnLayout={oneColumnLayout}>
                      <CompanionAds areStatsVisible={!!showStats} />
                    </Ui.AdSlotWrapper>
                  )}
                </Ui.PlayerBottomSection>
              </Ui.PlayerCol>

              {!oneColumnLayout && (
                <Ui.SidebarCol
                  $isOneColumnLayout={oneColumnLayout}
                  lg={oneColumnLayout ? 12 : 6}
                  xl={oneColumnLayout ? 12 : 4}
                >
                  <MsgStats
                    team={content.primaryTeam as string}
                    league={content.league as string}
                  />
                </Ui.SidebarCol>
              )}
            </Ui.ContentRow>
          </TVEAuthorization>
        </PlayerTimeProvider>
      </Ui.PlayerLayout>
    </Ui.Container>
  );
};
