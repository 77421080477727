import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  ActivationCodeResponse,
  ActivationCodeValidatedResponse,
} from '../../api/authApi/authApi.types';
import { DeviceCodeState } from './deviceCode.types';

export const generateDeviceCode = createAction('deviceCode/load');

export const initialState: DeviceCodeState = {
  accessToken: '',
  activationCode: '',
  error: '',
  loading: false,
  refreshToken: '',
  sessionToken: '',
  validated: false,
  validityTill: 0,
};

const slice = createSlice({
  initialState,
  name: 'deviceCode',
  reducers: {
    getActivationCodeError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    getActivationCodeSetLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    getActivationCodeSuccess: (
      state: DeviceCodeState,
      action: PayloadAction<ActivationCodeResponse>,
    ) => ({
      ...state,
      ...action.payload,
      loading: false,
      validated: false,
    }),
    getActivationCodeValidatedSuccess: (
      state: DeviceCodeState,
      action: PayloadAction<ActivationCodeValidatedResponse>,
    ) => ({
      ...state,
      ...action.payload,
      activationCode: state.activationCode,
      loading: false,
      validated: true,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as deviceCodeActions, name, reducer };
