import { getEnv } from '../../../env';
import { PauseSubscriptionResponseMessage } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const pauseSubscription = async (
  accessToken: string,
  numberOfPauseDays: string,
  subscriptionSku?: string,
) => {
  const { PAUSE_SUBSCRIPTION_URL, EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID } = getEnv();

  const requestData = {
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
    noOfDays: numberOfPauseDays,
    pauseEffectiveFrom: 'Immediately',
    sku: subscriptionSku,
  };

  const body = { PauseSubscriptionRequestMessage: requestData };

  const response = await evergentAxiosInstance.post<{
    PauseSubscriptionResponseMessage: PauseSubscriptionResponseMessage;
  }>(PAUSE_SUBSCRIPTION_URL, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { failureMessage, ...dataResponse } = response.data.PauseSubscriptionResponseMessage;

  if (failureMessage && failureMessage.length > 0) throw new Error(failureMessage[0].errorMessage);

  return dataResponse;
};
