import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setPlayerError } from '../../store/player/player.thunks';
import { AppDispatch } from '../../store/store';
import { useHandleGoBackOnPlayer } from '../useHandleGoBackPlayer';

export const useFLPlayerErrorAction = () => {
  const dispatch: AppDispatch = useDispatch();
  const handleGoBackOnPlayer = useHandleGoBackOnPlayer();

  const goBack = useCallback(() => {
    dispatch(setPlayerError(undefined));

    handleGoBackOnPlayer();
  }, [handleGoBackOnPlayer]);

  const [localPlayerErrorAction, setLocalPlayerErrorAction] = useState(() => goBack);
  const [playerErrorAction, setPlayerErrorAction] = useState(() => localPlayerErrorAction);

  const isLocalPlayerErrorAction = localPlayerErrorAction === playerErrorAction;

  const restoreLocalPlayerErrorAction = useCallback(() => {
    setPlayerErrorAction(() => localPlayerErrorAction);
  }, [setPlayerErrorAction, localPlayerErrorAction]);

  useEffect(() => {
    setLocalPlayerErrorAction(() => goBack);
    if (isLocalPlayerErrorAction) {
      setPlayerErrorAction(() => goBack);
    }
  }, [goBack]);

  return { playerErrorAction, restoreLocalPlayerErrorAction, setPlayerErrorAction };
};

export default useFLPlayerErrorAction;
