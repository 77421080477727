import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { PlayerState } from '../../../api';
import { PlayBig } from '../../../assets/icons/PlayBig';
import { playerStateSelector } from '../../../store/player';
import { IconButton } from '../../IconButton/IconButton';
import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import * as Ui from '../PlayerUi.styles';

interface PlayButtonProps {
  showLiveControls?: boolean;
}

export const PlayButton = ({ showLiveControls }: PlayButtonProps) => {
  const { handlePlay } = usePlayerControlsContext();
  const playerState = useSelector(playerStateSelector);

  const firstRender = useRef(true);
  const previousStatus = useRef<PlayerState | null>(null);

  const PlayerButton = useMemo(() => {
    if (firstRender.current) {
      if (
        playerState === PlayerState.PAUSED &&
        previousStatus.current === PlayerState.LOADING &&
        showLiveControls
      ) {
        firstRender.current = false;
        return (
          <Ui.Wrapper>
            <IconButton aria-label='Play video' onClick={handlePlay}>
              <PlayBig />
            </IconButton>
          </Ui.Wrapper>
        );
      }

      previousStatus.current = status as PlayerState;
    }
    return null;
  }, [handlePlay, showLiveControls, playerState]);

  return PlayerButton;
};

PlayButton.displayName = 'PlayButton';
