/* eslint-disable camelcase */
import { Video } from '../../api';

export const parseAssetParams = (asset: Video | null) => {
  const event_start_date_object_it = asset?.startDate
    ? new Date(asset?.startDate).toISOString()
    : '';

  return {
    away_team: asset?.rawData ? asset.rawData.aw_tm : asset?.visitorTeam?.name,
    duration: asset?.duration,
    event_start_date_object_it,
    game_id: asset?.id,
    home_team: asset?.rawData ? asset.rawData.hm_tm : asset?.homeTeam?.name,
    league_short_name: asset?.rawData ? asset.rawData.spt_lg : asset?.programation?.leagueName,
    network_name: asset?.rawData?.net_id,
    secondary_id: asset?.rawData?.id,
    sport: asset?.programation?.sportName,
    video_title: asset?.title,
    video_type: asset?.rawData?.cty,
  };
};
