import { ChevronLeft } from '../../assets/icons';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import * as Ui from './BackButton.styles';

export type BackButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  isFloating?: boolean;
};

export const BackButton = ({ onClick, disabled, isFloating = true, ...rest }: BackButtonProps) => {
  const isDesktopView = useMediaQuery('lg');
  return (
    <Ui.Button disabled={disabled} onClick={onClick} $isFloating={isFloating} {...rest}>
      <ChevronLeft large={!isDesktopView} />
      {isDesktopView && <span>Back</span>}
    </Ui.Button>
  );
};
