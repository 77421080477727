import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { NUMBER_OF_DAYS_TO_PAUSE_SUBSCRIPTION } from '../../api/authApi/EvergentAuthApi.constants';
import { Log } from '../../helpers/CustomLogger';
import { cacheManager } from '../../utils';
import { getErrorMessage } from '../../utils/error';
import { checkAuthCompleted } from '../auth';
import { RootState } from '../types';
import { ACTIVE_SUBSCRIPTIONS_STORAGE_KEY } from './activeSubscriptions.constants';
import {
  actions,
  loadActiveSubscriptionAction,
  pauseActiveSubscriptionAction,
  resumeSubscriptionAction,
} from './activeSubscriptions.slice';

export const loadActiveSupscription = createAsyncThunk(
  loadActiveSubscriptionAction.type,
  async ({ accessToken }: { accessToken: string }, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, ACTIVE_SUBSCRIPTIONS_URL },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(actions.loadActiveSubscriptionLoading());

      const subscriptionResponse = await evergentAuthApi.getActiveSubscriptions({
        accessToken,
        activeSubscriptionsUrl: ACTIVE_SUBSCRIPTIONS_URL,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      });
      thunksapi.dispatch(actions.loadActiveSubscriptionSuccess(subscriptionResponse));
      cacheManager.save(ACTIVE_SUBSCRIPTIONS_STORAGE_KEY, subscriptionResponse);
      thunksapi.dispatch(checkAuthCompleted());
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(actions.loadActiveSubscriptionError(errorMessage));
    }
  },
);

export const clearActiveSubscription = createAsyncThunk(
  loadActiveSubscriptionAction.type,
  async (__: never, thunksapi) => {
    thunksapi.dispatch(actions.clearActiveSubscriptionSuccess());
    cacheManager.remove(ACTIVE_SUBSCRIPTIONS_STORAGE_KEY);
  },
);
export const pauseSubscription = createAsyncThunk(
  pauseActiveSubscriptionAction.type,
  async (
    { onSuccess, onError }: { onSuccess: (endDate: string) => void; onError: () => void },
    thunksapi,
  ) => {
    try {
      const {
        auth: { accessToken },
        activeSubscriptions: { lastSubscription },
      } = thunksapi.getState() as RootState;

      const subscriptionResponse = await evergentAuthApi.pauseSubscription(
        accessToken,
        NUMBER_OF_DAYS_TO_PAUSE_SUBSCRIPTION,
        lastSubscription?.serviceID,
      );

      thunksapi.dispatch(actions.pauseSubscriptionSuccess(subscriptionResponse));
      onSuccess(format(new Date(subscriptionResponse?.pauseEndDate || ''), 'MMMM d, yyyy, h:mm a'));
    } catch (error: unknown) {
      onError();
    }
  },
);

export const resumeSubscription = createAsyncThunk(
  resumeSubscriptionAction.type,
  async ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }, thunksapi) => {
    try {
      const {
        auth: { accessToken },
        activeSubscriptions: { lastSubscription },
      } = thunksapi.getState() as RootState;

      await evergentAuthApi.resumeSubscription(accessToken, lastSubscription?.serviceID);

      thunksapi.dispatch(actions.resumeSubscriptionSuccess());
      onSuccess();
    } catch (error: unknown) {
      onError();
    }
  },
);
