import { useSelector } from 'react-redux';

import { useTranslations } from '../../hooks/useTranslations';
import { availablePaymentOptionsSelector } from '../../store/purchase';
import { PaymentOption } from './PaymentOption/PaymentOption';
import * as Ui from './PaymentOptions.styles';

interface IPaymentOptions {
  selectedOption: string;
  selectOption: (option: string) => void;
}

const PaymentOptions = ({ selectOption, selectedOption }: IPaymentOptions) => {
  const t = useTranslations();
  const paymentOptions = useSelector(availablePaymentOptionsSelector);

  return (
    <>
      <Ui.Title>{t.paymentOptions_title}</Ui.Title>

      <Ui.PaymentOptions>
        {paymentOptions.map((option: string) => (
          <PaymentOption
            option={option}
            key={option}
            onClickHandler={selectOption}
            isSelected={selectedOption === option}
          />
        ))}
      </Ui.PaymentOptions>
    </>
  );
};

export { PaymentOptions };
