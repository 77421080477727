import { FailureResponse, RefreshTokenResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const refreshToken = async ({
  refreshToken,
  evergentApiKey,
  evergentChannelPartnerId,
  refreshTokenUrl,
}: {
  refreshToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  refreshTokenUrl: string;
}): Promise<RefreshTokenResponse> => {
  const refreshData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    refreshToken: refreshToken,
  };
  const body = { RefreshTokenRequestMessage: refreshData };

  const response = await evergentAxiosInstance.post<{
    RefreshTokenResponseMessage: RefreshTokenResponse & FailureResponse;
  }>(refreshTokenUrl, body);

  const { failureMessage, ...dataResponse } = response.data.RefreshTokenResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage, {
      cause: {
        errorCode: failureMessage[0].errorCode,
      },
    });
  }

  return dataResponse;
};
