import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SchemaOf } from 'yup';

import { AppDispatch } from '../store/store';
import { zipCodeUpdate, zoneInfoStateSelector } from '../store/zoneInfo';
import { ZipCodeFormFields, zipCodeSchema } from '../utils/validations';
import { useForm } from './useForm';

type ZipCodeFormValues = {
  [ZipCodeFormFields.ZipCode]: string;
};

export const useZipCodeForm = (userZipCode?: string | null) => {
  const dispatch: AppDispatch = useDispatch();

  const { updating } = useSelector(zoneInfoStateSelector);

  const { register, handleSubmit, formState } = useForm<ZipCodeFormValues>({
    defaultValues: {
      [ZipCodeFormFields.ZipCode]: userZipCode || '',
    },
    mode: 'onTouched',
    resolver: yupResolver(zipCodeSchema as SchemaOf<ZipCodeFormValues>),
    values: {
      [ZipCodeFormFields.ZipCode]: userZipCode || '',
    },
  });

  const handleFormSubmit = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    handleSubmit((values: ZipCodeFormValues) => {
      dispatch(zipCodeUpdate(values[ZipCodeFormFields.ZipCode]));
    })();
  }, [dispatch, handleSubmit]);

  const submitOnEnter: React.KeyboardEventHandler<HTMLFormElement | HTMLInputElement> = useCallback(
    (e) => {
      if (e?.key === 'Enter') {
        handleFormSubmit();
      }
    },
    [handleFormSubmit],
  );

  return {
    handleFormSubmit,
    isSubmitDisabled: updating || !formState.isValid,
    register,
    submitOnEnter,
  };
};
