import styled from 'styled-components';

import { Button as AppButton } from '../Button';
import { Col, FONT_WEIGHT, Row } from '../Common';

export const ButtonRowDescription = styled.p`
  text-align: center;
  margin-bottom: 2rem;
`;

export const BoldSpan = styled.span`
  font-weight: ${FONT_WEIGHT.Bold};
`;

export const ButtonRow = styled(Row)<{ $withSpace?: boolean }>`
  margin-top: auto;
`;

export const ButtonCol = styled(Col).attrs({
  className: 'mt-auto mx-auto text-center',
  sm: '8',
  xl: '6',
  xs: '12',
  xxl: '4',
})``;

export const Button = styled(AppButton)`
  width: 100%;
  height: 3rem;
  padding: 1.5rem 2rem;
`;
