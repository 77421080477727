import { FLCastPlayer, FLLocalPlayer } from '..';

export const flpOnProgressChangedAdapter = (
  flPlayer: FLLocalPlayer | FLCastPlayer,
  callback: (newProgress: { currentTime: number; duration: number }) => void,
) => {
  const adaptedCallback = () => {
    callback({
      currentTime: flPlayer.currentTime,
      duration: flPlayer.duration,
    });
  };
  flPlayer.subscribe('progressupdate', adaptedCallback);
  return () => {
    flPlayer.unsubscribe('progressupdate', adaptedCallback);
  };
};
