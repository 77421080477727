import { useSelector } from 'react-redux';

import { activeSubscriptionsSelector } from '../store/activeSubscriptions';
import { authSelector } from '../store/auth';
import { deviceInfoSelector } from '../store/deviceInfo';
import { hasEntitlementsSelector } from '../store/entitlements';

export const useZipCodeTopVisibility = (): { isVisible: boolean } => {
  const { isWeb } = useSelector(deviceInfoSelector);
  const { hasActiveSubscriptions } = useSelector(activeSubscriptionsSelector);
  const { isQAUser, isVIPUser } = useSelector(authSelector);
  const hasEntitlements = useSelector(hasEntitlementsSelector);

  return {
    isVisible: isWeb && !(hasActiveSubscriptions || hasEntitlements || isQAUser || isVIPUser),
  };
};
