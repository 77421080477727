import { useTranslations } from '../../../../hooks/useTranslations';
import * as UI from './GeolocationErrorDescription.styles';

const GeolocationErrorDescription = () => {
  const t = useTranslations();

  return (
    <>
      <UI.ModalDescriptionListLabel>
        {t.geolocationErrorModal_list_label}
      </UI.ModalDescriptionListLabel>
      <UI.ModalDescriptionList>
        <li>{t.geolocationErrorModal_list_1}</li>
        <li>{t.geolocationErrorModal_list_2}</li>
      </UI.ModalDescriptionList>
    </>
  );
};

export default GeolocationErrorDescription;
