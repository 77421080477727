import { useEffect } from 'react';

import useEvergentIframeContext from '../../hooks/evergent/useEvergentIframeContext';
import { ErrorContentWrapper } from '../AuthForm/AuthForm.styles';
import * as Ui from './EvergentIframeElement.styles';

export const EvergentIframeElement = ({
  onError,
  ...rest
}: {
  onError?: (errorMessage: string) => void;
}) => {
  const { iframeUrl, iframeRef, iframeError } = useEvergentIframeContext();

  useEffect(() => {
    if (iframeError && onError) {
      onError(iframeError);
    }
  }, [onError, iframeError]);

  return (
    <Ui.Wrapper {...rest}>
      <iframe ref={iframeRef} src={iframeUrl} className='w-100 rounded'></iframe>

      {onError && iframeError && <ErrorContentWrapper>{iframeError}</ErrorContentWrapper>}
    </Ui.Wrapper>
  );
};

export default EvergentIframeElement;
