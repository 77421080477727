import { SVGProps } from 'react';

import { colors } from '../../components/ThemeProvider/constants/colors';

export const CreditCardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='69' height='48' viewBox='0 0 69 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Payment method'>
      <path
        id='Vector'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.1271 0H11.4443C5.11874 0 0 5.13391 0 11.4783V36.5217C0 42.8661 5.11874 48 11.4443 48H57.1271C63.4527 48 68.5714 42.8661 68.5714 36.5217V11.4783C68.5714 5.13391 63.4527 0 57.1271 0ZM11.4443 4.17391H57.1271C61.143 4.17391 64.4098 7.45043 64.4098 11.4783V12.5217H4.16158V11.4783C4.16158 7.45043 7.42841 4.17391 11.4443 4.17391ZM57.1271 43.8261H11.4443C7.42841 43.8261 4.16158 40.5496 4.16158 36.5217V16.6957H64.4098V36.5217C64.4098 40.5496 61.143 43.8261 57.1271 43.8261Z'
        fill={props.fill || colors['off-white']}
      />
    </g>
  </svg>
);
