import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { CouchRightsState } from './couchRights.types';

export const initialState: CouchRightsState = {
  endDate: '',
  shouldUpdateCouchRightsOnStreamStart: true,
  timeOfLastUpdate: undefined,
};

export const loadCouchRightsAction = createAction('couchRights/loadCouchRights');
export const addOrUpdateCouchRightsAction = createAction('couchRights/addOrUpdateCouchRights');

const slice = createSlice({
  initialState: initialState,
  name: 'couchRights',
  reducers: {
    loadCouchRightsSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      endDate: action.payload,
      error: undefined,
    }),
    setInitialState: (state) => ({
      ...state,
      ...initialState,
    }),
    setShouldUpdateCouchRightsOnStreamStart: (state, action: PayloadAction<boolean>) => ({
      ...state,
      shouldUpdateCouchRightsOnStreamStart: action.payload,
    }),
    updateCouchRightsSuccess: (state, action: PayloadAction<string | null>) => ({
      ...state,
      endDate: action.payload,
      timeOfLastUpdate: Date.now(),
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as couchRightsActions, name, reducer };
