import { createSlice } from '@reduxjs/toolkit';

import { fetchMsgStats } from './msgStats.thunks';
import { StatsState } from './msgStats.types';

const initialState: StatsState = {
  configItems: [],
  isLoading: true,
};

const slice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchMsgStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMsgStats.fulfilled, (state, action) => {
        state.configItems = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchMsgStats.rejected, (state) => {
        state.isLoading = false;
      });
  },
  initialState,
  name: 'msgStats',
  reducers: {},
});

const { reducer } = slice;

export { initialState, reducer };
