import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChannels } from '../store/channels';
import { AppDispatch } from '../store/store';
import { zoneNameSelector } from '../store/zoneInfo';

export const useLoadChannels = () => {
  const dispatch: AppDispatch = useDispatch();
  const userZone = useSelector(zoneNameSelector);

  useEffect(() => {
    dispatch(getChannels());
  }, [userZone]);
};
