import { useSelector } from 'react-redux';

import { fullScreenSelector } from '../../store/player';
import { selectedVideoSelector } from '../../store/videos';
import { useEnv } from '../EnvProvider/EnvProvider';
import ChromecastButton from '../playerUI/ChromecastButton/ChromecastButton';
import ClosedCaptionsButton from '../playerUI/ClosedCaptionsButton/ClosedCaptionsButton';
import { ProgressBar } from '../playerUI/ProgessBar/ProgressBar';
import { RestartButton } from '../playerUI/RestartButton/RestartButton';
import { SeekToLiveButton } from '../playerUI/SeekToLiveButton/SeekToLiveButton';
import VolumeControl from '../playerUI/VolumeControl';
import FullScreenButton from '../playerUI/fullScreenButton';
import PlayPauseButton from '../playerUI/playPauseButton';
import * as Ui from './PlayerControls.styles';

export const PlayerControlsLive = () => {
  const selectedVideo = useSelector(selectedVideoSelector);
  const fullscreen = useSelector(fullScreenSelector);

  const env = useEnv();
  return (
    <Ui.Wrapper>
      {fullscreen && <Ui.PlayerTitle>{selectedVideo?.title}</Ui.PlayerTitle>}
      {env.IS_VISIBLE_PROGRESSBAR_ON_LIVE && <ProgressBar />}
      <Ui.ControlsWrapper>
        <Ui.ButtonsWrapper>
          <VolumeControl />
        </Ui.ButtonsWrapper>
        {env.IS_VISIBLE_PLAY_PAUSE_BUTTON_ON_LIVE && (
          <Ui.PlayPauseButtonWrapper>
            <PlayPauseButton />
          </Ui.PlayPauseButtonWrapper>
        )}
        <Ui.ButtonsWrapper>
          <RestartButton />
          <SeekToLiveButton />
          <ClosedCaptionsButton />
          <ChromecastButton />
          <FullScreenButton />
        </Ui.ButtonsWrapper>
      </Ui.ControlsWrapper>
    </Ui.Wrapper>
  );
};

export default PlayerControlsLive;
