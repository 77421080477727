export function getMonthName(monthNumber: number) {
  const date = new Date();

  if (monthNumber < 0) {
    date.setMonth(Math.abs(12 + monthNumber) % 12);
  } else {
    date.setMonth(monthNumber % 12);
  }

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}
