import { SVGProps } from 'react';

export const VolumeDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.8192 6.7783C24.2017 6.96424 24.4446 7.35227 24.4446 7.77762V32.2221C24.4446 32.6474 24.2017 33.0355 23.8192 33.2214C23.4366 33.4073 22.9814 33.3586 22.647 33.0958L14.4643 26.6665H6.66678C6.05313 26.6665 5.55566 26.1691 5.55566 25.5554V14.4443C5.55566 13.8306 6.05313 13.3332 6.66678 13.3332H14.4643L22.647 6.90394C22.9814 6.64114 23.4366 6.59236 23.8192 6.7783ZM22.2223 10.0637L15.5351 15.318C15.3393 15.4718 15.0976 15.5554 14.8486 15.5554H7.77789V24.4443H14.8486C15.0976 24.4443 15.3393 24.5279 15.5351 24.6817L22.2223 29.936V10.0637Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.1508 17.5479C28.5848 17.114 29.2883 17.114 29.7222 17.5479L34.2619 22.0876C34.6959 22.5215 34.6959 23.2251 34.2619 23.659C33.828 24.0929 33.1245 24.0929 32.6906 23.659L28.1508 19.1192C27.7169 18.6853 27.7169 17.9818 28.1508 17.5479Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.1582 17.5396C33.7197 17.1103 33.0162 17.1178 32.5869 17.5563L28.1425 22.096C27.7132 22.5345 27.7206 23.238 28.1591 23.6673C28.5976 24.0966 29.3011 24.0891 29.7304 23.6506L34.1748 19.1108C34.6041 18.6724 34.5966 17.9689 34.1582 17.5396Z'
      fill={props.fill || 'white'}
    />
  </svg>
);
