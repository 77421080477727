import styled from 'styled-components';

import { Clock } from '../../assets/icons/Clock';
import { breakpoint } from '../../utils/breakpoint';
import { FONT_WEIGHT } from '../Common';
import * as PlayerUI from '../playerUI/PlayerUi.styles';

export const TextWrapper = styled.div``;

export const Icon = styled(Clock)`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  margin-bottom: 1rem;

  ${breakpoint('lg')`
    width: 3rem;
    height: 3rem;
    margin-bottom: 2rem; 
  `};
`;

export const Wrapper = styled(PlayerUI.Wrapper).attrs({ id: 'wrapper' })<{
  $maxHeight: string;
}>`
  aspect-ratio: 16/9;
  position: relative;
  max-height: ${({ $maxHeight }) => $maxHeight};
  width: 100%;
`;

export const ErrorWrapperInner = styled.div`
  font-family: 'GothamNarrow';
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ErrorTitle = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.125rem;
  text-align: center;
  margin: 0 auto 0.5rem;

  ${breakpoint('lg')`
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1rem; 
  `}
`;
export const ErrorDescription = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  font-size: 0.875rem;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.125rem;
  text-align: center;
  margin: 0 auto;

  ${breakpoint('lg')`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}
`;
