import { OnApproveData, PayPalNamespace, loadScript } from '@paypal/paypal-js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import evergentAuthApi from '../../../api/authApi/EvergentAuthApi';
import { Log } from '../../../helpers/CustomLogger';
import { useTranslations } from '../../../hooks/useTranslations';
import { accessTokenSelector } from '../../../store/auth';
import { purchaseActions } from '../../../store/purchase';
import { AppDispatch } from '../../../store/store';
import { useEnv } from '../../EnvProvider/EnvProvider';
import { ErrorType, TError } from '../../PurchaseModal/CostSummary/constants';
import { PayPalContainerId } from './contants';

interface IPayPal {
  totalAmount: number;
  onErrorHandler: (error: TError) => void;
}

const PayPal = ({ totalAmount, onErrorHandler }: IPayPal) => {
  const t = useTranslations();
  const paypalContainerRef = useRef<HTMLDivElement>(null);

  const accessToken = useSelector(accessTokenSelector);
  const { PAYPAL_CLIENT_ID } = useEnv();
  const dispatch: AppDispatch = useDispatch();
  const [paypal, setPayPal] = useState<PayPalNamespace | null>(null);

  useEffect(() => {
    loadScript({
      clientId: PAYPAL_CLIENT_ID,
      disableFunding: ['card', 'credit', 'bancontact', 'blik', 'paylater'],
      intent: 'authorize',
    })
      .then((paypal: PayPalNamespace | null): void | PromiseLike<void> => {
        setPayPal(paypal);
      })
      .catch((error: Error) => {
        Log.error('failed to load the PayPal JS SDK script', error);
      });
  }, []);

  useEffect(() => {
    if (paypal?.Buttons) {
      paypal
        .Buttons({
          createOrder: () =>
            evergentAuthApi
              .paypalSetExpressCheckout({
                accessToken,
                totalAmount,
              })
              .then((res) => {
                dispatch(purchaseActions.setPaypalExpressCheckoutToken(res));
                return res;
              }),
          onApprove: (data: OnApproveData): Promise<void> => {
            dispatch(purchaseActions.setPayPalOnApproveData(data));
            return Promise.resolve();
          },
          onCancel: () => {
            return onErrorHandler({
              message: t.paypal_payment_cancelled,
              type: ErrorType.Warning,
            });
          },
          onError: () => {
            return onErrorHandler({
              message: t.paypal_payment_error,
              type: ErrorType.Error,
            });
          },
          style: {
            disableMaxWidth: true,
            height: 52,
            label: 'buynow',
          },
        })
        .render(PayPalContainerId)
        .catch((error: Error) => Log.error('failed to render Paypal Buttons', error));
    }

    return () => {
      if (paypalContainerRef.current) {
        paypalContainerRef.current.innerHTML = '';
      }
    };
  }, [paypal, accessToken, totalAmount]);

  return <div ref={paypalContainerRef} id='paypal-container'></div>;
};

export { PayPal };
