import styled, { css } from 'styled-components';

import { bolder, fonts, textBase } from '../../Common/Typography.styles';

type InputProps = {
  $mb?: number;
  $hasError?: boolean;
};

const focusedStyle = css`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  padding-left: calc(0.75rem - 1.5px);
  outline: none;
  background-color: ${({ theme }) => theme.colors['primary-light']};
  box-shadow: none;

  &::placeholder {
    ${bolder}
  }
`;

export const StyledInput = styled.input<InputProps>`
  ${(props) => textBase(props)};
  ${fonts.body2.xl}

  display: flex;
  height: 3rem;
  padding: 0 0.75rem;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  border-radius: 0.25rem;
  border: 0.5px solid ${({ theme }) => theme.colors['grey-soft']};
  background-color: ${({ theme }) => theme.colors['pure-white']};
  color: ${({ theme }) => theme.colors['pure-black']};
  ${bolder}

  ${({ $hasError }) => $hasError && focusedStyle}

  &::placeholder {
    color: ${({ theme }) => theme.colors['pure-black']};
  }

  ${({ $hasError }) => $hasError && focusedStyle}

  &:active,
  &:focus-visible {
    ${focusedStyle}
  }

  &:disabled,
  .disabled {
    background-color: ${({ theme }) => theme.colors['grey-medium']};
  }
`;
