/* eslint-disable camelcase */
import { useSearchParams } from 'react-router-dom';

const SUPPORTED_UTM_PARAMS = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

export const useGetUtmParams = () => {
  const [searchParams] = useSearchParams();

  const utm_params = {
    utm_campaign: searchParams.get('utm_campaign') ? searchParams.get('utm_campaign') : undefined,
    utm_content: searchParams.get('utm_content') ? searchParams.get('utm_content') : undefined,
    utm_medium: searchParams.get('utm_medium') ? searchParams.get('utm_medium') : undefined,
    utm_source: searchParams.get('utm_source') ? searchParams.get('utm_source') : undefined,
    utm_term: searchParams.get('utm_term') ? searchParams.get('utm_term') : undefined,
  };

  return utm_params;
};

export const getUtmParams = (queryString: string | undefined): string | undefined => {
  if (!queryString?.length) return;

  const searchParams = new URLSearchParams(queryString);
  return Array.from(searchParams.entries())
    .filter(([param]) => SUPPORTED_UTM_PARAMS.includes(param))
    .map(([param, value]) => `${param}=${value}`)
    .join('&');
};

export default useGetUtmParams;
