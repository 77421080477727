import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { ModalContext, ModalContextValue, ModalState } from './context';

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [{ content, isDisplayed, modalProps }, setState] = useState<ModalState>({
    content: null,
    isDisplayed: false,
  });

  const openModal = useCallback(
    (newContent: ModalState['content'], newModalProps?: ModalState['modalProps']) => {
      setState((currentState) => ({
        ...currentState,
        content: newContent,
        isDisplayed: true,
        modalProps: newModalProps,
      }));
    },
    [],
  );

  const closeModal = useCallback(() => {
    setState((currentState) => ({ ...currentState, isDisplayed: false }));
  }, []);

  const contextValue = useMemo(
    (): ModalContextValue => ({ closeModal, isDisplayed, openModal }),
    [openModal, closeModal, isDisplayed],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <Modal show={isDisplayed} onHide={closeModal} {...modalProps}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
};
