import { createSelector } from '@reduxjs/toolkit';

import { PROMO_TYPE } from '../../Constants/promotions';
import { RootState } from '../types';
import { ConfigAppState } from './config.types';

export const configSelector = (state: RootState): ConfigAppState => state.appConfig;

export const isActionRequiredSelector = createSelector(
  configSelector,
  (appConfig) => appConfig.env.ACTION_REQUIRED,
);

export const envSelector = createSelector(configSelector, ({ env }) => env);
export const teamsConfigSelector = createSelector(configSelector, ({ teams }) => teams);

const allPromotionsSelector = createSelector(
  configSelector,
  (appConfig) => appConfig.env.PROMOTIONS ?? [],
);

const seasonTicketHolderPromoActiveSelector = createSelector(
  configSelector,
  (appConfig) => appConfig.env.SEASON_TICKET_HOLDER_PROMOTION_REDEMPTION ?? false,
);

export const activePromotionsSelector = createSelector(
  allPromotionsSelector,
  seasonTicketHolderPromoActiveSelector,
  (promotions, seasonTicketHolderPromoActive) =>
    promotions.filter((promo) => {
      if (promo.type === PROMO_TYPE.seasonTicketHolder) {
        return seasonTicketHolderPromoActive;
      }

      return !!promo.id;
    }),
);

export const upSellSelector = createSelector(
  configSelector,
  (appConfig) => appConfig.env.UPSELL ?? [],
);
