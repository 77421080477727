import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isAdobeError } from '../../api/authApi/adobeApi/adobeApi.helpers';
import { PlayIcon2 } from '../../assets/icons/PlayIcon2';
import { Log } from '../../helpers/CustomLogger';
import { useGetMediaShortToken } from '../../hooks/evergent/useGetMediaShortToken';
import { useTVEProviderUrl } from '../../hooks/evergent/useTVEProviderUrl';
import { useTranslations } from '../../hooks/useTranslations';
import { activeSubscriptionsSelector } from '../../store/activeSubscriptions';
import { authActions } from '../../store/auth';
import { deviceIdSelector } from '../../store/deviceInfo';
import { AppDispatch } from '../../store/store';
import { Loader } from '../Loader/Loader';
import { useSimpleModalContext } from '../Modals/SimpleModal/SimpleModalContext';
import * as Ui from './TVEAuthorization.styles';

export const TVEAuthorization = ({ children }: PropsWithChildren) => {
  const { closeModal, openModal } = useSimpleModalContext();
  const dispatch: AppDispatch = useDispatch();
  const t = useTranslations();
  const { hasTveProvider } = useSelector(activeSubscriptionsSelector);
  const deviceId = useSelector(deviceIdSelector);
  const { getMediaShortToken, getAdobeToken } = useGetMediaShortToken();
  const currentLocation = useLocation();
  const redirectBackUrl = currentLocation.pathname;
  const tveProviderUrl = useTVEProviderUrl({ redirectBackUrl });
  const [tryAgainAction, setTryAgainAction] = useState<'loadAdobeToken' | 'tveLogin'>();
  const [loaded, setLoaded] = useState(false);

  const showModalError = useCallback(
    ({
      error,
      tryAgain,
    }: {
      error: { errorCode: string; errorMessage: string };
      tryAgain: () => void;
    }) => {
      openModal({
        acceptButton: {
          onClick: tryAgain,
          text: t.retryModal_retryButton,
        },
        cancelButton: {
          text: t.global_close,
        },
        description: `[${error.errorCode}]: ${error.errorMessage}`,
        modalName: 'TVE authorization error',

        title: t.retryModal_title,
      });
    },
    [],
  );

  const loadAdobeToken = useCallback(() => {
    setLoaded(false);

    getAdobeToken()
      .then((adobeAccessToken) => {
        return getMediaShortToken({ adobeAccessToken, deviceId })
          .then((mediaToken) => {
            dispatch(authActions.setTveToken(mediaToken.serializedToken));
            setLoaded(true);
            setTryAgainAction(undefined);
          })
          .catch((e) => {
            const error = e as { response?: { status?: number }; message?: string };
            const errorCode = error.response?.status as number;
            if (isAdobeError(errorCode)) {
              Log.error('adobe error. redirecting to tve login', errorCode, e);
              setLoaded(true);
              setTryAgainAction('tveLogin');
            } else {
              throw {
                errorCode: String(errorCode),
                errorMessage: String(error.message),
              };
            }
          });
      })
      .catch((error) => {
        const tryAgain = () => {
          closeModal();
          loadAdobeToken();
        };
        showModalError({ error, tryAgain });
        setLoaded(true);
        setTryAgainAction('loadAdobeToken');
      });
  }, [getAdobeToken, getMediaShortToken, showModalError, dispatch, deviceId]);

  const tryAgain = useCallback(() => {
    if (tryAgainAction === 'loadAdobeToken' && deviceId) {
      loadAdobeToken();
    } else if (tryAgainAction === 'tveLogin') {
      location.href = tveProviderUrl();
    }
  }, [tryAgainAction, loadAdobeToken, tveProviderUrl, deviceId]);

  useEffect(() => {
    if (hasTveProvider && deviceId) {
      loadAdobeToken();
    } else {
      setLoaded(true);
    }
  }, [hasTveProvider, loadAdobeToken, deviceId]);

  return (
    <>
      {!loaded && <LoadingBox />}
      {loaded && tryAgainAction && <RetryBox tryAgain={tryAgain} />}
      {loaded && !tryAgainAction && <>{children}</>}
    </>
  );
};

const RetryBox = ({ tryAgain }: { tryAgain: () => void }) => {
  return (
    <Ui.RetryBox
      className='d-flex justify-content-center align-items-center w-100'
      onClick={tryAgain}
    >
      <PlayIcon2 />
    </Ui.RetryBox>
  );
};

const LoadingBox = () => {
  return (
    <Ui.LoadingBox className='d-flex justify-content-center align-items-center w-100'>
      <Loader />
    </Ui.LoadingBox>
  );
};
