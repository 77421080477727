import { ErrorCodeValue } from '../../Constants/ErrorCodesTVEMap';
import {
  REFRESH_TOKEN_ERROR_CODES,
  REFRESH_TOKEN_ERROR_CODES_FOR_LOGOUT_USER,
  URLS_EXCLUDED_FROM_REFRESH_TOKEN,
} from './EvergentAuthApi.constants';
import { EvergentAuthApiBody, EvergentAuthApiResponse } from './EvergentAuthApi.types';

const isErrorResponse = (response: EvergentAuthApiBody): boolean =>
  Array.isArray(response.failureMessage) && response.failureMessage.length > 0;

export const shouldOmitCheckingRefreshToken = (url: string, responseBody: EvergentAuthApiBody) =>
  !isErrorResponse(responseBody) || URLS_EXCLUDED_FROM_REFRESH_TOKEN.includes(url as string);

export const isRefreshTokenErrorCode = (errorCode: ErrorCodeValue) =>
  REFRESH_TOKEN_ERROR_CODES.includes(errorCode);

export const shouldLogoutUser = (errorCode: ErrorCodeValue) =>
  REFRESH_TOKEN_ERROR_CODES_FOR_LOGOUT_USER.includes(errorCode);

export const parseResponseBody = (data: EvergentAuthApiResponse) => data[Object.keys(data)[0]];
