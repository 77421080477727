import { useCallback, useMemo } from 'react';

import { getScreenName } from '../../../utils/getScreenName';
import { Button } from '../../Button/Button';
import { ButtonVariant } from '../../Button/Button.types';
import { ClickEventDispatcher } from '../../ClickEventDispatcher/ClickEventDispatcher';
import * as Ui from './SimpleModal.styles';
import { ModalProps, useSimpleModalContext } from './SimpleModalContext';

export const SimpleModal = (props: ModalProps) => {
  const {
    content,
    isOpen,
    title,
    description,
    acceptButton,
    cancelButton,
    centered,
    autoClose,
    reverseButtonOnMobile,
    modalName,
  } = props;
  const { closeModal } = useSimpleModalContext();

  const screenName = useMemo(() => getScreenName(), [isOpen]);

  const handleCancelClick = useCallback(() => {
    if (cancelButton?.onClick) {
      cancelButton.onClick();
    }

    if (autoClose) {
      closeModal();
    }
  }, [cancelButton, autoClose]);

  const handleAcceptClick = useCallback(() => {
    if (acceptButton?.onClick) {
      acceptButton.onClick();
    }

    if (autoClose) {
      closeModal();
    }
  }, [acceptButton?.onClick, autoClose]);

  return (
    <Ui.Modal show={isOpen} keyboard={false} centered={centered}>
      {title && <Ui.Title $uppercase>{title}</Ui.Title>}
      {description && <Ui.DescritpionText>{description}</Ui.DescritpionText>}
      {content}
      {(acceptButton || cancelButton) && (
        <Ui.ButtonsWrapper
          $singleButton={[cancelButton, acceptButton].filter((i) => i).length === 1}
          $reverseButtonOnMobile={reverseButtonOnMobile}
          $withTopSpacing={!!description}
        >
          {acceptButton && (
            <ClickEventDispatcher
              destination={acceptButton?.destination}
              location={`Modal: ${modalName}`}
              screen={screenName}
              text={`CTA: ${acceptButton.text}`}
            >
              <Button
                variant={ButtonVariant.Primary}
                text={acceptButton.text}
                onPress={handleAcceptClick}
                className={acceptButton.className}
              >
                {acceptButton.children}
              </Button>
            </ClickEventDispatcher>
          )}
          {cancelButton && (
            <ClickEventDispatcher
              location={`Modal: ${modalName}`}
              screen={screenName}
              text={`CTA: ${cancelButton.text}`}
            >
              <Button
                variant={ButtonVariant.Secondary}
                text={cancelButton.text}
                onPress={handleCancelClick}
                className={cancelButton.className}
              >
                {cancelButton.children}
              </Button>
            </ClickEventDispatcher>
          )}
        </Ui.ButtonsWrapper>
      )}
    </Ui.Modal>
  );
};
