import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { ZIPCODE_BAR_TRANSITION_TIME_MS } from '../components/Layout/Layout.styles';
import {
  HORIZONTAL_LARGE_HEIGHT,
  HORIZONTAL_SMALL_HEIGHT,
  PLAYER_BOTTOM_SECTION_PADDING,
  TITLE_AND_DESCRIPTION_HEIGHT,
  VERTICAL_LARGE_HEIGHT,
} from '../components/PlayerLayout/PlayerLayout.styles';
import { isAuthorizedAssetSelector } from '../store/activeSubscriptions';
import {
  playerActions,
  playerAssetSelector,
  playerMaxHeightSelector,
  shouldFetchCompanionAdsSelector,
} from '../store/player';
import { programsSelector } from '../store/programs';
import { selectedVideoSelector } from '../store/videos';
import { useMediaQuery } from './useMediaQuery';

export const useCalculatePlayerMaxHeight = (
  ref: React.RefObject<HTMLDivElement>,
  showStats?: boolean,
) => {
  const dispatch = useDispatch();
  const offsetTop = ref.current?.getBoundingClientRect().top;

  const { isZipBarVisible } = useTheme();
  const playerMaxHeight = useSelector(playerMaxHeightSelector);

  const isNotPhone = useMediaQuery('md');
  const isLargeDesktop = useMediaQuery('xxl');

  const shouldFetchCompanionAds = useSelector(shouldFetchCompanionAdsSelector);
  const asset = useSelector(playerAssetSelector);
  const selectedVideo = useSelector(selectedVideoSelector);
  const isAuthorized = useSelector(isAuthorizedAssetSelector(selectedVideo));
  const { error: programError } = useSelector(programsSelector);

  const playerBottomSectionHeight = useMemo(() => {
    if (shouldFetchCompanionAds) {
      if (showStats || !isLargeDesktop) {
        return HORIZONTAL_LARGE_HEIGHT;
      } else {
        if (isNotPhone) {
          return VERTICAL_LARGE_HEIGHT;
        } else {
          return HORIZONTAL_SMALL_HEIGHT;
        }
      }
    } else {
      return `calc(${TITLE_AND_DESCRIPTION_HEIGHT} + 2 * ${PLAYER_BOTTOM_SECTION_PADDING})`;
    }
  }, [offsetTop, isNotPhone, shouldFetchCompanionAds, showStats, isLargeDesktop]);

  useEffect(() => {
    if (offsetTop !== undefined && offsetTop > 0 && (asset || !isAuthorized || programError)) {
      dispatch(
        playerActions.setPlayerMaxHeight(
          `calc(100vh - calc(${offsetTop}px + calc(${playerBottomSectionHeight})))`,
        ),
      );
    }
  }, [playerBottomSectionHeight, offsetTop, asset, isAuthorized, programError]);

  // adjust player height when zipcode topbar has changed his visibility
  useEffect(() => {
    if (playerMaxHeight) {
      setTimeout(() => {
        dispatch(
          playerActions.setPlayerMaxHeight(
            `calc(100vh - calc(${
              ref.current?.getBoundingClientRect().top
            }px + calc(${playerBottomSectionHeight})))`,
          ),
        );
      }, ZIPCODE_BAR_TRANSITION_TIME_MS);
    }
  }, [isZipBarVisible]);
};
