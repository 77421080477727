import {
  GetActiveSubscriptionsResponse,
  ProductServiceStatusEnum,
  ProductServiceTypeEnum,
} from '../../../api/authApi/authApi.types';

export const activeSubscriptionsResponseToState = (
  activeSubscriptionsResponse: GetActiveSubscriptionsResponse | null,
) => {
  const accountServices = activeSubscriptionsResponse?.AccountServiceMessage || [];
  const subscriptions = accountServices
    .filter((accountService) => accountService.serviceType === ProductServiceTypeEnum.SVOD)
    .sort((as1, acs2) => as1.validityTill - acs2.validityTill);
  const lastSubscription =
    subscriptions.find(
      ({ subscriptionStatus }) =>
        [
          ProductServiceStatusEnum.ACTIVE,
          ProductServiceStatusEnum.ACTIVE_CANCELLED,
          ProductServiceStatusEnum.ACTIVE_IN_GRACE,
          ProductServiceStatusEnum.PAUSED,
          ProductServiceStatusEnum.ACTIVE_ALREADY_PAUSED,
        ].indexOf(subscriptionStatus) !== -1,
    ) || null;
  const canCancelSubscription =
    lastSubscription?.subscriptionStatus === ProductServiceStatusEnum.ACTIVE;
  const hasActiveSubscriptions = !!lastSubscription;
  const tveProviderName = lastSubscription?.mvpdName;
  const hasTveProvider = !!tveProviderName;

  return {
    canCancelSubscription,
    hasActiveSubscriptions,
    hasTveProvider,
    lastSubscription,
    subscriptions,
    tveProviderName,
  };
};
