import { OnApproveData } from '@paypal/paypal-js';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  addSubscriptions,
  addTVODOrder,
  applyVoucherCode,
  getPurchaseItem,
} from './purchase.thunks';
import { PurchaseState } from './purchase.types';

export const initialState: PurchaseState = {
  availablePaymentOptions: [],
  error: '',
  item: undefined,
  loading: false,
  payPalOnApproveData: null,
  paymentRequestComplete: false,
  paymentRequestPending: false,
  paypalExpressCheckoutToken: '',
  selectedPaymentOption: '',
  voucherCode: '',
  voucherCodeError: undefined,
  voucherCodeLoading: false,
};

const purchaseSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(addSubscriptions.pending, (state) => {
        state.paymentRequestPending = true;
      })
      .addCase(addSubscriptions.fulfilled, (state) => {
        state.error = '';
        state.paymentRequestPending = false;
        state.paymentRequestComplete = true;
      })
      .addCase(addSubscriptions.rejected, (state, action) => {
        state.paymentRequestPending = false;
        state.paymentRequestComplete = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.error = action.payload;
      })
      .addCase(addTVODOrder.pending, (state) => {
        state.paymentRequestPending = true;
      })
      .addCase(addTVODOrder.fulfilled, (state) => {
        state.error = '';
        state.paymentRequestPending = false;
        state.paymentRequestComplete = true;
      })
      .addCase(getPurchaseItem.fulfilled, (state, action) => {
        state.item = action.payload;
        state.voucherCode = '';
        state.voucherCodeError = undefined;
      })
      .addCase(addTVODOrder.rejected, (state, action) => {
        state.paymentRequestPending = false;
        state.paymentRequestComplete = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.error = action.payload;
      })
      .addCase(applyVoucherCode.pending, (state) => {
        state.voucherCodeLoading = true;
        state.voucherCodeError = undefined;
      })
      .addCase(applyVoucherCode.fulfilled, (state, action) => {
        const { voucherCode, ...item } = action.payload;

        state.voucherCode = voucherCode;
        state.item = item;
        state.voucherCodeLoading = false;
        state.voucherCodeError = undefined;
      })
      .addCase(applyVoucherCode.rejected, (state, action) => {
        state.voucherCodeLoading = false;
        // @ts-expect-error TODO cannot figure out why error occurs
        state.voucherCodeError = action.payload;
      });
  },
  initialState,
  name: 'purchase',
  reducers: {
    resetPurchaseState: (state) => ({
      ...initialState,
      availablePaymentOptions: state.availablePaymentOptions,
    }),
    selectPaymentOption: (state: PurchaseState, action: PayloadAction<string>) => ({
      ...state,
      selectedPaymentOption: action.payload,
    }),
    setPayPalOnApproveData: (
      state: PurchaseState,
      action: PayloadAction<OnApproveData | null>,
    ) => ({
      ...state,
      payPalOnApproveData: action.payload,
    }),
    setPaymentError: (state: PurchaseState, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      paymentRequestComplete: true,
      paymentRequestPending: false,
    }),
    setPaymentOptions: (
      state: PurchaseState,
      action: PayloadAction<PurchaseState['availablePaymentOptions']>,
    ) => ({
      ...state,
      availablePaymentOptions: action.payload,
    }),
    setPaypalExpressCheckoutToken: (state: PurchaseState, action: PayloadAction<string>) => ({
      ...state,
      paypalExpressCheckoutToken: action.payload,
    }),
  },
});

const { reducer, actions } = purchaseSlice;

export { actions as purchaseActions, reducer };
