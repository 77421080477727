import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { bgBlue } from '../../../utils/styled/mixins';
import {
  CONTAINER_MARGIN_X_MD,
  CONTAINER_MARGIN_X_XL,
  CONTAINER_MARGIN_X_XS,
  Row,
  TypographyProps,
  bolder,
  fonts,
  textBase,
  textCenter,
  uppercase,
} from '../../Common';
import { BREAKPOINT } from '../../ThemeProvider/constants/breakpoints';

export const ModalRoot = styled(Modal)`
  ${textCenter}

  .promotion-dialog {
    margin-top: 0;
    width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    width: calc(100% - 2 * ${CONTAINER_MARGIN_X_XS});
    max-width: calc(${BREAKPOINT.QHD}px - 2 * ${CONTAINER_MARGIN_X_XL});

    ${breakpoint('md')`
      width: calc(100% - 2 * ${CONTAINER_MARGIN_X_MD});
      margin-top: 2.75rem;
    `}

    ${breakpoint('xl')`
      width: calc(100% - 2 * ${CONTAINER_MARGIN_X_XL});
      margin-top: 5rem;
    `}

    ${breakpoint('xxl')`
      width: calc(100% - 2 * ${CONTAINER_MARGIN_X_XL});
      margin-top: 5rem;
    `}
  }
`;

export const ModalBody = styled(Modal.Body)`
  padding: 3.5rem 1rem 0.5rem 1rem;
  position: relative;
  overflow-x: hidden;
  ${bgBlue}

  ${breakpoint('md')`
    padding-top: 4.25rem;
    padding-bottom: 1rem;
  `}

  ${breakpoint('xl')`
    padding: 2.5rem 0 2.5rem 0;
  `}
`;

export const Title = styled.h1<TypographyProps>`
  ${textBase}
  ${fonts.header2.xs}
  ${bolder}
  ${uppercase}
  ${textCenter}
  color: ${({ theme }) => theme.colors['pure-white']};
  margin-bottom: 2.5rem;

  ${breakpoint('xl')`
    ${fonts.header2.xl}
  `}
`;

export const Description = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.title.xs}
  ${textCenter}
  color: ${({ theme }) => theme.colors['pure-white']};
  margin-bottom: 3rem;

  ${breakpoint('xl')`
    ${fonts.body1.xl}
  `}
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${breakpoint('md')`
    svg {
      width: 2.25rem;
      height: 2.25rem;
    }
  `}

  ${breakpoint('xl')`
    top: 2.5rem;
    right: 2.5rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`;

export const LogoWrapper = styled.div`
  ${textCenter}
  width: 14rem;
  max-width: 100%;
  margin: 0 auto 1.5rem auto;

  img {
    max-width: 100%;
  }

  ${breakpoint('md')`
    margin-bottom: 3rem;
    width: 20rem;
  `}
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  ${breakpoint('md')`
    gap: 1.5rem;
  `}

  ${breakpoint('xl')`
    flex-direction: row;
    align-items: flex-start;
  `}
`;

export const ButtonRow = styled(Row)`
  margin-top: 1.5rem;

  ${breakpoint('md')`
    margin-top: 3rem;
  `}

  ${breakpoint('xl')`
    margin-top: 4.5rem;
  `}
`;

export const Line = styled.div`
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors['scrim-white']};

  ${breakpoint('xl')`
    display: none;
  `}
`;
