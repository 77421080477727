import { FLCastPlayer, FLLocalPlayer } from '..';

export const flpOnStateChangedAdapter = (
  flPlayer: FLLocalPlayer | FLCastPlayer,
  callback: (newState: string) => void,
) => {
  const adaptedCallback = (state: string) => {
    callback(state);
  };
  flPlayer.subscribe('playbackstatechanged', adaptedCallback);
  return () => {
    flPlayer.unsubscribe('playbackstatechanged', adaptedCallback);
  };
};
