import { Rewind } from '../../assets/icons/Rewind';
import { usePlayerTimeContext } from './PlayerTimeProvider/PlayerTimeProvider';
import { SKIPPING_STEP_IN_SECONDS } from './PlayerUI.constants';
import * as Ui from './PlayerUi.styles';

export default function RewindButton() {
  const { setSeekValue, currentTime } = usePlayerTimeContext();

  return (
    <Ui.PlayerButton
      aria-label='previous song'
      onClick={() => {
        setSeekValue((prev) => {
          return Math.max(0, (prev || currentTime) - SKIPPING_STEP_IN_SECONDS);
        });
      }}
    >
      <Rewind fill={'#eeeeee'} />
    </Ui.PlayerButton>
  );
}
