/* eslint-disable camelcase */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webGoogleTagManagerKit from '@mparticle/web-google-tag-manager-kit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webMixpanelKit from '@mparticle/web-mixpanel-kit';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Platform } from '../../api/mParticlesApi/constants';
import { getMParticleConfig } from '../../api/mParticlesApi/mParticle.utils';
import { mParticleApi } from '../../api/mParticlesApi/mParticlesApi';
import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { getDeviceType } from '../../helpers/deviceType';
import { enhancedMParticleLoggingSelector } from '../../store/debug';
import { deviceInfoSelector } from '../../store/deviceInfo';

const usemParticles = () => {
  const { MPARTICLE_API_KEY_WEB } = useEnv();
  const { isLoaded } = useSelector(deviceInfoSelector);
  const enhancedMParticleLogging = useSelector(enhancedMParticleLoggingSelector);

  useEffect(() => {
    MPARTICLE_API_KEY_WEB !== '' &&
      mParticleApi.init(MPARTICLE_API_KEY_WEB, { enhancedMParticleLogging });
  }, [MPARTICLE_API_KEY_WEB, enhancedMParticleLogging]);

  useEffect(() => {
    webMixpanelKit.register(getMParticleConfig);
    webGoogleTagManagerKit.register(getMParticleConfig);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const platform = Platform.Web;
      const actionSource = 'website';
      const method = 'app';
      const device_type = getDeviceType();

      mParticleApi.setPlatformParams({
        action_source: actionSource,
        device_type,
        method,
        platform,
      });
    }
  }, [isLoaded]);
};

export default usemParticles;
