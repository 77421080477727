import mParticle from '@mparticle/web-sdk';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { sendMParticleError } from '../../utils/mParticle/sendMPArticleError';
import { RootState } from '../types';
import { PlayerError } from './player.types';

export const setPlayerErrorAction = createAction<PlayerError | undefined>('player/setPlayerError');

export const setPlayerError = createAsyncThunk(
  setPlayerErrorAction.type,
  async (error: PlayerError | undefined, thunkAPI) => {
    try {
      if (error) {
        sendMParticleError(
          {
            error,
            eventType: mParticle.EventType.Media,
          },
          thunkAPI.getState() as RootState,
        );
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return error;
    }
  },
);
