import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';

export const routerSelector = (state: RootState) => state.router;

export const previousLocationSelector = createSelector(
  routerSelector,
  ({ previousLocation }) => previousLocation,
);

export const previousLocationPathnameSelector = createSelector(
  routerSelector,
  ({ previousLocation }) => previousLocation?.pathname,
);
