import { AUTH_SESSION_STORAGE_KEY, SessionType } from '../../../store/auth';
import { cacheManager } from '../../../utils';
import { LogOutUserResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const logout = async ({
  evergentApiKey,
  evergentChannelPartnerId,
  logOutUrl,
}: {
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  logOutUrl: string;
}): Promise<LogOutUserResponse> => {
  const logOutData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const data = { LogOutUserRequestMessage: logOutData };

  const { accessToken } = (await cacheManager.load<SessionType>(AUTH_SESSION_STORAGE_KEY)) || {};

  const response = await evergentAxiosInstance.post<{
    LogOutUserResponseMessage: LogOutUserResponse;
  }>(logOutUrl, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const dataResponse = response.data.LogOutUserResponseMessage;

  return {
    failureMessage: dataResponse?.failureMessage,
    message: dataResponse.message,
    responseCode: dataResponse.responseCode,
  };
};
