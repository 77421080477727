import { Theme } from '@mui/material';
import styled from 'styled-components';

import BgImage from '../../assets/images/minimized/bg_blue_1920.jpg';
import { IconButton } from '../IconButton/IconButton';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background: ${`url(${BgImage})`};
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.playerButtons};
`;

export const PlayerButton = styled(IconButton)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const getPlayerStyles = (theme: Theme, focused: boolean) => ({
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
  '& .MuiSlider-thumb': {
    '&.Mui-active': {
      height: 20,
      width: 20,
    },
    '&:before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${
        theme.palette.mode === 'dark' ? 'rgb(255 255 255 / 16%)' : 'rgb(0 0 0 / 16%)'
      }`,
    },
    height: 8,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    width: 8,
  },
  border: focused ? '1px solid #FFB800' : '',
  color: '#FFB800',
  height: 4,
  paddingTop: '1.7vw',
});
