import { FailureResponse, GetVoucherCodeResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getVoucherCode = async ({
  voucherCode,
  evergentApiKey,
  evergentChannelPartnerId,
  getVoucherCodeUrl,
}: {
  voucherCode: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getVoucherCodeUrl: string;
}) => {
  const requestParams = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    voucherCode,
  };

  const requestBody = {
    GetVoucherDetailsRequestMessage: requestParams,
  };

  const response = await evergentAxiosInstance.post<{
    GetVoucherDetailsResponseMessage: GetVoucherCodeResponse & FailureResponse;
  }>(getVoucherCodeUrl, requestBody);

  const { failureMessage, ...dataResponse } = response.data.GetVoucherDetailsResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage, {
      cause: {
        errorCode: failureMessage[0].errorCode,
      },
    });
  }

  return dataResponse;
};
