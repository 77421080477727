import { AxiosResponse, default as axios } from 'axios';
import { isWithinInterval, startOfDay } from 'date-fns';

import { getEnv } from '../../env';
import { isCurrentDateBetween } from '../../helpers/date';
import { PROGRAM_ERROR_MESSAGES } from '../../store/programs/contants';
import { AllVideosInfo, AspectRatioKeys, Container, ContainerSize } from '../../store/videos';
import { cacheManager } from '../../utils';
import { doesGracePeriodEnded } from '../../utils/doesGracePeriodEnded';
import { handleQuickPlayError } from '../../utils/handleQuickPlayError';
import { isLive } from '../../utils/isLive';
import { zoneStore } from '../../utils/zoneKeeper';
import { ProductServiceTypeEnum } from '../authApi/authApi.types';
import { CatalogType, PlaybackMode } from '../playerApi';
import {
  AssetCatalogDataRaw,
  AssetContentType,
  Channel,
  ContainerType,
  GetImageResizeUrlProps,
  GetLiveEventsResponse,
  StorefrontContainer,
  Video,
  VideoProgramation,
  VideoProgramationRaw,
  VideoTeam,
  VideoTeamRaw,
} from './cmsApi.types';

type StorefrontListResponse = {
  data: { id: string }[];
} & ResponseWithErrors;

type StorefrontTabsResponse = {
  data: StorefrontContainer[];
} & ResponseWithErrors;

type GetVideoByIdResponse = {
  data: AssetCatalogDataRaw;
} & ResponseWithErrors;

export type ResourceWithId = {
  id: string;
};

export type ResourceWithImage = {
  ia?: string[];
};

export type TeamItemType = ResourceWithId &
  ResourceWithImage & {
    lon: { lang: string; n: string }[];
    losn: { lang: string; n: string }[];
    lod: { lang: string; n: string }[];
    lo: ContainerType;
    tc: string;
    tclr: string;
    ex_id: string;
  };

type TeamListResponse = {
  data: TeamItemType[];
};

type AccessTokenIAMResponseType = {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
};

export type GeoLocationType = {
  countryName: string;
  country: string;
  region: string;
  regionName: string;
  city: string;
  lat: number;
  lon: number;
  mobile: boolean;
  proxy: boolean;
  zone: string;
  zip?: string;
  hosting: true;
};

export type QuickplayErrors = { code: string; description: string }[];

export type ResponseWithErrors = {
  header: {
    code: number;
    errors?: QuickplayErrors;
    message: string;
  };
};

export type GeoLocationResponseType = {
  data: GeoLocationType;
};

export type ZipCodeDetailType = {
  zn: string;
};

export type ZipCodeDetailResponseType = {
  data: ZipCodeDetailType;
};

export type CheckredeemResponseType = {
  data: { token: string };
};

export type TeamData = {
  externalId: string;
  id: string;
  teamId: string;
  teamName: string;
  teamShortName: string;
  teamDescription: string;
  teamIcon?: string;
  teamColor?: string;
  imagesSizes: string[];
  imageURL?: string;
};

export type ChannelRaw = {
  id: string;
  net: string;
  pt: string;
  lon: { lang: string; n: string }[];
};

type EPGResponseType = ResponseWithErrors & {
  data: {
    airing: AssetCatalogDataRaw[];
  }[];
};

type AiringResponseType = ResponseWithErrors & {
  data: AssetCatalogDataRaw[];
};

type ChannelsResponseType = ResponseWithErrors & {
  data: ChannelRaw[];
};

const TRANSPARENCY_SHOW_TEXT = 100;

const buildQuickplayCmsApi = () => {
  const getClientParams = (xClientId: string) => {
    const client = xClientId;
    const params = { client };
    return params;
  };

  const getBaseParams = (xClientId: string) => {
    const client = xClientId;
    const params = { client, dt: 'web' };
    return params;
  };

  const getCommonParams = async ({
    forceZoneKey,
    xClientId,
  }: {
    forceZoneKey: string;
    xClientId: string;
  }) => {
    const zone = forceZoneKey || (await zoneStore.getZoneKey());
    const params = { ...getBaseParams(xClientId), reg: zone };
    return params;
  };

  return {
    async getAiringLive({
      channelId,
      startDate,
      endDate,
      getStorefrontChannelAiring,
      forceZoneKey,
      xClientId,
    }: {
      channelId: string;
      startDate: Date;
      endDate?: Date;
      getStorefrontChannelAiring: string;
      forceZoneKey: string;
      xClientId: string;
    }): Promise<Video[]> {
      const commonParams = await getCommonParams({ forceZoneKey, xClientId });

      const response = await axios.get<AiringResponseType>(getStorefrontChannelAiring, {
        params: {
          ...commonParams,
          channel: channelId,
          end: endDate?.toISOString(),
          start: startDate.toISOString(),
        },
      });

      const {
        data,
        header: { errors },
      } = response.data;

      if (errors?.length) {
        const [error] = errors;
        if (error.code === '40251902') {
          throw new Error(PROGRAM_ERROR_MESSAGES.NO_DATA_IN_EPG);
        }
        throw new Error(error?.description || String(error));
      }

      return data.map(parseVideoRaw);
    },
    async getAllVideos({
      url,
      forceZoneKey,
      xClientId,
    }: {
      url: string;
      forceZoneKey: string;
      xClientId: string;
    }): Promise<Video[]> {
      const commonParams = await getCommonParams({ forceZoneKey, xClientId });
      const params = { ...commonParams, pageSize: 100 };
      const response = await axios.get<AxiosResponse<AssetCatalogDataRaw[]>>(url, { params });

      return response.data.data.map(parseVideoRaw);
    },
    async getAuthTokenIAM({
      clientId,
      clientSecret,
      authorizationEndPoint,
    }: {
      clientId: string;
      clientSecret: string;
      authorizationEndPoint: string;
    }) {
      const TOKEN_IAM_KEY = 'access-token-iam';
      const currentToken = cacheManager.load<AccessTokenIAMResponseType>(TOKEN_IAM_KEY);
      if (currentToken) {
        return currentToken;
      }
      const data = [
        'audience=edge-service',
        `client_id=${clientId}`,
        `client_secret=${clientSecret}`,
        'grant_type=client_credentials',
        'scope=openid',
      ].join('&');

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
      const response = await axios.post<AccessTokenIAMResponseType>(authorizationEndPoint, data, {
        headers,
      });
      return response.data;
    },
    async getCatalogTabContainer({
      containerId,
      getCollectionTabContainer,
      forceZoneKey,
      xClientId,
    }: {
      containerId: string;
      getCollectionTabContainer: string;
      forceZoneKey: string;
      xClientId: string;
    }): Promise<StorefrontContainer[]> {
      // const containerId = '14D5E755-CD46-4F31-92B0-072C370B89E3';
      const url = getCollectionTabContainer.split('{id}').join(containerId);
      const params = await getCommonParams({ forceZoneKey, xClientId });
      const response = await axios.get<StorefrontTabsResponse>(url, { params });

      const tabs = response.data.data;
      return tabs;
    },
    async getEPG({
      channelId,
      startDate,
      endDate,
      forceZoneKey,
      xClientId,
      getStoreFrontChannelEpg,
    }: {
      channelId: string;
      startDate: Date;
      endDate?: Date;
      forceZoneKey: string;
      xClientId: string;
      getStoreFrontChannelEpg: string;
    }): Promise<Video[]> {
      const commonParams = await getCommonParams({ forceZoneKey, xClientId });

      const response = await axios.get<EPGResponseType>(getStoreFrontChannelEpg, {
        params: {
          ...commonParams,
          channel: channelId,
          end: endDate?.toISOString(),
          start: startDate.toISOString(),
        },
      });

      const responseData = response.data;
      const { data } = responseData;
      const { airing } = (data && data[0]) || { airing: [] };

      return airing.map(parseVideoRaw);
    },
    async getEPGByDate({
      channelId,
      date,
      forceZoneKey,
      xClientId,
      getStoreFrontChannelEpg,
    }: {
      channelId: string;
      date: Date;
      forceZoneKey: string;
      xClientId: string;
      getStoreFrontChannelEpg: string;
    }): Promise<Video[]> {
      const startDate = startOfDay(date);

      return quickplayCmsApi.getEPG({
        channelId,
        forceZoneKey,
        getStoreFrontChannelEpg,
        startDate,
        xClientId,
      });
    },
    async getGame(
      params: {
        contentId: string;
      },
      options: {
        forceZoneKey: string;
        xClientId: string;
        getLiveEvent: string;
      },
    ) {
      try {
        const commonParams = await getCommonParams({
          forceZoneKey: options.forceZoneKey,
          xClientId: options.xClientId,
        });

        const response = await axios.get(options.getLiveEvent + '/' + params.contentId, {
          params: {
            ...commonParams,
          },
        });

        const responseData = response.data;

        const { data } = responseData;
        const parsedData = parseVideoRaw(data);

        return parsedData;
      } catch (e) {
        return;
      }
    },
    async getGames(
      params: {
        start: string;
        team?: string;
        end: string;
        pageSize: number;
        pageNumber?: number;
      },
      options: {
        forceZoneKey: string;
        xClientId: string;
        getLiveEvents: string;
      },
    ): Promise<Video[]> {
      const { data } = await this.getGamesRaw(params, options);

      if (!data) {
        return [];
      }

      return data.map((value: AssetCatalogDataRaw) => parseVideoRaw(value));
    },
    async getGamesRaw(
      params: {
        start: string;
        team?: string;
        end: string;
        pageSize: number;
        pageNumber?: number;
      },
      options: {
        forceZoneKey: string;
        xClientId: string;
        getLiveEvents: string;
      },
    ): Promise<GetLiveEventsResponse> {
      const commonParams = await getCommonParams({
        forceZoneKey: options.forceZoneKey,
        xClientId: options.xClientId,
      });

      const response = await axios.get<GetLiveEventsResponse>(options.getLiveEvents, {
        params: {
          ...commonParams,
          ...params,
        },
      });

      return {
        data: response.data.data || [],
        header: response.data.header,
      };
    },
    async getGeoLocalization({
      geoLocationLookupUrl,
      xClientId,
      clientId,
      clientSecret,
      authorizationEndPoint,
    }: {
      geoLocationLookupUrl: string;
      xClientId: string;
      clientId: string;
      clientSecret: string;
      authorizationEndPoint: string;
    }): Promise<GeoLocationType> {
      const url = geoLocationLookupUrl;
      const { access_token: accesToken } = await this.getAuthTokenIAM({
        authorizationEndPoint,
        clientId,
        clientSecret,
      });
      const headers = {
        Authorization: `Bearer ${accesToken}`,
        'X-Client-Id': xClientId,
      };
      const response = await axios.get<GeoLocationResponseType>(url, {
        headers,
      });

      const geoLocationData = response.data.data;
      return geoLocationData;
    },
    getImageResizeUrl({
      assetId,
      assetSize,
      width,
      format = 'png',
      thumbImageUrl,
    }: GetImageResizeUrlProps): string {
      if (!(assetSize && width)) {
        return '';
      }

      const MAX_WIDTH_IMAGE_SIZE = 4000;
      let resizedWidth = width;

      if (resizedWidth >= MAX_WIDTH_IMAGE_SIZE) {
        resizedWidth = MAX_WIDTH_IMAGE_SIZE;
      }

      return thumbImageUrl
        .split('{assetId}')
        .join(assetId)
        .split('{assetSize}')
        .join(assetSize)
        .split('{width}')
        .join(String(resizedWidth))
        .split('{format}')
        .join(String(format));
    },
    async getLiveEventsLive({
      getLiveEventsLive,
      forceZoneKey,
      xClientId,
    }: {
      getLiveEventsLive: string;
      forceZoneKey: string;
      xClientId: string;
    }): Promise<Video[]> {
      try {
        const commonParams = await getCommonParams({
          forceZoneKey,
          xClientId,
        });
        const {
          data: { data },
        } = await axios.get<AxiosResponse<AssetCatalogDataRaw[]>>(getLiveEventsLive, {
          params: {
            ...commonParams,
          },
        });

        return data.map(parseVideoRaw);
      } catch (e) {
        // TODO: Should be logged
        return [];
      }
    },
    async getStorefrontChannels({
      forceZoneKey,
      xClientId,
      getStoreFrontChannels,
    }: {
      forceZoneKey: string;
      xClientId: string;
      getStoreFrontChannels: string;
    }): Promise<Channel[]> {
      const commonParams = await getCommonParams({
        forceZoneKey,
        xClientId,
      });
      const params = {
        ...commonParams,
        sortBy: 'cn',
        sortOrder: 'asc',
        st: 'published',
      };
      const response = await axios.get<ChannelsResponseType>(getStoreFrontChannels, {
        params,
      });

      const {
        data,
        header: { errors },
      } = response.data;

      handleQuickPlayError(errors);
      return data.map(parseChannelRaw);
    },
    async getStorefrontId({
      forceZoneKey,
      xClientId,
      getStoreFrontList,
    }: {
      forceZoneKey: string;
      xClientId: string;
      getStoreFrontList: string;
    }): Promise<string> {
      const params = {
        ...(await getCommonParams({ forceZoneKey, xClientId })),
        st: 'published',
      };
      const response = await axios.get<StorefrontListResponse>(getStoreFrontList, { params });

      const {
        data,
        header: { errors },
      } = response.data;

      handleQuickPlayError(errors);
      const [firstItem] = data;

      return firstItem.id as string;
    },
    async getStorefrontTabContainer({
      storefrontId,
      tabId,
      forceZoneKey,
      xClientId,
      getStorefrontTabContainerEnv,
      pageNumber,
      pageSize,
    }: {
      storefrontId: string;
      tabId: string;
      forceZoneKey: string;
      xClientId: string;
      getStorefrontTabContainerEnv: string;
      pageNumber?: number;
      pageSize?: number;
    }): Promise<StorefrontContainer[]> {
      const url = getStorefrontTabContainerEnv
        .split('{id}')
        .join(storefrontId)
        .split('{tabId}')
        .join(tabId);

      const params = await getCommonParams({ forceZoneKey, xClientId });
      const response = await axios.get<StorefrontTabsResponse>(url, {
        params: {
          ...params,
          pageNumber,
          pageSize,
        },
      });

      const {
        data: tabs,
        header: { errors },
      } = response.data;

      handleQuickPlayError(errors);

      return tabs;
    },
    async getStorefrontTabs({
      storefrontId,
      getStoreFrontTabs,
      forceZoneKey,
      xClientId,
    }: {
      storefrontId: string;
      getStoreFrontTabs: string;
      forceZoneKey: string;
      xClientId: string;
    }): Promise<StorefrontContainer[]> {
      const url = getStoreFrontTabs.split('{id}').join(storefrontId);
      const params = await getCommonParams({ forceZoneKey, xClientId });
      const response = await axios.get<StorefrontTabsResponse>(url, { params });

      const {
        data: tabs,
        header: { errors },
      } = response.data;

      handleQuickPlayError(errors);

      return tabs;
    },
    async getTeamsContainer({
      teamKey,
      pageNumber,
      pageSize,
      forceZoneKey,
      xClientId,
      teamsVideosUrl,
    }: {
      teamKey: string;
      pageNumber?: number;
      pageSize?: number;
      forceZoneKey: string;
      xClientId: string;
      teamsVideosUrl: string;
    }): Promise<StorefrontContainer[]> {
      const url = teamsVideosUrl.split('{teamKey}').join(teamKey);

      const params = {
        ...(await getCommonParams({ forceZoneKey, xClientId })),
        pageNumber,
        pageSize,
      };
      const response = await axios.get<StorefrontTabsResponse>(url, { params });

      const tabs = response.data.data;
      return tabs;
    },
    async getVideoById({
      url,
      xClientId,
    }: {
      url: string;
      xClientId: string;
    }): Promise<AssetCatalogDataRaw> {
      const params = getClientParams(xClientId);
      const response = await axios.get<GetVideoByIdResponse>(url, { params });

      const {
        data: video,
        header: { errors },
      } = response.data;

      if (errors?.length) {
        const [error] = errors;
        throw new Error(error?.description || String(error));
      }
      if (!video) {
        throw new Error('Missing video');
      }
      return video;
    },
    async getZipCodeInfo({
      zipCode,
      xClientId,
      getZipCodeInfoUrl,
    }: {
      zipCode: string;
      xClientId: string;
      getZipCodeInfoUrl: string;
    }): Promise<ZipCodeDetailType> {
      const url = getZipCodeInfoUrl.split('{zipCode}').join(zipCode);
      const params = getClientParams(xClientId);
      const response = await axios.get<ZipCodeDetailResponseType & ResponseWithErrors>(url, {
        params,
      });

      const {
        data: zipCodeInfo,
        header: { errors },
      } = response.data;

      if (errors?.length) {
        const [error] = errors;
        if (error.code === '40250404') {
          throw new Error(
            'Products are not available for this location. Please enter a valid zip code for your area.',
          );
        }
        throw new Error(error?.description || String(error));
      }
      if (!zipCodeInfo) {
        throw new Error('Unknown error getting zip code details');
      }
      return zipCodeInfo;
    },
    async listTeamsCurrentZone({
      forceZoneKey,
      xClientId,
      teamsListUrlByZone,
    }: {
      forceZoneKey: string;
      xClientId: string;
      teamsListUrlByZone: string;
    }): Promise<TeamData[]> {
      const params = {
        ...(await getCommonParams({ forceZoneKey, xClientId })),
        st: 'published',
      };
      const response = await axios.get<TeamListResponse>(teamsListUrlByZone, { params });

      const teams = response.data.data.map(parseTeam);
      return teams;
    },
    async mediaCheckredeem({
      authToken,
      contentId,
      deviceId,
      ovatToken,
      xClientId,
      mediaCheckredeemUrl,
    }: {
      authToken: string;
      contentId: string;
      deviceId: string;
      ovatToken: string;
      xClientId: string;
      mediaCheckredeemUrl: string;
    }) {
      const url = mediaCheckredeemUrl;
      const data = JSON.stringify({
        catalogType: CatalogType.LIVEEVENT,
        contentId,
        contentTypeId: 'live',
        delivery: 'streaming',
        deviceId,
      });
      const headers = {
        authorization: `Bearer ${authToken}`,
        'content-type': 'application/json',
        'x-authorization': ovatToken,
        'x-client-id': xClientId,
      };
      const response = await axios.post<CheckredeemResponseType & ResponseWithErrors>(url, data, {
        headers,
      });

      const {
        data: tokenData,
        header: { errors },
      } = response.data;

      handleQuickPlayError(errors);
      if (!tokenData) {
        throw new Error('Unknown error getting checkredeem');
      }
      return tokenData;
    },
  };
};

export const quickplayCmsApi = buildQuickplayCmsApi();

export const parseTeamsVideoRaw = (tm: VideoTeamRaw): VideoTeam => {
  return {
    fullName: (tm.lod || [])[0]?.n || '',
    id: tm.id || '',
    imagesSizes: tm.ia || [],
    name: (tm.lon || [])[0]?.n || '',
    rawData: tm,
    shortName: (tm.losn || [])[0]?.n || '',
    teamId: tm.c || '',
    venueCity: tm.r,
  };
};

export const parseProgramationVideoRaw = (
  pgm?: VideoProgramationRaw,
): VideoProgramation | undefined => {
  if (!pgm) return undefined;
  return {
    description: (pgm.lod || [])[0]?.n,
    leagueName: pgm.spt_lg || '',
    rawData: pgm,
    sportName: pgm.spt_ty || '',
    title: (pgm.lon || [])[0]?.n,
    venueCity: pgm.r,
  };
};

const getStartDate = (asset: AssetCatalogDataRaw): string | undefined => {
  if (asset.cty === AssetContentType.LIVEEVENT) {
    return asset.ev_st_dt;
  } else if (asset.cty === AssetContentType.AIRING) {
    return asset.sc_st_dt;
  }

  return asset.st_dt;
};

export const getEndDate = (asset: AssetCatalogDataRaw): string | undefined => {
  if (asset.cty === AssetContentType.LIVEEVENT) {
    return asset.ev_ed_dt;
  } else if (asset.cty === AssetContentType.AIRING) {
    return asset.sc_ed_dt;
  }

  return asset.ed_dt;
};

const getParamsByContentType = (
  asset: AssetCatalogDataRaw,
): Pick<
  Video,
  'playbackContentType' | 'playbackMode' | 'startDate' | 'endDate' | 'isCurrentlyAiring'
> => {
  const startDate = getStartDate(asset) || '';
  const endDate = getEndDate(asset) || '';
  const isCurrentlyAiring =
    asset.cty === AssetContentType.EPISODE ? false : isCurrentDateBetween(startDate, endDate);
  let playbackMode;
  let playbackContentType = asset.cty;
  const {
    playerConfiguration: { liveEventEndGracePeriodSeconds },
  } = getEnv();

  const defaultParams = {
    endDate,
    isCurrentlyAiring,
    startDate,
  };

  switch (asset.cty) {
    case AssetContentType.LIVEEVENT: {
      const endDateObj = new Date(endDate);

      const currentDateObj = new Date();

      if (endDateObj < currentDateObj) {
        playbackMode = doesGracePeriodEnded(endDateObj, liveEventEndGracePeriodSeconds)
          ? PlaybackMode.CATCHUP
          : PlaybackMode.RESTART;
      } else if (isCurrentlyAiring) {
        playbackMode = PlaybackMode.LIVE;
      }

      break;
    }

    case AssetContentType.AIRING: {
      playbackContentType = AssetContentType.CHANNEL;
      playbackMode = PlaybackMode.LIVE;
      break;
    }

    case AssetContentType.CHANNEL: {
      playbackMode = PlaybackMode.LIVE;
      break;
    }
  }

  return {
    ...defaultParams,
    playbackContentType,
    playbackMode,
  };
};

export const parseVideoRaw = (asset: AssetCatalogDataRaw): Video => {
  const { FREE_CONTENT_IDENTIFIER } = getEnv();
  const externalId = asset.ex_id || '';
  const programation = parseProgramationVideoRaw(asset.pgm);
  const [homeTeam, visitorTeam] = (asset?.pgm?.tm || asset.tm || []).map(parseTeamsVideoRaw);
  const { startDate, endDate, playbackMode, isCurrentlyAiring, playbackContentType } =
    getParamsByContentType(asset);

  const contentType = asset.cty;
  const [tvPc] = asset.tv_pc || [];
  const id = String(asset.id);
  const cid = asset.cid ?? null;
  const title = (asset.lon ?? asset?.pgm?.lon)?.[0]?.n || '';
  const description = (asset.lod ?? asset?.pgm?.lod)?.[0]?.n || '';
  const productType = asset.pt;
  const videoType = asset.ty;
  const categoryType = asset.ca_ty;
  const duration = asset.rt || asset.dur;
  const licenseWindowStart = asset.lws;
  const licenseWindowEnd = asset.lwe;
  const purchaseAvailabilityStart = tvPc?.tp_st_dt;
  const purchaseAvailabilityEnd = tvPc?.tp_ed_dt;
  const eventStartDate = new Date(startDate);
  const eventEndDate = new Date(endDate);
  const isVideoLive = isLive(eventStartDate, eventEndDate, asset?.cty, asset?.ev_live);
  const venueCity = programation?.venueCity || homeTeam?.venueCity;
  const genre = asset.log?.flatMap((genre) => genre?.n ?? []) ?? [];
  const isFree = Boolean(asset.ent?.filter((i) => i.sp?.includes(FREE_CONTENT_IDENTIFIER)).length);
  const channel = asset.net;

  const isPPGPurchasable =
    !!purchaseAvailabilityStart &&
    !!purchaseAvailabilityEnd &&
    isWithinInterval(new Date(), {
      end: new Date(purchaseAvailabilityEnd),
      start: new Date(purchaseAvailabilityStart),
    });
  const primaryTeam = asset.p_losn?.length ? asset.p_losn[0]?.n : undefined;
  const league =
    playbackContentType === AssetContentType.CHANNEL ? asset.pgm?.spt_lg : asset.spt_lg;
  const gameId = asset.gameId;

  return {
    categoryType,
    channel,
    cid,
    contentType,
    description,
    duration,
    endDate,
    externalId,
    gameId,
    genre,
    homeTeam,
    id,
    isCurrentlyAiring,
    isFree,
    isLive: isVideoLive,
    isPPGPurchasable,
    league,
    licenseWindowEnd,
    licenseWindowStart,
    playbackContentType,
    playbackMode,
    primaryTeam,
    productType,
    programation,
    purchaseAvailabilityEnd,
    purchaseAvailabilityStart,
    rawData: asset,
    startDate,
    title,
    venueCity,
    videoType,
    visitorTeam,
  };
};

export const parseStorefrontContainer = (container: StorefrontContainer): Container => {
  const showText = container?.text_style?.transparency === TRANSPARENCY_SHOW_TEXT;
  const diar = (container.diar || []).find((diar) => diar.dt === 'browser');
  const aspectRatioKey = (diar?.iar || AspectRatioKeys.A0_16x9) as AspectRatioKeys;
  const containerSize = (container.s || ContainerSize.REGULAR) as ContainerSize;
  const videos = (container.cd || [])?.map((video) => parseVideoRaw(video));
  const allVideosInfo = (container.i || []).map(
    ({ cu: url, ...rest }): AllVideosInfo => ({
      ...rest,
      url,
    }),
  );

  return {
    allVideos: [],
    allVideosInfo,
    aspectRatioKey,
    containerSize,
    id: container.id,
    showText,
    title: container?.lon ? container?.lon[0]?.n : '',
    videos,
  };
};

export const parseTeam = (tm: TeamItemType): TeamData => {
  const externalId = tm.ex_id;
  const id = tm.id;
  const teamId = tm.tc;
  const imagesSizes = tm.ia || [];
  const teamName = tm.lon[0].n;
  const teamShortName = tm.losn[0].n;
  const teamDescription = tm.lod[0].n;
  const teamColor = tm.tclr;

  return {
    externalId,
    id,
    imagesSizes,
    teamColor,
    teamDescription,
    teamId,
    teamName,
    teamShortName,
  };
};

export const parseChannelRaw = (rawData: ChannelRaw): Channel => {
  const { id, pt } = rawData;
  const name = (rawData.lon[0].n || '').toUpperCase();
  const purchaseType = String(pt).toUpperCase() as ProductServiceTypeEnum;

  return {
    id,
    name,
    program: null,
    purchaseType,
    rawData,
  };
};
