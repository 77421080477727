import { NonSupportedScreen } from '../components/NonSupportedScreen/NonSupportedScreen';
import { envName } from '../env';
import useIsMobile from '../hooks/useIsMobile';
import { isMobileDevice } from './isMobileDevice';

export const displayNonSupportedMobileScreen = <T extends object>(
  WrappedComponent: React.ComponentType<T>,
  {
    showSpecialContent,
    force,
  }: {
    showSpecialContent?: boolean;
    force?: boolean;
  } = {},
) => {
  const DisplayNonSupportedMobileScreen: React.FC<T> = (props) => {
    const isMobile = useIsMobile() || isMobileDevice();

    return (
      <>
        {(isMobile && envName !== 'dev') || force ? (
          <NonSupportedScreen showSpecialContent={showSpecialContent} />
        ) : (
          <WrappedComponent {...props} />
        )}
      </>
    );
  };

  return DisplayNonSupportedMobileScreen;
};
