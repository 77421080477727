import { createSelector } from '@reduxjs/toolkit';

import { FLPlatformAsset, PlaybackMode } from '../../api';
import { isPayPerViewAvailable } from '../../components/SubscriptionPlans/helpers/isPayPerViewAvailable';
import { configSelector } from '../config';
import { RootState } from '../types';
import { zoneInfoStateSelector } from '../zoneInfo/zoneInfo.selectors';
import { shouldVideoBeAvailable } from './utils/shouldVideoBeAvailable';

export const videosSelector = (state: RootState) => state.videos;

export const selectedVideoSelector = createSelector(
  videosSelector,
  ({ selectedVideo }) => selectedVideo,
);

export const purchaseVideoSelector = createSelector(videosSelector, (state) => state.purchaseVideo);

export const isPayPerViewAvailableSelector = createSelector(
  purchaseVideoSelector,
  configSelector,
  (purchaseVideo, config) =>
    isPayPerViewAvailable(purchaseVideo) && !config.env.RESTRICT_TO_SUBSCRIPTION_ONLY,
);

export const shouldVideoBeAvailableSelector = createSelector(
  zoneInfoStateSelector,
  selectedVideoSelector,
  (zoneInfo, selectedVideo) => {
    const userZoneName = zoneInfo?.zoneKey || zoneInfo?.currentLocation?.zone;
    const selectedVideoZoneName = selectedVideo?.rawData?.zn;

    return shouldVideoBeAvailable(userZoneName, selectedVideoZoneName);
  },
);

export const isLivePillVisibleSelector = createSelector(
  selectedVideoSelector,
  (selectedVideo) =>
    !!selectedVideo?.isCurrentlyAiring &&
    selectedVideo?.playbackMode !== PlaybackMode.RESTART &&
    selectedVideo?.playbackMode !== PlaybackMode.CATCHUP,
);

export const areLiveControlsVisibleSelector = (
  asset: FLPlatformAsset | undefined,
  showLiveControls?: boolean,
) =>
  createSelector(
    selectedVideoSelector,
    (selectedVideo) =>
      selectedVideo?.isCurrentlyAiring ||
      asset?.livePlaybackMode === PlaybackMode.RESTART ||
      asset?.livePlaybackMode === PlaybackMode.CATCHUP ||
      !!showLiveControls,
  );

export const playbackModeSelector = createSelector(
  selectedVideoSelector,
  (selectedVideo) => selectedVideo?.playbackMode,
);

export const isLiveVideoSelector = createSelector(
  selectedVideoSelector,
  (video) => !!video?.isLive,
);
