import { CastPlayer } from '@msgn/fl-module/fl-chrome-sender';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CastManager,
  FLCastPlayer,
  PlatformAuthorizer,
  PlatformClient,
  flpPlayerApi,
} from '../../api';
import { useEnv } from '../../components/EnvProvider/EnvProvider';

declare global {
  interface Window {
    __onGCastApiAvailable: unknown;
  }
}

enum CastState {
  NO_DEVICES_AVAILABLE = 'NO_DEVICES_AVAILABLE',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

enum CastEventType {
  CAST_STATE_CHANGED = 'caststatechanged',
}

const GOOGLO_API_CAST_SRC =
  'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';

const castApiAvailablePromise = new Promise<boolean>((resolve) => {
  window['__onGCastApiAvailable'] = (isAvailable: boolean) => {
    resolve(isAvailable);
  };
  const sc = document.createElement('script');
  sc.src = GOOGLO_API_CAST_SRC;
  document.body.append(sc);
});

export type FLPCastManagerCallback = (castPlayer: FLCastPlayer, castManager: CastManager) => void;

export const useFLPCastManager = ({
  platformAuthorizer,
  platformClient,
  onCastSessionConnected,
  onCastSessionEnded,
  onCastSessionError,
}: {
  platformAuthorizer: PlatformAuthorizer;
  platformClient: PlatformClient;
  onCastSessionConnected: FLPCastManagerCallback;
  onCastSessionEnded: FLPCastManagerCallback;
  onCastSessionError: FLPCastManagerCallback;
}) => {
  const { CAST_RECEIVER_APPLICATION_ID, CAST_CHANNEL_NAMESPACE } = useEnv();
  const receiverApplicationId = CAST_RECEIVER_APPLICATION_ID;
  const channelNamespase = CAST_CHANNEL_NAMESPACE;

  const [isCastApiAvailable, setIsCastApiAvailable] = useState(false);
  const [isCastDevicesAvailable, setIsCastDevicesAvailable] = useState(false);
  const castInitializeOptions = useMemo(() => ({ receiverApplicationId }), []);
  const castManager = useMemo(
    () => (isCastApiAvailable ? flpPlayerApi.createCastManager() : null),
    [isCastApiAvailable],
  );
  const [castPlayer, setCastPlayer] = useState<CastPlayer>();

  const handleSessionConnected = useCallback(() => {
    if (!castManager)
      throw new Error('session connected handled called but castManager is not available');
    const { castPlayer } = castManager;
    onCastSessionConnected(castPlayer as FLCastPlayer, castManager);
    setCastPlayer(castPlayer as FLCastPlayer);
  }, [castManager, onCastSessionConnected, setCastPlayer]);

  const handleSessionEnded = useCallback(() => {
    if (!castManager)
      throw new Error('session end handled called but castManager is not available');
    if (!castPlayer) throw new Error('session end handled called but castPlayer is not available');
    onCastSessionEnded(castPlayer as FLCastPlayer, castManager);
    setCastPlayer(undefined);
  }, [castPlayer, castManager, onCastSessionEnded, setCastPlayer]);

  const handleSessionError = useCallback(() => {
    if (!castManager || !castPlayer) throw new Error('Error in cast session');
    onCastSessionError(castPlayer as FLCastPlayer, castManager);
  }, [onCastSessionError, castPlayer, castManager]);

  useEffect(() => {
    if (!castManager) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    castManager.subscribe('castSessionConnected', handleSessionConnected);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    castManager.subscribe('castSessionEnded', handleSessionEnded);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    castManager.subscribe('castSessionFailed', handleSessionError);
    castManager.initialize(
      castInitializeOptions,
      platformClient,
      platformAuthorizer,
      channelNamespase,
    );
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      castManager.unsubscribe('castSessionConnected', handleSessionConnected);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      castManager.unsubscribe('castSessionEnded', handleSessionEnded);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      castManager.unsubscribe('castSessionFailed', handleSessionError);
    };
  }, [
    castManager,
    handleSessionConnected,
    handleSessionEnded,
    handleSessionError,
    castInitializeOptions,
    platformClient,
    platformAuthorizer,
  ]);

  useEffect(() => {
    castApiAvailablePromise.then((isCastApiAvailableLoaded) => {
      setIsCastApiAvailable(isCastApiAvailableLoaded);
    });
  }, []);

  useEffect(() => {
    const cb = (event: { type: string; castState: CastState }) => {
      setIsCastDevicesAvailable(event.castState !== CastState.NO_DEVICES_AVAILABLE);
    };
    if (castManager?.castContext.addEventListener) {
      setIsCastDevicesAvailable(
        castManager.castContext.getCastState() !== CastState.NO_DEVICES_AVAILABLE,
      );
      castManager.castContext.addEventListener(CastEventType.CAST_STATE_CHANGED, cb);
      return () => {
        castManager.castContext.removeEventListener(CastEventType.CAST_STATE_CHANGED, cb);
      };
    }
  }, [castManager]);

  useEffect(() => {
    if (!castManager) return;
    return () => {
      castManager.destroy(true);
    };
  }, [castManager]);

  return { castManager, castPlayer, isCastApiAvailable, isCastDevicesAvailable, setCastPlayer };
};

export default useFLPCastManager;
