import { Modal as BootstrapModal } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { flexCenter, transparentButton } from '../../../utils/styled/mixins';
import { Button as AppButton } from '../../Button/Button';
import {
  Container as AppContainer,
  Col,
  Row,
  TypographyProps,
  bolder,
  fonts,
  textBase,
  textCenter,
  uppercase,
} from '../../Common';
import { FONT_WEIGHT } from '../../Common/Typography.styles';
import { LegalLink as AppLegalLink } from '../../LegalLink/LegalLink';

export const ModalBody = styled(BootstrapModal.Body)`
  padding: 0;
`;

export const Container = styled(AppContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalHeader = styled(BootstrapModal.Header).attrs({
  className: 'border-0 pb-0 pt-0',
  closeVariant: 'white',
})``;

export const ModalTitle = styled(BootstrapModal.Title).attrs({})`
  color: ${({ theme }) => theme.colors['off-white']};
`;

export const BackButtonWrapper = styled.div`
  margin-top: 2.5rem;

  ${breakpoint('md')`
    margin-top: 1.25rem;
  `}
`;

export const TitleHeader = styled.h1<TypographyProps>`
  ${textBase}
  ${bolder}
  ${textCenter}
  ${uppercase}
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  font-size: 1.75rem;
  line-height: 1.75rem;
  letter-spacing: 0.07rem;

  ${breakpoint('md')`
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.1rem;
    margin-top: 3.25rem;
  `}
`;

export const ErrorRow = styled(Row)`
  display: flex;
  justify-content: center;
  height: calc(100vh - 4rem);
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;

  ${breakpoint('md')`
    margin-top: 7.75rem;  
  `}

  ${breakpoint('xl')`
    margin-top: 6.75rem;  
  `}
`;

export const ProductStateErrorCol = styled(Col).attrs({
  lg: 8,
  xl: 4,
  xs: 12,
})`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const PlanList = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: stretch;

  & > div {
    width: 100%;
  }

  ${breakpoint('md')`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: ${FONT_WEIGHT.Bold};;
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.1rem;

    & > div {
      flex: 1;
    }
  `}
`;

export const OrDivider = styled.div`
  ${flexCenter}
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${breakpoint('md')`
    width: 29.75rem;
    margin: 1.5rem auto;
  `}

  ${breakpoint('xl')`
    width: 28.125rem;
  `}
`;

export const OrDividerLine = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors['primary']};
  flex-grow: 1;
`;

export const OrText = styled.span<TypographyProps>`
  ${textBase}
  ${fonts.body1.xs}
  ${uppercase}
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const OrDividerRow = styled(Row)`
  margin-top: 0.5rem;
`;

export const TeamsWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center flex-wrap',
})`
  margin-top: 1rem;
`;

export const LegalWrapper = styled.div`
  text-align: center;
  font-family: 'GothamNarrow';
  margin-top: 0.75rem;
  font-size: 0.625rem;
  line-height: 1rem;
  align-self: stretch;
`;

export const LegalLink = styled(AppLegalLink)`
  font-size: 0.625rem;
  line-height: 0.875rem;

  ${breakpoint('md')`
    text-transform: uppercase;
  `}
`;

export const PurchaseButtonRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

export const PurchaseButtonCol = styled(Col).attrs({
  lg: 6,
  xl: 5,
  xs: 12,
  xxl: 4,
})`
  text-align: center;
  display: flex;
`;

export const PurchaseRow = styled(Row)`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

export const PurchaseButton = styled(AppButton)`
  ${bolder}
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  flex: 1;
  width: 100%;

  ${breakpoint('md')`
    margin-bottom: 2.5rem;
  `}
  ${breakpoint('xl')`
    margin-bottom: 3.5rem; 
  `}
  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors['primary-soft']};
    color: ${({ theme }) => theme.colors['pure-black']};
    border-color: ${({ theme }) => theme.colors['grey-soft']};
  }
`;

export const MissingZipCodeErrorWrapper = styled.div`
  margin-top: 2.75rem;

  ${breakpoint('md')`
    margin-top: 7.75rem;
  `}
`;
export const ConnectTVEProviderButton = styled(AppButton)`
  ${transparentButton}
  display: flex;
  text-align: center;
  text-transform: unset;
  width: 100%;

  font-family: 'GothamNarrow';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 1.25rem;
  line-height: 1.5;
  text-decoration: none;
  color: ${({ theme }) => theme.colors['grey-light']};
  background-color: ${({ theme }) => theme.colors['grey-dark']};
  padding: 1rem 2rem;
  border-radius: 4px;

  ${breakpoint('lg')`
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 1rem 3rem;
  `}
  &:hover {
    color: ${({ theme }) => theme.colors['pure-black']};
    background-color: ${({ theme }) => theme.colors['primary']};

    .textIcon {
      path {
        stroke: black;
      }
    }
  }
`;
