// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webAdWordsKit from '@mparticle/web-adwords-kit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webFacebookKit from '@mparticle/web-facebook-kit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webGoogleTagManagerKit from '@mparticle/web-google-tag-manager-kit';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import webMixpanelKit from '@mparticle/web-mixpanel-kit';
import { User } from '@mparticle/web-sdk';

import { envName } from '../../env';
import flowManager from '../../flow/flowManager';
import { Platform, cachedMParticleSession, flowPlatformMap } from './constants';
import { mParticleConfigOptions } from './mParticleApi.types';

const cachedSessionConfig = cachedMParticleSession
  ? {
      identifyRequest: {
        userIdentities: {
          ...cachedMParticleSession,
        },
      },
    }
  : {};

export const getMParticleConfig = (options: mParticleConfigOptions): mParticle.MPConfiguration => ({
  appName: 'MSG',
  ...cachedSessionConfig,
  dataPlan: {
    planId: 'msgn_dtc_app',
    planVersion: 1,
  },
  // The identityCallback is a feature of IDSync which will be covered
  // in a more advanced tutorial. You can ignore this for now.
  identityCallback: (result: { getUser: () => User }) => {
    if (result.getUser()) {
      const user = result.getUser();
      user.getUserIdentities();
    } else {
      // the IDSync call failed
    }
  },
  isDevelopmentMode: envName === 'dev' || envName === 'stage' ? true : false,
  logLevel: options?.enhancedMParticleLogging ? 'verbose' : 'none',
  sideloadedKits: [webAdWordsKit, webFacebookKit, webGoogleTagManagerKit, webMixpanelKit],
});

export const getPlatformByFlowId = () => {
  const flowId = flowManager.flowId;
  return flowId ? flowPlatformMap[flowId] : Platform.Web;
};
