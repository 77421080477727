import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlaybackMode, Video, quickplayCmsApi } from '../../../api';
import { SeekToLive } from '../../../assets/icons/SeekToLive';
import { usePlayerContext } from '../../../hooks/quickplay/usePlayerContext';
import { useTranslations } from '../../../hooks/useTranslations';
import { AppDispatch } from '../../../store/store';
import { selectedVideoSelector, videosActions } from '../../../store/videos';
import { useConvivaContext } from '../../ConvivaProvider/ConvivaProvider';
import { useEnv } from '../../EnvProvider/EnvProvider';
import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import { LIVE_OFFSET_IN_SECONDS } from '../PlayerUI.constants';
import * as Ui from '../PlayerUi.styles';

export const SeekToLiveButton = () => {
  const t = useTranslations();
  const dispatch: AppDispatch = useDispatch();
  const { player } = usePlayerContext();
  const { handleJumpToLive, pushNotification } = usePlayerControlsContext();
  const selectedVideo = useSelector(selectedVideoSelector);
  const isVideoInRestartMode = selectedVideo?.playbackMode === PlaybackMode.RESTART;
  const { GET_LIVE_EVENTS_LIVE, FORCE_ZONE_KEY, X_CLIENT_ID, PLAYER_FORCE_BACK_TO_LIVE_BUTTON } =
    useEnv();
  const { startConvivaSession } = useConvivaContext();

  const hideSeekToLiveButton =
    (!isVideoInRestartMode && !PLAYER_FORCE_BACK_TO_LIVE_BUTTON) ||
    (player?.currentOffsetFromLiveEdge &&
      player.currentOffsetFromLiveEdge() < LIVE_OFFSET_IN_SECONDS);

  const handleRestartModeClick = useCallback(() => {
    if (selectedVideo) {
      player.stop();
      dispatch(
        videosActions.displayVideo({
          ...selectedVideo,
          isCurrentlyAiring: true,
          playbackMode: PlaybackMode.LIVE,
        } as Video),
      );
      startConvivaSession();
    }
  }, [selectedVideo, dispatch, startConvivaSession]);

  const checkIfStillLive = useCallback(
    async ({
      getLiveEventsLive,
      forceZoneKey,
      xClientId,
    }: {
      getLiveEventsLive: string;
      forceZoneKey: string;
      xClientId: string;
    }): Promise<boolean> => {
      const liveGames = await quickplayCmsApi.getLiveEventsLive({
        forceZoneKey,
        getLiveEventsLive,
        xClientId,
      });
      const isLive = liveGames.some((item) => item.id === selectedVideo?.id);

      return isLive;
    },
    [selectedVideo?.id],
  );

  const handleOnClick = useCallback(async () => {
    if (isVideoInRestartMode) {
      const isStillLive = await checkIfStillLive({
        forceZoneKey: FORCE_ZONE_KEY,
        getLiveEventsLive: GET_LIVE_EVENTS_LIVE,
        xClientId: X_CLIENT_ID,
      });

      if (isStillLive) {
        handleRestartModeClick();
      } else {
        pushNotification(t.playerNotification_disabledBackToLive);
      }
    } else {
      handleJumpToLive();
    }
  }, [
    isVideoInRestartMode,
    checkIfStillLive,
    handleRestartModeClick,
    pushNotification,
    handleJumpToLive,
  ]);

  if (hideSeekToLiveButton) {
    return null;
  }

  return (
    <Ui.PlayerButton aria-label={t.playerSideBar_seekToLive} onClick={handleOnClick}>
      <SeekToLive />
    </Ui.PlayerButton>
  );
};
