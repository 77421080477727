import styled, { css } from 'styled-components';

import { transparentButton } from '../../../../utils/styled/mixins';
import { FONT_WEIGHT } from '../../../Common';

export const Item = styled.div.attrs({ role: 'button' })<{
  $isEnabled?: boolean;
  $isActive: boolean;
  $disabled?: boolean;
}>`
  ${transparentButton}
  display: block;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors['scrim-white']};
  color: ${({ theme, $isEnabled }) =>
    $isEnabled ? theme.colors['pure-white'] : theme.colors['grey']};
  font-family: 'GothamNarrow';
  font-size: 1rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 0;
  width: 100%;

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: 450;
    `}

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      color: ${theme.colors.grey};
    `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.span`
  padding-right: 1rem;
`;
