import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useFLPAuthorizers } from '../components/FLPAuthorizers/FLPAuthorizers';
import { logOutAllDevices, logoutUser } from '../store/auth';
import { favoriteTeamsActions } from '../store/favoriteTeams';
import { AppDispatch } from '../store/store';
import { clearUserData } from '../store/userData';

export enum LogoutType {
  CURRENT_DEVICE = 'CURRENT_DEVICE',
  ALL_DEVICES = 'ALL_DEVICES',
  JUST_CLEAR_DATA = 'JUST_CLEAR_DATA',
}

export const useLogoutAction = () => {
  const dispatch: AppDispatch = useDispatch();
  const { contentAuthorizer } = useFLPAuthorizers();

  return useCallback(
    (logoutType: LogoutType) => {
      dispatch(clearUserData());
      dispatch(favoriteTeamsActions.resetFavoriteTeams());
      contentAuthorizer?.invalidateDeviceToken();
      if (logoutType === LogoutType.CURRENT_DEVICE) {
        dispatch(logoutUser());
      } else if (logoutType === LogoutType.ALL_DEVICES) {
        dispatch(logOutAllDevices());
      }
    },
    [dispatch, contentAuthorizer],
  );
};
