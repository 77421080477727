import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { ErrorComponent } from '../ErrorComponent/ErrorComponent';

export const Error = styled(ErrorComponent)`
  flex: 1;

  [data-name='error-message'] {
    ${breakpoint('lg')`
  	  font-size: 1.5rem;
  `}

    ${breakpoint('xl')`
    font-size: 2.5rem;
  `}
  }
`;
