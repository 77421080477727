import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { GetEntitlementsResponse } from '../../api/authApi/authApi.types';
import { cacheManager } from '../../utils';
import { ENTITLEMENTS_STORAGE_KEY } from './entitlements.constants';
import { EntitlementsState } from './entitlements.types';
import { entitlementsResponseResponseToState } from './utils/entitlementsResponseResponseToState';

export const loadEntitlementsAction = createAction('entitlements/load');

const noEntitlementsState: EntitlementsState = {
  canCancelSubscription: false,
  entitlements: null,
  error: null,
  hasActiveSubscriptions: false,
  lastSubscription: null,
  loaded: false,
  loading: false,
  ppgItemsIds: {},
  subscriptions: [],
  tvodAccountServices: [],
};

const cachedEntitlements = cacheManager.load<GetEntitlementsResponse & { fetchTimestamp: number }>(
  ENTITLEMENTS_STORAGE_KEY,
);

export const initialState: EntitlementsState = {
  ...noEntitlementsState,
  ...entitlementsResponseResponseToState(cachedEntitlements),
  entitlements: cachedEntitlements,
};

const slice = createSlice({
  initialState,
  name: 'entitlements',
  reducers: {
    clearEntitlements: () => ({
      ...noEntitlementsState,
    }),
    loadEntitlementsError: (
      state: EntitlementsState,
      action: PayloadAction<string>,
    ): EntitlementsState => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    loadEntitlementsLoading: (state: EntitlementsState): EntitlementsState => ({
      ...state,
      error: null,
      loading: true,
    }),
    loadEntitlementsSuccess: (
      state: EntitlementsState,
      action: PayloadAction<GetEntitlementsResponse & { fetchTimestamp: number }>,
    ): EntitlementsState => ({
      ...noEntitlementsState,
      ...entitlementsResponseResponseToState(action.payload),
      entitlements: {
        ...action.payload,
        fetchTimestamp: new Date().getTime(),
      },

      loaded: true,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as entitlementsActions, name, reducer };
