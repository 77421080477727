import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { flexCenter } from '../../utils/styled/mixins';
import { TypographyProps, bolder, fonts, textBase, textCenter } from '../Common';
import { DynamicImage } from '../DynamicImage/DynamicImage';

export const Root = styled.div`
  display: flex;
  flex: 1;

  ${breakpoint('xl')`
    flex-direction: column-reverse;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const DynamicImg = styled(DynamicImage)`
  max-width: 100%;
  width: 9.25rem;
  height: 5rem;
  margin-right: 1rem;
  border: 1px solid ${({ theme }) => theme.colors['pure-white']};

  ${breakpoint('xl')`
    width: 100%;
    height: unset;
    margin-right: 0;
  `}
`;

export const CaptionWrapper = styled.div`
  text-align: left;

  ${breakpoint('md')`
    height: unset;
  `}

  ${breakpoint('xl')`
    min-height: 3rem;
    margin-bottom: 1.5rem;  
    ${textCenter} 
  `}
`;

export const Index = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.title.xs}
  ${bolder}
  ${flexCenter}
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 0.5rem;

  &::before,
  &::after {
    content: '';
    width: 1.5rem;
    height: 0.1875rem;
    background-color: ${({ theme }) => theme.colors.primary};
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  ${breakpoint('md')`  
    ${fonts.title.xl}
    justify-content: center; 
    width: 16.375rem; 
  `}

  ${breakpoint('xl')`
    width: 100%;
    justify-content: center; 
  `}
`;

export const Caption = styled.h2<TypographyProps>`
  ${textBase}
  ${fonts.body1.xs}
  ${bolder}

  ${breakpoint('md')`
    ${fonts.body1.xl}
  `}
`;
