import { Alert, AlertProps } from '@mui/material';
import styled from 'styled-components';

import { colors } from '../../ThemeProvider/constants/colors';

interface AlertWrapperProps extends AlertProps {
  $visible: boolean;
}

export const AlertWrapper = styled.div<AlertWrapperProps>`
  display: ${(props) => (props.$visible ? 'flex' : 'none')} !important;
`;

export const StyledAlert = styled(Alert)`
  max-width: 24rem;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.primary};

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const alertOverwriteStyles = {
  backgroundColor: colors['pure-white'],
  fontFamily: 'GothamNarrow',
  fontSize: '1.25rem',
  fontWeight: 325,
  lineHeight: 1.5,
};
