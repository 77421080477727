import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAfter, isBefore } from 'date-fns';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { mParticleApi } from '../../api/mParticlesApi/mParticlesApi';
import { Log } from '../../helpers/CustomLogger';
import { setPlayerError } from '../player/player.thunks';
import { RootState } from '../types';
import { isCurrentLocationUpToDateSelector, zoneInfoLoad } from '../zoneInfo';
import {
  shouldFetchOrUpdateCouchRightsSelector,
  shouldUpdateCouchRightsSelector,
} from './couchRights.selectors';
import {
  addOrUpdateCouchRightsAction,
  couchRightsActions,
  loadCouchRightsAction,
} from './couchRights.slice';

export const getCouchRights = createAsyncThunk(
  loadCouchRightsAction.type,
  async (params: never, thunksapi) => {
    try {
      const state = thunksapi.getState() as RootState;

      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, EVERGENT_COUCH_RIGHTS },
        },
        deviceInfo: { deviceId },
        auth: { accessToken },
      } = state as RootState;

      const shouldFetchOrUpdateCouchRights = shouldFetchOrUpdateCouchRightsSelector(state);

      if (!shouldFetchOrUpdateCouchRights) {
        return;
      }

      const couchRights = await evergentAuthApi.getCouchRights({
        accessToken,
        deviceId,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        evergentCouchRights: EVERGENT_COUCH_RIGHTS,
      });

      const { endDate, responseCode, status } = couchRights;

      if (responseCode == 1 && status === 'SUCCESS') {
        thunksapi.dispatch(couchRightsActions.loadCouchRightsSuccess(endDate));
      }
    } catch (error: unknown) {
      if (typeof error === 'string') {
        const [errorCode, errorMessage] = error.split('|');
        // DeviceCouchRights not found for the given deviceID
        if (errorCode === 'eV5660') {
          thunksapi.dispatch(couchRightsActions.loadCouchRightsSuccess(''));

          mParticleApi.setUserData({
            key: 'couch_rights',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore since mParticle expects a boolean, and sdk accepts only string type
            value: false,
          });
        }

        Log.error(errorMessage);
      }
    }
  },
);
export const addOrUpdateCouchRights = createAsyncThunk(
  addOrUpdateCouchRightsAction.type,
  async (params: { forceCheck: boolean } | undefined, thunksapi) => {
    const state = thunksapi.getState() as RootState;

    const {
      deviceInfo: { deviceId },
      auth: { accessToken },
      appConfig: {
        env: {
          EVERGENT_API_KEY,
          EVERGENT_CHANNEL_PARTNER_ID,
          EVERGENT_ADD_OUR_UPDATE_COUCH_RIGHTS,
          SHOULD_BLOCK_COUCH_RIGHTS_ERROR,
        },
      },
      translations: { data: translationsData },
    } = state;
    try {
      const isLocationValid = isCurrentLocationUpToDateSelector(state);
      const shouldUpdateCouchRights = shouldUpdateCouchRightsSelector(state) || params?.forceCheck;

      if (!isLocationValid) {
        await thunksapi.dispatch(zoneInfoLoad());
      }

      if (shouldUpdateCouchRights || !isLocationValid) {
        const couchRights = await evergentAuthApi.addOrUpdateCouchRights({
          accessToken,
          currentLocationZone:
            (thunksapi.getState() as RootState).zoneInfo.currentLocation?.zone || '',
          deviceId,
          evergentAddOurUpdateCouchRights: EVERGENT_ADD_OUR_UPDATE_COUCH_RIGHTS,
          evergentApiKey: EVERGENT_API_KEY,
          evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
          translations: translationsData,
        });
        const { endDate, responseCode, status } = couchRights;

        if (responseCode == 1 && status === 'SUCCESS') {
          if (endDate && isAfter(new Date(), new Date(endDate))) {
            return thunksapi.dispatch(
              setPlayerError({
                errorMessage: translationsData.couchRightsExpired,
              }),
            );
          }
          if (!endDate) {
            mParticleApi.removeAttributeFromUserData({ key: 'couch_rights' });
          } else {
            mParticleApi.setUserData({
              key: 'couch_rights',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore since mParticle expects a boolean, and sdk accepts only string type
              value: isBefore(new Date(), new Date(endDate)),
            });
          }
          thunksapi.dispatch(couchRightsActions.updateCouchRightsSuccess(endDate));
        }
      }
    } catch (errorMessage: unknown) {
      Log.error(errorMessage);

      if (!SHOULD_BLOCK_COUCH_RIGHTS_ERROR) {
        thunksapi.dispatch(
          setPlayerError({
            errorMessage: String(errorMessage),
          }),
        );
      }
    }
  },
);
