import { Channel } from '../../../../api/cmsApi/cmsApi.types';
import { getImageResizeUrl } from '../../../../helpers/imageResizeUrl';
import { SelectorSize } from '../../../Selector/Selector.types';
import * as Ui from './ChannelItemButton.styles';

export const ChannelItemButton = ({
  channel,
  selected,
  onPress,
}: {
  channel: Channel;
  selected: boolean;
  onPress: (channel: Channel) => void;
}) => {
  const imageUrl = getImageResizeUrl({
    assetId: channel.id,
    format: 'png',
    size: '3-16x9',
    width: 145,
  });

  return (
    <Ui.ChannelItemButton
      size={SelectorSize.Medium}
      onClick={() => onPress(channel)}
      selected={selected}
    >
      <img src={imageUrl} alt={channel.name} />
    </Ui.ChannelItemButton>
  );
};
