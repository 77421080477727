import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { isPayPerViewAvailableSelector } from '../videos';
import { isPayPerView } from './products.utils';

export const productsStateSelector = (state: RootState) => state.products;

export const availableProductsSelector = createSelector(
  productsStateSelector,
  isPayPerViewAvailableSelector,
  ({ items }, isPayPerViewAvailable) => {
    if (isPayPerViewAvailable) {
      return items;
    }

    return items.filter((product) => !isPayPerView(product));
  },
);

export const selectedProductSelector = createSelector(
  productsStateSelector,
  ({ selectedProduct }) => selectedProduct,
);
