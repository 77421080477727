import { createGlobalStyle } from 'styled-components';

import backgroundImage from '../../assets/images/minimized/background_image.jpg';
import backgroundImageSmall from '../../assets/images/minimized/background_image_small.jpg';
import bgBlue from '../../assets/images/minimized/bg_blue_1920.jpg';
import blurredImageMobile from '../../assets/images/minimized/blurred_image_mobile.jpg';

export const GlobalStyle = createGlobalStyle`
    google-cast-launcher {
        --connected-color: #ffb800;
        --disconnected-color: white;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    html {
        font-size: 16px;
    }

    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Gotham',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }


    .bg_blur_web {
        background-color: #02101f;
        background-image: url(${blurredImageMobile});
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }


    .bg_basket_player_mobile {
        background-image: url(${backgroundImageSmall});
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }

    #onetrust-pc-sdk {
        height: 45rem;
    }

    @media screen and (min-width: 550px) {
    .bg_basket_player_mobile {
        background-image: url(${backgroundImage});
    }

    .bg_blur_web {
        background-image: url(${bgBlue});
    }
    

    // subscription modal
    .subscriptionModal {
        color: #eeeeee;
        font-family: 'GothamNarrow';
        font-style: normal;
        z-index: 1050; // zIndex.BootstrapModal;
    }

    .subscriptionModalContent {
        background-color: transparent;
    }

    .subscriptionModalDialog {
        width: 100%;
    }

    // closeed captions modal

    .closedCaptionBackdrop {
        background-color: #111111 !important;
    }

    .closedCaptionModalContent {
        font-family: 'Gotham';
        background-color: #333333;
    }

    .closedCaptionModalDialog {
        color: #eeeeee;
        max-width: 600px;
    }

    .toastError {
        border-left: 10px solid red;
        color: white;
        width: 560px;
      }

    // MIGARION MODAL

    .migrationModalDialog {
        margin: 0;
        width: 100%;
        height: 100vh;
        max-width: unset;
    }

    .migrationModalContent {
        height: 100%;
    }
      

`;
