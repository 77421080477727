import { TeamData } from '../../../../api';
import { getImageResizeUrl } from '../../../../helpers/imageResizeUrl';
import { Selector } from '../../../Selector/Selector';
import { SelectorSize } from '../../../Selector/Selector.types';
import { findAndUseLogoSize } from './helpers/findAndUseLogoSize';

export const TeamFilterItem = ({
  team,
  selected,
  onClick,
}: {
  team: TeamData;
  selected: boolean;
  onClick: () => void;
}) => {
  const imageUrl = getImageResizeUrl({
    assetId: team.teamId,
    size: findAndUseLogoSize(team.imagesSizes),
    width: 24,
  });

  return (
    <Selector
      size={SelectorSize.Medium}
      selected={selected}
      onClick={onClick}
      icon={<img src={imageUrl} alt={team.teamName} />}
    >
      {team.teamShortName}
    </Selector>
  );
};
