import { SVGProps } from 'react';

export const MenuClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_13965_10084)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.65826 5.40034C5.91861 5.13999 6.34072 5.13999 6.60107 5.40034L12.2579 11.0572L17.9148 5.40034C18.1751 5.13999 18.5972 5.13999 18.8576 5.40034C19.1179 5.66069 19.1179 6.0828 18.8576 6.34315L13.2007 12L18.8576 17.6569C19.1179 17.9172 19.1179 18.3393 18.8576 18.5997C18.5972 18.86 18.1751 18.86 17.9148 18.5997L12.2579 12.9428L6.60107 18.5997C6.34072 18.86 5.91861 18.86 5.65826 18.5997C5.39791 18.3393 5.39791 17.9172 5.65826 17.6569L11.3151 12L5.65826 6.34315C5.39791 6.0828 5.39791 5.66069 5.65826 5.40034Z'
        fill={props.fill || 'white'}
      />
    </g>
    <defs>
      <clipPath id='clip0_13965_10084'>
        <rect width='24' height='24' fill={props.fill || 'white'} />
      </clipPath>
    </defs>
  </svg>
);
