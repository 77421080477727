import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { getCacheZoneInfo } from './utils/utils';
import { noZoneInfo } from './zoneInfo.constants';
import { ZoneInfoState, ZoneInfoType } from './zoneInfo.types';

export const zoneInfoLoadAction = createAction('zoneInfo/load');

export const initialState: ZoneInfoState = {
  ...noZoneInfo,
  ...getCacheZoneInfo(),
  loaded: false,
  loading: false,
  timeOfLastFetch: undefined,
  updated: false,
  updating: false,
};

const slice = createSlice({
  initialState,
  name: 'zoneInfo',
  reducers: {
    loadError: (state, action: PayloadAction<string>) => ({
      ...state,
      loadError: action.payload,
      loaded: true,
      loading: false,
    }),
    loadSetLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loadError: undefined,
      loading: action.payload,
    }),
    loadSuccess: (state: ZoneInfoState, action: PayloadAction<ZoneInfoType>) => {
      return {
        ...state,
        ...action.payload,
        loadError: undefined,
        loaded: true,
        loading: false,
        timeOfLastFetch: Date.now(),
      };
    },
    updateError: (state, action: PayloadAction<string>) => ({
      ...state,
      updateError: action.payload,
      updated: true,
      updating: false,
    }),
    updateSetUpdating: (state, action: PayloadAction<boolean>) => ({
      ...state,
      updateError: undefined,
      updated: false,
      updating: action.payload,
    }),
    updateSuccess: (state: ZoneInfoState, action: PayloadAction<ZoneInfoType>) => ({
      ...state,
      ...action.payload,
      updateError: undefined,
      updated: true,
      updating: false,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as zoneInfoActions, name, reducer };
