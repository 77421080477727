import { useEffect, useState } from 'react';

import { envName } from '../env';

export const useLoadSquareSdk = () => {
  const scriptUrl = ['preprod', 'prod'].includes(envName)
    ? 'https://web.squarecdn.com/v1/square.js'
    : 'https://sandbox.web.squarecdn.com/v1/square.js';
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = scriptUrl;
    script.async = true;
    script.onload = () => {
      setLoaded(true);
    };

    script.onerror = () => {
      setLoaded(true);
    };

    document.body.appendChild(script);
  }, []);

  return loaded;
};
