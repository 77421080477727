import { isBefore } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelector } from '../../store/auth';
import { isActiveSelector } from '../../store/browser';
import {
  clearEntitlements,
  loadEntitlements,
  ovatTokenLastTimeFetchSelector,
} from '../../store/entitlements';
import { AppDispatch } from '../../store/store';

const REQUEST_TIME_TRASHHOLD = 5 * 60 * 1000; // 5min

export const useOvatToken = () => {
  const dispatch: AppDispatch = useDispatch();
  const { accessToken, authed } = useSelector(authSelector);
  const ovatTokenLastTimeFetch = useSelector(ovatTokenLastTimeFetchSelector);

  const isFirstRender = useRef(true);

  const isTabActive = useSelector(isActiveSelector);

  useEffect(() => {
    if (accessToken) {
      dispatch(loadEntitlements({ accessToken }));
    } else {
      dispatch(clearEntitlements());
    }
  }, [accessToken]);

  useEffect(() => {
    if (isTabActive && !isFirstRender.current) {
      const now = new Date().getTime();
      const trasholdTimePasseed = ovatTokenLastTimeFetch
        ? isBefore(ovatTokenLastTimeFetch + REQUEST_TIME_TRASHHOLD, now)
        : true;

      if (trasholdTimePasseed && authed) {
        dispatch(loadEntitlements({ accessToken }));
      }
    }
    isFirstRender.current = false;
  }, [isTabActive]);
};
