import { ROUTES, RouteName } from '../Constants/routes';
import { AllFlowsMap } from './flow.types';
import { createStepConfig } from './utils';

export const tizenSignInFlow = 'tizenSignInFlow';
const TIZEN_SIGN_IN_FLOW = [
  createStepConfig(RouteName.ActivateSignIn, ROUTES[RouteName.ActivateSignIn]),
  createStepConfig(RouteName.Activate, ROUTES[RouteName.Activate]),
  createStepConfig(RouteName.AllSet, ROUTES[RouteName.AllSet]),
];

export const tizenSignUpFlow = 'tizenSignUpFlow';
const TIZEN_SIGN_UP_FLOW = [
  createStepConfig(RouteName.ActivateSignUp, ROUTES[RouteName.ActivateSignUp]),
  createStepConfig(RouteName.FavoriteTeams, ROUTES[RouteName.FavoriteTeams]),
  createStepConfig(RouteName.Activate, ROUTES[RouteName.Activate]),
  createStepConfig(RouteName.AllSet, ROUTES[RouteName.AllSet]),
];

export const tizenPaymentFlow = 'tizenPaymentFlow';
const TIZEN_PAYMENT_FLOW = [
  createStepConfig(RouteName.activatePayment, ROUTES[RouteName.activatePayment]),
  createStepConfig(RouteName.Payment, ROUTES[RouteName.Payment]),
  createStepConfig(RouteName.AllSetPayment, ROUTES[RouteName.AllSetPayment]),
];

export const providerFlow = 'providerFlow';
const PROVIDER_FLOW = [
  createStepConfig(RouteName.Provider, ROUTES[RouteName.Provider]),
  createStepConfig(RouteName.TveConnectionStatus, ROUTES[RouteName.TveConnectionStatus]),
];

export const flows: AllFlowsMap = {
  [providerFlow]: PROVIDER_FLOW,
  [tizenPaymentFlow]: TIZEN_PAYMENT_FLOW,
  [tizenSignInFlow]: TIZEN_SIGN_IN_FLOW,
  [tizenSignUpFlow]: TIZEN_SIGN_UP_FLOW,
};
