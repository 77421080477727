import { ChangeEmailAddress } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const changeEmailAddress = async ({
  accessToken,
  email,
  evergentApiKey,
  evergentChannelPartnerId,
  changeEmailUrl,
}: {
  accessToken: string;
  email: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  changeEmailUrl: string;
}): Promise<ChangeEmailAddress> => {
  const requestData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    customerUsername: email,
    email: email,
  };

  const body = { UpdateProfileRequestMessage: requestData };

  const changeEmailResponse = await evergentAxiosInstance.post<{
    UpdateProfileResponseMessage: ChangeEmailAddress;
  }>(changeEmailUrl, body, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { failureMessage, ...dataResponse } = changeEmailResponse.data.UpdateProfileResponseMessage;

  if (failureMessage && failureMessage.length > 0) throw new Error(failureMessage[0].errorMessage);

  return dataResponse;
};
