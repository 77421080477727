import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  checkAdobeAuthorizationAndGetMediaShortToken,
  getAdobeToken as getAdobeTokenRequest,
} from '../../api/authApi/adobeApi/adobeApi';
import { useEnv } from '../../components/EnvProvider/EnvProvider';
import { authSelector } from '../../store/auth';

export const useGetMediaShortToken = () => {
  const { accessToken } = useSelector(authSelector);
  const {
    TVE_ADOBE_REQUESTOR,
    TVE_ADOBE_RESOURCE,
    ADOBE_GET_MEDIA_SHORT_TOKEN_URL,
    ADOBE_TOKENS_AUTHZ_URL,
    EVERGENT_API_KEY,
    GET_ADOBE_ACCESSE_TOKEN_URL,
    EVERGENT_CHANNEL_PARTNER_ID,
    ADOBE_AUTHORIZE_URL,
  } = useEnv();

  const getAdobeToken = useCallback(async () => {
    return getAdobeTokenRequest({
      accessToken,
      evergentApiKey: EVERGENT_API_KEY,
      evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
      getAdobeAccessTokenUrl: GET_ADOBE_ACCESSE_TOKEN_URL,
    });
  }, [accessToken]);

  const getMediaShortToken = useCallback(
    async ({ adobeAccessToken, deviceId }: { adobeAccessToken: string; deviceId: string }) => {
      return checkAdobeAuthorizationAndGetMediaShortToken({
        adobeAccessToken,
        adobeAuthorizeUrl: ADOBE_AUTHORIZE_URL,
        adobeGetMediaShortTokenUrl: ADOBE_GET_MEDIA_SHORT_TOKEN_URL,
        adobeTokensAuthzUrl: ADOBE_TOKENS_AUTHZ_URL,
        deviceId,
        tveAdobeRequestor: TVE_ADOBE_REQUESTOR,
        tveAdobeResource: TVE_ADOBE_RESOURCE,
      });
    },
    [],
  );

  return { getAdobeToken, getMediaShortToken };
};
