import { matchPath } from 'react-router-dom';

export const getRedirectPathForPattern = (
  pattern: string,
  entryPage: string,
  ignoreOptionalRoute?: boolean,
) => {
  const routeMatch = matchPath(pattern, entryPage);
  if (!ignoreOptionalRoute || (ignoreOptionalRoute && routeMatch !== null)) {
    return routeMatch ? routeMatch.pathname : undefined;
  }

  const basePattern = pattern.split('/:');
  const baseRouteMatch = matchPath(basePattern[0], entryPage);
  return baseRouteMatch ? baseRouteMatch.pathname : undefined;
};
