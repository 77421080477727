import { AssetContentType, ConsumptionType, Video } from '../../api';
import { ActiveSubscription, ProductServiceStatusEnum } from '../../api/authApi/authApi.types';
import { Platform } from '../../api/mParticlesApi/constants';
import { isPayPerViewAvailable } from '../../components/SubscriptionPlans/helpers/isPayPerViewAvailable';
import { envName } from '../../env';

enum SSAI_FWUID_TYPES {
  Monthly = 'Monthly',
  Annual = 'Annual',
  Yearly = 'Yearly',
  PPG = 'PayPerView',
  TVE = 'TVE',
  None = 'None',
}

const SSAI_FWUID = {
  [SSAI_FWUID_TYPES.Monthly]: 'DTC-MONTHLY',
  [SSAI_FWUID_TYPES.Annual]: 'DTC-ANNUAL',
  [SSAI_FWUID_TYPES.Yearly]: 'DTC-YEARLY',
  [SSAI_FWUID_TYPES.PPG]: 'DTC-PPG',
  [SSAI_FWUID_TYPES.TVE]: 'TVE',
  [SSAI_FWUID_TYPES.None]: 'NONE',
};

export const useSSAIHeaders = (
  asset: Video | null,
  deviceId: string,
  zipCode: string | null,
  currentSubscription: ActiveSubscription | null,
  platform: Platform = Platform.Web,
): Record<string, unknown> => {
  const platformLowerCase = platform.toLowerCase();
  const isSubPaused = currentSubscription?.subscriptionStatus === ProductServiceStatusEnum.PAUSED;

  const getFWUID = (
    type: SSAI_FWUID_TYPES,
    asset: Video,
    isSubscriptionPaused: boolean,
  ): string => {
    const isPPG = isPayPerViewAvailable(asset);

    if (isSubscriptionPaused) {
      return SSAI_FWUID[SSAI_FWUID_TYPES.None];
    }

    if (type === SSAI_FWUID_TYPES.Monthly) {
      return SSAI_FWUID[SSAI_FWUID_TYPES.Monthly];
    }

    if (type === SSAI_FWUID_TYPES.Annual || type === SSAI_FWUID_TYPES.Yearly) {
      return SSAI_FWUID[SSAI_FWUID_TYPES.Annual];
    }

    if (type === SSAI_FWUID_TYPES.TVE) {
      return SSAI_FWUID[SSAI_FWUID_TYPES.TVE];
    }

    if (isPPG) {
      return SSAI_FWUID[SSAI_FWUID_TYPES.PPG];
    }

    return SSAI_FWUID[SSAI_FWUID_TYPES.None];
  };

  if (asset) {
    const consumptionType =
      asset.playbackContentType === AssetContentType.EPISODE
        ? ConsumptionType.VOD
        : ConsumptionType.LIVE;

    return {
      urlParameters: {
        'ads._fw_is_lat': '1',
        'ads._fw_vcid2': deviceId,
        'ads._fw_zipcode': zipCode ?? '',
        'ads.csid': `msgplus_${platformLowerCase}_${consumptionType}`,
        'ads.external_id': deviceId,
        'ads.fw_did': deviceId,
        'ads.fwuid': getFWUID(
          currentSubscription?.displayName as SSAI_FWUID_TYPES,
          asset,
          isSubPaused,
        ),
        'ads.prof': `${
          envName === 'prod' ? '511661' : '511660'
        }:msgplus_${platformLowerCase}_${consumptionType}_H`,
      },
    };
  }

  return {};
};
