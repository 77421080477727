export const ApplePayPayment = () => (
  <svg width='35' height='24' viewBox='0 0 35 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='34.2857' height='24' rx='4' fill='#979B9F' />
    <g clipPath='url(#clip0_25468_645323)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.0835 11.7022C21.0654 9.87097 22.5766 8.98489 22.6445 8.94399C21.7984 7.69438 20.4772 7.52625 20.0021 7.50353C18.8799 7.38993 17.8076 8.16696 17.2375 8.16696C16.6674 8.16696 15.7851 7.51716 14.8575 7.53534C13.6313 7.55352 12.5002 8.24875 11.8712 9.35295C10.5998 11.5704 11.5455 14.8558 12.7852 16.6598C13.3915 17.5368 14.1155 18.5319 15.0656 18.4955C15.9796 18.4592 16.328 17.9003 17.4321 17.9003C18.5361 17.9003 18.8483 18.4955 19.8166 18.4728C20.7984 18.4546 21.4228 17.5731 22.0246 16.6916C22.7214 15.6692 23.0064 14.6786 23.0245 14.6286C23.0019 14.6195 21.1061 13.8879 21.088 11.6977L21.0835 11.7022Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.2646 6.31752C19.7668 5.70407 20.1061 4.84979 20.0157 4.00006C19.2917 4.02733 18.4094 4.48627 17.8891 5.09972C17.423 5.645 17.0113 6.50837 17.1244 7.33992C17.9343 7.40354 18.7623 6.92642 19.2646 6.31297V6.31752Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_25468_645323'>
        <rect width='11.7551' height='14.5' fill='white' transform='translate(11.2646 4.00006)' />
      </clipPath>
    </defs>
  </svg>
);
