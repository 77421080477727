import { SVGProps } from 'react';

export const VolumeUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.8192 6.7783C24.2017 6.96424 24.4446 7.35227 24.4446 7.77762V32.2221C24.4446 32.6474 24.2017 33.0355 23.8192 33.2214C23.4366 33.4073 22.9814 33.3586 22.647 33.0958L14.4643 26.6665H6.66678C6.05313 26.6665 5.55566 26.1691 5.55566 25.5554V14.4443C5.55566 13.8306 6.05313 13.3332 6.66678 13.3332H14.4643L22.647 6.90394C22.9814 6.64114 23.4366 6.59236 23.8192 6.7783ZM22.2223 10.0637L15.5351 15.318C15.3393 15.4718 15.0976 15.5554 14.8486 15.5554H7.77789V24.4443H14.8486C15.0976 24.4443 15.3393 24.5279 15.5351 24.6817L22.2223 29.936V10.0637Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8893 18.8887C29.184 18.8887 29.4666 19.0058 29.675 19.2142C29.8834 19.4225 30.0004 19.7052 30.0004 19.9998C30.0004 20.2945 29.8834 20.5771 29.675 20.7855C29.4666 20.9939 29.184 21.1109 28.8893 21.1109C28.2757 21.1109 27.7782 21.6084 27.7782 22.2221C27.7782 22.8357 28.2757 23.3332 28.8893 23.3332C29.7734 23.3332 30.6212 22.982 31.2463 22.3569C31.8715 21.7317 32.2227 20.8839 32.2227 19.9998C32.2227 19.1158 31.8715 18.2679 31.2463 17.6428C30.6212 17.0177 29.7734 16.6665 28.8893 16.6665C28.2757 16.6665 27.7782 17.164 27.7782 17.7776C27.7782 18.3913 28.2757 18.8887 28.8893 18.8887Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8892 14.4444C30.3626 14.4444 31.7757 15.0297 32.8176 16.0716C33.8595 17.1134 34.4448 18.5265 34.4448 19.9999C34.4448 21.4734 33.8595 22.8864 32.8176 23.9283C31.7757 24.9702 30.3626 25.5555 28.8892 25.5555C28.2756 25.5555 27.7781 26.053 27.7781 26.6666C27.7781 27.2803 28.2756 27.7777 28.8892 27.7777C30.952 27.7777 32.9303 26.9583 34.3889 25.4997C35.8476 24.041 36.667 22.0627 36.667 19.9999C36.667 17.9372 35.8475 15.9588 34.3889 14.5002C32.9303 13.0416 30.952 12.2222 28.8892 12.2222C28.2756 12.2222 27.7781 12.7196 27.7781 13.3333C27.7781 13.9469 28.2756 14.4444 28.8892 14.4444Z'
      fill={props.fill || 'white'}
    />
  </svg>
);
