import { PlaybackError } from '@msgn/fl-module/fl-analytics';

import { FLPlatformAsset } from '../api';

export const getPlaybackErrorPayload = ({
  errorCode,
  errorMessage,
  error,
  asset,
}: {
  errorCode: string;
  errorMessage: string;
  // eslint-disable-next-line
  error: any;
  asset?: FLPlatformAsset;
}): PlaybackError => {
  return {
    code: errorCode,
    content: {
      id: asset?.contentId,
      type: asset?.contentType,
    },
    internalError: error,
    message: errorMessage,
  };
};
