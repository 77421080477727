import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { FONT_WEIGHT } from '../../../Common';

export const Row = styled.div<{ $clicable: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 64px 38px;
  column-gap: 0.75rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors['grey']}`};
  padding: 0.625rem;

  cursor: ${({ $clicable }) => ($clicable ? 'pointer' : 'default')};

  &:hover {
    background: ${({ theme }) => `${theme.colors['white-scrim']}`};
  }

  ${breakpoint('lg')`
    grid-template-columns: 1fr 11rem 3rem;
    column-gap: 1.5rem;
    padding: 1rem 2rem;

  `}
`;

export const ProgramNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${breakpoint('lg')`
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  `}
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors['primary-light']};
  font-family: 'GothamNarrow';
  font-weight: ${FONT_WEIGHT.Bold};
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 0.875rem;
  ${breakpoint('lg')`
    width: 25rem;
    font-size: 1rem;
    line-height: 1.25rem;
  `}
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors['primary-light']};
  font-family: 'GothamNarrow';
  font-size: 0.625rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  flex: 1;
  ${breakpoint('lg')`
    font-size: 1rem;
    line-height: 1.25rem;
  `}
`;

export const Divider = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  height: 80%;
`;

export const Time = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.125rem;
  font-weight: ${FONT_WEIGHT.Bold};;

  span {
    white-space: nowrap;
    font-style: normal;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors['off-white']};
    font-family: 'GothamNarrow';
    text-transform: uppercase;

    ${breakpoint('lg')`
      font-size: 1rem;
      line-height: 1.25rem;
      flex-direction: column;
      color: ${({ theme }) => theme.colors['primary-light']};
    `}
`;

export const LivePill = styled.div`
  font-family: 'GothamNarrow';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 33px;
  color: ${({ theme }) => theme.colors['additional-color-black']};
  padding: 0.375rem 0.625rem;
  line-height: 0.875rem;
  margin-bottom: 0.25rem;

  ${breakpoint('lg')`
    line-height: 1.125rem;
    padding: 0.5rem 0.75rem;
  `}
  svg {
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.25rem;

    ${breakpoint('lg')`
      width: 0.875rem;
      height: 0.875rem;
    `}
  }
`;
