import styled from 'styled-components';

import AppLogo from '../../components/Logo/Logo';
import { breakpoint } from '../../utils/breakpoint';
import { Container, Row } from '../Common';

export const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Logo = styled(AppLogo)`
  width: 12.125rem;
  margin: 7.5rem auto 7.5rem auto;

  img {
    width: 100%;
  }

  ${breakpoint('md')`
    width: 11rem;
    margin-bottom: 2.5rem;
  `}

  ${breakpoint('xl')`
    width: 16.5rem;
    margin-bottom: 7.5rem;
  `}
`;

export const CheckWrapper = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 3rem;
`;

export const ButtonRow = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  margin-bottom: 1.5rem;
`;
