export const getScrollingPositionOfElement = (element: Element | null, elementId: string) => {
  if (!element) {
    return 0;
  }

  const tableElement = document.querySelector(`#${elementId}`);

  const offsetTop = (tableElement?.getBoundingClientRect().top || 0) + window.scrollY;

  const postition =
    element.getBoundingClientRect().top +
    window.scrollY -
    offsetTop -
    element.getBoundingClientRect().height / 2;

  return postition;
};
