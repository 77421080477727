import { getEnv } from '../../../env';
import { DisconnectTVEAccountResponse, FailureResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const disconnectTVEAccount = async ({
  accessToken,
  deviceId,
}: {
  accessToken: string;
  deviceId: string;
}): Promise<DisconnectTVEAccountResponse> => {
  const { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, DISCONNECT_TVE_ACCOUNT_URL } = getEnv();

  const data = {
    apiKey: EVERGENT_API_KEY,
    channelPartnerID: EVERGENT_CHANNEL_PARTNER_ID,
    deviceId,
  };

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  const body = { DisconnectTVEAccountRequestMessage: data };

  const response = await evergentAxiosInstance.post<{
    DisconnectTVEAccountResponseMessage: DisconnectTVEAccountResponse & FailureResponse;
  }>(DISCONNECT_TVE_ACCOUNT_URL, body, { headers });

  const { failureMessage, ...dataResponse } = response.data.DisconnectTVEAccountResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
