import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useListenToLiveEventEnd } from '../../hooks/flp/useListenToLiveEventEnd';
import { playerActions } from '../../store/player';
import { usePlayerTime } from '../playerUI/hooks/usePlayerTime';
import { useVideoComplete } from '../playerUI/hooks/useVideoComplete';

export const PlayerTimeConsumer = () => {
  const dispatch = useDispatch();

  usePlayerTime();
  useVideoComplete();

  const onLiveEventEnd = useCallback(() => {
    dispatch(playerActions.setLiveStreamEnded(true));
  }, []);

  useListenToLiveEventEnd(onLiveEventEnd);

  return null;
};
