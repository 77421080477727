export const SamsungCheckoutIcon = () => (
  <svg width='35' height='24' viewBox='0 0 35 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='34.2857' height='24' rx='4' fill='#1B41CC' />
    <path
      d='M24.6853 9.10707V8.09385C24.6853 7.40936 24.1314 6.85547 23.4469 6.85547H10.838C10.1535 6.85547 9.59961 7.40936 9.59961 8.09385V9.10707H24.6853Z'
      fill='white'
    />
    <path
      d='M9.59961 11.8086V16.537C9.59961 17.2214 10.1535 17.7753 10.838 17.7753H23.4469C24.1314 17.7753 24.6853 17.2214 24.6853 16.537V11.8086H9.59961Z'
      fill='white'
    />
  </svg>
);
