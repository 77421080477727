import { ContentAuthorizationData, PlatformAsset } from '@msgn/fl-module/fl-content-authorization';

import { ContentAuthorizer, PlaybackParams } from './playerApi.types';

export const authorizePlayback = (
  contentAuthorizer: ContentAuthorizer,
  params: PlaybackParams,
  headers: Record<string, string> = {},
): Promise<ContentAuthorizationData> => {
  return contentAuthorizer.authorizePlayback(params as PlatformAsset, headers);
};
