import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { flexCenter } from '../../utils/styled/mixins';
import { Button } from '../Button';
import { FONT_WEIGHT, bolder } from '../Common';

export const ErrorWrapper = styled.div`
  ${flexCenter}
  z-index: ${({ theme }) => theme.zIndex.playerButtons};
`;

export const ErrorWrapperInner = styled.div`
  ${flexCenter}
  flex-direction: column;
  gap: 1.5rem;
  ${breakpoint('lg')`
  	gap: 2.5rem;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${breakpoint('lg')`
    gap: 1.5rem;
  `}
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  font-family: 'GothamNarrow';
  font-size: 1.25rem;
  font-style: normal;
  ${bolder}
  line-height: 1.2;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-align: center;

  ${breakpoint('lg')`
  	font-size: 2.5rem;
  `}
`;

export const ErrorDescription = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  text-align: center;
  font-family: 'GothamNarrow';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.2;
  font-size: 1rem;

  ${breakpoint('lg')`
    font-size: 1.25rem;
  `}
`;

export const ErrorButton = styled(Button)`
  width: 100%;
  ${breakpoint('lg')`
 	  width: 16.5rem;
  `}
`;
