import styled from 'styled-components';

import bgBlue1920 from '../../../assets/images/minimized/bg_blue_1920.jpg';
import { IconButton } from '../../IconButton/IconButton';

export const ReplayButtonWrapper = styled.div<{ $backgroundImage?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ $backgroundImage }) => `url(${$backgroundImage || bgBlue1920})`};
  z-index: ${({ theme }) => theme.zIndex.playerButtons};

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to top, rgba(17, 17, 17, 1) 0%, rgba(17, 17, 17, 0) 100%);
    content: '';
  }
`;

export const ReplayButton = styled(IconButton)`
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.replayButton};
  background: var(linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111 100%));
`;
