import Conviva from '@convivainc/conviva-js-coresdk';
import { useCallback, useEffect } from 'react';

import { useConvivaContext } from '../../components/ConvivaProvider/ConvivaProvider';
import { usePlayerTimeContext } from '../../components/playerUI/PlayerTimeProvider/PlayerTimeProvider';
import { usePlayerContext } from './usePlayerContext';

export const usePlayerProgressUpdate = (
  onProgressUpdate: (currentTime: number, duration: number) => void,
) => {
  const { player } = usePlayerContext();
  const { seekValue } = usePlayerTimeContext();
  const { convivaSession } = useConvivaContext();

  const handleProgressUpdate = useCallback(() => {
    if (!seekValue) {
      onProgressUpdate(player.currentTime, player.duration);
    }

    convivaSession?.reportPlaybackMetricEvent(
      Conviva.Constants.Playback.PLAY_HEAD_TIME,
      player.isLive ? player.currentTime : player.currentTime * 1000,
    );
  }, [player, onProgressUpdate, seekValue]);

  useEffect(() => {
    if (player) {
      player?.subscribe('progressupdate', handleProgressUpdate);
      return () => {
        player?.unsubscribe('progressupdate', handleProgressUpdate);
      };
    }
  }, [player, handleProgressUpdate]);
};
