import styled, { css } from 'styled-components';

interface StyledProps {
  $withBackdrop?: boolean;
  $absolute?: boolean;
}

export const LoaderWrapper = styled.div<StyledProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  ${(props) =>
    props.$withBackdrop &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
    `}

  ${(props) =>
    props.$absolute &&
    css`
      z-index: ${({ theme }) => theme.zIndex.player};
      position: absolute;
      top: 0;
      left: 0;
    `}
`;
