import { format } from 'date-fns';
import { FC, useCallback } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTES, RouteName } from '../../../../Constants/routes';
import { Video } from '../../../../api';
import { GetProductsResponseItem } from '../../../../api/authApi/authApi.types';
import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { CheckSubscriptionParamsType } from '../../../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../../../hooks/useTranslations';
import { activeSubscriptionsSelector } from '../../../../store/activeSubscriptions';
import { AppDispatch } from '../../../../store/store';
import { displayVideo } from '../../../../store/videos';
import { usePauseSubscription } from '../../../Settings/hooks/usePauseSubscription';
import { ProgramButton } from '../ProgramButton/ProgramButton';
import * as Ui from './ProgramRow.styles';

interface ProgramRowProps {
  checkSubscription: (
    asset: Video | null,
    { replaceRoute, onSuccess, shouldTriggerGoBackOnClick }: CheckSubscriptionParamsType,
  ) => Promise<boolean>;
  program: Video;
}

export const ProgramRow: FC<ProgramRowProps> = ({ checkSubscription, program }) => {
  const t = useTranslations();
  const dispatch: AppDispatch = useDispatch();
  const { hasActiveSubscriptions } = useSelector(activeSubscriptionsSelector);
  const navigate = useExtendedNavigate();
  const currentLocation = useLocation();
  const { handleResumeSubscription, isSubscriptionPaused } = usePauseSubscription();

  const onPlay = useCallback(() => {
    if (isSubscriptionPaused) {
      return handleResumeSubscription();
    }

    if (!hasActiveSubscriptions || !!program.isCurrentlyAiring) {
      checkSubscription(program, {
        onSuccess: (product: GetProductsResponseItem): void =>
          navigate(`${ROUTES[RouteName.AllSetPayment]}`, {
            state: { backRoute: currentLocation, product },
          }),
        replaceRoute: false,
      })
        .then((playbackAuthorized: boolean) => program.isCurrentlyAiring && playbackAuthorized)
        .then((): void => {
          dispatch(displayVideo(program));
          navigate(`${ROUTES[RouteName.Live]}?channel=${program.cid}`, {
            state: { goBackWhenNotAuthorized: true },
          });
        });
    }
  }, [
    hasActiveSubscriptions,
    program,
    checkSubscription,
    navigate,
    currentLocation,
    dispatch,
    isSubscriptionPaused,
    handleResumeSubscription,
  ]);

  return (
    <Ui.Row
      key={program?.id}
      onClick={onPlay}
      $clicable={!hasActiveSubscriptions || !!program.isCurrentlyAiring}
    >
      <Ui.ProgramNameWrapper>
        <Ui.Title>{program.title} </Ui.Title>
        <Ui.Description> {program.description}</Ui.Description>
      </Ui.ProgramNameWrapper>

      <Ui.Time>
        {program.isCurrentlyAiring ? (
          <Ui.LivePill>
            <BsCircleFill /> {t.global_live}
          </Ui.LivePill>
        ) : (
          <div />
        )}
        <span>{format(new Date(program.startDate), 'h:mm a')}</span>
      </Ui.Time>
      <ProgramButton hasActiveSubscriptions={hasActiveSubscriptions} program={program} />
    </Ui.Row>
  );
};
