import { useEffect, useRef, useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { useDraggable } from 'react-use-draggable-scroll';

import useIsMobile from '../../hooks/useIsMobile';
import {
  GenericCarouselArrowButton,
  GenericCarouselItemsWrapper,
  GenericCarouselWrapper,
} from './GanericCarousel.styles';
import { GenericCarouselProps } from './GenericCarousel.types';
import { useScrollToCarouselElement } from './hooks/useScrollToCarouselElement';

export const GenericCarousel = ({
  children,
  isBackwardButtonDisabled,
  isForwardButtonDisabled,
  handleControlBackward,
  handleControlForward,
  scrollRef,
  containerSize,
  ...restProps
}: GenericCarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);
  const isMobile = useIsMobile();
  const shouldShowArrows = showArrows && !isMobile;
  useScrollToCarouselElement(scrollRef);
  // eslint-disable-next-line
  // @ts-ignore
  const { events } = useDraggable(scrollRef);

  useEffect(() => {
    if (containerRef.current && scrollRef.current) {
      setShowArrows(scrollRef.current?.scrollWidth > containerRef.current.offsetWidth);
    }
  }, [containerRef.current, containerSize]);

  return (
    <GenericCarouselWrapper ref={containerRef} {...restProps}>
      {shouldShowArrows && (
        <GenericCarouselArrowButton
          disabled={isBackwardButtonDisabled}
          $disabled={isBackwardButtonDisabled}
          onClick={handleControlBackward}
        >
          <BiChevronLeft />
        </GenericCarouselArrowButton>
      )}

      <GenericCarouselItemsWrapper ref={scrollRef} {...events}>
        {children}
      </GenericCarouselItemsWrapper>

      {shouldShowArrows && (
        <GenericCarouselArrowButton
          disabled={isForwardButtonDisabled}
          $disabled={isForwardButtonDisabled}
          onClick={handleControlForward}
        >
          <BiChevronRight />
        </GenericCarouselArrowButton>
      )}
    </GenericCarouselWrapper>
  );
};
