import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslations } from '../../hooks/useTranslations';
import { AppDispatch } from '../../store/store';
import { zipCodeUpdate, zoneInfoStateSelector } from '../../store/zoneInfo';
import { Button } from '../Button';
import { ButtonSize, ButtonVariant } from '../Button/Button.types';
import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import { Loading } from '../Loading';

export const ZipCodeModalButtons = ({ onCancelAction }: { onCancelAction: () => void }) => {
  const t = useTranslations();
  const dispatch: AppDispatch = useDispatch();

  const { currentLocation, updating } = useSelector(zoneInfoStateSelector);

  const navigatorZipCode = currentLocation?.zip || '';

  const handleContinue = useCallback(() => {
    dispatch(zipCodeUpdate(navigatorZipCode));
  }, [dispatch, navigatorZipCode]);

  return (
    <div className='row w-100 m-0'>
      <div className='col-6 ps-0'>
        <ClickEventDispatcher
          location='Modal: Zip code modal'
          text={`CTA: ${t.zipCodeModal_continueButton}`}
        >
          <Button
            disabled={updating}
            variant={ButtonVariant.Primary}
            onPress={handleContinue}
            className='w-100'
            size={ButtonSize.Large}
          >
            {t.zipCodeModal_continueButton}
            {updating && <Loading size='sm' />}
          </Button>
        </ClickEventDispatcher>
      </div>
      <div className='col-6 pe-0'>
        <ClickEventDispatcher
          location='Modal: Zip code modal'
          text={`CTA: ${t.zipCodeModal_changeZipCodeButton}`}
        >
          <Button
            disabled={updating}
            variant={ButtonVariant.Secondary}
            onPress={onCancelAction}
            className='w-100 border'
            size={ButtonSize.Large}
          >
            {t.zipCodeModal_changeZipCodeButton}
          </Button>
        </ClickEventDispatcher>
      </div>
    </div>
  );
};
