import { createSelector } from '@reduxjs/toolkit';

import { PlaybackMode } from '../../api';
import { configSelector } from '../config';
import { RootState } from '../types';

export const playerSelector = (state: RootState) => state.player;

export const playerErrorSelector = createSelector(playerSelector, ({ error }) => error);
export const playerAssetLoadingSelector = createSelector(
  playerSelector,
  ({ assetLoading }) => assetLoading,
);

export const playerAssetErrorSelector = createSelector(
  playerSelector,
  ({ assetError }) => assetError,
);

export const isLocalPlayerSelector = createSelector(
  playerSelector,
  ({ isLocalPlayer }) => isLocalPlayer,
);

export const doesPlayerHasErrorSelector = createSelector(
  playerErrorSelector,
  playerAssetErrorSelector,
  (playerError, assetError) => Boolean(playerError) || Boolean(assetError),
);

export const playerAssetSelector = createSelector(playerSelector, ({ asset }) => asset);

export const playerMaxHeightSelector = createSelector(playerSelector, ({ maxHeight }) => maxHeight);

export const fullScreenSelector = createSelector(
  playerSelector,
  ({ isFullScreen }) => isFullScreen,
);

export const playerStateSelector = createSelector(playerSelector, ({ state }) => state);

export const adsUrlSelector = createSelector(playerAssetSelector, (asset) =>
  asset?.custom ? asset?.custom.ssaiAdMetadataUrl : '',
);

export const isLivePlaybackModeSelector = createSelector(playerAssetSelector, (asset) => {
  return asset?.livePlaybackMode === PlaybackMode.LIVE;
});

export const isLiveStreamEndedSelector = createSelector(
  playerAssetSelector,
  (asset) => !!asset?.isLiveStreamEnded,
);

export const shouldFetchCompanionAdsSelector = createSelector(
  adsUrlSelector,
  fullScreenSelector,
  isLivePlaybackModeSelector,
  configSelector,
  (adsUrl, fullScreen, isLivePlaybackMode, { env }) => {
    return Boolean(adsUrl && !fullScreen && isLivePlaybackMode && env.COMPANION_ADS_ENABLED);
  },
);
