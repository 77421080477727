import { Modal as BootstrapModal } from 'react-bootstrap';
import styled from 'styled-components';

import { breakpoint } from '../../../utils/breakpoint';
import { bgBlue } from '../../../utils/styled/mixins';
import { Button as AppButton } from '../../Button/Button';
import {
  Container as AppContainer,
  TypographyProps,
  bolder,
  fonts,
  textBase,
  uppercase,
} from '../../Common';
import { LegalLink as AppLegalLink } from '../../LegalLink/LegalLink';

const MAX_CONTENT_WIDTH = '44rem';

export const ModalBody = styled(BootstrapModal.Body)`
  padding: 0;
  ${bgBlue}
`;

export const Container = styled(AppContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${({ theme }) => theme.colors['off-white']};
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Section = styled.div<{ $takeFullHeight?: boolean }>`
  ${({ $takeFullHeight }) =>
    $takeFullHeight &&
    `
	   height: 100%;
	`}

  ${breakpoint('md')`
    	height: unset;
  	`}
`;

export const ModalHeader = styled(BootstrapModal.Header).attrs({
  className: 'border-0 pb-0 pt-0',
  closeVariant: 'white',
})``;

export const ModalTitle = styled(BootstrapModal.Title)`
  color: ${({ theme }) => theme.colors['off-white']};
`;

export const BackButtonWrapper = styled.div`
  margin-top: 2.5rem;

  ${breakpoint('md')`
    margin-top: 1.25rem;
  `}
`;

export const TitleHeader = styled.h1<TypographyProps>`
  ${textBase}
  ${bolder}
  ${uppercase}
  margin-top: 1.5rem;
  margin-bottom: 3rem;

  font-size: 1.75rem;
  line-height: 1.75rem;
  letter-spacing: 0.07rem;
  text-align: center;
  ${breakpoint('md')`
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.1rem;
    margin-top: 3.25rem;
  `}
`;

export const Content = styled.div`
  max-width: ${MAX_CONTENT_WIDTH};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  gap: 3rem;
  ${breakpoint('md')`
  	gap: 4.5rem;
  `}
`;

export const ContentText = styled.p`
  margin-bottom: 0;
  ${fonts.body2.xs}

  ${breakpoint('md')`
  	${fonts.body1.xl}
  `}
`;

export const ContentList = styled.div`
  text-transform: uppercase;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const Img = styled.img`
  width: 3rem;

  ${breakpoint('md')`
	width: auto
  `}
`;

export const Arrow = styled.img`
  width: 2rem;

  ${breakpoint('md')`
	width: auto
  `}
`;

export const FaqInfoWrapper = styled.div`
  max-width: ${MAX_CONTENT_WIDTH};
  margin: 1.5rem auto 1.75rem auto;
  text-align: center;
  ${fonts.body3.xs}

  ${breakpoint('md')`
  	${fonts.body2.xl}
    margin: 2.5rem auto 1.5rem auto;
  `}
`;

export const LegalLink = styled(AppLegalLink)`
  font-size: 1rem;
  line-height: 1.25;

  ${breakpoint('md')`
    text-transform: uppercase;
  `}
`;

export const ConnectTVEProviderButton = styled(AppButton)`
  width: 100%;
  margin-bottom: 1.5rem;

  ${breakpoint('md')`
  	margin-bottom: 4.5rem;
  `}
`;
