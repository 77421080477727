import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { NavigationItem } from '../../Navigation.types';
import { itemsLogosMap } from '../../constants/icons';
import { isElementActive } from '../../helpers/isElementActive';
import { navigationClickHandler } from '../../helpers/navigationClickHandler';
import * as Ui from './SidebarItem.styles';

interface SidebarItemProps {
  item: NavigationItem;
  closeMobileNavigation: () => void;
  disabled?: boolean;
}

const Label = ({ item, disabled }: { item: NavigationItem; disabled?: boolean }) => (
  <Ui.LogoWrapper>
    <Ui.Logo>
      {itemsLogosMap[item.type](isElementActive(item), item.isAvailableOnMobile, disabled)}
    </Ui.Logo>
    <span>{item.label}</span>
  </Ui.LogoWrapper>
);

export const SidebarItem = ({ item, closeMobileNavigation, disabled }: SidebarItemProps) => {
  const navigate = useExtendedNavigate();

  return (
    <Ui.Item
      $isEnabled={item.isAvailableOnMobile}
      $isActive={isElementActive(item)}
      onClick={() => {
        if (disabled) return;

        navigationClickHandler(item.type, item.path || '', navigate);
        closeMobileNavigation();
      }}
      data-text={item.label}
      $disabled={disabled}
    >
      <Label item={item} disabled={disabled} />
    </Ui.Item>
  );
};
