import { DeviceInfo, FailureResponse, SignUpResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const signUp = async ({
  password,
  username,
  deviceInfo,
  evergentApiKey,
  evergentChannelPartnerId,
  signUpUrl,
}: {
  password: string;
  username: string;
  deviceInfo: DeviceInfo;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  signUpUrl: string;
}): Promise<SignUpResponse> => {
  const deviceMessage = {
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    modelNo: deviceInfo.deviceModelNo,
    serialNo: deviceInfo.deviceId,
    userAgent: deviceInfo.deviceUserAgent,
  };

  const singUpData = {
    accountAttributes: [
      {
        attributeName: 'MarketingPreferences',
        value: 'FALSE',
      },
    ],
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    customerPassword: password,
    customerUsername: username,
    deviceMessage: deviceMessage,
    email: username,
    isGenerateJWT: true,
  };

  const body = { CreateUserRequestMessage: singUpData };

  const signUpResponse = await evergentAxiosInstance.post<{
    CreateUserResponseMessage: SignUpResponse & FailureResponse;
  }>(signUpUrl, body);

  const { failureMessage, ...dataResponse } = signUpResponse.data.CreateUserResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
