import { Home } from '../../../assets/icons/sideNavigation/Home';
import { Live } from '../../../assets/icons/sideNavigation/Live';
import { Odds } from '../../../assets/icons/sideNavigation/Odds';
import { Schedule } from '../../../assets/icons/sideNavigation/Schedule';
import { Settings } from '../../../assets/icons/sideNavigation/Settings';
import { Teams } from '../../../assets/icons/sideNavigation/Team';
import { colors } from '../../ThemeProvider/constants/colors';
import { NavigationItemType } from '../Navigation.types';

export const itemsLogosMap: Record<
  NavigationItemType,
  (active: boolean, isAvailable: boolean, disabled?: boolean) => JSX.Element
> = {
  [NavigationItemType.HOME]: (active, isAvailable, disabled) => (
    <Home active={active} fill={!isAvailable || disabled ? colors.grey : undefined} />
  ),
  [NavigationItemType.LIVE]: (active, isAvailable, disabled) => (
    <Live active={active} fill={!isAvailable || disabled ? colors.grey : undefined} />
  ),
  [NavigationItemType.SCHEDULE]: (active, isAvailable, disabled) => (
    <Schedule active={active} fill={!isAvailable || disabled ? colors.grey : undefined} />
  ),
  [NavigationItemType.TEAMS]: (active, isAvailable, disabled) => (
    <Teams active={active} fill={!isAvailable || disabled ? colors.grey : undefined} />
  ),
  [NavigationItemType.ODDS]: (active, isAvailable, disabled) => (
    <Odds active={active} fill={!isAvailable || disabled ? colors.grey : undefined} />
  ),
  [NavigationItemType.SETTINGS]: (active, isAvailable, disabled) => (
    <Settings active={active} fill={!isAvailable || disabled ? colors.grey : undefined} />
  ),
};
