import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { videosSelector } from '../../../store/videos';
import t from '../../../translations/en.json';
import { TeamShield } from '../../TeamShield/TeamShield';
import * as Ui from './GameDetails.styled';

export const GameDetails = () => {
  const { purchaseVideo: video } = useSelector(videosSelector);

  const { homeTeam, visitorTeam, description } = video || {};
  const areTeamsAvailable = !!homeTeam?.id && !!visitorTeam?.id;

  if (!video || !areTeamsAvailable) {
    return null;
  }

  const titleText = `${visitorTeam.name} ${t.global_vs} ${homeTeam.name}`;

  return (
    <Ui.Wrapper>
      {areTeamsAvailable && (
        <Ui.TeamsWrapper>
          <Ui.TeamShieldWrapper>
            <TeamShield teamId={visitorTeam.teamId} teamName={visitorTeam.name} />
          </Ui.TeamShieldWrapper>

          <Ui.TeamSpan>{t.global_vs}</Ui.TeamSpan>

          <Ui.TeamShieldWrapper>
            <TeamShield teamId={homeTeam.teamId} teamName={homeTeam.name} />
          </Ui.TeamShieldWrapper>
        </Ui.TeamsWrapper>
      )}

      <div>
        <Ui.Title>{titleText}</Ui.Title>
        <Ui.Description>{description}</Ui.Description>
        <Ui.EventDateLabel>
          {t.planCard_scheduledFor}:{' '}
          <Ui.EventDateSpan>{format(new Date(video.startDate), 'MM/dd/yyyy')}</Ui.EventDateSpan>
        </Ui.EventDateLabel>
      </div>
    </Ui.Wrapper>
  );
};
