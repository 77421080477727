import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { routerActions } from '../store/router';
import { AppDispatch } from '../store/store';

export const useSetPreviousPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(routerActions.setPreviousLocation(location));
    };
  }, [location.pathname]);
};
