import { SVGProps } from 'react';

import { colors } from '../../components/ThemeProvider/constants/colors';

interface ChevronLeftProps extends SVGProps<SVGSVGElement> {
  large?: boolean;
}

export const ChevronLeft = (props: ChevronLeftProps) => {
  if (props.large) {
    return (
      <svg
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='chevron-left outlined'>
          <path
            id='Vector'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.2071 8.29289C23.5976 8.68342 23.5976 9.31658 23.2071 9.70711L14.9142 18L23.2071 26.2929C23.5976 26.6834 23.5976 27.3166 23.2071 27.7071C22.8166 28.0976 22.1834 28.0976 21.7929 27.7071L12.7929 18.7071C12.4024 18.3166 12.4024 17.6834 12.7929 17.2929L21.7929 8.29289C22.1834 7.90237 22.8166 7.90237 23.2071 8.29289Z'
            fill={props.fill || colors['off-white']}
          />
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.4163 6.47943C14.7038 6.70943 14.7504 7.12896 14.5204 7.41647L10.8536 12L14.5204 16.5835C14.7504 16.871 14.7038 17.2906 14.4163 17.5206C14.1288 17.7506 13.7092 17.704 13.4792 17.4165L9.47923 12.4165C9.28445 12.173 9.28445 11.827 9.47923 11.5835L13.4792 6.58354C13.7092 6.29603 14.1288 6.24942 14.4163 6.47943Z'
        fill={props.fill || colors['off-white']}
      />
    </svg>
  );
};
