import { AdSizeName } from './CompanionAds.types';

export const COMPANION_ADS_SIZES = {
  [AdSizeName.SMALL]: {
    height: 50,
    width: 320,
  },
  [AdSizeName.MEDIUM]: {
    height: 250,
    width: 300,
  },
  [AdSizeName.LARGE]: {
    height: 90,
    width: 970,
  },
};

export const IMPRESSIONS_STORAGE_KEY = 'companionAdImpressions';
