import { useSelector } from 'react-redux';

import { GetProductsResponseItem } from '../../../api/authApi/authApi.types';
import { useTranslations } from '../../../hooks/useTranslations';
import { purchaseSelector } from '../../../store/purchase';
import { zoneInfoStateSelector } from '../../../store/zoneInfo';
import PurchaseIframe from '../../PurchaseIframe/PurchaseIframe';
import * as Ui from '../PurchaseCommon.styles';

export const PurchaseForm = ({
  product,
  onError,
  onSuccess,
  changeSelection,
  hideZipCodeChange,
}: {
  product: GetProductsResponseItem;
  onError: (err: unknown) => void;
  onSuccess: () => void;
  changeSelection: () => void;
  hideZipCodeChange?: boolean;
}) => {
  const t = useTranslations();
  const { voucherCode, item } = useSelector(purchaseSelector);
  const { zoneKey, zipCode } = useSelector(zoneInfoStateSelector);

  return (
    <Ui.FormWrapperCol>
      <Ui.FormBox>
        <Ui.FormTitle>{t.purchaseModal_formTitle}</Ui.FormTitle>

        <Ui.BillingWrapper>
          <div>
            <Ui.FormBillingZipCode>
              {t.purchaseModal_formDescription_1} {zipCode}
            </Ui.FormBillingZipCode>
            <Ui.FormDescription>{t.purchaseModal_formDescription_2}</Ui.FormDescription>
          </div>

          {!hideZipCodeChange && (
            <Ui.SummaryLink onClick={changeSelection}>{t.purchaseModal_link}</Ui.SummaryLink>
          )}
        </Ui.BillingWrapper>

        <Ui.Hr />

        {zoneKey && zipCode && (
          <PurchaseIframe
            zoneKey={zoneKey}
            zipCode={zipCode}
            product={product}
            onSuccess={onSuccess}
            onError={onError}
            total={item?.amountDueToday}
            couponCode={voucherCode}
          />
        )}
      </Ui.FormBox>
    </Ui.FormWrapperCol>
  );
};
