import {
  ActivationCodeResponse,
  ActivationCodeValidatedResponse,
  DeviceInfo,
  FailureResponse,
} from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getActivationCode = async ({
  deviceInfo,
  evergentApiKey,
  evergentChannelPartnerId,
  generateActivationCode,
}: {
  deviceInfo: DeviceInfo;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  generateActivationCode: string;
}): Promise<ActivationCodeResponse | ActivationCodeValidatedResponse> => {
  const deviceDetails = {
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    modelNo: deviceInfo.deviceModelNo,
    serialNo: deviceInfo.deviceId,
  };

  const activationCodeData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    deviceDetails,
  };

  const body = { GenerateDeviceActivationCodeRequestMessage: activationCodeData };

  const response = evergentAxiosInstance.post<{
    GenerateDeviceActivationCodeResponseMessage:
      | (ActivationCodeResponse & FailureResponse)
      | (ActivationCodeValidatedResponse & FailureResponse);
  }>(generateActivationCode, body);

  const { failureMessage, ...dataResponse } = (await response).data
    .GenerateDeviceActivationCodeResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
