import { isAfter } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelector } from '../../store/auth';
import {
  loadEntitlements,
  ovatTokenExpirationTimestampSelector,
  ovatTokenSelector,
} from '../../store/entitlements';
import { AppDispatch } from '../../store/store';

// everytime we use ovat token we need to check is it still valid
export const useOvatTokenSelector = () => {
  const dispatch: AppDispatch = useDispatch();

  const { accessToken } = useSelector(authSelector);
  const ovatToken = useSelector(ovatTokenSelector);
  const ovatTokenExpirationTimestamp = useSelector(ovatTokenExpirationTimestampSelector);
  const isValid = ovatTokenExpirationTimestamp
    ? !isAfter(new Date().getTime(), ovatTokenExpirationTimestamp)
    : false;

  useEffect(() => {
    if (!isValid) {
      dispatch(loadEntitlements({ accessToken }));
    }
  }, []);

  return { isValid, ovatToken };
};
