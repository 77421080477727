import { SVGProps } from 'react';

export const CrossInCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon'>
        <path
          id='Ellipse 19 (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M49.9991 11.1112C28.5214 11.1112 11.1102 28.5224 11.1102 50.0001C11.1102 71.4778 28.5214 88.889 49.9991 88.889C71.4769 88.889 88.888 71.4778 88.888 50.0001C88.888 28.5224 71.4769 11.1112 49.9991 11.1112ZM5.55469 50.0001C5.55469 25.4541 25.4531 5.55566 49.9991 5.55566C74.5451 5.55566 94.4436 25.4541 94.4436 50.0001C94.4436 74.5461 74.5451 94.4445 49.9991 94.4445C25.4531 94.4445 5.55469 74.5461 5.55469 50.0001Z'
          fill={props.fill || '#F44336'}
        />
        <path
          id='Vector (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M35.5362 35.5362C36.621 34.4515 38.3798 34.4515 39.4646 35.5362L50.0004 46.0721L60.5363 35.5362C61.621 34.4515 63.3798 34.4515 64.4646 35.5362C65.5494 36.621 65.5494 38.3798 64.4646 39.4646L53.9288 50.0004L64.4646 60.5363C65.5494 61.621 65.5494 63.3798 64.4646 64.4646C63.3798 65.5494 61.621 65.5494 60.5363 64.4646L50.0004 53.9288L39.4646 64.4646C38.3798 65.5494 36.621 65.5494 35.5362 64.4646C34.4515 63.3798 34.4515 61.621 35.5362 60.5363L46.0721 50.0004L35.5362 39.4646C34.4515 38.3798 34.4515 36.621 35.5362 35.5362Z'
          fill={props.fill || '#F44336'}
        />
      </g>
    </svg>
  );
};
