import styled, { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
	opacity:0.6;
    transform: scale(0.3);
  }
  30% {
	opacity:0.3;
    transform: scale(1.3);

  }
  100% {
	opacity:0.1;
    transform: scale(1);
  }
`;

const iconAnimation = keyframes`
  0% {
	opacity:1;
    transform: scale(0.5);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const Circle = styled.div`
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  width: 11.25rem;
  height: 11.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${pulseAnimation} 400ms ease-in-out;
  position: absolute;
  top: calc(50% - 5.625rem);
  left: calc(50% - 5.625rem);
  opacity: 0;
`;

export const IconWrapper = styled.div`
  animation: ${iconAnimation} 600ms ease-in-out;
  position: absolute;
  top: calc(50% - 5.625rem);
  left: calc(50% - 5.625rem);
  width: 11.25rem;
  height: 11.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
`;
