import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';

import { videoSeekStarted } from '../../../api/mParticlesApi/mParticleEvents/video/videoEvents';
import { usePlayerContext } from '../../../hooks/quickplay/usePlayerContext';
import { useVideos } from '../../../hooks/useVideos';
import { usePlayerControlsContext } from '../../PlayerControls/PlayerControlsProvider';
import { usePlayerTimeContext } from '../PlayerTimeProvider/PlayerTimeProvider';
import { getPlayerStyles } from '../PlayerUi.styles';
import { formatDuration } from './ProgressBar.helprers';
import * as Ui from './ProgressBar.styles';

export const DEFAULT_STEP_SIZE = 5;

export const ProgressBar = () => {
  const { selectedVideo } = useVideos();
  const { player } = usePlayerContext();
  const { start, end } = player.seekableRange();
  const { currentTime, duration, seekValue, setSeekValue } = usePlayerTimeContext();

  const { logSeekBarEnded } = usePlayerControlsContext();
  const theme = useTheme();
  const isLive = player.isLive;

  return (
    <div className='w-100'>
      <Slider
        aria-label='time-indicator'
        size='small'
        value={seekValue || currentTime}
        min={start}
        step={1}
        max={end}
        valueLabelDisplay='auto'
        valueLabelFormat={(time) => {
          return isLive ? `-${formatDuration(end - currentTime)}` : formatDuration(time);
        }}
        onChange={(event, value) => {
          if (Array.isArray(value)) {
            return;
          }
          setSeekValue(value);
          if (event?.type === 'mousedown') {
            videoSeekStarted(selectedVideo, value);
          }
        }}
        onChangeCommitted={() => {
          logSeekBarEnded(seekValue || 0);
        }}
        sx={getPlayerStyles(theme, false)}
      />

      {!isLive && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            mt: -2,
          }}
        >
          <Ui.TinyText>{formatDuration(seekValue || currentTime)}</Ui.TinyText>
          <Ui.TinyText>-{formatDuration(duration - (seekValue || currentTime))}</Ui.TinyText>
        </Box>
      )}
    </div>
  );
};
