import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';

export const TabSelectorWrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  justify-content: center;

  ${breakpoint('lg')`
    gap: 1rem;
  `}
`;
