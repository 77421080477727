import { AssetContentType, Video } from '../api';
import { ProductServiceTypeEnum } from '../api/authApi/authApi.types';

interface IAssetSearchParams {
  cid: string;
  contentType: AssetContentType | '';
  id: string;
  isLive: string;
  playbackContentType: Video['playbackContentType'];
  playbackMode: Video['playbackMode'];
}

const defaultAsset: Video = {
  cid: '',
  contentType: undefined,
  description: '',
  endDate: '',
  externalId: '',
  id: '',
  isFree: false,
  isLive: false,
  playbackContentType: undefined,
  productType: ProductServiceTypeEnum.SVOD,
  rawData: {},
  startDate: '',
  title: '',
};

const ASSET_PROPS = ['contentType', 'id', 'isLive', 'cid', 'playbackContentType', 'playbackMode'];

export const getSearchParamsFromAsset = ({
  contentType,
  id,
  isLive,
  cid,
  playbackContentType,
  playbackMode,
}: Video) => {
  const params: Partial<IAssetSearchParams> = {
    contentType,
    id,
    playbackContentType,
  };

  if (cid) params.cid = cid;
  if (isLive) params.isLive = 'true';
  if (playbackMode) params.playbackMode = playbackMode;

  return new URLSearchParams(params);
};

export const getAssetFromSearchParams = (searchParams: URLSearchParams): Video | null => {
  const asset = ASSET_PROPS.reduce<Video>((acc, key) => {
    const value = searchParams.get(key);
    return value ? { ...acc, [key]: value } : acc;
  }, defaultAsset);

  if (!asset.id || !asset.contentType) return null;

  return asset;
};

export const hasSearchParams = (SearchParams: URLSearchParams): boolean =>
  !!SearchParams.toString().length;

export const stringToHashTag = (input: string, prefix = '#'): string => {
  const stringWithUnderscores = input.replace(/ /g, '_');
  const lowercaseString = stringWithUnderscores.toLowerCase();
  const hashTag = `${prefix}${lowercaseString}`;

  return hashTag;
};

export const getQueryParams = () => {
  const queryString = window.location.href.split('?')[1];

  if (!queryString) {
    return {};
  }

  const queryParams: { [key: string]: string } = {};
  queryString.split('&').forEach((pair) => {
    const [key, value] = pair.split('=');
    queryParams[key] = decodeURIComponent(value);
  });

  return queryParams;
};
