import { format, isToday, isTomorrow, isYesterday } from 'date-fns';

import { ITranslationData } from '../store/translations';

export const getContextualDateLabel = (t: ITranslationData, startDate: Date): string => {
  const isGameYesterday = isYesterday(startDate);
  const isGameToday = isToday(startDate);
  const isGameTomorrow = isTomorrow(startDate);

  if (isGameYesterday) {
    return t.global_yesterday;
  } else if (isGameToday) {
    return t.global_today;
  } else if (isGameTomorrow) {
    return t.global_tomorrow;
  }

  return format(startDate, 'EEE, MM/dd');
};
