import styled, { css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { FONT_WEIGHT } from '../Common';
import { SelectorSize } from './Selector.types';

interface SelectorButtonProps {
  $size: SelectorSize;
  $selected?: boolean;
}

const smallButtonStyles = css`
  font-size: 0.625rem;
  gap: 0.625rem;
  line-height: 0.875rem;
  min-width: 4.0625rem;
  height: 1.375rem;

  ${breakpoint('lg')`
    gap: 0.5rem;
    min-width: 4.5rem;
    height: 2.5rem;
  `}
`;

const mediumButtonStyles = css`
  min-width: 6.875rem;
  height: 2.5rem;
  font-size: 0.75rem;
  gap: 0.25rem;
  line-height: 1rem;

  ${breakpoint('lg')`
    font-size: 0.875rem;
    gap: 0.5rem;
    line-height: 1.125rem;
    min-width: 7.8125rem;
    width: 7.8125rem;
    height: 3.25rem;
  `}
`;

const mediumWiderButtonStyles = css`
  padding: 0 3rem;
  height: 2.5rem;
  font-size: 0.75rem;
  gap: 0.25rem;
  line-height: 1rem;

  ${breakpoint('lg')`
    font-size: 0.875rem;
    gap: 0.5rem;
    line-height: 1.125rem;
    height: 3.25rem;
  `}
`;

const largeButtonStyles = css`
  font-size: 0.875rem;
  line-height: 1.125rem;
  width: 50%;
  height: 3rem;
  gap: 0.625rem;

  ${breakpoint('lg')`
    gap: 0.75rem;
    line-height: 1.5rem;
    font-size: 1.25rem;
    min-width: 10.5625rem;
    width: 10.5625rem;
    height: 4rem;
  `}
`;

const noSelectedStyles = css`
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors['grey-soft']};
  background: ${({ theme }) => theme.colors['pure-black']};
`;

const selectedStyles = css`
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors['primary']};
  background: ${({ theme }) => theme.colors['grey-dark']};
`;

const disabledStyles = css`
  border-color: ${({ theme }) => theme.colors['grey']};
  color: ${({ theme }) => theme.colors['grey']};
  cursor: not-allowed;
`;

export const SelectorButton = styled.button<SelectorButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  border-radius: 4px;
  border-style: solid;
  color: ${({ theme }) => theme.colors['off-white']};
  font-family: 'GothamNarrow';
  font-weight: ${FONT_WEIGHT.Bold};
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  margin: 0;

  &:hover {
    border-color: ${({ theme }) => theme.colors['primary']};
  }

  &:disabled {
    ${disabledStyles}
  }

  ${({ $selected }) => ($selected ? selectedStyles : noSelectedStyles)}

  ${({ $size }) =>
    ({
      [SelectorSize.Small]: smallButtonStyles,
      [SelectorSize.Medium]: mediumButtonStyles,
      [SelectorSize.MediumWider]: mediumWiderButtonStyles,
      [SelectorSize.Large]: largeButtonStyles,
    }[$size])};
`;
