import { createSelector } from '@reduxjs/toolkit';

import { Video, VideoType } from '../../api';
import {
  PaymentMethod,
  ProductServiceStatusEnum,
  SubscriptionTypeEnum,
} from '../../api/authApi/authApi.types';
import { envSelector } from '../config';
import { entitlementsSelector } from '../entitlements';
import { RootState } from '../types';
import { ActiveSubscriptionState } from './activeSubscriptions.types';

export const activeSubscriptionsSelector = (state: RootState): ActiveSubscriptionState =>
  state.activeSubscriptions;

export const hasActiveSubscriptionsSelector = createSelector(
  activeSubscriptionsSelector,
  ({ hasActiveSubscriptions }) => hasActiveSubscriptions,
);

export const hasTveProviderSelector = createSelector(
  activeSubscriptionsSelector,
  (subscription) => subscription.hasTveProvider,
);

export const lastSubscriptionSelector = createSelector(
  activeSubscriptionsSelector,
  (state) => state.lastSubscription,
);

export const hasSubscriptionWithActiveStatus = createSelector(
  lastSubscriptionSelector,
  (subscription) => subscription?.subscriptionStatus === ProductServiceStatusEnum.ACTIVE,
);

export const currentSubscriptionPaymentMethodSelector = createSelector(
  lastSubscriptionSelector,
  (subscription) => subscription?.paymentMethod,
);

export const subscriptionTypeSelector = createSelector(
  lastSubscriptionSelector,
  (subscription) => subscription?.subscriptionType,
);

export const subscriptionStatusSelector = createSelector(
  lastSubscriptionSelector,
  (subscription) => subscription?.subscriptionStatus,
);

export const loadedActiveSubscriptionsSelector = createSelector(
  activeSubscriptionsSelector,
  (state) => state.loaded,
);

export const isAuthorizedAssetSelector = (video: Video | null) =>
  createSelector(
    entitlementsSelector,
    activeSubscriptionsSelector,
    subscriptionStatusSelector,
    (entitlements, activeSubscriptions, subscriptionStatus) => {
      const isAuthorized =
        (activeSubscriptions.hasActiveSubscriptions &&
          subscriptionStatus !== ProductServiceStatusEnum.PAUSED) ||
        (!!video?.externalId && Boolean(entitlements.ppgItemsIds[video.externalId])) ||
        Boolean(video?.isFree) ||
        Boolean(video?.videoType === VideoType.COLLECTION);

      return isAuthorized;
    },
  );

export const canPauseSubscriptionSelector = createSelector(
  hasSubscriptionWithActiveStatus,
  currentSubscriptionPaymentMethodSelector,
  subscriptionTypeSelector,
  envSelector,
  (isActive, paymentMethod, type, env) => {
    return Boolean(
      env.PAUSE_SUBSCRIPTION_ENABLED &&
        isActive &&
        paymentMethod &&
        type &&
        [PaymentMethod.ApplePay, PaymentMethod.CreditCard, PaymentMethod.PayPal].includes(
          paymentMethod,
        ) &&
        [SubscriptionTypeEnum.MONTHLY, SubscriptionTypeEnum.YEARLY].includes(type),
    );
  },
);

export const isSubscriptionPausedSelector = createSelector(
  subscriptionStatusSelector,
  (status) => status === ProductServiceStatusEnum.PAUSED,
);

export const hasSubscriptionBeenPausedBeforeSelector = createSelector(
  subscriptionStatusSelector,
  (status) => status === ProductServiceStatusEnum.ACTIVE_ALREADY_PAUSED,
);
