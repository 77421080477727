import { SVGProps } from 'react';

import { colors } from '../../components/ThemeProvider/constants/colors';

export const FastForward = (props: SVGProps<SVGSVGElement>) => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.96 5.83224C17.7619 5.2749 20.6662 5.56095 23.3056 6.65421C25.945 7.74748 28.2009 9.59886 29.7881 11.9742C31.3752 14.3496 32.2224 17.1423 32.2224 19.9991C32.2224 20.6128 31.7249 21.1102 31.1113 21.1102C30.4976 21.1102 30.0002 20.6128 30.0002 19.9991C30.0002 17.5818 29.2833 15.2188 27.9404 13.2088C26.5974 11.1989 24.6885 9.63235 22.4552 8.70728C20.2219 7.78221 17.7644 7.54017 15.3935 8.01176C13.0226 8.48336 10.8448 9.64741 9.13553 11.3567C7.42622 13.066 6.26217 15.2438 5.79057 17.6147C5.31897 19.9856 5.56101 22.4431 6.48609 24.6764C7.41116 26.9097 8.97771 28.8185 10.9876 30.1615C12.9976 31.5045 15.3606 32.2214 17.7779 32.2214C18.3916 32.2214 18.8891 32.7188 18.8891 33.3325C18.8891 33.9461 18.3916 34.4436 17.7779 34.4436C14.9211 34.4436 12.1284 33.5964 9.75304 32.0093C7.37767 30.4221 5.52629 28.1662 4.43302 25.5268C3.33975 22.8874 3.05371 19.9831 3.61105 17.1812C4.16839 14.3792 5.54409 11.8055 7.56418 9.78537C9.58427 7.76528 12.158 6.38958 14.96 5.83224Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.7246 17.261C26.0543 16.7434 26.7411 16.5911 27.2587 16.9209L31.2149 19.4412L33.7352 15.485C34.0649 14.9675 34.7518 14.8152 35.2693 15.1449C35.7869 15.4747 35.9391 16.1615 35.6094 16.679L31.8951 22.5094L26.0647 18.7951C25.5471 18.4653 25.3949 17.7785 25.7246 17.261Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
    <path
      d='M12.8224 17.3658L11.4669 17.6991L11.1113 16.2991L13.3447 15.6325H14.5113V23.4658H12.8224V17.3658Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
    <path
      d='M19.5988 22.0547C19.8581 22.0547 20.0914 21.9954 20.2988 21.8769C20.5062 21.751 20.684 21.5806 20.8322 21.3658C20.9803 21.1436 21.0914 20.8843 21.1655 20.588C21.247 20.2843 21.2877 19.9547 21.2877 19.5991V19.5769C21.2877 19.2214 21.247 18.8954 21.1655 18.5991C21.084 18.2954 20.9655 18.0325 20.8099 17.8102C20.6618 17.588 20.4803 17.4139 20.2655 17.288C20.0581 17.1621 19.8285 17.0991 19.5766 17.0991C19.3173 17.0991 19.084 17.1621 18.8766 17.288C18.6692 17.4065 18.4914 17.5769 18.3433 17.7991C18.2025 18.0139 18.0914 18.2732 18.0099 18.5769C17.9285 18.8732 17.8877 19.1991 17.8877 19.5547V19.5769C17.8877 19.9325 17.9285 20.2621 18.0099 20.5658C18.0914 20.8695 18.2062 21.1325 18.3544 21.3547C18.5025 21.5695 18.6803 21.7399 18.8877 21.8658C19.1025 21.9917 19.3396 22.0547 19.5988 22.0547ZM19.5766 23.5991C19.0581 23.5991 18.584 23.4991 18.1544 23.2991C17.7322 23.0917 17.3692 22.8102 17.0655 22.4547C16.7692 22.0917 16.5396 21.6658 16.3766 21.1769C16.2136 20.688 16.1322 20.1621 16.1322 19.5991V19.5769C16.1322 19.0139 16.2136 18.488 16.3766 17.9991C16.547 17.5102 16.784 17.0843 17.0877 16.7214C17.3914 16.3584 17.7544 16.0732 18.1766 15.8658C18.6062 15.6584 19.0803 15.5547 19.5988 15.5547C20.1099 15.5547 20.5766 15.6584 20.9988 15.8658C21.4285 16.0658 21.7914 16.3473 22.0877 16.7102C22.3914 17.0658 22.6248 17.488 22.7877 17.9769C22.9581 18.4658 23.0433 18.9917 23.0433 19.5547V19.5769C23.0433 20.1399 22.9581 20.6658 22.7877 21.1547C22.6248 21.6436 22.3914 22.0695 22.0877 22.4325C21.784 22.7954 21.4173 23.0806 20.9877 23.288C20.5655 23.4954 20.0951 23.5991 19.5766 23.5991Z'
      fill={props?.fill ? props.fill : colors['off-white']}
    />
  </svg>
);
