import { useCallback, useRef } from 'react';
import useSmoothScroll from 'react-smooth-scroll-hook';

import { GC_ALIGN, GenericCarouselProps } from './GenericCarousel.types';

export const useGenericCarousel = ({ stepSize = 100 }: { stepSize?: number } = {}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { reachedBottom, reachedTop, containerSize } = useSmoothScroll({
    direction: 'x',
    ref: scrollRef,
  });

  const scrollTo = useCallback(
    (x: number) => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({ behavior: 'smooth', left: x });
      }
    },
    [scrollRef],
  );

  const onItemFocus = useCallback(
    ({ x }: { x: number }) => {
      scrollTo(x);
    },
    [scrollTo],
  );

  const handleControlBackward = useCallback(() => {
    const newPosition = (scrollRef.current?.scrollLeft || 0) - stepSize;
    scrollTo(newPosition);
  }, [scrollTo, stepSize]);

  const handleControlForward = useCallback(() => {
    const newPosition = (scrollRef.current?.scrollLeft || 0) + stepSize;
    scrollTo(newPosition);
  }, [scrollTo, stepSize]);

  const props: GenericCarouselProps = {
    $align: GC_ALIGN.CENTER,
    $isFullWidth: false,
    containerSize,
    handleControlBackward,
    handleControlForward,
    isBackwardButtonDisabled: reachedTop,
    isForwardButtonDisabled: reachedBottom,
    scrollRef,
  };

  return {
    onItemFocus,
    props,
    scrollTo,
  };
};

export default useGenericCarousel;
