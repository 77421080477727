import { SVGProps } from 'react';

export const Restart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='30' viewBox='0 0 35 30' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.5405 0.83224C19.7386 0.274898 16.8343 0.560946 14.1949 1.65421C11.5555 2.74748 9.2996 4.59886 7.71242 6.97423C6.12525 9.34961 5.2781 12.1423 5.2781 14.9991C5.2781 15.6128 5.77556 16.1102 6.38921 16.1102C7.00286 16.1102 7.50032 15.6128 7.50032 14.9991C7.50032 12.5818 8.21714 10.2188 9.56013 8.20883C10.9031 6.1989 12.812 4.63235 15.0453 3.70728C17.2786 2.78221 19.7361 2.54017 22.107 3.01176C24.4779 3.48336 26.6556 4.64741 28.365 6.35672C30.0743 8.06603 31.2383 10.2438 31.7099 12.6147C32.1815 14.9856 31.9395 17.4431 31.0144 19.6764C30.0893 21.9097 28.5228 23.8185 26.5128 25.1615C24.5029 26.5045 22.1399 27.2214 19.7225 27.2214C19.1089 27.2214 18.6114 27.7188 18.6114 28.3325C18.6114 28.9461 19.1089 29.4436 19.7225 29.4436C22.5794 29.4436 25.3721 28.5964 27.7474 27.0093C30.1228 25.4221 31.9742 23.1662 33.0675 20.5268C34.1607 17.8874 34.4468 14.9831 33.8894 12.1812C33.3321 9.37922 31.9564 6.80547 29.9363 4.78537C27.9162 2.76528 25.3425 1.38958 22.5405 0.83224Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.7759 12.261C11.4462 11.7434 10.7593 11.5911 10.2418 11.9209L6.28563 14.4412L3.76527 10.485C3.43556 9.9675 2.74871 9.81523 2.23117 10.1449C1.71362 10.4747 1.56135 11.1615 1.89107 11.679L5.60542 17.5094L11.4358 13.7951C11.9533 13.4653 12.1056 12.7785 11.7759 12.261Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.723 16.1105C20.3366 16.1105 20.8341 15.613 20.8341 14.9993C20.8341 14.3857 20.3366 13.8882 19.723 13.8882C19.1093 13.8882 18.6119 14.3857 18.6119 14.9993C18.6119 15.613 19.1093 16.1105 19.723 16.1105ZM19.723 18.3327C21.5639 18.3327 23.0563 16.8403 23.0563 14.9993C23.0563 13.1584 21.5639 11.666 19.723 11.666C17.882 11.666 16.3896 13.1584 16.3896 14.9993C16.3896 16.8403 17.882 18.3327 19.723 18.3327Z'
      fill={props.fill || 'white'}
    />
  </svg>
);
