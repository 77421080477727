import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { AppDispatch } from '../store/store';
import { getVideoById, videosSelector } from '../store/videos';

export const useGetPlayerVideo = () => {
  const { selectedVideo } = useSelector(videosSelector);

  const dispatch: AppDispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const contentType = searchParams.get('contentType');

  useEffect(() => {
    if (contentType && id && !selectedVideo) {
      dispatch(getVideoById({ contentType, id }));
    }
  }, []);
};
