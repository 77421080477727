import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { fonts } from '../../../Common';

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 1.35rem 0.625rem;
  gap: 0.5rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors['grey']}`};
  svg {
    transform: scale(0.65);
  }

  &:hover {
    background: ${({ theme }) => `${theme.colors['white-scrim']}`};
  }

  ${breakpoint('lg')`
    padding: 1.75rem 2rem;
    gap: 1.5rem;

    svg {
        transform: scale(1);
      }
    
  `}
`;

export const Name = styled.p`
  ${fonts.body2.xs}
  color: ${({ theme }) => theme.colors['off-white']};
  margin-bottom: 0;

  ${breakpoint('lg')`
    ${fonts.body2.xl}
`}
`;
