import { ReactNode } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { TranslationsContext } from './context';

export const TranslationsProvider = ({ children }: { children: ReactNode }) => {
  const t = useTranslations();

  return <TranslationsContext.Provider value={t}>{children}</TranslationsContext.Provider>;
};
