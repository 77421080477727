import { ServiceType } from '../../api/authApi/authApi.types';
import {
  AddSubscriptionRequestMessage,
  AddTVODOrderRequestMessage,
  ApplePayPayload,
  ApplePayTVODPayload,
  PayPalPayload,
  PayPalTVODPayload,
} from './purchase.types';

export const createPayPalPayload = (payload: PayPalPayload): AddSubscriptionRequestMessage => ({
  accessToken: payload.accessToken,
  couponCode: payload.voucherCode,
  dmaID: payload.zone,
  paymentmethodInfo: {
    label: 'PayPalAccount',
    paypalAccountMsg: {
      billingAddress: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        zipCode: payload.zipCode,
      },
      email: payload.formValues.email,
      payerID: payload.onApproveData.payerID as string,
      token: payload.onApproveData.orderID,
    },
  },
  service: [
    {
      quantity: 1,
      serviceId: payload.product.skuORQuickCode,
      serviceType: ServiceType.Product,
    },
  ],
});

export const createPayPalTVODPayload = (
  payload: PayPalTVODPayload,
): AddTVODOrderRequestMessage => ({
  accessToken: payload.accessToken,
  assetType: payload.assetType,
  couponCode: payload.voucherCode,
  dmaID: payload.zone,
  paymentmethodInfo: {
    label: 'PayPalAccount',
    paypalAccountMsg: {
      billingAddress: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        state: '',
        zipCode: payload.zipCode,
      },
      email: payload.formValues.email,
      payerID: payload.onApproveData.payerID as string,
      token: payload.onApproveData.orderID,
    },
  },
  price: payload.price,
  purchaseToken: payload.purchaseToken,
});

export const createApplePayTVODPayload = (
  payload: ApplePayTVODPayload,
): AddTVODOrderRequestMessage => {
  return {
    accessToken: payload.accessToken,
    assetType: payload.assetType,
    couponCode: payload.voucherCode,
    dmaID: payload.zone,
    paymentmethodInfo: {
      applePayMessage: {
        paymentInterface: 'Square',
        subscriptionId: payload.txRefNo,
        txRefNo: payload.txRefNo,
        xid: 'ApplePay',
      },
      billingAddress: {
        zipCode: payload.zipCode,
      },
      label: 'ApplePay',
      makeAutoPayment: true,
    },
    purchaseToken: payload.purchaseToken,
  };
};

export const createApplePayPayload = (payload: ApplePayPayload): AddSubscriptionRequestMessage => ({
  accessToken: payload.accessToken,
  couponCode: payload.voucherCode,
  dmaID: payload.zone,
  paymentmethodInfo: {
    applePayMessage: {
      paymentInterface: 'Square',
      subscriptionId: payload.txRefNo,
      txRefNo: payload.txRefNo,
      xid: 'ApplePay',
    },
    billingAddress: {
      zipCode: payload.zipCode,
    },
    label: 'ApplePay',
    makeAutoPayment: true,
  },
  service: [
    {
      quantity: 1,
      serviceId: payload.product.skuORQuickCode,
      serviceType: ServiceType.Product,
    },
  ],
});
