import { createSlice } from '@reduxjs/toolkit';

import localTranslations from '../../translations/en.json';
import { fetchTranslation } from './translations.thunks';
import { ITranslation } from './translations.types';

const initialState: ITranslation = {
  data: localTranslations,
  isLoading: true,
};

const translationSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchTranslation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTranslation.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          ...action.payload,
        };
        state.isLoading = false;
      })
      .addCase(fetchTranslation.rejected, (state) => {
        state.isLoading = false;
      });
  },
  initialState,
  name: 'translations',
  reducers: {},
});

const { reducer } = translationSlice;

export { initialState, reducer };
