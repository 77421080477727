import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { isActionRequiredSelector } from '../../store/config';
import { cacheManager } from '../../utils';
import { useSimpleModalContext } from '../Modals/SimpleModal/SimpleModalContext';
import * as Ui from './ActionRequiredModal.styles';

export const useActionRequiredModal = (cacheKey: string) => {
  const isActionRequired = useSelector(isActionRequiredSelector);
  const t = useTranslations();
  const navigate = useExtendedNavigate();
  const { openModal } = useSimpleModalContext();

  useEffect(() => {
    if (!isActionRequired) {
      return;
    }

    if (cacheManager.load<boolean>(cacheKey) === true) {
      return;
    }

    openModal({
      acceptButton: {
        onClick: () => {
          cacheManager.save(cacheKey, true);
          navigate(0);
        },
        text: t.confirm,
      },
      description: (
        <>
          <Ui.Text>
            Dear user, we have recently released an updated version of our MSG+ app. However, if you
            are unable to update the app through the force update option, please follow these steps
            to update the app manually:
          </Ui.Text>

          <Ui.OrderedList>
            <Ui.ListItem>Open the App Store or Google Play Store on your device.</Ui.ListItem>
            <Ui.ListItem>Search for our app in the search bar.</Ui.ListItem>
            <Ui.ListItem>
              If an update is available, you will see an &apos;Update&apos; button next to the app
              name.
            </Ui.ListItem>
            <Ui.ListItem>
              Click on the &apos;Update&apos; button to download and install the latest version of
              the app.
            </Ui.ListItem>
          </Ui.OrderedList>

          <Ui.Text>
            If you have any questions or concerns, please feel free to contact us at{' '}
            <Ui.Link href='mailto:msg@emailsupport.com'>msg@emailsupport.com</Ui.Link>. Thank you
            for using our MSG+ app!
          </Ui.Text>
        </>
      ),
      modalName: 'Outdated app version',
      title: t.actionRequired,
    });
  }, [cacheKey]);
};
