import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../store/store';
import { getTeams, teamsSelector } from '../../store/teams';
import { zoneInfoStateSelector } from '../../store/zoneInfo';

export const useTeamsListState = (callGetTeams = true) => {
  const dispatch: AppDispatch = useDispatch();
  const teamsListState = useSelector(teamsSelector);
  const { zoneKey } = useSelector(zoneInfoStateSelector);

  useEffect(() => {
    if (callGetTeams) {
      dispatch(getTeams());
    }
  }, [zoneKey]);

  return teamsListState;
};

export default useTeamsListState;
