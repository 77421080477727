import { NavigateOptions } from 'react-router';

import { Log } from '../../helpers/CustomLogger';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { UseFlowManagerHandlerOptions, UseFlowManagerResult } from '../flow.types';
import flowManager from '../flowManager';
import { flows } from '../flows';

export const useFlowManager = (): UseFlowManagerResult => {
  const navigate = useExtendedNavigate();
  const defaultNavigateOptions = {
    replace: true,
  };

  const navigateTo = (params?: UseFlowManagerHandlerOptions) => {
    return params?.to
      ? navigate(params.to, params.navigateOptions ?? defaultNavigateOptions)
      : Log.warn('Please, specify navigate path');
  };

  return {
    exit: (pathname?: string, navigateOptions?: NavigateOptions) => {
      if (flowManager.isInitialized()) {
        flowManager.exit();
      }

      pathname && navigate(pathname, navigateOptions);
    },
    flowId: flowManager.flowId,
    goToStep: (pathname: string, options?: NavigateOptions) => {
      if (flowManager.isInitialized()) {
        flowManager.goToStep(pathname, (currentStep) => {
          navigate(currentStep.pathname, options);
        });
      }
    },
    initFlow: (flowId: string) => {
      if (flowManager.flowId === flowId) return;

      flowManager.init(flowId, flows);
    },
    isFlowInitialized: () => flowManager.isInitialized(),
    next: (params) => {
      if (!flowManager.isInitialized()) {
        return navigateTo(params);
      }

      if (flowManager.isLastStep()) {
        flowManager.exit();
        return navigateTo(params);
      }

      return flowManager.next((currentStep) => {
        navigate(currentStep.pathname, params?.navigateOptions ?? defaultNavigateOptions);
      });
    },
    previous: (params) => {
      if (flowManager.isInitialized()) {
        flowManager.previous((currentStep) => {
          navigate(currentStep.pathname, params?.navigateOptions ?? defaultNavigateOptions);
        });
      }
    },
    updateFlowManager: (pathname) => {
      if (flowManager.isOneOfPreviousSteps(pathname)) {
        flowManager.goToStep(pathname);
      }
    },
  };
};
