import { css } from 'styled-components';

import bgBlueSrc from '../../assets/images/minimized/bg_blue_1920.jpg';

export const transparentButton = css`
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const noScrollbarsForOverflowElements = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const bgBlue = css`
  background-color: ${({ theme }) => theme.colors.bgFallbackColor};
  background-image: url(${bgBlueSrc});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`;
