import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { defaultEnvs } from '../../components/EnvProvider/EnvProvider';
import { ConfigAppState } from './config.types';

export const configAppAction = createAction('config/changeConfig');

export const initialState: ConfigAppState = {
  autoHideHeader: false,
  env: defaultEnvs,
  showHeader: true,
  startedDate: new Date().getTime(),
  teams: [],
};

const slice = createSlice({
  initialState,
  name: 'appConfig',
  reducers: {
    setConfigAppSuccess: (
      state: ConfigAppState,
      action: PayloadAction<Partial<ConfigAppState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as configActions, name, reducer };
