import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { DeviceInfo } from '../../api/authApi/authApi.types';
import { DeviceInfoState } from './deviceInfo.types';

export const deviceInfoLoadAction = createAction('deviceInfo/load');

export const initialState: DeviceInfoState = {
  deviceId: '',
  deviceModelNo: '',
  deviceName: '',
  deviceType: '',
  deviceUserAgent: '',
  isAndroid: false,
  isIOS: false,
  isLoaded: false,
  isTTSEnabled: false,
  isWeb: false,
  version: '',
};

const slice = createSlice({
  initialState,
  name: 'deviceInfo',
  reducers: {
    loadError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      isLoaded: true,
    }),
    loadSetIsLoaded: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoaded: action.payload,
    }),
    loadSuccess: (state: DeviceInfoState, action: PayloadAction<DeviceInfo>) => ({
      ...state,
      ...action.payload,
      isLoaded: true,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as deviceInfoActions, name, reducer };
