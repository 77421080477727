import styled from 'styled-components';

import AppLogo from '../../components/Logo/Logo';
import { breakpoint } from '../../utils/breakpoint';
import { BackButton } from '../BackButton/BackButton';
import { Container, FONT_WEIGHT } from '../Common';
import { LegalLink as AppLegalLink } from '../LegalLink/LegalLink';

export const ContentContainer = styled(Container)`
  padding-top: 6.25rem;
  padding-bottom: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div:last-child {
    flex: 1;
  }

  ${breakpoint('md')`
    padding-top: 5rem;
  `}

  ${breakpoint('xl')`
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
  `}
`;

export const StyledBackButton = styled(BackButton)`
  top: 1.25rem;
  left: 0;
`;

export const Logo = styled(AppLogo)`
  width: 12.125rem;
  margin: 0 auto 1.5rem auto;

  img {
    width: 100%;
  }

  ${breakpoint('md')`
    width: 11rem;
    margin-bottom: 2.5rem;
  `}

  ${breakpoint('xl')`
    width: 16.5rem;
    margin-bottom: 7.5rem;
  `}
`;

export const TermsAndConditions = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${FONT_WEIGHT.Regular};
  text-align: center;
  padding: 0 1.88rem 2.5rem 1.88rem;
`;

export const LegalLink = styled(AppLegalLink)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: ${FONT_WEIGHT.Bold};
  font-style: normal;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors['pure-white']};
  text-align: center;
  margin: 0;
  padding: 0 0 1.5rem 0;

  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.5rem;
  text-transform: uppercase;

  ${breakpoint('lg')`
    font-size: 2.5rem;
    font-style: normal;
    line-height: 3rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  `}
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors['pure-white']};
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.25rem;

  ${breakpoint('lg')`
    font-size: 1.25rem;
    font-style: normal;
    font-weight: ${FONT_WEIGHT.Regular};;
    line-height: 1.5rem;
  `}
`;
