import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { ROUTES, RouteName } from '../../Constants/routes';
import { getUtmParams } from '../../hooks/mParticle/useGetUtmParams';
import { getEntryPage } from '../../helpers/getEntryPage';
import { getRedirectPathForPattern } from '../../helpers/getRedirectPath';
import { isAuthedSelector } from '../../store/auth';

const PrivateRoute = ({
  children,
  path,
  ignoreOptionalRoute = false,
  redirectTo,
}: {
  children: ReactJSXElement;
  path: string;
  ignoreOptionalRoute?: boolean;
  redirectTo?: string;
}) => {
  const authed = useSelector(isAuthedSelector);
  const entryPage = getEntryPage();
  const [entryPageWithNoQueryParams, entryPageQueryParams] = entryPage.split('?');

  if (authed) return children;

  const redirectToFromEntryPage = getRedirectPathForPattern(
    path,
    redirectTo || entryPageWithNoQueryParams,
    ignoreOptionalRoute,
  );

  const utmQuery = getUtmParams(entryPageQueryParams);
  const welcomePageUrl = `${ROUTES[RouteName.Welcome]}${utmQuery ? `?${utmQuery}` : ''}`;

  if (redirectToFromEntryPage) {
    const resultRoute = redirectTo ? redirectToFromEntryPage : entryPage;
    return <Navigate to={welcomePageUrl} state={{ redirectTo: resultRoute }} />;
  }

  return <Navigate to={welcomePageUrl} />;
};

export default PrivateRoute;
