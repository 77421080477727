import { AUTH_SESSION_STORAGE_KEY, SessionType } from '../../../store/auth';
import { cacheManager } from '../../../utils';
import { LogOutUserResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const logOutAllDevices = async ({
  evergentApiKey,
  evergentChannelPartnerId,
  logOutAllDevicesUrl,
}: {
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  logOutAllDevicesUrl: string;
}): Promise<LogOutUserResponse> => {
  const logOutData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const data = { LogoutAllDevicesRequestMessage: logOutData };

  const { accessToken } = (await cacheManager.load<SessionType>(AUTH_SESSION_STORAGE_KEY)) || {};

  const response = await evergentAxiosInstance.post<{
    LogoutAllDevicesResponseMessage: LogOutUserResponse;
  }>(logOutAllDevicesUrl, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const dataResponse = response.data.LogoutAllDevicesResponseMessage;

  return {
    failureMessage: dataResponse?.failureMessage,
    message: dataResponse.message,
    responseCode: dataResponse.responseCode,
  };
};
