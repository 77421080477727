import { useSelector } from 'react-redux';

import { PlayerState } from '../../api';
import { Pause, Play } from '../../assets/icons';
import { playerStateSelector } from '../../store/player';
import { usePlayerControlsContext } from '../PlayerControls/PlayerControlsProvider';
import { colors } from '../ThemeProvider/constants/colors';
import * as Ui from './PlayerUi.styles';

export default function PlayPauseButton() {
  const { handlePlay, handlePause } = usePlayerControlsContext();
  const playerState = useSelector(playerStateSelector);

  const handleClick = () => {
    if (playerState === PlayerState.PAUSED) {
      handlePlay();
    } else {
      handlePause();
    }
  };

  return (
    <Ui.PlayerButton
      aria-label={playerState === PlayerState.PAUSED ? 'play' : 'pause'}
      onClick={handleClick}
    >
      {playerState === PlayerState.PAUSED ? (
        <Play width={32} height={32} stroke={colors['off-white']} />
      ) : (
        <Pause stroke={colors['off-white']} />
      )}
    </Ui.PlayerButton>
  );
}
