import { createSelector } from '@reduxjs/toolkit';
import { getMonth } from 'date-fns';
import { uniq } from 'lodash-es';

import { RootState } from '../types';

export const scheduleSelector = (state: RootState) => state.schedule;

export const scheduleChannelsSelector = createSelector(
  scheduleSelector,
  ({ channels }) => channels,
);

export const scheduleGamesSelector = createSelector(scheduleSelector, ({ games }) => games);

export const monthsHavingGamesSelector = createSelector(scheduleGamesSelector, (games) =>
  uniq(games.map(({ startDate }) => getMonth(new Date(startDate)))),
);

export const monthsWithoutGamesSelector = createSelector(
  monthsHavingGamesSelector,
  (monthsWithGames) => {
    const allMonths = Array.from(Array(12).keys());

    return allMonths.filter((x) => !monthsWithGames.includes(x));
  },
);
