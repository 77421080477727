import styled from 'styled-components';

import { Button } from '../Button/Button';

const Link = styled(Button)`
  color: white;
  display: block;
  font-size: 1rem;
  font-family: 'GothamNarrow';
  font-weight: 900;
  font-style: normal;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors['grey-light']};
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors['grey-light']};
  background-color: transparent;
  padding: 0.938rem 0 0.938rem 0;
  border-radius: 0;
  height: 3rem;
  margin-top: 1rem;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors['grey-light']};
    background-color: transparent !important;
    border: 0;
    outline: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors['grey-light']};
  }
`;

export default Link;
