import { SVGProps } from 'react';

export const InfoErrorSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 2.66732C7.34532 2.66732 3.16665 6.84599 3.16665 12.0007C3.16665 17.1553 7.34532 21.334 12.5 21.334C17.6546 21.334 21.8333 17.1553 21.8333 12.0007C21.8333 6.84599 17.6546 2.66732 12.5 2.66732ZM1.83331 12.0007C1.83331 6.10961 6.60894 1.33398 12.5 1.33398C18.391 1.33398 23.1666 6.10961 23.1666 12.0007C23.1666 17.8917 18.391 22.6673 12.5 22.6673C6.60894 22.6673 1.83331 17.8917 1.83331 12.0007Z'
      fill={props?.fill ? props.fill : '#FFB800'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 8.66732H12C11.6318 8.66732 11.3333 8.36884 11.3333 8.00065C11.3333 7.63246 11.6318 7.33398 12 7.33398L13 7.33398C13.3682 7.33398 13.6666 7.63246 13.6666 8.00065C13.6666 8.36884 13.3682 8.66732 13 8.66732ZM12.5 10.334C12.8682 10.334 13.1666 10.6325 13.1666 11.0007V16.0006C13.1666 16.3688 12.8682 16.6673 12.5 16.6673C12.1318 16.6673 11.8333 16.3688 11.8333 16.0006V11.0007C11.8333 10.6325 12.1318 10.334 12.5 10.334Z'
      fill={props?.fill ? props.fill : '#FFB800'}
    />
  </svg>
);
