export const ADOBE_AUTHORIZATION_TOKEN = 'adobe-authorization-token';
export const ADOBE_AUTHORIZATION_RESPONSE = 'adobe-authorization-response';
export const ADOBE_TOKENS_AUTHZ_RESPONSE = 'adobe-tokens-authz-response';

export const ADOBE_ERRORS_CODES = [412, 410, 404, 403, 401];

export type AdobeAuthorizationResponse = {
  ttl: number;
  mvpd: string;
  requestor: string;
  resource: string;
  expires: string;
};

export type AdobeTokensAuthzResponse = {
  mvpd: string;
  requestor: string;
  resource: string;
  expires: string;
};

export type GetMediaShortTokenResponse = {
  serializedToken: string;
  userId: string;
  mvpdId: string;
  requestor: string;
  resource: string;
  expires: string;
};
