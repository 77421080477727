import { format } from 'date-fns';

import { Video } from '../../../api';
import { useTranslations } from '../../../hooks/useTranslations';
import * as Ui from '../PurchaseCommon.styles';

const PurchaseSummary = ({
  changeSelection,
  imageUrl,
  displayPPGSummary,
  purchaseVideo,
}: {
  imageUrl: string;
  changeSelection: () => void;
  displayPPGSummary?: boolean;
  purchaseVideo: Video | null;
}) => {
  const t = useTranslations();

  if (!displayPPGSummary) {
    return null;
  }

  return (
    <Ui.SummaryCol>
      <Ui.SummaryBox>
        <Ui.SummaryTitle>{t.purchaseSummary_title}</Ui.SummaryTitle>
        {displayPPGSummary && purchaseVideo && (
          <Ui.SummaryContentWrapper>
            {imageUrl ? (
              <Ui.SummaryPPGImage
                src={imageUrl}
                alt={purchaseVideo?.id}
                onError={({ currentTarget }) => {
                  // does not display image if image source is not available
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.style.display = 'none';
                }}
              />
            ) : null}
            <Ui.SummaryDescriptionWrapper>
              <Ui.SummaryPPGTitle>{purchaseVideo?.title}</Ui.SummaryPPGTitle>
              <Ui.SummaryPPGEventDate>
                <Ui.SummaryPPGEventDateLabel>
                  {t.purchaseModal_summaryEventDate}
                </Ui.SummaryPPGEventDateLabel>
                <Ui.SummaryPPGEventDateValue>
                  {format(new Date(purchaseVideo?.startDate), 'MM/dd/yyyy')}
                </Ui.SummaryPPGEventDateValue>
              </Ui.SummaryPPGEventDate>
            </Ui.SummaryDescriptionWrapper>
          </Ui.SummaryContentWrapper>
        )}
        {!displayPPGSummary && (
          <Ui.SummaryLink onClick={changeSelection}>
            {t.purchaseModal_changeSelection}
          </Ui.SummaryLink>
        )}
      </Ui.SummaryBox>
    </Ui.SummaryCol>
  );
};

export { PurchaseSummary };
