import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import { FONT_WEIGHT, TypographyProps, bolder, fonts, textBase } from '../Common';

export const MessageContainer = styled.p<TypographyProps>`
  ${textBase}
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1.5625rem;
`;

export const Footer = styled(Modal.Footer).attrs({ className: 'border-0' })`
  padding: 1.5625rem 0 0 0;
`;

export const FooterText = styled.span<TypographyProps>`
  ${textBase}
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
`;

export const ChangeZipCodeButton = styled(Button).attrs({ className: 'w-100' })`
  margin-bottom: 1.5625rem;
`;

export const CancelButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CancelButton = styled(Button)`
  font-family: 'GothamNarrow';
  font-weight: ${FONT_WEIGHT.Bold};
  font-style: normal;
  background-color: transparent;
  color: ${({ theme }) => theme.colors['grey-light']};
  flex-direction: column;

  &:hover {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors['grey-light']};
  }
`;

export const ZipCodeInputLabel = styled.label<TypographyProps>`
  ${textBase}
  ${fonts.body3.xl}
  margin-bottom: 0.75rem;
`;

export const ZipCodeInfo = styled.p<TypographyProps>`
  ${textBase}
  ${fonts.body2.xl}
  ${bolder}
  margin-bottom: 1.5rem;
`;

export const ZipCodeInputWrapper = styled.div`
  margin-bottom: 1.5625rem;
`;

export const UnderlineCancel = styled.div`
  border-bottom: 1px solid #f2f2f4;
  width: 100%;
`;
