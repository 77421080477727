import { useEffect, useState } from 'react';

import { TeamsConfig } from '../api/remoteConfigApi/remoteConfigApi.types';
import { defaultEnvs } from '../components/EnvProvider/EnvProvider';
import { setupEnv } from '../env';
import configManager from '../services/config/ConfigManager';

export const useAppInit = () => {
  const [appReady, setAppReady] = useState(false);
  const [env, setEnv] = useState(defaultEnvs);
  const [teams, setTeams] = useState<TeamsConfig[]>([]);

  const checkAppReady = () => {
    const initInterval = setInterval(() => {
      if (configManager.remoteConfigFetchedTrial) {
        const envs = configManager?.remoteConfig
          ? setupEnv(configManager.remoteConfig)
          : defaultEnvs;
        const teamsInConfig = configManager?.remoteConfig?.teams;

        setEnv(envs);
        setTeams(teamsInConfig || []);
        setAppReady(true);
        clearInterval(initInterval);
      }
    }, 100);
  };

  useEffect(() => {
    checkAppReady();
  }, []);

  return { appReady, env, teams };
};
