import {
  addOrRemoveFavorites,
  addOrUpdateCouchRights,
  addSubscriptions,
  addTVODOrder,
  anonymizeAccount,
  changeEmailAddress,
  changePassword,
  disconnectTVEAccount,
  forgotContactPassword,
  getActivationCode,
  getActiveSubscriptions,
  getAdobeAccessToken,
  getContact,
  getCouchRights,
  getEntitlements,
  getFavoriteTeams,
  getPaymentHistory,
  getProducts,
  getProrate,
  getString,
  getSubscriptionHistory,
  getVoucherCode,
  logOutAllDevices,
  login,
  logout,
  pauseSubscription,
  paypalSetExpressCheckout,
  refreshToken,
  registerDevice,
  removeSubscription,
  resetPassword,
  resumeSubscription,
  searchAccountV2,
  setString,
  signUp,
} from './endpoints';

const buildEvergentAuthApi = () => {
  return {
    addOrRemoveFavorites,
    addOrUpdateCouchRights,
    addSubscriptions,
    addTVODOrder,
    anonymizeAccount,
    changeEmailAddress,
    changePassword,
    disconnectTVEAccount,
    forgotContactPassword,
    getActivationCode,
    getActiveSubscriptions,
    getAdobeAccessToken,
    getContact,
    getCouchRights,
    getEntitlements,
    getFavoriteTeams,
    getPaymentHistory,
    getProducts,
    getProrate,
    getString,
    getSubscriptionHistory,
    getVoucherCode,
    logOutAllDevices,
    login,
    logout,
    pauseSubscription,
    paypalSetExpressCheckout,
    refreshToken,
    registerDevice,
    removeSubscription,
    resetPassword,
    resumeSubscription,
    searchAccountV2,
    setString,
    signUp,
  };
};

const evergentAuthApi = buildEvergentAuthApi();

export default evergentAuthApi;
