import * as Ui from './NoProgramError.styles';

type NoProgramErrorProps = {
  /**
   * Title string
   */
  title?: string;
  /**
   * Description string
   */
  description?: string;
  playerMaxHeight: string;
};

export const NoProgramError = ({
  title,
  description,
  playerMaxHeight,
  ...rest
}: NoProgramErrorProps) => {
  return (
    <Ui.Wrapper $maxHeight={playerMaxHeight} {...rest}>
      <Ui.ErrorWrapperInner>
        <Ui.Icon />
        <Ui.TextWrapper>
          {title && <Ui.ErrorTitle>{title}</Ui.ErrorTitle>}
          {description && <Ui.ErrorDescription>{description}</Ui.ErrorDescription>}
        </Ui.TextWrapper>
      </Ui.ErrorWrapperInner>
    </Ui.Wrapper>
  );
};
