import { colors } from '../../components/ThemeProvider/constants/colors';

interface Props {
  fill?: string;
}

export const ChevronRight = ({ fill = colors['pure-white'] }: Props) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.58324 6.47936C9.29573 6.70937 9.24911 7.1289 9.47912 7.41641L13.1459 11.9999L9.47912 16.5835C9.24911 16.871 9.29573 17.2905 9.58324 17.5205C9.87074 17.7505 10.2903 17.7039 10.5203 17.4164L14.5203 12.4164C14.7151 12.1729 14.7151 11.827 14.5203 11.5835L10.5203 6.58348C10.2903 6.29597 9.87074 6.24936 9.58324 6.47936Z'
        fill={fill}
      />
    </svg>
  );
};
