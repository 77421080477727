import { SVGProps } from 'react';

import { colors } from '../../../components/ThemeProvider/constants/colors';

interface Props extends SVGProps<SVGSVGElement> {
  active: boolean;
}

export const Teams = ({ active, ...rest }: Props) => {
  if (active) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.98196 3.70422C6.0974 3.48385 6.3283 3.3335 6.59432 3.3335H9.23689C9.95013 3.3335 10.3896 3.90121 10.5263 4.38006C10.6912 4.95829 11.2241 5.38062 11.854 5.38062C12.4608 5.38062 12.9779 4.9886 13.1623 4.44209C13.3289 3.94879 13.7995 3.3335 14.5677 3.3335H17.2121C17.4782 3.3335 17.7096 3.48385 17.8251 3.70421L22.3354 9.60382L18.2598 12.7197L17.9036 12.2537V19.3335C17.9036 20.0699 17.3066 20.6668 16.5702 20.6668H7.23681C6.50043 20.6668 5.90347 20.0699 5.90347 19.3335V12.2537L5.54719 12.7198L1.47156 9.60388L5.98196 3.70422ZM19.0696 13.7789L23.1452 10.6631L23.4059 11.004C23.8532 11.589 23.7415 12.4258 23.1565 12.8731L21.1993 14.3694C20.6143 14.8166 19.7775 14.7049 19.3303 14.1199L19.0696 13.7789ZM0.661756 10.6631L0.401116 11.004C-0.0461293 11.589 0.0655468 12.4258 0.650548 12.8731L2.60769 14.3694C3.1927 14.8166 4.0295 14.7049 4.47674 14.1199L4.73738 13.779L0.661756 10.6631ZM17.9035 16.6668H5.90348V18.0002H17.9035V16.6668Z'
          fill={rest.fill || colors.primary}
        />
      </svg>
    );
  }

  return (
    <svg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.92633 1.66671L1.46231 8.81372L3.41946 10.31L4.84619 8.44381C5.62064 7.43081 7.23876 7.97849 7.23876 9.25362V16.3334H16.5722V9.25362C16.5722 7.97849 18.1903 7.43082 18.9647 8.44381L20.3915 10.31L22.3486 8.81372L16.8846 1.66671H14.5714C14.5665 1.66842 14.555 1.67415 14.5365 1.69092C14.4982 1.72558 14.4547 1.78813 14.4276 1.86843C14.0657 2.94048 13.052 3.71383 11.8559 3.71383C10.6149 3.71383 9.57008 2.8814 9.24605 1.74579C9.24039 1.72596 9.23206 1.70695 9.22224 1.69061C9.21604 1.6803 9.21009 1.67242 9.20513 1.66671H6.92633ZM5.98391 0.704093C6.09935 0.483729 6.33025 0.333374 6.59627 0.333374H9.23884C9.95209 0.333374 10.3916 0.901092 10.5282 1.37994C10.6932 1.95817 11.2261 2.3805 11.8559 2.3805C12.4628 2.3805 12.9798 1.98848 13.1643 1.44197C13.3308 0.948669 13.8015 0.333374 14.5697 0.333374H17.2141C17.4801 0.333374 17.7116 0.483728 17.827 0.70409L23.4079 8.00392C23.8551 8.58892 23.7434 9.42572 23.1584 9.87296L21.2013 11.3692C20.6163 11.8165 19.7795 11.7048 19.3322 11.1198L17.9055 9.25362V16.3334C17.9055 17.0698 17.3086 17.6667 16.5722 17.6667H7.23876C6.50238 17.6667 5.90543 17.0698 5.90543 16.3334V9.25362L4.4787 11.1198C4.03145 11.7048 3.19465 11.8165 2.60965 11.3692L0.652501 9.87296C0.0674999 9.42572 -0.0441762 8.58892 0.403069 8.00392L5.98391 0.704093Z'
        fill={rest.fill || colors['pure-white']}
      />
      <path
        d='M0.667969 7.66663L4.71129 10.7578L5.5211 9.69858L1.47778 6.60739L0.667969 7.66663Z'
        fill={rest.fill || colors['pure-white']}
      />
      <path
        d='M23.1211 7.68298L19.0815 10.7713L18.2717 9.71206L22.3113 6.62374L23.1211 7.68298Z'
        fill={rest.fill || colors['pure-white']}
      />
      <path d='M5.90625 13.6667H17.9062V15.0001H5.90625V13.6667Z' fill='#626266' />
    </svg>
  );
};
