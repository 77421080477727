import { FailureResponse, GetContactResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getContact = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  getContactUrl,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  getContactUrl: string;
}): Promise<GetContactResponse> => {
  const data = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const headers = {
    authorization: `Bearer ${accessToken}`,
  };

  const body = { GetContactRequestMessage: data };

  const response = await evergentAxiosInstance.post<{
    GetContactResponseMessage: GetContactResponse & FailureResponse;
  }>(getContactUrl, body, { headers });

  const { failureMessage, ...dataResponse } = response.data.GetContactResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
