import * as Bowser from 'bowser';

const SUPPORTED_PLATFORMS = ['mobile', 'tablet', 'desktop'];

export const getDeviceType = (): string | undefined => {
  const { platform } = Bowser.parse(window.navigator.userAgent);

  if (platform.type && SUPPORTED_PLATFORMS.includes(platform.type)) {
    return platform.type;
  }

  return undefined;
};
