import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { AdSizeName } from '../CompanionAds.types';

export const useCompanionAdImageSize = (areStatsVisible: boolean) => {
  const isNotMobile = useMediaQuery('sm');

  if (isNotMobile) {
    return AdSizeName.LARGE;
  }

  if (areStatsVisible) {
    return AdSizeName.SMALL;
  }

  return AdSizeName.MEDIUM;
};
