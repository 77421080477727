import styled from 'styled-components';

import { FONT_WEIGHT } from '../Common';

const PLAY_BUTTON_WRAPPER_WIDTH = 200;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PlayerTitle = styled.h3`
  text-align: center;
  width: 100%;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors['pure-white']};
  text-transform: uppercase;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const PlayPauseButtonWrapper = styled(ButtonsWrapper)`
  width: ${PLAY_BUTTON_WRAPPER_WIDTH}px;
  position: absolute;
  justify-content: center;
  left: calc(50% - ${PLAY_BUTTON_WRAPPER_WIDTH / 2}px);
  gap: 1.5rem;
`;
