import styled from 'styled-components';

import { FONT_WEIGHT } from '../../Common';

export const SummaryTitle = styled.div`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.5rem;
`;

export const SummaryRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const SummaryLink = styled.div`
  padding: 0.75rem 0;
  font-weight: ${FONT_WEIGHT.Bold};
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 0.75rem;
  white-space: nowrap;
`;

export const SummaryLabel = styled.div`
  color: ${({ theme }) => theme.colors.black};
  flex: 1;
  font-size: 1rem;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: 1.25rem;
`;

export const TotalSummaryLabel = styled(SummaryLabel)`
  font-weight: ${FONT_WEIGHT.Bold};
  text-transform: uppercase;
`;

export const SummaryValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT.Bold};
  line-height: 1.25rem;
`;
