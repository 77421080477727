import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Log } from '../../helpers/CustomLogger';
import { getTranslationsEnv } from '../../helpers/translations';
import { RootState } from '../types';
import { ITranslation } from './translations.types';

export const translationsLoadAction = createAction<ITranslation>('translations/fetchTranslation');
export const fetchTranslation = createAsyncThunk(
  translationsLoadAction.type,
  async (payload, thunkAPI) => {
    try {
      const {
        appConfig: {
          env: { TRANSLATIONS_URL, TRANSLATIONS_API_KEY, LANGUAGE },
        },
      } = thunkAPI.getState() as RootState;

      const response = await axios.get(TRANSLATIONS_URL, {
        headers: {
          'x-api-key': TRANSLATIONS_API_KEY,
        },
        params: {
          environment: getTranslationsEnv(),
          language: LANGUAGE,
          platform: 'web',
        },
      });
      return response.data;
    } catch (error: unknown) {
      // If there is a fetch translations issue,
      // just the local dictionary will be used.
      // TODO: Should be reported.
      Log.error('translationsLoadAction', error);
      return thunkAPI.rejectWithValue(null);
    }
  },
);
