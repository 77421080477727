import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';
import { Selector } from '../../../Selector/Selector';

export const ChannelItemButton = styled(Selector)`
  width: 5.25rem;

  ${breakpoint('lg')`
    width: 10.5625rem;
  `}

  img {
    width: 3.75rem;
    height: auto;

    ${breakpoint('lg')`
      width: 9.0625rem;
    `}
  }
`;
