import { SVGProps } from 'react';

import { colors } from '../../../components/ThemeProvider/constants/colors';

interface Props extends SVGProps<SVGSVGElement> {
  active: boolean;
}

export const Schedule = (props: Props) => {
  if (props.active) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M3.33398 10H20.6673V19.3333C20.6673 20.0697 20.0704 20.6667 19.334 20.6667H4.66732C3.93094 20.6667 3.33398 20.0697 3.33398 19.3333V10Z'
          fill={props.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.66602 9.3335H21.3327V19.3335C21.3327 20.4381 20.4373 21.3335 19.3327 21.3335H4.66602C3.56145 21.3335 2.66602 20.4381 2.66602 19.3335V9.3335ZM3.99935 10.6668V19.3335C3.99935 19.7017 4.29783 20.0002 4.66602 20.0002H19.3327C19.7009 20.0002 19.9993 19.7017 19.9993 19.3335V10.6668H3.99935Z'
          fill={props.fill || colors.primary}
        />
        <path
          d='M3.33398 5.33333C3.33398 4.59695 3.93094 4 4.66732 4H19.334C20.0704 4 20.6673 4.59695 20.6673 5.33333V7.33333H3.33398V5.33333Z'
          fill={props.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.66602 4.66683C4.29783 4.66683 3.99935 4.96531 3.99935 5.3335V6.66683H19.9993V5.3335C19.9993 4.96531 19.7009 4.66683 19.3327 4.66683H4.66602ZM2.66602 5.3335C2.66602 4.22893 3.56145 3.3335 4.66602 3.3335H19.3327C20.4373 3.3335 21.3327 4.22893 21.3327 5.3335V8.00016H2.66602V5.3335Z'
          fill={props.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.69661 2.882C7.69661 2.51381 7.39814 2.21533 7.02995 2.21533C6.66176 2.21533 6.36328 2.51381 6.36328 2.882V2.99984H7.69661V2.882ZM12.6966 2.99984H11.3633V2.882C11.3633 2.51381 11.6618 2.21533 12.0299 2.21533C12.3981 2.21533 12.6966 2.51381 12.6966 2.882V2.99984ZM17.6966 2.99984H16.3633V2.882C16.3633 2.51381 16.6618 2.21533 17.0299 2.21533C17.3981 2.21533 17.6966 2.51381 17.6966 2.882V2.99984Z'
          fill={props.fill || colors.primary}
        />
      </svg>
    );
  }
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.66797 4.66671C4.29978 4.66671 4.0013 4.96518 4.0013 5.33337V19.3334C4.0013 19.7016 4.29978 20 4.66797 20H19.3346C19.7028 20 20.0013 19.7016 20.0013 19.3334V5.33337C20.0013 4.96518 19.7028 4.66671 19.3346 4.66671H4.66797ZM2.66797 5.33337C2.66797 4.2288 3.5634 3.33337 4.66797 3.33337H19.3346C20.4392 3.33337 21.3346 4.2288 21.3346 5.33337V19.3334C21.3346 20.4379 20.4392 21.3334 19.3346 21.3334H4.66797C3.5634 21.3334 2.66797 20.4379 2.66797 19.3334V5.33337Z'
        fill={props.fill || colors['pure-white']}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.02995 2.21545C7.39814 2.21545 7.69661 2.51393 7.69661 2.88212V4.88212C7.69661 5.25031 7.39814 5.54879 7.02995 5.54879C6.66176 5.54879 6.36328 5.25031 6.36328 4.88212V2.88212C6.36328 2.51393 6.66176 2.21545 7.02995 2.21545ZM12.0299 2.21545C12.3981 2.21545 12.6966 2.51393 12.6966 2.88212V4.88212C12.6966 5.25031 12.3981 5.54879 12.0299 5.54879C11.6618 5.54879 11.3633 5.25031 11.3633 4.88212V2.88212C11.3633 2.51393 11.6618 2.21545 12.0299 2.21545ZM17.0299 2.21545C17.3981 2.21545 17.6966 2.51393 17.6966 2.88212V4.88212C17.6966 5.25031 17.3981 5.54879 17.0299 5.54879C16.6618 5.54879 16.3633 5.25031 16.3633 4.88212V2.88212C16.3633 2.51393 16.6618 2.21545 17.0299 2.21545Z'
        fill={props.fill || colors['pure-white']}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.6654 9.33333H3.33203V8H20.6654V9.33333Z'
        fill={props.fill || colors['pure-white']}
      />
    </svg>
  );
};
