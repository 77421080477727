import { PropsWithChildren } from 'react';

import { ApplePayIcon } from '../../../assets/icons';
import { CreditCardIcon } from '../../../assets/icons/CreditCard';
import { PayPalIcon } from '../../../assets/icons/PayPal';
import { AvailablePaymentOptions } from '../constants';
import * as Ui from './PaymentOption.styles';

const PaymentIcons = {
  [AvailablePaymentOptions.APPLE_PAY]: {
    icon: <ApplePayIcon />,
  },
  [AvailablePaymentOptions.PAYPAL]: {
    icon: <PayPalIcon />,
  },
  [AvailablePaymentOptions.CREDIT_CARD]: {
    icon: <CreditCardIcon />,
  },
};

type PaymentOptionProps = {
  isSelected: boolean;
  onClickHandler: (option: string) => void;
  option: string;
} & PropsWithChildren;

const PaymentOption = ({ isSelected, onClickHandler, option, children }: PaymentOptionProps) => {
  return (
    <Ui.PaymentOption onClick={() => onClickHandler(option)} isSelected={isSelected}>
      {children ? (
        children
      ) : (
        <>
          <Ui.IconWrapper>{PaymentIcons[option]?.icon}</Ui.IconWrapper>
          <Ui.Label>{option}</Ui.Label>
        </>
      )}
    </Ui.PaymentOption>
  );
};

export { PaymentOption };
