import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum ButtonSize {
  Small = 'small',
  Large = 'large',
}

export enum DisabledButtonVariant {
  Regular = 'regular',
  Transparent = 'transparent',
}

export interface ButtonProps extends PropsWithChildren {
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  disabledVariant?: DisabledButtonVariant;
  selected?: boolean;
  focusKey?: string;
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onPress?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  text?: string;
  w100?: boolean;
  shouldLookLikeDisabled?: boolean;
  loading?: boolean;
  /**
   * TODO temporary solution until we do not have Selector component implemented
   * */
  isSelector?: boolean;
}
