import styled, { css } from 'styled-components';

import { FONT_WEIGHT, TypographyProps, textBase, titleXs } from '../../Common';

export const PaymentOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  border: 2px solid;

  ${({ isSelected }) =>
    isSelected
      ? css`
          border-color: ${({ theme }) => theme.colors['primary']};

          p {
            color: ${({ theme }) => theme.colors.primary};
          }
        `
      : css`
          border-color: ${({ theme }) => theme.colors['off-white']};
        `}

  padding: 1rem 1.5rem;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors['off-white']};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors['primary-soft']};

    p {
      color: ${({ theme }) => theme.colors['primary-soft']};
    }
  }
`;

export const IconWrapper = styled.div`
  margin: 0 1.5rem;
`;

export const Label = styled.p<TypographyProps>`
  ${textBase};
  ${titleXs};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${FONT_WEIGHT.Bold};
`;
