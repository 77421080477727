import { SVGProps } from 'react';

export const InfoError = (props: SVGProps<SVGSVGElement>) => (
  <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.0001 7.1111C18.2544 7.1111 7.11122 18.2542 7.11122 32C7.11122 45.7457 18.2544 56.8889 32.0001 56.8889C45.7459 56.8889 56.889 45.7457 56.889 32C56.889 18.2542 45.7459 7.1111 32.0001 7.1111ZM3.55566 32C3.55566 16.2906 16.2907 3.55554 32.0001 3.55554C47.7095 3.55554 60.4445 16.2906 60.4445 32C60.4445 47.7094 47.7095 60.4444 32.0001 60.4444C16.2907 60.4444 3.55566 47.7094 3.55566 32Z'
      fill={props?.fill ? props.fill : '#EEEEEE'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.7758 23.1111C30.794 23.1111 29.998 22.3152 29.998 21.3333C29.998 20.3515 30.794 19.5555 31.7758 19.5555C32.7577 19.5555 33.6074 20.3515 33.6074 21.3333C33.6074 22.3152 32.7577 23.1111 31.7758 23.1111ZM32.1092 27.5555C33.091 27.5555 33.8869 28.3515 33.8869 29.3333V42.6667C33.8869 43.6485 33.091 44.4444 32.1092 44.4444C31.1273 44.4444 30.3314 43.6485 30.3314 42.6667V29.3333C30.3314 28.3515 31.1273 27.5555 32.1092 27.5555Z'
      fill={props?.fill ? props.fill : '#EEEEEE'}
    />
  </svg>
);
