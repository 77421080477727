import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeHeaderState } from '../store/config';
import { AppDispatch } from '../store/store';

export const useAutoHideHeader = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(changeHeaderState({ autoHideHeader: false }));
  }, []);
};
