import { useEffect } from 'react';

import { cacheManager } from '../utils';

export const ENTRY_PAGE_STORAGE_KEY = 'entryPage';

export const useSetEntryPage = () => {
  useEffect(() => {
    cacheManager.save<string>(
      ENTRY_PAGE_STORAGE_KEY,
      window.location.pathname + window.location.search,
    );
  }, []);
};
