import { AssetContentType, Video } from '../../../api';
import { isCurrentDateBetween } from '../../../helpers/date';

export const isPayPerViewAvailable = (video: Video | null, blockPPGPurchase?: boolean): boolean => {
  if (blockPPGPurchase) {
    return false;
  }

  if (!video) {
    return false;
  }

  if (video.contentType !== AssetContentType.LIVEEVENT) {
    return false;
  }

  if (!(video.purchaseAvailabilityStart && video.purchaseAvailabilityEnd)) {
    return false;
  }

  return isCurrentDateBetween(video.purchaseAvailabilityStart, video.purchaseAvailabilityEnd);
};
