import styled from 'styled-components';

import { breakpoint } from '../../../../utils/breakpoint';

export const ChannelListContainer = styled.div`
  gap: 0.5rem;

  ${breakpoint('lg')`
    gap: 1rem;
  `}
`;
