import { omit } from 'lodash-es';
import { useRef } from 'react';

type ErrorObj = Record<
  string,
  { count: number; timeoutId: NodeJS.Timeout; firstTimeReported: number }
>;

export const useErrorReporting = (
  interval: number,
  cb: (errorCode: string, count: number) => void,
) => {
  const errors = useRef<ErrorObj>({});

  const getError = (errorCode: string) => errors.current[errorCode as keyof ErrorObj];

  const clearError = (errorCode: string) => {
    errors.current = omit(errors.current, errorCode);
  };

  const setError = (
    errorCode: string,
    error: { count: number; timeoutId: NodeJS.Timeout; firstTimeReported: number },
  ) => {
    errors.current[errorCode as keyof ErrorObj] = error;
  };

  const reportError = (errorCode: string) => {
    const reportedError = getError(errorCode);

    if (reportedError) {
      clearTimeout(reportedError.timeoutId);

      const timeoutId = setTimeout(() => {
        cb(errorCode, reportedError.count + 1);
        clearError(errorCode);
      }, reportedError.firstTimeReported + interval - new Date().getTime());

      setError(errorCode, {
        ...reportedError,
        count: reportedError.count + 1,
        timeoutId,
      });
    } else {
      const timeoutId = setTimeout(() => {
        cb(errorCode, errors.current[errorCode].count);
        clearError(errorCode);
      }, interval);

      setError(errorCode, {
        count: 1,
        firstTimeReported: new Date().getTime(),
        timeoutId,
      });
    }
  };

  return {
    reportError,
  };
};
