import { SVGProps } from 'react';

import { colors } from '../../../components/ThemeProvider/constants/colors';

interface Props extends SVGProps<SVGSVGElement> {
  active: boolean;
}

export const Odds = ({ active, ...rest }: Props) => {
  if (active) {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.5868 1.3335C18.9535 1.3335 19.2535 1.6335 19.2535 2.00016V4.74683H22.0002C22.3668 4.74683 22.6668 5.04683 22.6668 5.4135C22.6668 5.78016 22.3668 6.08016 22.0002 6.08016H19.2535V8.82683C19.2535 9.1935 18.9535 9.4935 18.5868 9.4935C18.2202 9.4935 17.9202 9.1935 17.9202 8.82683V6.08016H15.1735C14.8068 6.08016 14.5068 5.78016 14.5068 5.4135C14.5068 5.04683 14.8068 4.74683 15.1735 4.74683H17.9202V2.00016C17.9202 1.6335 18.2202 1.3335 18.5868 1.3335Z'
          fill={rest.fill || colors.primary}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21.3333 11.7668C21.2067 11.6401 21.04 11.5735 20.86 11.5735H12.4333V4.27346C12.4333 3.28013 11.6267 2.4668 10.6267 2.4668H6.08C3.82667 2.47346 2 4.29346 2 6.5468V12.2335C2 12.3935 2.06 12.5335 2.15333 12.6468C2.05333 12.8668 2 13.1135 2 13.3735V17.9201C2 20.1735 3.82667 22.0001 6.08 22.0001H11.7667C11.92 22.0001 12.06 21.9401 12.18 21.8468C12.4 21.9468 12.6467 22.0001 12.9067 22.0001H17.4533C19.7067 22.0001 21.5333 20.1735 21.5333 17.9201V12.2335C21.5267 12.0601 21.46 11.8868 21.3333 11.7668ZM3.33333 6.5468C3.33333 5.03346 4.56 3.80013 6.08 3.80013H10.6267C10.8867 3.80013 11.1 4.01346 11.1 4.27346V11.5668H3.33333V6.5468ZM11.1 20.6668H6.08C4.56 20.6668 3.33333 19.4401 3.33333 17.9201V13.3735C3.33333 13.1135 3.54667 12.9001 3.80667 12.9001H11.1V20.6668ZM20.1933 17.9201C20.1933 19.4335 18.9667 20.6668 17.4467 20.6668H12.9C12.64 20.6668 12.4267 20.4535 12.4267 20.1935V12.9001H20.1933V17.9201Z'
          fill={rest.fill || colors.primary}
        />
      </svg>
    );
  }

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5868 1.3335C18.9535 1.3335 19.2535 1.6335 19.2535 2.00016V4.74683H22.0002C22.3668 4.74683 22.6668 5.04683 22.6668 5.4135C22.6668 5.78016 22.3668 6.08016 22.0002 6.08016H19.2535V8.82683C19.2535 9.1935 18.9535 9.4935 18.5868 9.4935C18.2202 9.4935 17.9202 9.1935 17.9202 8.82683V6.08016H15.1735C14.8068 6.08016 14.5068 5.78016 14.5068 5.4135C14.5068 5.04683 14.8068 4.74683 15.1735 4.74683H17.9202V2.00016C17.9202 1.6335 18.2202 1.3335 18.5868 1.3335Z'
        fill='#626266'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.3333 11.7668C21.2067 11.6401 21.04 11.5735 20.86 11.5735H12.4333V4.27346C12.4333 3.28013 11.6267 2.4668 10.6267 2.4668H6.08C3.82667 2.47346 2 4.29346 2 6.5468V12.2335C2 12.3935 2.06 12.5335 2.15333 12.6468C2.05333 12.8668 2 13.1135 2 13.3735V17.9201C2 20.1735 3.82667 22.0001 6.08 22.0001H11.7667C11.92 22.0001 12.06 21.9401 12.18 21.8468C12.4 21.9468 12.6467 22.0001 12.9067 22.0001H17.4533C19.7067 22.0001 21.5333 20.1735 21.5333 17.9201V12.2335C21.5267 12.0601 21.46 11.8868 21.3333 11.7668ZM3.33333 6.5468C3.33333 5.03346 4.56 3.80013 6.08 3.80013H10.6267C10.8867 3.80013 11.1 4.01346 11.1 4.27346V11.5668H3.33333V6.5468ZM11.1 20.6668H6.08C4.56 20.6668 3.33333 19.4401 3.33333 17.9201V13.3735C3.33333 13.1135 3.54667 12.9001 3.80667 12.9001H11.1V20.6668ZM20.1933 17.9201C20.1933 19.4335 18.9667 20.6668 17.4467 20.6668H12.9C12.64 20.6668 12.4267 20.4535 12.4267 20.1935V12.9001H20.1933V17.9201Z'
        fill='#626266'
      />
    </svg>
  );
};
