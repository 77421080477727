import { createAsyncThunk } from '@reduxjs/toolkit';

import { quickplayCmsApi } from '../../api';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { storefrontActions, storefrontLoadIdAction } from './storefront.slice';

export const storefrontLoadId = createAsyncThunk(
  storefrontLoadIdAction.type,
  async (payload: void, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { GET_STOREFRONT_TABS, GET_STOREFRONT_LIST, FORCE_ZONE_KEY, X_CLIENT_ID },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(storefrontActions.loadStorefrontSetLoading(true));

      const storefrontId = await quickplayCmsApi.getStorefrontId({
        forceZoneKey: FORCE_ZONE_KEY,
        getStoreFrontList: GET_STOREFRONT_LIST,
        xClientId: X_CLIENT_ID,
      });

      const tabs = await quickplayCmsApi.getStorefrontTabs({
        forceZoneKey: FORCE_ZONE_KEY,
        getStoreFrontTabs: GET_STOREFRONT_TABS,
        storefrontId,
        xClientId: X_CLIENT_ID,
      });
      thunksapi.dispatch(storefrontActions.loadStorefrontSuccess({ storefrontId, tabs }));
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(storefrontActions.loadStorefrontError(errorMessage));
    }
  },
);
