import { createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { deviceInfoActions, deviceInfoLoadAction } from './deviceInfo.slice';
import { getWebInfo } from './utils/getWebInfo';

export const deviceInfoLoad = createAsyncThunk(
  deviceInfoLoadAction.type,
  async (payload: void, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { DEVICE_MODEL_NO, DEVICE_USER_AGENT },
        },
      } = thunksapi.getState() as RootState;

      thunksapi.dispatch(deviceInfoActions.loadSetIsLoaded(false));

      const deviceInfo = await getWebInfo({
        deviceModelNo: DEVICE_MODEL_NO,
        deviceUserAgent: DEVICE_USER_AGENT,
      });

      thunksapi.dispatch(deviceInfoActions.loadSuccess(deviceInfo));
    } catch (error: unknown) {
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(deviceInfoActions.loadError(errorMessage));
    }
  },
);
