import { SVGProps } from 'react';

export const EyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.33325 11.9999C9.33325 10.5272 10.5272 9.33325 11.9999 9.33325C13.4727 9.33325 14.6666 10.5272 14.6666 11.9999C14.6666 13.4727 13.4727 14.6666 11.9999 14.6666C10.5272 14.6666 9.33325 13.4727 9.33325 11.9999Z'
      fill={props.fill || 'black'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.76084 8.31806C4.18743 9.32954 3.33333 10.6468 3.33333 11.9999C3.33333 13.353 4.18743 14.6703 5.76084 15.6818C7.32713 16.6887 9.53117 17.3333 12 17.3333C14.4688 17.3333 16.6729 16.6887 18.2392 15.6818C19.8126 14.6703 20.6667 13.353 20.6667 11.9999C20.6667 10.6468 19.8126 9.32954 18.2392 8.31806C16.6729 7.31117 14.4688 6.66659 12 6.66659C9.53117 6.66659 7.32713 7.31117 5.76084 8.31806ZM5.03983 7.19649C6.85155 6.03182 9.31417 5.33325 12 5.33325C14.6858 5.33325 17.1485 6.03182 18.9602 7.19649C20.7648 8.35659 22 10.0393 22 11.9999C22 13.9605 20.7648 15.6433 18.9602 16.8033C17.1485 17.968 14.6858 18.6666 12 18.6666C9.31417 18.6666 6.85155 17.968 5.03983 16.8033C3.23524 15.6433 2 13.9605 2 11.9999C2 10.0393 3.23524 8.35659 5.03983 7.19649Z'
      fill={props.fill || 'black'}
    />
  </svg>
);
