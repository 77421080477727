import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AssetContentType } from '../api/cmsApi/cmsApi.types';
import { channelsSelector } from '../store/channels';
import { videosSelector } from '../store/videos';

export const useVideos = () => {
  const { selectedVideo, carousels, banner, purchaseVideo, loaded, videoLoaded, videoLoading } =
    useSelector(videosSelector);
  const channels = useSelector(channelsSelector);

  const getStreamTitle = useCallback(() => {
    if (selectedVideo?.contentType === AssetContentType.AIRING) {
      const channelWithName = channels.find(
        (channel) => channel.id === selectedVideo.cid && channel.name,
      );

      if (channelWithName) {
        return channelWithName.name;
      }
    }

    return selectedVideo?.title;
  }, [selectedVideo, channels]);

  return {
    banner,
    carousels,
    channels,
    getStreamTitle,
    loaded,
    purchaseVideo,
    selectedVideo,
    videoLoaded,
    videoLoading,
  };
};
