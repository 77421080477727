import { SVGProps } from 'react';

export const SeekToLive = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='35' height='30' viewBox='0 0 35 30' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.4595 0.832238C15.2614 0.274895 18.1657 0.560943 20.8051 1.65421C23.4445 2.74747 25.7004 4.59885 27.2876 6.97423C28.8748 9.34961 29.7219 12.1423 29.7219 14.9991C29.7219 15.6128 29.2244 16.1102 28.6108 16.1102C27.9971 16.1102 27.4997 15.6128 27.4997 14.9991C27.4997 12.5818 26.7829 10.2188 25.4399 8.20883C24.0969 6.1989 22.188 4.63234 19.9547 3.70727C17.7214 2.7822 15.2639 2.54016 12.893 3.01176C10.5221 3.48336 8.34435 4.64741 6.63504 6.35672C4.92573 8.06603 3.76168 10.2438 3.29008 12.6147C2.81849 14.9856 3.06053 17.4431 3.9856 19.6764C4.91067 21.9097 6.47723 23.8185 8.48716 25.1615C10.4971 26.5045 12.8601 27.2214 15.2775 27.2214C15.8911 27.2214 16.3886 27.7188 16.3886 28.3325C16.3886 28.9461 15.8911 29.4436 15.2775 29.4436C12.4206 29.4436 9.62794 28.5964 7.25256 27.0093C4.87718 25.4221 3.0258 23.1662 1.93254 20.5268C0.839269 17.8874 0.553221 14.9831 1.11056 12.1812C1.6679 9.37922 3.0436 6.80546 5.06369 4.78537C7.08379 2.76528 9.65754 1.38958 12.4595 0.832238Z'
      fill={props.fill || 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.2241 12.261C23.5538 11.7434 24.2407 11.5911 24.7582 11.9209L28.7144 14.4412L31.2347 10.485C31.5644 9.96749 32.2513 9.81522 32.7688 10.1449C33.2864 10.4747 33.4386 11.1615 33.1089 11.679L29.3946 17.5094L23.5642 13.7951C23.0467 13.4653 22.8944 12.7785 23.2241 12.261Z'
      fill={props.fill || 'white'}
    />
    <ellipse cx='15.2777' cy='14.9993' rx='3.33333' ry='3.33333' fill='#FFB800' />
  </svg>
);
