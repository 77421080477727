import { isWithinInterval } from 'date-fns';

import { AssetContentType } from '../api/cmsApi/cmsApi.types';

export const isLive = (
  startDate: Date,
  endDate: Date,
  contentType?: string,
  live = '',
): boolean => {
  if (contentType === AssetContentType.AIRING && live === 'true') {
    return true;
  }

  // Preventing RangeError: Invalid interval if invalid data received
  if (endDate <= startDate) {
    return false;
  }

  try {
    const isCurrentlyLive =
      isWithinInterval(new Date(), {
        end: endDate,
        start: startDate,
      }) && [AssetContentType.LIVEEVENT as string].includes(contentType || '');

    return isCurrentlyLive;
  } catch (error) {
    return false;
  }
};
