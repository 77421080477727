import { ChevronIcon } from '../../../../assets/icons/Chevron';
import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { NavigationItem } from '../../Navigation.types';
import { itemsLogosMap } from '../../constants/icons';
import { isElementActive } from '../../helpers/isElementActive';
import { navigationClickHandler } from '../../helpers/navigationClickHandler';
import * as Ui from './SidebarCollapsibleItem.styles';

interface SidebarCollapsibleItemProps {
  item: NavigationItem;
  isOpen: boolean;
  onClick: () => void;
  closeMobileNavigation: () => void;
  disabled?: boolean;
}

export const SidebarCollapsibleItem = ({
  item,
  isOpen,
  onClick,
  closeMobileNavigation,
  disabled,
}: SidebarCollapsibleItemProps) => {
  const navigate = useExtendedNavigate();
  const isParentActive = item.options?.some(isElementActive);

  return (
    <Ui.CollapseLink $isActive={!!isParentActive}>
      <Ui.ParentLink
        $isEnabled={!!item.isAvailableOnMobile}
        $isOpen={isOpen}
        $isActive={!!isParentActive}
        onClick={onClick}
        disabled={disabled}
      >
        <Ui.Logo>
          {itemsLogosMap[item.type](!!isParentActive, item.isAvailableOnMobile, disabled)}
        </Ui.Logo>
        <Ui.Label>{item.label}</Ui.Label>
        <Ui.ChevronWrapper $isOpen={isOpen}>
          <ChevronIcon fill='currentColor' />
        </Ui.ChevronWrapper>
      </Ui.ParentLink>

      <Ui.SubItemsWrapper $isOpen={isOpen}>
        {item?.options?.map((option) => (
          <Ui.SubItem
            role='button'
            key={option.label}
            $isEnabled={!!option.isAvailableOnMobile}
            $isActive={isElementActive(option)}
            onClick={() => {
              navigationClickHandler(option.type, option.path || '', navigate);
              closeMobileNavigation();
            }}
            data-text={option.label}
          >
            {option.label}
          </Ui.SubItem>
        ))}
      </Ui.SubItemsWrapper>
    </Ui.CollapseLink>
  );
};
