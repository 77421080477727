export const getCookie = (cname: string) => {
  const cookie = document.cookie.split(';').find((cookie) => {
    const [cookieName] = cookie.split('=');
    return cookieName.trim() === cname;
  });
  if (cookie) {
    const [, ...valueArray] = cookie.split('=');
    return valueArray.join('=');
  }
  return null;
};
