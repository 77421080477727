import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { PROMO_PARAM_KEY } from '../Constants/promotions';
import { useEnv } from '../components/EnvProvider/EnvProvider';
import { LandingPageModal } from '../components/LandingPageModal/LandingPageModal';
import { useModalContext } from '../components/Modals/context';
import { isAuthedSelector } from '../store/auth';

export const useLandingPage = () => {
  const [html, setHTML] = useState('');
  const isAuthed = useSelector(isAuthedSelector);

  const [searchParams] = useSearchParams();
  const { openModal } = useModalContext();

  const landingId = searchParams.get('landing');
  const promotionId = searchParams.get(PROMO_PARAM_KEY.promotionId);

  const { landingPages } = useEnv();

  useEffect(() => {
    const landingPage = landingPages.find(({ id }) => id === landingId);

    if (landingPage && !promotionId && !isAuthed) {
      axios.get(landingPage.url).then((response) => {
        setHTML(response.data);
      });
    }
  }, [landingId, landingPages, promotionId, isAuthed]);

  useEffect(() => {
    if (html) {
      openModal(<LandingPageModal html={html} id={landingId || ''} />, {
        fullscreen: true,
      });
    }
  }, [html]);
};
