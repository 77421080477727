/* eslint-disable @typescript-eslint/ban-ts-comment */

import { format } from 'date-fns';

export const sortFromOldestToNewest = (a: string, b: string): number => {
  // @ts-ignore
  return new Date(a) - new Date(b);
};

export const filterOutPastItems = (date: string): boolean => {
  const now = new Date();

  return new Date(date) >= now;
};
export const getDate = (date?: string | null) => {
  const now = new Date();
  const [year, month, day] = (date || format(now, 'yyyy-MM-dd')).split('-');
  // month in JS is 0-based
  return new Date(+year, +month - 1, +day);
};
