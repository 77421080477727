import { useEffect, useState } from 'react';

import { flpPlayerApi } from '../../api/playerApi';
import { useEnv } from '../../components/EnvProvider/EnvProvider';

export const useFLPPlatformAuthorizer = ({ accessToken }: { accessToken: string }) => {
  const [platformAuthorizer, setPlatformAuthorizer] = useState();

  const { AUTHORIZATION_ENDPOINT, CLIENT_ID, CLIENT_SECRET, X_CLIENT_ID } = useEnv();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    platformAuthorizer?.dispose();

    const newPlatformAuthorizer = flpPlayerApi.createPlatformAuthorizer({
      accessToken,
      authorizationEndpoint: AUTHORIZATION_ENDPOINT,
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      xClinetId: X_CLIENT_ID,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setPlatformAuthorizer(newPlatformAuthorizer);
  }, [accessToken]);

  return platformAuthorizer;
};

export default useFLPPlatformAuthorizer;
