import { SVGProps } from 'react';

export const PlayIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='52'
    height='64'
    viewBox='0 0 52 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.2536 3.10516L46.2169 25.7474C47.2146 26.4124 48.0977 27.001 48.7681 27.5417C49.4619 28.1013 50.202 28.818 50.6229 29.8329C51.1983 31.2203 51.1983 32.7796 50.6229 34.167C50.202 35.1818 49.4619 35.8985 48.7681 36.4581C48.0977 36.9988 47.2146 37.5874 46.217 38.2524L12.2536 60.8947C11.0359 61.7066 9.98407 62.4078 9.1012 62.8863C8.21752 63.3652 7.11979 63.8443 5.87554 63.77C4.22438 63.6714 2.69909 62.8551 1.70116 61.536C0.949157 60.5419 0.73889 59.3628 0.647181 58.3618C0.555556 57.3618 0.555607 56.0977 0.555667 54.6341L0.55567 9.47847C0.55567 9.44076 0.555669 9.40318 0.555667 9.36573C0.555607 7.90218 0.555556 6.63801 0.647181 5.638C0.738889 4.63709 0.949157 3.45796 1.70116 2.46388C2.69909 1.14472 4.22438 0.328411 5.87554 0.229825C7.1198 0.155532 8.21753 0.634642 9.10121 1.11354C9.98409 1.59201 11.0359 2.29329 12.2536 3.10516ZM6.11431 5.11498C5.92796 5.13904 5.75679 5.23064 5.63341 5.37235C5.6141 5.43154 5.5573 5.62983 5.51568 6.08407C5.44702 6.83343 5.44456 7.86994 5.44456 9.47847V54.5214C5.44456 56.1299 5.44702 57.1664 5.51568 57.9158C5.5573 58.37 5.61409 58.5683 5.63341 58.6275C5.75679 58.7692 5.92798 58.8608 6.11432 58.8849C6.1743 58.8681 6.37079 58.8054 6.77181 58.588C7.43339 58.2295 8.29718 57.6566 9.63556 56.7643L43.4177 34.2429C44.5293 33.5018 45.2229 33.0365 45.699 32.6526C45.9818 32.4245 46.0891 32.3003 46.1195 32.2617C46.1809 32.0926 46.1809 31.9072 46.1195 31.7381C46.0891 31.6996 45.9818 31.5753 45.699 31.3472C45.2229 30.9633 44.5293 30.498 43.4177 29.757L9.63556 7.23551C8.29718 6.34326 7.43339 5.77035 6.77181 5.41181C6.37076 5.19447 6.17428 5.13174 6.11431 5.11498Z'
      fill={props.fill || 'white'}
    />
  </svg>
);
