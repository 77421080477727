import { Suspense, lazy, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES, RouteName } from '../Constants/routes';
import { LogoutToast } from '../components/LogoutToast';
import { ResetPassword, ResetPasswordDone } from '../components/ResetPassword';
import { RoutesProxy } from '../components/RoutesProxy/RoutesProxy';
import Splash from '../components/Splash/Splash';
import { useCheckFlow } from '../flow/hooks/useCheckFlow';
import { displayNonSupportedMobileScreen } from '../helpers/displayMobileView';
import { getRedirectPathForPattern } from '../helpers/getRedirectPath';
import { useSetPreviousPage } from '../hooks/useSetPreviousPage';
import { ForbiddenRegion } from '../pages/Error/ForbiddenRegion/ForbiddenRegion';
import { GeolocationError } from '../pages/Error/GeolocationError/GeolocationError';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ForgotPasswordDone from '../pages/ForgotPasswordDone/ForgotPasswordDone';
import { MigrationPage } from '../pages/MigrationPage/MigrationPage';
import PlayerScreen from '../pages/Player/PlayerScreen';
import { RouteLayoutWrapper } from './RouteLayoutWrapper';

const networkLostPagePromise = import(
  /* webpackPrefetch: true */ '../pages/Error/NetworkLost/NetworkLost'
);
const NetworkLostPage = lazy(() => networkLostPagePromise);
const PlayerPage = displayNonSupportedMobileScreen(PlayerScreen);
const TveResultPage = lazy(() => import('../pages/TveResultPage/TveResultPage'));
const WelcomePage = lazy(() => import('../pages/Welcome/Welcome'));
const SignUpPage = lazy(() => import('../pages/SignUp/SignUp'));
const LoginPage = lazy(() => import('../pages/Login/Login'));
const DeleteAccountPage = lazy(() => import('../pages/DeleteAccount/DeleteAccount'));
const HomePage = lazy(() => import('../pages/Home/Home'));
const SettingsPage = lazy(() => import('../pages/Settings/SettingsPage'));
const TermsPrivacyPage = lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'));
const ServerErrorPage = lazy(() => import('../pages/Error/ServerError'));
const TeamsPage = lazy(() => import('../pages/TeamsPage/TeamsPage'));
const NotFoundPage = lazy(() => import('../pages/Error/NotFound'));
const SchedulePage = lazy(() => import('../pages/Schedule/Schedule'));
const SubscriberAgreementPage = lazy(
  () => import('../pages/SubscriberAgreement/SubscriberAgreement'),
);
const ActivatePage = lazy(() => import('../pages/Activate/Activate'));
const TermsOfUsePage = lazy(() => import('../pages/TermsOfUse/TermsOfUse'));
const PastTermsOfUsePage = lazy(() => import('../pages/PastTermsOfUse/PastTermsOfUse'));
const PrivacyChoicesPage = lazy(() => import('../pages/PrivacyChoices/PrivacyChoices'));
const ProviderPage = lazy(() => import('../pages/Provider/Provider'));
const FavoriteTeamsPage = lazy(() => import('../pages/FavoriteTeams/FavoriteTeams'));
const LivePage = displayNonSupportedMobileScreen(lazy(() => import('../pages/Live/Live')));
const AllSetAuth = lazy(() => import('../pages/AllSetAuth/AllSetAuth'));
const AllSetPayment = lazy(() => import('../pages/AllSetPayment/AllSetPayment'));
const UpdateFavoriteTeams = lazy(() => import('../pages/FavoriteTeams/UpdateFavoriteTeams'));
const SelectSubscriptionPage = lazy(() => import('../pages/SelectSubscription/SelectSubscription'));
const PromotionPage = lazy(() => import('../pages/PromotionPage/PromotionPage'));
const OddsPage = lazy(() => import('../pages/OddsPage/OddsPage'));

const WebViewEditPassword = lazy(() => import('../components/WebView/WebViewEditPassword'));
const LoginPaymentPage = lazy(() => import('../components/LoginPayment/LoginPayment'));
const PaymentError = lazy(() => import('../pages/PaymentError/PaymentError'));
const PurchaseScreenPage = lazy(() => import('../pages/Purchase'));
const TveConnectionStatusPage = lazy(
  () => import('../pages/TveConnectionStatus/TveConnectionStatus'),
);
const CollectionPage = lazy(() => import('../components/SeeAll/Collection'));
const SeeAll = lazy(() => import('../components/SeeAll/SeeAll'));

export type FirstLoadProps = {
  setFirstLoad: (p: boolean) => void;
  firstLoad: boolean;
};

type MsgRoutesProps = {
  entryPage?: string;
};

const MsgRoutes = ({ entryPage }: MsgRoutesProps) => {
  useCheckFlow();
  const [firstLoad, setFirstLoad] = useState(true);
  useSetPreviousPage();

  return (
    <RouteLayoutWrapper>
      <Suspense fallback={<Splash setFirstLoad={setFirstLoad} firstLoad={firstLoad} />}>
        <Routes>
          {firstLoad ? (
            <Route
              path='*'
              element={<Splash setFirstLoad={setFirstLoad} firstLoad={firstLoad} />}
            />
          ) : (
            <Route path='*' element={<NotFoundPage />} />
          )}
          <Route element={<RoutesProxy />}>
            {/* ********* HOME *****************/}

            <Route path='home'>
              <Route path='' element={<HomePage />} />
              <Route path='promotion' element={<PromotionPage />} />
              <Route path='player' element={<PlayerPage />} />
              <Route path='collection' element={<CollectionPage />} />
              <Route path='see-all' element={<SeeAll />} />
              <Route path='see-all/player' element={<PlayerPage />} />
              <Route
                path='select-subscription'
                element={
                  <SelectSubscriptionPage
                    isEntryPage={
                      !!getRedirectPathForPattern(
                        ROUTES[RouteName.SelectSubscription],
                        entryPage || '',
                      )
                    }
                  />
                }
              />
            </Route>

            {/* ********* SCHEDULE *****************/}
            <Route path='schedule'>
              <Route path=':tab?' element={<SchedulePage />} />
              <Route path='games/player' element={<PlayerPage />} />
              <Route path='channels/player' element={<PlayerPage />} />
            </Route>

            {/* ********* TEAMS *****************/}
            <Route path='teams'>
              <Route path='' element={<TeamsPage />} />
              <Route path='player' element={<PlayerPage />} />
              <Route path='collection' element={<CollectionPage />} />
              <Route path='see-all' element={<SeeAll />}>
                <Route path='player' element={<PlayerPage />} />
              </Route>
            </Route>

            {/* ********* ODDS *****************/}

            {/* ********* SETTINGS *****************/}
            <Route path='settings'>
              <Route
                path=''
                element={
                  <LogoutToast>
                    <SettingsPage />
                  </LogoutToast>
                }
              />
              <Route path='profile/update-favorite-teams' element={<UpdateFavoriteTeams />} />
              <Route
                path=':tab'
                element={
                  <LogoutToast>
                    <SettingsPage />
                  </LogoutToast>
                }
              />
            </Route>

            {/* ********* SINGLE PAGES *****************/}
            <Route
              path='/'
              element={<WelcomePage setFirstLoad={setFirstLoad} firstLoad={firstLoad} />}
            />
            <Route
              path='welcome'
              element={<WelcomePage setFirstLoad={setFirstLoad} firstLoad={firstLoad} />}
            />
            <Route path={ROUTES[RouteName.ForbiddenRegion]} element={<ForbiddenRegion />} />
            <Route path={ROUTES[RouteName.GeolocationError]} element={<GeolocationError />} />
            <Route path='favorite-teams' element={<FavoriteTeamsPage />} />
            <Route path='signup' element={<SignUpPage />} />
            <Route path='login' element={<LoginPage />} />
            <Route path='serverError' element={<ServerErrorPage />} />
            <Route path='subscriberAgreement' element={<SubscriberAgreementPage />} />
            <Route path='tveResult/:redirectPath' element={<TveResultPage />} />
            <Route path={ROUTES[RouteName.Networkerror]} element={<NetworkLostPage />} />
            <Route path='editPassword' element={<WebViewEditPassword />} />
            <Route path='terms-of-use' element={<TermsOfUsePage />} />
            <Route path='past-terms-of-use' element={<PastTermsOfUsePage />} />
            <Route path='privacy-choices' element={<PrivacyChoicesPage />} />
            <Route path='privacy-policy' element={<TermsPrivacyPage />} />
            <Route path='payment' element={<PurchaseScreenPage />} />
            <Route path='provider' element={<ProviderPage />} />
            <Route path='odds' element={<OddsPage />} />
            <Route
              path='activate-payment/:zipCode/:zone/:sku/:contentId?'
              element={<LoginPaymentPage />}
            />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='forgot-password-done' element={<ForgotPasswordDone />} />
            <Route path='process/reset' element={<ResetPassword />} />
            <Route path='reset-password-done' element={<ResetPasswordDone />} />
            <Route
              path='activate-sign-in'
              element={
                <LoginPage
                  nextPage={{
                    buttonLabel: 'NEXT',
                    navigateUrl: '/activate',
                    title: 'Sign In',
                  }}
                />
              }
            />
            <Route
              path='activate-sign-up'
              element={
                <SignUpPage
                  nextPage={{
                    buttonLabel: 'NEXT',
                    navigateUrl: '/all-set',
                    title: 'Create a new account',
                  }}
                />
              }
            />
            <Route
              path='activate'
              element={
                <ActivatePage
                  nextPage={{
                    buttonLabel: 'NEXT',
                    navigateUrl: '/all-set',
                    title: 'Create a new account',
                  }}
                />
              }
            />
            <Route path='channels/player' element={<PlayerPage />} />
            <Route path='games/player' element={<PlayerPage />} />
            <Route path='live' element={<LivePage />} />
            <Route path='all-set' element={<AllSetAuth />} />
            <Route path='all-set-payment' element={<AllSetPayment />} />
            <Route path='payment-error' element={<PaymentError />} />
            <Route path='update-favorite-teams' element={<UpdateFavoriteTeams />} />
            <Route path='tve-connection-status' element={<TveConnectionStatusPage />} />
            <Route path='delete-account' element={<DeleteAccountPage />} />
            <Route path='system-settings' element={<Navigate to={ROUTES[RouteName.Home]} />} />
            <Route path='new-app' element={<MigrationPage />} />
          </Route>
        </Routes>
      </Suspense>
    </RouteLayoutWrapper>
  );
};

export default MsgRoutes;
