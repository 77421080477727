export const KEY_CODES = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  BACK: 10009,
  ENTER: 13,
  EXIT: 10182,
  MEDIA_FAST_FOWARD: 417,
  MEDIA_PAUSE: 19,
  MEDIA_PLAY: 415,
  MEDIA_RECORD: 416,
  MEDIA_REWIND: 412,
  MEDIA_STOP: 413,
  MENU: 18,
  SUBTITLES: 10221,
};
