/* eslint-disable sort-keys-fix/sort-keys-fix */
import styled, { RuleSet, css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { ObjectValues } from '../../utils/object-values';

export type TypographyProps = {
  $bolder?: boolean;
  $uppercase?: boolean;
  $mb?: number;
  $truncate?: boolean;
  $stylesXs?: RuleSet<object>;
  $stylesLg?: RuleSet<object>;
  $stylesXl?: RuleSet<object>;
};

export const textBase = (props: TypographyProps) => css`
  font-family: 'GothamNarrow';
  color: ${({ theme }) => theme.colors['off-white']};
  font-style: normal;

  font-weight: ${props.$bolder ? '900' : '325'};
  text-transform: ${props.$uppercase ? 'uppercase' : 'none'};
  margin-bottom: ${props.$mb !== undefined ? props.$mb + 'rem' : 0};

  ${props.$truncate &&
  `
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    `}
`;

export const Header1Xs = css`
  font-size: 2.25rem;
  line-height: 1.1;
  letter-spacing: 0.09rem;
`;

export const Header1Lg = css`
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: 0.1rem;
`;

export const Header1Xl = css`
  font-size: 3.625rem;
  line-height: 5.5rem;
  letter-spacing: 0.145rem;
`;

export const Header2Xs = css`
  font-size: 1.75rem;
  line-height: 1.15;
  letter-spacing: 0.07rem;
`;

export const Header2Lg = css`
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.08rem;
`;

export const Header2Xl = css`
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: 0.1rem;
`;

export const titleXs = css`
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: normal;
`;

export const titleXl = css`
  font-size: 1.75rem;
  line-height: 1.15;
  letter-spacing: normal;
`;

export const body1Xs = css`
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: normal;
`;

export const body1Xl = css`
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: normal;
`;

export const body2Xs = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: normal;
`;

export const body2Xl = css`
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: normal;
`;

export const body3Xs = css`
  font-size: 0.75rem;
  line-height: 1.333;
  letter-spacing: normal;
`;

export const body3Xl = css`
  font-size: 0.875rem;
  line-height: 1.285;
  letter-spacing: normal;
`;

export const body4Xs = css`
  font-size: 0.625rem;
  line-height: 1.4;
  letter-spacing: normal;
`;

export const uppercase = css`
  text-transform: uppercase;
`;

export const FONT_WEIGHT = {
  Regular: '325',
  Bold: '900',
};

export const bolder = css`
  font-weight: ${FONT_WEIGHT.Bold};
`;

export const textCenter = css`
  text-align: center;
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

export const VARIANT = {
  header1: 'header1',
  header2: 'header2',
  title: 'title',
  body1: 'body1',
  body2: 'body2',
  body3: 'body3',
  body4: 'body4',
} as const;

const BREAKPOINT = {
  xs: 'xs',
  lg: 'lg',
  xl: 'xl',
} as const;

type Variant = ObjectValues<typeof VARIANT>;
type Breakpoint = ObjectValues<typeof BREAKPOINT>;

type Fonts = {
  [k in Variant]: {
    [k in Breakpoint]: RuleSet<object>;
  };
};

export const fonts: Fonts = {
  header1: {
    xs: Header1Xs,
    lg: Header1Lg,
    xl: Header1Xl,
  },
  header2: {
    xs: Header2Xs,
    lg: Header2Lg,
    xl: Header2Xl,
  },
  title: {
    xs: titleXs,
    lg: titleXs,
    xl: titleXl,
  },
  body1: {
    xs: body1Xs,
    lg: body1Xs,
    xl: body1Xl,
  },
  body2: {
    xs: body2Xs,
    lg: body2Xs,
    xl: body2Xl,
  },
  body3: {
    xs: body3Xs,
    lg: body3Xs,
    xl: body3Xl,
  },
  body4: {
    xs: body4Xs,
    lg: body4Xs,
    xl: body4Xs,
  },
} as const;

type Tag = 'h1' | 'h2' | 'p';

const generateStyles = (tag: Tag, variant: Variant) => {
  return styled[tag]<TypographyProps>`
    ${textBase}

    ${({ $stylesXs }) => ($stylesXs ? $stylesXs : fonts[variant].xs)};

    ${({ $stylesXs, $stylesLg }) => breakpoint('lg')`
      ${$stylesLg && $stylesLg}
      ${!$stylesXs && !$stylesLg && fonts[variant].lg}
    `}

    ${({ $stylesXs, $stylesLg, $stylesXl }) => breakpoint('xl')`
      ${$stylesXl && $stylesXl}
      ${!$stylesXs && !$stylesLg && !$stylesXl && fonts[variant].xl}
    `}
  `;
};

const Header1 = generateStyles('h1', 'header1');
const Header2 = generateStyles('h2', 'header2');
const Title = generateStyles('p', 'title');
const Body1 = generateStyles('p', 'body1');
const Body2 = generateStyles('p', 'body2');
const Body3 = generateStyles('p', 'body3');
const Body4 = generateStyles('p', 'body4');

export const Typography = {
  Body1,
  Body2,
  Body3,
  Body4,
  Header1,
  Header2,
  Title,
};
