import { createAsyncThunk } from '@reduxjs/toolkit';

import evergentAuthApi from '../../api/authApi/EvergentAuthApi';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { registerDeviceAction, registerDeviceActions } from './registerDevice.slice';

const registerDevice = createAsyncThunk(
  registerDeviceAction.type,
  async (payload: { accessToken: string; activationCode: string }, thunksapi) => {
    try {
      const {
        appConfig: {
          env: { EVERGENT_API_KEY, EVERGENT_CHANNEL_PARTNER_ID, REGISTER_DEVICE_URL },
        },
      } = thunksapi.getState() as RootState;

      const registerDeviceResponse = await evergentAuthApi.registerDevice({
        ...payload,
        evergentApiKey: EVERGENT_API_KEY,
        evergentChannelPartnerId: EVERGENT_CHANNEL_PARTNER_ID,
        registerDeviceUrl: REGISTER_DEVICE_URL,
      });

      if (
        registerDeviceResponse?.failureMessage &&
        registerDeviceResponse.failureMessage.length > 0
      ) {
        thunksapi.dispatch(
          registerDeviceActions.registerDeviceError(
            registerDeviceResponse.failureMessage[0].errorMessage,
          ),
        );
      } else {
        thunksapi.dispatch(registerDeviceActions.registerDeviceSuccess(registerDeviceResponse));
      }
    } catch (error: unknown) {
      Log.error(error);
      const errorMessage = getErrorMessage(error);
      thunksapi.dispatch(registerDeviceActions.registerDeviceError(errorMessage));
    }
  },
);
export { registerDevice };
