import { FailureResponse, GetActiveSubscriptionsResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const getActiveSubscriptions = async ({
  accessToken,
  evergentApiKey,
  evergentChannelPartnerId,
  activeSubscriptionsUrl,
}: {
  accessToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  activeSubscriptionsUrl: string;
}): Promise<GetActiveSubscriptionsResponse> => {
  const message = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
  };

  const data = { GetActiveSubscriptionsRequestMessage: message };
  const response = await evergentAxiosInstance.post<{
    GetActiveSubscriptionsResponseMessage: GetActiveSubscriptionsResponse & FailureResponse;
  }>(activeSubscriptionsUrl, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const { failureMessage, ...dataResponse } = response.data.GetActiveSubscriptionsResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
