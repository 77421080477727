import { useContext } from 'react';

import { PlayerHandlerContext } from '../Player/Player';
import VolumeButton from './VolumeButton/VolumeButton';

export const VolumeControl = () => {
  const { volumeControlState } = useContext(PlayerHandlerContext);
  const { canAdjustVolume } = volumeControlState.volumenControl.volumeControl;

  if (!canAdjustVolume()) {
    return null;
  }

  return (
    <div>
      <VolumeButton />
    </div>
  );
};

export default VolumeControl;
