import styled from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import {
  Container as AppContainer,
  TypographyProps,
  bolder,
  fonts,
  textBase,
  textCenter,
  uppercase,
} from '../Common';
import { NAVIGATION_HEIGHT } from '../Navigation/Navigation.styles';

export const Container = styled(AppContainer)`
  min-height: calc(100vh - ${NAVIGATION_HEIGHT}px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  width: 12.125rem;
  display: flex;
  justify-content: center;
  margin: 2.5rem auto 1.5rem auto;

  img {
    width: 100%;
  }

  ${breakpoint('md')`
    width: 16.5rem;
  `}
`;

export const SpecialTitle = styled.h1<TypographyProps>`
  ${(props) => textBase(props)};
  ${fonts.header2.xs}
  ${bolder}
  ${textCenter}
  ${uppercase} 
  margin-bottom: 1.5rem;

  ${breakpoint('md')`
    margin-top: 8.5rem;
    ${fonts.header2.xl}
  `}
`;

export const DownloadText = styled.p<TypographyProps>`
  ${(props) => textBase(props)};
  ${fonts.body1.xl}
  ${bolder}
  ${textCenter}
  margin-bottom: 1rem;
  margin-top: 3rem;
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  flex-grow: 1;
  margin-bottom: 1.5rem;

  ${breakpoint('xl')`
    margin-bottom: 2.5rem;
  `}
`;

export const LinkWraper = styled.div<TypographyProps>`
  ${(props) => textBase(props)};
  display: flex;
  flex-wrap: wrap;
  width: 13rem;
`;
