import { SVGProps } from 'react';

export const PadlockFilled = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width ?? '36'}
    height={props?.height ?? '36'}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17 6C15.3431 6 14 7.34315 14 9V11H12V9C12 6.23858 14.2386 4 17 4H19C21.7614 4 24 6.23858 24 9V11H22V9C22 7.34315 20.6569 6 19 6H17Z'
      fill={fill ?? 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 13C8.89543 13 8 13.8954 8 15V29C8 30.1046 8.89543 31 10 31H26C27.1046 31 28 30.1046 28 29V15C28 13.8954 27.1046 13 26 13H10ZM18 23C16.8954 23 16 23.8954 16 25V27C16 28.1046 16.8954 29 18 29C19.1046 29 20 28.1046 20 27V25C20 23.8954 19.1046 23 18 23Z'
      fill={fill ?? 'white'}
    />
  </svg>
);
