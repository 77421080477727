import { createAsyncThunk } from '@reduxjs/toolkit';

import { quickplayCmsApi } from '../../api';
import { Log } from '../../helpers/CustomLogger';
import { getErrorMessage } from '../../utils/error';
import { RootState } from '../types';
import { loadTeamsAction, teamsActions } from './teams.slice';

const getTeams = createAsyncThunk(loadTeamsAction.type, async (payload: never, thunksapi) => {
  try {
    thunksapi.dispatch(teamsActions.loadTeamsLoading());

    const {
      appConfig: {
        env: { X_CLIENT_ID, FORCE_ZONE_KEY, TEAMS_LIST_URL_BY_ZONE },
      },
    } = thunksapi.getState() as RootState;

    const teams = await quickplayCmsApi.listTeamsCurrentZone({
      forceZoneKey: FORCE_ZONE_KEY,
      teamsListUrlByZone: TEAMS_LIST_URL_BY_ZONE,
      xClientId: X_CLIENT_ID,
    });

    thunksapi.dispatch(teamsActions.loadTeamsSuccess(teams));
  } catch (error: unknown) {
    Log.error(error);
    const errorMessage = getErrorMessage(error);
    thunksapi.dispatch(teamsActions.loadTeamsError(errorMessage));
  }
});
export { getTeams };
