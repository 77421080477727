import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from '../../../hooks/useTranslations';
import { doesSelectedPaymentOptionRequiresLegalTextSelector } from '../../../store/purchase';
import { LEGAL_LINK } from '../../LegalLink/LegalLink';
import * as Ui from './LegalText.styles';

const LegalText = () => {
  const t = useTranslations();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isLegalTextVisible = useSelector(doesSelectedPaymentOptionRequiresLegalTextSelector);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(isLegalTextVisible ? wrapperRef.current?.scrollHeight || 0 : 0);
  }, [isLegalTextVisible]);

  return (
    <Ui.Wrapper ref={wrapperRef} $height={height}>
      <Ui.Text>
        {t.purchaseScreen_legalText_1}
        <Ui.Link to={LEGAL_LINK.terms} className={'fw-bold text-warning'}>
          {t.subscription_termsOfUseLink}
        </Ui.Link>
        {t.purchaseScreen_legalText_2}
        <Ui.Link to={LEGAL_LINK.privacyPolicy} className={'fw-bold text-warning'}>
          {t.subscription_privacyPolicyLink}
        </Ui.Link>
        {'.'}
      </Ui.Text>

      <br />
      <Ui.Text $bold>{t.purchaseScreen_legalText_3}</Ui.Text>
    </Ui.Wrapper>
  );
};

export { LegalText };
