import { Video } from '../api';

export const determineLocationType = (asset: Video | null): string | undefined => {
  const rowDataTM = asset?.rawData?.tm;

  if (rowDataTM) {
    return rowDataTM[0]?.pmy_tm === 'true' ? rowDataTM[0]?.ty : rowDataTM[1]?.ty;
  }
  return undefined;
};
