import { FailureResponse, ResetPasswordResponse } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const resetPassword = async ({
  email,
  password,
  userToken,
  evergentApiKey,
  evergentChannelPartnerId,
  resetPasswordUrl,
}: {
  email: string;
  password: string;
  userToken: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  resetPasswordUrl: string;
}): Promise<ResetPasswordResponse> => {
  const resetPasswordData = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    contactPassword: password,
    email: email,
    userToken: userToken,
  };

  const body = { ResetPasswordRequestMessage: resetPasswordData };

  const response = await evergentAxiosInstance.post<{
    ResetPasswordResponseMessage: ResetPasswordResponse & FailureResponse;
  }>(resetPasswordUrl, body);

  const { failureMessage, ...dataResponse } = response.data.ResetPasswordResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
