import { createAsyncThunk } from '@reduxjs/toolkit';

import { configActions, configAppAction } from './config.slice';
import { ConfigAppState } from './config.types';

export const changeHeaderState = createAsyncThunk(
  configAppAction.type,
  async (payload: Partial<ConfigAppState>, thunksapi) => {
    thunksapi.dispatch(configActions.setConfigAppSuccess(payload));
  },
);
