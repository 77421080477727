import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../../Constants/routes';
import { Settings, SettingsFilled } from '../../assets/icons';
import logoWeb from '../../assets/images/logo.svg';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useTranslations } from '../../hooks/useTranslations';
import { isAuthedSelector } from '../../store/auth';
import { TVEProviderLogo } from '../TVEProviderComponents/TVEProviderLogo';
import { colors } from '../ThemeProvider/constants/colors';
import * as Ui from './Navigation.styles';
import { NavigationItemType } from './Navigation.types';
import { NavigationDropdown } from './components/NavigationDropdown/NavigationDropdown';
import { NavigationItem } from './components/NavigationItem/NavigationItem';
import { NavigationToggle } from './components/NavigationToggle/NavigationToggle';
import { SidebarNav } from './components/SidebarNav/SideBarNav';
import { isSettingsActive } from './helpers/isSettingsActive';
import { useNavigation } from './hooks/useNavigation';

export const Navigation = () => {
  const t = useTranslations();
  const { isMobileNavigationOpen, items, setIsMobileNavigationOpen } = useNavigation();
  const isAuthed = useSelector(isAuthedSelector);
  const navigate = useExtendedNavigate();

  const closeMobileNavigation = useCallback(() => {
    setIsMobileNavigationOpen(false);
  }, [setIsMobileNavigationOpen]);

  const SettingsIcon = isSettingsActive(ROUTES[RouteName.Settings]) ? SettingsFilled : Settings;

  return (
    <>
      <Ui.Navbar>
        <Ui.Nav>
          <Ui.NavContainer>
            <NavigationToggle
              isNavigationOpen={isMobileNavigationOpen}
              onClick={() => {
                setIsMobileNavigationOpen((prev: boolean) => !prev);
              }}
            />
            <Ui.LogoPlusItemsWrapper>
              <Ui.Logo onClick={() => navigate('/home', { replace: true })} role='button'>
                <Ui.LogoImg src={logoWeb} alt='logo web' />
              </Ui.Logo>
              <Ui.ItemsWrapper>
                {items
                  .filter((item) => item.type !== NavigationItemType.SETTINGS)
                  .map((item, index) =>
                    item?.options?.length ? (
                      <NavigationDropdown key={index} {...item} disabled={!isAuthed} />
                    ) : (
                      <NavigationItem key={index} {...item} disabled={!isAuthed} />
                    ),
                  )}
              </Ui.ItemsWrapper>
            </Ui.LogoPlusItemsWrapper>

            <Ui.SettingsPlusProviderWrapper>
              <TVEProviderLogo height={64} />

              <Ui.Settings>
                <Ui.SettingsButton
                  type={NavigationItemType.SETTINGS}
                  isAvailableOnMobile
                  label={t.header_settings}
                  path={ROUTES[RouteName.Settings]}
                  disabled={!isAuthed}
                >
                  <Ui.SettingsIconWrapper
                    $isSelected={isAuthed && isSettingsActive(ROUTES[RouteName.Settings])}
                  >
                    <SettingsIcon
                      width={30}
                      height={30}
                      fill={!isAuthed ? colors['grey'] : undefined}
                    />
                  </Ui.SettingsIconWrapper>
                </Ui.SettingsButton>
              </Ui.Settings>
            </Ui.SettingsPlusProviderWrapper>
          </Ui.NavContainer>
        </Ui.Nav>
      </Ui.Navbar>

      <SidebarNav
        items={items}
        isOpen={isMobileNavigationOpen}
        closeMobileNavigation={closeMobileNavigation}
      />
    </>
  );
};
