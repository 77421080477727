export const Close = () => (
  <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.54863 9.11592C9.98809 8.67646 10.7006 8.67646 11.1401 9.11592L20.6886 18.6644L30.2371 9.11592C30.6766 8.67646 31.3891 8.67646 31.8285 9.11592C32.268 9.55538 32.268 10.2679 31.8285 10.7073L22.28 20.2559L31.8285 29.8044C32.268 30.2438 32.268 30.9563 31.8285 31.3958C31.3891 31.8353 30.6766 31.8353 30.2371 31.3958L20.6886 21.8473L11.1401 31.3958C10.7006 31.8353 9.98809 31.8353 9.54863 31.3958C9.10917 30.9563 9.10917 30.2438 9.54863 29.8044L19.0972 20.2559L9.54863 10.7073C9.10917 10.2679 9.10917 9.55538 9.54863 9.11592Z'
        fill='#EEEEEE'
      />
    </g>
    <defs>
      <clipPath id='clip0_17548_54055'>
        <rect width='40.5109' height='40.5109' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
