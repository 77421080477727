import styled, { css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { FONT_WEIGHT } from '../Common';
import { ButtonSize, ButtonVariant, DisabledButtonVariant } from './Button.types';

interface StyledButtonProps {
  $variant: ButtonVariant;
  $disabled?: boolean;
  $disabledVariant?: DisabledButtonVariant;
  $size: ButtonSize;
  $selected?: boolean;
  $isSelector?: boolean;
  $w100?: boolean;
}

const primaryButtonStyles = css`
  background: ${({ theme }) => theme.colors['primary']};
  color: ${({ theme }) => theme.colors['pure-black']};
  border-color: ${({ theme }) => theme.colors['primary']};
`;

const secondaryButtonStyles = css`
  background: ${({ theme }) => theme.colors['pure-black']};
  color: ${({ theme }) => theme.colors['grey-soft']};
  border-color: ${({ theme }) => theme.colors['grey-soft']};
`;

const primaryButtonHoverStyles = css`
  &:hover:enabled,
  &:focus-visible:enabled {
    background: ${({ theme }) => theme.colors['primary-soft']};
    border-color: ${({ theme }) => theme.colors['grey-medium']};
  }
`;

const secondaryButtonHoverStyles = css`
  &:hover:enabled,
  &:focus-visible:enabled {
    background: ${({ theme }) => theme.colors['primary-soft']};
    color: ${({ theme }) => theme.colors['pure-black']};
    border-color: ${({ theme }) => theme.colors['grey-medium']};
  }
`;

const disabledButtonStyles = css`
  color: ${({ theme }) => theme.colors['grey-light']};
  background-color: ${({ theme }) => theme.colors['grey']};
  border-color: ${({ theme }) => theme.colors['grey']};
`;

const secondaryButtonDisabledStyles = css`
  ${disabledButtonStyles};
  border-color: ${({ theme }) => theme.colors['grey-soft']};
  background-color: ${({ theme }) => theme.colors['pure-black']};
  opacity: 0.69;
`;

const buttonDisabledTransparentStyles = css`
  color: ${({ theme }) => theme.colors['grey-soft']};
  background: transparent;
`;

const buttonLargeStyles = css`
  font-size: 1rem;
  line-height: 1.25rem;
  padding-inline: 2.25rem;
`;

const buttonSmallStyles = css`
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding-inline: 2rem;
`;

const primaryButtonSelectedStyles = css`
  background: ${({ theme }) => theme.colors['primary']};
  border-color: ${({ theme }) => theme.colors['off-white']};
`;

const secondaryButtonSelectedStyles = css`
  background: ${({ theme }) => theme.colors['pure-black']};
  border-color: ${({ theme }) => theme.colors['primary']};
  color: ${({ theme }) => theme.colors['primary']};
`;

const selectorLikeStyles = css`
  background: ${({ theme }) => theme.colors['grey-dark']};
  border-color: ${({ theme }) => theme.colors['primary']};
  color: ${({ theme }) => theme.colors['off-white']};
`;

const largeButtonPadding = css`
  padding: 0.75rem 1.1875rem 0.75rem 1.1875rem;

  ${breakpoint('lg')`
    padding: 0.875rem 2.125rem 0.875rem 2.125rem;
  `}
`;

const smallButtonPadding = css`
  padding: 0.5625rem 1.5rem;
`;

export const Button = styled.button<StyledButtonProps>`
  position: relative;
  height: auto;
  display: inline-flex;
  border-radius: 0.25rem;
  font-weight: ${FONT_WEIGHT.Bold};
  text-align: center;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-family: 'GothamNarrow';
  min-inline-size: auto;
  border-style: solid;
  gap: 0.75rem;
  white-space: nowrap;
  border-width: 2px;

  .button-content-hidden {
    visibility: hidden;
  }

  .button-loader {
    position: absolute;
  }

  ${({ $variant }) =>
    $variant === ButtonVariant.Primary ? primaryButtonStyles : secondaryButtonStyles}

  ${({ $size }) => ($size === ButtonSize.Large ? buttonLargeStyles : buttonSmallStyles)}

  ${({ $w100 }) => $w100 && 'width: 100%;'}

  ${({ $variant, $isSelector, $disabled }) => {
    /**
     * TODO temporary solution until we do not have Selector component implemented
     * */
    if ($isSelector || $disabled) {
      return undefined;
    }

    return $variant === ButtonVariant.Primary
      ? primaryButtonHoverStyles
      : secondaryButtonHoverStyles;
  }}

  ${({ $disabled, $disabledVariant, $variant }) => {
    if (!$disabled) {
      return undefined;
    }

    if ($disabledVariant === DisabledButtonVariant.Transparent) {
      return buttonDisabledTransparentStyles;
    }

    if ($variant === ButtonVariant.Primary) {
      return disabledButtonStyles;
    }

    return secondaryButtonDisabledStyles;
  }}


  ${({ $size }) => {
    return {
      [ButtonSize.Small]: smallButtonPadding,
      [ButtonSize.Large]: largeButtonPadding,
    }[$size];
  }}};



  ${({ $variant, $selected, $isSelector }) => {
    if (!$selected) {
      return undefined;
    }

    /**
     * TODO temporary solution until we do not have Selector component implemented
     * */

    if ($isSelector) {
      return selectorLikeStyles;
    }

    return $variant === ButtonVariant.Primary
      ? primaryButtonSelectedStyles
      : secondaryButtonSelectedStyles;
  }}
`;
