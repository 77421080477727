import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { ClickEventDispatcher } from '../ClickEventDispatcher/ClickEventDispatcher';
import * as Ui from './ZipCodeTopBar.styles';

export const ZipCodeTopWeb = () => {
  const theme = useTheme();
  const [zipCodeTopRender, setZipCodeTopRender] = useState(true);

  const closeComponent = useCallback(() => {
    theme.setIsZipBarVisible(false);

    setZipCodeTopRender(false);
  }, [theme]);

  if (!zipCodeTopRender) {
    return null;
  }

  return (
    <Ui.Wrapper>
      <Ui.ContentContainer>
        <Ui.ZipCodeForm />

        <Ui.CloseButtonWrapper>
          <ClickEventDispatcher location='Section: Zipcode top bar' text='CTA: Close - "X"'>
            <button
              type='button'
              className='btn-close btn-close-white'
              aria-label='Close'
              onClick={closeComponent}
            ></button>
          </ClickEventDispatcher>
        </Ui.CloseButtonWrapper>
      </Ui.ContentContainer>
    </Ui.Wrapper>
  );
};
