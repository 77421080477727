import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { BackButton } from '../../components/BackButton/BackButton';
import { PlayerLayout } from '../../components/PlayerLayout/PlayerLayout';
import { hasSearchParams } from '../../helpers/searchParams';
import { useExtendedNavigate } from '../../hooks/useExtendedNavigate';
import { useFetchStats } from '../../hooks/useFetchStats';
import { useGetPlayerVideo } from '../../hooks/useGetPlayerVideo';
import { useHandleGoBackOnPlayer } from '../../hooks/useHandleGoBackPlayer';
import { useSubscriptionCheck } from '../../hooks/useSubscriptionCheck';
import { useTranslations } from '../../hooks/useTranslations';
import { useVideos } from '../../hooks/useVideos';
import { isAuthorizedAssetSelector } from '../../store/activeSubscriptions';
import { shouldShowStatsSelector } from '../../store/msgStats';
import { playerActions } from '../../store/player';
import { setPlayerError } from '../../store/player/player.thunks';
import { AppDispatch } from '../../store/store';
import { shouldVideoBeAvailableSelector } from '../../store/videos';

const PlayerScreen = () => {
  useFetchStats();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useExtendedNavigate();
  const [searchParams] = useSearchParams();
  const { selectedVideo, videoLoaded } = useVideos();
  const shouldVideoBeAvailable = useSelector(shouldVideoBeAvailableSelector);

  const isAuthorized = useSelector(isAuthorizedAssetSelector(selectedVideo));
  const shouldShowStats = useSelector(shouldShowStatsSelector);
  const doesUrlContainsSearchParams = hasSearchParams(searchParams);
  const showStats = isAuthorized && shouldShowStats;

  useGetPlayerVideo();
  useSubscriptionCheck();
  const handleGoBackOnPlayer = useHandleGoBackOnPlayer();
  const t = useTranslations();

  // Redirect to home page when content in store or query string is not defined
  useEffect(() => {
    if (!doesUrlContainsSearchParams) {
      navigate('/home');
    }
  }, [doesUrlContainsSearchParams]);

  useEffect(() => {
    if (selectedVideo && !shouldVideoBeAvailable) {
      dispatch(
        setPlayerError({
          errorMessage: t.playerContentAvailability,
        }),
      );
    }
  }, [shouldVideoBeAvailable, selectedVideo]);

  useEffect(() => {
    return () => {
      dispatch(playerActions.resetPlayerAsset());
    };
  }, []);

  return (
    <PlayerLayout
      topbarComponent={<BackButton onClick={handleGoBackOnPlayer} isFloating={false} />}
      content={selectedVideo}
      isVideoReady={videoLoaded}
      showStats={showStats}
    />
  );
};

export default PlayerScreen;
