import { createSelector } from '@reduxjs/toolkit';

import { getDifferenceTimeInHours } from '../../utils/getTimeDifferenceInHours';
import { envSelector } from '../config';
import { RootState } from '../types';
import { ZoneInfoState } from './zoneInfo.types';

export const zoneInfoStateSelector = (state: RootState): ZoneInfoState => state.zoneInfo;

export const currentLocationSelector = createSelector(
  zoneInfoStateSelector,
  ({ currentLocation }) => currentLocation,
);

export const zipCodeSelector = createSelector(zoneInfoStateSelector, ({ zipCode }) => zipCode);

export const zoneNameSelector = createSelector(zoneInfoStateSelector, ({ zoneKey }) => zoneKey);

export const timeOfLastLocationFetchSelector = createSelector(
  zoneInfoStateSelector,
  ({ timeOfLastFetch }) => timeOfLastFetch,
);

export const isCurrentLocationUpToDateSelector = createSelector(
  timeOfLastLocationFetchSelector,
  envSelector,
  (timeOfLastLocationFetch, envs) => {
    if (!timeOfLastLocationFetch) {
      return false;
    }

    const differenceInHours = getDifferenceTimeInHours(Date.now(), timeOfLastLocationFetch);

    return differenceInHours < envs.NUMBER_OF_HOURS_LOCATION_IS_VALID;
  },
);
