import { FailureResponse, SearchAccountV2Response } from '../authApi.types';
import { evergentAxiosInstance } from '../evergentAxiosInstance';

export const searchAccountV2 = async ({
  email,
  evergentApiKey,
  evergentChannelPartnerId,
  searchAccountV2Url,
}: {
  email: string;
  evergentApiKey: string;
  evergentChannelPartnerId: string;
  searchAccountV2Url: string;
}): Promise<SearchAccountV2Response> => {
  const searchAccountV2Data = {
    apiKey: evergentApiKey,
    channelPartnerID: evergentChannelPartnerId,
    email: email,
  };
  const body = { SearchAccountV2RequestMessage: searchAccountV2Data };

  const response = await evergentAxiosInstance.post<{
    SearchAccountV2ResponseMessage: SearchAccountV2Response & FailureResponse;
  }>(searchAccountV2Url, body);

  const { failureMessage, ...dataResponse } = response.data.SearchAccountV2ResponseMessage;

  if (failureMessage && failureMessage.length > 0) {
    throw new Error(failureMessage[0].errorMessage);
  }

  return dataResponse;
};
