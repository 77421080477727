export interface NavigationItem {
  type: NavigationItemType;
  label: string;
  path?: string;
  options?: NavigationItem[];
  isAvailableOnMobile: boolean;
  isElementActiveValidator?: (path?: string) => boolean;
  disabled?: boolean;
  isHiddenByFeatureFlag?: boolean;
  children?: React.ReactNode;
}

export type NavigationItems = Array<NavigationItem>;

export enum NavigationItemType {
  HOME = 'Home',
  LIVE = 'Live',
  TEAMS = 'Teams',
  SCHEDULE = 'Schedule',
  SETTINGS = 'Settings',
  ODDS = 'Odds',
}
