import styled, { css } from 'styled-components';

import { breakpoint } from '../../utils/breakpoint';
import { noScrollbarsForOverflowElements } from '../../utils/styled/mixins';
import { GC_ALIGN } from './GenericCarousel.types';

interface IGenericCarouselWrapperProp {
  $align?: GC_ALIGN;
  $isFullWidth?: boolean;
}

export const GenericCarouselWrapper = styled.div<IGenericCarouselWrapperProp>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  ${({ $isFullWidth }) =>
    $isFullWidth === true &&
    css`
      max-width: 100%;
    `}

  ${({ $align }) =>
    ($align === GC_ALIGN.LEFT || $align === GC_ALIGN.RIGHT) &&
    css`
      margin: 0;
      padding: 0;
      justify-content: ${$align};
    `}
`;

interface GenericCarouselItemsWrapperProps {
  $enableNativeScroll?: boolean;
}

export const GenericCarouselItemsWrapper = styled.div<GenericCarouselItemsWrapperProps>`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  gap: 0.5rem;

  ${noScrollbarsForOverflowElements}

  ${breakpoint('lg')`
    gap: 1rem;
  `}
`;

interface GenericCarouselArrowButtonProps {
  $disabled?: boolean;
}

export const GenericCarouselArrowButton = styled.button<GenericCarouselArrowButtonProps>`
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  font-size: 2.5rem;
  display: flex;
  color: ${({ $disabled, theme }) => theme.colors[$disabled ? 'grey' : 'pure-white']};
`;
