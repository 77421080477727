import styled, { css } from 'styled-components';

import { convertPxToRem } from '../../../utils/styled/convertPxToRem';
import { Button as AppButton } from '../../Button';
import { FONT_WEIGHT, TypographyProps, textBase, titleXs } from '../../Common';
import { ErrorType } from './constants';

export const Wrapper = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors['off-white']};
  color: ${({ theme }) => theme.colors.black}

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2<TypographyProps>`
  ${textBase};
  ${titleXs};
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5rem;
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHT.Bold};
  margin-bottom: 1.5rem;
`;

export const Button = styled(AppButton)`
  display: flex;
  width: 100%;
`;

export const ErrorContentWrapper = styled.div<{
  $type: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors['pure-white']};
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  margin-top: 1.5rem;
  font-family: 'GothamNarrow';

  &:before {
    display: block;
    content: '';
    ${({ $type }) =>
      $type === ErrorType.Error
        ? css`
            background-color: ${({ theme }) => theme.colors['error']};
          `
        : css`
            background-color: ${({ theme }) => theme.colors['primary']};
          `};
    height: 100%;
    width: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export const ErrorLine = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${FONT_WEIGHT.Bold};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.5rem;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
`;

export const PaypalButtonWrapper = styled.div`
  height: ${convertPxToRem(52)};
`;
