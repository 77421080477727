import { RefObject, useEffect } from 'react';

export const useScrollToCarouselElement = (containerRef: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    if (!containerRef.current) return;

    const scroll = () => {
      const currentElement = containerRef?.current?.querySelector('[data-selected="true"]');

      if (currentElement) {
        const childLeft = currentElement.getBoundingClientRect().left;
        const parentLeft = containerRef?.current?.getBoundingClientRect().left || 0;

        containerRef?.current?.scrollTo({
          behavior: 'smooth',
          left: childLeft - parentLeft,
        });
      }
    };

    setTimeout(scroll, 600);
  }, []);
};
