import { useExtendedNavigate } from '../../../../hooks/useExtendedNavigate';
import { NavigationItem as INavigationItem } from '../../Navigation.types';
import { isElementActive } from '../../helpers/isElementActive';
import { navigationClickHandler } from '../../helpers/navigationClickHandler';
import * as Ui from './NavigationItem.styles';

export const NavigationItem = (item: INavigationItem) => {
  const navigate = useExtendedNavigate();
  const { type, path, label, disabled, children, ...restItemProps } = item;

  return (
    <Ui.Item
      $isActive={isElementActive(item)}
      onClick={() => {
        navigationClickHandler(type, path || '', navigate);
      }}
      data-text={label}
      disabled={disabled}
      {...restItemProps}
    >
      {children || label}
    </Ui.Item>
  );
};
