import { RouteName } from '../Constants/routes';
import { StepConfig } from './flow.types';

export class Step {
  id: RouteName;
  pathname: string;
  options?: Record<string, unknown>;

  constructor(config: StepConfig) {
    this.id = config.id;
    this.pathname = config.pathname;
    this.options = config.options ?? {};
  }
}
