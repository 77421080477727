import { SVGProps } from 'react';

export const YourPrivacyChoices = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='25'
    height='14'
    viewBox='0 0 25 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_3740_1681)'>
      <path
        d='M18.6857 12.5656H6.0244C3.00021 12.5656 0.540527 10.0468 0.540527 6.99995C0.540527 3.95308 3.0002 1.43433 6.06472 1.43433H18.6857C21.7099 1.43433 24.2099 3.91245 24.2099 6.99995C24.1696 10.0468 21.7099 12.5656 18.6857 12.5656Z'
        fill='white'
      />
      <path
        d='M18.6856 12.9718H6.0243C2.75817 12.9718 0.137207 10.2906 0.137207 6.99995C0.137207 3.70933 2.7985 1.02808 6.06463 1.02808H18.6856C21.9517 1.02808 24.613 3.70933 24.613 6.99995C24.5727 10.2906 21.9517 12.9718 18.6856 12.9718ZM6.0243 1.84058C3.24205 1.84058 0.943659 4.1562 0.943659 6.99995C0.943659 9.8437 3.24205 12.1593 6.06463 12.1593H18.6856C21.5082 12.1593 23.8066 9.8437 23.8066 6.99995C23.8066 4.1562 21.4679 1.84058 18.6856 1.84058H6.0243Z'
        fill='#005AF8'
      />
      <path
        d='M6.7098 9.3969C6.58884 9.3969 6.50819 9.35628 6.42755 9.27503L4.49206 7.24378C4.33077 7.08128 4.33077 6.83753 4.49206 6.67503C4.65335 6.51253 4.89529 6.51253 5.05658 6.67503L6.66948 8.38128L9.81464 4.76565C9.97593 4.60315 10.2179 4.56253 10.3792 4.72503C10.5404 4.88753 10.5808 5.13128 10.4195 5.29378L6.99206 9.27503C6.91142 9.35628 6.83077 9.3969 6.7098 9.3969Z'
        fill='#005AF8'
      />
      <path
        d='M11.2261 12.5655L14.1696 1.47485H19.2099C19.2099 1.47485 24.1696 2.08423 24.1696 7.04048C24.1696 11.9967 18.6858 12.5655 18.6858 12.5655H11.2261Z'
        fill='#005AF8'
      />
      <path
        d='M19.5323 9.55933C19.4113 9.55933 19.3307 9.5187 19.25 9.43745L14.8549 4.9687C14.6936 4.8062 14.6936 4.56245 14.8549 4.39995C15.0161 4.23745 15.2581 4.23745 15.4194 4.39995L19.7742 8.8687C19.9355 9.0312 19.9355 9.27495 19.7742 9.43745C19.7339 9.5187 19.6129 9.55933 19.5323 9.55933Z'
        fill='white'
      />
      <path
        d='M15.1371 9.5186C15.0161 9.5186 14.9355 9.47798 14.8549 9.39673C14.6936 9.23423 14.6936 8.99048 14.8549 8.82798L19.2903 4.44048C19.4516 4.27798 19.6936 4.27798 19.8549 4.44048C20.0161 4.60298 20.0161 4.84673 19.8549 5.00923L15.4194 9.39673C15.3387 9.47798 15.2178 9.5186 15.1371 9.5186Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3740_1681'>
        <rect
          width='24.4758'
          height='13.9438'
          fill='white'
          transform='translate(0.137207 0.0280762)'
        />
      </clipPath>
    </defs>
  </svg>
);
