/* eslint-disable camelcase */
import mParticle from '@mparticle/web-sdk';

import { EventName, Platform } from '../../api/mParticlesApi/constants';
import { mParticleApi } from '../../api/mParticlesApi/mParticlesApi';
import { PlayerError } from '../../store/player';
import { RootState } from '../../store/types';
import { parseAssetParams } from './parseAssetParams';

const parseEventTypeToErrorType = (eventType: mParticle.EventType) => {
  switch (eventType) {
    case mParticle.EventType.Media:
      return 'media';
    case mParticle.EventType.Transaction:
      return 'purchase';
    case mParticle.EventType.Other:
      return 'tve';
    default:
      return 'unknown';
  }
};

export const sendMParticleError = (
  {
    error,
    eventType,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: PlayerError | any;
    eventType: mParticle.EventType;
  },
  state: RootState,
) => {
  const {
    videos,
    products: { selectedProduct },
    purchase: { voucherCode, item, selectedPaymentOption },
  } = state as RootState;

  const assetParams = videos.purchaseVideo ? parseAssetParams(videos.purchaseVideo) : {};

  const params = {
    ...assetParams,
    affiliation: 'WEB Checkout',
    category: selectedProduct?.productCategory,
    coupon: voucherCode,
    currency: selectedProduct?.currencyCode,
    discount: item?.totalDiscountAmount ?? 0,
    error_code: error?.errorCode || '',
    error_messgage: error?.errorDesc || error?.errorMessage || '',
    error_type: parseEventTypeToErrorType(eventType),
    event_origin: 'client',
    orderId: '',
    payment_method: selectedPaymentOption,
    platform: Platform.Web,
    purchase_type: 'subscription',
    subscription_type: selectedProduct?.serviceType,
    subtotal: selectedProduct?.retailPrice,
    tax: item?.totalTax,
    total: item?.totalPriceAfterTax,
    zone: selectedProduct?.dmaName,
  };

  mParticleApi.triggerEvent(EventName.ErrorReceived, eventType, params);
};
