import { createSlice } from '@reduxjs/toolkit';

import { fetchProducts, selectProduct } from './products.thunks';
import { ProductsState } from './products.types';

const initialState: ProductsState = {
  error: undefined,
  items: [],
  loaded: false,
  selectedProduct: undefined,
};

const slice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.rejected, (state, action) => {
      // eslint-disable-next-line
      // @ts-ignore
      state.error = action.payload;
      state.loaded = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.items = action.payload.products;
      state.selectedProduct = action.payload.selectedProduct;
      state.loaded = true;
      state.error = undefined;
    });
    builder.addCase(selectProduct.fulfilled, (state, action) => {
      state.selectedProduct = action.payload;
    });
  },
  initialState,
  name: 'products',
  reducers: {},
});

const { reducer } = slice;

export { initialState, reducer };
