import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GetProductsResponseItem, ProductServiceTypeEnum } from '../../api/authApi/authApi.types';
import { accessTokenSelector } from '../../store/auth';
import { deviceInfoSelector } from '../../store/deviceInfo';
import { videosSelector } from '../../store/videos';
import { useFLPPlatformAuthorizer } from '../flp';
import { useOvatTokenSelector } from './useOvatTokenSelector';
import usePurchaseToken from './usePurchaseToken';

export const useTVODOrder = ({ product }: { product: GetProductsResponseItem }) => {
  const accessToken = useSelector(accessTokenSelector);
  const { purchaseVideo } = useSelector(videosSelector);
  const contentId = purchaseVideo?.id;
  const platformAuthorizer = useFLPPlatformAuthorizer({ accessToken });

  const { ovatToken, isValid } = useOvatTokenSelector();
  const { deviceId } = useSelector(deviceInfoSelector);

  const isTVODPurchase = useMemo(
    () =>
      purchaseVideo?.productType === ProductServiceTypeEnum.TVOD &&
      product.serviceType === ProductServiceTypeEnum.TVOD,
    [purchaseVideo?.productType, product.serviceType],
  );

  const { purchaseToken } = usePurchaseToken({
    contentId: contentId || '',
    deviceId,
    ovatToken,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    platformAuthorizer,
    shouldSkip: !isTVODPurchase || !isValid,
  });

  return {
    isTVODPurchase,
    purchaseToken,
  };
};
