import { omitBy } from 'lodash';

import { RemoteConfigDataType } from '../api/remoteConfigApi/remoteConfigApi.types';
import { AvailablePaymentOptions } from '../components/PaymentOptions';
import { PaymentOption } from '../store/purchase';

export const getAvailablePaymentOptions = (
  options?: RemoteConfigDataType['availablePaymentOptions'],
): PaymentOption[] => {
  if (!options) {
    return [];
  }

  const availablePaymentOptionsArray = Object.keys(omitBy(options, (isTurnedOn) => !isTurnedOn));

  return Object.values(
    omitBy(AvailablePaymentOptions, (__, key) => !availablePaymentOptionsArray.includes(key)),
  );
};
