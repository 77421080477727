import { useSelector } from 'react-redux';

import { ROUTES, RouteName } from '../../../Constants/routes';
import { buttonClicked } from '../../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { ErrorComponent, IconType } from '../../../components/ErrorComponent/ErrorComponent';
import { useExtendedNavigate } from '../../../hooks/useExtendedNavigate';
import { LogoutType, useLogoutAction } from '../../../hooks/useLogoutAction';
import { useTranslations } from '../../../hooks/useTranslations';
import { authSelector } from '../../../store/auth';
import { getScreenName } from '../../../utils/getScreenName';
import * as Ui from './ForbiddenRegion.styles';

export const ForbiddenRegion = () => {
  const dispatchLogout = useLogoutAction();
  const navigate = useExtendedNavigate();
  const t = useTranslations();
  const { authed } = useSelector(authSelector);

  return (
    <Ui.ContentContainer>
      <ErrorComponent
        error={t.errorDisallowedLocation_text}
        description={t.errorDisallowedLocation_description}
        action={() => {
          authed && dispatchLogout(LogoutType.CURRENT_DEVICE);
          buttonClicked({
            destination: ROUTES[RouteName.Welcome],
            location: 'Module: ForbiddenRegion',
            path: window.location.pathname,
            screen: getScreenName(),
            text: `CTA: ${t.errorDisallowedLocation_button}`,
            type: 'Button',
            url: window.location.href,
          });
          navigate(ROUTES[RouteName.Welcome]);
        }}
        icon={IconType.ErrorX}
        buttonText={t.errorDisallowedLocation_button}
      />
    </Ui.ContentContainer>
  );
};
