import { AllFlowsMap, StepConfig } from './flow.types';

export const createStepConfig = (
  id: StepConfig['id'],
  pathname: StepConfig['pathname'],
  options: StepConfig['options'] = {},
): StepConfig => ({
  id,
  options,
  pathname,
});

export const getFlowByStartingPoint = (entryPage: string, allFlows: AllFlowsMap) => {
  const pathname = getPathnameFromUrl(entryPage);
  return Object.keys(allFlows).find((flowId) => {
    const firstStep = allFlows[flowId][0];
    return pathname === firstStep.pathname;
  });
};

export const getPathnameFromUrl = (url: string) => {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return url;
  }
};
