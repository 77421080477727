import { endOfMonth, subMonths } from 'date-fns';

import { quickplayCmsApi } from '../../../api';
import { getEnv } from '../../../env';
import { prepareGetGamesParams } from './prepareGetGamesParams';

export const checkPrevMonth = async (team: string) => {
  const now = new Date();
  const isFirstDayOfMonth = now.getDate() === 1;

  if (isFirstDayOfMonth) {
    const lastDayOfPreviousMonth = subMonths(endOfMonth(now), 1);
    const lastDayParams = prepareGetGamesParams(team, lastDayOfPreviousMonth);
    const { FORCE_ZONE_KEY, X_CLIENT_ID, GET_LIVE_EVENTS } = getEnv();

    const options = {
      forceZoneKey: FORCE_ZONE_KEY,
      getLiveEvents: GET_LIVE_EVENTS,
      xClientId: X_CLIENT_ID,
    };
    const lastDayGames = await quickplayCmsApi.getGames(lastDayParams, options);

    return !!lastDayGames.length;
  }

  return false;
};
