import { FC, PropsWithChildren, useState } from 'react';
import { default as BootstapThemeProvider } from 'react-bootstrap/ThemeProvider';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { BREAKPOINT } from './constants/breakpoints';
import { colors } from './constants/colors';
import { zIndex } from './constants/zIndex';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isZipBarVisible, setIsZipBarVisible] = useState(false);

  return (
    <BootstapThemeProvider breakpoints={Object.keys(BREAKPOINT)}>
      <StyledThemeProvider
        theme={{
          breakpoints: BREAKPOINT,
          colors,
          isZipBarVisible,
          setIsZipBarVisible,
          zIndex,
        }}
      >
        {children}
      </StyledThemeProvider>
    </BootstapThemeProvider>
  );
};
