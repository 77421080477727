import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ROUTES, RouteName } from '../Constants/routes';
import { GetProductsResponseItem } from '../api/authApi/authApi.types';
import { usePauseSubscription } from '../components/Settings/hooks/usePauseSubscription';
import { getEntryPage } from '../helpers/getEntryPage';
import { previousLocationSelector } from '../store/router';
import { selectedVideoSelector, shouldVideoBeAvailableSelector } from '../store/videos';
import { useExtendedNavigate } from './useExtendedNavigate';
import { useSubscriptionNavigate } from './useSubscriptionNavigate';

export const useSubscriptionCheck = (skipSelectedVideoCheck = false) => {
  const { handleResumeSubscription, isSubscriptionPaused } = usePauseSubscription();
  const { checkSubscription } = useSubscriptionNavigate();
  const selectedVideo = useSelector(selectedVideoSelector);

  const previousLocation = useSelector(previousLocationSelector);
  const shouldVideoBeAvailable = useSelector(shouldVideoBeAvailableSelector);
  const currentLocation = useLocation();
  const { pathname, search } = currentLocation;
  const navigate = useExtendedNavigate();

  // we need internal state to catch navigation changes that use {replace: true}, to
  // not display subscription modal multiple times (for example entering live page and switich between channels)
  const [isFirstAppEntry, setIsFirstAppEntry] = useState(() => !previousLocation);

  const isCurrentLocationEqualEntryPage = useCallback(() => {
    const entryPage = getEntryPage();

    return entryPage === pathname + search;
  }, [pathname, search]);

  const doesUserComesFromLoginOrSignUpFlow = [
    ROUTES[RouteName.Login],
    ROUTES[RouteName.FavoriteTeams],
    ROUTES[RouteName.Signup],
  ].includes(previousLocation?.pathname || '');

  const shouldTriggerSubscriptionCheck = useCallback(() => {
    if ((selectedVideo && shouldVideoBeAvailable) || skipSelectedVideoCheck) {
      // it's entry page
      if (isFirstAppEntry) return true;
      return isCurrentLocationEqualEntryPage() && doesUserComesFromLoginOrSignUpFlow;
    }

    return false;
  }, [
    doesUserComesFromLoginOrSignUpFlow,
    isCurrentLocationEqualEntryPage,
    selectedVideo,
    shouldVideoBeAvailable,
    skipSelectedVideoCheck,
  ]);

  useEffect(() => {
    if (shouldTriggerSubscriptionCheck()) {
      setIsFirstAppEntry(false);
      if (isSubscriptionPaused && !selectedVideo?.isFree) {
        return handleResumeSubscription();
      }
      checkSubscription(selectedVideo, {
        onSuccess: (product: GetProductsResponseItem) => {
          navigate(`${ROUTES[RouteName.AllSetPayment]}`, {
            state: { backRoute: currentLocation, product },
          });
        },
        replaceRoute: false,
      });
    }
  }, [selectedVideo, shouldTriggerSubscriptionCheck, isSubscriptionPaused, currentLocation]);
};
