/* eslint-disable camelcase */
import mParticle from '@mparticle/web-sdk';
import Bowser from 'bowser';

import { logEvent } from '../../../../firebase/firebase';
import { parseAssetParams } from '../../../../utils/mParticle/parseAssetParams';
import { SubscriptionTypeEnum } from '../../../authApi/authApi.types';
import { mParticleApi } from '../../mParticlesApi';
import { EventName } from '../../constants';
import { PurchaseEventParams } from './purchaseEvents.types';

export type PurchasePlanSelectedProps = {
  price: string | number;
  purchase_plan: string;
};

export type ProductItemEventParams = {
  coupon?: string;
  discount?: number;
  tax?: number;
  currencyCode: string;
  displayName: string;
  dmaName: string;
  externalID: string;
  period?: string;
  productCategory: string;
  productDescription: string;
  productID: string;
  productName: string;
  retailPrice: number;
  serviceType: string;
};

export type UserSubscriptionEventParams = {
  id: string;
  plan: string;
  planId: string;
  status: string;
};

export const subscriptionTypeToPurchaseType = (subscriptionType?: SubscriptionTypeEnum) => {
  if (!subscriptionType) {
    return undefined;
  }
  return subscriptionType === SubscriptionTypeEnum.MONTHLY ? 'monthly' : 'annual';
};

export const parsePurchaseParams = (params: PurchaseEventParams) => {
  const browserProps = Bowser.parse(window.navigator.userAgent);

  const assetParams = params.purchaseVideo ? parseAssetParams(params.purchaseVideo) : {};

  const activeSubscription = params.activeSubscription;
  const discount = params.totalDiscountAmount ?? 0;
  const retailPrice = activeSubscription?.retailPrice ?? params.product?.retailPrice ?? 0;
  const taxAmount =
    activeSubscription?.taxDetails?.reduce((total, taxData) => total + taxData.taxAmount, 0) ?? 0;

  return {
    ...assetParams,
    affiliation: activeSubscription?.paymentMethod,
    category: params.product?.productCategory,
    coupon: params.product?.coupon,
    currency: params.product?.currencyCode,
    discount,
    event_origin: 'client',
    order_id: params.subscription?.id,
    payment_method: activeSubscription?.paymentMethod,
    platform: browserProps.platform?.type,
    purchase_type: subscriptionTypeToPurchaseType(activeSubscription?.subscriptionType),
    subscription_type: activeSubscription?.subscriptionType,
    subtotal: retailPrice,
    tax: taxAmount,
    total: +(retailPrice - discount + taxAmount).toFixed(2),
    zone: params.product?.dmaName,
  };
};

export const purchasePlanSelectedEvent = (params: PurchasePlanSelectedProps) => {
  mParticleApi.triggerEvent(
    EventName.PurchasePlanSelected,
    mParticle.EventType.Transaction,
    params,
  );
  logEvent('purchase_plan_selected', { plan: params?.purchase_plan });
};

export const purchaseEvent = (params: PurchaseEventParams) => {
  const data = {
    ...parsePurchaseParams(params),
  };
  mParticleApi.triggerEvent('Purchase', mParticle.EventType.Transaction, data);
  logEvent('subscription_purchase', data);
};
