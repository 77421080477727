import { AssetContentType, PlaybackMode } from '../api';

export const parseContentType = (contentType?: AssetContentType, playbackMode?: PlaybackMode) => {
  switch (playbackMode) {
    case PlaybackMode.LIVE: {
      return 'Live';
    }
    case PlaybackMode.RESTART: {
      return 'DVR';
    }
    case PlaybackMode.CATCHUP: {
      return 'Catchup';
    }
  }

  switch (contentType) {
    case AssetContentType.LIVEEVENT: {
      return 'Live';
    }

    case AssetContentType.AIRING: {
      return 'Live-Linear';
    }

    case AssetContentType.EPISODE: {
      return 'VOD';
    }

    case AssetContentType.CHANNEL: {
      return 'Live-Linear';
    }

    default:
      return '';
  }
};
