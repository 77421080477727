import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useExtendedNavigate } from '../../../hooks/useExtendedNavigate';
import { useSubscriptionNavigate } from '../../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../../hooks/useTranslations';
import { scheduleSelector } from '../../../store/schedule';
import { filterOutPastItems, sortFromOldestToNewest } from '../../../utils/date';
import { Loader } from '../../Loader/Loader';
import { LoadingErrorTryAgain } from '../../LoadingErrorTryAgain/LoadingErrorTryAgain';
import { ProgramRow } from './ProgramRow/ProgramRow';
import { ContentWrapper, NoContentMessage } from './Schedule.styles';

export const ChannelGuide = () => {
  const { channels, loading, error } = useSelector(scheduleSelector);
  const t = useTranslations();
  const { checkSubscription } = useSubscriptionNavigate();
  const navigate = useExtendedNavigate();

  const sortedChannels = useMemo(
    () =>
      channels
        .filter((item) => filterOutPastItems(item.endDate))
        .sort((a, b) => sortFromOldestToNewest(a.startDate, b.startDate)),
    [channels],
  );

  const handleTryAgain = useCallback(() => {
    navigate(0);
  }, [navigate]);

  if (!loading && error) {
    return (
      <ContentWrapper>
        <LoadingErrorTryAgain error={error} tryAgain={handleTryAgain} loading={loading} />
      </ContentWrapper>
    );
  }

  return (
    <>
      {loading && (
        <ContentWrapper>
          <Loader />
        </ContentWrapper>
      )}

      {!loading && sortedChannels.length === 0 && (
        <ContentWrapper>
          <NoContentMessage>{t.schedule_noContent}</NoContentMessage>
        </ContentWrapper>
      )}

      {!error && !loading && sortedChannels.length > 0 && (
        <div className='w-100 mb-4'>
          {sortedChannels.map((program) => (
            <ProgramRow key={program.id} program={program} checkSubscription={checkSubscription} />
          ))}
        </div>
      )}
    </>
  );
};
