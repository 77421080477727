import { SVGProps } from 'react';

export const Padlock = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23 11V9C23 6.79086 21.2091 5 19 5H17C14.7909 5 13 6.79086 13 9V11'
      stroke={fill ?? 'white'}
      strokeWidth='2'
    />
    <path
      d='M8 15C8 13.8954 8.89543 13 10 13H26C27.1046 13 28 13.8954 28 15V29C28 30.1046 27.1046 31 26 31H10C8.89543 31 8 30.1046 8 29V15Z'
      stroke={fill ?? 'white'}
      strokeWidth='2'
    />
    <path
      d='M16 25C16 23.8954 16.8954 23 18 23C19.1046 23 20 23.8954 20 25C20 26.1046 19.1046 27 18 27C16.8954 27 16 26.1046 16 25Z'
      fill={fill ?? 'white'}
      stroke={fill ?? 'white'}
    />
  </svg>
);
