import { SVGProps } from 'react';

export const FullScreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.55469 8.88873C5.55469 7.66143 6.54961 6.6665 7.77691 6.6665H15.5547C16.1683 6.6665 16.6658 7.16397 16.6658 7.77762C16.6658 8.39127 16.1683 8.88873 15.5547 8.88873H7.77691V16.6665C7.77691 17.2802 7.27945 17.7776 6.6658 17.7776C6.05215 17.7776 5.55469 17.2802 5.55469 16.6665V8.88873Z'
      fill={props.stroke || '#EEEEEE'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.2231 6.6665C33.4504 6.6665 34.4453 7.66143 34.4453 8.88873L34.4453 16.6665C34.4453 17.2802 33.9479 17.7776 33.3342 17.7776C32.7206 17.7776 32.2231 17.2802 32.2231 16.6665L32.2231 8.88873L24.4453 8.88873C23.8317 8.88873 23.3342 8.39126 23.3342 7.77761C23.3342 7.16396 23.8317 6.6665 24.4453 6.6665L32.2231 6.6665Z'
      fill={props.stroke || '#EEEEEE'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.4453 31.1113C34.4453 32.3386 33.4504 33.3335 32.2231 33.3335L24.4453 33.3335C23.8317 33.3335 23.3342 32.836 23.3342 32.2224C23.3342 31.6087 23.8317 31.1113 24.4453 31.1113L32.2231 31.1113L32.2231 23.3335C32.2231 22.7198 32.7206 22.2224 33.3342 22.2224C33.9479 22.2224 34.4453 22.7198 34.4453 23.3335L34.4453 31.1113Z'
      fill={props.stroke || '#EEEEEE'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.77691 33.3335C6.54961 33.3335 5.55469 32.3386 5.55469 31.1113L5.55469 23.3335C5.55469 22.7198 6.05215 22.2224 6.6658 22.2224C7.27945 22.2224 7.77691 22.7198 7.77691 23.3335L7.77691 31.1113L15.5547 31.1113C16.1683 31.1113 16.6658 31.6087 16.6658 32.2224C16.6658 32.836 16.1683 33.3335 15.5547 33.3335L7.77691 33.3335Z'
      fill={props.stroke || '#EEEEEE'}
    />
  </svg>
);
