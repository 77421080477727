import { SVGProps } from 'react';

export const PlayBig = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='88'
    height='88'
    viewBox='0 0 88 88'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.2536 15.1057L65.2169 37.7479C66.2146 38.4129 67.0977 39.0015 67.7681 39.5422C68.4619 40.1018 69.202 40.8185 69.6229 41.8334C70.1983 43.2208 70.1983 44.78 69.6229 46.1674C69.202 47.1823 68.4619 47.899 67.7681 48.4586C67.0977 48.9993 66.2146 49.5879 65.217 50.2529L31.2536 72.8952C30.0359 73.7071 28.9841 74.4083 28.1012 74.8868C27.2175 75.3657 26.1198 75.8448 24.8755 75.7705C23.2244 75.6719 21.6991 74.8556 20.7012 73.5364C19.9492 72.5424 19.7389 71.3632 19.6472 70.3623C19.5556 69.3623 19.5556 68.0981 19.5557 66.6346L19.5557 21.479C19.5557 21.4412 19.5557 21.4037 19.5557 21.3662C19.5556 19.9027 19.5556 18.6385 19.6472 17.6385C19.7389 16.6376 19.9492 15.4584 20.7012 14.4644C21.6991 13.1452 23.2244 12.3289 24.8755 12.2303C26.1198 12.156 27.2175 12.6351 28.1012 13.114C28.9841 13.5925 30.0359 14.2938 31.2536 15.1057ZM25.1143 17.1155C24.928 17.1395 24.7568 17.2311 24.6334 17.3728C24.6141 17.432 24.5573 17.6303 24.5157 18.0846C24.447 18.8339 24.4446 19.8704 24.4446 21.479V66.5219C24.4446 68.1304 24.447 69.1669 24.5157 69.9163C24.5573 70.3705 24.6141 70.5688 24.6334 70.628C24.7568 70.7697 24.928 70.8613 25.1143 70.8853C25.1743 70.8686 25.3708 70.8058 25.7718 70.5885C26.4334 70.23 27.2972 69.6571 28.6356 68.7648L62.4177 46.2434C63.5293 45.5023 64.2229 45.037 64.699 44.6531C64.9818 44.425 65.0891 44.3007 65.1195 44.2622C65.1809 44.0931 65.1809 43.9077 65.1195 43.7386C65.0891 43.7001 64.9818 43.5758 64.699 43.3477C64.2229 42.9638 63.5293 42.4985 62.4177 41.7575L28.6356 19.236C27.2972 18.3437 26.4334 17.7708 25.7718 17.4123C25.3708 17.195 25.1743 17.1322 25.1143 17.1155Z'
      fill='white'
    />
  </svg>
);
