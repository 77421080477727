export enum PerformanceMeasure {
  MEDIA_PLAYBACK = 'MEDIA_PLAYBACK',
  EVERGENT_PAYMENT_GATEWAY_ACCESS = 'EVERGENT_PAYMENT_GATEWAY_ACCESS',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  EDIT_PASSWORD = 'EDIT_PASSWORD',
  EDIT_EMAIL = 'EDIT_EMAIL',
  SETUP_FAVORITE_TEAMS = 'SETUP_FAVORITE_TEAMS',
  UPDATE_FAVORITE_TEAMS = 'UPDATE_FAVORITE_TEAMS',
}
