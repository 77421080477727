import t from '../translations/en.json';
import { isErrorWithInternalObj } from './error';

export enum AssetErrorCode {
  ItemNotFound = '40250404',
  LiveEventNotAvailable = '40050126',
  InvalidPlaybackMode = '40050112',
}

export const AssetErrorsMessageMap: Record<AssetErrorCode, string> = {
  [AssetErrorCode.LiveEventNotAvailable]: t.errorLiveEventNotAvailableAnymore,
  [AssetErrorCode.ItemNotFound]: t.errorLiveEventNotAvailableAnymore,
  [AssetErrorCode.InvalidPlaybackMode]: t.errorInvalidPlaybackMode,
};

export const mapAssetErrorMessage = (error: unknown): string => {
  let internalErrorCode: string | undefined;

  if (isErrorWithInternalObj(error)) {
    internalErrorCode = error?.internalError && error?.internalError?.errorCode;
  }
  const message = internalErrorCode
    ? AssetErrorsMessageMap[internalErrorCode as AssetErrorCode]
    : '';

  if (message) {
    return message;
  }

  if (
    typeof error === 'object' &&
    error !== null &&
    'errorMessage' in error &&
    typeof error.errorMessage === 'string'
  ) {
    return error.errorMessage;
  }

  return String(error);
};
