import { Menu } from '../../../../assets/icons/Menu';
import { MenuClose } from '../../../../assets/icons/MenuClose';
import * as Ui from './NavigationToggle.styles';

interface NavigationToggleProps {
  isNavigationOpen: boolean;
  onClick: () => void;
}

export const NavigationToggle = ({ isNavigationOpen, onClick }: NavigationToggleProps) => {
  return <Ui.Button onClick={onClick}>{isNavigationOpen ? <MenuClose /> : <Menu />}</Ui.Button>;
};
