import styled, { css } from 'styled-components';

import { transparentButton } from '../../../../utils/styled/mixins';
import { FONT_WEIGHT } from '../../../Common/Typography.styles';

export const navigationItemStyles = css`
  color: ${({ theme }) => theme.colors['pure-white']};
  font-family: 'GothamNarrow';
  font-size: 1.5rem;
  font-weight: ${FONT_WEIGHT.Regular};
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.75rem;
  text-align: left;
  ${transparentButton}
`;

export const Item = styled.button<{ $isActive: boolean }>`
  ${navigationItemStyles}

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
      font-weight: ${FONT_WEIGHT.Bold};
    `}

  &:hover:enabled {
    font-weight: ${FONT_WEIGHT.Bold};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grey};
  }
`;
