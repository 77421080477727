import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { DeviceInfoState } from './deviceInfo.types';

export const deviceInfoSelector = (state: RootState): DeviceInfoState => state.deviceInfo;

export const deviceIdSelector = createSelector(
  deviceInfoSelector,
  (deviceInfo) => deviceInfo.deviceId,
);
