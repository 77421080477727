import styled from 'styled-components';

export const RetryBox = styled.div`
  height: 681px;
  cursor: pointer;
  &:hover {
    path {
      fill: #ffb800;
    }
  }
`;

export const LoadingBox = styled.div`
  height: 681px;
`;
