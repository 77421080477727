import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { StorefrontContainer } from '../../api/cmsApi';
import { StorefrontState } from './storefront.types';

export const storefrontLoadIdAction = createAction('storefront/loadId');

export const initialState: StorefrontState = {
  error: '',
  loading: false,
  storefrontId: '',
  tabs: [],
};

const slice = createSlice({
  initialState,
  name: 'storefront',
  reducers: {
    clearStorefront: () => initialState,
    loadStorefrontError: (state, action: PayloadAction<string>) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    loadStorefrontSetLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    loadStorefrontSuccess: (
      state: StorefrontState,
      action: PayloadAction<{ storefrontId: string; tabs: StorefrontContainer[] }>,
    ) => ({
      ...state,
      ...action.payload,
      error: '',
      loading: false,
    }),
  },
});

const { name, actions, reducer } = slice;

export { actions as storefrontActions, name, reducer };
