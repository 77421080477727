import { startOfDay } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Channel } from '../../../../api';
import { buttonClicked } from '../../../../api/mParticlesApi/mParticleEvents/generic/genericEvents';
import { useChannelSearchParam } from '../../../../hooks/params/useChannelSearchParam';
import { useDateSearchParam } from '../../../../hooks/params/useDateSearchParam';
import { ScheduleTabs } from '../../../../pages/Schedule/Schedule';
import { channelsSelector, getChannels } from '../../../../store/channels';
import { getChannelEPG } from '../../../../store/schedule';
import { AppDispatch } from '../../../../store/store';
import { getDate } from '../../../../utils/date';
import { ChannelGuide } from '../ChannelGuide';
import { ChannelList } from '../ChannelList/ChannelList';
import { DaysFilter } from '../DaysFilter/DaysFilter';
import * as Ui from '../Schedule.styles';
import { TabSelector } from '../TabSelector/TabSelector';

export const ChannelBody = ({
  selectedTab,
  onChangeTab,
}: {
  selectedTab: ScheduleTabs;
  onChangeTab: (tab: ScheduleTabs) => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const channels = useSelector(channelsSelector);
  const { selectChannel, selectedChannel } = useChannelSearchParam();
  const { selectDate, selectedDate } = useDateSearchParam({});

  const currentSelectedDate = useMemo(() => {
    const newDate = getDate(selectedDate);

    return startOfDay(newDate);
  }, [selectedDate]);

  useEffect(() => {
    if (!channels?.length) {
      dispatch(getChannels());
    }
  }, [dispatch, channels]);

  const handleOnChangeChannel = (channel: Channel) => {
    selectChannel(channel);

    buttonClicked({
      destination: '/schedule/channels',
      location: 'Module: Schedule',
      path: window.location.pathname,
      screen: 'Schedule',
      text: 'CTA: Channels Filters Option',
      type: 'Button',
      url: window.location.href,
    });
  };

  const handleOnChangeDate = (date: Date) => {
    selectDate(date);

    buttonClicked({
      destination: '/schedule/channels',
      location: 'Module: Schedule',
      path: window.location.pathname,
      screen: 'Schedule',
      text: 'CTA: Month-Day Filters Option',
      type: 'Button',
      url: window.location.href,
    });
  };

  useEffect(() => {
    if (selectedChannel && currentSelectedDate) {
      dispatch(getChannelEPG({ channelId: selectedChannel.id, date: currentSelectedDate }));
    }
  }, [currentSelectedDate, selectedChannel]);

  return (
    <Ui.SchedulePage>
      <Ui.Navigation>
        <Ui.NavigationContainer>
          <TabSelector selectedTab={selectedTab} onChangeTab={onChangeTab} />

          <ChannelList selectedChannel={selectedChannel} onChangeChannel={handleOnChangeChannel} />
          <DaysFilter date={currentSelectedDate} onChangeDate={handleOnChangeDate} />
        </Ui.NavigationContainer>
      </Ui.Navigation>

      <Ui.ContentContainer>
        <ChannelGuide />
      </Ui.ContentContainer>
    </Ui.SchedulePage>
  );
};
