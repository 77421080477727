import { envName } from '../env';

/**
 * The getTranslationsEnv function returns the name of the environment.
 * Currently, only two environments are available, as documented at
 * https://github.com/MSGN/DTC_Config
 * @returns The name of the environment (either "prod" or "qa").
 */
export const getTranslationsEnv = () => {
  return envName === 'prod' ? 'prod' : 'qa';
};
