import { useCallback } from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import arrowImg from '../../../assets/images/connectingProvider/arrow.png';
import lockImg from '../../../assets/images/connectingProvider/lock.png';
import msgImg from '../../../assets/images/connectingProvider/msg.png';
import tvImg from '../../../assets/images/connectingProvider/tv.png';
import { useTVEProviderUrl } from '../../../hooks/evergent/useTVEProviderUrl';
import { useLogScreenViewedEvent } from '../../../hooks/useLogScreenViewedEvent';
import { useSubscriptionNavigate } from '../../../hooks/useSubscriptionNavigate';
import { useTranslations } from '../../../hooks/useTranslations';
import { purchaseVideoSelector } from '../../../store/videos';
import { BackButton } from '../../BackButton/BackButton';
import { ClickEventDispatcher } from '../../ClickEventDispatcher/ClickEventDispatcher';
import { LEGAL_LINK } from '../../LegalLink/LegalLink';
import { useModalContext } from '../../Modals/context';
import * as Ui from './ConnectingProviderModal.styles';

type ConnectingProviderModalProps = {
  redirectBackUrl: string;
  skipSubscriptionCheck?: boolean;
};

const ConnectingProviderModal = ({
  redirectBackUrl,
  skipSubscriptionCheck,
}: ConnectingProviderModalProps) => {
  const t = useTranslations();
  const tveProviderUrl = useTVEProviderUrl({ redirectBackUrl });
  const { checkSubscription } = useSubscriptionNavigate();
  const purchaseVideo = useSelector(purchaseVideoSelector);
  const { closeModal } = useModalContext();

  useLogScreenViewedEvent('tve-howto');

  const handleGoBackAction = useCallback(() => {
    closeModal();
    !skipSubscriptionCheck && checkSubscription(purchaseVideo, { replaceRoute: false });
  }, [closeModal, purchaseVideo]);

  return (
    <Ui.ModalBody>
      <Ui.Container>
        <Ui.Wrapper>
          <Ui.Section>
            <Ui.BackButtonWrapper>
              <ClickEventDispatcher location='Module: Connecting Provider Modal' text='CTA: Back'>
                <BackButton onClick={handleGoBackAction} isFloating={false} />
              </ClickEventDispatcher>
            </Ui.BackButtonWrapper>

            <Ui.TitleHeader>{t.tVEConnectingProviderModal_title}</Ui.TitleHeader>
          </Ui.Section>
          <Ui.Section $takeFullHeight>
            <Ui.Content>
              <Ui.ContentText>{t.tVEConnectingProviderModal_desc}</Ui.ContentText>

              <Ui.ImageWrapper>
                <Ui.Img src={msgImg} />
                <Ui.Arrow src={arrowImg} />
                <Ui.Img src={tvImg} />
                <Ui.Arrow src={arrowImg} />
                <Ui.Img src={lockImg} />
              </Ui.ImageWrapper>

              <Ui.ContentList>
                <Ui.ContentText>{t.tVEConnectingProviderModal_listItem_1}</Ui.ContentText>
                <Ui.ContentText>{t.tVEConnectingProviderModal_listItem_2}</Ui.ContentText>
                <Ui.ContentText>{t.tVEConnectingProviderModal_listItem_3}</Ui.ContentText>
              </Ui.ContentList>
              <Ui.ContentText>{t.tVEConnectingProviderModal_noteText}</Ui.ContentText>
            </Ui.Content>
          </Ui.Section>

          <Ui.Section>
            <Ui.FaqInfoWrapper>
              <span>{t.tVEConnectingProviderModal_faq_1}</span>
              <Ui.LegalLink
                to={LEGAL_LINK.faqs}
                className='text-decoration-none fw-bold text-warning'
              >
                &nbsp;{t.subscription_faqLink}&nbsp;
              </Ui.LegalLink>

              <span> {t.tVEConnectingProviderModal_faq_2}</span>
            </Ui.FaqInfoWrapper>
            <Col md={{ offset: 4, span: 4 }}>
              <Ui.ConnectTVEProviderButton
                onPress={() => {
                  window.location.href = tveProviderUrl();
                }}
              >
                {t.tVEConnectingProviderModal_button}
              </Ui.ConnectTVEProviderButton>
            </Col>
          </Ui.Section>
        </Ui.Wrapper>
      </Ui.Container>
    </Ui.ModalBody>
  );
};

export { ConnectingProviderModal };
